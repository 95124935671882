import {
  FromGameMessage,
  FromGameMessagesSchema,
} from "../../../core/gameConnection/messages/fromGameMessages";
import { SliceCreator } from "../../../store/store";
import { ProfileData } from "../profile/lib/profileTypes";

type LugiaReaction = FromGameMessagesSchema["Reaction"] & {
  timestamp: ReturnType<DateConstructor["now"]>;
};

export interface SocialState {
  selectedProfileData: Partial<ProfileData> | null;
  setSelectedProfileData: (profile: Partial<ProfileData> | null) => void;
  dispatchSocialMessage: (message: FromGameMessage) => void;

  currentReactions: Array<LugiaReaction>;
  pushReaction: (reaction: LugiaReaction) => void;
  shiftReactions: () => void;
}

type State = {
  social: SocialState;
};

export const createSocialSlice: SliceCreator<State> = (set) => ({
  social: {
    selectedProfileData: null,
    setSelectedProfileData: (profile) =>
      set((state) => {
        state.social.selectedProfileData = profile;
      }),
    currentReactions: [],
    pushReaction: (reaction) =>
      set((state) => {
        state.social.currentReactions.push(reaction);
      }),
    shiftReactions: () =>
      set((state) => {
        state.social.currentReactions.shift();
      }),
    dispatchSocialMessage: (message) => {
      set((state) => {
        switch (message.type) {
          case "ShowBusinessCard":
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations
            const { type, ...profileData } = message;
            state.social.selectedProfileData = profileData;
            break;
          case "Reaction":
            if (
              state.gameConnection.playerId !== message.playerId ||
              state.gameConnection.roomId !== message.roomId
            ) {
              const LugiaReactionDto = {
                ...message,
                timestamp: Date.now(),
              };
              state.social.currentReactions.push(LugiaReactionDto);
            }
            break;
          default:
            break;
        }
      });
    },
  },
});
