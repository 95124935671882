import { useEffect, useState } from "react";
import { Props } from "./VideoAvatars.ui";
import AvatarsRow from "./lib/AvatarsRow";

const VideoAvatarsUiNormal: React.FC<Props> = (props) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    // Observe if row intersects half of screen horizontally
    const observer = new IntersectionObserver(
      ([entry]) => {
        props?.openPanel?.("logo");
        if (entry.isIntersecting) {
          props?.openPanel?.("logo");
        }
      },
      { rootMargin: "0px -45%" }
    );
    if (ref) {
      observer.observe(ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, props.openPanel, props.closePanel]);
  return <AvatarsRow {...props} ref={setRef} />;
};

export default VideoAvatarsUiNormal;
