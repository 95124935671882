import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCurrentMap } from "../api/gyaradosHooks";

export type MapImageContextType = {
  mapImage?: ReactNode;
};

const MagImageContext = createContext<MapImageContextType>(
  {} as MapImageContextType
);

export const MapImageProvider = MagImageContext.Provider;

/** Context that provides the preloaded Map image for smoother transitions */
export const useMapImageContext = () => {
  const context = useContext(MagImageContext);
  if (context === undefined) {
    throw new Error("MagImageContext must be within the Experience Route");
  }
  return context;
};

type Props = {
  children?: ReactNode;
};

const MapImageContextProvider: React.FC<Props> = ({ children }) => {
  const { imgUrl } = useCurrentMap();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const mapImage = useMemo(() => {
    const image = new Image();
    image.src = imgUrl || "";
    image.style.minHeight = "100dvh";
    image.style.minWidth = "100dvw";

    return image;
  }, [imgUrl]);

  useEffect(() => {
    if (!containerRef) return;
    if (!mapImage) return;
    if (containerRef.firstChild instanceof HTMLImageElement) return;

    containerRef.appendChild(mapImage);
  }, [mapImage, containerRef]);

  const container = <div ref={(el) => setContainerRef(el)} />;

  return (
    <MapImageProvider value={{ mapImage: container }}>
      {children}
    </MapImageProvider>
  );
};

export default MapImageContextProvider;
