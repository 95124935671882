import { styled } from "styled-components";
import Column from "../../../components-ui/atoms/Column";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import Card from "../../../components-ui/molecules/Card";
import { useText } from "../../../core/i18n/i18nHooks";
import { Props } from "./Language.ui";
import LanguageCardHeader from "./component/LanguageCardHeader";
import LanguageCardList from "./component/LanguageCardList";

const Wrapper = styled.div`
  display: flex;
`;

const LanguageUiNormal: React.FC<Props> = ({
  handleDismiss,
  locales,
  currentLocale,
  onLanguageSelected,
}) => {
  const t = useText();
  return (
    <Wrapper>
      <Spatial rim glare mouseZoom mouseTiltX mouseTiltY dragMotion>
        <Card
          maxHeight="500px"
          onClose={handleDismiss}
          topSection={
            <>
              <Space h={7} />
              <LanguageCardHeader />
              <Space h={4} />
              <Column align="center">
                <Typo.Title>{t("settings_select_language_title")}</Typo.Title>
                <Space h={2} />
                <Typo.Body>
                  {t("settings_select_language_description")}
                </Typo.Body>
                <Space h={4} />
              </Column>
            </>
          }
        >
          <LanguageCardList
            locales={locales}
            currentLocale={currentLocale}
            onLanguageSelected={onLanguageSelected}
          />
        </Card>
      </Spatial>
    </Wrapper>
  );
};

export default LanguageUiNormal;
