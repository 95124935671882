import { log, logTable } from "../../../lib/logger";
import { ToGameMessage } from "../messages/toGameMessages";
import { sanitizeGameMessage } from "./helpers/logs";
import { emitUIInteraction } from "./webRtcConnection";

export const sendGameMessage = (message: ToGameMessage) => {
  emitUIInteraction(message);
  const { type } = message;
  const ignoreInLog = ["WebRtcStreamingStats", "UIElementCoords"];
  if (!ignoreInLog.includes(type)) {
    log("MESSAGE_OUT", `-> GameMessage ${type}:`);
    logTable("MESSAGE_OUT", sanitizeGameMessage(message));
  }
};
