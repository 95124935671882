import { useStore } from "../../../store/store";
import DevOptionsUi from "./DevOptions.ui";

const DevOptionsLogic: React.FC = () => {
  const closePanel = useStore((s) => s.layout.closePanel);

  return <DevOptionsUi onClose={() => closePanel("devOptions")} />;
};

export default DevOptionsLogic;
