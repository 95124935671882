import { useStore } from "../../../../store/store";
import { SettingName } from "../subpages/home/SettingsHomePage.ui";

const useInactiveSettings = () => {
  const muted = useStore((s) => s.userMedia.muted);
  const micMuted = useStore((s) => s.userMedia.micMuted);
  const inactive: SettingName[] = [];
  if (muted) inactive.push("volume");
  if (micMuted) inactive.push("mic");

  return { inactive };
};

export default useInactiveSettings;
