import { useVideoConferenceControlsContext } from "../../../core/videoConference/VideoConference";
import { VideoConferenceParticipant } from "../../../core/videoConference/VideoConferenceAdapter";
import { useStore } from "../../../store/store";
import { getLocalPlayerName } from "../profile/profileSlice";
import ScreenSharingUi from "./ScreenSharing.ui";

const ScreenSharingLogic: React.FC = () => {
  const profileData = useStore((s) => s.profile.profileData);
  const minimized = useStore(
    (s) => s.layout.panels.screenSharing.options?.minimized
  );
  const setPanelExtraOptions = useStore((s) => s.layout.setPanelExtraOptions);
  const screenSharer = useStore((s) => s.videoConference.screenSharer);
  const subpage = useStore((s) => s.layout.panels.social.subpage);
  const socialPanelVisible = useStore((s) => s.layout.panels.social.visible);
  const openPanel = useStore((state) => state.layout.openPanel);
  const activeScreenShare = useStore(
    (state) => state.videoConference.activeScreenShare
  );
  const userId = useStore((s) => s.session.visitorTokenData?.id);
  const micMuted = useStore((s) => s.userMedia.micMuted);
  const { toggleLocalAudio, toggleVideo, stopScreenShare, startScreenShare } =
    useVideoConferenceControlsContext();
  const amIScreenSharer = useStore((state) =>
    state.videoConference.amIScreenSharer()
  );
  const activeParticipants = useStore(
    (state) => state.videoConference.activeParticipants
  );
  const participants = useStore((state) => state.videoConference.participants);
  const screenSharerParticipant = useStore(
    (state) => state.videoConference.screenSharer
  );
  const nrOfParticipants = useStore((state) =>
    state.videoConference.nrOfPublishingParticipants()
  );
  const localParticipant = useStore((state) => state.videoConference.self());
  const allPlayers = useStore((s) => s.gameConnection.allPlayers);
  const roomId = useStore((s) => s.gameConnection.roomId);

  const name = getLocalPlayerName(profileData);
  const screenSharerProfile = screenSharerParticipant?.userId
    ? allPlayers[screenSharerParticipant.userId]
    : null;

  const onMinimizeScreenShare = () => {
    setPanelExtraOptions("screenSharing", {
      minimized: true,
    });
  };

  const onMaximizeScreenShare = () => {
    setPanelExtraOptions("screenSharing", {
      minimized: false,
    });
  };

  return (
    <ScreenSharingUi
      playersRowHidden={
        Boolean(socialPanelVisible) && subpage === "social/players"
      }
      profileData={profileData}
      userId={userId}
      allPlayers={allPlayers}
      openPlayerList={() => openPanel("social/players")}
      screenSharer={screenSharer}
      screenSharerName={
        amIScreenSharer ? name : screenSharerProfile?.name || null
      }
      minimized={Boolean(minimized)}
      roomId={roomId}
      micMuted={micMuted}
      stopScreenShare={stopScreenShare}
      startScreenShare={startScreenShare}
      onMinimizeScreenShare={onMinimizeScreenShare}
      onMaximizeScreenShare={onMaximizeScreenShare}
      amIScreenSharer={amIScreenSharer}
      activeScreenShare={activeScreenShare}
      allParticipants={Object.values(participants)}
      activeParticipants={activeParticipants}
      nrOfParticipants={nrOfParticipants}
      localParticipant={localParticipant as VideoConferenceParticipant}
      toggleVideo={toggleVideo}
      toggleLocalAudio={toggleLocalAudio}
    />
  );
};

export default ScreenSharingLogic;
