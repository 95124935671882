import { useRef } from "react";
import CardFullLandscape from "../../../components-ui/molecules/CardFullLandscape";
import { PanelTestIds } from "../../../constants/testIds";
import { Props } from "./Profile.ui";
import SmallProfileForm from "./component/SmallProfileForm";

const ProfilePanelUiLandscape: React.FC<Props> = ({
  data,
  settings,
  onChange,
  avatarsOptions,
  isReadyPlayerMeEditorOpen,
  setIsReadyPlayerMeEditorOpen,
  setCustomAvatarUrl,
  hasConfirmed,
  setHasConfirmed,
  hasReadyPlayerMe,
  isMissingAvatar,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CardFullLandscape testId={PanelTestIds.profile.landscape.root} ref={ref}>
      <SmallProfileForm
        hasReadyPlayerMe={hasReadyPlayerMe}
        setCustomAvatarUrl={setCustomAvatarUrl}
        data={data}
        settings={settings}
        onChange={onChange}
        avatarsOptions={avatarsOptions}
        isReadyPlayerMeEditorOpen={isReadyPlayerMeEditorOpen}
        setIsReadyPlayerMeEditorOpen={setIsReadyPlayerMeEditorOpen}
        isLandscape
        hasConfirmed={hasConfirmed}
        setHasConfirmed={setHasConfirmed}
        isMissingAvatar={isMissingAvatar}
      />
    </CardFullLandscape>
  );
};

export default ProfilePanelUiLandscape;
