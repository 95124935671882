import { useEffect, useState } from "react";
import LogRocket from "logrocket";
import { USE_LOGROCKET_IN_DEVELOPMENT } from "../../constants/configs";
import { useStore } from "../../store/store";
import { useEnvironmentContext } from "../EnvironmentFetcher.core";
import { getInstanceInfoFromUrl } from "../gameConnection/gameConnectionUtils";
import { getQueryStrings } from "../routing/routingUtils";
import { safelyParseJsonResponse, sanitizeURL } from "./sanitize";

export const useLogrocket = (disabled: boolean) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const isDisabled = !useIsLogRocketEnabled(disabled);
  const setLogRocketSessionId = useStore(
    (s) => s.session.setLogRocketSessionId
  );

  const shouldInit = !isInitialized && !isDisabled;

  useEffect(() => {
    if (!shouldInit) return;
    setIsInitialized(true);

    const APP_ID = import.meta.env.VITE_LOGROCKET_APP_ID;
    LogRocket.init(APP_ID, {
      release: __APP_VERSION__,
      console: {
        shouldAggregateConsoleErrors: true,
      },
      shouldCaptureIP: false,
      network: {
        requestSanitizer: requestSanitizer,
        responseSanitizer: responseSanitizer,
      },
      // browser: {
      //   urlSanitizer: (url) => sanitizeURL(url) || null,
      // },
    });

    LogRocket.getSessionURL((session) => setLogRocketSessionId(session));
  }, [shouldInit, setLogRocketSessionId]);
};

type Metadata = {
  id: string;
  environmentId: string;
  instanceId: string;
  streamId?: string;
  testerId?: string;
};

export const useIdentifyLogRocketUser = () => {
  const instanceUrl = useStore((s) => s.gameConnection.instanceUrl);
  const visitorTokenData = useStore((s) => s.session.visitorTokenData);
  const streamId = useStore((s) => s.gameConnection.streamId);
  const { environmentId } = useEnvironmentContext();
  const { testerId } = getQueryStrings();
  const logRocketEnabled = useIsLogRocketEnabled(false);
  const instance = getInstanceInfoFromUrl(instanceUrl ?? "");

  useEffect(() => {
    if (!instanceUrl && import.meta.env.MODE !== "development") return;
    if (!environmentId) return;
    if (!visitorTokenData) return;
    if (!logRocketEnabled) return;

    const metadata: Metadata = {
      id: visitorTokenData.id,
      environmentId,
      instanceId: instance.instanceId,
      streamId: streamId ?? "",
    };
    // we cannot send metadata that will change else it wont be modifiable on the next session
    // if the metadata is added in the next page reload it will be appended to the session
    if (testerId) {
      metadata.testerId = testerId;
    }

    LogRocket.identify(visitorTokenData.id.toString(), metadata);
  }, [
    streamId,
    instanceUrl,
    environmentId,
    testerId,
    instance,
    visitorTokenData,
    logRocketEnabled,
  ]);
};

const useIsLogRocketEnabled = (disabled: boolean) => {
  const {
    environment: { disableLogrocket: isDisabledInCms },
  } = useEnvironmentContext();

  const isDisabled =
    disabled ||
    isDisabledInCms ||
    (import.meta.env.MODE === "development" && !USE_LOGROCKET_IN_DEVELOPMENT);

  return !isDisabled;
};
/**
 * Copied and modified from LR SDK because URL is missing in the LogRocket type.
 */
export interface IResponseWithUrl {
  reqId: string;
  status?: number;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  url?: string;
}
/**
 * Copied and modified from LR SDK because LogRocket does not exprot the type.
 */
interface IRequest {
  reqId: string;
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  referrer?: string;
  mode?: string;
  credentials?: string;
}

const redactHeader = (data: IRequest | IResponseWithUrl, header: string) => {
  if (data.headers[header]) {
    data.headers[header] = "**redacted**";
  }
};

const requestSanitizer = (request: IRequest) => {
  redactHeader(request, "x-auth-token");
  redactHeader(request, "Authorization");

  request.url = sanitizeURL(request.url) || request.url;
  if (request.url.toLowerCase().indexOf("/login") >= 0) {
    request.body = "**redacted**";
  }
  if (request.url.toLowerCase().indexOf("/business-card") >= 0) {
    const parsedBody = request.body ? JSON.parse(request.body) : null;
    if (parsedBody) {
      const requestBodyCopy: Record<string, string> = {};
      requestBodyCopy.roomId = parsedBody.roomId;
      requestBodyCopy.playerId = parsedBody.playerId;
      request.body = JSON.stringify(requestBodyCopy);
    }
  }

  return request;
};

const responseSanitizer = (response: IResponseWithUrl) => {
  redactHeader(response, "Authorization");

  if (response.headers["x-secret"]) {
    // removes all response data
    return null;
  }
  response.url = sanitizeURL(response.url);
  if (response.url) {
    if (response.url.toLowerCase().indexOf("/login") >= 0) {
      response.body = "**redacted**";
    }
    if (response.url.toLowerCase().indexOf("/login") >= 0) {
      response.body = "**redacted**";
    }
    if (response.url.toLowerCase().indexOf("/me") >= 0) {
      const responseBodyCopy = safelyParseJsonResponse(response);
      if (responseBodyCopy) {
        responseBodyCopy.email = "**redacted**";
        response.body = JSON.stringify(responseBodyCopy);
      }
    }
    if (response.url.toLowerCase().indexOf("/check-token") >= 0) {
      const responseBodyCopy = safelyParseJsonResponse(response);
      if (responseBodyCopy) {
        responseBodyCopy.jwt = "*****";
        responseBodyCopy.email = "**redacted**";
        response.body = JSON.stringify(responseBodyCopy);
      }
    }
  }
  return response;
};
