import { SvgProps } from "../../types/svg";

const WalletIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.75 3C16.8875 3 17 3.11187 17 3.25C17 3.38813 16.8875 3.5 16.75 3.5H4.25C3.28344 3.5 2.5 4.28344 2.5 5.25V14.75C2.5 15.7156 3.28344 16.5 4.25 16.5H15.75C16.7156 16.5 17.5 15.7156 17.5 14.75V7.75C17.5 6.78438 16.7156 6 15.75 6H4.25C4.11187 6 4 5.8875 4 5.75C4 5.6125 4.11187 5.5 4.25 5.5H15.75C16.9937 5.5 18 6.50625 18 7.75V14.75C18 15.9937 16.9937 17 15.75 17H4.25C3.0075 17 2 15.9937 2 14.75V5.25C2 4.0075 3.0075 3 4.25 3H16.75ZM15.75 11.25C15.75 11.9406 15.1906 12.5 14.5 12.5C13.8094 12.5 13.25 11.9406 13.25 11.25C13.25 10.5594 13.8094 10 14.5 10C15.1906 10 15.75 10.5594 15.75 11.25ZM14.5 10.5C14.0844 10.5 13.75 10.8344 13.75 11.25C13.75 11.6656 14.0844 12 14.5 12C14.9156 12 15.25 11.6656 15.25 11.25C15.25 10.8344 14.9156 10.5 14.5 10.5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default WalletIcon;
