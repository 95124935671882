import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import Backend, { HttpBackendOptions } from "i18next-http-backend";

export const DEFAULT_LOCALE_CODE = "en";

/**
 * 18n initialization should be done and exported at the beginning according to their docs.
 * https://react.i18next.com/latest/using-with-hooks
 * https://react.i18next.com/latest/i18next-instance
 * else some components will not be able to use the hooks.
 * https://github.com/i18next/react-i18next/issues/1473
 */
// Passes i18n down to react-i18next.
i18n
  /* Load translation using http -> see /public/locales
     it has 1 level of fallback search for the exact match and the */
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    // resources: {
    //   en: { translation: en },
    // },
    fallbackLng: DEFAULT_LOCALE_CODE,
    // We do not use keys in form messages.welcome.
    keySeparator: false,
    interpolation: {
      // React already protects from xss.
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });
