import { ReactNode, forwardRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { CardTestIds } from "../../constants/testIds";
import { steps } from "../../style/theme";
import Glass from "../atoms/Glass";
import Scroll from "../atoms/Scroll";
import CloseButton from "./CloseButton";

const CloseWrapper = styled.div`
  position: absolute;
  top: max(var(--safe-margin-top), ${steps.spacing[5]});
  right: max(var(--safe-margin-right), ${steps.spacing[9]});
  background: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentWrapper = styled.div<{ $height?: string }>`
  position: absolute;
  left: 0px;
  right: 0px;
  height: ${(p) => p.$height};
  display: flex;
  flex-direction: row;
`;

const Padding = styled.div`
  padding: 6px;
  height: calc(100% - 12px);
  width: calc(100% - 12px);
`;

const NoPadding = styled.div`
  height: 100%;
  width: 100%;
`;

const TopWrapper = styled.div`
  position: relative;
  width: 45%;
  height: 100%;
  flex-shrink: 0;
`;
const BottomWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  left: max(var(--safe-margin-left), 0px);
  right: max(var(--safe-margin-right), 0px);
  bottom: max(var(--safe-margin-bottom), 0px);
  height: auto;
`;

export interface Props {
  floatingSection?: ReactNode;
  brightCloseButton?: boolean;
  topSection?: ReactNode;
  bottomSection?: ReactNode;
  closeSection?: ReactNode;
  onClose?: () => void;
  hideScrollButton?: boolean;
  noPadding?: boolean;
  showFade?: boolean;
  children?: ReactNode;
  testId?: string;
  style?: React.CSSProperties;
}

/** The fullscreen+landscape version of the card component. */
const CardFullLandscape = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      floatingSection,
      bottomSection,
      onClose,
      topSection,
      brightCloseButton,
      hideScrollButton,
      showFade,
      testId,
      closeSection,
      noPadding,
      style,
    },
    ref
  ) => {
    const [bottomElement, setBottomElement] = useState<HTMLDivElement | null>(
      null
    );
    const bottomHeight = bottomElement?.offsetHeight ?? 0;
    const computedHeight = `calc(100dvh - ${bottomHeight}px)`;
    const theme = useTheme();

    return (
      <Glass
        width="100vw"
        style={{
          position: "relative",
          ...style,
        }}
        height="100dvh"
        padding="0px"
        borderRadius={theme.radiusBig}
        blur="strong"
        ref={ref}
        testId={testId}
        overflow="hidden"
      >
        <ContentWrapper $height={computedHeight}>
          {Boolean(topSection) && <TopWrapper>{topSection}</TopWrapper>}
          <Scroll
            height={computedHeight}
            barPaddingTop={onClose ? 40 : 0}
            fadeTop={Boolean(topSection || floatingSection)}
            hideScrollToBottom={hideScrollButton}
            showFade={showFade}
          >
            {noPadding ? (
              <NoPadding>{children}</NoPadding>
            ) : (
              <Padding>{children}</Padding>
            )}
          </Scroll>
          {Boolean(onClose) && (
            <CloseWrapper>
              {closeSection}
              <CloseButton
                testId={CardTestIds.closeBtn}
                onClick={onClose}
                isGlass={brightCloseButton}
              />
            </CloseWrapper>
          )}
        </ContentWrapper>
        {Boolean(bottomSection) && (
          <BottomWrapper ref={setBottomElement}>{bottomSection}</BottomWrapper>
        )}
        {floatingSection && <div>{floatingSection}</div>}
      </Glass>
    );
  }
);

export default CardFullLandscape;
