import React from "react";
import { SvgProps } from "../../types/svg";

const PersonTeleportIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.99963C8.89688 1.99963 8 2.89495 8 3.99963C8 5.10432 8.89688 5.99963 10 5.99963C11.1031 5.99963 12 5.10432 12 3.99963C12 2.89495 11.1031 1.99963 10 1.99963ZM9 6.99963C7.89687 6.99963 7 7.89651 7 8.99963V10.4996C7 11.0528 7.44688 11.4996 8 11.4996H8.05625L8.4 14.609C8.45625 15.1153 8.88438 15.4996 9.39375 15.4996H10.6062C11.1156 15.4996 11.5438 15.1153 11.6 14.609L11.9438 11.4996H12C12.5531 11.4996 13 11.0528 13 10.4996V8.99963C13 7.89651 12.1031 6.99963 11 6.99963H9ZM6.13438 14.3191C6.54375 14.2441 6.78438 13.8535 6.7375 13.4472C6.6625 13.041 6.27187 12.7691 5.86562 12.8441C4.85125 13.0285 3.96094 13.2879 3.30375 13.6191C2.97625 13.7847 2.67 13.9847 2.43625 14.2316C2.19925 14.4847 2 14.8285 2 15.2504C2 15.9191 2.48312 16.3785 2.91 16.6566C3.36844 16.9566 3.9825 17.1972 4.67406 17.3879C6.06875 17.7722 7.95 18.0004 10 18.0004C12.05 18.0004 13.9312 17.7722 15.325 17.3879C16.0187 17.1972 16.6313 16.9566 17.0906 16.6566C17.5156 16.3785 18 15.9191 18 15.2504C18 14.8285 17.8 14.4847 17.5625 14.2316C17.3312 13.9847 17.025 13.7847 16.6969 13.6191C16.0375 13.2879 15.15 13.0285 14.1344 12.8441C13.7281 12.7691 13.3375 13.041 13.2625 13.4472C13.1875 13.8535 13.4563 14.2441 13.8656 14.3191C14.8094 14.4941 15.5438 14.7191 16.0219 14.9597C16.1219 15.0097 16.2031 15.0566 16.2719 15.1004C16.3844 15.1754 16.3844 15.3254 16.2719 15.4004C15.9969 15.5816 15.5469 15.7691 14.9281 15.941C13.6969 16.2535 11.9531 16.5004 10 16.5004C8.04688 16.5004 6.30312 16.2535 5.07281 15.941C4.4525 15.7691 4.00469 15.5816 3.72937 15.4004C3.61625 15.3254 3.61563 15.1754 3.72906 15.1004C3.79594 15.0566 3.87781 15.0097 3.97719 14.9597C4.45531 14.7191 5.19063 14.4941 6.13438 14.3191Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PersonTeleportIcon;
