import styled from "styled-components";
import Hide from "../../components-ui/atoms/Hide";
import { useStore } from "../../store/store";
import CinematicModePanelLogic from "./CinematicModePanelLogic";

/** The main purpose of the cinematic mode is to
 *  clear all UI (solo) and show just the video stream.
 *  It does have an optional "skip" button it can display.
 */

const Layout = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const CinematicModePanelLayout: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.cinematicView.visible);

  return (
    <Layout id="layout-cinematic-mode">
      <Hide width="100%" height="100%" hide={!visible}>
        <CinematicModePanelLogic />
      </Hide>
    </Layout>
  );
};

export default CinematicModePanelLayout;
