import { SvgProps } from "../../types/svg";

const VolumeOffIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.8438 9.97891C12.8438 10.3005 12.7278 10.6077 12.523 10.8634L12.8371 11.0969C13.0968 10.7758 13.2446 10.4078 13.2446 10C13.2446 9.44195 12.9746 8.91437 12.5042 8.55484C12.4181 8.48966 12.2913 8.50066 12.2224 8.58121C12.152 8.66104 12.1645 8.7797 12.2506 8.84488C12.6284 9.13281 12.8438 9.53359 12.8438 9.97891ZM8.99673 5.2C9.06685 5.15148 9.14951 5.125 9.21463 5.125C9.27283 5.125 9.32949 5.13663 9.38251 5.15938C9.54524 5.22039 9.63791 5.35398 9.63791 5.49977V8.73906L10.0386 9.03672V5.5C10.0386 5.20703 9.85095 4.93891 9.56678 4.81656C9.45935 4.77041 9.3508 4.74998 9.23717 4.74998C9.04942 4.74998 8.86348 4.81166 8.71571 4.93077L7.01557 6.76797L7.3294 7.00148L8.99673 5.2ZM15.2482 9.97891C15.2482 10.8107 14.9464 11.6088 14.4012 12.2603L14.715 12.4937C15.3159 11.7977 15.649 10.9188 15.649 10C15.649 8.77398 15.0556 7.61406 14.0202 6.82188C13.9365 6.7567 13.8089 6.76769 13.7385 6.84824C13.668 6.92807 13.6806 7.04601 13.7666 7.11191C14.7072 7.83437 15.2482 8.88672 15.2482 9.97891ZM9.63791 14.5C9.63791 14.6459 9.54634 14.7794 9.40475 14.8405C9.35238 14.8633 9.29477 14.875 9.23717 14.875C9.15008 14.875 9.06575 14.8484 8.99718 14.7998L6.3218 11.8539H2.82535C2.60434 11.8539 2.42461 11.6857 2.42461 11.4789V8.47891C2.42461 8.27214 2.60434 8.10391 2.82535 8.10391H3.01663L2.57589 7.79688C2.2573 7.89531 2.02387 8.16953 2.02387 8.47891V11.4789C2.02387 11.893 2.38279 12.2289 2.82535 12.2289H6.13645L8.69367 15.0484C8.86398 15.1891 9.04932 15.25 9.23717 15.25C9.35065 15.25 9.4596 15.2296 9.56678 15.1835C9.8508 15.0602 10.0386 14.793 10.0386 14.5V13.348L9.63791 13.0499V14.5ZM16.3528 15.6648L0.74627 4.04055C0.709703 4.01328 0.665647 4 0.621916 4C0.563033 4 0.504475 4.02407 0.464451 4.07059C0.395574 4.1519 0.410051 4.26981 0.496535 4.33427L16.1028 15.9593C16.1624 15.9859 16.205 16 16.2276 16C16.2863 16 16.345 15.9758 16.3845 15.9293C16.4755 15.8477 16.4605 15.7305 16.3528 15.6648Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default VolumeOffIcon;
