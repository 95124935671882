import { useEffect, useState } from "react";
import styled from "styled-components";
import { useIsDarkMode } from "../../../../api/gyaradosHooks";
import Button from "../../../../components-ui/atoms/Button";
import Hide from "../../../../components-ui/atoms/Hide";
import { defaultTheme } from "../../../../style/theme";
import { ReactionName } from "../../../actionBarPanel/lib/ReactionsTypes";
import { ReactionItem } from "./ReactionsBar";

// visually correct stage progress values
const STAGE_1_PROGRESS = 90;
const STAGE_2_PROGRESS = 165;
const STAGE_3_PROGRESS = 315;

const BUTTON_SCALE_MULTIPLIER = 0.0015;
const CLICK_SCALE_DIFFERENCE = 0.06;
const ICON_SCALE_DECAY = 0.03;
const INITIAL_ICON_SCALE = 0.7;

const DEFAULT_PROGRESS_PROPS = {
  width: "44",
  height: "44",
  xmlns: "http://www.w3.org/2000/svg",
  strokeWidth: "1px",
};

const Progress = styled.svg`
  position: absolute;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 0.3s;
`;

const ProgressWrapper = styled.div<{
  $scale: number;
  $clickScale: number;
  $isActive: boolean;
}>`
  position: absolute;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  scale: ${(p) => (p.$isActive ? p.$scale : 1)};

  &:active {
    scale: ${(p) => p.$clickScale};
  }

  transition: scale ${(p) => (p.$isActive ? "0.3s" : "1s")};
`;

const ProgressGapVertical = styled.div<{
  $backgroundColor: React.CSSProperties["color"];
}>`
  background-color: ${(p) => p.$backgroundColor};
  height: 40px;
  width: 4px;
  position: absolute;
  left: calc(50% - 2);
  z-index: 0;
`;

const ProgressGapHorizontal = styled.div<{
  $backgroundColor: React.CSSProperties["color"];
}>`
  background-color: ${(p) => p.$backgroundColor};
  height: 4px;
  width: 20px;
  position: absolute;
  left: 50%;
  z-index: 0;
`;

const ZoomIcon = styled.div<{
  $show: boolean;
  $scale: number;
  $stage?: number;
}>`
  scale: ${(p) => (p.$show ? p.$scale : 0)};
  position: absolute;
  transition: scale ${(p) => (p.$stage !== 3 ? "0.3s" : "1s")};
`;

const ButtonPadding = styled.div`
  padding: 2px;
`;

type Props = {
  data: ReactionItem;
  handleReaction: (reaction: ReactionName, stage: number) => void;
};

const EscalatingButton: React.FC<Props> = ({ data, handleReaction }) => {
  const isDarkMode = useIsDarkMode();
  const [progress, setProgress] = useState(0);
  const [stage, setStage] = useState(0);
  const [hold, setHold] = useState(false);
  const [stageReleased, setStageReleased] = useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const [isActive, setActive] = useState(false);
  const [iconScale, setIconScale] = useState(1);
  const colors = isDarkMode
    ? {
        button: defaultTheme.colorBelowRoot,
        progress: defaultTheme.colorAboveRoot,
        progressBackground: "#ccc",
      }
    : {
        button: defaultTheme.colorAboveRoot,
        progress: defaultTheme.colorBelowRoot,
        progressBackground: "#555",
      };

  const handleRelease = () => {
    setHold(false);
    let isEmojiReleased = false;

    const newStageReleased = [...stageReleased];
    const newProgress = progress + 26;
    setProgress(
      newProgress > STAGE_3_PROGRESS ? STAGE_3_PROGRESS + 1 : newProgress
    );
    if (stage === 1) setIconScale(iconScale + 0.2);
    if (stage === 2) setIconScale(iconScale + 0.1);

    // Release for click
    if (
      (stage === 0 && newProgress > STAGE_1_PROGRESS) ||
      (stage === 1 && newProgress > STAGE_2_PROGRESS) ||
      (stage === 2 && newProgress > STAGE_3_PROGRESS)
    ) {
      if (newStageReleased[stage] === false) {
        handleReaction(data.value, stage);
        newStageReleased[stage] = true;
        setStageReleased(newStageReleased);
        isEmojiReleased = true;
      }
    }

    // Release for hold
    if (
      !isEmojiReleased &&
      newProgress > STAGE_3_PROGRESS &&
      stageReleased[2] === false
    ) {
      handleReaction(data.value, 2);
      newStageReleased[2] = true;
      setStageReleased(newStageReleased);
    } else if (!isEmojiReleased && stageReleased[stage - 1] === false) {
      handleReaction(data.value, stage - 1);
      newStageReleased[stage - 1] = true;
      setStageReleased(newStageReleased);
    }
  };

  // decay logic
  useEffect(() => {
    const id = setInterval(() => {
      const newProgress = progress - ((isActive ? 1 : 100) + progress / 30);
      const newIconScale = iconScale - ICON_SCALE_DECAY;
      setProgress(newProgress < 0 ? 0 : newProgress);
      setIconScale(newIconScale > 1 ? newIconScale : 1);
    }, 100);

    return () => {
      clearInterval(id);
    };
  }, [iconScale, isActive, progress, stage]);

  // stage logic
  useEffect(() => {
    if (
      (stage === 0 && progress > STAGE_1_PROGRESS) ||
      (stage === 1 && progress > STAGE_2_PROGRESS) ||
      (stage === 2 && progress > STAGE_3_PROGRESS)
    ) {
      setStage((stage + 1) % 4);
      setIconScale(1);
    }

    if (progress < 5) {
      setStage(0);
      setStageReleased([false, false, false]);
    }
  }, [progress, stage]);

  // hold logic
  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;
    if (hold) {
      if (progress < 65) {
        setProgress(65);
      } else {
        id = setTimeout(() => {
          const newProgress = progress + 10;
          setProgress(
            newProgress > STAGE_3_PROGRESS ? STAGE_3_PROGRESS + 1 : newProgress
          );
          if (stage === 1) setIconScale(iconScale + 0.1);
          if (stage === 2) setIconScale(iconScale + 0.05);
        }, 100);
      }
    }

    return () => {
      clearTimeout(id);
    };
  }, [hold, iconScale, progress, stage]);

  return (
    <div
      style={{
        cursor: "pointer",
      }}
      onMouseDown={() => {
        setHold(true);
        setActive(true);
      }}
      onMouseLeave={() => {
        setHold(false);
        setActive(false);
      }}
      onMouseUp={handleRelease}
    >
      <Hide hide={progress < 5} speed={500}>
        <ProgressWrapper
          $scale={1 + progress * BUTTON_SCALE_MULTIPLIER}
          $clickScale={
            1 + progress * BUTTON_SCALE_MULTIPLIER - CLICK_SCALE_DIFFERENCE
          }
          $isActive={isActive}
        >
          {/* black outline */}
          <svg
            {...DEFAULT_PROGRESS_PROPS}
            stroke={colors.button}
            fill={colors.button}
          >
            <circle r={20} cx={22} cy={22} />
          </svg>

          {/* gray progress */}
          <Progress
            {...DEFAULT_PROGRESS_PROPS}
            stroke={colors.progressBackground}
            fill={colors.button}
            transform="rotate(-90)"
          >
            <circle r={18} cx={22} cy={22} />
          </Progress>

          {/* white progress */}
          <Progress
            {...DEFAULT_PROGRESS_PROPS}
            stroke={colors.progress}
            strokeWidth="1px"
            strokeDasharray="138"
            fill="none"
            strokeDashoffset={138 - (progress / 360) * 138 + 1}
          >
            <circle r={18} cx={22} cy={22} />
          </Progress>

          <ProgressGapHorizontal $backgroundColor={colors.button} />
          <ProgressGapVertical $backgroundColor={colors.button} />

          <ZoomIcon $show={stage === 0} $scale={1}>
            {data.icons.stage1}
          </ZoomIcon>
          <ZoomIcon $show={stage === 1} $scale={INITIAL_ICON_SCALE * iconScale}>
            {data.icons.stage2}
          </ZoomIcon>
          <ZoomIcon
            $show={stage === 2 || stage === 3}
            $scale={stage === 3 ? 1 : INITIAL_ICON_SCALE * iconScale}
            $stage={stage}
          >
            {data.icons.stage3}
          </ZoomIcon>
        </ProgressWrapper>
      </Hide>
      <Hide hide={progress > 5} speed={500}>
        <ButtonPadding>
          <Button.Subtle override={{ height: "40px", width: "40px" }} circular>
            {data.icons.anchor}
          </Button.Subtle>
        </ButtonPadding>
      </Hide>
    </div>
  );
};

export default EscalatingButton;
