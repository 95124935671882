import { CSSProperties, ReactNode, forwardRef } from "react";
import styled, { css } from "styled-components";

const Div = styled.div<{
  $width?: string;
  $height?: string;
  $minHeight?: string;
  $padding?: string;
  $color?: string;
  $borderRadius?: string;
  $border?: string;
  $blur?: "none" | "default" | "strong";
  $overflow?: string;
}>`
  position: relative;
  pointer-events: all;

  box-sizing: border-box;
  width: ${(p) => p.$width ?? "auto"};
  height: ${(p) => p.$height ?? "auto"};
  min-height: ${(p) => p.$minHeight ?? ""};
  background-color: ${(p) => p.$color ?? p.theme.colorBelow0};
  padding: ${(p) => p.$padding ?? "10px 20px"};
  border: ${(p) =>
    p.$border ?? `${p.theme.borderWidth} solid ${p.theme.borderColor}`};
  border-radius: ${(p) => p.$borderRadius ?? p.theme.radiusBig};
  overflow: ${(p) => p.$overflow ?? "unset"};
  ${(p) => {
    if (p.$blur !== "none") {
      const blur =
        p.$blur === "strong" ? p.theme.blurStrong : p.theme.blurLight;
      return css`
        backdrop-filter: blur(${blur});
      `;
    }
  }}

  transition: height 0.6s;
`;

export type PropsGlass = {
  width?: string;
  height?: string;
  minHeight?: string;
  padding?: string;
  color?: string;
  testId?: string;
  borderRadius?: string;
  border?: string;
  blur?: "none" | "default" | "strong";
  overflow?: CSSProperties["overflow"];
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

/** The backdrop material for panels. It implements the glass effect. */
const Glass = forwardRef<HTMLDivElement, PropsGlass>(
  ({ children, ...props }, ref) => {
    return (
      <Div
        ref={ref}
        data-testid={props.testId}
        $border={props.border}
        $borderRadius={props.borderRadius}
        $color={props.color}
        $height={props.height}
        $minHeight={props.minHeight}
        $width={props.width}
        $padding={props.padding}
        $blur={props.blur}
        $overflow={props.overflow}
        style={props.style}
        className={props.className}
      >
        {children}
      </Div>
    );
  }
);

Glass.displayName = "Glass";
export default Glass;
