import { SvgProps } from "../../types/svg";

const GearFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.457 7.20625C17.5596 7.475 17.4725 7.77812 17.2612 7.975L15.9153 9.20625C15.9495 9.46563 15.9681 9.73125 15.9681 10C15.9681 10.2688 15.9495 10.5344 15.9153 10.7937L17.2612 12.025C17.4725 12.2219 17.5596 12.525 17.457 12.7937C17.3202 13.1656 17.1555 13.525 16.969 13.8656L16.8229 14.1187C16.6178 14.4625 16.3877 14.7875 16.136 15.0969C15.9495 15.3188 15.648 15.3969 15.3744 15.3094L13.6431 14.7531C13.2266 15.075 12.7386 15.3438 12.2755 15.55L11.887 17.3344C11.8248 17.6156 11.6072 17.8156 11.3212 17.8906C10.8923 17.9625 10.4509 18 9.97225 18C9.54952 18 9.10815 17.9625 8.6792 17.8906C8.39324 17.8156 8.17566 17.6156 8.1135 17.3344L7.72496 15.55C7.23385 15.3438 6.77383 15.075 6.35732 14.7531L4.62725 15.3094C4.35248 15.3969 4.04942 15.3188 3.86541 15.0969C3.61333 14.7875 3.38331 14.4625 3.17817 14.1187L3.0327 13.8656C2.84403 13.525 2.67991 13.1656 2.54221 12.7937C2.4415 12.525 2.52636 12.2219 2.73959 12.025L4.08423 10.7937C4.05004 10.5344 4.03232 10.2688 4.03232 10C4.03232 9.73125 4.05004 9.46563 4.08423 9.20625L2.73959 7.975C2.52636 7.77812 2.4415 7.47813 2.54221 7.20625C2.67991 6.83438 2.84434 6.475 3.0327 6.13438L3.17786 5.88125C3.38331 5.5375 3.61333 5.2125 3.86541 4.90469C4.04942 4.68125 4.35248 4.60375 4.62725 4.69187L6.35732 5.24688C6.77383 4.92375 7.23385 4.655 7.72496 4.45094L8.1135 2.66656C8.17566 2.38281 8.39324 2.1575 8.6792 2.10969C9.10815 2.03753 9.54952 2 10.0002 2C10.4509 2 10.8923 2.03753 11.3212 2.10969C11.6072 2.1575 11.8248 2.38281 11.887 2.66656L12.2755 4.45094C12.7386 4.655 13.2266 4.92375 13.6431 5.24688L15.3744 4.69187C15.648 4.60375 15.9495 4.68125 16.136 4.90469C16.3877 5.2125 16.6178 5.5375 16.8229 5.88125L16.969 6.13438C17.1555 6.475 17.3202 6.83438 17.457 7.20625ZM10.0002 12.5C11.3741 12.5 12.4868 11.3813 12.4868 9.97188C12.4868 8.61875 11.3741 7.47188 10.0002 7.47188C8.62636 7.47188 7.5136 8.61875 7.5136 9.97188C7.5136 11.3813 8.62636 12.5 10.0002 12.5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default GearFilledIcon;
