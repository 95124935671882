import { SvgProps } from "../../types/svg";

const BustIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 6C8.61875 6 7.5 7.11875 7.5 8.5C7.5 9.88125 8.61875 11 10 11C11.3806 11 12.5 9.88062 12.5 8.5C12.5 7.11938 11.3813 6 10 6ZM10 10.5C8.89719 10.5 8 9.60281 8 8.5C8 7.39719 8.89719 6.5 10 6.5C11.1028 6.5 12 7.39719 12 8.5C12 9.60281 11.1031 10.5 10 10.5ZM10 2C5.58125 2 2 5.58125 2 10C2 14.4187 5.58125 18 10 18C14.4187 18 18 14.4187 18 10C18 5.58125 14.4187 2 10 2ZM10 17.5C8.31125 17.5 6.75625 16.9322 5.52813 15.9866C5.50938 14.0625 7.075 12.5 9 12.5H11C12.9253 12.5 14.4906 14.0631 14.4719 15.9875C13.2438 16.9313 11.6875 17.5 10 17.5ZM14.9594 15.6094C14.7625 13.5875 13.075 12 11 12H9C6.92438 12 5.2375 13.5869 5.04063 15.6094C3.48594 14.2344 2.5 12.2312 2.5 10C2.5 5.86562 5.86562 2.5 10 2.5C14.1344 2.5 17.5 5.86562 17.5 10C17.5 12.2312 16.5125 14.2344 14.9594 15.6094Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default BustIcon;
