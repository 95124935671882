import { useEffect, useState } from "react";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { sendGameMessage } from "../../../core/gameConnection/webrtc/webRtcMessageHandlers";
import { useText } from "../../../core/i18n/i18nHooks";
import { useStore } from "../../../store/store";
import { PropsLoginPageUi } from "../LoginPageUi";

const useStartActionCheckStep = (
  setLoginPageProps: (props: PropsLoginPageUi) => void,
  userEarlyClick?: boolean,
  // We check the startButton visible state in layoutSlice to determine if the game is ready to start.
  isStartAction?: boolean
) => {
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const {
    environment: { title, description },
  } = useEnvironmentContext();
  const t = useText();
  const [userClick, setUserClick] = useState(false);

  useEffect(() => {
    if (!userClick) return;
    if (currentStep !== "login:start-action") return;
    sendGameMessage({
      type: "OnStartAction",
    });
    window.analytics?.track("click", {
      type: "click",
      name: "enter",
    });
    // TODO: LEGACY
    sendGameMessage({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      type: "ExperienceCanStart",
    });
    window.analytics?.track("flow", {
      type: "flow",
      name: "interacted",
    });
    completeStep("login:start-action");
    window.dispatchEvent(new CustomEvent("play-experience-video"));
    window.dispatchEvent(new CustomEvent("play-experience-audio"));
  }, [completeStep, currentStep, userClick]);

  useEffect(() => {
    const mainButtonClickHandler = () => {
      setUserClick(true);
    };

    if (currentStep !== "login:start-action") return;
    if (userEarlyClick) {
      setLoginPageProps({
        isDismissed: true,
        isMainButtonVisible: isStartAction,
        onMainButtonClick: mainButtonClickHandler,
        isMainButtonFollowingMouse: isStartAction,
        mainButtonLabel: isStartAction
          ? t("login_default_specific_call_to_action")
          : "",
      });
    } else {
      setLoginPageProps({
        isMainButtonVisible: isStartAction,
        onMainButtonClick: mainButtonClickHandler,
        isMainButtonFollowingMouse: isStartAction,
        mainButtonLabel: isStartAction
          ? t("login_default_specific_call_to_action")
          : "",
        title: title || "",
        description: description || "",
      });
    }
  }, [
    currentStep,
    isStartAction,
    setUserClick,
    setLoginPageProps,
    t,
    userEarlyClick,
    title,
    description,
  ]);
};

export default useStartActionCheckStep;
