import { SvgProps } from "../../types/svg";

const SadFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10ZM7.34688 14.3625C8.85313 13.1563 10.7281 12.5 12.6562 12.5H13C13.275 12.5 13.5 12.275 13.5 12C13.5 11.725 13.275 11.5 13 11.5H12.6562C10.4719 11.5 8.40625 12.2344 6.72188 13.5813L6.6875 13.6094C6.47187 13.7812 6.40937 14.0969 6.60938 14.2844C6.78125 14.5281 7.09688 14.5625 7.28438 14.3906L7.34688 14.3625ZM7.5125 7.5C6.95937 7.5 6.5125 7.94687 6.5125 8.5C6.5125 9.05313 6.95937 9.5 7.5125 9.5C8.0625 9.5 8.5125 9.05313 8.5125 8.5C8.5125 7.94687 8.0625 7.5 7.5125 7.5ZM12.5125 9.5C13.0625 9.5 13.5125 9.05313 13.5125 8.5C13.5125 7.94687 13.0625 7.5 12.5125 7.5C11.9594 7.5 11.5125 7.94687 11.5125 8.5C11.5125 9.05313 11.9594 9.5 12.5125 9.5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default SadFilledIcon;
