export const roundDecimal = (num: number, decimals: number = 2) => {
  const d = Math.pow(10, decimals);
  return Math.round((num + Number.EPSILON) * d) / d;
};

export const clamp = (v: number, min: number, max: number) => {
  const realMin = Math.min(max, min);
  const realMax = Math.max(max, min);
  return Math.max(Math.min(v, realMax), realMin);
};

/** You pass a value in the range of low1-high1 and it returns the equivalent value in the remapped ranfe low2-hight2. */
export const remap = (
  value: number,
  low1: number,
  high1: number,
  low2: number,
  high2: number,
  clamp?: boolean
): number => {
  const remapped = low2 + ((value - low1) * (high2 - low2)) / (high1 - low1);
  return clamp ? Math.min(Math.max(remapped, low2), high2) : remapped;
};

export const randomInRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = String(time % 60).padStart(2, "0");
  return `${minutes}:${seconds}`;
};
