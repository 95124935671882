export const getFullName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`.trim();
};

export const alphabeticalSorting = (a: string, b: string) => {
  const textA = a.toUpperCase();
  const textB = b.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};

// Set the limit of characters to display in a string
export const clipString = (str: string, limit: number) => {
  return str.length > limit ? str.slice(0, limit) + "..." : str;
};
