import { styled } from "styled-components";
import Column from "../../../components-ui/atoms/Column";
import Glass from "../../../components-ui/atoms/Glass";
import Row from "../../../components-ui/atoms/Row";
import Scroll from "../../../components-ui/atoms/Scroll";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import CloseButton from "../../../components-ui/molecules/CloseButton";
import { FeatureFlag } from "../../../constants/configs";
import { useDevOptionsContext } from "../../../core/dev/DevOptionsProvider";

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 1rem;
`;

type Props = {
  onClose?: () => void;
};

/* eslint-disable i18next/no-literal-string */
const DevOptionsUi: React.FC<Props> = ({ onClose }) => {
  const { featureFlags, setFeatureFlags } = useDevOptionsContext();

  const onFeatureFlagChange = (featureFlag: FeatureFlag, value: boolean) => {
    setFeatureFlags({ [featureFlag]: value });
  };

  return (
    <Spatial dragMotion rim glare>
      <Glass>
        <CloseButtonWrapper>
          <CloseButton onClick={onClose} />
        </CloseButtonWrapper>
        <Space h={4} />
        <Row gap={2}>
          <Typo.Title>Dev Options</Typo.Title>
        </Row>
        <Space h={4} />
        <Row gap={2}>
          <Typo.Subtitle>Feature Flags</Typo.Subtitle>
        </Row>
        <Space h={2} />
        <Scroll maxHeight="200px" fadeTop={false}>
          <Column gap={4} align="stretch">
            {(Object.keys(featureFlags) as FeatureFlag[]).map((featureFlag) => {
              return (
                <Row key={featureFlag}>
                  <input
                    type="checkbox"
                    checked={featureFlags[featureFlag]}
                    onChange={(e) => {
                      onFeatureFlagChange(featureFlag, e.target.checked);
                    }}
                  />
                  <Space w={2} />
                  <label>{featureFlag}</label>
                </Row>
              );
            })}
          </Column>
        </Scroll>
      </Glass>
    </Spatial>
  );
};

export default DevOptionsUi;
