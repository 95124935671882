import React, { useEffect, useRef } from "react";
import { css, styled } from "styled-components";
import useResizeObserver from "use-resize-observer";
import FadeIn from "../../../../../components-ui/atoms/FadeIn";
import Space from "../../../../../components-ui/atoms/Space";
import { useText } from "../../../../../core/i18n/i18nHooks";
import { useIsLandscape, useIsSmallScreen } from "../../../../../hooks/ui";
import { useStore } from "../../../../../store/store";
import { steps } from "../../../../../style/theme";
import { getPlayerKey } from "../../socialUtils";
import TextChatMessage from "./TextChatMessage";
import TextChatTypingIndicator from "./TextChatTypingIndicator";
import {
  TextChatMessageGroup,
  TextChatMessage as TextChatMessageType,
} from "./textChatTypes";

const INITIAL_HEIGHT = 70;

const Wrapper = styled.div<{
  height: string;
  $isSmallscreenLandscape: boolean;
}>`
  height: ${(p) => p.height};
  ${(p) =>
    p.$isSmallscreenLandscape
      ? css`
          padding-right: 5%;
          padding-left: 5%;
          margin-right: max(var(--safe-margin-right), 64px);
          margin-left: max(var(--safe-margin-left), 64px);
        `
      : css`
          padding-right: 10px;
          padding-left: 10px;
        `};

  transition: height 200ms;
`;

const TextChatGroup = styled.div<{ $isSelf: boolean }>`
  display: flex;
  flex-direction: column;
  direction: ${(p) => (p.$isSelf ? "rtl" : "ltr")};
  font-family: ${(p) => p.theme.fontMain};
  font-size: ${steps.font.f20.size};
  margin-bottom: ${steps.spacing[3]};
`;

const SenderName = styled.p`
  margin: 0;
  cursor: pointer;
  margin-right: ${steps.spacing[2]};
  margin-left: ${steps.spacing[2]};
  color: ${(p) => p.theme.colorAbove2};
`;

type Props = {
  fixedFirstMessagesGroup?: TextChatMessageGroup[];
  groupedMessageThread?: TextChatMessageGroup[];
  deletedMessages?: Set<string>;
  isModerationOn?: boolean;
  isTyping?: boolean;
  onDelete?: (message: TextChatMessageType) => void;
};

const TextChatThread: React.FC<Props> = ({
  groupedMessageThread = [],
  fixedFirstMessagesGroup = [],
  deletedMessages = new Set<string>(),
  isModerationOn,
  isTyping,
  onDelete,
}) => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const activeChannel = useStore((s) => s.textChat.activeChannel);
  const isLandscape = useIsLandscape();
  const isSmallScreen = useIsSmallScreen();
  const endRef = useRef<HTMLDivElement>(null);
  const { ref: threadRef, height: threadRefHeight } =
    useResizeObserver<HTMLDivElement>();
  const t = useText();
  const playerId = useStore((s) => s.gameConnection.playerId);
  const roomId = useStore((s) => s.gameConnection.roomId);

  const onPlayerNameClick = (playerId: number) => {
    if (!roomId) return;
    const externalId = getPlayerKey({
      roomId,
      playerId,
    });
    openPanel(`social/playerProfile/${externalId}`);
  };

  // Scroll to bottom when chat panel opens
  useEffect(() => {
    // Add some delay so it scrolls properly
    setTimeout(() => {
      endRef.current?.scrollIntoView({ block: "nearest" });
    }, 20);
  }, []);

  // Scroll to bottom when there is a new message
  useEffect(() => {
    // Add some delay so it scrolls properly
    setTimeout(() => {
      endRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, 120);
  }, [threadRefHeight, isTyping]);

  return (
    <Wrapper
      // Set the height so it animates when there is no scroll yet
      height={(threadRefHeight ?? INITIAL_HEIGHT) + "px"}
      $isSmallscreenLandscape={isLandscape && isSmallScreen}
    >
      <FadeIn>
        <div ref={threadRef}>
          {fixedFirstMessagesGroup?.map((groupedThread) => {
            const groupTimeStamp = groupedThread.timestamp;
            const fixedFirstMessages = groupedThread.messages;
            const isSelf =
              groupedThread.senderId === playerId &&
              (groupedThread.roomId ? groupedThread.roomId === roomId : true);

            return (
              <TextChatGroup $isSelf={isSelf} key={groupTimeStamp}>
                {fixedFirstMessages?.map((m) => (
                  <TextChatMessage message={m} key={`fixed_${m.timestamp}`} />
                ))}
              </TextChatGroup>
            );
          })}

          {groupedMessageThread?.map((groupedThread) => {
            const groupTimeStamp = groupedThread.timestamp;
            const sender = groupedThread.sender || t("chat_anonymous");
            const isSelf =
              groupedThread.senderId === playerId &&
              (groupedThread.roomId ? groupedThread.roomId === roomId : true);

            return (
              <TextChatGroup $isSelf={isSelf} key={groupTimeStamp}>
                <SenderName
                  data-private
                  onClick={() => onPlayerNameClick(groupedThread.senderId)}
                >
                  {isSelf ? t("chat_me") : sender}
                  {" " + formatToHHMM(groupedThread.timestamp)}
                </SenderName>
                <Space h={1} />
                {groupedThread.messages.map((m) => {
                  const idNotNull = m.messageId != null;
                  const idNotUndefined = m.messageId !== undefined;
                  return (
                    <TextChatMessage
                      key={m.timestamp}
                      message={m}
                      withModeration={idNotNull && isModerationOn}
                      isDeleted={
                        idNotUndefined &&
                        deletedMessages.has(`${m.senderId}_${m.messageId}`)
                      }
                      onDelete={() => onDelete?.(m)}
                    />
                  );
                })}
              </TextChatGroup>
            );
          })}

          {isTyping && (
            <TextChatTypingIndicator activeChannel={activeChannel} />
          )}
          <Space h={1} />
        </div>
        <div id="end-ref" ref={endRef} />
      </FadeIn>
    </Wrapper>
  );
};

function formatToHHMM(timestamp: number): string {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export default TextChatThread;
