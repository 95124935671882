import { styled } from "styled-components";
import { useText } from "../../../core/i18n/i18nHooks";
import { steps } from "../../../style/theme";

const TAB_DATA = [
  {
    value: "photo",
    label: "media_capture_tab_photo",
  },
  {
    value: "videoCapture",
    label: "media_capture_tab_video",
  },
] as const;

const Tabs = styled.div`
  padding: 0px 16px;
  height: 36px;
  width: 117px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-shadow: -8px 16px 32px ${(p) => p.theme.colorShadow};
  border-radius: ${(p) => p.theme.radiusFull};
  background-color: ${(p) => p.theme.colorBelow2};
  pointer-events: all;
`;

const Tab = styled.div`
  cursor: pointer;
  text-align: center;
  height: 20px;
  font-family: ${(p) => p.theme.fontMain};
  color: ${(p) => p.theme.colorAbove2};
  font-size: ${steps.font.f20.size};
  font-weight: ${steps.fontWeight.semiBold};
  line-height: ${steps.font.f20.lineHight};
  &.active {
    color: ${(p) => p.theme.colorAbove5};
  }
`;

export type Props = {
  onClick: () => void;
  currentTab?: string;
};

const MediaTabs: React.FC<Props> = ({ onClick, currentTab }) => {
  const t = useText();

  return (
    <Tabs>
      {TAB_DATA.map((it) => (
        <Tab
          key={it.value}
          onClick={() => it.value !== currentTab && onClick()}
          className={it.value === currentTab ? "active" : undefined}
        >
          {t(it.label)}
        </Tab>
      ))}
    </Tabs>
  );
};

export default MediaTabs;
