/* eslint-disable @typescript-eslint/no-explicit-any */
import { readOsInformation } from "./os";

export const readBrowserInformation = () => {
  const os = readOsInformation();
  const userAgent = window.navigator.userAgent;
  const w = window as any;

  const isChromeAgent = /Chrome/i.test(userAgent);
  const isChromeIosAgent = Boolean(userAgent.match(/CriOS/i));
  // Webkit (Safari macOS, Safari iOS, iOS WebViews)
  const isWebkitAgent = Boolean(userAgent.match(/WebKit/i));
  const isFirefoxIosAgent = Boolean(userAgent.match(/FxiOS/i));
  const isEdgeAgent = /Edge/i.test(userAgent);
  const isOperaAgent = userAgent.indexOf(" OPR/") >= 0;
  const isOperaIosAgent = Boolean(userAgent.match(/OPiOS/i));
  const isFacebookAgent = Boolean(
    /FBAN/.test(userAgent) || /FBAV/.test(userAgent)
  );
  const isLinkedinAgent = Boolean(/LinkedIn/.test(userAgent));
  const isInstagramAgent = Boolean(/Instagram/.test(userAgent));
  const isEdgeIosAgent = Boolean(userAgent.match(/EdgiOS/i));
  const isBraveAgent = Boolean(w.navigator.brave);

  const isChomeInjectedInWindow = Boolean(
    w.chrome && (w.chrome.webstore || w.chrome.runtime)
  );
  const isOperaInjectedInWindow = Boolean(w.opr?.addons || w.opera); // Opera 8.0+
  const hasFirefoxTraces = typeof w.InstallTrigger !== "undefined";

  const isChrome = isChromeAgent || isChomeInjectedInWindow;
  const isChromeIos = os.isIos && isWebkitAgent && isChromeIosAgent;
  const isOpera = isOperaInjectedInWindow || isOperaAgent;
  const isFirefox = hasFirefoxTraces;
  const isEdge = isEdgeAgent;
  const isWebkit = isWebkitAgent;

  const isSafari =
    !os.isIos &&
    !os.isIpad &&
    isWebkit &&
    !isChromeAgent &&
    !isChromeIosAgent &&
    !isFacebookAgent &&
    !isEdgeIosAgent &&
    !isBraveAgent &&
    !isFirefoxIosAgent &&
    !isOperaIosAgent;

  const isSafariIos =
    os.isIos &&
    isWebkit &&
    !isChromeIosAgent &&
    !isFacebookAgent &&
    !isEdgeIosAgent &&
    !isBraveAgent &&
    !isFirefoxIosAgent &&
    !isOperaIosAgent;

  const isSocialMediaBrowser =
    isInstagramAgent || isFacebookAgent || isLinkedinAgent;

  const checkIsIFrame = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };
  const isIFrame = checkIsIFrame();

  return {
    isChrome,
    isChromeIos,
    isOpera,
    isFirefox,
    isEdge,
    isWebkit,
    isSafariIos,
    isSafari,
    isSocialMediaBrowser,
    isInstagramAgent,
    isIFrame,
  };
};
