import CloseButton from "../../../components-ui/molecules/CloseButton";
import CaptureButton from "../components/CaptureButton";
import CommonMediaCaptureUi from "../components/CommonMediaCaptureUi";
import MediaTabs from "../components/MediaTabs";
import {
  AbsoluteOverlay,
  CameraFrameBorders,
  CaptureButtonWrapper,
  ReactionsWrapper,
  TabsWrapper,
  TopRightButton,
} from "../components/Wrappers";
import { Props } from "./PhotoCapture.ui";

const PhotoCaptureUiNormal: React.FC<Props> = ({
  onCapture,
  reactions,
  error,
  logoUrl,
  nrOfReactions,
  onClose,
  onChangeTab,
  hideUi = false,
  hideTabs,
}) => {
  return (
    <AbsoluteOverlay>
      <CommonMediaCaptureUi
        error={error}
        logoUrl={logoUrl}
        hideUi={hideUi}
        hideTabs={hideTabs}
      />
      <CameraFrameBorders />
      {!hideTabs && (
        <TabsWrapper>
          <MediaTabs currentTab={"photo"} onClick={onChangeTab} />
        </TabsWrapper>
      )}
      {Boolean(nrOfReactions) && (
        <ReactionsWrapper $isLandscape>{reactions}</ReactionsWrapper>
      )}
      <CaptureButtonWrapper>
        <CaptureButton
          onClick={onCapture}
          size="80px"
          mode={hideUi ? "video" : "photo"}
        />
      </CaptureButtonWrapper>
      <TopRightButton>
        <CloseButton onClick={onClose} isGlass size="48px" />
      </TopRightButton>
    </AbsoluteOverlay>
  );
};

export default PhotoCaptureUiNormal;
