import { SvgProps } from "../../types/svg";

const ChatFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0006 3.97187V12.9438C18.0006 14.0453 17.1022 14.9156 16.0006 14.9156H11.5006L7.5975 17.8431C7.35141 18.0228 7 17.8447 7 17.54V14.9156H4C2.89844 14.9156 2 14.0172 2 12.9438V3.97187C2 2.87031 2.89844 2 4 2H16C17.1287 2 18.0006 2.89844 18.0006 3.97187Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ChatFilledIcon;
