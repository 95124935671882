import Button from "../../../../components-ui/atoms/Button";
import Icon, { IconName } from "../../../../components-ui/atoms/Icon";
import Tooltip from "../../../../components-ui/molecules/Tooltip";

export type ShareButtonData = {
  name: string;
  iconName: IconName;
  disabled?: boolean;
  onClick?: () => void | Promise<void>;
  size?: string;
  loading?: boolean;
};

/** A minimal icon button to quickly share the captured photo. */
const ShareButton: React.FC<ShareButtonData> = ({
  name,
  iconName,
  disabled,
  onClick,
  size = "48px",
  loading,
}) => {
  const CurrentIcon = Icon[iconName];

  return (
    <Tooltip key={name} label={name}>
      <Button.Bright
        circular
        disabled={disabled}
        onClick={onClick}
        override={{
          width: size,
          height: size,
          style: loading
            ? {
                cursor: "wait",
              }
            : undefined,
        }}
      >
        <CurrentIcon />
      </Button.Bright>
    </Tooltip>
  );
};

export default ShareButton;
