import { FromGameMessage } from "../../messages/fromGameMessages";
import { ToGameMessage } from "../../messages/toGameMessages";

const fullySanitizedAction = {
  type: "FullySanitized",
  info: "This App message has been fully sanitized.",
} as const;

/**
 * The goal of this function is to remove PII (Personally Identifiable Information)
 * from known objects, so that they can be safely sent to third-parties.
 * Randomly generated user IDs are considered acceptable here (not PII) because
 * third-parties would require information only Journee has to tie it to a
 * physical person.
 *
 * @param action A redux action or stream app message to be sanitized.
 * @returns The action immutably sanitized from PII and tokens, or null
 *          if the action only contains sensitive data.
 */
export const sanitizeGameMessage = (
  message: FromGameMessage | ToGameMessage
) => {
  // if (ACTION_IGNORE_LIST.includes(action.type)) return null;

  switch (message.type) {
    // case "ShowBusinessCard":
    //   return fullySanitizedAction;

    case "NearbyPlayers":
      return {
        ...message,
        players: message.players.map((player) => {
          return {
            ...player,
            name: "**redacted**",
          };
        }),
      } as typeof message;

    case "ReceivedChatMessage":
      return {
        ...message,
        content: "**redacted**",
        sender: "**redacted**",
      } as typeof message;

    case "SendChatMessage":
      return {
        ...message,
        content: "**redacted**",
      } as typeof message;

    case "BusinessCard":
      return fullySanitizedAction;

    default:
      return message;
  }
};
