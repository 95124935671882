import { styled } from "styled-components";
import Space from "../../../components-ui/atoms/Space";
import CardPartialPortrait from "../../../components-ui/molecules/CardPartialPortrait";
import { steps } from "../../../style/theme";
import { Props } from "./Poll.ui";
import useGetPollElements from "./hooks/useGetPollElements";

const PollContainer = styled.div`
  padding: 0 ${steps.spacing[6]};
`;

const PollUiPortait: React.FC<Props> = ({
  handleDismiss,
  poll,
  testId,
  ...rest
}) => {
  const { Header, Body, Button, Title, Subtitle, ErrorMessage } =
    useGetPollElements({ poll, handleDismiss, ...rest });
  return (
    <CardPartialPortrait
      testId={testId}
      padding="0px"
      topSection={
        <PollContainer>
          <Space h={8} />
          {Header}
          <Space h={4} />
          {Title}
        </PollContainer>
      }
      onClose={handleDismiss}
    >
      <PollContainer>
        <Space h={5} />
        {Subtitle}
        {Body}
        {Button}
        <Space h={2} />
        {ErrorMessage}
        <Space h={8} />
      </PollContainer>
    </CardPartialPortrait>
  );
};

export default PollUiPortait;
