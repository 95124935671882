import styled from "styled-components";
import { getImageUrl } from "../../../api/gyaradosUtils";
import CardContentAlignment from "../../../components-ui/atoms/CardContentAlignment";
import CardImage from "../../../components-ui/atoms/CardImage";
import CardFullLandscape from "../../../components-ui/molecules/CardFullLandscape";
import { Props } from "./Popup.ui";
import PopupContent from "./component/PopupContent";

const Body = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    text-align: start;
    min-inline-size: 0;
  }
  height: 100%;
  padding-top: 64px;
  padding-bottom: 38px;
  padding-left: 32px;
  padding-right: max(var(--safe-margin-right), 64px);
`;

const PopupPanelUi: React.FC<Props> = ({
  onClose,
  popup,
  handleExternalLink,
  handleTeleport,
  extraText,
}) => {
  const popupImage = popup?.image;

  return (
    <CardFullLandscape
      onClose={popup?.dismissible ? onClose : undefined}
      topSection={
        <CardImage
          height="100%"
          width="100%"
          src={getImageUrl(popupImage, 700, 700)}
          orientation="landscape"
        />
      }
    >
      <Body>
        <CardContentAlignment>
          <PopupContent
            onClose={onClose}
            popup={popup}
            handleExternalLink={handleExternalLink}
            handleTeleport={handleTeleport}
            extraText={extraText}
          />
        </CardContentAlignment>
      </Body>
    </CardFullLandscape>
  );
};

export default PopupPanelUi;
