import CardFullPortrait from "../../../components-ui/molecules/CardFullPortrait";
import { isColorBright } from "../../../lib/color";
import { Props, generateGradientFromColor } from "./Social.ui";

const SocialUiPortrait: React.FC<Props> = ({ onClose, tabs, selectedTab }) => {
  return (
    <CardFullPortrait
      floatingSection={tabs}
      bottomSection={selectedTab.drawer}
      onClose={onClose}
      hideScrollButton
      noPadding
      style={{
        background: generateGradientFromColor(selectedTab.backgroundColor),
      }}
      showFade={selectedTab.showFade}
      brightCloseButton={
        selectedTab.backgroundColor
          ? !isColorBright(selectedTab.backgroundColor)
          : false
      }
    >
      {selectedTab.content}
    </CardFullPortrait>
  );
};

export default SocialUiPortrait;
