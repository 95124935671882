import { SvgProps } from "../../types/svg";

const CrossIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.7638 17.7655C17.4512 18.0782 16.9454 18.0782 16.6324 17.7655L9.99987 11.1307L3.3658 17.7655C3.05319 18.0782 2.54741 18.0782 2.23445 17.7655C1.92185 17.4529 1.92185 16.947 2.23445 16.6341L8.87003 10.0008L2.23545 3.36595C1.92285 3.05331 1.92285 2.54747 2.23545 2.23448C2.54806 1.92184 3.05384 1.92184 3.3668 2.23448L9.99987 8.87078L16.6339 2.23598C16.9465 1.92334 17.4523 1.92334 17.7653 2.23598C18.0779 2.54862 18.0779 3.05446 17.7653 3.36745L11.1297 10.0008L17.7638 16.6356C18.0787 16.9455 18.0787 17.4555 17.7638 17.7655Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CrossIcon;
