import styled, { useTheme } from "styled-components";
import { DynamicButtonTestIds } from "../../constants/testIds";
import Clickable from "../atoms/Clickable";

const AnchorWrapper = styled.div`
  position: relative;
  color: inherit;
`;

export type DynamicButtonProps = {
  focused?: boolean;
  testId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  size?: React.CSSProperties["width"];
  disabled?: boolean;
};

const DynamicButton: React.FC<DynamicButtonProps> = ({
  focused = true,
  testId,
  onClick,
  children,
  style,
  size = "48px",
  disabled = false,
}) => {
  const theme = useTheme();
  /* BEGIN OF HACK */
  return (
    <Clickable
      css={{
        zIndex: 3,
        transitionTimingFunction: "ease-in-out",
        ...style,
      }}
      excite={{
        clickResize: "0.98",
        hoverResize: "1.05",
        clickSpeedMs: 200,
      }}
      transitionMs={400}
      borderRadius="50%"
      width={size}
      height={size}
      color={focused ? theme.colorAbove4 : theme.colorAboveNoBackground}
      backgroundColor={focused ? theme.colorBelow0 : "transparent"}
      hoverBackground={theme.colorBelow1}
      hoverColor={theme.colorAbove5}
      onClick={onClick}
      disabled={disabled}
      testId={testId ?? DynamicButtonTestIds.wrapper}
      backdropFilter={focused ? `blur(${theme.blurLight})` : undefined}
    >
      <AnchorWrapper>{children}</AnchorWrapper>
    </Clickable>
  );
};

export default DynamicButton;
