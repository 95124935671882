import { SvgProps } from "../../types/svg";

const MapIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6667 16.0184L7.33333 14.5172V3.98252L12.6667 5.4826V16.0184ZM13.5556 5.41643L17.0861 4.02627C17.525 3.854 18 4.17146 18 4.63576V13.7899C18 14.0579 17.8333 14.2985 17.5806 14.3997L13.5556 15.9829V5.41643ZM2.41917 5.60127L6.44444 4.01643V14.5829L2.91417 15.9747C2.47639 16.1469 2 15.8297 2 15.3649V6.21021C2 5.94225 2.16597 5.70107 2.41917 5.60127Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MapIcon;
