import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import InputHint from "./InputHint";
import { GenericInputStyles } from "./RadioButton";
import Typo from "./Typo";

const CheckboxInput = styled.input`
  ${GenericInputStyles};
  border-radius: ${(p) => p.theme.radiusTiny};
  border: 1px solid ${(p) => p.theme.colorAbove1};

  &::before {
    content: "";
    inline-size: 14px;
    block-size: 16px;
    border-radius: ${(p) => p.theme.radiusTiny};
    transform: scale(0);
    transition: 150ms transform ease-in-out;
  }

  &:checked {
    border: 1px solid ${(p) => p.theme.colorBelow0};
    color: ${(p) => p.theme.colorBelow1};
    &::before {
      background-color: ${(p) => p.theme.colorBelow2};
      mask-mode: alpha;
      mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIDEiIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yNjc1XzEwMzYyKSI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xMy44NTMxIDMuMTQ2NzJDMTQuMDUgMy4zNDA0NyAxNC4wNSAzLjY1OTIyIDEzLjg1MzEgMy44NTI5N0w1LjM1MzEzIDEyLjM1M0M1LjE1OTM4IDEyLjU0OTggNC44NDA2MiAxMi41NDk4IDQuNjQ2ODcgMTIuMzUzTDAuMTQ2NDM3IDcuODUyOTdDLTAuMDQ4ODEyNSA3LjY1OTIyIC0wLjA0ODgxMjUgNy4zNDA0NyAwLjE0NjQzNyA3LjE0NjcyQzAuMzQxNTYzIDYuOTQ5ODQgMC42NTgxMjUgNi45NDk4NCAwLjg1MzQzNyA3LjE0NjcyTDUgMTEuMjkzNkwxMy4xNDY5IDMuMTQ2NzJDMTMuMzQwNiAyLjk1MTA5IDEzLjY1OTQgMi45NTEwOSAxMy44NTMxIDMuMTQ2NzJaIiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjk1Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjY3NV8xMDM2MiI+CjxyZWN0IHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K);
      transform: scale(1);
    }
  }
`;

export type CheckboxProps = {
  name: string;
  label: string;
  checked: boolean;
  onChange?: (params: { name: string; checked: boolean }) => void;
  width?: React.CSSProperties["width"];
  style?: React.CSSProperties;
  value?: string;
  testId?: string;
  disabled?: boolean;
  hint?: string;
};

// HACK: `transform: translateZ(0);` is used to fix a rendering issue
// where the checkbox is placed behind the form and not clickable.
const Wrapper = styled.div<{ $width?: React.CSSProperties["width"] }>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p.$width || "auto"};
  transform: translateZ(0);
`;

export const CheckboxWrapper = styled.div<{
  $checked: boolean;
  $disabled?: boolean;
  $width?: React.CSSProperties["width"];
}>`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: ${(p) => p.theme.radiusTiny};
  transition: 300ms background-color ease;
  transition-property: background-color, color, box-shadow;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  vertical-align: middle;
  width: ${(p) => p.$width ?? "auto"};
  ${(p) =>
    p.$checked &&
    css`
      box-shadow: -16px 12px 24px 0px ${(p) => p.theme.colorShadow};
    `};
  background-color: ${(p) =>
    p.$checked ? p.theme.colorAbove5 : p.theme.colorAbove0};
  span {
    color: ${(p) => (p.$checked ? p.theme.colorBelow2 : p.theme.colorAbove3)};
  }
  .noTouch &:hover {
    span {
      color: ${(p) => (p.$checked ? p.theme.colorBelow2 : p.theme.colorAbove4)};
    }
    background-color: ${(p) =>
      p.$checked ? p.theme.colorAbove4 : p.theme.colorAbove1};
    ${CheckboxInput}:not(:checked) {
      border: 1px solid ${(p) => p.theme.colorAbove2};
    }
  }
  ${(p) =>
    p.$disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      transition: opacity 0.25s;
    `};
`;

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  name,
  value,
  onChange,
  style,
  width,
  disabled,
  testId,
  hint,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({ name, checked: e.target.checked });
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const _onClick = () => {
    setIsChecked((prev) => !prev);
    onChange?.({ name, checked: !isChecked });
  };

  return (
    <Wrapper $width={width}>
      <CheckboxWrapper
        $disabled={disabled}
        data-testid={testId}
        $checked={isChecked}
        onClick={_onClick}
        style={style}
      >
        <CheckboxInput
          type="checkbox"
          value={value ?? label}
          name={name}
          checked={isChecked}
          onChange={_onChange}
        />
        <Typo.Label>{label}</Typo.Label>
      </CheckboxWrapper>
      {hint && <InputHint hint={hint} />}
    </Wrapper>
  );
};

export default Checkbox;
