import React from "react";
import { SvgProps } from "../../types/svg";

const PersonSlidingFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.3969 9.8C16.5805 9.93281 16.7866 9.99978 16.9975 9.99978C17.3338 9.99978 17.9991 9.73716 17.9991 9.00072C17.9991 8.69634 17.8609 8.39572 17.5991 8.19916L14.1147 5.59041C13.685 5.27009 13.2072 5.01478 12.6953 4.84697L10.7059 4.18353L9.88969 2.55478C9.71503 2.20572 9.36156 2.00353 8.995 2.00353C8.43875 2.00353 7.99531 2.45572 7.99531 3.00384C7.99531 3.15394 8.02925 3.30616 8.10075 3.44916L9.01075 5.26541C9.19044 5.62103 9.49669 5.88572 9.87544 6.01478L10.3895 6.18734L9.10313 6.83125C8.42188 7.17188 7.99687 7.85625 7.99687 8.61875V10.2781L5.68125 11.05C5.2625 11.1906 4.99844 11.5781 4.99844 11.9969C4.99844 12.3734 5.18606 12.5922 5.32125 12.7184L4.1125 12.2766C3.28844 11.9813 3.53469 11.2188 2.74906 11.2188C2.16562 11.2188 2 11.755 2 11.9716C2 12.2388 2.4275 13.2628 3.59781 13.6866L14.9759 17.8366C15.3031 17.9437 15.6156 18 15.9281 18C16.3188 18 16.7084 17.9146 17.0678 17.7428C17.3404 17.6178 17.5006 17.3456 17.5006 17.0624C17.5006 16.479 16.9647 16.3137 16.7487 16.3137C16.6433 16.3137 16.5384 16.3371 16.4363 16.3839C16.2766 16.4616 16.1038 16.4996 15.9298 16.4996C15.7888 16.4996 15.647 16.4747 15.5107 16.4257L12.6769 15.3907C12.9465 15.2618 13.1622 15.0233 13.2326 14.7067L13.9163 11.5473C13.9385 11.4431 13.9491 11.3389 13.9491 11.2348C13.9491 10.7689 13.7333 10.3189 13.3501 10.0348L11.7247 8.81606L13.7876 7.86294L16.3969 9.8ZM11.8844 11.4156L11.27 14.2906C11.2569 14.353 11.2515 14.4149 11.2515 14.4753C11.2515 14.6306 11.291 14.7782 11.3556 14.9132L6.05877 12.9916C6.1408 12.9838 6.2304 12.9777 6.31243 12.9503L8.96868 12.0635C9.58212 11.8603 9.99274 11.2907 9.99274 10.6422V10.0007L11.8844 11.4156ZM15.4969 4.99969C16.325 4.99969 16.9969 4.32781 16.9969 3.52781C16.9969 2.72781 16.325 2 15.4969 2C14.6687 2 13.9969 2.67187 13.9969 3.47187C13.9969 4.27187 14.6687 4.99969 15.4969 4.99969Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PersonSlidingFilledIcon;
