import { IconName } from "../../../components-ui/atoms/Icon";
import { ReactionName } from "./ReactionsTypes";

export const Reactions: Record<
  ReactionName,
  {
    emojis: IconName[];
    position: number;
  }
> = {
  surprised: {
    emojis: ["SurprisedFilled", "SurprisedHandsFilled", "MindBlownFilled"],
    position: 5,
  },
  sad: {
    emojis: ["SadFilled", "SadTearFilled", "SadCryFilled"],
    position: 4,
  },
  laughing: {
    emojis: ["LaughingFilled", "LaughingTearsFilled", "LaughingRollingFilled"],
    position: 3,
  },
  celebrate: {
    emojis: ["PartyPopperFilled", "RocketFilled", "ShootingStarFilled"],
    position: 2,
  },
  love: {
    emojis: ["HeartFilled", "HeartStarsFilled", "HeartsFilled"],
    position: 1,
  },
  thumbsUp: {
    emojis: ["ThumbsUpFilled", "OneHundredFilled", "FireFilled"],
    position: 0,
  },
};
