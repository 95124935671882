import styled from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Icon from "../../../components-ui/atoms/Icon";
import Row from "../../../components-ui/atoms/Row";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import CardFullPortrait from "../../../components-ui/molecules/CardFullPortrait";
import { PanelTestIds } from "../../../constants/testIds";
import { Props } from "./Settings.ui";
import BottomBar from "./component/BottomBar";

const TitleWrapper = styled.div`
  padding-left: max(var(--safe-margin-left), 24px);
  padding-right: max(var(--safe-margin-right), 24px);
  padding-bottom: 0;
  padding-top: max(var(--safe-margin-top), 32px);
`;

const SettingsUiPortrait: React.FC<Props> = ({
  children,
  bottomBarChildren,
  onClose,
  onPrevious,
  title,
  links,
  locales,
  selectedLocaleCode,
  onShowStats,
  openLanguagePanel,
}) => {
  const content = (
    <>
      <TitleWrapper>
        <Row gap={2}>
          {onPrevious && (
            <Button.Tertiary onClick={onPrevious} circular>
              <Icon.Chevron size="1rem" rotate="-90deg" shiftY="-0.1rem" />
            </Button.Tertiary>
          )}
          <Typo.Title>{title}</Typo.Title>
        </Row>
      </TitleWrapper>
      <Space h={6} />
      {children}
    </>
  );

  return (
    <CardFullPortrait
      hideScrollButton
      testId={PanelTestIds.settings.portrait.root}
      noPadding
      onClose={onClose}
      bottomSection={
        <BottomBar
          openLanguagePanel={openLanguagePanel}
          locales={locales}
          selectedLocaleCode={selectedLocaleCode}
          links={links}
          onShowStats={onShowStats}
        >
          {bottomBarChildren}
        </BottomBar>
      }
    >
      {content}
    </CardFullPortrait>
  );
};

export default SettingsUiPortrait;
