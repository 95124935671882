import styled from "styled-components";
import Button from "../../../../components-ui/atoms/Button";
import Icon from "../../../../components-ui/atoms/Icon";
import Space from "../../../../components-ui/atoms/Space";
import { SettingName } from "../subpages/home/SettingsHomePage.ui";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 32px;
  flex-shrink: 0;
  background-color: ${(p) => p.theme.colorAbove1};
`;

type Props = {
  handleSettingClick: (setting: SettingName) => void;
  isMuted: boolean;
};

const VolumeFullScreenTogglesLandscape: React.FC<Props> = ({
  handleSettingClick,
  isMuted,
}) => {
  return (
    <Wrapper>
      <Button.Secondary
        onClick={() => handleSettingClick("volume")}
        circular
        override={{
          width: "32px",
          height: "32px",
        }}
      >
        {isMuted ? <Icon.VolumeOff size="22px" /> : <Icon.Volume size="22px" />}
      </Button.Secondary>
      <Space w={1} />
      <Button.Secondary
        onClick={() => handleSettingClick("fullscreen")}
        circular
        override={{
          width: "32px",
          height: "32px",
        }}
      >
        <Icon.Fullscreen size="16px" />
      </Button.Secondary>
      <Space w={4} />
      <VerticalDivider />
      <Space w={4} />
    </Wrapper>
  );
};

export default VolumeFullScreenTogglesLandscape;
