import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Glass from "../../../../components-ui/atoms/Glass";
import { PanelTestIds } from "../../../../constants/testIds";
import { GameColor } from "../../../../core/gameConnection/messages/sharedDataTypes";
import { VideoConferenceParticipant } from "../../../../core/videoConference/VideoConferenceAdapter";
import { rgbaToHex } from "../../../../lib/color";
import { zIndex } from "../../../../style/theme";
import { stringToColour } from "./LocalVideoAvatarCircle";
import PlayerAvatarWithInitial from "./PlayerAvatarWithInitials";

const SMALLER_SCALE = 0.675;

const Border = styled.div<{ $size: number }>`
  border: ${(p) => (p.$size < 130 ? 2 : 4)}px solid
    ${(p) => p.theme.colorBelow2};
  border-radius: 50%;
  position: absolute;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  z-index: -1;
`;

const SubtleBorder = styled.div<{ $size: number }>`
  border: 1px solid ${(p) => p.theme.colorAbove0};
  border-radius: 50%;
  position: absolute;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
`;

const VideoAvatarContainer = styled.video<{
  $size: number;
  $isMirrored: boolean;
  onClick?: () => void;
}>`
  transform: ${(p) => (p.$isMirrored ? "scaleX(-1)" : "scaleX(1)")};
  position: absolute;
  box-sizing: border-box;
  width: ${(p) => p.$size}px;
  z-index: 2;
  height: ${(p) => p.$size}px;
  object-fit: cover;
  background-color: transparent;
  border-radius: 50%;
  ${(p) => p.onClick && "cursor: pointer"};
`;

const AvatarWrapper = styled.div<{ $small: boolean }>`
  &:hover {
    .camera-hover-overlay {
      opacity: 1;
    }
  }

  scale: ${(p) => (p.$small ? SMALLER_SCALE : 1)};
  transition: scale 0.3s ease-in-out;
`;

const RelativeWrapper = styled.div<{
  $size: number;
}>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  border-radius: 50%;
  z-index: 1;
  position: relative;
`;

const Container = styled.div<{ $size: number; $small: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  flex: 0;
  pointer-events: all;
  justify-content: center;
  width: ${(p) => p.$size * (p.$small ? SMALLER_SCALE : 1)}px;
  height: ${(p) => p.$size * 1.5 * (p.$small ? SMALLER_SCALE : 1)}px;
  flex-direction: column;
  box-sizing: border-box;
  z-index: ${zIndex.extras};
  * {
    box-sizing: content-box;
  }
  transition:
    width 0.3s ease-in-out,
    height 0.3s ease-in-out;
`;

const IndicatorWrapper = styled.div<{ $small: boolean }>`
  position: relative;
  top: ${(p) => (p.$small ? "-10px" : "0px")};
  width: 100%;
  transition: top 0.3s ease-in-out;
`;

type RenderCoverProps = {
  isVideoAvailable: boolean;
  isLoading: boolean;
};

type RenderLabelProps = {
  size?: number;
  name: string | null;
  isMuted?: boolean;
  isLocal?: boolean;
  isSpeaking?: boolean;
  isTeleport?: boolean;
  hideTitle?: boolean;
  darkText?: boolean;
  isHovered?: boolean;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  onClick?: () => void;
};

export type Props = {
  participant: VideoConferenceParticipant | null;
  playerKey: string | null;
  playerName: string | null;
  testId?: string;
  avatarColor?: GameColor;
  size?: number;
  isSharingScreen?: boolean;
  speakingIndicatorPosition?: "back" | "front";
  speakingIndicator?: React.ReactNode;
  screenShareIndicator?: React.ReactNode;
  renderTitleBreakout?: (props: RenderLabelProps) => React.ReactNode;
  renderCoverComponent?: (props: RenderCoverProps) => React.ReactNode;
  onClick?: () => void;
  hideTitle?: boolean;
  noBorder?: boolean;
  isLocal?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  micHidden?: boolean;
  circleStyle?: React.CSSProperties;
  handleLoadedVideo?: () => void;
};

/**
 * An avatar component that displays either a video stream or a player's initials.
 * It shows the user's name and if the user is speaking or screensharing.
 * Additional parameters can be added such as a hover/cover component and an onClick handler.
 **/
const VideoAvatarCircle: React.FC<Props> = ({
  participant,
  playerKey,
  testId,
  playerName,
  avatarColor,
  size = 92,
  isSharingScreen,
  speakingIndicator,
  screenShareIndicator,
  renderTitleBreakout,
  renderCoverComponent,
  onClick,
  hideTitle = false,
  noBorder = false,
  isLocal = false,
  circleStyle,
  speakingIndicatorPosition = "front",
}) => {
  const isMuted = !participant?.isAudioOn;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);
  const stream = participant?.streams[0];
  const [isHovered, setIsHovered] = useState(false);
  const isVideoAvailable = Boolean(participant?.isVideoOn);
  const [isLoading, setIsLoading] = useState(isVideoAvailable);
  const timeoutRef = useRef<number | null>(null);
  const [activeStream, setActiveStream] = useState<MediaStream | null>(null);
  const activeVideoTrackRef = useRef<MediaStreamTrack | null>(null);

  const handleLoadedVideo = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };
  useEffect(() => {
    const track = stream?.getVideoTracks()[0];
    // For local participants, the video was flickering when rerendering.
    // For remote participants, we want to always refresh track
    if (isLocal) {
      if (
        stream?.active &&
        track &&
        activeVideoTrackRef.current?.id !== track?.id
      ) {
        setActiveStream(stream);
        activeVideoTrackRef.current = track;
      }
    } else if (stream?.active && track) {
      setActiveStream(stream);
    }
  }, [activeStream, stream?.active, stream, isLocal]);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseOut = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (isVideoAvailable && videoRef && activeStream) {
      videoRef.srcObject = activeStream;
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        setIsLoading?.(false);
      }, 4000);
    } else if (isVideoAvailable) {
      setIsLoading?.(true);
    }
  }, [activeStream, isVideoAvailable, setIsLoading, videoRef]);

  const isSpeaking = participant?.isSpeaking;

  const playerAvatarColor =
    (avatarColor && rgbaToHex(avatarColor)) ||
    stringToColour(playerKey || "random-seed");

  return (
    <Container
      ref={wrapperRef}
      $size={size}
      $small={hideTitle}
      data-testid={testId}
    >
      <AvatarWrapper $small={hideTitle}>
        <Glass
          borderRadius="50%"
          style={{
            padding: "0px",
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...circleStyle,
          }}
        >
          <SubtleBorder $size={size} />
          {!noBorder && <Border $size={size} />}
          {renderCoverComponent?.({
            isVideoAvailable,
            isLoading,
          })}
          <RelativeWrapper $size={size}>
            {isVideoAvailable && (
              <VideoAvatarContainer
                data-testid={
                  PanelTestIds.videoAvatars.videoAvatarCircle.videoFeed
                }
                $isMirrored={isLocal}
                $size={size}
                ref={setVideoRef}
                muted
                autoPlay
                playsInline
                id={activeStream?.id}
                key={activeStream?.id}
                onClick={onClick}
                onLoadedData={handleLoadedVideo}
                onError={handleLoadedVideo}
                onMouseEnter={onMouseEnter}
                onMouseOut={onMouseOut}
              />
            )}
            <PlayerAvatarWithInitial
              testId={
                PanelTestIds.videoAvatars.videoAvatarCircle.avatarInitials
              }
              size={`${size}px`}
              color={playerAvatarColor}
              playerName={playerName}
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseOut={onMouseOut}
            />
            {speakingIndicatorPosition === "back" && speakingIndicator}
          </RelativeWrapper>
        </Glass>
      </AvatarWrapper>

      <IndicatorWrapper $small={hideTitle}>
        {speakingIndicatorPosition === "front" && speakingIndicator}
        {isSharingScreen && screenShareIndicator}
      </IndicatorWrapper>
      {renderTitleBreakout?.({
        size,
        name: playerName,
        isMuted,
        isSpeaking,
        isHovered,
        hideTitle,
        onMouseEnter: onMouseEnter,
        onMouseOut: onMouseOut,
        onClick,
      })}
    </Container>
  );
};

export default VideoAvatarCircle;
