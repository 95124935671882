import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { getImageUrl } from "../../../../api/gyaradosUtils";
import Button from "../../../../components-ui/atoms/Button";
import Column from "../../../../components-ui/atoms/Column";
import Glass from "../../../../components-ui/atoms/Glass";
import Hide from "../../../../components-ui/atoms/Hide";
import Icon from "../../../../components-ui/atoms/Icon";
import Image from "../../../../components-ui/atoms/Image";
import Markdown from "../../../../components-ui/atoms/Markdown";
import Spatial from "../../../../components-ui/atoms/Spatial";
import Transition from "../../../../components-ui/atoms/Transition";
import Typo from "../../../../components-ui/atoms/Typo";
import CloseButton from "../../../../components-ui/molecules/CloseButton";
import { steps } from "../../../../style/theme";
import {
  ImageWrapper,
  MarkdownWrapper,
  TextWrapper,
  TitleWrapper,
} from "../../infoCard/InfoCard.ui.preview";
import { Props as MapCardProps } from "./MapCard.ui.normal";
import TeleportButton from "./TeleportButton";

// @todo: Remove grid here and in InfoCardPanelSmallPreviewUi. Use Layout components instead.
export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  grid-template-rows: [top-edge] 1fr [middle];
  grid-template-columns: [left-edge] 50px [left-inset] 1fr [right-inset] 30px [right-edge];
  column-gap: 8px;
  row-gap: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
`;

const Wrapper = styled.div`
  top: max(var(--safe-margin-top), 16px);
  right: max(var(--safe-margin-right), 16px);
  left: max(var(--safe-margin-left), 16px);
  position: absolute;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: ${() => steps.spacing[1]};
`;

const IconWrapper = styled.div`
  height: 46px;
  width: 46px;
  background-color: ${(p) => p.theme.colorAbove0};
  border-radius: ${(p) => p.theme.radiusSmall};
  border: 1px solid ${(p) => p.theme.colorAbove0};
`;

export type Props = {
  onExpandClick: () => void;
  isLandscape?: boolean;
};

/*
 * Card that shows the Map point information on initial click when in small sized screens.
 * This component should be kept as similar as possible to InfoCardPanelSmallPreviewUi (InfoCard.ui.preview)
 */
const MapCardUiPreview: React.FC<Props & MapCardProps> = ({
  selectedPoint,
  onClose,
  onExpandClick,
  onTeleport,
  show,
  isLandscape,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { image, title, description, label, spawnPoint } = selectedPoint;
  const cardTitle = title || label;

  return (
    <Wrapper>
      <Hide hide={!show} shiftY="-20px" unMount>
        <Spatial rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
          <Glass
            height="120px"
            width={isLandscape ? "352px" : undefined}
            overflow="hidden"
            style={{
              display: "flex",
              float: isLandscape ? "right" : undefined,
            }}
          >
            <Column>
              <Grid>
                <ImageWrapper>
                  <Transition watch={[image]} delay={300} height="100%">
                    {image ? (
                      <Image
                        src={getImageUrl(image, 48, 48) ?? ""}
                        width="48px"
                        height="48px"
                        styleOverrides={{
                          borderRadius: theme.radiusSmall,
                          border: `1px solid ${theme.colorAbove0}`,
                        }}
                        cover
                      />
                    ) : (
                      <IconWrapper>
                        <Column align="center" justify="center" height="100%">
                          <Icon.CircleInfo size="20px" />
                        </Column>
                      </IconWrapper>
                    )}
                  </Transition>
                </ImageWrapper>
                <TextWrapper>
                  <Transition
                    watch={[cardTitle, description]}
                    delay={300}
                    height="100%"
                  >
                    <Column>
                      <TitleWrapper>
                        <Typo.Title size={steps.font.f20.size}>
                          {cardTitle}
                        </Typo.Title>
                      </TitleWrapper>
                      <MarkdownWrapper>
                        <Markdown content={description} textAlign="left" />
                      </MarkdownWrapper>
                    </Column>
                  </Transition>
                </TextWrapper>
              </Grid>
              <ButtonsWrapper>
                {spawnPoint && (
                  <TeleportButton
                    onTeleport={() => onTeleport(selectedPoint)}
                  />
                )}
                <Button.Secondary
                  override={{
                    width: "100%",
                    height: "32px",
                  }}
                  onClick={onExpandClick}
                  iconLeft={<Icon.Plus size="15px" inheritColor />}
                  gap={2}
                >
                  {t("info_card_expand")}
                </Button.Secondary>
                <CloseButton onClick={onClose} />
              </ButtonsWrapper>
            </Column>
          </Glass>
        </Spatial>
      </Hide>
    </Wrapper>
  );
};

export default MapCardUiPreview;
