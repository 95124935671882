import { useCallback, useState } from "react";
import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import { css, styled } from "styled-components";
import { getImageUrl } from "../../../../api/gyaradosUtils";
import RpmImgSrc from "../../../../assets/img/RpmDefaultPreview.png";
import Carousel, { Slide } from "../../../../components-ui/atoms/Carousel";
import Row from "../../../../components-ui/atoms/Row";
import Space from "../../../../components-ui/atoms/Space";
import Typo from "../../../../components-ui/atoms/Typo";
import ArrowButton from "../../../../components-ui/molecules/ArrowButton";
import { PanelTestIds } from "../../../../constants/testIds";
import CustomAvatarButton from "./CustomAvatarButton";

const getInitialAvatarIndex = (
  options: AvatarResponseDto[],
  initialAvatarId: string
): number => {
  const index = options.findIndex(
    (option) => option.avatarId === initialAvatarId
  );
  if (index === -1) {
    return 0;
  }
  return index;
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  white-space: nowrap;
  height: 212px;
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0.6rem;
`;

const Avatar = styled.div<{ $bgColor?: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  will-change: transform;
  border-radius: ${({ theme }) => theme.radiusSmall};
  border: 1px solid ${(p) => p.theme.colorAbove0};
  ${(p) =>
    p.$bgColor &&
    css`
      background-color: ${p.$bgColor};
      border-color: rgba(0, 0, 0, 0.1);
    `}

  transition:
    transform 0.3s 0.1s ease-in-out,
    box-shadow 0.3s ease-in-out;

  .active & {
    transform: scale(1.1);
    box-shadow: 0px 30px 30px ${(p) => p.theme.colorShadow};
  }
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  transform-origin: center bottom;
  transition: transform 0.3s 0.1s ease-in-out;
  position: absolute;
  z-index: 1;
  bottom: -1px;
  left: 0;
  .active & {
    transform: scale(1.2);
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.radiusSmall};
  overflow: hidden;
`;

const BackGroundGradient = styled.div`
  width: 100%;
  height: 100%;
  /* The exact same border radius makes the border in the corners slightly wider */
  border-radius: calc(${({ theme }) => theme.radiusSmall} - 1px);
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const ImgBackground = styled.img<{
  $backgroundUrl?: string;
}>`
  width: 100%;
  height: 100%;
  background-image: url(${({ $backgroundUrl }) => $backgroundUrl});
  background-size: 300%;
  background-position: center;
  filter: blur(30px) saturate(4.5);
  opacity: 0.3;
`;

export const CustomAvatarButtonWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  z-index: 1;
  width: 102px;
  display: flex;
  justify-content: center;
`;

type Props = {
  options: AvatarResponseDto[];
  initialAvatarId: string;
  onSelect: (avatarId: string) => void;
  openReadyPlayerMeEditor: () => void;
};

const AvatarCarousel: React.FC<Props> = ({
  options,
  initialAvatarId,
  onSelect,
  openReadyPlayerMeEditor,
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(
    getInitialAvatarIndex(options, initialAvatarId)
  );
  const [rotationDirection, setRotationDirection] = useState<"left" | "right">(
    "right"
  );

  const goToNextAvatar = useCallback(() => {
    const nextSlideIndex = (activeSlideIndex + 1) % options.length;
    setRotationDirection("right");
    setActiveSlideIndex(nextSlideIndex);
    onSelect(options[nextSlideIndex].avatarId);
  }, [activeSlideIndex, onSelect, options]);

  const goToPreviousAvatar = useCallback(() => {
    const previousSlideIndex =
      (activeSlideIndex - 1 + options.length) % options.length;
    setRotationDirection("left");
    setActiveSlideIndex(previousSlideIndex);
    onSelect(options[previousSlideIndex].avatarId);
  }, [activeSlideIndex, onSelect, options]);

  const goToClickedAvatar = useCallback(
    (avatarId: Slide["id"], position: "left" | "right") => {
      const clickedSlideIndex = options.findIndex(
        (option) => option.avatarId === avatarId
      );
      setRotationDirection(position);
      setActiveSlideIndex(clickedSlideIndex);
      onSelect(options[clickedSlideIndex].avatarId);
    },
    [onSelect, options]
  );

  const slides: Slide[] = options.map((option) => ({
    id: option.avatarId,
    width: "130px",
    getElement: (active: boolean) => {
      const isReadyPlayerMe = option.avatarId === "readyplayerme";

      const imageUrl =
        isReadyPlayerMe && !option.avatarImage
          ? RpmImgSrc
          : getImageUrl(option.avatarImage, 200, 200);

      return (
        <AvatarWrapper
          data-testid={PanelTestIds.profile.common.carouselAvatarChoice}
        >
          <Avatar $bgColor={option.colors.backgroundColor}>
            {!option.colors.backgroundColor ? (
              <BackgroundImageWrapper>
                <ImgBackground $backgroundUrl={imageUrl} />
              </BackgroundImageWrapper>
            ) : (
              <BackGroundGradient />
            )}

            <Img src={imageUrl} alt={option.name} />
            <CustomAvatarButton
              testId={PanelTestIds.profile.common.customAvatarBtn(
                option.avatarId
              )}
              hide={!isReadyPlayerMe || !active}
              onClick={(e) => {
                e.preventDefault();
                openReadyPlayerMeEditor();
              }}
            />
          </Avatar>
        </AvatarWrapper>
      );
    },
  }));

  return (
    <>
      <Space h={8} />
      <Space h={2} />
      <Wrapper data-testid={PanelTestIds.profile.common.carouselAvatarPicker}>
        <input
          name="avatarId"
          type="hidden"
          value={options[activeSlideIndex].avatarId}
          data-testid={PanelTestIds.profile.common.avatarHiddenInput}
        />
        <Carousel
          activeClass="active"
          rotationDirection={rotationDirection}
          activeSlideId={options[activeSlideIndex].avatarId}
          slides={slides}
          onSlideClick={goToClickedAvatar}
        />
        <Space h={5} />
        <Row height="50px" width="300px" justify="space-between" align="center">
          <Row width="auto" justify="center">
            <Space w={6} />
            <ArrowButton
              testId={PanelTestIds.profile.common.leftCarouselButton}
              rotate="-90deg"
              onClick={(e) => {
                e.preventDefault();
                goToPreviousAvatar();
              }}
            />
          </Row>

          <Typo.Body>{options[activeSlideIndex].name}</Typo.Body>

          <Row width="auto" justify="center">
            <ArrowButton
              testId={PanelTestIds.profile.common.rightCarouselButton}
              rotate="90deg"
              onClick={(e) => {
                e.preventDefault();
                goToNextAvatar();
              }}
            />
            <Space w={6} />
          </Row>
        </Row>
      </Wrapper>
    </>
  );
};

export default AvatarCarousel;
