import React, { ReactNode, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div<{ $noFlexbox: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${(p) =>
    !p.$noFlexbox &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  z-index: 1;
  overflow: hidden;
`;

type Props = {
  children: ReactNode;
  testId?: string;
  noFlexbox?: boolean;
};

const SpacePageUi: React.FC<Props> = ({ children, testId, noFlexbox }) => {
  return (
    <>
      <ParticlesBackground />
      <Wrapper data-testid={testId} $noFlexbox={Boolean(noFlexbox)}>
        {children}
      </Wrapper>
    </>
  );
};

export default SpacePageUi;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
`;

const ParticlesBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let id: undefined | number;
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.height = window.innerHeight;
      canvas.width = window.innerWidth;
      const context = canvas.getContext("2d");

      if (context) {
        stars = [];
        id = window.requestAnimationFrame(function animate() {
          draw(context, canvas.width, canvas.height); // draw it
          window.requestAnimationFrame(animate);
        });
      }
    }

    return () => {
      if (id) window.cancelAnimationFrame(id);
    };
  }, []);

  return <Canvas height="100%" ref={canvasRef} />;
};

type Star = {
  angle: number;
  life: number;
  plane: number;
  x: number;
  y: number;
  speed: number;
};
let stars: Star[] = [];
let counter = -1;
const movmentSpeed = 0.8;

const draw = (ctx: CanvasRenderingContext2D, w: number, h: number) => {
  // Initial burst
  if (counter === -1) {
    for (let i = 0; i < 50; i++) {
      const d = 80 + Math.random() * 600;
      const a = Math.random() * Math.PI * 2;
      stars.push({
        angle: a,
        life: 0,
        plane: 1,
        x: w / 2 + Math.cos(a) * d,
        y: h / 2 + Math.sin(a) * d,
        speed: 0.5 + Math.random(),
      });
    }
  }
  // Continous generation burst
  if (counter === 0) {
    for (let i = 0; i < 2; i++) {
      const d = 150 + Math.random() * 200;
      const a = Math.random() * Math.PI * 2;
      stars.push({
        angle: a,
        life: 0,
        plane: 1,
        x: w / 2 + Math.cos(a) * d,
        y: h / 2 + Math.sin(a) * d,
        speed: 0.5,
      });
    }
  }
  counter = (counter + 1) % 10;

  // Clear slightly the background (trail effect)
  ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
  ctx.fillRect(0, 0, w, h);

  for (let i = 0; i < stars.length; i++) {
    const star = stars[i];
    // Fade in based on life
    ctx.fillStyle = `rgba(255, 255, 255, ${star.life / 500})`;

    // draw star
    ctx.beginPath();
    ctx.arc(star.x, star.y, 1 + star.life / 400, 0, 2 * Math.PI);
    ctx.fill();

    // move
    star.x += Math.cos(star.angle) * movmentSpeed * star.speed;
    star.y += Math.sin(star.angle) * movmentSpeed * star.speed;

    // accellerate
    star.speed += 0.01;

    // age
    star.life += 1;

    // die
    if (star.x < 0 || star.x > w || star.y < 0 || star.y > h) {
      // remove current star
      stars.splice(i, 1);
      i--;
    }
  }
};
