import React from "react";
import { SvgProps } from "../../types/svg";

const TwitterXIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.03893 2.5L8.21635 10.7596L2 17.4751H3.39916L8.84168 11.5955L13.2389 17.4751H18L11.4749 8.75098L17.2611 2.5H15.8619L10.8498 7.91487L6.8 2.5H2.03893ZM4.09646 3.53051H6.28367L15.9422 16.4446H13.755L4.09646 3.53051Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default TwitterXIcon;
