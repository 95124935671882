import { SvgProps } from "../../types/svg";

const CrosshairIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.25 10C9.25 9.58437 9.58437 9.25 10 9.25C10.4156 9.25 10.75 9.58437 10.75 10C10.75 10.4156 10.4156 10.75 10 10.75C9.58437 10.75 9.25 10.4156 9.25 10ZM10 2C10.275 2 10.5 2.22387 10.5 2.5V3.51906C13.6906 3.76156 16.2375 6.30937 16.4812 9.5H17.5C17.775 9.5 18 9.725 18 10C18 10.275 17.775 10.5 17.5 10.5H16.4812C16.2375 13.6906 13.6906 16.2375 10.5 16.4812V17.5C10.5 17.775 10.275 18 10 18C9.725 18 9.5 17.775 9.5 17.5V16.4812C6.30937 16.2375 3.76156 13.6906 3.51906 10.5H2.5C2.22387 10.5 2 10.275 2 10C2 9.725 2.22387 9.5 2.5 9.5H3.51906C3.76156 6.30937 6.30937 3.76156 9.5 3.51906V2.5C9.5 2.22387 9.725 2 10 2ZM9.5 15.4781V14C9.5 13.725 9.725 13.5 10 13.5C10.275 13.5 10.5 13.725 10.5 14V15.4781C13.1375 15.2406 15.2406 13.1375 15.4781 10.5H14C13.725 10.5 13.5 10.275 13.5 10C13.5 9.725 13.725 9.5 14 9.5H15.4781C15.2406 6.8625 13.1375 4.76031 10.5 4.5225V6C10.5 6.275 10.275 6.5 10 6.5C9.725 6.5 9.5 6.275 9.5 6V4.5225C6.8625 4.76031 4.76031 6.8625 4.5225 9.5H6C6.275 9.5 6.5 9.725 6.5 10C6.5 10.275 6.275 10.5 6 10.5H4.5225C4.76031 13.1375 6.8625 15.2406 9.5 15.4781Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CrosshairIcon;
