import {
  GyaradosAxios,
  GyaradosPrisma,
  GyaradosSDK,
} from "@journee-live/gyarados";
import { AxiosResponse } from "@journee-live/mew/default/script/deps";
import * as Sentry from "@sentry/react";

const actionPanelsApi = new GyaradosAxios.ActionPanelsApi(
  undefined,
  import.meta.env.VITE_GYARADOS_URL
);

const environmentsApi = new GyaradosAxios.EnvironmentsApi(
  undefined,
  import.meta.env.VITE_GYARADOS_URL
);

export const getActionPanelById = async (
  actionPanelId: string | undefined
): Promise<GyaradosPrisma.ActionPanel | null> => {
  if (!actionPanelId) return null;
  const { data } = (await actionPanelsApi.actionPanelsControllerFindOne({
    id: actionPanelId,
  })) as AxiosResponse<GyaradosPrisma.ActionPanel>;
  return data;
};

export const getEnvironmentBySlug = async (
  environmentSlug: string | undefined,
  organisationSlug?: string | undefined,
  language?: string
): Promise<GyaradosSDK.EnvironmentConfigResponseDto | null> => {
  if (!environmentSlug) return null;

  /* We use 2 different endpoints for environments.
     One for the original Journee envs.
     Another for the new "client" envs which are namespaced by their organisation slug.
  */
  const headers = language ? { language } : undefined;
  if (organisationSlug) {
    const { data } = await environmentsApi
      .environmentsControllerConfigClient(
        {
          environmentSlug,
          organisationSlug,
        },
        {
          headers,
        }
      )
      .catch((error) => {
        if (error.response.status === 404) {
          Sentry.withScope((scope) => {
            scope.setTag("shouldReport", "false");
          });
        }
        throw error;
      });
    return data ?? null;
  } else {
    const { data } = await environmentsApi
      .environmentsControllerConfigJournee(
        {
          environmentSlug,
        },
        {
          headers,
        }
      )
      .catch((error) => {
        if (error.response.status === 404) {
          Sentry.withScope((scope) => {
            scope.setTag("shouldReport", "false");
          });
        }
        throw error;
      });
    return data ?? null;
  }
};
