// A hackish way to check if a video has audio
// lifted from: https://stackoverflow.com/questions/21270048/html5-video-how-to-detect-when-there-is-no-audio-track
export function hasAudio(
  video: HTMLVideoElement & {
    mozHasAudio?: boolean;
    webkitAudioDecodedByteCount?: number;
    audioTracks?: unknown[];
  }
) {
  return (
    video.mozHasAudio ||
    Boolean(video.webkitAudioDecodedByteCount) ||
    Boolean(video.audioTracks?.length)
  );
}
