import { SvgProps } from "../../types/svg";

const PersonWalkingFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 3.5C13 4.32844 12.3281 5 11.5 5C10.6719 5 10 4.32844 10 3.5C10 2.67156 10.6719 2 11.5 2C12.3281 2 13 2.67156 13 3.5ZM8.95312 8.22813C8.925 8.24062 8.89375 8.25313 8.86562 8.26562L8.52187 8.41875C8.04406 8.63125 7.66937 9.01875 7.47563 9.50313L7.42844 9.62187C7.22344 10.1344 6.64156 10.3844 6.12875 10.1781C5.61594 9.97188 5.36656 9.39062 5.57156 8.87813L5.61875 8.75938C6.00594 7.79375 6.75563 7.01562 7.70813 6.59062L8.05188 6.4375C8.70312 6.15 9.37812 6 10.1156 6C11.5094 6 12.7656 6.8375 13.3 8.12187L13.7531 9.27187L14.4469 9.60625C14.9406 9.85312 15.1406 10.4531 14.8938 10.9469C14.6469 11.4406 14.0469 11.6406 13.5531 11.3938L12.7188 10.9781C12.3969 10.8156 12.1437 10.5437 12.0062 10.2125L11.7063 9.49375L11.1031 11.5406L12.65 13.2312C12.8187 13.4156 12.9406 13.6375 13 13.8813L13.7188 16.7563C13.8531 17.2938 13.5281 17.8375 12.9938 17.9688C12.4563 18.1031 11.9125 17.7781 11.7531 17.2437L11.0906 14.4875L8.88438 12.0781C8.42188 11.575 8.22187 10.8719 8.425 10.2125L8.95312 8.22813ZM7.14781 14.4375L7.92781 12.4875C7.995 12.5813 8.0675 12.6688 8.14687 12.7563L9.41875 14.1438L8.96563 15.275C8.89062 15.4656 8.77813 15.6375 8.63438 15.7781L6.70719 17.7063C6.31656 18.0969 5.68344 18.0969 5.29287 17.7063C4.90237 17.3156 4.90237 16.6844 5.29287 16.2937L7.14781 14.4375Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PersonWalkingFilledIcon;
