import { AnalyticsPlugin } from "analytics";
import { AnalyticsMessage } from "./messages/analyticsMessages";

function getGAEventName(name: string) {
  return name.replace(/[^a-zA-Z0-9_]/g, "_").slice(0, 40);
}

function getGAEvent(message: AnalyticsMessage, environmentId: string) {
  const event: {
    event: string;
    properties: Record<string, string | number | object | undefined>;
  } = {
    event: "",
    properties: {
      environment_id: environmentId,
    },
  };

  switch (message.type) {
    case "share":
    case "login":
      // `share` and `login` are special cases that fall into recommended GA event categories
      // they have `method` parameter where we can store the sharing target or login method
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=6277120558615191454-EU&client_type=gtag#login
      event.event = message.type;
      event.properties.method = message.name;
      break;
    case "connection":
    case "flow":
    case "click":
      event.event = getGAEventName(`${message.type}_${message.name}`);
      // For all other events that have optional string parameter (e.g. `detail`)
      // we can abuse the built-in `content_type` parameter
      event.properties["content_type"] = message.detail;
      break;
    case "poll":
    case "quiz":
      event.event = message.type;
      // For all other events that have optional string parameter (e.g. `detail`)
      // we can abuse the built-in `content_type` parameter
      event.properties["content_type"] = `${message.name}_${message.detail}`;
      break;
    case "ui":
      // Include state in the event name
      event.event = getGAEventName(
        `${message.type}_${message.name}_${message.state}`
      );
      event.properties["content_type"] = message.detail;
      break;
    case "ai_webcam_running":
      event.event = getGAEventName(`${message.type}`);
      break;
    case "region":
      // Include state in the event name
      event.event = getGAEventName(`${message.type}_${message.state}`);
      event.properties["content_type"] = message.name;
      break;
    case "game":
    case "avatar":
      event.event = message.type;
      event.properties["content_type"] = message.name;
      break;
    case "item_select":
      event.event = "select_item";
      event.properties["items"] = [
        {
          item_name: message.name,
          item_variant: message.detail,
        },
      ];
      break;
    case "item_add":
      event.event = "add_to_cart";
      event.properties["items"] = [
        {
          item_name: message.name,
          quantity: message.amount,
        },
      ];
      break;
    default:
      event.event = getGAEventName(`${message.type}_${message.name}`);
      break;
  }
  return event;
}

/**
 * Wraps the GA plugin to handle our unified Analytics Message format
 */
export function wrapGAPlugin(
  plugin: AnalyticsPlugin,
  environmentId: string
): AnalyticsPlugin {
  const originalTrack = plugin.track as (param: unknown) => void;
  return {
    ...plugin,
    track: (param: {
      payload: { event: string; properties: AnalyticsMessage };
    }) => {
      const { event, properties } = getGAEvent(
        param.payload.properties,
        environmentId
      );
      param.payload.event = event;
      // Have to cast to override the existing value
      param.payload.properties = properties as unknown as AnalyticsMessage;
      originalTrack(param);
    },
  };
}
