import React from "react";
import { SvgProps } from "../../types/svg";

const StarFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.6 11H30.5C31.1438 11 31.7125 11.4062 31.9188 12.0125C32.125 12.6187 31.925 13.2875 31.4188 13.6812L23.2438 20.0438L26.4313 30.0438C26.6313 30.6688 26.4 31.3563 25.8563 31.7313C25.3188 32.1063 24.6 32.0875 24.0813 31.6812L16 25.4L7.91876 31.6812C7.40001 32.0875 6.68126 32.1063 6.14188 31.7313C5.60313 31.3563 5.37126 30.6688 5.57063 30.0438L8.75626 20.0438L0.579198 13.6812C0.0733852 13.2875 -0.126677 12.6187 0.0812602 12.0125C0.28926 11.4062 0.859385 11 1.50001 11H11.4063L14.5688 1.045C14.7688 0.42275 15.35 0 16 0C16.6563 0 17.2313 0.42275 17.4313 1.045L20.6 11Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default StarFilledIcon;
