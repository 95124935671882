import styled, { useTheme } from "styled-components";
import Button from "../../../../components-ui/atoms/Button";
import Hide from "../../../../components-ui/atoms/Hide";
import Icon from "../../../../components-ui/atoms/Icon";
import { PanelTestIds } from "../../../../constants/testIds";
import { zIndex } from "../../../../style/theme";
import {
  INDICATOR_SIZE,
  VIDEO_AVATAR_CIRCLE_SIZE,
} from "../lib/videoAvatarsConstants";
import VideoAvatarCircle, { Props } from "./VideoAvatarCircle";

const SpeakingCircle = styled.div<{ $size: number; $visible: boolean }>`
  position: absolute;
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
  width: ${(p) => p.$size + 5}px;
  height: ${(p) => p.$size + 5}px;
  border-radius: 50%;
  background: ${(p) => p.theme.colorGradient.purpleBlue.value};
  box-sizing: border-box;
  z-index: 0;
`;

const ScreenSharingIndication = styled(Hide)`
  bottom: 1px;
  left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: ${zIndex.overlays};
`;

const SpeakingBorderWrapper = styled.div`
  position: absolute;
  left: -2.5px;
  top: -2.5px;
`;
const PeerVideoAvatarCircle: React.FC<Props> = ({
  participant,
  isSharingScreen,
  size = 108,
  playerKey,
  renderTitleBreakout,
  hideTitle = false,
  noBorder = false,
  avatarColor,
  playerName,
  circleStyle,
  onClick,
}) => {
  const theme = useTheme();

  const isSpeaking = participant?.isSpeaking || false;

  const speakingIndicatorSize =
    size < VIDEO_AVATAR_CIRCLE_SIZE.LARGE
      ? INDICATOR_SIZE.SMALL - INDICATOR_SIZE.OFFSET // workaround for Button.Glass
      : INDICATOR_SIZE.LARGE;

  const speakingIndicator = (
    <SpeakingBorderWrapper>
      <SpeakingCircle $size={size} $visible={isSpeaking} />
    </SpeakingBorderWrapper>
  );

  const screenShareIndicator = (
    <ScreenSharingIndication hide={false}>
      <Button.Glass
        circular
        override={{
          width: `${speakingIndicatorSize}px`,
          height: `${speakingIndicatorSize}px`,
        }}
      >
        <Icon.ScreenShareFilled color={theme.colorAbove3} size="18px" />
      </Button.Glass>
    </ScreenSharingIndication>
  );
  return (
    <VideoAvatarCircle
      testId={PanelTestIds.videoAvatars.peerVideoAvatarCircle}
      renderTitleBreakout={renderTitleBreakout}
      isSharingScreen={isSharingScreen}
      hideTitle={hideTitle}
      playerKey={playerKey}
      playerName={playerName}
      avatarColor={avatarColor}
      size={size}
      participant={participant}
      speakingIndicator={speakingIndicator}
      speakingIndicatorPosition="back"
      screenShareIndicator={screenShareIndicator}
      noBorder={noBorder}
      onClick={onClick}
      circleStyle={circleStyle}
    />
  );
};

export default PeerVideoAvatarCircle;
