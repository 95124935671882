import styled from "styled-components";
import { getImageUrl } from "../../../api/gyaradosUtils";
import CardImage from "../../../components-ui/atoms/CardImage";
import CardPartialPortrait from "../../../components-ui/molecules/CardPartialPortrait";
import { Props } from "./Popup.ui";
import PopupContent from "./component/PopupContent";

const Body = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    text-align: center;
    flex: 1;
    min-inline-size: 0;
  }
  padding-bottom: 38px;
  min-width: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PopupUiPortrait: React.FC<Props> = ({
  onClose,
  popup,
  handleExternalLink,
  handleTeleport,
  extraText,
}) => {
  const popupImage = popup?.image;

  return (
    <CardPartialPortrait
      onClose={popup?.dismissible ? onClose : undefined}
      topSection={
        <CardImage
          height={popupImage ? "100%" : "200px"}
          width="100%"
          src={getImageUrl(popupImage, 700, 700)}
          orientation="portrait"
        />
      }
      topSectionHeight={popupImage ? "200px" : "120px"}
      brightCloseButton={Boolean(popup?.image)}
      padding={`${popupImage ? "32px" : "16px"} 32px 10px`}
    >
      <Body>
        <PopupContent
          onClose={onClose}
          popup={popup}
          handleExternalLink={handleExternalLink}
          handleTeleport={handleTeleport}
          extraText={extraText}
        />
      </Body>
    </CardPartialPortrait>
  );
};

export default PopupUiPortrait;
