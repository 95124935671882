import { styled } from "styled-components";

/** A fullscreen bright flash animation. Used after the user capture a screenshot/picture. */
const Flash = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @keyframes flash {
    0% {
      background-color: rgb(255, 255, 255, 0.7);
    }
    100% {
      background-color: transparent;
      display: none;
    }
  }
  animation: flash 0.8s ease-out;
`;

export default Flash;
