import { styled } from "styled-components";
import Spatial from "../../../components-ui/atoms/Spatial";
import Card from "../../../components-ui/molecules/Card";
import RestrictionMessageUi from "./component/RestrictionMessageUi";

const Wrapper = styled.div`
  display: flex;
`;

const RestrictionUiNormal: React.FC = () => {
  return (
    <Wrapper>
      <Spatial rim glare mouseZoom mouseTiltX mouseTiltY dragMotion>
        <Card
          maxHeight="500px"
          style={{ padding: "0 30px" }}
          topSection={<RestrictionMessageUi />}
        ></Card>
      </Spatial>
    </Wrapper>
  );
};

export default RestrictionUiNormal;
