import { GameColor } from "../../../../../../core/gameConnection/messages/sharedDataTypes";
import { sendGameMessage } from "../../../../../../core/gameConnection/webrtc/webRtcMessageHandlers";
import { useStore } from "../../../../../../store/store";
import { SearchableProfile } from "../../../../profile/lib/profileTypes";
import PlayerProfileUi, { AvailableSocials } from "./PlayerProfile.ui";
import UnknownPlayerProfileUi from "./UnknownPlayer.ui";

export type Props = {
  playerProfile: SearchableProfile | null;
  onPrevious: () => void;
};

function getAvailableSocials(profile: SearchableProfile): AvailableSocials {
  return Object.keys(profile).reduce((acc, key) => {
    if (
      (key === "twitter" ||
        key === "linkedin" ||
        key === "instagram" ||
        key === "facebook") &&
      profile[key] !== ""
    ) {
      acc[key] = profile[key];
    }
    return acc;
  }, {} as AvailableSocials);
}
const PlayerProfileLogic: React.FC<Props> = ({ playerProfile, onPrevious }) => {
  const userId = playerProfile?.userId;
  const participant = useStore(
    (s) => userId && s.videoConference.participants[userId]
  );
  const nearbyPlayers = useStore((s) => s.gameConnection.nearbyPlayers);
  const nearbyPlayer = nearbyPlayers.find(
    (p) => p.playerId === playerProfile?.photonPlayerId
  );
  const isStillConnected = Boolean(participant) || Boolean(nearbyPlayer);
  const onTeleport = () => {
    if (!playerProfile?.photonPlayerId || !isStillConnected) {
      return;
    }
    sendGameMessage({
      type: "TeleportTo",
      playerId: playerProfile?.photonPlayerId,
      roomId: playerProfile.photonRoomId,
    });
  };
  if (!playerProfile) {
    return <UnknownPlayerProfileUi onPrevious={onPrevious} />;
  }
  return (
    <PlayerProfileUi
      isStillConnected={isStillConnected}
      onPrevious={onPrevious}
      playerName={playerProfile.name}
      playerTitle={playerProfile.jobTitle}
      playerCompany={playerProfile.company}
      avatarId={playerProfile.rpmAvatarId}
      avatarColor={playerProfile.avatarColor as GameColor}
      socials={getAvailableSocials(playerProfile)}
      playerKey={playerProfile.id}
      participant={participant || null}
      onTeleport={onTeleport}
    />
  );
};

export default PlayerProfileLogic;
