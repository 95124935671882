import Spatial from "../../../components-ui/atoms/Spatial";
import Card from "../../../components-ui/molecules/Card";
import { isColorBright } from "../../../lib/color";
import { Props, generateGradientFromColor } from "./Social.ui";

const SocialUiNormal: React.FC<Props> = ({ onClose, tabs, selectedTab }) => {
  return (
    <Spatial tiltX={6} rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
      <Card
        floatingSection={tabs}
        bottomSection={selectedTab.drawer}
        onClose={onClose}
        style={{
          background: generateGradientFromColor(selectedTab.backgroundColor),
        }}
        border="none"
        height="530px"
        maxHeight="530px"
        width="320px"
        hideScrollButton
        brightCloseButton={
          selectedTab.backgroundColor
            ? !isColorBright(selectedTab.backgroundColor)
            : false
        }
        showFade={selectedTab.showFade}
      >
        {selectedTab.content}
      </Card>
    </Spatial>
  );
};

export default SocialUiNormal;
