import { log } from "../../../../lib/logger";

let vid: HTMLVideoElement | null = null;

declare global {
  interface Window {
    startScreencast: (duration: number) => void;
    stopScreencast: () => void;
  }
}

export const startScreencast = (duration: number) => {
  // Snippet to save a video excerpt from the stream
  // Copy-paste in the console.

  if (!window.MediaRecorder) {
    log("WEBRTC", "Missing MediaRecorder API");
    return;
  }

  const videoElem = document.getElementById(
    "streamingVideo"
  ) as HTMLVideoElement;
  if (!videoElem) {
    log("WEBRTC", "Couldn't find streaming source");
    return;
  }
  if (videoElem.tagName !== "VIDEO") {
    log("WEBRTC", "Element isn't a video");
    return;
  }
  const stream = videoElem.srcObject as MediaStream;
  if (!stream) {
    log("WEBRTC", "Missing stream");
    return;
  }

  if (!vid) {
    vid = document.createElement("video");
    document.body.append(vid);
    vid.style.position = "absolute";
    vid.style.backgroundColor = "blue";
    vid.style.top = "100px";
    vid.style.left = "12px";
    vid.style.maxWidth = "180px";
    vid.controls = true;
  }

  vid.src = "";
  function finishCapturing(e: BlobEvent) {
    const videoData = [e.data];
    const blob = new Blob(videoData, { type: "video/webm" });
    const videoURL = URL.createObjectURL(blob);
    if (!vid) return;
    vid.src = videoURL;
    vid.play();
  }

  const recorder = new window.MediaRecorder(stream);
  recorder.addEventListener("dataavailable", finishCapturing);
  recorder.start();
  const seconds = duration || 10;
  log("WEBRTC", "Starting video capture for " + seconds + "sec.");

  const stopScreencast = () => {
    log("WEBRTC", "Stopping recorder.");
    recorder.stop();
  };
  window.stopScreencast = stopScreencast;
  setTimeout(stopScreencast, seconds * 1000);
};

window.startScreencast = startScreencast;
