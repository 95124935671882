import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useCurrentLoginBackgroundImage,
  useCurrentLoginBackgroundVideo,
} from "../../api/gyaradosHooks";
import { getImageUrl } from "../../api/gyaradosUtils";
import Hide from "../../components-ui/atoms/Hide";
import { useEnvironmentContext } from "../../core/EnvironmentFetcher.core";
import { getFitStreamInViewport } from "../../core/gameConnection/gameConnectionUtils";
import { useIsLandscape } from "../../hooks/ui";
import { emit } from "../../lib/bus";
import { useStore } from "../../store/store";
import { zIndex } from "../../style/theme";

const Container = styled.div`
  position: absolute;
  z-index: ${zIndex.background};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  /* disable scroll bars for external controls (mouse or keyboards) */
  overflow: hidden;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 50px;
  min-height: 50px;
  background-color: black;
  &::-webkit-media-controls-start-playback-button {
    display: none;
  }
  &::-webkit-media-controls {
    display: none;
  }
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: black;
`;

type Props = {
  isDevRoute?: boolean;
};

const LoginVideoBackground: React.FC<Props> = ({ isDevRoute }) => {
  const videoSource = useCurrentLoginBackgroundVideo();
  const backgroundImageSource = useCurrentLoginBackgroundImage();
  const backgroundImageUrl = getImageUrl(backgroundImageSource);
  const gameIsReady = useStore((s) => s.gameConnection.gameIsReady);
  const {
    environment: { fitViewport: fitViewportConfig },
  } = useEnvironmentContext();
  const isLandscape = useIsLandscape();
  const fitViewport = !isLandscape && getFitStreamInViewport(fitViewportConfig);
  const hideLoginBackground = gameIsReady && fitViewport;

  const [videoElement, setVideoElement] = useState<HTMLVideoElement>();
  const [videoError, setVideoError] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);

  // Fade in the background if the video or image is reday.
  const [hide, setHide] = useState(true);
  useEffect(() => {
    if (isImageReady || isVideoReady) setHide(false);
  }, [isImageReady, isVideoReady]);

  useEffect(() => {
    if (!videoSource) return;
    if (videoElement == null) return;
    // We append the video source after in order to detect
    // bad video links.
    const source = document.createElement("source");
    source.src = videoSource ?? "";
    source.onerror = () => {
      emit("errorReport", {
        type: "LOGIN_VIDEO_BG_COULD_NOT_LOAD",
        payload: {
          videoSource,
        },
        signature: videoSource,
      });
      setVideoError(true);
    };
    videoElement.appendChild(source);
    setIsVideoReady(true);
  }, [videoElement, videoSource]);

  const noVideoBackground = !videoSource || videoError;
  const isExperiencePhase = useStore((s) => s.userFlow.isExperiencePhase());

  // We hide the video background after the game stream has started.
  // But in development we keep it as it's a useful replacement for the stream.
  if (!isDevRoute && isExperiencePhase) return null;

  return (
    <Container>
      <Hide
        width="100%"
        height="100%"
        hide={hide || hideLoginBackground}
        speed={600}
      >
        {noVideoBackground ? (
          backgroundImageUrl && (
            <Img
              src={backgroundImageUrl}
              onLoad={() => setIsImageReady(true)}
            />
          )
        ) : (
          <Video
            autoPlay
            muted
            loop
            playsInline
            draggable={false}
            disablePictureInPicture
            onDragStart={(e) => e.preventDefault()}
            ref={(el) => setVideoElement(el || undefined)}
          />
        )}
      </Hide>
    </Container>
  );
};

export default LoginVideoBackground;
