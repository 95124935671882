import styled, { keyframes } from "styled-components";
import Icon from "../../../../components-ui/atoms/Icon";
import { isColorBright } from "../../../../lib/color";

// slow opacity pulse animation
const pulsatingAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const RunningWrapper = styled.div<{
  $size: string;
  $visible: boolean;
  onClick?: () => void;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: ${(p) => p.$size};
  width: ${(p) => p.$size};
  animation: ${pulsatingAnimation} 2s infinite;
`;

const AvatarWrapper = styled.div<{
  $size: string;
  $color: string;
  $visible: boolean;
  onClick?: () => void;
}>`
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  display: flex;
  position: absolute;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.$size};
  color: ${(p) =>
    isColorBright(p.$color) ? p.theme.colorAbove4 : p.theme.colorBelow2};
  height: ${(p) => p.$size};
  border-radius: 50%;
  font-size: calc(${(p) => p.$size} / 3.7);
  font-family: "Unbounded", serif;
  z-index: 1;
  background: radial-gradient(
      100% 100% at 50% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${(p) => p.$color};

  ${(p) =>
    p.onClick &&
    `
    &:hover {
      cursor: pointer;
    }`}
`;

function getInitials(name: string) {
  if (!name) return "";
  const parts = name.split(" ");
  if (parts.length === 1) {
    return parts[0].charAt(0);
  }
  if (parts.length >= 2) {
    return `${parts[0].charAt(0)}${parts[1].charAt(0)}`;
  }
}

type Props = {
  playerName: string | null;
  testId?: string;
  size?: string;
  color: string;
  visible?: boolean;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  onClick?: () => void;
};

const PlayerAvatarWithInitial: React.FC<Props> = ({
  playerName,
  size = "92px",
  testId,
  visible = true,
  color,
  onMouseEnter,
  onMouseOut,
  onClick,
}) => {
  const parsedSize = parseInt(size, 10);
  if (!playerName)
    return (
      <RunningWrapper $visible={visible} $size={size}>
        <Icon.PersonWalkingFilled size={`${parsedSize - 20}px`} />
      </RunningWrapper>
    );

  // Get First initial from name
  return (
    <AvatarWrapper
      $visible={visible}
      data-testid={testId}
      $size={size}
      $color={color}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      onClick={onClick}
    >
      {getInitials(playerName)}
    </AvatarWrapper>
  );
};

export default PlayerAvatarWithInitial;
