import { SvgProps } from "../../types/svg";

const LaughingIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_264_58)">
      <path
        d="M14.0469 11C14.4906 11 14.8156 11.3844 14.7281 11.8125C14.1625 13.9094 12.2594 15.5 10 15.5C7.74062 15.5 5.8375 13.9094 5.27187 11.8125C5.15937 11.3844 5.50938 11 5.95312 11H14.0469ZM13.6156 12H6.38438C6.96563 13.475 8.375 14.5 10 14.5C11.625 14.5 13.0344 13.475 13.6156 12ZM6.89062 7.89062C6.69375 8.11562 6.55313 8.41562 6.48438 8.68437C6.41875 8.95312 6.14687 9.11563 5.87812 9.04688C5.60937 8.98125 5.44687 8.70938 5.51562 8.44063C5.61563 8.04375 5.82188 7.59375 6.13438 7.23438C6.45 6.87187 6.9125 6.5625 7.5 6.5625C8.0875 6.5625 8.55 6.87187 8.86562 7.23438C9.17812 7.59375 9.38437 8.04375 9.48438 8.44063C9.55313 8.70938 9.39062 8.98125 9.12187 9.04688C8.85312 9.11563 8.58125 8.95312 8.51562 8.68437C8.44687 8.41562 8.30625 8.11562 8.10938 7.89062C7.91875 7.66875 7.7125 7.5625 7.5 7.5625C7.2875 7.5625 7.08125 7.66875 6.89062 7.89062ZM10.8781 9.04688C10.6094 8.98125 10.4469 8.70938 10.5156 8.44063C10.6156 8.04375 10.8219 7.59375 11.1344 7.23438C11.45 6.87187 11.9125 6.5625 12.5 6.5625C13.0875 6.5625 13.55 6.87187 13.8656 7.23438C14.1781 7.59375 14.3844 8.04375 14.4844 8.44063C14.5531 8.70938 14.3906 8.98125 14.1219 9.04688C13.8531 9.11563 13.5812 8.95312 13.5156 8.68437C13.4469 8.41562 13.3063 8.11562 13.1094 7.89062C12.9188 7.66875 12.7125 7.5625 12.5 7.5625C12.2875 7.5625 12.0812 7.66875 11.8906 7.89062C11.6937 8.11562 11.5531 8.41562 11.4844 8.68437C11.4188 8.95312 11.1469 9.11563 10.8781 9.04688ZM18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10ZM10 3C6.13438 3 3 6.13438 3 10C3 13.8656 6.13438 17 10 17C13.8656 17 17 13.8656 17 10C17 6.13438 13.8656 3 10 3Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
    <defs>
      <clipPath id="clip0_264_58">
        <rect
          width="16"
          height="16"
          fill="currentColor"
          className="fill-color"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default LaughingIcon;
