import { SvgProps } from "../../types/svg";

const SquareInfoIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99643 7.1875C10.3115 7.1875 10.5679 6.93515 10.5679 6.625C10.5679 6.31485 10.3115 6.0625 9.99643 6.0625C9.68136 6.0625 9.425 6.31485 9.425 6.625C9.425 6.93515 9.68214 7.1875 9.99643 7.1875ZM11.1429 13.375H10.285L10.2854 8.62539C10.2854 8.62477 10.2854 8.62601 10.2854 8.62539C10.2536 8.43906 10.1571 8.3125 10 8.3125H8.85714C8.69979 8.3125 8.57143 8.43885 8.57143 8.59375C8.57143 8.74865 8.7 8.875 8.85714 8.875H9.71357V13.375H8.85714C8.69979 13.375 8.57143 13.5014 8.57143 13.6562C8.57143 13.8111 8.7 13.9375 8.85714 13.9375H11.1429C11.3002 13.9375 11.4286 13.8111 11.4286 13.6562C11.4286 13.5014 11.3 13.375 11.1429 13.375ZM15.7143 2.125H4.28571C3.02357 2.125 2 3.13258 2 4.375V15.625C2 16.8674 3.02357 17.875 4.28571 17.875H15.7143C16.9768 17.875 18 16.8674 18 15.625V4.375C18 3.13258 16.975 2.125 15.7143 2.125ZM17.4286 15.625C17.4286 16.5556 16.6596 17.3125 15.7143 17.3125H4.28571C3.34036 17.3125 2.57143 16.5556 2.57143 15.625V4.375C2.57143 3.44441 3.34036 2.6875 4.28571 2.6875H15.7143C16.6596 2.6875 17.4286 3.44441 17.4286 4.375V15.625Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default SquareInfoIcon;
