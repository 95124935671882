import { styled } from "styled-components";
import { useReflow } from "../../../hooks/ui";
import { steps } from "../../../style/theme";
import HintUiNormal from "./Hint.ui.normal";
import HintUiSmall from "./Hint.ui.small";

export type Props = {
  visible?: boolean;
  onClose?: () => void;
  content: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  noAnimation?: boolean;
  isLow?: boolean;
};

export const DivFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > * + * {
    margin-inline-start: ${steps.spacing[2]};
  }
`;

const HintUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <HintUiNormal {...props} />,
    small: <HintUiSmall {...props} />,
  });
};

export default HintUi;
