import { SvgProps } from "../../types/svg";

const Warning: React.FC<SvgProps> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.2 11.0714C10.2 11.1893 10.11 11.2857 10 11.2857C9.89 11.2857 9.8 11.1893 9.8 11.0714V6.78571C9.8 6.66786 9.89 6.57143 10 6.57143C10.11 6.57143 10.2 6.66786 10.2 6.78571V11.0714ZM9.6 12.5714C9.6 12.3357 9.78 12.1429 10 12.1429C10.22 12.1429 10.4 12.3357 10.4 12.5714C10.4 12.8071 10.22 13 10 13C9.78 13 9.6 12.8071 9.6 12.5714ZM4.4 10C4.4 6.68661 6.9075 4 10 4C13.0925 4 15.6 6.68661 15.6 10C15.6 13.3134 13.0925 16 10 16C6.9075 16 4.4 13.3134 4.4 10ZM10 15.5714C12.8725 15.5714 15.2 13.0777 15.2 10C15.2 6.92232 12.8725 4.42857 10 4.42857C7.1275 4.42857 4.8 6.92232 4.8 10C4.8 13.0777 7.1275 15.5714 10 15.5714ZM2.4 10C2.4 11.575 2.82875 13.0429 3.56825 14.2777C3.628 14.3768 3.601 14.5107 3.508 14.575C3.415 14.6366 3.2915 14.6098 3.23175 14.508C2.452 13.2089 2 11.6366 2 10C2 8.33929 2.452 6.79107 3.23175 5.49089C3.2915 5.39125 3.415 5.36232 3.508 5.42634C3.601 5.49009 3.628 5.62268 3.56825 5.72232C2.82875 6.95714 2.4 8.425 2.4 10ZM18 10C18 11.6366 17.5475 13.2089 16.7675 14.508C16.7075 14.6098 16.585 14.6366 16.4925 14.575C16.3775 14.5107 16.3725 14.3768 16.4325 14.2777C17.17 13.0429 17.6 11.575 17.6 10C17.6 8.425 17.17 6.95714 16.4325 5.72232C16.3725 5.62268 16.3775 5.49009 16.4925 5.42634C16.585 5.36232 16.7075 5.39125 16.7675 5.49089C17.5475 6.79107 18 8.33929 18 10Z"
        fill="currentColor"
        className="fill-color"
      />
    </svg>
  );
};

export default Warning;
