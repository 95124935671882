import { useCallback } from "react";
import {
  useCurrentLoginBackgroundImage,
  useGetCurrentLevelVideoBySlug,
} from "../../../api/gyaradosHooks";
import { getImageUrl } from "../../../api/gyaradosUtils";
import { useStore } from "../../../store/store";
import FullscreenVideoUi from "./FullscreenVideo.ui";

type Props = {
  slug?: string;
};

const FullscreenVideoLogic: React.FC<Props> = ({ slug }) => {
  const subElements = useStore(
    (s) => s.layout.panels.fullscreenVideo.subElements
  );
  const levelVideo = useGetCurrentLevelVideoBySlug(slug);

  const backgroundImageSource = useCurrentLoginBackgroundImage();
  const backgroundImageUrl = getImageUrl(backgroundImageSource);
  const isMuted = useStore((s) => s.userMedia.muted);
  const visible = useStore((s) => s.layout.panels.fullscreenVideo.visible);
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const onVideoError = useCallback(() => {
    openPanel("fullscreenVideo/skip", {
      slug,
    });
  }, [openPanel, slug]);

  const hasSkipButton = subElements?.includes("fullscreenVideo/skip");

  const handleSkip = () => {
    closePanel("fullscreenVideo/skip", { slug });
    closePanel("fullscreenVideo", { slug });
  };

  const handleVideoEnded = useCallback(
    () => closePanel("fullscreenVideo/skip", { slug }),
    [closePanel, slug]
  );

  return (
    <FullscreenVideoUi
      isGameMuted={isMuted}
      videoURL={levelVideo?.videoURL}
      showingPlayer={visible}
      backgroundImageUrl={backgroundImageUrl}
      onError={onVideoError}
      showSkipButton={hasSkipButton}
      onSkipClick={handleSkip}
      isContained={levelVideo?.isContained}
      handleVideoEnded={handleVideoEnded}
    />
  );
};

export default FullscreenVideoLogic;
