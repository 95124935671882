export type IconDescriptor = {
  src: string;
  name: string;
  direction: keyof typeof HeaderBadgeAngles;
};

// To render the icons at the correct ripple line we unsync the animations by 6 seconds each time
export const UNSYNC_SECONDS = 6;

export const HeaderBadgeAngles = {
  deg0: "deg0",
  deg45: "deg45",
  deg90: "deg90",
  deg135: "deg135",
  deg180: "deg180",
} as const;

/* the x and y beginning and end values where not the same for each angle and needed to be calculated manually
  thus the usage of a lookup table where we explicitly define any angle.
*/
export const lookupTable: Record<
  keyof typeof HeaderBadgeAngles,
  { x1: number; x2: number; y1: number; y2: number }
> = {
  deg0: {
    x1: 40,
    x2: 360,
    y1: 0,
    y2: 0,
  },
  deg45: {
    x1: 25,
    x2: 280,
    y1: -25,
    y2: -280,
  },
  deg90: {
    x1: 0,
    x2: 0,
    y1: -40,
    y2: -360,
  },
  deg135: {
    x1: -25,
    x2: -280,
    y1: -25,
    y2: -280,
  },
  deg180: {
    x1: -40,
    x2: -360,
    y1: 0,
    y2: 0,
  },
};
