import { SvgProps } from "../../types/svg";

const HandWaveFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 3C12.3281 3 13 3.67281 13 4.5C13 4.77625 13.225 5 13.5 5C13.775 5 14 4.77625 14 4.5C14 3.12156 12.8781 2 11.5 2C11.225 2 11 2.22363 11 2.5C11 2.77637 11.225 3 11.5 3ZM4.5 15C3.67281 15 3 14.3281 3 13.5C3 13.225 2.77625 13 2.5 13C2.22375 13 2 13.225 2 13.5C2 14.8781 3.12125 16 4.5 16C4.77625 16 5 15.775 5 15.5C5 15.225 4.77625 15 4.5 15ZM16.75 5C16.0594 5 15.5281 5.55938 15.5281 6.22188V8.77812L9.325 2.60312C9.13125 2.40781 8.84688 2.31022 8.61875 2.31022C8.04688 2.31022 7.64687 2.77772 7.64687 3.28209C7.64687 3.53803 7.7445 3.79397 7.93975 3.98928L11.8304 7.87991C11.928 7.97753 11.9768 8.1055 11.9768 8.23334C11.9768 8.51947 11.743 8.70522 11.5049 8.70522C11.3769 8.70522 11.249 8.65641 11.1515 8.55878L6.49687 4.01719C6.30312 3.82188 6.04688 3.72438 5.79063 3.72438C5.25656 3.72438 4.81875 4.15438 4.81875 4.72438C4.81875 4.98031 4.91637 5.23625 5.11162 5.43156L9.67812 10.0281C9.77575 10.1257 9.82456 10.2537 9.82456 10.3816C9.82456 10.6676 9.59081 10.8534 9.35269 10.8534C9.22472 10.8534 9.09678 10.8046 8.99925 10.707L5.08281 6.84688C4.8875 6.65 4.63156 6.55312 4.37562 6.55312C3.80344 6.55312 3.37563 7.02094 3.37563 7.525C3.37563 7.78094 3.47325 8.03687 3.6685 8.23219L7.55912 12.1228C7.65675 12.2204 7.70556 12.3484 7.70556 12.4762C7.70556 12.7433 7.49066 12.9481 7.23369 12.9481C7.10572 12.9481 6.97778 12.8993 6.88025 12.8017L4.37594 10.3813C4.18062 10.1594 3.92469 10.0875 3.66875 10.0875C3.13469 10.0875 2.66875 10.5175 2.66875 11.0875C2.66875 11.3434 2.76637 11.5994 2.96162 11.7947L7.5585 16.3916C8.63069 17.4637 10.0391 18 11.4491 18C12.8591 18 14.266 17.4637 15.3398 16.3916L16.1732 15.5581C17.35 14.3781 18 12.8094 18 11.1406V6.22188C18 5.55938 17.4406 5 16.75 5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default HandWaveFilledIcon;
