import { useStore } from "../../store/store";
import CinematicModePanelUi from "./CinematicModePanelUi";

const CinematicModePanelLogic: React.FC = () => {
  const subElement = useStore((s) => s.layout.panels.cinematicView.subElements);
  const closePanel = useStore((s) => s.layout.closePanel);

  const handleClick = () => {
    closePanel("cinematicView/skip");
    closePanel("cinematicView");
  };

  const hasSkipButton = subElement?.includes("cinematicView/skip");

  return (
    <CinematicModePanelUi
      showSkipButton={hasSkipButton}
      onSkipClick={handleClick}
    />
  );
};

export default CinematicModePanelLogic;
