import React from "react";
import { SvgProps } from "../../types/svg";

const LinkedInIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.48142 17.75H2.16428V7.23472H5.48142V17.75ZM3.82107 5.80034C2.76035 5.80034 1.89999 4.9355 1.89999 3.89136C1.89999 3.38982 2.10239 2.90882 2.46266 2.55418C2.82293 2.19954 3.31157 2.00031 3.82107 2.00031C4.33057 2.00031 4.8192 2.19954 5.17947 2.55418C5.53974 2.90882 5.74214 3.38982 5.74214 3.89136C5.74214 4.9355 4.88142 5.80034 3.82107 5.80034ZM17.8964 17.75H14.5864V12.6312C14.5864 11.4113 14.5614 9.84683 12.8618 9.84683C11.1371 9.84683 10.8729 11.1722 10.8729 12.5433V17.75H7.55928V7.23472H10.7407V8.6691H10.7871C11.23 7.84292 12.3118 6.97105 13.9257 6.97105C17.2829 6.97105 17.9 9.14722 17.9 11.9738V17.75H17.8964Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default LinkedInIcon;
