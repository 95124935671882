import { SliceCreator } from "../../store/store";
import { sendGameMessage } from "../gameConnection/webrtc/webRtcMessageHandlers";

const sliceName = "i18n";

export interface I18nState {
  selectedLocaleCode: string | undefined;
  setSelectedLocaleCode: (code: string) => void;
}

type State = {
  i18n: I18nState;
};

export const createI18nSlice: SliceCreator<State> = (set) => ({
  i18n: {
    selectedLocaleCode: undefined,

    setSelectedLocaleCode: (code) =>
      set(
        (state) => {
          state.i18n.selectedLocaleCode = code;
          sendGameMessage({ type: "LanguageSelected", langCode: code });
        },
        false,
        sliceName + "/setSelectedLocaleCode"
      ),
  },
});
