import { memo } from "react";
import { useTheme } from "styled-components";
import Button from "../../../../../components-ui/atoms/Button";
import { PropsClickable } from "../../../../../components-ui/atoms/Clickable";
import Column from "../../../../../components-ui/atoms/Column";
import Space from "../../../../../components-ui/atoms/Space";
import Typo from "../../../../../components-ui/atoms/Typo";

type Props = {
  active: boolean;
  error?: boolean;
  disabled: boolean;
  onText: string;
  offText: string;
  errorText?: string;
  testId?: string;
  label: string;
  onIcon: React.ReactNode;
  offIcon: React.ReactNode;
  onClick: () => void;
};

const ConferenceControlButton: React.FC<Props> = ({
  active,
  onText,
  offText,
  disabled,
  error,
  errorText,
  testId,
  label,
  onIcon,
  offIcon,
  onClick,
}) => {
  const theme = useTheme();

  const overrides: PropsClickable = {
    width: "48px",
    height: "48px",
  };

  let buttonText;
  switch (true) {
    case error:
      buttonText = errorText;
      break;
    case active:
      buttonText = onText;
      break;
    case !active:
      buttonText = offText;
      break;
    case disabled:
      buttonText = "";
      break;

    default:
      buttonText = "";
      break;
  }
  return (
    <Column
      width="auto"
      justify="center"
      align="center"
      style={{
        width: "50px",
      }}
    >
      <Button.Purple
        testId={testId}
        circular
        disabled={disabled || error}
        onClick={onClick}
        silent={!active}
        override={overrides}
      >
        {active ? onIcon : offIcon}
      </Button.Purple>
      <Space h={2} />
      <Typo.Note color={theme.colorAbove4}>{label}</Typo.Note>
      <Typo.Note align="center">{buttonText}</Typo.Note>
    </Column>
  );
};

export default memo(ConferenceControlButton, (prev, next) => {
  return (
    prev.active === next.active &&
    prev.disabled === next.disabled &&
    prev.testId === next.testId &&
    prev.error === next.error &&
    prev.label === next.label
  );
});
