import React, { useCallback, useRef } from "react";
import {
  ReactZoomPanPinchProps,
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { css, styled } from "styled-components";
import { useIsLandscape } from "../../hooks/ui";
import { steps } from "../../style/theme";
import CloseButton from "../molecules/CloseButton";

const MIDDLE_POINT_ID = "middle-point";

const CloseButtonWrapper = styled.div<{ $isLandscape: boolean }>`
  position: absolute;
  top: 8px;
  right: 8px;
  ${(p) =>
    p.$isLandscape &&
    css`
      right: max(var(--safe-margin-right), ${steps.spacing[9]});
      top: max(var(--safe-margin-top), ${steps.spacing[6]});
    `}
`;

const MiddlePoint = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

type Props = {
  image: React.ReactNode;
  onClose?: () => void;
  minScale?: number;
  initialZoomedId?: string;
  initialScale?: number;
  /* Components that will move with the image */
  children?: React.ReactNode;
} & ReactZoomPanPinchProps;

/**
 * A fullscreen wrapper that can pan and zoom an image.
 */
const FullscreenPanZoom: React.FC<Props> = ({
  image,
  onClose,
  minScale = 1,
  initialScale,
  initialZoomedId = "middle",
  children,
}) => {
  const isLandscape = useIsLandscape();
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const zoom = useCallback(() => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current;
      setTimeout(() => {
        zoomToElement(
          initialZoomedId || MIDDLE_POINT_ID,
          1,
          1200,
          "easeOutQuart"
        );
      }, 800);
    }
  }, [initialZoomedId]);

  return (
    <>
      <TransformWrapper
        initialScale={initialScale || minScale}
        maxScale={1}
        minScale={minScale}
        disablePadding
        centerOnInit
        doubleClick={{
          disabled: true,
        }}
        ref={transformComponentRef}
        onInit={() => {
          zoom();
        }}
      >
        <TransformComponent
          wrapperStyle={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        >
          {image}
          {children}
          <MiddlePoint id={MIDDLE_POINT_ID} />
        </TransformComponent>
      </TransformWrapper>
      <CloseButtonWrapper $isLandscape={isLandscape}>
        <CloseButton isGlass onClick={onClose} />
      </CloseButtonWrapper>
    </>
  );
};

export default FullscreenPanZoom;
