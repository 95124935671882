export const linkify = (text: string): string => {
  const words = text.split(" ");
  const linkRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gim;

  const sanitize = (link: string): string => {
    return (link || "").replace("http://", "").replace("https://", "");
  };

  const replace = (
    string: string,
    regex: RegExp,
    computedValue: (value: string) => string
  ) => {
    const [match] = string.match(regex) || [];
    return match ? string.replace(match || "", computedValue(match)) : string;
  };

  return words
    .map((word) => {
      const linkified = replace(
        word,
        linkRegex,
        (match: string) =>
          `<a href="https://${sanitize(match)}" target="_blank">${match}</a>`
      );

      const mailified = replace(
        linkified,
        emailRegex,
        (match: string) => `<a href="mailto:${sanitize(match)}">${match}</a>`
      );

      return mailified;
    })
    .join(" ");
};
