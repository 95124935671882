import { FromGameMessage } from "../../../core/gameConnection/messages/fromGameMessages";
import { SliceCreator } from "../../../store/store";

export interface MapState {
  /* Active region with category Map */
  activeRegion: string;
  dispatchMapMessage: (message: FromGameMessage) => void;
}

type State = {
  map: MapState;
};

export const createMapSlice: SliceCreator<State> = (set) => ({
  map: {
    activeRegion: "",
    dispatchMapMessage: (message: FromGameMessage) => {
      set((state) => {
        if (message.type === "ActiveRegion" && message.categoryId === "Map") {
          state.map.activeRegion = message.regionId;
        }
      });
    },
  },
});
