import { SvgProps } from "../../types/svg";

const ThumbsDownIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 10.75V4.25C6 3.56063 5.44063 3 4.75 3H3.25C2.56063 3 2 3.56063 2 4.25V10.75C2 11.4406 2.56063 12 3.25 12H4.75C5.44063 12 6 11.4406 6 10.75ZM5 10.75C5 10.8875 4.88781 11 4.75 11H3.25C3.11219 11 3 10.8875 3 10.75V4.25C3 4.11219 3.11219 4 3.25 4H4.75C4.88781 4 5 4.11219 5 4.25V10.75ZM16.9344 9.36562C17.0115 9.15712 17.0516 8.93531 17.0516 8.71C17.0516 7.99219 16.6531 7.35312 16.0497 7.02437C16.0853 6.87837 16.1039 6.72847 16.1039 6.57844C16.1039 5.88906 15.7357 5.26781 15.1561 4.92844C15.1562 3.87 14.2938 3 13.2344 3H11.4969C9.07781 3 7 4.97219 7 5.50219C7 5.77616 7.22291 6.00094 7.5 6.00094C8.11875 6 8.94375 4 11.4969 4H13.2353C13.7437 4 14.1572 4.41313 14.1572 4.92094C14.1572 5.07175 14.1202 5.12378 14.1202 5.23375C14.1202 5.88813 15.1048 5.61156 15.1048 6.57906C15.1048 6.98031 14.8934 7.03781 14.8934 7.31531C14.8934 7.60741 15.1202 7.77344 15.3016 7.8075C15.7366 7.88856 16.0525 8.26844 16.0525 8.71031C16.0525 9.26562 15.6756 9.28688 15.6756 9.6675C15.6756 9.92731 15.877 10.1481 16.1416 10.1663C16.6219 10.2 17 10.6 17 11.0781C17 11.5875 16.5875 12 16.0781 12H12.0688C11.8102 12 11.5969 12.2081 11.5969 12.5003C11.5969 12.5847 11.6181 12.669 11.6608 12.745C12.2149 13.7303 12.4927 14.7884 12.4927 15.1038C12.4656 15.475 12.175 16 11.5281 16C11.0062 16 10.9137 15.8452 10.6287 14.9756C9.94781 12.9003 9.49937 12.8047 7.85344 11.1475C7.75625 11.05 7.62812 11 7.5 11C7.23125 11 7 11.2156 7 11.4719C7 12.1984 8.81062 12.6134 9.67906 15.2594C9.94375 16.0906 10.2375 17 11.5281 17C12.7397 17 13.4659 16.0362 13.4659 15.1041C13.4659 14.6716 13.2521 13.8456 12.8775 13H16.0806C17.1375 13 18 12.1375 18 11.0781C18 10.3375 17.5687 9.68125 16.9344 9.36562Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ThumbsDownIcon;
