import { SetStateAction } from "react";
import styled, { keyframes } from "styled-components";
import Button from "../../../../components-ui/atoms/Button";
import Icon from "../../../../components-ui/atoms/Icon";
import { MapPoint } from "../Map.logic";

const iconSize = "15px";

const Wrapper = styled.div<{ $left: number; $top: number }>`
  position: absolute;
  left: ${(p) => p.$left}%;
  top: ${(p) => p.$top}%;
`;

const ButtonWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const rippling = keyframes`
  0% {
    height: 0;
    width: 0;
    opacity: 1;
    filter: blur(0);
  }
  100% {
    height: 160px;
    width: 160px;
    opacity: 0;
    filter: blur(2px);
  }`;

const Dot = styled.div`
  width: 13px;
  height: 13px;
  background-color: ${(p) => p.theme.colorInfo};
  border-radius: 50%;
  border: 3px solid white;
`;

const Ripple = styled.div<{ $color?: string; $lineWidth?: number }>`
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid white;
  opacity: 0;
  &.first {
    animation: ${rippling} 8s linear 0s infinite;
  }
  &.second {
    animation: ${rippling} 8s linear 4s infinite;
  }
`;

const LocationWrapper = styled.div`
  position: absolute;
  top: 30px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  z-index: 1;
`;

type Props = {
  point: MapPoint;
  selectedPoint: MapPoint;
  onSelectPoint: (point: MapPoint) => void;
  activeRegion?: string;
  onTeleport: (mapPoint: MapPoint) => void;
  setPointRef?: React.Dispatch<SetStateAction<HTMLDivElement | null>>;
  isSmallScreen?: boolean;
};

const MapPointUi: React.FC<Props> = ({
  point,
  selectedPoint,
  onSelectPoint,
  activeRegion,
  setPointRef,
  onTeleport,
  isSmallScreen,
}) => {
  const onMouseEnter = () => {
    const selectedPointName = selectedPoint?.name;
    if (selectedPointName !== point.name) onSelectPoint(point);
  };
  const onClick = () => {
    if (isSmallScreen) onSelectPoint(point);
    else onTeleport(point);
  };

  return (
    <Wrapper
      ref={(el) => {
        if (activeRegion === point.regionId && el && setPointRef) {
          setPointRef(el);
        }
      }}
      key={point.name}
      $left={point.left}
      $top={point.top}
      onMouseEnter={onMouseEnter}
    >
      <ButtonWrapper id={point.regionId}>
        <Button.Bright
          iconLeft={
            activeRegion === point.regionId && (
              <Icon.Arrow inheritColor size={iconSize} />
            )
          }
          onClick={onClick}
          gap={1}
        >
          {point.name}
        </Button.Bright>
      </ButtonWrapper>
      {point.regionId === activeRegion && (
        <>
          <LocationWrapper>
            <Ripple className="first" />
          </LocationWrapper>
          <LocationWrapper>
            <Ripple className="second" />
          </LocationWrapper>
          <LocationWrapper>
            <Dot />
          </LocationWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default MapPointUi;
