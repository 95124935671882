import { useMemo } from "react";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { useCurrentTextChatChannel } from "../../panels/social/subpages/chat/textChatHooks";

const useReactionStartPosition = () => {
  const {
    environment: { disableChat, movements },
  } = useEnvironmentContext();
  const { type } = useCurrentTextChatChannel();
  const isSmartChat = type === "smartChat";
  const hasMovements =
    movements && Object.values(movements).find((enabled) => enabled);
  const showSocialButton = !disableChat || isSmartChat;

  const startPosition = useMemo(() => {
    let buttons = 0;
    if (showSocialButton) {
      buttons++;
    }
    if (hasMovements) {
      buttons++;
    }

    // 48px button + 8px margin
    return buttons * 56;
  }, [hasMovements, showSocialButton]);

  return startPosition;
};

export default useReactionStartPosition;
