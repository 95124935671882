import { GyaradosAxios } from "@journee-live/gyarados";

const useGetPollResultsApi = () => {
  return new GyaradosAxios.PollResultsApi(
    undefined,
    import.meta.env.VITE_GYARADOS_URL
  );
};

export default useGetPollResultsApi;
