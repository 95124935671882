import { SvgProps } from "../../types/svg";

const GemIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9093 8.2125L14.4093 3.2125C14.3156 3.07969 14.1625 3 14 3H5.97184C5.83747 3 5.68434 3.07969 5.59059 3.21344L2.09059 8.21344C1.95484 8.40728 1.9734 8.66937 2.13453 8.84188L9.63453 16.8419C9.73122 16.9438 9.86247 17 9.99997 17C10.1375 17 10.2698 16.9429 10.3647 16.8418L17.8647 8.84181C18.0281 8.66875 18.0437 8.40625 17.9093 8.2125ZM13.9812 4.34781L16.5406 8H11.2L13.9812 4.34781ZM9.99997 7.925L7.00934 4H12.9906L9.99997 7.925ZM6.01872 4.34781L8.79997 8H3.46028L6.01872 4.34781ZM9.99997 15.7688L3.65403 9H16.3447L9.99997 15.7688Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default GemIcon;
