import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import { useEnvironmentContext } from "../../core/EnvironmentFetcher.core";
import { steps } from "../../style/theme";

export const TypographyNames = {
  Note: "Note",
  Body: "Body",
  Label: "Label",
  Subtitle: "Subtitle",
  Title: "Title",
} as const;

type TypographyName = (typeof TypographyNames)[keyof typeof TypographyNames];

type CustomProps = {
  /** Css color */
  color?: CSSProperties["color"];
  fontFamily?: CSSProperties["fontFamily"];
  size?: CSSProperties["fontSize"];
  spacing?: CSSProperties["letterSpacing"];
  lineHeight?: CSSProperties["lineHeight"];
  weight?: CSSProperties["fontWeight"];
  align?: CSSProperties["textAlign"];
  casing?: CSSProperties["textTransform"];
  noWrap?: boolean;
  /** A tooltip */
  title?: string;
  globalFontsResize?: number;
  fontMainOffset?: number;
  fontDisplayOffset?: number;
};

const applyCustomProps = (props: CustomProps): string => {
  let result = "";
  if (props.color) result += `color: ${props.color};`;
  if (props.align) result += `text-align: ${props.align};`;
  if (props.casing) result += `text-transform: ${props.casing};`;
  if (props.fontFamily) result += `font-family: ${props.fontFamily};`;
  if (props.size) result += `font-size: ${props.size};`;
  if (props.weight) result += `font-weight: ${props.weight};`;
  if (props.spacing) result += `letter-spacing: ${props.spacing};`;
  if (props.lineHeight) result += `line-height: ${props.lineHeight};`;
  if (props.noWrap)
    result += `overflow: hidden; text-overflow: ellipsis; white-space: nowrap; text-wrap: nowrap`;

  return result;
};

// All rem-to-px annotation are based on a root font size of 16px.

const Note = styled.span<{
  $props: CustomProps;
}>`
  font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  font-size: calc(
    ${(p) => p.$props.globalFontsResize} * ${steps.font.f10.size}
  );
  line-height: ${steps.font.f10.lineHight};
  font-weight: ${steps.fontWeight.semiBold};
  letter-spacing: 0;
  word-break: break-word;
  color: ${(p) => p.theme.colorAbove2};
  display: block;
  padding-top: ${(p) => p.$props.fontMainOffset}rem;
  ${(p) => applyCustomProps(p.$props)};
`;

const Body = styled.span<{
  $props: CustomProps;
}>`
  font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  font-size: calc(
    ${(p) => p.$props.globalFontsResize} * ${steps.font.f20.size}
  );
  line-height: ${steps.font.f20.lineHight};
  font-weight: ${steps.fontWeight.medium};
  letter-spacing: 0;
  word-break: break-word;
  color: ${(p) => p.theme.colorAbove3};
  display: block;
  padding-top: ${(p) => p.$props.fontMainOffset}rem;
  ${(p) => applyCustomProps(p.$props)};
`;

const Label = styled.span<{
  $props: CustomProps;
}>`
  font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  font-size: calc(
    ${(p) => p.$props.globalFontsResize} * ${steps.font.f20.size}
  );
  line-height: ${steps.font.f20.lineHight};
  font-weight: ${steps.fontWeight.semiBold};
  letter-spacing: 0;
  word-break: break-word;
  color: ${(p) => p.theme.colorAbove4};
  display: block;
  padding-top: ${(p) => p.$props.fontMainOffset}rem;
  ${(p) => applyCustomProps(p.$props)};
`;

const Title = styled.span<{
  $props: CustomProps;
}>`
  font-family: ${(p) => p.theme.fontDisplay},
    ${(p) => p.theme.fontDisplayFallback};
  font-size: ${steps.font.f70.size};
  line-height: ${steps.font.f70.lineHight};
  font-weight: ${steps.fontWeight.light};
  letter-spacing: 0;
  word-break: break-word;
  color: ${(p) => p.theme.colorAbove5};
  display: block;
  padding-top: ${(p) => p.$props.fontDisplayOffset}rem;
  ${(p) => applyCustomProps(p.$props)};
`;

const Subtitle = styled.span<{
  $props: CustomProps;
}>`
  font-family: ${(p) => p.theme.fontDisplay},
    ${(p) => p.theme.fontDisplayFallback};
  font-size: ${steps.font.f50.size};
  line-height: ${steps.font.f50.lineHight};
  font-weight: ${steps.fontWeight.light};
  letter-spacing: 0;
  word-break: break-word;
  color: ${(p) => p.theme.colorAbove5};
  display: block;
  padding-top: ${(p) => p.$props.fontDisplayOffset}rem;
  ${(p) => applyCustomProps(p.$props)};
`;

type TypographyElement = typeof Title;

const typographyComponents: Record<TypographyName, TypographyElement> = {
  Body,
  Note,
  Label,
  Subtitle,
  Title,
};

export type BaseTypoProps = {
  testId?: string;
  type?: TypographyName;
  /** React style for the component. Useful for code driven animations. */
  style?: CSSProperties;
  children?: ReactNode;
} & CustomProps;

/** The main component to place text in the application. */
const BaseTypo: React.FC<BaseTypoProps> = ({
  type,
  style,
  children,
  testId,
  ...customProps
}) => {
  const { environment } = useEnvironmentContext();
  const globalFontsResize = environment?.globalFontsResize || 100;

  if (!type) return null;

  const Component = typographyComponents[type];
  const tooltip = customProps.noWrap ? children + "" : undefined;
  customProps.globalFontsResize = globalFontsResize / 100;
  customProps.fontMainOffset = environment?.fontMain.offset || 0;
  customProps.fontDisplayOffset = environment?.fontDisplay.offset || 0;

  return (
    <Component
      title={tooltip}
      style={style}
      $props={customProps}
      data-testid={testId}
    >
      {children}
    </Component>
  );
};

// Below is a cheeky way to give the Typo component this sexy API: <Typo.Body />
const typographyNameList = Object.keys(typographyComponents);

const typographyArray = typographyNameList.map((name) => {
  const typoName = name as TypographyName;
  const specificTypo: React.FC<BaseTypoProps> = (props) => (
    <BaseTypo {...props} type={typoName} />
  );
  return [typoName, specificTypo];
});

const Typo = Object.fromEntries(typographyArray) as Record<
  TypographyName,
  typeof BaseTypo
>;

export default Typo;
