export const Locales = {
  "cs-CZ": "cs-CZ",
  "da-DK": "da-DK",
  "de-AT": "de-AT",
  "de-DE": "de-DE",
  "el-GR": "el-GR",
  "es-CL": "es-CL",
  "es-ES": "es-ES",
  "es-MX": "es-MX",
  "es-419": "es-419",
  "en-AU": "en-AU",
  "en-CA": "en-CA",
  "en-GB": "en-GB",
  "en-IN": "en-IN",
  "en-MY": "en-MY",
  "en-NZ": "en-NZ",
  "en-SG": "en-SG",
  "en-US": "en-US",
  "en-ZA": "en-ZA",
  "fi-FI": "fi-FI",
  "fr-BE": "fr-BE",
  "fr-CH": "fr-CH",
  "fr-FR": "fr-FR",
  "he-IL": "he-IL",
  "hu-HU": "hu-HU",
  "it-IT": "it-IT",
  "ja-JP": "ja-JP",
  "ko-KR": "ko-KR",
  "nl-NL": "nl-NL",
  "nb-NO": "nb-NO",
  "pl-PL": "pl-PL",
  "pt-BR": "pt-BR",
  "ro-RO": "ro-RO",
  "sv-SE": "sv-SE",
  "th-TH": "th-TH",
  "tr-TR": "tr-TR",
  "zh-CN": "zh-CN",
  "zh-HK": "zh-HK",
  "zh-TW": "zh-TW",
  cs: "cs",
  da: "da",
  de: "de",
  en: "en",
  el: "el",
  es: "es",
  fi: "fi",
  fr: "fr",
  he: "he",
  hu: "hu",
  it: "it",
  ja: "ja",
  ko: "ko",
  nl: "nl",
  no: "no",
  pl: "pl",
  pt: "pt",
  ro: "ro",
  sv: "sv",
  th: "th",
  tr: "tr",
  zh: "zh",
} as const;

export type Locale = (typeof Locales)[keyof typeof Locales];
