import { useTheme } from "styled-components";
import Button from "../atoms/Button";
import { PropsClickable } from "../atoms/Clickable";
import Icon from "../atoms/Icon";

export type Props = {
  isGlass?: boolean;
  disabled?: boolean;
  override?: PropsClickable;
  rotate?: string;
  onClick?: React.MouseEventHandler;
  size?: string;
  testId?: string;
};

const ArrowButton: React.FC<Props> = ({ isGlass, rotate, size, ...props }) => {
  const theme = useTheme();
  const icon = (
    <Icon.Chevron
      rotate={rotate || "-90deg"}
      inheritColor
      size={size ? `calc(${size} * 0.45)` : "13px"}
      shiftY="-1px"
    />
  );

  return isGlass ? (
    <Button.Glass
      circular
      override={{
        color: theme.colorAbove4,
        ...(size && { width: size, height: size }),
      }}
      {...props}
    >
      {icon}
    </Button.Glass>
  ) : (
    <Button.Tertiary
      circular
      override={{
        color: theme.colorAbove4,
        ...(size && { width: size, height: size }),
      }}
      {...props}
    >
      {icon}
    </Button.Tertiary>
  );
};

export default ArrowButton;
