import React from "react";
import { SvgProps } from "../../types/svg";

const YammerIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.4818 4.02952C8.79445 2.81766 7.4927 2 6 2C3.79086 2 2 3.79086 2 6V12.25H2.00434C2.11165 15.3986 3.90528 18 7.25 18C8.30445 18 9.2692 17.6612 10.012 17.1002C10.7898 17.6744 11.7771 18 12.75 18C13.1749 18 13.5852 17.945 13.9724 17.8426C17.1637 17.1099 17.9298 13.996 17.9952 12.25H18V6C18 3.79086 16.2092 2 14 2C12.5073 2 11.2055 2.81766 10.5182 4.02952C10.3482 4.01002 10.1752 4 10 4C9.82475 4 9.6518 4.01002 9.4818 4.02952ZM8.45325 4.27287C7.9102 3.50291 7.01385 3 6 3C4.34315 3 3 4.34315 3 6V7.47515C3.68101 8.6288 4.5823 9.75205 5.84984 10.2427C5.62452 9.70665 5.5 9.11785 5.5 8.5C5.5 6.55818 6.72993 4.90363 8.45325 4.27287ZM7.12605 10.4981C7.16705 10.4994 7.2084 10.5 7.25 10.5C8.29875 10.5 9.2588 10.8352 10 11.3907C10.7412 10.8352 11.7012 10.5 12.75 10.5C12.7916 10.5 12.833 10.4994 12.874 10.4981C13.2686 9.9316 13.5 9.2428 13.5 8.5C13.5 6.56701 11.933 5 10 5C8.067 5 6.5 6.56701 6.5 8.5C6.5 9.2428 6.7314 9.9316 7.12605 10.4981ZM14.1502 10.2427C15.4177 9.75205 16.319 8.6288 17 7.47515V6C17 4.34315 15.6568 3 14 3C12.9862 3 12.0898 3.50291 11.5468 4.27287C13.2701 4.90363 14.5 6.55818 14.5 8.5C14.5 9.11785 14.3755 9.70665 14.1502 10.2427ZM12.75 17C12.005 17 11.3002 16.7723 10.7387 16.3923C11.2186 15.785 11.5 15.0465 11.5 14.25C11.5 13.4484 11.2149 12.7055 10.7294 12.096C11.2688 11.7287 11.9599 11.5 12.75 11.5C14.68 11.5 16.0373 10.4515 17 9.24685V12.1038L16.9959 12.2126C16.9671 12.983 16.7813 14.0326 16.2798 14.9444C15.7939 15.8278 15.0203 16.576 13.7486 16.868L13.7325 16.8716L13.7166 16.8758C13.4129 16.9562 13.0885 17 12.75 17ZM3 12.1055L3.00376 12.216C3.05164 13.6208 3.474 14.8262 4.17776 15.6612C4.86211 16.4731 5.86373 17 7.25 17C9.1662 17 10.5 15.6549 10.5 14.25C10.5 12.8451 9.1662 11.5 7.25 11.5C5.32002 11.5 3.9627 10.4515 3 9.24685V12.1055Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default YammerIcon;
