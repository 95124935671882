import styled from "styled-components";
import CardContentAlignment from "../../../components-ui/atoms/CardContentAlignment";
import Column from "../../../components-ui/atoms/Column";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import CardFullLandscape from "../../../components-ui/molecules/CardFullLandscape";
import { useText } from "../../../core/i18n/i18nHooks";
import { steps } from "../../../style/theme";
import { NudgeRight } from "../poll/Poll.ui.landscape";
import { Props } from "./Language.ui";
import LanguageCardHeader from "./component/LanguageCardHeader";
import LanguageCardList from "./component/LanguageCardList";

const Padding = styled.div`
  padding-left: ${steps.spacing[6]};
  padding-right: max(var(--safe-margin-right), 64px);
`;

const LanguagePanelSmallLandscapeUi: React.FC<Props> = ({
  handleDismiss,
  locales,
  currentLocale,
  onLanguageSelected,
}) => {
  const t = useText();

  return (
    <CardFullLandscape
      topSection={
        <NudgeRight>
          <LanguageCardHeader />
        </NudgeRight>
      }
      onClose={handleDismiss}
    >
      <Padding>
        <Space h={9} />
        <CardContentAlignment>
          <Column align="flex-start" style={{ padding: "0 12px" }}>
            <Typo.Title align="left">
              {t("settings_select_language_title")}
            </Typo.Title>
            <Space h={2} />
            <Typo.Body align="left">
              {t("settings_select_language_description")}
            </Typo.Body>
            <Space h={4} />
          </Column>
          <Space h={2} />
          <LanguageCardList
            padding="0px"
            locales={locales}
            currentLocale={currentLocale}
            onLanguageSelected={onLanguageSelected}
          />
        </CardContentAlignment>
      </Padding>
    </CardFullLandscape>
  );
};

export default LanguagePanelSmallLandscapeUi;
