import { styled } from "styled-components";
import { getImageUrl } from "../../../../api/gyaradosUtils";
import Hide from "../../../../components-ui/atoms/Hide";
import Icon from "../../../../components-ui/atoms/Icon";
import Image from "../../../../components-ui/atoms/Image";
import Markdown from "../../../../components-ui/atoms/Markdown";
import Space from "../../../../components-ui/atoms/Space";
import Spatial from "../../../../components-ui/atoms/Spatial";
import Tag from "../../../../components-ui/atoms/Tag";
import Typo from "../../../../components-ui/atoms/Typo";
import Card from "../../../../components-ui/molecules/Card";
import { useText } from "../../../../core/i18n/i18nHooks";
import { Body } from "../../infoCard/InfoCardPanel.ui.normal";
import { MapPoint } from "../Map.logic";

const Padding = styled.div`
  padding-left: 19px;
  padding-right: 19px;
`;

const TitleWrapper = styled.div<{ $bigTopPadding?: boolean }>`
  padding-top: 16px;
  padding-left: 19px;
  padding-right: 19px;
`;

const TagWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 1;
`;

export type Props = {
  selectedPoint: MapPoint;
  isCurrentLocation?: boolean;
  show: boolean;
  onClose?: () => void;
  onTeleport: (mapPoint: MapPoint) => void;
};

/*
 * Card that shows the Map point information when in normal sized screens
 * This component should be kept as similar as possible to the InfoCard.ui.normal component
 */
const MapCardUiNormal: React.FC<Props> = ({
  selectedPoint,
  isCurrentLocation,
  show,
}) => {
  const { image, title, description, label } = selectedPoint;
  const t = useText();
  const cardTitle = title || label;

  return (
    <Hide hide={!show}>
      <Spatial rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
        <Card
          topSection={
            selectedPoint.image && (
              <>
                {isCurrentLocation && (
                  <TagWrapper>
                    <Tag
                      text={t("map_user_location")}
                      iconLeft={<Icon.Arrow size="15px" />}
                    />
                  </TagWrapper>
                )}
                <Image
                  src={getImageUrl(image, 700, 700) ?? ""}
                  width="100%"
                  height="100%"
                  cover
                />
              </>
            )
          }
          topSectionHeight="190px"
          width="260px"
          style={{
            boxShadow: "-25px -15px 25px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Body>
            {cardTitle ? (
              <TitleWrapper>
                <Typo.Title size="18px" lineHeight="24px">
                  {cardTitle}
                </Typo.Title>
              </TitleWrapper>
            ) : (
              <Space h={2} />
            )}
            <Space h={2} />
            <Padding>
              {description && (
                <Markdown content={description} textAlign="left" />
              )}
              <Space h={4} />
            </Padding>
          </Body>
        </Card>
      </Spatial>
    </Hide>
  );
};

export default MapCardUiNormal;
