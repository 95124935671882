import { ProfileData } from "../../features/panels/profile/lib/profileTypes";
import { logError } from "../../lib/logger";
import { IResponseWithUrl } from "./logrocket";

export const ProfileDataKeys: (keyof ProfileData)[] = [
  "email",
  "firstName",
  "lastName",
  "company",
  "city",
  "country",
  "website",
  "twitter",
  "xing",
  "instagram",
  "linkedin",
  "facebook",
  "orgCode",
  "jobTitle",
  "userEmail",
  "msTeamsEmail",
  "guestEmail",
  "age",
];

/**
 * Strip a URL out of known PII and sensitive data.
 *
 * @param url The URL to be cleaned
 * @returns A URL cleaned of Journee sensitive data or PII.
 */
export const sanitizeURL = (
  url: string | undefined | null
): string | undefined => {
  let cleanedUrl = url;
  if (!cleanedUrl) return undefined;
  // Redact the value of user profile data (PII).
  ProfileDataKeys.map((key) => {
    if (cleanedUrl && cleanedUrl.indexOf(key) !== -1) {
      const findParameterRegex = new RegExp(`${key}=([a-zA-Z0-1]+)`, "gi");
      cleanedUrl = cleanedUrl.replace(findParameterRegex, `${key}=redacted`);
    }
  });
  // Redact the value of the query parameter jwt
  cleanedUrl = cleanedUrl.replace(/jwt=([^&]*)/, "jwt=**redacted**");
  return cleanedUrl;
};

/**
 * @param response A LogRocket response object.
 * @returns the parsed body if it's JSON.
 */
export const safelyParseJsonResponse = (
  response: IResponseWithUrl
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | null => {
  try {
    if (response.body && isJsonContentType(response.headers["content-type"]))
      return JSON.parse(response.body);
  } catch (e) {
    logError(
      "GENERIC",
      "Failed to parse JSON response for " + sanitizeURL(response.url)
    );
  }
  return null;
};

/**
 * @param headerValue A "content-type" header value to check
 * @returns true if headerValue indicates JSON.
 */
const isJsonContentType = (headerValue: string | undefined): boolean => {
  return Boolean(headerValue && headerValue.indexOf("application/json") !== -1);
};

export const removeEmailsFromString = (string?: string): string => {
  if (!string) return "";
  return string.replace(
    /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gm,
    "***"
  );
};
