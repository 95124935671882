import { SvgProps } from "../../types/svg";

const MicrophoneFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 10C13 11.6572 11.6572 13 10 13C8.31563 13 7 11.6562 7 10V5C7 3.34281 8.34281 2 10 2C11.6572 2 13 3.34281 13 5V10ZM15.5 10V8.72188C15.5 8.33438 15.1656 8 14.75 8C14.3344 8 14 8.33438 14 8.72188V10C14 12.2906 12.0625 14.1375 9.7125 13.9906C7.64875 13.8594 5.97188 11.9097 5.97188 9.84062L6 8.72188C6 8.33438 5.66406 8 5.25 8C4.83594 8 4.5 8.33438 4.5 8.72188V9.75313C4.5 12.5562 6.49906 15.0531 9.25 15.4328V16.5H8C7.43125 16.5 6.97375 16.975 7.00125 17.5491C7.01344 17.8057 7.24306 18 7.5 18H12.5C12.7563 18 12.9875 17.8062 12.9987 17.5491C13.0262 16.9747 12.5688 16.5 12 16.5H10.75V15.4438C13.4288 15.0781 15.5 12.7784 15.5 10Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MicrophoneFilledIcon;
