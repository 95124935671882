import { GyaradosSDK } from "@journee-live/gyarados";
import { getImageUrl } from "../../../../api/gyaradosUtils";
import ButtonCard from "../../../../components-ui/molecules/ButtonCard";
import { FromGameMessagesSchema } from "../../../../core/gameConnection/messages/fromGameMessages";
import QuestProgressCircle from "./QuestProgressCircle";

type QuestCardProps = {
  quest: GyaradosSDK.QuestResponseDto &
    FromGameMessagesSchema["QuestsInfo"]["quests"][number];
};

const QuestCard: React.FC<QuestCardProps> = ({ quest }) => {
  const imageSrc = getImageUrl(quest.image, 80, 80) ?? "";
  return (
    // TODO: Do not use ButtonCard here
    <ButtonCard
      width="380px"
      height="80px"
      title={quest.title}
      description={quest.summary ?? ""}
      imageSrc={imageSrc}
      padding="8px 16px 8px 8px"
      status={(isHovering) => (
        <QuestProgressCircle
          requiredAmount={quest.currencyNeededAmount}
          collectedAmount={quest.currencyCollectedAmount}
          isHovering={isHovering}
        />
      )}
    />
  );
};

export default QuestCard;
