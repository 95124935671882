/* eslint-disable @typescript-eslint/no-explicit-any */
import { Publisher } from "@opentok/client";
import { ActorRef, ActorRefFrom, createMachine, fromPromise } from "xstate";
import { ConferenceError } from "../../../hooks/useConferenceErrors";
import MediaDevicesService from "../mediaDevices";
import { cameraCheckerMachine } from "./cameraChecker.machine";

type CameraSelectorInput = {
  rootActor: ActorRef<any, any>;
  cameraCheckerRef: ActorRefFrom<typeof cameraCheckerMachine> | null;
  videoInputSource: MediaDeviceInfo;
  videoInputDevices: MediaDeviceInfo[];
  publisher: Publisher | null;
  mediaPermissions: { camera: boolean; microphone: boolean };
};

type CameraSelectorContext = CameraSelectorInput & {
  cameraCheckerRef: ActorRefFrom<typeof cameraCheckerMachine> | null;
};

const cameraSelectorMachine = createMachine(
  {
    types: {} as {
      input: CameraSelectorInput;
      context: CameraSelectorContext;
    },
    id: "cameraSelector",
    initial: "CheckingCameraFeed",
    context: ({ input }) => input,
    states: {
      CheckingCameraFeed: {
        invoke: {
          id: "checkingCameraFeed",
          src: "checkingCameraFeed",
          input: ({ context }) => {
            return context;
          },
          onDone: [
            {
              guard: "noCameraErrors",
              target: "SelectingCamera",
            },
            {
              actions: ["startCheckingForCamera"],
              target: "Success",
            },
          ],
        },
      },
      SelectingCamera: {
        invoke: {
          id: "cameraSelector",
          src: "cameraSelector",
          input: ({ context }) => {
            return context;
          },
          onDone: {
            target: "Success",
          },
          onError: {
            actions: "republishDevices",
            target: "Failure",
          },
        },
      },
      Failure: {
        type: "final",
      },
      Success: {
        type: "final",
      },
    },
  },
  {
    guards: {
      noCameraErrors: function ({ event }) {
        return event.output.length === 0;
      },
    },
    actions: {
      republishDevices: ({ context }) => {
        context.rootActor.send({
          type: "conference.republishDevices",
        });
      },
      startCheckingForCamera: ({ context }) => {
        context.cameraCheckerRef?.send({
          type: "lugia.startCheckingForCamera",
          data: {
            videoInputSource: context.videoInputSource,
            videoInputDevices: context.videoInputDevices,
            mediaPermissions: context.mediaPermissions,
          },
        });
      },
    },
    actors: {
      cameraSelector: fromPromise<void, CameraSelectorContext>(
        async ({ input }) => {
          // TODO handle further errors
          await input.publisher?.setVideoSource(
            input.videoInputSource?.deviceId
          );
        }
      ),
      cameraFeedChecker: fromPromise<ConferenceError[], CameraSelectorContext>(
        ({ input }) => {
          const hasCameras = input.videoInputDevices.length > 0;
          const hasCameraPermissions = input.mediaPermissions.camera;
          return MediaDevicesService.checkForCameraAvailability(
            hasCameras,
            hasCameraPermissions,
            input.videoInputSource?.deviceId
          );
        }
      ),
    },
  }
);

export default cameraSelectorMachine;
