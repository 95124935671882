import { useEffect } from "react";
import { listen, unlisten } from "../../lib/bus";
import { useStore } from "../../store/store";

export const useWebRTCStats = () => {
  const setStreamingStats = useStore((s) => s.gameConnection.setStreamingStats);
  useEffect(() => {
    listen("webRtcStreamingStats", setStreamingStats);
    return () => {
      unlisten("webRtcStreamingStats", setStreamingStats);
    };
  }, [setStreamingStats]);
};
