import { styled } from "styled-components";
import {
  SocialPanelSubPageName,
  matchPanelName,
} from "../../../core/layout/panelsTypes";
import { steps } from "../../../style/theme";
import { TabData } from "./Social.logic";

const Wrapper = styled.div<{ $faded: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  opacity: ${(p) => (p.$faded ? 0.6 : 1)};
  justify-content: center;
  pointer-events: none;
  z-index: 1;
`;

const Tabs = styled.div`
  padding: 8px 16px;
  width: 117px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-shadow: -8px 16px 32px ${(p) => p.theme.colorShadow};
  border-radius: ${(p) => p.theme.radiusFull};
  background-color: ${(p) => p.theme.colorBelow2};
  pointer-events: all;
`;

const Tab = styled.div`
  cursor: pointer;
  text-align: center;
  height: 20px;
  font-family: ${(p) => p.theme.fontMain};
  color: ${(p) => p.theme.colorAbove2};
  font-size: ${steps.font.f20.size};
  font-weight: ${steps.fontWeight.semiBold};
  line-height: ${steps.font.f20.lineHight};
  &.active {
    color: ${(p) => p.theme.colorAbove5};
  }
`;

export type Props = {
  data: TabData[];
  setCurrentTab: (tab: SocialPanelSubPageName) => void;
  currentTab?: SocialPanelSubPageName;
  previouslySelectedTab?: SocialPanelSubPageName;
};

const SocialTabs: React.FC<Props> = ({
  data,
  setCurrentTab,
  currentTab,
  previouslySelectedTab,
}) => {
  const templatePanelName = currentTab && matchPanelName(currentTab);
  const isCurrentTabHidden = data.find(
    (it) => it.key === templatePanelName
  )?.hiddenFromTabs;

  return (
    <Wrapper $faded={Boolean(isCurrentTabHidden)}>
      <Tabs>
        {data
          .filter((it) => !it.hiddenFromTabs)
          .map((it) => (
            <Tab
              key={it.key}
              onClick={() => setCurrentTab(it.key)}
              className={
                it.key === currentTab ||
                (isCurrentTabHidden && it.key === previouslySelectedTab)
                  ? "active"
                  : undefined
              }
            >
              {it.name}
            </Tab>
          ))}
      </Tabs>
    </Wrapper>
  );
};

export default SocialTabs;
