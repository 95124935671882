import styled from "styled-components";
import CardList from "./CardList";
import { Props } from "./SettingsHomePage.ui";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 4px;
  padding-left: max(var(--safe-margin-left), 8px);
  padding-right: max(var(--safe-margin-right), 8px);
  padding-bottom: max(var(--safe-margin-bottom), 32px);
  padding-top: max(var(--safe-margin-top), 32px);
`;

const SettingsHomePageUiPortrait: React.FC<Props> = (props) => {
  return (
    <Flex>
      <CardList {...props} />
    </Flex>
  );
};

export default SettingsHomePageUiPortrait;
