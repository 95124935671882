import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components-ui/atoms/Button";
import Column from "../../components-ui/atoms/Column";
import InputText from "../../components-ui/atoms/InputText";
import Space from "../../components-ui/atoms/Space";
import Typo from "../../components-ui/atoms/Typo";
import Card from "../../components-ui/molecules/Card";
import DropdownSelect from "../../components-ui/molecules/DropdownSelect";
import IframeAdapter from "../../features/iframe/IframeAdapter";
import { useStore } from "../../store/store";
import { steps } from "../../style/theme";

const Container = styled.div`
  position: absolute;
  top: 0;
  pointer-events: all;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type customMessageType = {
  type: string;
};

const IframeMessageTester: React.FC = () => {
  const { envSlug } = useParams();
  const closePanel = useStore((s) => s.layout.closePanel);
  const [iframeAdapterInstance, setIframeAdapterInstance] =
    useState<IframeAdapter | null>(null);
  const [messageTypes, setMessageTypes] = useState<
    customMessageType[] | null
  >();
  const [selectedMessageType, setSelectedMessageType] = useState("0");
  const [messageData, setMessageData] = useState({});
  const [messageError, setMessageError] = useState("");
  const [iframeError, setIframeError] = useState("");

  const handleClick = () => {
    iframeAdapterInstance?.sendMessageToParent({
      source: "Journee",
      ...messageData,
    });
  };

  useEffect(() => {
    const org = envSlug?.split("-").shift();
    const importMessages = async () => {
      if (org) {
        try {
          const messages = (await import(`../../assets/messages/${org}.json`))
            .default;
          setMessageTypes(messages);
        } catch {
          setMessageError(
            "Please add your organisation at the start of your environment name e.g. 'journee-demo'"
          );
        }
      }
    };

    importMessages();
    setIframeAdapterInstance(IframeAdapter.getInstance());

    closePanel("profile");
    closePanel("actionBar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messageTypes)
      setMessageData(messageTypes[parseInt(selectedMessageType)]);
  }, [messageTypes, selectedMessageType]);

  return (
    <Container>
      <Card>
        <Column
          align="center"
          style={{
            padding: `0 ${steps.spacing[5]}`,
            boxSizing: "border-box",
          }}
        >
          <Space h={8} />
          {messageTypes ? (
            <DropdownSelect
              label="type"
              onSelect={(value) => {
                setSelectedMessageType(value);
                setMessageData({});
              }}
              selected={selectedMessageType}
              options={messageTypes.map(({ type }, index) => ({
                value: index.toString(),
                label: type,
              }))}
            />
          ) : (
            <Typo.Body style={{ textAlign: "center" }}>
              {messageError}
            </Typo.Body>
          )}
          {Object.entries(messageData).map(([key, value]) => {
            if (key === "type") {
              return null;
            }
            return (
              <>
                <Space h={2} />
                <InputText
                  noForm
                  name={key}
                  label={key}
                  value={value as string}
                  onChange={(value) =>
                    setMessageData((prev) => ({ ...prev, [key]: value }))
                  }
                />
              </>
            );
          })}
          <Space h={8} />
          <Button.Primary
            onClick={handleClick}
            disabled={Boolean(messageError)}
          >
            Send message
          </Button.Primary>

          <Space h={3} />

          <Button.Subtle
            onClick={() => {
              const iframe =
                window?.parent?.document?.getElementById("journee-iframe");
              if (iframe)
                (iframe as HTMLIFrameElement).src =
                  `https://embed.next.journee.live/${envSlug}`;
              else
                setIframeError(
                  "Iframe not found. Please set the iframe id to journee-iframe."
                );
            }}
          >
            Go to Experience
          </Button.Subtle>
          {iframeError && (
            <>
              <Space h={3} />
              <Typo.Body style={{ textAlign: "center" }}>
                {iframeError}
              </Typo.Body>
            </>
          )}
          <Space h={8} />
        </Column>
      </Card>
    </Container>
  );
};

export default IframeMessageTester;
