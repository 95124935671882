import { useEffect, useMemo, useState } from "react";
import FileSaver from "file-saver";
import useSWRImmutable from "swr/immutable";
import {
  isAndroid,
  isIos,
  isMobile,
  isSafariIos,
  isSocialMediaBrowser,
} from "../../../constants/flags";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { useText } from "../../../core/i18n/i18nHooks";
import { logError, logWarn } from "../../../lib/logger";
import { useStore } from "../../../store/store";
import { MediaCaptureService, ShareTarget } from "../lib/MediaCapture.service";
import MediaShareUi from "./MediaShare.ui";
import sharingLoadingPageHtml from "./components/LoadingPageHtml";
import { Preview } from "./components/Preview";
import { ShareButtonData } from "./components/ShareButton";

const MediaShareLogic: React.FC = () => {
  const t = useText();
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const visible = useStore((s) => s.layout.panels.mediaShare.visible);
  const mediaShareState = useStore((s) => s.mediaCapture.mediaShareState);
  const setMediaShareState = useStore(
    (state) => state.mediaCapture.setMediaShareState
  );
  const mediaData = mediaShareState?.media;
  const mediaUrl = mediaShareState?.url;
  const noClose = mediaShareState?.noClose;
  const previousPanel = mediaShareState?.previousPanel;
  const visitorToken = useStore((s) => s.session.visitorToken);
  const { environment } = useEnvironmentContext();
  const canShare = Boolean(environment && mediaData);
  const canWebShare = Boolean(isMobile && !!navigator.canShare);
  const isSingleTabBrowser = isIos && (isSocialMediaBrowser || !isSafariIos);
  const [isSharingUrl, setIsSharingUrl] = useState(false);
  const [sharedUrl, setSharedUrl] = useState<string | null>(null);
  const [hasCopied, setHasCopied] = useState(false);
  const [errorSharedUrl, setErrorSharedUrl] = useState<string | null>(null);

  const handleClose = () => {
    closePanel("mediaShare");
  };

  const handleBack = () => {
    if (previousPanel) {
      openPanel(previousPanel, { options: { switchTab: false } });
    }
    closePanel("mediaShare");
  };

  // Reset the panel state when it is closed
  useEffect(() => {
    if (!visible) {
      setMediaShareState(undefined);
    }
  }, [visible, setMediaShareState]);

  // Pre-load file shared by Unreal
  const {
    data: loadedSharedFile,
    isLoading: isSharedFileLoading,
    error: errorSharedFile,
  } = useSWRImmutable(
    mediaUrl ?? null,
    async (url: string) => {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `An error occurred while fetching the data: ${response.status}`
        );
      }
      const blob = await response.blob();
      const extension = MediaCaptureService.getExtension(blob.type);
      return new File(
        [blob],
        MediaCaptureService.getFileName(environment?.metaTitle, extension),
        {
          type: blob.type,
        }
      );
    },
    {
      onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
        if (retryCount >= 10) return;
        // Retry after 1 seconds.
        setTimeout(() => revalidate({ retryCount }), 1000);
      },
    }
  );
  const sharedFile = useMemo(() => {
    if (mediaData) {
      return new File(
        [mediaData],
        MediaCaptureService.getFileName(
          environment?.metaTitle,
          MediaCaptureService.getExtension(mediaData.type)
        ),
        {
          type: mediaData.type,
        }
      );
    } else if (loadedSharedFile) {
      return loadedSharedFile;
    }
  }, [loadedSharedFile, mediaData, environment?.metaTitle]);

  const isVideo = sharedFile
    ? MediaCaptureService.isVideo(sharedFile.type)
    : false;

  // Reset the shared url when the shared file changes
  useEffect(() => {
    setSharedUrl(null);
  }, [sharedFile]);

  const handleShareUrl = async (target: ShareTarget) => {
    window.analytics?.track("share", {
      type: "share",
      name: target,
    });
    if (!sharedFile || !environment || !visitorToken) return;
    setIsSharingUrl(true);

    /*
     * Browser will block the popup if the window.open is not called from a user action
     * Since it might take a while to get the url, we open the window before we get the url
     */
    let newWindow: Window | undefined | null = undefined;
    if (target !== "copy") {
      newWindow = window.open("", "_blank");
      newWindow?.document.write(sharingLoadingPageHtml);
    }

    try {
      let url = sharedUrl;
      if (!url) {
        url = await MediaCaptureService.getShareUrl(
          sharedFile,
          environment?.id,
          visitorToken
        );
        setSharedUrl(url);
      }
      if (target === "copy") {
        await navigator.clipboard.writeText(url || t("photo_link_not_found"));
        setHasCopied(true);
      } else {
        MediaCaptureService.shareOnSocialMedia(
          target,
          url,
          environment,
          newWindow
        );
      }
    } catch (error: unknown) {
      logError("GENERIC", error);
      setErrorSharedUrl((error as Error).message);
    } finally {
      setIsSharingUrl(false);
    }
  };

  const handleSaveFile = () => {
    if (sharedFile) FileSaver.saveAs(sharedFile, sharedFile.name);
    window.analytics?.track("share", {
      type: "share",
      name: "download",
    });
  };

  const handleWebShareFile = async () => {
    if (sharedFile) {
      // Always share the media
      const payload: ShareData = { files: [sharedFile] };

      if (!isVideo && isAndroid) {
        // Try sharing the post prompt and url as text On Android Chrome
        payload.text = Object.entries(environment || {})
          .filter(
            ([key]) => key === "postText" || key === "socialRedirectionURL"
          )
          .map(([, value]) => value)
          .join(" ");
      }
      if (navigator.canShare(payload)) {
        navigator.share(payload).catch((error) => {
          if (error.name !== "AbortError") setErrorSharedUrl(error.message);
        });
      } else {
        logWarn("GENERIC", "Not able to share the payload");
      }
    } else {
      logWarn("GENERIC", "No shared file found");
    }

    window.analytics?.track("share", {
      type: "share",
      name: "webshare",
    });
  };

  const preview = useMemo(() => {
    if (!sharedFile) return;
    const dataUrl = URL.createObjectURL(sharedFile);
    return isVideo ? (
      <Preview as="video" src={dataUrl} loop autoPlay muted playsInline />
    ) : (
      <Preview as="img" src={dataUrl} />
    );
  }, [sharedFile, isVideo]);

  const showWebShareButton = canWebShare;
  // Hide the download button if Web Share API is supported but only on mobile.
  // When the Web Share API is not supported, we always show the download button for videos,
  // but for images we only show it if the browser is not a single tab browser
  // since we expect to have social media sharing buttons for pictures.
  const showDownloadButton = canWebShare
    ? !isMobile
    : isVideo || !isSingleTabBrowser;
  const showFacebookButton =
    !isVideo &&
    !canWebShare &&
    !isSingleTabBrowser &&
    canShare &&
    environment?.allowFacebookPhotoShare;
  const showTwitterButton =
    !isVideo &&
    !canWebShare &&
    !isSingleTabBrowser &&
    canShare &&
    environment?.allowTwitterPhotoShare;
  const showYammerButton =
    !isVideo &&
    !canWebShare &&
    !isSingleTabBrowser &&
    canShare &&
    environment?.allowYammerPhotoShare;
  const showlinkedinButton =
    !isVideo &&
    ((!isSingleTabBrowser &&
      canShare &&
      environment?.allowLinkedinPhotoShare) ||
      (isSingleTabBrowser && isSocialMediaBrowser));

  const shareButtons: ShareButtonData[] = [];

  if (showFacebookButton)
    shareButtons.push({
      name: "Facebook",
      iconName: "Facebook",
      disabled: isSharingUrl,
      onClick: () => handleShareUrl("facebook"),
    });

  if (showlinkedinButton)
    shareButtons.push({
      name: "Linkedin",
      iconName: "Linkedin",
      disabled: isSharingUrl,
      onClick: () => handleShareUrl("linkedin"),
    });

  if (showTwitterButton)
    shareButtons.push({
      name: "Twitter",
      iconName: "TwitterX",
      disabled: isSharingUrl,
      onClick: () => handleShareUrl("twitter"),
    });

  if (showYammerButton)
    shareButtons.push({
      name: "Yammer",
      iconName: "Yammer",
      disabled: isSharingUrl,
      onClick: () => handleShareUrl("yammer"),
    });

  if (showWebShareButton)
    shareButtons.push({
      name: "Web Share",
      iconName: "Share",
      disabled: isSharedFileLoading || errorSharedFile,
      onClick: () => handleWebShareFile(),
    });

  if (showDownloadButton)
    shareButtons.push({
      name: "Download",
      iconName: "Download",
      onClick: () => handleSaveFile(),
    });
  return (
    <MediaShareUi
      isLoading={isSharingUrl}
      preview={preview}
      shareButtons={shareButtons}
      onClose={handleClose}
      onBack={previousPanel ? handleBack : undefined}
      previousPanel={previousPanel}
      infoMessage={
        !errorSharedUrl && hasCopied ? t("photo_link_copied") : undefined
      }
      errorMessage={errorSharedUrl}
      warningMessage={
        showDownloadButton && isSingleTabBrowser && !noClose
          ? t("photo_image_download_warning")
          : undefined
      }
      hideTabs={!environment.allowVideoCapture}
    />
  );
};

export default MediaShareLogic;
