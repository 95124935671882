/* eslint-disable @typescript-eslint/no-explicit-any */
import { Director, Publish, PublishConnectOptions, View } from "@millicast/sdk";
import { logError } from "../../../lib/logger";
import { isScreenShareBusy, resetEnvironmentState } from "../util.core";
import {
  EventCallbackMap,
  ScreenShareProviderInterface,
} from "./ScreenShareProviderInterface";

async function fetchMilicastSubscribeToken(
  environmentId: string,
  visitorToken: string
): Promise<any> {
  const response = await fetch(
    `${import.meta.env.VITE_GYARALESS_URL}/conferences/${environmentId}/subscribe-token`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${visitorToken}`,
      },
    }
  );
  const data = await response.json();
  return data;
}

async function fetchMilicastPublishToken(
  environmentId: string,
  visitorToken: string
): Promise<any> {
  const response = await fetch(
    `${import.meta.env.VITE_GYARALESS_URL}/conferences/${environmentId}/publish-token`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${visitorToken}`,
      },
    }
  );
  const data = await response.json();
  return data;
}

type EVENT_TYPES =
  | "active"
  | "inactive"
  | "stopped"
  | "vad"
  | "layers"
  | "track"
  | "migrate"
  | "viewercount";

type BroadcastEvent = {
  name: EVENT_TYPES;
  data: any;
  type: "event";
};

export class MilicastScreenShareProvider
  implements ScreenShareProviderInterface
{
  public initStatus: "idle" | "loading" | "success" = "idle";
  public localStream: MediaStream | null = null;
  public publishInstance: Publish | null = null;
  public listeningStatus: "idle" | "loading" | "listening" = "idle";
  public onEndedCallback: ((shouldUpdatePresenter?: boolean) => void) | null =
    null;
  public viewInstance: View | null = null;
  public environmentId: string | null = null;
  public visitorToken: string | null = null;
  public publisherTokenGenerator:
    | (() => ReturnType<typeof Director.getPublisher>)
    | null = null;
  public subscriberTokenGenerator:
    | (() => ReturnType<typeof Director.getSubscriber>)
    | null = null;

  async init(envId: string, visitorToken: string) {
    if (this.initStatus === "loading") {
      return;
    }
    this.initStatus = "loading";
    this.environmentId = envId;
    this.visitorToken = visitorToken;
    this.publisherTokenGenerator = async () => {
      const publishToken = await fetchMilicastPublishToken(envId, visitorToken);
      return Director.getPublisher({
        token: publishToken.token,
        streamName: `${envId}:screen-share`,
      });
    };
    this.subscriberTokenGenerator = async () => {
      const subscribeToken = await fetchMilicastSubscribeToken(
        envId,
        visitorToken
      );
      return Director.getSubscriber({
        streamAccountId: import.meta.env.VITE_DOLBY_MILICAST_ACCOUNT_ID,
        subscriberToken: subscribeToken.token,
        streamName: `${envId}:screen-share`,
      });
    };
    await this.cleanUpScreensharing();
    this.initStatus = "success";
  }

  async stopListeningForScreenShare(): Promise<void> {
    if (this.viewInstance) {
      this.viewInstance.stop();
      this.viewInstance = null;
    }
  }

  async isStreamAvailable(): Promise<boolean> {
    if (!this.subscriberTokenGenerator) {
      logError("VOICE/VIDEO", "Subscriber token generator is not set");
      return false;
    }
    if (!this.environmentId) {
      logError("VOICE/VIDEO", "Environment slug is not set");
      return false;
    }
    const viewInstance = new View(
      this.environmentId,
      this.subscriberTokenGenerator,
      undefined,
      false
    );
    try {
      await viewInstance.connect({
        events: ["active", "inactive"],
      });
      viewInstance.stop();
      return true;
    } catch (e: unknown) {
      logError("VOICE/VIDEO", e);
      return false;
    }
  }

  async listenForScreenShare(
    eventCallbackMap: EventCallbackMap
  ): Promise<void> {
    if (this.listeningStatus === "loading") {
      return;
    }
    this.listeningStatus = "loading";
    if (!this.subscriberTokenGenerator) {
      this.listeningStatus = "idle";
      logError("VOICE/VIDEO", "Subscriber token generator is not set");
      return;
    }
    if (!this.environmentId) {
      this.listeningStatus = "idle";
      logError("VOICE/VIDEO", "Environment slug is not set");
      return;
    }

    if (this.viewInstance) {
      this.viewInstance.stop();
      this.viewInstance = null;
    }

    this.viewInstance = new View(
      this.environmentId,
      this.subscriberTokenGenerator,
      undefined,
      false
    );

    //Set track event handler to receive streams from Publisher.
    this.viewInstance.on("track", (event) => {
      const mediaStream = event.streams[0] as MediaStream;
      eventCallbackMap["started"]?.(mediaStream);
    });
    this.viewInstance.on("broadcastEvent", (event: BroadcastEvent) => {
      if (event.name === "inactive") {
        eventCallbackMap["stopped"]?.();
      }
    });
    try {
      await this.viewInstance.connect({
        events: ["active", "inactive"],
      });
    } catch (e: unknown) {
      logError("VOICE/VIDEO", e);
      this.listeningStatus = "idle";
      return;
    }
    this.listeningStatus = "listening";
  }

  public async stopScreenShare(shouldUpdatePresenter?: boolean): Promise<void> {
    this.localStream?.getTracks().forEach((track) => track.stop());
    if (this.publishInstance) {
      this.publishInstance.stop();
    }
    this.onEndedCallback?.(shouldUpdatePresenter);
  }

  /** This function is used to clean up the screen sharing state in the environment
   * if the user has not stopped the screen sharing properly.
   */
  public async cleanUpScreensharing() {
    if (!this.environmentId || !this.visitorToken) {
      logError("VOICE/VIDEO", "Environment slug or visitor token is not set");
      return;
    }
    const screenSharingBusy = await isScreenShareBusy(
      this.environmentId,
      this.visitorToken
    );
    if (!screenSharingBusy) return;
    const streamAvailable = await this.isStreamAvailable();

    if (!streamAvailable) {
      await resetEnvironmentState(this.environmentId, this.visitorToken);
    }
  }

  async startScreenShare(params: { onEnded?: () => void }) {
    this.onEndedCallback = params.onEnded || null;
    if (!this.environmentId || !this.visitorToken) {
      logError("VOICE/VIDEO", "Environment slug or visitor token is not set");
      return null;
    }
    let screenSharingBusy = await isScreenShareBusy(
      this.environmentId,
      this.visitorToken
    );
    if (screenSharingBusy) {
      logError("VOICE/VIDEO", "Screen sharing is not available");
      return null;
    }
    this.publishInstance = new Publish(
      this.environmentId,
      this.publisherTokenGenerator
    );
    const displayMediaOptions: DisplayMediaStreamOptions = {
      video: {
        displaySurface: "window",
        width: { max: 1920 },
        height: { max: 1080 },
        frameRate: { max: 30 },
      },
      audio: {
        echoCancellation: false,
        noiseSuppression: false,
        sampleRate: 44100,
      },
    };
    try {
      this.localStream =
        await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
    } catch (e) {
      logError("VOICE/VIDEO", e);
      return null;
    }
    screenSharingBusy = await isScreenShareBusy(
      this.environmentId,
      this.visitorToken
    );
    if (screenSharingBusy) {
      logError("VOICE/VIDEO", "Screen sharing is not available");
      this.stopScreenShare.bind(this);
      return null;
    }
    const broadcastOptions: PublishConnectOptions = {
      mediaStream: this.localStream,
      events: ["active", "inactive"],
      codec: "vp8",
    };
    this.localStream.getVideoTracks()[0].onended = this.stopScreenShare.bind(
      this,
      true
    );
    try {
      await this.stopListeningForScreenShare();
      await this.publishInstance.connect(broadcastOptions);
      return this.localStream;
    } catch (e) {
      logError("VOICE/VIDEO", e);
    }
    return null;
  }
}

export default MilicastScreenShareProvider;
