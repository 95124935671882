import { ReactNode, useMemo } from "react";
import Favicon from "react-favicon";
import { Helmet } from "react-helmet-async";
import { getImageUrl } from "../api/gyaradosUtils";
import { useMintEvent } from "../features/minting/mintingHooks";
import { useEnvironmentContext } from "./EnvironmentFetcher.core";

type Props = {
  children?: ReactNode;
};
const EnvironmentMetadata: React.FC<Props> = ({ children }) => {
  // TODO: implement helmet properly.
  const { environment } = useEnvironmentContext();

  // Add Favicon depending on the environment.
  const FaviconElement = useMemo(() => {
    if (!environment?.favicon?.s3Path) return undefined;
    const iconUrl = getImageUrl(environment?.favicon, 200, 200);
    return <Favicon url={iconUrl} keepIconLink={() => false} />;
  }, [environment?.favicon]);

  // Listen for mint events
  useMintEvent();

  return (
    <>
      <Helmet>
        <title>
          {environment?.tabTitle ||
            environment?.name?.toUpperCase() ||
            "Journee"}
        </title>
        <link rel="manifest" href="/manifest.json" />
        {/* Manrope and Unbounded are our fallback fonts. */}
        {/* The browser will import each of them individually only if we actually need to fallback to them. */}
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&family=Unbounded:wght@300&display=swap"
          rel="stylesheet"
        />
        {/* Chrome iOS does not go under the notch in landscape as of 2022-12. 
          so having a black background is nicer here. In general however, we 
          still want a default (generally white) background to avoid flashing 
          white->black->backgroundImage as white is the default color of most 
          browsers. */}
        {/* {isChromeIos && <style>{`body { background-color: black; }`}</style>} */}
        {/* {!isFaviconUrlLoading && !faviconUrl && (
          <>
            <link rel="icon" href="favicon.ico" />
            <link rel="apple-touch-icon" href="logo192.png" />
          </>
        )} */}
      </Helmet>
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {/* {faviconUrl && <Favicon url={faviconUrl} keepIconLink={() => false} />} */}
      {FaviconElement}
      {children}
    </>
  );
};

export default EnvironmentMetadata;
