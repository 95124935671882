import Overlay from "../../../components-ui/molecules/Overlay";
import RestrictionPanelUi from "./Restriction.ui";

const RestrictionPanel: React.FC = () => {
  return (
    <Overlay id="layout-restriction" open>
      <RestrictionPanelUi />
    </Overlay>
  );
};

export default RestrictionPanel;
