import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

/**
 *  To be able to play sounds in videos on iOS, we need a user interaction.
 *  Thus the video player is created even before the panels are mounted.
 *  Then the video player is mounted in the video FullscreenVideoPanelUI.
 *  This allow us to catch the click on the login button at the beginning.
 */

export type VideoPlayerContextType = {
  VideoPlayer: HTMLVideoElement;
};

const VideoPlayerContext = createContext<VideoPlayerContextType>(
  {} as VideoPlayerContextType
);

export const VideoPlayerProvider = VideoPlayerContext.Provider;
export const useVideoPlayerContext = () => {
  const context = useContext(VideoPlayerContext);
  if (context === undefined) {
    throw new Error("VideoPlayerProvider must be within the Experience Route");
  }
  return context;
};

type Props = {
  children?: ReactNode;
};

const VideoPlayerContextProvider: React.FC<Props> = ({ children }) => {
  const VideoPlayer = useMemo(() => document.createElement("video"), []);
  VideoPlayer.autoplay = true;
  // IMPORTANT: Muted true and PlaysInline are a must for iOS for autoplay to work.
  VideoPlayer.playsInline = true;
  VideoPlayer.muted = true;
  VideoPlayer.loop = true;
  VideoPlayer.draggable = false;
  VideoPlayer.disablePictureInPicture = true;
  VideoPlayer.ondragstart = (e) => e.preventDefault();
  useEffect(() => {
    // A user interaction will trigger this event starting the audio
    const playFullscreenVideoAudioHandler = () => {
      if (VideoPlayer.muted) VideoPlayer.muted = false;
    };
    window.addEventListener(
      "play-experience-audio",
      playFullscreenVideoAudioHandler
    );
    return () => {
      window.removeEventListener(
        "play-experience-audio",
        playFullscreenVideoAudioHandler
      );
    };
  }, [VideoPlayer]);
  return (
    <VideoPlayerProvider value={{ VideoPlayer }}>
      {children}
    </VideoPlayerProvider>
  );
};

export default VideoPlayerContextProvider;
