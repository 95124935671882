import { ReactNode, useEffect, useState } from "react";
import { styled } from "styled-components";

const Variables = styled.div<{
  $top: string | null;
  $left: string | null;
  $right: string | null;
  $bottom: string | null;
}>`
  display: contents;
  --safe-margin-top: ${(props) => props.$top};
  --safe-margin-left: ${(props) => props.$left};
  --safe-margin-right: ${(props) => props.$right};
  --safe-margin-bottom: ${(props) => props.$bottom};
`;

type Props = {
  children: ReactNode;
};

/** Add safe margin variables to the children CSS.
 *  We consume safe margins this way (instead of "env(safe-area-inset-...)" directly)
 *  so that they are optinally overridable by a parent iframe.
 */
const SafeMarginVariables: React.FC<Props> = ({ children }) => {
  const [margin, setMargin] = useState({
    top: null,
    left: null,
    right: null,
    bottom: null,
  });

  useEffect(() => {
    // TODO: here we should listen to the parent iframe for safe margin in some postmessage initialisation.
    setMargin({
      top: null,
      left: null,
      right: null,
      bottom: null,
    });
  }, []);
  return (
    <Variables
      $top={margin.top ?? "env(safe-area-inset-top, 0px)"}
      $left={margin.left ?? "env(safe-area-inset-left, 0px)"}
      $right={margin.right ?? "env(safe-area-inset-right, 0px)"}
      $bottom={margin.bottom ?? "env(safe-area-inset-bottom, 0px)"}
    >
      {children}
    </Variables>
  );
};

export default SafeMarginVariables;
