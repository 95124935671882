import { Draft } from "immer";
import { groupArrayBy } from "../../lib/array";
import { logError, logInfo, logWarn } from "../../lib/logger";
import { CombinedState, SliceCreator } from "../../store/store";
import { FromGameMessage } from "../gameConnection/messages/fromGameMessages";
import { sendGameMessage } from "../gameConnection/webrtc/webRtcMessageHandlers";
import {
  AllPanelSubPagesNames,
  PanelContentOptions,
  PanelExtraOptions,
  PanelLayoutOptions,
  PanelName,
  PanelState,
  PanelSubElementName,
  PanelSubPages,
  hasPanelRule,
  isPanelName,
  isPanelSubElementName,
  isPanelSubPageName,
  isPanelWithSubPages,
} from "./panelsTypes";

const sliceName = "panels";
let counter = 0;

/**
 * Panels are the main UI element on top of the streamed experience.
 * Panels can be opened (activated) by the user or the by game (from game messages).
 * If all active panels would be visible, they would collide with each other and
 * the screen would be cluttered.
 *
 * This logic divides panels into zones and ensures that only the most
 * recently activated panel in each zone is visible.
 *
 * Some properties can enable special behaviour:
 * - solo: Modal-like behaviour. When visible it makes all other panels
 * in any other zone invisible.
 * - stubborn: For important information. The panel will not be hidden by
 * the opening of a new panel in the same zone.
 * - ghost: The panels will be ignored by the layout logic and always been shown.
 */

type GetPanelData<T> = T extends PanelName ? T : never;
export type LayoutState = {
  panels: {
    [key in PanelName]: PanelState<key>;
  };
  openPanel: <
    TPanelName extends PanelName | AllPanelSubPagesNames | PanelSubElementName,
  >(
    name: TPanelName,
    data?: PanelContentOptions<GetPanelData<TPanelName>>
  ) => void;
  // Passing content onClose is uncommon but could be useful for reset purposes.
  closePanel: <
    TPanelName extends PanelName | AllPanelSubPagesNames | PanelSubElementName,
  >(
    name: PanelName | AllPanelSubPagesNames | PanelSubElementName,
    data?: PanelContentOptions<GetPanelData<TPanelName>>
  ) => void;
  closeAllPanels: () => void;
  setPanelExtraOptions: <TPanelName extends PanelName>(
    name: TPanelName,
    options: PanelExtraOptions[TPanelName]
  ) => void;
  clearPanelExtraOptions: <TPanelName extends PanelName>(
    name: TPanelName
  ) => void;

  /** Update the panels layout options and recompute potential change states.
   *  This is important when some panels have different behaviour and zones on
   *  small screens compared to default. */
  updateLayout: <TPanelName extends PanelName>(
    layoutOptions: {
      name: TPanelName;
      options: PanelLayoutOptions<TPanelName>;
    }[]
  ) => void;

  /** The entry point for the game's imperative game messages which toggle the panels. */
  dispatchUiMessage: (message: FromGameMessage) => void;
};

type State = {
  layout: LayoutState;
};

export const createLayoutSlice: SliceCreator<State> = (set) => ({
  layout: {
    // Initial visible/active panels.
    panels: {
      report: {
        name: "report",
        rules: [],
        ghost: true,
      },
      presentationBar: {
        name: "presentationBar",
        rules: [],
      },
      videoAvatars: {
        name: "videoAvatars",
        rules: [],
      },
      screenSharing: {
        name: "screenSharing",
        rules: [],
        options: {
          minimized: false,
        },
      },
      devOptions: {
        name: "devOptions",
        rules: [],
      },
      logo: {
        name: "logo",
        rules: [],
        visible: true,
        active: true,
      },
      forceLandscape: {
        name: "forceLandscape",
        rules: [],
      },
      actionBar: {
        name: "actionBar",
        rules: [],
        visible: true,
        active: true,
        subElements: [
          "actionBar/emojis",
          "actionBar/map",
          "actionBar/movements",
          "actionBar/photo",
          "actionBar/settings",
          "actionBar/social",
        ],
      },
      profile: {
        name: "profile",
        rules: [],
        visible: true,
        active: true,
      },
      social: {
        name: "social",
        rules: [],
      },
      infocard: {
        name: "infocard",
        rules: [],
      },
      language: {
        name: "language",
        rules: [],
      },
      settings: {
        name: "settings",
        rules: [],
      },
      map: {
        name: "map",
        rules: [],
      },
      popup: {
        name: "popup",
        rules: [],
      },
      cinematicView: {
        name: "cinematicView",
        rules: [],
      },
      photo: {
        name: "photo",
        rules: [],
        options: {
          switchTab: false,
        },
      },
      videoCapture: {
        name: "videoCapture",
        rules: [],
        options: {
          switchTab: false,
        },
      },
      mediaShare: {
        name: "mediaShare",
        rules: [],
      },
      ending: {
        name: "ending",
        rules: [],
      },
      hint: {
        name: "hint",
        rules: [],
      },
      questHint: {
        name: "questHint",
        options: {
          slug: "",
        },
        rules: [],
      },
      stats: {
        name: "stats",
        rules: [],
      },
      fullscreenVideo: {
        name: "fullscreenVideo",
        rules: [],
      },
      poll: {
        name: "poll",
        rules: [],
      },
      textChatPreview: {
        name: "textChatPreview",
        rules: [],
      },
      // This is a special case and it's used before the ExperiencePage is mounted.
      startButton: {
        name: "startButton",
        rules: ["ghost"],
        visible: true,
        active: true,
        zone: "extra",
        ghost: true,
      },
      walletConnect: {
        name: "walletConnect",
        rules: [],
      },
      quest: {
        name: "quest",
        rules: [],
      },
    },
    openPanel: (name, data) => {
      set(
        (state) => {
          change(state, name, true, data);
        },
        false,
        sliceName + "/openPanel"
      );
    },
    closePanel: (name, data) =>
      set(
        (state) => {
          change(state, name, false, data);
        },
        false,
        sliceName + "/closePanel"
      ),
    closeAllPanels: () =>
      set(
        (state) => {
          for (const it in state.layout.panels) {
            const name = it as keyof typeof state.layout.panels;
            state.layout.panels[name].active = false;
            state.layout.panels[name].visible = false;
          }
        },
        false,
        sliceName + "/closeAllPanels"
      ),
    setPanelExtraOptions: (name, options) =>
      set(
        (state) => {
          state.layout.panels[name] = {
            ...state.layout.panels[name],
            options: { ...state.layout.panels[name].options, ...options },
          };
          computeVisibility(state);
        },
        false,
        sliceName + "/setPanelExtraOptions"
      ),
    clearPanelExtraOptions: (name) =>
      set(
        (state) => {
          state.layout.panels[name] = {
            ...state.layout.panels[name],
            options: undefined,
          };
          computeVisibility(state);
        },
        false,
        sliceName + "/clearPanelExtraOptions"
      ),
    updateLayout: (layoutOptions) =>
      set(
        (state) => {
          layoutOptions.forEach(({ name, options }) => {
            const panel = state.layout.panels[name];
            state.layout.panels[name] = {
              ...panel,
              ...options,
            };
          });
          computeVisibility(state);
        },
        false,
        sliceName + "/updateLayout"
      ),

    dispatchUiMessage: (message) =>
      set(
        (state) => {
          switch (message.type) {
            case "UiAction":
              change(
                state,
                message.uiElement,
                message.uiActionType === "open",
                message.options
              );
              break;
            /** LEGACY MESSAGES SUPPORT *********/
            case "ActionPanel":
              change(state, "popup", message.display, {
                slug: message.id,
              });
              break;
            case "InfoCard":
              change(state, "infocard", message.display, {
                slug: message.id,
              });
              break;
            case "Poll":
              change(state, "poll", message.display, {
                slug: message.id,
              });
              break;
            default:
              logWarn("GENERIC", "Unknown UiMessage", message);
          }
        },
        false,
        sliceName + "/dispatchUiMessage"
      ),
  },
});

/** Accept a change with a generic target (panel, subpage, subelement) or
 *  even a faulty payload.
 *  It routes to the correct target and apply the correct changes.
 */
const change = <
  TPanelName extends PanelName | AllPanelSubPagesNames | PanelSubElementName,
>(
  state: Draft<CombinedState>,
  target: TPanelName,
  activate: boolean,
  data?: PanelContentOptions<GetPanelData<TPanelName>>
) => {
  if (isPanelName(target)) changePanel(state, target, activate, data);
  else if (isPanelSubPageName(target))
    changeSubPage(state, target, activate, data);
  else if (isPanelSubElementName(target))
    changeSubElement(state, target, activate);
  else
    logError(
      "GENERIC",
      `Cannot ${
        activate ? "open" : "close"
      } unknown panel/subpage/subelement: "${target}"`
    );
};

/** Apply a change targeting the root panel directly.
 *  If we are closing/opening the panel itself it mean that we are
 *  resetting to the default subpage if any.
 */
const changePanel = <TPanelName extends PanelName>(
  state: Draft<CombinedState>,
  panel: TPanelName,
  activate: boolean,
  data?: PanelContentOptions<TPanelName>
) => {
  /** Resets the current subpage as we targeted the root panel. */
  state.layout.panels[panel].subpage = undefined;
  changeState(state, panel, activate, data);
};

/** Apply a change targeting a subpage.
 *  Activate/deactivate the panel associated to the given subpage, saving the
 *  subpage value in the panel state.
 */
const changeSubPage = (
  state: Draft<CombinedState>,
  subpage: AllPanelSubPagesNames,
  activate: boolean,
  data?: PanelContentOptions<PanelName>
) => {
  const panel = subpage.split("/")[0];
  if (isPanelName(panel)) {
    state.layout.panels[panel].subpage = subpage;
    changeState(state, panel, activate, data);
  } else logError("GENERIC", `Subpage not found "${subpage}"`);
};

/** Apply a change targeting a subElement.
 *  Add/remove the subelement from the associated the panel.
 *  This doesn't change the panel activation/visibility.
 */
const changeSubElement = (
  state: Draft<CombinedState>,
  subElement: PanelSubElementName,
  activate: boolean
) => {
  const panel = subElement.split("/")[0];
  if (isPanelName(panel)) {
    const panelRef = state.layout.panels[panel];
    // Make sure that the subelement was not already present.
    panelRef.subElements = panelRef.subElements?.filter(
      (it) => it !== subElement
    );
    if (activate)
      panelRef.subElements = [...(panelRef.subElements || []), subElement];
  } else logError("GENERIC", `Sub element not found "${subElement}"`);
};

/** Update the state of the panel and content and then recompute the new
 *  visibilities of each panel in the layout.
 */
const changeState = <TPanelName extends PanelName>(
  state: Draft<CombinedState>,
  panel: TPanelName,
  activate: boolean,
  data?: PanelContentOptions<TPanelName>
) => {
  const layoutState = state.layout;
  const initialPanelActivatedState = layoutState.panels[panel].visible;
  if (data)
    layoutState.panels[panel] = { ...layoutState.panels[panel], ...data };
  layoutState.panels[panel].active = activate;
  layoutState.panels[panel].priority = counter++;
  computeVisibility(state);
  logState(layoutState);
  // Shouldn't we track visible panels, not activated ones??
  if (initialPanelActivatedState !== activate) {
    sendGameUiEvent(panel, activate, data);
    trackPanelChange(layoutState.panels[panel], panel, activate);
  }
};

/** Set each panel visibility based on all panels
 *  options, priority and activation. */
const computeVisibility = (state: Draft<CombinedState>) => {
  const layoutState = state.layout;
  const isExperiencePhase = state.userFlow.isExperiencePhase();
  // Make groups.
  const groups: Record<string, PanelState<PanelName>[]> = {
    all: [],
    ghost: [],
    solo: [],
    normal: [],
  };
  // Populate groups.
  for (const it in layoutState.panels) {
    const name = it as PanelName;
    const panel = layoutState.panels[name];
    groups.all.push(panel);
    if (hasPanelRule(panel, "ghost")) groups.ghost.push(panel);
    else if (hasPanelRule(panel, "solo")) groups.solo.push(panel);
    else groups.normal.push(panel);
  }

  // Hide all panels if we are not in the experience phase.
  groups.all.forEach((panel) => {
    if (panel.rules.includes("global")) {
      panel.readyForMount = true;
    } else {
      const isExperienceOnly = panel.rules.includes("experienceOnly");
      panel.readyForMount = isExperienceOnly && isExperiencePhase;
    }
  });

  // Sort by priority and filter by active global panels
  const globalPanels = groups.all.filter((panel) =>
    panel.rules.includes("global")
  );
  const sortedGlobalPanels = sortAndFilterByActivePriority(globalPanels);
  sortedGlobalPanels.forEach((panel, i) => {
    panel.visible = i === 0;
  });

  // Hide any panel that is not active anymore.
  groups.all.forEach((panel) => {
    if (!panel.active) panel.visible = false;
  });

  // Ghosts bypass hiding logic. So if they are active they are visible.
  groups.ghost.forEach((panel) => {
    if (panel.active) panel.visible = true;
  });

  // A stubborn solo, until visible, would prevent any change to other panels visibility.
  const isSoloStubbornVisible = groups.solo.some(
    (panel) => panel.visible && hasPanelRule(panel, "stubborn")
  );
  if (isSoloStubbornVisible) return;

  // Only the highest priority solo is visible.
  const solos = sortAndFilterByActivePriority(groups.solo);
  solos.forEach((panel, i) => {
    panel.visible = i === 0;
  });

  // A solo, until visible, would prevent any change to other non-solo panels
  // and force them to be invisible.
  const isSoloVisible = solos.length > 0 && solos[0].visible;
  if (isSoloVisible) {
    groups.normal.forEach((panel) => {
      if (hasPanelRule(panel, "global")) return;
      panel.visible = false;
    });
    return;
  }

  const normals = sortAndFilterByActivePriority(groups.normal);
  const normalsByZone = groupArrayBy(normals, "zone");

  normalsByZone.forEach((zone) => {
    // A stubborn normal, until visible, would prevent any change to other normal visibility in the same zone.
    const isNormalStubbornVisible = zone.some(
      (panel) => hasPanelRule(panel, "stubborn") && panel.visible
    );
    if (!isNormalStubbornVisible) {
      // Only the highest priority normal is visible in its zone.
      zone.forEach((panel, i) => {
        panel.visible = i === 0;
      });
    }
  });
};

const sortAndFilterByActivePriority = (panels: PanelState<PanelName>[]) => {
  return panels
    .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
    .filter((it) => it.active);
};

/** Simple utility to have an overview on the panels state. */
const logState = (state: LayoutState) => {
  let log = "";
  Object.entries(state.panels).map(([name, panel]) => {
    log += `${panel.active ? "A" : "_"}${panel.visible ? "V" : "_"}  ${name}${
      hasPanelRule(panel, "stubborn") ? "*" : ""
    }${hasPanelRule(panel, "solo") ? " (solo)" : ""} - ${panel.priority}\n`;
  });
  logInfo("PANELS_LAYOUT", log);
};

const sendGameUiEvent = <TPanelName extends PanelName>(
  name: PanelName,
  activated: boolean,
  options?: PanelContentOptions<TPanelName>
) => {
  sendGameMessage({
    type: "UiEvent",
    uiElement: name,
    uiEventType: activated ? "onOpen" : "onClose",
    slug: options?.slug !== "" ? options?.slug : undefined,
  });
};

/**
 * Umami tracking
 *
 * The following list of non-tracked panels and the tracking function
 * are here temporarily until we have implement a new, consolidated solution for tracking.
 */
const nonTrackedPanels = new Set<PanelName>([
  "actionBar",
  "logo",
  "startButton",
]);

const trackPanelChange = <TPanelName extends PanelName>(
  panel: Draft<PanelState<TPanelName>>,
  panelName: TPanelName,
  activated: boolean
) => {
  if (nonTrackedPanels.has(panelName)) return;
  window.analytics?.track("ui", {
    type: "ui",
    name: isPanelWithSubPages(panelName)
      ? (panel.subpage as PanelSubPages[TPanelName])
      : panelName,
    state: activated ? "open" : "close",
    ...(panel.slug && { detail: panel.slug }),
  });
};
