import Column from "../../components-ui/atoms/Column";
import Card from "../../components-ui/molecules/Card";
import { DevLandingPageTestIds } from "../../constants/testIds";
import EnvironmentMetadata from "../../core/EnvironmentMetadata";
import SpacePageUi from "../utilityPages/SpacePageUi";

type Props = {
  content: React.ReactNode;
};

const DataExternalPageUi: React.FC<Props> = ({ content }) => {
  return (
    <>
      <EnvironmentMetadata />
      <SpacePageUi testId={DevLandingPageTestIds.root}>
        <Card
          height="600px"
          width="1024px"
          style={{ padding: "40px" }}
          maxHeight="520px"
        >
          <Column width="auto" height="auto">
            {content}
          </Column>
        </Card>
      </SpacePageUi>
    </>
  );
};

export default DataExternalPageUi;
