import { SvgProps } from "../../types/svg";

const CircleStarIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10ZM9.55312 5.52813L8.41875 7.825L5.8875 8.19063C5.69688 8.21875 5.54063 8.35 5.48125 8.53125C5.425 8.7125 5.47187 8.9125 5.60938 9.04375L7.44063 10.8313L7.00938 13.3531C6.97813 13.5406 7.05312 13.7313 7.20937 13.8156C7.3625 13.9563 7.56562 13.9688 7.73438 13.8813L9.97188 12.6906L12.2656 13.8813C12.4344 13.9688 12.6375 13.9563 12.7906 13.8156C12.9469 13.7313 13.0219 13.5406 12.9906 13.3531L12.5594 10.8313L14.3906 9.04375C14.5281 8.9125 14.575 8.7125 14.5188 8.53125C14.4594 8.35 14.3031 8.21875 14.1125 8.19063L11.5813 7.825L10.4469 5.52813C10.3656 5.35938 10.1906 5.25 9.97188 5.25C9.80938 5.25 9.63437 5.35938 9.55312 5.52813Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CircleStarIcon;
