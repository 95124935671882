export enum WSStatus {
  /**
   * = 3
   */
  CLOSED = WebSocket["CLOSED"],
  /**
   * = 0
   */
  CONNECTING = WebSocket["CONNECTING"],
  /**
   * = 1
   */
  OPEN = WebSocket["OPEN"],
  /**
   * = 2
   */
  CLOSING = WebSocket["CLOSING"],
}

export type WebsocketMessage = {
  type: string;
  [key: string]: unknown;
};
