import HeaderBadge from "../../../../components-ui/atoms/HeaderBadge";
import IconComponent, { IconName } from "../../../../components-ui/atoms/Icon";
import Row from "../../../../components-ui/atoms/Row";

const PollCardHeader: React.FC<{
  icon: Extract<IconName, "Stars" | "Poll" | "Warning">;
}> = ({ icon: iconType }) => {
  let icon;

  switch (iconType) {
    case "Stars":
      icon = <IconComponent.Stars inheritColor size="32px" />;
      break;
    case "Poll":
      icon = <IconComponent.Poll inheritColor size="32px" />;
      break;
    case "Warning":
      icon = <IconComponent.Warning inheritColor size="32px" />;
      break;
  }

  return (
    <Row height="100%" width="100%" align="center" justify="center">
      <HeaderBadge>{icon}</HeaderBadge>
    </Row>
  );
};

export default PollCardHeader;
