import { SvgProps } from "../../types/svg";

const SurprisedHandsFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.22992 12.3156C2.78367 11.9438 2.12274 11.8875 1.60961 12.1906C0.983987 12.5969 0.810862 13.4531 1.22117 14.0688C2.04742 15.3188 2.58399 16.4219 2.57024 17.9719L5.99867 18C5.77992 15.8625 4.91117 13.6906 3.22992 12.3156Z"
      fill="currentColor"
      className="fill-color"
    />
    <path
      d="M16.1299 11.5469C16.633 11.1281 17.2892 10.9406 17.908 11.0156C17.9767 10.6844 17.9986 10.3438 17.9986 10C17.9986 5.58125 14.4174 2 9.99862 2C5.57987 2 1.99862 5.58125 1.99862 10C1.99862 10.3438 2.02049 10.6844 2.06268 11.0188C2.70706 10.9375 3.36737 11.1281 3.87049 11.5469C5.66737 13.0469 6.61424 15.2344 6.93299 17.3906C7.87674 17.7844 8.91112 18 9.99862 18C11.0861 18 12.0955 17.7844 13.0674 17.3906C13.3861 15.2344 14.3299 13.0469 16.1299 11.5469ZM5.13299 7.84062C4.94487 7.64062 4.95549 7.32188 5.15799 7.13438C5.78299 6.55 6.57987 6.15313 7.46112 6.03438C7.63612 6.0125 7.81737 6 7.99862 6C8.27362 6 8.49862 6.225 8.49862 6.5C8.49862 6.775 8.27362 7 7.99862 7C7.86112 7 7.72362 7.00937 7.56424 7.02812C6.92987 7.11562 6.32362 7.4125 5.83924 7.86562C5.63924 8.05312 5.32049 8.04375 5.13299 7.84062ZM7.51112 11C6.95799 11 6.51112 10.5531 6.51112 10C6.51112 9.44687 6.95799 9 7.51112 9C8.06424 9 8.51112 9.44687 8.51112 10C8.51112 10.5531 8.06112 11 7.51112 11ZM11.4986 14.5C11.4986 15.3281 10.8267 16 9.99862 16C9.17049 16 8.49862 15.3281 8.49862 14.5V13.5C8.49862 12.6719 9.17049 12 9.99862 12C10.8267 12 11.4986 12.6719 11.4986 13.5V14.5ZM12.5111 11C11.958 11 11.5111 10.5531 11.5111 10C11.5111 9.44687 11.958 9 12.5111 9C13.0642 9 13.5111 9.44687 13.5111 10C13.5111 10.5531 13.0611 11 12.5111 11ZM14.8642 7.84062C14.6767 8.04375 14.358 8.05312 14.158 7.86562C13.6736 7.4125 13.0674 7.11562 12.4049 7.02812C12.2736 7.00937 12.1361 7 11.9986 7C11.7236 7 11.4986 6.775 11.4986 6.5C11.4986 6.225 11.7236 6 11.9986 6C12.1799 6 12.3611 6.0125 12.5361 6.03438C13.4174 6.15313 14.2142 6.55 14.8392 7.13438C15.0424 7.32188 15.0517 7.64062 14.8642 7.84062Z"
      fill="currentColor"
      className="fill-color"
    />
    <path
      d="M18.3893 12.1906C17.8768 11.8875 17.2143 11.9438 16.7706 12.3156C15.0862 13.6906 14.2174 15.8625 13.9987 18L17.4299 17.9719C17.4143 16.4219 17.9518 15.3188 18.7768 14.0688V14.0719C19.1893 13.4531 19.0143 12.5969 18.3893 12.1906Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default SurprisedHandsFilledIcon;
