import Markdown from "../../../../components-ui/atoms/Markdown";
import Space from "../../../../components-ui/atoms/Space";
import Typo from "../../../../components-ui/atoms/Typo";
import { Props } from "../Popup.ui";
import { getButtons } from "../lib/popupUtils";
import ButtonWrapper from "./ButtonWrapper";

const PopupContent: React.FC<Props> = ({
  onClose,
  popup,
  handleExternalLink,
  handleTeleport,
  extraText,
}) => {
  const buttons = getButtons({
    popup,
    handleExternalLink,
    handleTeleport,
    onClose,
  });

  return (
    <>
      {popup?.title && <Typo.Title>{popup.title}</Typo.Title>}
      <Space h={2} />
      {popup?.text && <Markdown content={popup.text} />}
      {Boolean(extraText) && (
        <>
          <Space h={2} />
          <Typo.Body>{extraText}</Typo.Body>
        </>
      )}
      <Space h={5} />
      <ButtonWrapper>{...buttons}</ButtonWrapper>
    </>
  );
};

export default PopupContent;
