import { SvgProps } from "../../types/svg";

const LaughingFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10ZM10 15.5C12.3781 15.5 14.3813 13.9375 14.975 11.8156C15.0969 11.3875 14.7438 11 14.3 11H5.7C5.25625 11 4.90375 11.3875 5.02375 11.8156C5.61875 13.9375 7.62188 15.5 10 15.5ZM9.07812 8.7375C9.18125 8.70313 9.25 8.60625 9.25 8.5C9.25 7.94063 9.04062 7.3875 8.73125 6.975C8.425 6.56875 7.98438 6.25 7.5 6.25C7.01562 6.25 6.575 6.56875 6.26875 6.975C5.95938 7.3875 5.75 7.94063 5.75 8.5C5.75 8.60625 5.81875 8.70313 5.92188 8.7375C6.02188 8.77188 6.13437 8.7375 6.2 8.65L6.20625 8.64062C6.2125 8.63437 6.19063 8.625 6.22813 8.6125C6.22188 8.5875 6.27813 8.55313 6.31563 8.50938C6.39375 8.41875 6.50313 8.30313 6.63438 8.15938C6.90938 7.94375 7.225 7.75 7.5 7.75C7.775 7.75 8.09062 7.94375 8.36562 8.15938C8.49687 8.30313 8.60625 8.41875 8.68437 8.50938C8.72187 8.55313 8.75 8.5875 8.77187 8.6125C8.78125 8.625 8.7875 8.63437 8.79375 8.64062L8.8 8.65C8.86563 8.7375 8.97812 8.77188 9.07812 8.7375ZM13.8 8.65C13.8656 8.7375 13.9781 8.77188 14.0781 8.7375C14.1812 8.70313 14.25 8.60625 14.25 8.5C14.25 7.94063 14.0406 7.3875 13.7312 6.975C13.425 6.56875 12.9844 6.25 12.5 6.25C12.0156 6.25 11.575 6.56875 11.2688 6.975C10.9594 7.3875 10.75 7.94063 10.75 8.5C10.75 8.60625 10.8188 8.70313 10.9219 8.7375C11.0219 8.77188 11.1344 8.7375 11.2 8.65L11.2063 8.64062C11.2125 8.63437 11.1906 8.625 11.2281 8.6125C11.2219 8.5875 11.2781 8.55313 11.3156 8.50938C11.3938 8.41875 11.5031 8.30313 11.6344 8.15938C11.9094 7.94375 12.225 7.75 12.5 7.75C12.775 7.75 13.0906 7.94375 13.3656 8.15938C13.4969 8.30313 13.6062 8.41875 13.6844 8.50938C13.7219 8.55313 13.75 8.5875 13.7719 8.6125C13.7813 8.625 13.7875 8.63437 13.7937 8.64062L13.8 8.65Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default LaughingFilledIcon;
