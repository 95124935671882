import { FromGameMessage } from "../../../../../core/gameConnection/messages/fromGameMessages";
import { isANaughtyString } from "../../../../../lib/naughty-words";
import { SliceCreator } from "../../../../../store/store";
import {
  TextChatChannel,
  TextChatMessage,
  TextChatMessageGroup,
} from "./textChatTypes";

const GROUPING_THRESHOLD = 1000 * 60; // 1 minute

function appendTextChatMessage(
  thread: TextChatMessageGroup[],
  message: TextChatMessage
) {
  const lastMessage = thread[thread.length - 1];
  if (
    lastMessage &&
    lastMessage.senderId === message.senderId &&
    message.timestamp - lastMessage.timestamp < GROUPING_THRESHOLD
  ) {
    lastMessage.messages.push(message);
  } else {
    thread.push({
      messages: [message],
      sender: message.sender,
      senderId: message.senderId,
      timestamp: message.timestamp,
      ...(message.roomId && { roomId: message.roomId }),
    });
  }
}

export interface TextChatState {
  isModerating: boolean;
  wasMessageRecentlySent: boolean;
  activeChannel: string | null;
  channels: {
    [slug: string]: TextChatChannel;
  };
  setIsModerating: (value: boolean) => void;
  setWasMessageRecentlySent: (value: boolean) => void;
  setUnseenMessagesCount: (value: number) => void;
  addTextChatMessage(
    channel: string,
    sender: string,
    senderId: number,
    content: string
  ): void;
  dispatchTextChatMessage: (message: FromGameMessage) => void;
}

type State = {
  textChat: TextChatState;
};
const sliceName = "textChat";

export const createTextChatSlice: SliceCreator<State> = (set, get) => ({
  textChat: {
    isModerating: false,
    wasMessageRecentlySent: false,
    activeChannel: "public",
    channels: {
      public: {
        type: "public",
        name: "Public",
        slug: "public",
        thread: [],
        lastTyping: undefined,
        unseenMessagesCount: 0,
      },
    },
    setIsModerating: (value) =>
      set(
        (state) => {
          state.textChat.isModerating = value;
        },
        false,
        sliceName + "/setIsModerating"
      ),
    setWasMessageRecentlySent: (value) =>
      set(
        (state) => {
          state.textChat.wasMessageRecentlySent = value;
        },
        false,
        sliceName + "/setWasMessageRecentlySent"
      ),
    setUnseenMessagesCount: (value) =>
      set(
        (state) => {
          const activeChannel = state.textChat.activeChannel;
          if (activeChannel) {
            state.textChat.channels[activeChannel].unseenMessagesCount = value;
          }
        },
        false,
        sliceName + "/setUnseenMessagesCount"
      ),
    addTextChatMessage: (
      channel: string,
      sender: string,
      senderId: number,
      content: string
    ) =>
      set((state: State) => {
        appendTextChatMessage(state.textChat.channels[channel].thread, {
          content,
          sender,
          senderId,
          timestamp: Date.now(),
        });
      }),
    dispatchTextChatMessage: (message) => {
      set((state: State): void => {
        switch (message.type) {
          case "ReceivedChatMessage":
            {
              // TODO: Find a better way to filter out naughty messages.
              const isNaughty = isANaughtyString(message.content);
              const isAllowed = state.textChat.isModerating || !isNaughty;
              if (!isAllowed) break;
              appendTextChatMessage(state.textChat.channels.public.thread, {
                content: message.content,
                sender: message.sender,
                senderId: message.senderId,
                messageId: message.messageId,
                timestamp: Date.now(),
                isNaughty,
                roomId: message.roomId,
              });

              const socialPanel = get().layout.panels.social;
              const isSocialVisible =
                socialPanel.visible &&
                socialPanel.active &&
                socialPanel.subpage === "social/chat";
              if (!isSocialVisible) {
                const unseenMessagesCount =
                  state.textChat.channels.public.unseenMessagesCount ?? 0;
                state.textChat.channels.public.unseenMessagesCount =
                  unseenMessagesCount + 1;
              }
            }
            break;
          case "OnChatMessageDeleted":
            for (const channelId in state.textChat.channels) {
              const chat = state.textChat.channels[channelId].thread;
              chat.forEach((group) => {
                const index = group.messages.findIndex(
                  (m) =>
                    m.messageId === message.messageId &&
                    m.senderId === message.senderId
                );
                if (index !== -1) {
                  chat.splice(index, 1);
                }
              });
            }
            break;
          case "SmartChatAction":
            if (message.action === "openChat")
              state.textChat.activeChannel = message.smartChatSlug;
            else if (message.action === "closeChat")
              state.textChat.activeChannel = "public";
            else if (
              message.action === "npcTyping" &&
              message.smartChatSlug === state.textChat.activeChannel
            ) {
              state.textChat.channels[message.smartChatSlug].lastTyping =
                Date.now();
            }
            break;
          case "SmartChatEngineReply":
            {
              appendTextChatMessage(
                state.textChat.channels[message.smartChatSlug].thread,
                {
                  content: message.message,
                  timestamp: new Date().getTime(),
                  sender: message.smartChatSlug,
                  senderId: -1,
                }
              );
              const activeChannel = state.textChat.activeChannel;
              const isSocialVisible = get().layout.panels.social.visible;
              if (activeChannel && !isSocialVisible) {
                const unseenMessagesCount =
                  state.textChat.channels[activeChannel].unseenMessagesCount ??
                  0;
                state.textChat.channels[activeChannel].unseenMessagesCount =
                  unseenMessagesCount + 1;
              }
            }
            break;
          case "SmartChatSubscriptionUpdate":
            {
              const newChannels = message.smartChatSlugs.reduce(
                (result, slug) => {
                  const existingChannel = state.textChat.channels[slug];
                  result[slug] = existingChannel ?? {
                    type: "smartChat",
                    // TODO: Get the name from environment?
                    name: slug,
                    slug: slug,
                    thread: [],
                    lastTyping: undefined,
                    unseenMessagesCount: 0,
                  };
                  return result;
                },
                {} as Record<string, TextChatChannel>
              );
              let activeChannel;
              if (
                state.textChat.activeChannel &&
                newChannels[state.textChat.activeChannel]
              ) {
                activeChannel = state.textChat.activeChannel;
              } else {
                activeChannel = "public";
              }
              state.textChat.channels = {
                ...state.textChat.channels,
                ...newChannels,
              };
              state.textChat.activeChannel = activeChannel;
            }
            break;
          default:
            break;
        }
      });
    },
  },
});
