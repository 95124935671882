import Image from "../atoms/Image";
import Row from "../atoms/Row";

type Props = {
  logoUrl: string;
  height?: string;
};

const Logo: React.FC<Props> = ({ logoUrl, height }) => {
  return (
    logoUrl && (
      <Row justify="center" align="flex-start" height="100%">
        <Image src={logoUrl} width="100%" height={height} minimalPlaceholder />
      </Row>
    )
  );
};

export default Logo;
