import React from "react";
import { SvgProps } from "../../types/svg";

const ShareIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1111 12.6175C12.8657 12.6175 12.6667 12.8137 12.6667 13.0557V15.2223C12.6667 15.7318 12.2694 16.1235 11.7778 16.1235H3.77778C3.28694 16.1235 2.88889 15.731 2.88889 15.247V7.35849C2.88889 6.8745 3.28694 6.48199 3.77778 6.48199H6.88889C7.13333 6.48199 7.33333 6.28478 7.33333 6.04374C7.33333 5.8027 7.13333 5.60549 6.88889 5.60549H3.77778C2.79583 5.60549 2 6.3916 2 7.35849V15.2223C2 16.1903 2.79583 16.9753 3.77778 16.9753L11.7778 17C12.7597 17 13.5556 16.215 13.5556 15.247V13.0557C13.5556 12.8147 13.3556 12.6175 13.1111 12.6175ZM17.8417 6.58334L13.3972 3.10199C13.2097 2.94706 12.9294 2.97103 12.7714 3.15762C12.6143 3.34335 12.6394 3.61997 12.8278 3.77473L16.3278 6.48199H12.2222C9.50278 6.48199 7.33333 8.6212 7.33333 11.3027V12.1792C7.33333 12.4203 7.53333 12.6175 7.77778 12.6175C8.02222 12.6175 8.22222 12.4203 8.22222 12.1792V11.3027C8.22222 9.12793 10.0167 7.35849 12.2222 7.35849H16.3278L12.825 10.0899C12.6366 10.2448 12.6114 10.5213 12.7686 10.707C12.8583 10.8097 12.9833 10.8645 13.1111 10.8645C13.2118 10.8645 13.3125 10.8311 13.3958 10.7626L17.8403 7.25663C17.9417 7.17497 18 7.04898 18 6.89559C18 6.7422 17.9417 6.66825 17.8417 6.58334Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ShareIcon;
