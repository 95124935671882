import { useEffect, useState } from "react";
import TextChatMessage from "./TextChatMessage";

type Props = {
  activeChannel: string | null;
};

/** Animates a three dot typing indicator in text chat. */
const TextChatTypingIndicator: React.FC<Props> = ({ activeChannel }) => {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    let count = 0;
    const intervalId = setInterval(() => {
      count = (count + 1) % 4;
      const dotsString = count === 0 ? "&nbsp;" : ".".repeat(count);
      setDots(dotsString);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <TextChatMessage
      message={{
        content: dots,
        timestamp: 0,
        sender: activeChannel || "",
        senderId: 0,
      }}
    />
  );
};

export default TextChatTypingIndicator;
