import { styled } from "styled-components";
import Hide from "../../../components-ui/atoms/Hide";
import { BaseLayout } from "../../../core/layout/PanelsLayout";
import { useStore } from "../../../store/store";
import SocialLogic from "./Social.logic";
import { useUpdateSearchableProfile } from "./socialHooks";

const Layout = styled(BaseLayout)`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`;

const SocialPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.social.visible);
  const subpage = useStore((s) => s.layout.panels.social.subpage);
  // For environments showing player list, we update the visitor profile
  // on an interval to signal to the server that the user is still active.
  useUpdateSearchableProfile();
  return (
    <Layout id="panel-social">
      <Hide
        hide={!visible}
        height="100%"
        width="100%"
        unMount
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <SocialLogic subpage={subpage ?? "social/chat"} visible={visible} />
      </Hide>
    </Layout>
  );
};

export default SocialPanel;
