import { styled } from "styled-components";
import Row from "../../../components-ui/atoms/Row";

const Grid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.5;
`;

const Cell = styled.div`
  height: 100%;
  width: 33.33%;
  border: 1px solid ${(p) => p.theme.colorAbove3};
`;

/** A ui that render the classic reticle/grid seen in the viewfinders of most photo-cameras. */
const ViewFinderGrid: React.FC = () => {
  return (
    <Grid>
      <Row height="33.33%">
        <Cell />
        <Cell />
        <Cell />
      </Row>
      <Row height="33.33%">
        <Cell />
        <Cell />
        <Cell />
      </Row>
      <Row height="33.33%">
        <Cell />
        <Cell />
        <Cell />
      </Row>
    </Grid>
  );
};

export default ViewFinderGrid;
