import { useEffect, useState } from "react";
import throttle from "lodash/throttle";
import { useIsLandscape } from "../../../hooks/ui";
import { logInfo } from "../../../lib/logger";

// Add 1 of tolerance for the initial state that is somehow 1px off
const isScrolledToBottom = () =>
  window.scrollY >= document.body.scrollHeight - window.innerHeight - 1;

export const useDetectIsScrolledToBottom = () => {
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const isLandscape = useIsLandscape();

  useEffect(() => {
    const listener = throttle(
      () => {
        if (isLandscape && isScrolledToBottom()) {
          setAtBottom((currentValue) => {
            if (!currentValue) {
              logInfo(
                "GENERIC",
                "Scroll: The user scrolled to the end of page."
              );
            }
            return !currentValue;
          });
        } else setAtBottom(false);
      },
      16,
      {
        leading: true,
        trailing: true,
      }
    );
    window.addEventListener("scroll", listener);
    window.addEventListener("orientationchange", listener);
    // Resize is needed on Firefox Android for example, when the
    // scrollbar disappears in certain scenarios.
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("scroll", listener);
      window.removeEventListener("orientationchange", listener);
      window.removeEventListener("resize", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLandscape, window.innerHeight]);

  return atBottom;
};

export default useDetectIsScrolledToBottom;
