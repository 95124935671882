import styled from "styled-components";
import CardList from "./CardList";
import { Props } from "./SettingsHomePage.ui";

const Flex = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  padding-left: max(var(--safe-margin-left), 8px);
  padding-right: max(var(--safe-margin-right), 8px);
`;

const SettingsHomePageUiLandscape: React.FC<Props> = (props) => {
  return (
    <Flex>
      <CardList {...props} />
    </Flex>
  );
};

export default SettingsHomePageUiLandscape;
