import { memo } from "react";
import styled from "styled-components";
import JourneeLogo from "../../assets/svg/JourneeLogo";
import Column from "../../components-ui/atoms/Column";
import HeaderBadge from "../../components-ui/atoms/HeaderBadge";
import Icon from "../../components-ui/atoms/Icon";
import Typo from "../../components-ui/atoms/Typo";
import { useText } from "../../core/i18n/i18nHooks";
import SpacePageUi from "./SpacePageUi";

const JourneeLink = styled.a`
  position: absolute;
  top: 25px;
`;

const ErrorPageUi: React.FC = () => {
  const t = useText();
  return (
    <SpacePageUi>
      <JourneeLink href="https://www.journee.live">
        <JourneeLogo />
      </JourneeLink>
      <Column
        justify="center"
        align="center"
        gap={4}
        width="600px"
        textAlign="center"
      >
        <HeaderBadge rippleLineWidth={5} noBackground color="inverted">
          <Icon.Warning color="white" size="60px" inheritColor />
        </HeaderBadge>
        <Typo.Title color="white">{t("error_title")}</Typo.Title>
        <Typo.Body color="white" size="17px">
          {t("error_description")}
        </Typo.Body>
      </Column>
    </SpacePageUi>
  );
};

export default memo(ErrorPageUi);
