import { ReactNode, forwardRef } from "react";
import styled, { useTheme } from "styled-components";
import { CardTestIds } from "../../constants/testIds";
import Column from "../atoms/Column";
import Glass from "../atoms/Glass";
import CloseButton from "./CloseButton";

const CloseWrapper = styled.div`
  position: absolute;
  top: 8.5px;
  right: 8.5px;
  background: none;
  border: none;
  z-index: 1;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow-x: hidden;
`;

const TopWrapper = styled.div<{ $height?: string }>`
  height: ${(p) => p.$height ?? "auto"};
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top-left-radius: ${(p) => p.theme.radiusBig};
  border-top-right-radius: ${(p) => p.theme.radiusBig};
`;

const Padding = styled.div<{ $padding: React.CSSProperties["padding"] }>`
  padding: ${(p) => p.$padding};
  width: 100%;
  box-sizing: border-box;
`;

const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const ScrollElement = styled.div`
  position: absolute;
  bottom: 0;
  max-height: 85vh;
  width: 100%;
  box-sizing: border-box;
`;

export interface Props {
  floatingSection?: ReactNode;
  topSection?: ReactNode;
  testId?: string;
  topSectionHeight?: string;
  bottomSection?: ReactNode;
  brightCloseButton?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  padding?: React.CSSProperties["padding"];
}

/** A fullscreen wide version of the card component. */
const CardPartialPortrait = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      padding = "32px 32px 10px",
      floatingSection,
      topSection,
      topSectionHeight = "auto",
      bottomSection,
      onClose,
      brightCloseButton,
      testId,
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Wrapper ref={ref} data-testid={testId}>
        <ScrollWrapper>
          <ScrollElement>
            <Glass
              width="100%"
              height={"auto"}
              padding="0px"
              borderRadius={`${theme.radiusBig} ${theme.radiusBig} 0 0`}
              blur="strong"
            >
              <Content>
                {Boolean(floatingSection) && <div>{floatingSection}</div>}
                <Column align="center" width="100%">
                  {Boolean(topSection) && (
                    <TopWrapper $height={topSectionHeight}>
                      {topSection}
                    </TopWrapper>
                  )}
                  <Padding $padding={padding}>{children}</Padding>
                  {bottomSection}
                </Column>
              </Content>
              {Boolean(onClose) && (
                <CloseWrapper>
                  <CloseButton
                    testId={CardTestIds.closeBtn}
                    onClick={onClose}
                    isGlass={brightCloseButton}
                  />
                </CloseWrapper>
              )}
            </Glass>
          </ScrollElement>
        </ScrollWrapper>
      </Wrapper>
    );
  }
);

export default CardPartialPortrait;
