import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { sendGameMessage } from "../../../core/gameConnection/webrtc/webRtcMessageHandlers";
import { logWarn } from "../../../lib/logger";
import { useStore } from "../../../store/store";
import ProfileUi from "./Profile.ui";
import { EmptyAvatarData } from "./lib/constants";
import { ProfileData } from "./lib/profileTypes";
import {
  getProfileDataFromFormEvent,
  getProfileFieldConfigurations,
  getRpmAvatarIdFromUrl,
  initialiseProfile,
  parseProfileForGame,
  parseProfileForWeb,
} from "./lib/profileUtils";

/** The visitor profile data is used in many places:
 * - Game side (current avatars, avatar color and names hovering the head).
 * - Multiplayer system (search other players and see their visitors profiles).
 * - Persisted for faster future logins.
 */
export const ProfileLogic: React.FC<{
  onSubmit?: () => void;
}> = (props) => {
  const closePanel = useStore((s) => s.layout.closePanel);
  const profileData = useStore((s) => s.profile.profileData);
  const setProfileData = useStore((s) => s.profile.setProfileData);
  const addInactivityException = useStore(
    (s) => s.session.addInactivityException
  );
  const removeInactivityException = useStore(
    (s) => s.session.removeInactivityException
  );
  const customAvatarUrl = useStore(
    (s) => s.profile.profileData.customAvatarUrl
  );
  const setCustomAvatarUrl = useStore((s) => s.profile.setCustomAvatarUrl);

  const { environment } = useEnvironmentContext();
  const shouldGenerateRandomName = environment.businessCard.autoFillName;
  const hasReadyPlayerMe =
    environment.enableRpmAvatar ||
    environment.avatars?.some((avatar) => avatar.avatarId === "readyplayerme");
  const profileFields = useMemo(
    () => getProfileFieldConfigurations(environment),
    [environment]
  );
  const [isReadyPlayerMeEditorOpen, setIsReadyPlayerMeEditorOpen] =
    useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [isMissingAvatar, setIsMissingAvatar] = useState<boolean>(false);

  // Sends to the game the values that makes the avatar change as we fill them in.
  const updateVisitorProfileData = useCallback(
    (data: ProfileData, key: keyof ProfileData) => {
      const parsedData = parseProfileForGame(data);
      switch (key) {
        case "avatarColor":
        case "avatarId":
        case "firstName":
          sendGameMessage({
            type: "BusinessCard",
            ...parsedData,
          });
          break;
      }
    },
    []
  );

  const avatarsOptions = useMemo(() => {
    const avatars = environment.avatars?.filter((item) => !item.deactivated);

    if (environment.enableRpmAvatar) {
      avatars.push({
        ...EmptyAvatarData,
        colors: {
          backgroundColor: undefined,
        },
        avatarId: "readyplayerme",
        avatarImage: environment.customRpmAvatarImage,
        name: "Ready Player Me",
        singleColor: true,
      });
    }

    return avatars;
  }, [environment]);

  // Make sure that the data is initialized (idempotent).
  const initialProfileData = useMemo(
    () =>
      initialiseProfile(
        parseProfileForWeb(profileData),
        avatarsOptions,
        shouldGenerateRandomName
      ),
    [profileData, avatarsOptions, shouldGenerateRandomName]
  );
  const [data, setData] = useState<ProfileData>(initialProfileData);

  const handleChange = useCallback(
    (key: keyof ProfileData, value: string | boolean) => {
      const newData = { ...data, [key]: value };
      setData(newData);
      updateVisitorProfileData(newData, key);
    },
    [data, updateVisitorProfileData]
  );

  // Sends the initial data to the store and the game.
  useEffect(() => {
    setProfileData(initialProfileData);
    // This effect should happen only once on first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = getProfileDataFromFormEvent(event);
    if (data.avatarId === "readyplayerme") {
      const customAvatarUrl = data.customAvatarUrl;
      const rpmAvatarId = getRpmAvatarIdFromUrl(customAvatarUrl);
      if (rpmAvatarId) {
        data.rpmAvatarId = rpmAvatarId;
        setIsMissingAvatar(false);
      } else {
        setIsMissingAvatar(true);
        logWarn("GENERIC", "No RPM avatar ID found in the custom avatar URL.");
        return;
      }
    } else {
      data.rpmAvatarId = undefined;
    }
    Boolean(data.customAvatarUrl) && data.customAvatarUrl.split("");
    sendGameMessage({
      type: "EditingBusinessCard",
      opened: false,
    });

    // Add profile data which is not present in the form
    if (environment.enableWeb3) data.walletId = profileData.walletId;

    setProfileData(parseProfileForWeb(data));
    props.onSubmit?.();
    window.analytics?.track("avatar", {
      type: "avatar",
      name: data.avatarId,
    });
    closePanel("profile");
  };

  const avatarsAvailable = avatarsOptions && avatarsOptions.length > 0;
  if (!avatarsAvailable) {
    logWarn(
      "GENERIC",
      "No avatars have been provided to the business card. Behaviour may be broken."
    );
  }

  // Stop inactivity check if RPM editor is open
  useEffect(() => {
    if (isReadyPlayerMeEditorOpen) {
      addInactivityException("readyplayerme");
    } else {
      removeInactivityException("readyplayerme");
    }
  }, [
    isReadyPlayerMeEditorOpen,
    addInactivityException,
    removeInactivityException,
  ]);

  // Update local profile data when the custom avatar URL changes
  useEffect(() => {
    if (data.customAvatarUrl !== customAvatarUrl) {
      setData({
        ...data,
        customAvatarUrl,
        avatarId: "readyplayerme",
      });

      const rpmAvatarId = getRpmAvatarIdFromUrl(customAvatarUrl);
      if (rpmAvatarId) {
        setIsMissingAvatar(false);
      }
    }
  }, [customAvatarUrl, data]);

  return (
    <ProfileUi
      onSubmit={onSubmit}
      hasReadyPlayerMe={hasReadyPlayerMe}
      data={data}
      setCustomAvatarUrl={setCustomAvatarUrl}
      settings={profileFields}
      onChange={handleChange}
      avatarsOptions={avatarsOptions}
      isReadyPlayerMeEditorOpen={isReadyPlayerMeEditorOpen}
      setIsReadyPlayerMeEditorOpen={setIsReadyPlayerMeEditorOpen}
      hasConfirmed={hasConfirmed}
      setHasConfirmed={setHasConfirmed}
      isMissingAvatar={isMissingAvatar}
    />
  );
};

export default ProfileLogic;
