import styled, { css, useTheme } from "styled-components";
import tinycolor from "tinycolor2";
import Column from "../../components-ui/atoms/Column";
import Hide from "../../components-ui/atoms/Hide";
import Image from "../../components-ui/atoms/Image";
import Row from "../../components-ui/atoms/Row";
import Space from "../../components-ui/atoms/Space";
import Transition from "../../components-ui/atoms/Transition";
import ButtonText from "../../components-ui/molecules/ButtonText";
import LanguageSelectionButton from "../../components-ui/molecules/LanguageSelectionButton";
import StartButton from "../../components-ui/molecules/StartButton";
import { LoginPageTestIds } from "../../constants/testIds";
import { useText } from "../../core/i18n/i18nHooks";
import { PanelName } from "../../core/layout/panelsTypes";
import { useIsLandscape, useIsSmallScreen } from "../../hooks/ui";
import { grayscaleInvert } from "../../lib/color";
import { breakpoints, steps } from "../../style/theme";
import RestrictionPanel from "../panels/restriction/Restriction.panel";
import LoginPageUiLandingText from "./LoginPageUiLandingText";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100dvh;
  box-sizing: border-box;
  pointer-events: none;
  user-select: none;
`;

const Content = styled.div`
  position: absolute;
  inset: max(var(--safe-margin-top), 0px) max(var(--safe-margin-right), 0px)
    max(var(--safe-margin-bottom), 0px) max(var(--safe-margin-left), 0px);
  @media screen and (max-width: ${breakpoints.px.s}) {
    inset: max(var(--safe-margin-top), 8px) max(var(--safe-margin-right), 24px)
      max(var(--safe-margin-bottom), 8px) max(var(--safe-margin-left), 24px);
  }
`;

const BottomDescription = styled.div<{ $bottomSpacing: string }>`
  bottom: calc(${(p) => p.$bottomSpacing});
  position: absolute;
  height: 200px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const BottomBar = styled.div`
  position: absolute;
  inset-block-end: 10px;
  flex-wrap: wrap;
  left: var(--safe-margin-left);
  right: var(--safe-margin-right);
  bottom: var(--safe-margin-bottom);

  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${breakpoints.px.s}) {
    inset-block-end: 0px;
  }
`;

const Vignette = styled.div<{ $color?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;

  ${(p) => {
    const root = tinycolor(p.$color ?? "black");
    const dark000 = root.setAlpha(0).toRgbString();
    const dark010 = root.setAlpha(0.1).toRgbString();
    const dark036 = root.setAlpha(0.36).toRgbString();
    const dark080 = root.setAlpha(0.8).toRgbString();
    return css`
      background: linear-gradient(0deg, ${dark010} 0%, ${dark010} 100%),
        radial-gradient(
          77.33% 77.33% at 50% 22.09%,
          ${dark000} 0%,
          ${dark036} 66.67%,
          ${dark080} 100%
        );
    `;
  }}
`;

const Fullscreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const layoutDefault = {
  logoHeight: "70px",
  titleFont: steps.font.f100,
  bodyFont: steps.font.f40,
  titleBottomMargin: 4,
  bottomSpacing: "96px",
  cursorAreaHeight: "100%",
  cursorScale: 1,
};

export type LoginPageLayout = Omit<
  typeof layoutDefault,
  "bodyFont" | "titleFont"
> & {
  titleFont: (typeof steps.font)[keyof typeof steps.font];
  bodyFont: (typeof steps.font)[keyof typeof steps.font];
};

const layoutSmall: LoginPageLayout = {
  logoHeight: "30px",
  titleFont: steps.font.f70,
  bodyFont: steps.font.f20,
  titleBottomMargin: 4,
  bottomSpacing: "70px",
  cursorAreaHeight: "100%",
  cursorScale: 1,
} as const;
const layoutSmallLandscape: LoginPageLayout = {
  logoHeight: "23px",
  titleFont: steps.font.f70,
  bodyFont: steps.font.f20,
  titleBottomMargin: 2,
  bottomSpacing: "50px",
  cursorAreaHeight: "75%",
  cursorScale: 0.7,
} as const;

export type PropsLoginPageUi = {
  logoUrl?: string;
  title?: string;

  descriptionHeader?: "warning";
  description?: string;

  isDismissed?: boolean;

  isMainButtonVisible?: boolean;
  isMainButtonLoading?: boolean;
  isMainButtonFollowingMouse?: boolean;

  mainButtonLabel?: string;
  onMainButtonClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;

  isSecondaryButtonVisible?: boolean;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;

  showLogo?: boolean;
  selectedLocaleCode?: string;
  locales?: string[];
  links?: { text: string; url: string }[];

  openPanel?: (panel: PanelName) => void;
  isRestricted?: boolean;
};

const LoginPageUi: React.FC<PropsLoginPageUi> = ({
  logoUrl,
  title,

  descriptionHeader,
  description,

  isDismissed,

  isMainButtonVisible,
  isMainButtonLoading,
  mainButtonLabel,
  onMainButtonClick,
  isMainButtonFollowingMouse,

  isSecondaryButtonVisible,
  secondaryButtonLabel,
  onSecondaryButtonClick,

  showLogo,

  selectedLocaleCode,
  locales,

  links,

  openPanel,
  isRestricted,
}) => {
  const t = useText();
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();

  const layout = isSmallScreen
    ? isLandscape
      ? layoutSmallLandscape
      : layoutSmall
    : layoutDefault;

  const theme = useTheme();

  const inverted = theme.isLoginColorInverted;
  const isOverlayDisabled = theme.isLoginOverlayDisabled;

  const colorBelowRoot = inverted
    ? grayscaleInvert(theme.colorBelowRoot)
    : theme.colorBelowRoot;

  return (
    <Wrapper data-testid={LoginPageTestIds.root}>
      <Fullscreen>
        <Hide
          hide={isDismissed || isOverlayDisabled}
          width="100%"
          height="100%"
        >
          <Vignette
            data-testid={LoginPageTestIds.vignette}
            $color={colorBelowRoot}
          />
        </Hide>
      </Fullscreen>
      <Fullscreen>
        {isRestricted && <RestrictionPanel />}
        <Hide hide={!isMainButtonVisible} width="100%" height="100%">
          <StartButton
            label={mainButtonLabel}
            isLoading={isMainButtonLoading}
            shouldFollowMouse={isMainButtonFollowingMouse}
            hasShadow={isDismissed}
            onClick={onMainButtonClick}
            inverted={inverted}
            height={layout.cursorAreaHeight}
            cursorScale={layout.cursorScale}
          />
        </Hide>
      </Fullscreen>
      <Hide hide={isDismissed} width="100%" height="100%">
        <Content>
          <Column height="100%" align="center" justify="space-between">
            <Column align="center">
              <Space h={4} />
              <Row justify="center" align="center">
                <Hide hide={!showLogo}>
                  {logoUrl && (
                    <Image
                      testId={LoginPageTestIds.logo}
                      minimalPlaceholder
                      src={logoUrl}
                      width="200px"
                      height={layout.logoHeight}
                    />
                  )}
                </Hide>
              </Row>
            </Column>
            <BottomDescription $bottomSpacing={layout.bottomSpacing}>
              <Transition
                height="200px"
                watch={[
                  title,
                  description,
                  Boolean(descriptionHeader),
                  isSecondaryButtonVisible,
                  secondaryButtonLabel,
                ]}
              >
                <LoginPageUiLandingText
                  layout={layout}
                  title={title}
                  descriptionHeader={descriptionHeader}
                  description={description}
                  isSecondaryButtonVisible={isSecondaryButtonVisible}
                  secondaryButtonLabel={secondaryButtonLabel}
                  onSecondaryButtonClick={onSecondaryButtonClick}
                />
              </Transition>
            </BottomDescription>
          </Column>
        </Content>
      </Hide>

      <BottomBar>
        <LanguageSelectionButton
          openLanguagePanel={() => openPanel?.("language")}
          selectedLocaleCode={selectedLocaleCode}
          locales={locales}
          bright={inverted}
        />
        <a href="https://journee.live/imprint" target="_blank" rel="noreferrer">
          <ButtonText bright={inverted}>
            {t("corporate_information")}
          </ButtonText>
        </a>
        <a href="https://journee.live/privacy" target="_blank" rel="noreferrer">
          <ButtonText bright={inverted}>
            {t("platform_privacy_policy")}
          </ButtonText>
        </a>
        <ButtonText bright={inverted} onClick={() => openPanel?.("report")}>
          {t("report_button")}
        </ButtonText>
        {Boolean(links?.length) &&
          links?.map((link, id) => (
            <a
              key={`link_${id}`}
              href={link.url}
              target="_blank"
              rel="noreferrer"
            >
              <ButtonText bright={inverted}>{link.text}</ButtonText>
            </a>
          ))}
      </BottomBar>
    </Wrapper>
  );
};

export default LoginPageUi;
