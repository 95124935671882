// This is a list of manually triggered errors (mainly for Sentry).
// Sentries already automatically collects thrown errors and console.error messages.
// This list represent the known error we want to manually watch for.

const projectCode = "LUG -";

export const Errors = {
  VIDEO_CONFERENCE_ERROR: `${projectCode} Video Conference Error`,
  ENVIRONMENT_DATA_NOT_AVAILABLE: `${projectCode} Did not recive environment data while fetching it.`,
  ENVIRONMENT_FAILED_TO_LOAD: `${projectCode} User navigated to an url with an environment slug which cannot be found or loaded.`,
  LOGIN_VIDEO_BG_COULD_NOT_LOAD: `${projectCode} The link for the login video background failed to load (Journee org only).`,
  IMAGE_COULDN_NOT_LOAD: `${projectCode} An external image failed to load (Journee org only).`,
} as const;
