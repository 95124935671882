import { styled } from "styled-components";
import Transition from "../../../components-ui/atoms/Transition";
import { BaseLayout } from "../../../core/layout/PanelsLayout";
import { useIsLandscape, useIsSmallScreen } from "../../../hooks/ui";
import { useStore } from "../../../store/store";
import LanguageLogic from "./Language.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0px;
  top: 0px;
`;

const LanguagePanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.language.visible);

  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();

  return (
    <Layout id="panel-language">
      <Transition
        height="100%"
        watch={[visible]}
        delay={200}
        slideIn={isSmallScreen && !isLandscape ? "down" : undefined}
        slideOut={isSmallScreen && !isLandscape ? "down" : undefined}
        justify="center"
        align="center"
      >
        {visible && <LanguageLogic />}
      </Transition>
    </Layout>
  );
};

export default LanguagePanel;
