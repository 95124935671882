import { useCallback, useEffect, useRef } from "react";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { removeEmailsFromString } from "../../../core/tracking/sanitize";
import { logError } from "../../../lib/logger";
import { useStore } from "../../../store/store";
import { initialiseProfile } from "../profile/lib/profileUtils";

export const useUpdateSearchableProfile = () => {
  const visitorTokenData = useStore((s) => s.session.visitorTokenData);
  const profileDataInitialisedRef = useRef(false);
  const visitorToken = useStore((s) => s.session.visitorToken);
  const profileData = useStore((s) => s.profile.profileData);
  const playerId = useStore((s) => s.gameConnection.playerId);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const { environment, environmentId } = useEnvironmentContext();
  const showPlayerList = environment?.showPlayerList;
  const setProfileData = useStore((s) => s.profile.setProfileData);

  const upsertVisitorProfile = useCallback(async () => {
    if (!visitorToken || !profileData || !environment?.id) return;

    await fetch(
      `${import.meta.env.VITE_GYARALESS_URL}/profiles/${environment?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${visitorToken}`,
        },
        body: JSON.stringify({
          businessCard: profileData,
          photonRoomId: roomId || "NONE",
          photonPlayerId: playerId || 0,
          updated: false,
        }),
      }
    ).catch((error) => {
      logError(
        "GENERIC",
        "Upserting visitor profile.",
        removeEmailsFromString(error?.message || error.response?.data?.message)
      );
    });
  }, [visitorToken, profileData, environment, playerId, roomId]);

  useEffect(() => {
    if (!visitorTokenData || profileDataInitialisedRef.current) return;
    const initializedProfile = initialiseProfile(profileData, []);
    setProfileData(initializedProfile);
    profileDataInitialisedRef.current = true;
  }, [
    environmentId,
    profileData,
    setProfileData,
    upsertVisitorProfile,
    visitorTokenData,
  ]);

  useEffect(() => {
    if (!showPlayerList) return;
    const intervalId: number | undefined = window.setInterval(
      upsertVisitorProfile,
      10000
    );
    // Upsert the current profile immediately, and set interval to update every 10 seconds
    return () => clearInterval(intervalId);
  }, [showPlayerList, upsertVisitorProfile]);
};
