import { SvgProps } from "../../types/svg";

const MicrophoneOnThinIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 13C11.6572 13 13 11.6572 13 10V5C13 3.34281 11.6572 2 10 2C8.34281 2 7 3.34281 7 5V10C7 11.6562 8.31562 13 10 13ZM7.5 5C7.5 3.62156 8.62156 2.5 10 2.5C11.3784 2.5 12.5 3.62156 12.5 5V10C12.5 11.3784 11.3784 12.5 10 12.5C8.62156 12.5 7.5 11.3781 7.5 10V5ZM15 10V8.25C15 8.1125 14.8875 8 14.75 8C14.6125 8 14.5 8.1125 14.5 8.25V10C14.5 12.4816 12.4816 14.5 10 14.5C7.51844 14.5 5.5 12.4813 5.5 10V8.25C5.5 8.1125 5.38781 8 5.25 8C5.11219 8 5 8.1125 5 8.25V10C5 12.6713 7.11125 14.8406 9.75 14.975V17.5H6.25C6.11219 17.5 6 17.6125 6 17.75C6 17.8875 6.11219 18 6.25 18H13.75C13.8877 18 14 17.8877 14 17.75C14 17.6123 13.8877 17.5 13.75 17.5H10.25V14.9747C12.8875 14.8406 15 12.6719 15 10Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MicrophoneOnThinIcon;
