import { styled } from "styled-components";
import Transition from "../../../components-ui/atoms/Transition";
import { useStore } from "../../../store/store";
import FullscreenVideoLogic from "./FullscreenVideo.logic";
import FullscreenVideoLogicPersistent from "./FullscreenVideo.logic.persistent";

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const FullscreenVideoPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.fullscreenVideo.visible);
  const slug = useStore((s) => s.layout.panels.fullscreenVideo.slug);

  return (
    <Layout id="panel-fullscreen-video">
      <Transition watch={[visible]} height="100%">
        {visible && <FullscreenVideoLogic slug={slug} />}
      </Transition>

      <FullscreenVideoLogicPersistent />
    </Layout>
  );
};

export default FullscreenVideoPanel;
