// The user flow is a simple sequential state-machine.

// These steps are used to have a generic overview on the app flow/state.
// Below we have all possible steps. Nontheless a step may skip itself depending the situation.

export const userFlowPhases = {
  login: "login:",
  entering: "entering:",
  experience: "experience:",
} as const;

export type UserFlowStep =
  | // Loading the environment data from the CMS.
  "load-environment"
  // A default unskippable intro animation.
  | "login:intro"
  // Check if browser is supported.
  | "login:check-browser"
  // Check if visitor has any authenticaton.
  | "login:auth-check"
  // Check if the experience is open.
  | "login:check-opening-times"
  // Check if the visitor needs to login manually.
  | "login:auth-manual"
  // Check if there are instances online.
  | "login:availability"
  // If still going, we wait for the queue to be resolved and an instance assigned.
  | "login:queue-and-connect"
  // Check if the visitor need to do an explicit click to start the experience.
  | "login:start-action"
  // The experience is ready.
  | "experience:ready"
  // The experience has ended.
  | "ended";

export const userFlow: UserFlowStep[] = [
  // Loading the environment data from the CMS.
  "load-environment",
  // A default unskippable intro animation.
  "login:intro",
  // Check if browser is supported.
  "login:check-browser",
  // Check if visitor has any authenticaton.
  "login:auth-check",
  // Check if the experience is open.
  "login:check-opening-times",
  // Check if the visitor needs to login manually.
  "login:auth-manual",
  // Check if there are instances online.
  "login:availability",
  // If still going, we wait for the queue to be resolved and an instance assigned.
  "login:queue-and-connect",
  // Check if the visitor need to do an explicit click to start the experience.
  "login:start-action",
  // The experience is ready.
  "experience:ready",
];
