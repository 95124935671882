import styled, { useTheme } from "styled-components";
import Button from "../../../../components-ui/atoms/Button";
import Hide from "../../../../components-ui/atoms/Hide";
import Icon from "../../../../components-ui/atoms/Icon";
import { PanelTestIds } from "../../../../constants/testIds";
import { rgbaToHex } from "../../../../lib/color";
import { zIndex } from "../../../../style/theme";
import {
  INDICATOR_SIZE,
  VIDEO_AVATAR_CIRCLE_SIZE,
} from "../lib/videoAvatarsConstants";
import CameraCover from "./CameraCover";
import SpeakingWidget from "./SpeakingWidget";
import VideoAvatarCircle, {
  Props as VideoAvatarCircleProps,
} from "./VideoAvatarCircle";

export const stringToColour = (str: string) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

export const SpeakingIndicatorWrapper = styled.div<{ $size: number }>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  bottom: 1px;
  left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: ${zIndex.overlays + 1};
`;

const ScreenSharingIndication = styled(Hide)`
  bottom: 1px;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: ${zIndex.overlays + 1};
`;

type Props = {
  toggleVideo?: () => void;
  toggleLocalAudio: () => void;
  micMuted: boolean;
} & VideoAvatarCircleProps;

const LocalVideoAvatarCircle: React.FC<Props> = ({
  participant,
  toggleLocalAudio,
  toggleVideo,
  size = 108,
  playerKey,
  micMuted,
  renderTitleBreakout,
  micHidden = false,
  hideTitle = false,
  noBorder = false,
  avatarColor,
  playerName,
  circleStyle,
  isSharingScreen,
}) => {
  const theme = useTheme();

  const isSpeaking = participant?.isSpeaking || false;

  const playerAvatarColor =
    (avatarColor && rgbaToHex(avatarColor)) ||
    stringToColour(playerKey || "random-seed");

  const speakingIndicatorSize =
    size < VIDEO_AVATAR_CIRCLE_SIZE.LARGE
      ? INDICATOR_SIZE.SMALL
      : INDICATOR_SIZE.LARGE;

  const speakingIndicator = (
    <SpeakingIndicatorWrapper
      $size={speakingIndicatorSize}
      data-testid={PanelTestIds.videoAvatars.videoAvatarCircle.muteButton}
    >
      <SpeakingWidget
        micHidden={micHidden}
        size={speakingIndicatorSize}
        isSpeaking={isSpeaking}
        isMuted={micMuted}
        toggleMute={toggleLocalAudio}
      />
    </SpeakingIndicatorWrapper>
  );

  const screenShareIndicator = (
    <ScreenSharingIndication hide={false}>
      <Button.Purple
        circular
        override={{
          width: speakingIndicatorSize + "px",
          height: speakingIndicatorSize + "px",
        }}
      >
        <Icon.ScreenShareFilled color={theme.colorBelow1} size="18px" />
      </Button.Purple>
    </ScreenSharingIndication>
  );

  return (
    <VideoAvatarCircle
      testId={PanelTestIds.videoAvatars.localVideoAvatarCircle}
      renderTitleBreakout={renderTitleBreakout}
      isSharingScreen={isSharingScreen}
      hideTitle={hideTitle}
      playerKey={playerKey}
      playerName={playerName}
      avatarColor={avatarColor}
      speakingIndicatorPosition="front"
      size={size}
      isLocal
      participant={participant}
      noBorder={noBorder}
      speakingIndicator={speakingIndicator}
      screenShareIndicator={screenShareIndicator}
      renderCoverComponent={({ isLoading, isVideoAvailable }) => (
        <CameraCover
          isLoading={isLoading}
          isVideoAvailable={isVideoAvailable}
          size={size}
          playerAvatarColor={playerAvatarColor}
          toggleVideo={toggleVideo}
        />
      )}
      circleStyle={circleStyle}
    />
  );
};

export default LocalVideoAvatarCircle;
