import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMachine } from "@xstate/react";
import { isEqual } from "lodash";
import { VisitorTokenData } from "../../features/login/loginUtils";
import { SearchableProfile } from "../../features/panels/profile/lib/profileTypes";
import { parsePlayerExternalId } from "../../features/panels/social/Social.logic";
import useSearchPlayerList from "../../features/panels/social/subpages/players/useSearchPlayerList";
import useActiveParticipants from "../../features/panels/videoAvatars/lib/useActiveParticipants";
import { filterEmptyDevices } from "../../lib/voice";
import { useStore } from "../../store/store";
import { useEnvironmentContext } from "../EnvironmentFetcher.core";
import { matchPanelName } from "../layout/panelsTypes";
import { VideoConferenceParticipant } from "./VideoConferenceAdapter";
import MediaDevicesService, { UpdatedList } from "./mediaDevices";
import { ScreenShareProviderInterface } from "./screenShareProviders/ScreenShareProviderInterface";
import VonageScreenShareProvider from "./screenShareProviders/VonageScreenShareProvider";
import useConferenceControls from "./useConferenceControls";
import { getEnvironmentState } from "./util.core";
import { screenShareMachineActor } from "./vonageStateMachine/screenShare.machine";
import vonageVideoConferenceStateMachine, {
  cleanUnusedConference,
} from "./vonageStateMachine/vonageVideoConferenceState.machine";

export type UseVonageMachine = ReturnType<
  typeof useMachine<typeof vonageVideoConferenceStateMachine>
>;

// https://www.npmjs.com/package/@xstate/inspect
// const { inspect } = createBrowserInspector();

type VideoConferenceContextType = {
  toggleLocalAudio: () => Promise<void>;
  startVideo: () => Promise<void>;
  stopLocalAudio: () => Promise<void>;
  startLocalAudio: () => Promise<void>;
  stopVideo: () => Promise<void>;
  toggleVideo: () => Promise<void>;
  refreshMediaDevices: (updated: UpdatedList) => void;
  stopScreenShare: () => Promise<void>;
  startScreenShare: () => Promise<void>;
  toggleScreenShare: () => Promise<void>;
  mediaDeviceService: MediaDevicesService;
  startRemoteParticipantVideo: (participantId: string) => void;
  stopRemoteParticipantVideo: (participantId: string) => void;
  joinConference: () => void;
  goToDevicePreview: () => void;
  leaveConference: () => void;
  selectMicrophone: (device: MediaDeviceInfo) => void;
  selectSpeakers: (device: MediaDeviceInfo) => void;
  selectCamera: (device: MediaDeviceInfo) => void;
  machineRef: UseVonageMachine[2];
};

export const useVideoConferenceControlsContext = () => {
  const context = useContext(VideoConferenceContext);
  if (!context) {
    throw new Error(
      "useVideoConferenceControlsContext must be used within a VideoConferenceProvider"
    );
  }
  return context;
};

const VideoConferenceContext = createContext<VideoConferenceContextType>({
  toggleLocalAudio: async () => {},
  startVideo: async () => {},
  stopLocalAudio: async () => {},
  startLocalAudio: async () => {},
  stopVideo: async () => {},
  refreshMediaDevices: async () => {},
  toggleVideo: async () => {},
  stopScreenShare: async () => {},
  startScreenShare: async () => {},
  toggleScreenShare: async () => {},
  mediaDeviceService: {} as MediaDevicesService,
  startRemoteParticipantVideo: () => {},
  stopRemoteParticipantVideo: () => {},
  joinConference: () => {},
  goToDevicePreview: () => {},
  leaveConference: () => {},
  selectMicrophone: () => {},
  selectSpeakers: () => {},
  selectCamera: () => {},
  machineRef: {} as UseVonageMachine[2],
});

type Props = {
  children: React.ReactNode;
  screenShareProvider: ScreenShareProviderInterface;
  visitorToken: string;
  visitorTokenData: VisitorTokenData;
};

const VideoConference: React.FC<Props> = ({
  children,
  screenShareProvider,
  visitorTokenData,
  visitorToken,
}) => {
  // Temporary here until we migrate everything to state machines
  const [conferenceMachineState, sendEventToStateMachine, machineRef] =
    useMachine(vonageVideoConferenceStateMachine, {
      systemId: "videoConference",
      //inspect,
    });
  const mediaDeviceService = useRef<MediaDevicesService>(
    new MediaDevicesService()
  );
  const { environmentId } = useEnvironmentContext();
  const playerRefreshIntervalId = useRef<number | null>(null);
  const registerCleanupHook = useStore((s) => s.userFlow.registerCleanupHook);
  const setScreenSharer = useStore((s) => s.videoConference.setScreenSharer);
  const amIScreenSharer = useStore((s) => s.videoConference.amIScreenSharer());
  const addInactivityException = useStore(
    (s) => s.session.addInactivityException
  );
  const removeInactivityException = useStore(
    (s) => s.session.removeInactivityException
  );
  const getPlayerByKey = useStore((s) => s.gameConnection.getPlayerByKey);

  const isPlayerListOpen = useStore(
    (s) => s.layout.panels.social.subpage === "social/players"
  );
  const isPlayerProfileOpen = useStore(
    (s) =>
      s.layout.panels.social.subpage &&
      matchPanelName(s.layout.panels.social.subpage) ===
        "social/playerProfile/:playerId"
  );
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const { roomId, setPlayer, playerId, setAllPlayers, allPlayers } = useStore(
    (s) => s.gameConnection
  );
  const socialSubPage = useStore((s) => s.layout.panels.social.subpage);
  let playerProfile: SearchableProfile | null = null;
  const { playerId: openedPlayerId } = useMemo(() => {
    const result = socialSubPage && parsePlayerExternalId(socialSubPage);
    if (!result) return { playerId: null, roomId: null };
    return result;
  }, [socialSubPage]);
  if (openedPlayerId && roomId) {
    playerProfile = getPlayerByKey(openedPlayerId, roomId);
  }
  const machineStateRef = useRef(conferenceMachineState);
  machineStateRef.current = conferenceMachineState;
  useEffect(() => {
    return () => {
      // If we are unmounting and we still have an old machine that didn't clean itself properly, clean
      // it up here manually
      if (machineStateRef.current?.context.session) {
        const contextSnapshot = machineRef?.getSnapshot().context;
        const subscriberSnapshots = Object.values(
          contextSnapshot.participants
        ).map((sub) => sub.getSnapshot().context.subscriber);
        cleanUnusedConference(
          contextSnapshot.session,
          contextSnapshot.publisher,
          subscriberSnapshots
        );
        machineRef?.stop();
      }
    };
  }, [machineRef]);

  const videoConferenceInitialized = useStore((state) =>
    state.videoConference.isConferenceInitialized()
  );
  const {
    setScreenSharing,
    setActiveScreenShare,
    participants,
    setActiveParticipants,
    screenSharer,
    setUpdateActiveParticipantVideo,
  } = useStore((s) => s.videoConference);
  const videoConferenceStore = useStore((s) => s.videoConference);
  const videoConferenceStoreRef = useRef(videoConferenceStore);
  videoConferenceStoreRef.current = videoConferenceStore;

  const {
    selectedCamera,
    selectedMicrophone,
    selectedSpeakers,
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    permissions: mediaPermissions,
  } = useStore((s) => s.userMedia);

  const hasCameras = videoInputDevices?.length > 0;
  const hasMicrophones = audioInputDevices?.length > 0;

  const { findByUserId, findManyPlayers } = useSearchPlayerList();
  const activeParticipants = useActiveParticipants({
    allParticipants: Object.values(participants),
  });

  const {
    selectMicrophone,
    selectSpeakers,
    selectCamera,
    toggleLocalAudio,
    startVideo,
    stopLocalAudio,
    startLocalAudio,
    stopVideo,
    toggleVideo,
    stopScreenShare,
    startScreenShare,
    toggleScreenShare,
    startRemoteParticipantVideo,
    stopRemoteParticipantVideo,
  } = useConferenceControls({
    screenShareProvider,
    sendEvent: sendEventToStateMachine,
  });

  // Temporary hack until we migrate everything to state machiens
  useEffect(() => {
    if (
      screenShareProvider instanceof VonageScreenShareProvider &&
      conferenceMachineState.context.session
    ) {
      const ref = screenShareMachineActor.start();
      ref.send({
        type: "lugia.enableVonageScreenSharing",
        data: conferenceMachineState.context.session,
      });
    } else if (!(screenShareProvider instanceof VonageScreenShareProvider)) {
      screenShareMachineActor.stop();
    }
  }, [screenShareProvider, conferenceMachineState]);
  // Here we are setting active participants to global store
  // and also setting the which participants should have video on
  useEffect(() => {
    setActiveParticipants(activeParticipants);
    setUpdateActiveParticipantVideo(activeParticipants.map((p) => p.userId));
  }, [
    activeParticipants,
    setActiveParticipants,
    setUpdateActiveParticipantVideo,
  ]);

  const [activeParticipantsMapped, setActiveParticipantsMapped] = useState<
    Record<string, Partial<VideoConferenceParticipant>>
  >({});

  useEffect(() => {
    setActiveParticipantsMapped((prev) => {
      const newParticipants: Record<
        string,
        Partial<VideoConferenceParticipant>
      > = {};
      Object.entries(participants).map(([key, value]) => {
        newParticipants[key] = {
          isVideoHidden: value.isVideoHidden,
          userId: value.userId,
          isLocal: value.isLocal,
          isPublishing: value.isPublishing,
          isVideoOn: value.isVideoOn,
          streams: value.streams,
        };
      });
      if (isEqual(prev, newParticipants)) {
        return prev;
      }

      return newParticipants;
    });
  }, [setActiveParticipantsMapped, participants]);
  // Idea is this: we are watching the participants and if the video is hidden, we stop the video
  // BUT only if the player list is not open.
  useEffect(() => {
    Object.values(activeParticipantsMapped).forEach((p) => {
      if (p.isLocal || !p.isPublishing || !p.userId) return;
      const openedPlayerId = isPlayerProfileOpen ? playerProfile?.userId : null;
      const shouldShowVideo =
        !p.isVideoHidden || p.userId === openedPlayerId || isPlayerListOpen;
      if (shouldShowVideo) {
        startRemoteParticipantVideo(p.userId);
      } else {
        stopRemoteParticipantVideo(p.userId);
      }
    });
  }, [
    activeParticipantsMapped,
    startRemoteParticipantVideo,
    stopRemoteParticipantVideo,
    isPlayerListOpen,
    isPlayerProfileOpen,
    socialSubPage,
    playerProfile?.userId,
  ]);

  const refreshPlayers = useCallback(async () => {
    if (!roomId) return;
    // We cannot use store participants since they change very often
    // and they trigger the useEffect that sets the interval for this function,
    // resulting in many  calls
    if (!participants) return;
    const playerUserIds = Object.values(participants)
      .filter((p) => p?.userId)
      .map((p) => p?.userId as string);
    const allProfiles = await findManyPlayers({
      userIds: playerUserIds,
    });
    if (allProfiles) {
      setAllPlayers(allProfiles.map((p) => p.document));
    }
  }, [roomId, participants, findManyPlayers, setAllPlayers]);

  const findPlayerProfile = useCallback(
    async (userId: string) => {
      const playerProfiles = await findByUserId(userId);
      const firstProfile = playerProfiles?.[0];
      if (firstProfile) {
        setPlayer(firstProfile.document, userId);
      }
    },
    [findByUserId, setPlayer]
  );

  useEffect(() => {
    if (
      (conferenceMachineState.value === "Lobby" ||
        conferenceMachineState.matches("ConnectedToConference")) &&
      visitorTokenRef.current
    ) {
      getEnvironmentState(environmentId, visitorTokenRef.current).then(
        (data) => {
          if (data?.presenterId) {
            setScreenSharing(data.presenterId);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentId, setScreenSharing, conferenceMachineState.value]);

  useEffect(() => {
    const newParticipant = Object.values(participants).find(
      (p) => !allPlayers[p.userId]
    );
    if (newParticipant?.userId) {
      findPlayerProfile(newParticipant.userId);
    }
  }, [findPlayerProfile, participants, allPlayers]);

  useEffect(() => {
    if (!screenSharer?.userId) return;
    screenShareProvider?.listenForScreenShare({
      started: (mediaStream) => {
        setActiveScreenShare(mediaStream);
        addInactivityException("screenShare");
      },
      stopped: () => {
        setActiveScreenShare(null);
        setScreenSharer(null);
        screenShareProvider.cleanUpScreensharing();
        removeInactivityException("screenShare");
      },
    });
  }, [
    addInactivityException,
    screenSharer,
    removeInactivityException,
    screenShareProvider,
    setActiveScreenShare,
    setScreenSharer,
  ]);

  useEffect(() => {
    if (videoConferenceInitialized) {
      playerRefreshIntervalId.current = window.setInterval(
        refreshPlayers,
        5000
      );
    }
    return () => {
      if (playerRefreshIntervalId.current)
        window.clearInterval(playerRefreshIntervalId.current);
    };
  }, [refreshPlayers, videoConferenceInitialized]);

  const onDeviceListChanged = useCallback(
    (updated: UpdatedList) => {
      const audioInputDevices = updated.list.audioInput || [];
      const videoDevices = updated.list.videoInput || [];
      const audioOutputDevices = updated.list.audioOutput || [];
      const filteredInputDevices = filterEmptyDevices(audioInputDevices);
      const filteredOutputDevices = filterEmptyDevices(audioOutputDevices);
      const filteredVideoDevices = filterEmptyDevices(videoDevices);

      const hasCameras = filteredVideoDevices.length > 0;
      const hasMicrophones = filteredInputDevices.length > 0;
      const hasSpeakers = filteredOutputDevices.length > 0;
      sendEventToStateMachine({
        type: "lugia.deviceListChanged",
        data: {
          selectedMicrophone: !hasMicrophones ? null : selectedMicrophone,
          selectedSpeaker: !hasSpeakers ? null : selectedSpeakers,
          selectedCamera: !hasCameras ? null : selectedCamera,
          videoInputDevices: filteredVideoDevices,
          audioInputDevices: filteredInputDevices,
          audioOutputDevices: filteredOutputDevices,
        },
      });
    },
    [
      selectedCamera,
      selectedMicrophone,
      selectedSpeakers,
      sendEventToStateMachine,
    ]
  );

  useEffect(() => {
    if (!visitorToken || currentStep !== "experience:ready") return;
    screenShareProvider.init(environmentId, visitorToken);
  }, [currentStep, environmentId, screenShareProvider, visitorToken]);

  useEffect(() => {
    const unsubscribe =
      mediaDeviceService.current.onDeviceListChanged(onDeviceListChanged);
    return () => {
      unsubscribe();
    };
  }, [mediaDeviceService, onDeviceListChanged]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sendEventToStateMachine({ type: "lugia.leaveConference" });
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [sendEventToStateMachine]);

  useEffect(() => {
    if (!visitorToken || currentStep !== "experience:ready") return;
    const userId = visitorTokenDataRef.current.id;
    if (conferenceMachineState.value === "Disconnected") {
      sendEventToStateMachine({
        type: "lugia.InitSession",
        data: {
          environmentId,
          userId,
          visitorToken,
        },
      });
      registerCleanupHook("videoConference", () => {
        sendEventToStateMachine({
          type: "lugia.leaveConference",
        });
      });
    }
  }, [
    currentStep,
    environmentId,
    visitorToken,
    roomId,
    playerId,
    registerCleanupHook,
    setScreenSharing,
    sendEventToStateMachine,
    conferenceMachineState.value,
  ]);
  // This is probably the ugliest thing I've written since a while. Forgive me.
  // This is a workaround to make sure that the video conference is initialized ONCE.
  // The refs are used to keep track of the values that are used in the useEffect, but
  // we don't want this useEffect to run every time the values change, only once
  const visitorTokenRef = useRef(visitorToken);
  visitorTokenRef.current = visitorToken;
  const visitorTokenDataRef = useRef(visitorTokenData);
  visitorTokenDataRef.current = visitorTokenData;
  const currentStepRef = useRef(currentStep);
  currentStepRef.current = currentStep;
  const playerIdRef = useRef(playerId);
  playerIdRef.current = playerId;
  const roomIdRef = useRef(roomId);
  roomIdRef.current = roomId;
  const environmentIdRef = useRef(environmentId);
  environmentIdRef.current = environmentId;
  const hasCamerasRef = useRef(hasCameras);
  hasCamerasRef.current = hasCameras;
  const hasMicrophonesRef = useRef(hasMicrophones);
  hasMicrophonesRef.current = hasMicrophones;
  const mediaPermissionsRef = useRef(mediaPermissions);
  mediaPermissionsRef.current = mediaPermissions;
  const selectedCameraRef = useRef(selectedCamera);
  selectedCameraRef.current = selectedCamera;
  const selectedMicrophoneRef = useRef(selectedMicrophone);
  selectedMicrophoneRef.current = selectedMicrophone;
  const selectedSpeakersRef = useRef(selectedSpeakers);
  selectedSpeakersRef.current = selectedSpeakers;
  const videoInputDevicesRef = useRef(videoInputDevices);
  videoInputDevicesRef.current = videoInputDevices;
  const audioInputDevicesRef = useRef(audioInputDevices);
  audioInputDevicesRef.current = audioInputDevices;
  const audioOutputDevicesRef = useRef(audioOutputDevices);
  audioOutputDevicesRef.current = audioOutputDevices;

  const joinConference = useCallback(() => {
    sendEventToStateMachine({
      type: "lugia.joinConference",
    });
  }, [sendEventToStateMachine]);

  const goToDevicePreview = useCallback(() => {
    sendEventToStateMachine({
      type: "lugia.goToDevicePreview",
    });
  }, [sendEventToStateMachine]);

  const leaveConference = useCallback(() => {
    amIScreenSharer && stopScreenShare();
    sendEventToStateMachine({
      type: "lugia.leaveConference",
    });
  }, [amIScreenSharer, sendEventToStateMachine, stopScreenShare]);

  return (
    <VideoConferenceContext.Provider
      value={useMemo(
        () => ({
          joinConference,
          goToDevicePreview,
          leaveConference,
          toggleLocalAudio,
          startVideo,
          stopLocalAudio,
          startLocalAudio,
          stopVideo,
          toggleVideo,
          stopScreenShare,
          startScreenShare,
          toggleScreenShare,
          startRemoteParticipantVideo,
          stopRemoteParticipantVideo,
          selectMicrophone,
          selectSpeakers,
          selectCamera,
          refreshMediaDevices: onDeviceListChanged,
          mediaDeviceService: mediaDeviceService.current,
          machineRef,
        }),
        [
          joinConference,
          goToDevicePreview,
          leaveConference,
          toggleLocalAudio,
          startVideo,
          stopLocalAudio,
          startLocalAudio,
          stopVideo,
          toggleVideo,
          stopScreenShare,
          startScreenShare,
          toggleScreenShare,
          startRemoteParticipantVideo,
          stopRemoteParticipantVideo,
          selectMicrophone,
          selectSpeakers,
          selectCamera,
          onDeviceListChanged,
          mediaDeviceService,
          machineRef,
        ]
      )}
    >
      {children}
    </VideoConferenceContext.Provider>
  );
};

export default VideoConference;
