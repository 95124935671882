export enum SupportedOS {
  iPadOS = "iPadOS",
  MacOS = "MacOS",
  iOS = "iOS",
  Windows = "Windows",
  Android = "Android",
  Linux = "Linux",
}

export const getOS = (
  userAgent: string,
  platform: string
): SupportedOS | null => {
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  const ipadosPlatforms = ["iPad", "iPod"];
  let os: SupportedOS | null = null;

  if (
    macosPlatforms.includes(platform) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  ) {
    // Must be the iPad, as currently there are no Apple devices
    // with touchscreens.
    os = SupportedOS.iPadOS;
  } else if (ipadosPlatforms.includes(platform)) {
    os = SupportedOS.iPadOS;
  } else if (macosPlatforms.includes(platform)) {
    os = SupportedOS.MacOS;
  } else if (iosPlatforms.includes(platform)) {
    os = SupportedOS.iOS;
  } else if (windowsPlatforms.includes(platform)) {
    os = SupportedOS.Windows;
  } else if (/Android/.test(userAgent)) {
    os = SupportedOS.Android;
  } else if (/Linux/.test(platform)) {
    os = SupportedOS.Linux;
  }

  return os;
};

export const readOsInformation = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;

  const os = getOS(userAgent, platform);

  const isIpad = os === SupportedOS.iPadOS;
  const isIos = os === SupportedOS.iOS || isIpad;
  const isAndroid = os === SupportedOS.Android;
  const isWindows = os === SupportedOS.Windows;

  return {
    isWindows,
    isIos,
    isIpad,
    isAndroid,
  };
};
