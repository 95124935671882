import React from "react";
import { SvgProps } from "../../types/svg";

const VideoStopRecordingIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 4.67293V15.3271C18 16.8034 16.8063 18 15.3333 18H4.66667C3.19375 18 2 16.8034 2 15.3271V4.67293C2 3.19655 3.19375 2 4.66667 2H15.3333C16.8042 2 18 3.19655 18 4.67293Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default VideoStopRecordingIcon;
