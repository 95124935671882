import Button from "../../../../components-ui/atoms/Button";
import { PropsClickable } from "../../../../components-ui/atoms/Clickable";

type Props = {
  active: boolean;
  error?: boolean;
  disabled: boolean;
  errorText?: string;
  onIcon: React.ReactNode;
  offIcon: React.ReactNode;
  onClick: () => void;
};

const PresentationBarItem: React.FC<Props> = ({
  active,
  disabled,
  error,
  onIcon,
  offIcon,
  onClick,
}) => {
  const overrides: PropsClickable = {
    width: "44px",
    height: "44px",
  };

  return (
    <Button.Subtle
      circular
      disabled={disabled || error}
      onClick={onClick}
      silent={!active}
      override={overrides}
    >
      {active ? onIcon : offIcon}
    </Button.Subtle>
  );
};

export default PresentationBarItem;
