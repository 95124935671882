import { SvgProps } from "../../types/svg";

const SurprisedFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10ZM7.5125 11C8.0625 11 8.5125 10.5531 8.5125 10C8.5125 9.44687 8.0625 9 7.5125 9C6.95937 9 6.5125 9.44687 6.5125 10C6.5125 10.5531 6.95937 11 7.5125 11ZM12.5125 9C11.9594 9 11.5125 9.44687 11.5125 10C11.5125 10.5531 11.9594 11 12.5125 11C13.0625 11 13.5125 10.5531 13.5125 10C13.5125 9.44687 13.0625 9 12.5125 9ZM7.56563 7.02812C7.725 7.00937 7.8625 7 8 7C8.275 7 8.5 6.775 8.5 6.5C8.5 6.225 8.275 6 8 6C7.81875 6 7.6375 6.0125 7.4625 6.03438C6.58125 6.15313 5.78437 6.55 5.15937 7.13438C4.95687 7.32188 4.94625 7.64062 5.13438 7.84062C5.32188 8.04375 5.64063 8.05312 5.84063 7.86562C6.325 7.4125 6.93125 7.11562 7.56563 7.02812ZM12 7C12.1375 7 12.275 7.00937 12.4062 7.02812C13.0688 7.11562 13.675 7.4125 14.1594 7.86562C14.3594 8.05312 14.6781 8.04375 14.8656 7.84062C15.0531 7.64062 15.0437 7.32188 14.8406 7.13438C14.2156 6.55 13.4188 6.15313 12.5375 6.03438C12.3625 6.0125 12.1813 6 12 6C11.725 6 11.5 6.225 11.5 6.5C11.5 6.775 11.725 7 12 7ZM10 15.5C10.8281 15.5 11.5 14.8281 11.5 14C11.5 13.1719 10.8281 12.5 10 12.5C9.17188 12.5 8.5 13.1719 8.5 14C8.5 14.8281 9.17188 15.5 10 15.5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default SurprisedFilledIcon;
