import { useNavigate } from "react-router";
import Button from "../../components-ui/atoms/Button";
import Column from "../../components-ui/atoms/Column";
import HeaderBadge from "../../components-ui/atoms/HeaderBadge";
import Icon from "../../components-ui/atoms/Icon";
import Space from "../../components-ui/atoms/Space";
import Typo from "../../components-ui/atoms/Typo";
import { useText } from "../../core/i18n/i18nHooks";
import { useGetLoginPath } from "../../core/routing/routingHooks";
import SpacePageUi from "./SpacePageUi";

const EndSessionPageUi: React.FC = () => {
  const navigate = useNavigate();
  const loginPath = useGetLoginPath();
  const t = useText();

  return (
    <SpacePageUi>
      <Column
        textAlign="center"
        justify="center"
        align="center"
        gap={4}
        width="600px"
      >
        <HeaderBadge rippleLineWidth={5} noBackground color="inverted">
          <Icon.PersonWalking inheritColor />
        </HeaderBadge>
        <Typo.Title color="white">{t("end_session_ended")}</Typo.Title>
        <Typo.Body color="white" size="17px">
          {t("end_session_description")}
        </Typo.Body>
        <Space h={2} />
        <Button.Bright
          onClick={() => {
            navigate(loginPath, {
              relative: "path",
            });
            navigate(0); // refresh
          }}
        >
          {t("end_session_back_to_start")}
        </Button.Bright>
      </Column>
    </SpacePageUi>
  );
};

export default EndSessionPageUi;
