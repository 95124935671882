import { SvgProps } from "../../types/svg";

const ShootingStarFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.61872 9.64999L9.89372 10.1275L11.3562 13.1031C11.618 13.6344 12.3787 13.6384 12.6443 13.1031L14.109 10.1266L17.3834 9.64999C17.9733 9.59062 18.2107 8.83749 17.781 8.42187L15.41 6.10937L15.9443 2.84062C16.042 2.25062 15.4218 1.80781 14.9022 2.08499L11.9718 3.62812L9.04215 2.08499C8.79997 1.95603 8.50653 1.97984 8.28403 2.14006C8.06528 2.30021 7.95247 2.57412 7.9994 2.83974L8.55878 6.10849L6.1869 8.42099C5.79059 8.83437 6.02809 9.59062 6.61872 9.64999ZM8.28122 11.7469C8.13434 11.5719 7.94059 11.5 7.74997 11.5C7.55934 11.5 7.36622 11.5732 7.21965 11.7197L2.21965 16.7197C1.92669 17.0127 1.92669 17.4872 2.21965 17.7803C2.51262 18.0735 2.98715 18.0733 3.28028 17.7803L8.28028 12.7803C8.57184 12.4875 8.57184 12.0125 8.28122 11.7469ZM10.25 15C10.0581 15 9.86622 15.0732 9.71965 15.2197L8.21965 16.7197C7.92668 17.0127 7.92668 17.4872 8.21965 17.7803C8.51262 18.0735 8.98715 18.0733 9.28028 17.7803L10.7803 16.2803C11.0732 15.9874 11.0732 15.5128 10.7803 15.2197C10.6343 15.0731 10.4406 15 10.25 15ZM3.28122 11.7812L4.78122 10.2812C5.07419 9.98828 5.07419 9.51374 4.78122 9.22062C4.63434 9.07187 4.44059 8.99999 4.24997 8.99999C4.05934 8.99999 3.86622 9.07324 3.71965 9.21971L2.21965 10.7197C1.92669 11.0127 1.92669 11.4872 2.21965 11.7803C2.51262 12.0735 2.98747 12.0719 3.28122 11.7812Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ShootingStarFilledIcon;
