import FadeIn from "../../../../../../components-ui/atoms/FadeIn";
import { GameColor } from "../../../../../../core/gameConnection/messages/sharedDataTypes";
import { VideoConferenceParticipant } from "../../../../../../core/videoConference/VideoConferenceAdapter";
import { ProfileData } from "../../../../profile/lib/profileTypes";
import EmptyPlayerProfileUiNormal from "./EmptyPlayerProfile.ui.normal";
import FullPlayerProfileUi from "./FullPlayerProfile.ui";

export type AvailableSocials = Pick<
  ProfileData,
  "twitter" | "linkedin" | "instagram" | "facebook"
>;

export type Props = {
  playerName: string;
  playerTitle?: string;
  playerCompany?: string;
  avatarId?: string;
  isStillConnected: boolean;
  avatarColor: GameColor;
  socials?: AvailableSocials;
  playerKey: string;
  participant: VideoConferenceParticipant | null;
  onTeleport: () => void;
  onPrevious: () => void;
};

const PlayerProfileUi: React.FC<Props> = (props) => {
  if (props.avatarId || Object.keys(props.socials || {}).length > 0) {
    return (
      <FadeIn>
        <FullPlayerProfileUi {...props} />
      </FadeIn>
    );
  } else {
    return (
      <FadeIn>
        <EmptyPlayerProfileUiNormal {...props} />{" "}
      </FadeIn>
    );
  }
};

export default PlayerProfileUi;
