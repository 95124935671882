import styled, { useTheme } from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Column from "../../../components-ui/atoms/Column";
import HeaderBadge from "../../../components-ui/atoms/HeaderBadge";
import Icon from "../../../components-ui/atoms/Icon";
import Row from "../../../components-ui/atoms/Row";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import Card from "../../../components-ui/molecules/Card";
import { useText } from "../../../core/i18n/i18nHooks";
import { Props } from "./Quest.ui";
import QuestCard from "./component/QuestCard";

const PaddingWrapper = styled.div`
  padding: 0px 16px;
`;

const QuestUiHeader: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <Column align="center">
      <Space h={8} />
      <Row width="100%" align="center" justify="center">
        <HeaderBadge>
          <Icon.Star inheritColor size="32px" />
        </HeaderBadge>
      </Row>
      <Space h={4} />
      <Typo.Title>{title}</Typo.Title>
      <Space h={2} />
      <Typo.Body>{description}</Typo.Body>
    </Column>
  );
};

const QuestUiNormal: React.FC<Props> = ({
  handleDismiss,
  buttonFilters,
  isLoadingQuests,
  quests,
}) => {
  const t = useText();
  const theme = useTheme();

  return (
    <Spatial tiltX={-6} rim glare mouseZoom mouseTiltX mouseTiltY dragMotion>
      <Card
        testId={"normal"}
        maxHeight="610px"
        hideScrollSpace
        hideScrollButton
        onClose={handleDismiss}
      >
        <PaddingWrapper>
          <QuestUiHeader
            title={t("quest_panel_title")}
            description={t("quest_panel_description")}
          />

          <Space h={4} />

          <Row width="100%" align="center" justify="center">
            {buttonFilters.map((buttonFilter) => (
              <Button.Subtle
                key={buttonFilter.type}
                onClick={buttonFilter.onClick}
                override={{
                  backgroundColor: buttonFilter.active
                    ? theme.colorAbove0
                    : "inherit",
                }}
              >
                {t(`quest_panel_filter_${buttonFilter.type}`)}
              </Button.Subtle>
            ))}
          </Row>

          <Space h={4} />

          <Column align="stretch" gap={1}>
            {isLoadingQuests ? (
              <Row width="100%" justify="center">
                <Icon.RipplesAnimated />
              </Row>
            ) : (
              quests.map((quest) => <QuestCard key={quest.id} quest={quest} />)
            )}
          </Column>

          {quests.length === 0 && (
            <Column align="center" justify="center" height="40px">
              <Typo.Body>{t("quest_panel_no_quests")}</Typo.Body>
            </Column>
          )}
        </PaddingWrapper>

        <Space h={6} />
      </Card>
    </Spatial>
  );
};

export default QuestUiNormal;
