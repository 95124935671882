export class Vec {
  x: number;
  y: number;
  constructor(x?: number, y?: number) {
    this.x = x ?? 0;
    this.y = y ?? 0;
  }
  getMagnitude() {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }
  getAngle() {
    return Math.atan2(this.y, this.x);
  }
  setMagnitude(value: number) {
    const a = this.getAngle();
    this.x = Math.cos(a) * value;
    this.y = Math.sin(a) * value;
  }
  getNormalised() {
    const v = new Vec(this.x, this.y);
    v.setMagnitude(1);
    return v;
  }
  add(vector: Vec) {
    this.x += vector.x;
    this.y += vector.y;
  }
  subtract(vector: Vec) {
    this.x -= vector.x;
    this.y -= vector.y;
  }
  scale(coefficient: number) {
    this.x *= coefficient;
    this.y *= coefficient;
  }
  copy() {
    return new Vec(this.x, this.y);
  }
  static getDelta(start: Vec, end: Vec) {
    return new Vec(end.x - start.x, end.y - start.y);
  }
}
