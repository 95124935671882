import React, { ReactNode } from "react";
import { useReflow } from "../../../hooks/ui";
import { MapPoint } from "./Map.logic";
import MapUiLandscape from "./Map.ui.landscape";
import MapUiNormal from "./Map.ui.normal";
import MapUiPortrait from "./Map.ui.portrait";

export type Props = {
  onClose?: () => void;
  points: MapPoint[];
  onSelectPoint: (mapPoint: MapPoint) => void;
  selectedPoint: MapPoint;
  activeRegion?: string;
  imgSrc: string;
  imgRatio: number;
  imgWidth: number;
  preloadedImage: ReactNode;
  showCard: boolean;
  onCloseCard: () => void;
  onTeleport: (mapPoint: MapPoint) => void;
  previewMode: boolean;
  setPreviewMode: (value: boolean) => void;
  title: string;
  description: string;
};

const MapUi: React.FC<Props> = (props) => {
  const render = useReflow({
    normal: <MapUiNormal {...props} />,
    landscape: <MapUiLandscape {...props} />,
    portrait: <MapUiPortrait {...props} />,
  });

  return render;
};

export default MapUi;
