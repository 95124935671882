import { styled } from "styled-components";
import Column from "../../../../../../components-ui/atoms/Column";
import Row from "../../../../../../components-ui/atoms/Row";
import Typo from "../../../../../../components-ui/atoms/Typo";
import { PanelTestIds } from "../../../../../../constants/testIds";
import { useControls } from "../controlHooks";

const KeyBox = styled.div`
  background-color: ${(p) => p.theme.colorAbove0};
  border: 1px solid ${(p) => p.theme.colorAbove1};
  border-radius: ${(p) => p.theme.radiusTiny};
  padding: 2px 6px;
  min-width: 12px;
  text-align: center;
  margin-left: 2px;
`;

const KeysSchema: React.FC = () => {
  const { keys } = useControls();

  return (
    <Column
      gap={2}
      width="auto"
      style={{ flex: 1 }}
      justify="flex-start"
      testId={PanelTestIds.controls.default.common.keysSchema}
    >
      {keys.map(({ label, keys }, groupIndex) => (
        <Row justify="space-between" key={groupIndex}>
          <Typo.Body>{label}</Typo.Body>
          <Row justify="space-between" width="auto">
            {keys.map((controlKey, buttonIndex) => (
              <KeyBox key={buttonIndex}>
                <Typo.Body>{controlKey}</Typo.Body>
              </KeyBox>
            ))}
          </Row>
        </Row>
      ))}
    </Column>
  );
};

export default KeysSchema;
