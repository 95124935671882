import WelcomeDeveloperPageUi from "../../../features/utilityPages/WelcomeDeveloperPageUi";

const RootRoute: React.FC = () => {
  if (import.meta.env.MODE !== "development") {
    window.location.href = import.meta.env.VITE_JOURNEE_OFFICIAL_URL;
  }
  return import.meta.env.MODE === "development" ? (
    <WelcomeDeveloperPageUi />
  ) : null;
};

export default RootRoute;
