import { SvgProps } from "../../types/svg";

const ThumbsUpIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.75 8H3.25C2.56063 8 2 8.55937 2 9.25V15.75C2 16.4406 2.56063 17 3.25 17H4.75C5.44063 17 6 16.4406 6 15.75V9.25C6 8.55937 5.44063 8 4.75 8ZM5 15.75C5 15.8875 4.88781 16 4.75 16H3.25C3.11219 16 3 15.8875 3 15.75V9.25C3 9.1125 3.11219 9 3.25 9H4.75C4.88781 9 5 9.1125 5 9.25V15.75ZM18 8.92188C18 7.8625 17.1375 7 16.0781 7H12.875C13.2494 6.15438 13.4634 5.32875 13.4634 4.89594C13.4656 3.96375 12.7375 3 11.5281 3C10.24 3 9.94375 3.90969 9.68062 4.71281C8.81562 7.34687 7 7.775 7 8.5C7 8.78438 7.23438 9 7.5 9C7.62812 9 7.75625 8.95 7.85313 8.85313C9.49938 7.19563 9.9475 7.09969 10.6284 5.025C10.9156 4.15469 11.0062 4 11.5281 4C12.1737 4 12.4637 4.52406 12.4637 4.89594C12.4637 5.21125 12.1859 6.26937 11.6319 7.25469C11.5892 7.33069 11.5679 7.41503 11.5679 7.49937C11.5688 7.79687 11.8125 8 12.0688 8H16.0781C16.5875 8 17 8.4125 17 8.92188C17 9.40094 16.6225 9.80187 16.1412 9.83437C15.8768 9.85254 15.6753 10.0734 15.6753 10.3331C15.6753 10.7141 16.0522 10.735 16.0522 11.2903C16.0522 11.7322 15.7363 12.1122 15.3013 12.1931C15.1207 12.2269 14.8931 12.3937 14.8931 12.6853C14.8931 12.963 15.1045 13.0203 15.1045 13.4216C15.1045 14.3909 14.1198 14.1128 14.1198 14.7669C14.1198 14.8771 14.1569 14.9272 14.1569 15.0797C14.1562 15.5875 13.7437 16 13.2344 16H11.4969C8.93406 16 8.1125 13.9994 7.5 13.9994C7.22272 13.9994 7 14.2242 7 14.4981C6.97188 15.0094 9.01875 17 11.4969 17H13.2353C14.295 17 15.1572 16.1381 15.1572 15.0791C15.7369 14.7397 16.105 14.1103 16.105 13.4209C16.105 13.271 16.0864 13.1211 16.0508 12.975C16.6543 12.6463 17.0527 12.0072 17.0527 11.2894C17.0527 11.0638 17.0127 10.8422 16.9355 10.6337C17.5688 10.3187 18 9.66562 18 8.92188Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ThumbsUpIcon;
