import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Column from "../../components-ui/atoms/Column";
import Row from "../../components-ui/atoms/Row";
import { LOCALSTORAGE_RECENT_ENV } from "../../constants/configs";
import { PATH } from "../../constants/paths";
import {
  ReactionName,
  ReactionNames,
} from "../../features/actionBarPanel/lib/ReactionsTypes";
import useLocalStorage from "../../hooks/localStorage";
import EnvironmentFetcher, {
  useEnvironmentContext,
} from "../EnvironmentFetcher.core";
import { FromGameMessagesSchema } from "../gameConnection/messages/fromGameMessages";
import {
  panelNames,
  panelSubElementsNames,
  panelSubPagesNames,
} from "../layout/panelsTypes";
import { useEnvironmentPath } from "../routing/routingHooks";
import { parseRecentEnvString } from "./DevtoolsUtils";

const Wrapper = styled.div`
  padding: 10px 16px;
  font-size: 14px;
  width: 390px;
  overflow: scroll;
  box-sizing: border-box;

  button.small {
    inline-size: 70px;
  }
  button.large {
    inline-size: 70px;
  }
`;
const Label = styled.div`
  width: 100px;
  padding: 0px 5px 0px 5px;
`;
const ButtonS = styled.button`
  width: 60px;
  cursor: pointer;
`;
const ButtonM = styled.button`
  width: 86px;
  cursor: pointer;
`;
const ButtonL = styled.button`
  width: 180px;
  cursor: pointer;
`;
const SelectL = styled.select`
  width: 260px;
  cursor: pointer;
`;
const SelectM = styled.select`
  width: 137px;
  cursor: pointer;
`;
const InputS = styled.input`
  width: calc(75px);
`;
const InputM = styled.input`
  width: calc(137px);
`;

const PREFIX = "debug";

export type DebugAction =
  | "GO_TO_DEV"
  | "GO_TO_TALEBOOK"
  | "GO_TO_IFRAME"
  | "GO_TO_EXPERIENCE"
  | "GO_TO_ENV"
  | "LOGOUT"
  | "TRIGGER_EXPERIENCE_AUDIO"
  | "TRIGGER_BUSINESSCARD_MESSAGE"
  | "TOGGLE_ACTIVITY_CHECK"
  | "TRIGGER_LEVEL_LOAD"
  | "OPEN_PANEL"
  | "CLOSE_PANEL"
  | "SET_ACTIVE_REGION"
  | "TRIGGER_CHAT_NOTIFICATION"
  | "MEDIA_CAPTURE_ACTION"
  | "SEND_REACTION"
  | "SEND_QUEST_STATUS";

const load = () => <>...</>;
const notFound = () => <>not found</>;
const error = () => <>error</>;

/** The UI to trigger the debug actions in the debug popover. */
const DevtoolsSender: React.FC = () => {
  const { envSlug } = useEnvironmentPath();
  const navigate = useNavigate();

  const { environment } = useEnvironmentContext();
  const [extraValues, setExtraValues] = useState({
    infocard: "",
    popup: "",
    hint: "",
    fullscreenVideo: "",
    poll: "",
    mediaCapture: ["image", "start"],
  });
  const [recentUrls] = useLocalStorage<string[]>(LOCALSTORAGE_RECENT_ENV, []);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedReaction, setSelectedReaction] = useState<ReactionName>(
    ReactionNames[0]
  );
  const [selectedReactionLevel, setSelectedReactionLevel] = useState<number>(0);
  const [loadTime, setLoadTime] = useState<number>(1000);
  const [chatNotification, setChatNotification] = useState<string>("");

  // These panels are filtered from the ui panels default behavior.
  // This is to be able to control differently or because the env doesn't have the feature enabled.
  // e.g. Web3
  const filteredPanels = ["textChatPreview"];
  if (!environment?.enableWeb3) {
    filteredPanels.push("settings/walletconnect");
    filteredPanels.push("walletConnect");
  }

  if (!environment?.voiceVideoOn) {
    filteredPanels.push("settings/video");
  }

  const panelsTarget = [
    ...panelNames,
    ...panelSubPagesNames,
    ...panelSubElementsNames,
  ]
    .sort((a: string, b: string) => a.localeCompare(b))
    .filter((it) => !filteredPanels.includes(it));

  const send = (type: DebugAction, extra1?: string) => {
    let extra2 = "";

    // Auto include relevant extra2 data for panel open/closing cases.
    if (type === "OPEN_PANEL" || type === "CLOSE_PANEL") {
      const extraValue = extraValues[extra1 as keyof typeof extraValues];
      if (extraValue) extra2 = String(extraValue);
    }

    window.opener.postMessage(
      PREFIX + ";" + type + ";" + extra1 + ";" + extra2
    );
  };

  const extraInputs = {
    infocard: (
      <SelectM
        defaultValue=""
        value={extraValues.infocard}
        onChange={(e) =>
          setExtraValues({ ...extraValues, infocard: e.target.value })
        }
      >
        <option key="" value="">
          -
        </option>
        {environment?.infoCards?.map((it) => (
          <option key={it.infoCardId} value={it.infoCardId ?? ""}>
            {it.infoCardId}
          </option>
        ))}
      </SelectM>
    ),
    popup: (
      <SelectM
        defaultValue=""
        value={extraValues.popup}
        onChange={(e) =>
          setExtraValues({ ...extraValues, popup: e.target.value })
        }
      >
        <option key="" value="">
          -
        </option>
        {environment?.actionPanels?.map((it) => (
          <option key={it.actionPanelId} value={it.actionPanelId ?? ""}>
            {it.actionPanelId}
          </option>
        ))}
      </SelectM>
    ),
    hint: (
      <SelectM
        defaultValue=""
        value={extraValues.hint}
        onChange={(e) =>
          setExtraValues({ ...extraValues, hint: e.target.value })
        }
      >
        <option key="" value="">
          -
        </option>
        {environment?.hints?.map((hint) => (
          <option key={hint.hintId} value={hint.hintId ?? ""}>
            {hint.hintId}
          </option>
        ))}
      </SelectM>
    ),
    fullscreenVideo: (
      <SelectM
        defaultValue=""
        value={extraValues.fullscreenVideo}
        onChange={(e) =>
          setExtraValues({ ...extraValues, fullscreenVideo: e.target.value })
        }
      >
        <option key="" value="">
          -
        </option>
        {environment?.levelVideos?.map((video) => (
          <option key={video.levelId} value={video.levelId ?? ""}>
            {video.levelId}
          </option>
        ))}
      </SelectM>
    ),
    poll: (
      <SelectM
        defaultValue=""
        value={extraValues.poll}
        onChange={(e) =>
          setExtraValues({ ...extraValues, poll: e.target.value })
        }
      >
        <option key="" value="">
          -
        </option>
        {environment?.polls?.map((poll) => (
          <option key={poll.pollId} value={poll.pollId ?? ""}>
            {poll.pollId}
          </option>
        ))}
      </SelectM>
    ),
  } as const;

  return (
    <Wrapper>
      <Column width="280px" align="flex-start" gap={2}>
        <div>Navigate to recent environment</div>

        <Row>
          <SelectL
            onChange={(e) => {
              const recentEnv = e.target.value;
              if (!recentEnv) return;
              send("GO_TO_ENV", recentEnv);
              const { envSlug, orgSlug } = parseRecentEnvString(recentEnv);
              navigate(
                generatePath(PATH.devtoolsEnvironment, {
                  orgSlug: orgSlug ?? null,
                  envSlug: envSlug ?? null,
                })
              );
              navigate(0);
            }}
          >
            <option key="" value="">
              -
            </option>
            {recentUrls.map((it) => (
              <option key={it} value={it ?? ""}>
                {it}
              </option>
            ))}
          </SelectL>
        </Row>

        {!envSlug ? (
          <div>Navigate to an environment for more controls.</div>
        ) : (
          <EnvironmentFetcher
            loadingPage={load}
            notFoundPage={notFound}
            errorPage={error}
          >
            <Row flexWrap>
              <ButtonM onClick={() => send("GO_TO_EXPERIENCE")}>MAIN</ButtonM>
              <ButtonM onClick={() => send("GO_TO_DEV")}>DEV</ButtonM>
              <ButtonM onClick={() => send("GO_TO_TALEBOOK")}>TALES</ButtonM>
              <ButtonM onClick={() => send("GO_TO_IFRAME")}>IFRAME</ButtonM>
            </Row>
            <div>ui panels</div>

            {panelsTarget.map((it) => (
              <Row key={it}>
                <ButtonS onClick={() => send("OPEN_PANEL", it)}>open</ButtonS>
                <ButtonS onClick={() => send("CLOSE_PANEL", it)}>close</ButtonS>
                <Label>{it}</Label>
                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/*@ts-ignore*/}
                {extraInputs[it] ? extraInputs[it] : null}
              </Row>
            ))}

            <div>others</div>
            <ButtonL onClick={() => send("LOGOUT")}>logout</ButtonL>
            <Row>
              <ButtonL
                onClick={() => {
                  if (!chatNotification) return;
                  send("TRIGGER_CHAT_NOTIFICATION", chatNotification);
                }}
              >
                Trigger Chat Notification
              </ButtonL>
              <InputM
                id="textNotification"
                type="text"
                placeholder="Write your message"
                onChange={(e) => setChatNotification(e.target.value)}
              />
            </Row>

            <ButtonL onClick={() => send("TRIGGER_EXPERIENCE_AUDIO")}>
              trigger experience audio
            </ButtonL>
            <ButtonL onClick={() => send("TRIGGER_BUSINESSCARD_MESSAGE")}>
              visitor profile done
            </ButtonL>
            <ButtonL onClick={() => send("TOGGLE_ACTIVITY_CHECK")}>
              toggle inactivity check
            </ButtonL>
            <div>Set Active Region</div>
            <Row>
              <SelectM
                defaultValue=""
                value={selectedRegion}
                onChange={(e) => setSelectedRegion(e.target.value)}
              >
                <option key="" value="">
                  -
                </option>
                {environment?.mapPoints?.map((mapPoint) => (
                  <option
                    key={mapPoint.regionId}
                    value={mapPoint.regionId ?? ""}
                  >
                    {mapPoint.regionId}
                  </option>
                ))}
                <option key={"random"} value={"random"}>
                  Random Not Mapped region
                </option>
              </SelectM>
              <ButtonS
                onClick={() => {
                  send("SET_ACTIVE_REGION", selectedRegion);
                }}
              >
                Set
              </ButtonS>
            </Row>

            <div>Level Loading</div>
            <Column>
              <Row>
                <InputS
                  id="loadTime"
                  type="text"
                  defaultValue={loadTime}
                  onChange={(e) => {
                    if (e.target.value.match(/^[0-9]+$/) != null) {
                      setLoadTime(parseInt(e.target.value));
                    }
                  }}
                />
                <Label>Load Time (ms)</Label>
              </Row>
              <Row>
                <SelectM
                  defaultValue=""
                  value={selectedLevel}
                  onChange={(e) => setSelectedLevel(e.target.value)}
                >
                  <option key="" value="">
                    -
                  </option>
                  {environment?.levelVideos?.map((video) => (
                    <option
                      key={video.levelId + "L"}
                      value={video.levelId ?? ""}
                    >
                      {video.levelId}
                    </option>
                  ))}
                </SelectM>
                <ButtonS
                  onClick={() => {
                    send(
                      "TRIGGER_LEVEL_LOAD",
                      JSON.stringify([selectedLevel, loadTime])
                    );
                  }}
                >
                  Load
                </ButtonS>
              </Row>

              <div>Video Capture</div>
              <Row>
                <SelectM
                  defaultValue=""
                  value={extraValues.mediaCapture[0]}
                  onChange={(e) =>
                    setExtraValues({
                      ...extraValues,
                      mediaCapture: [
                        e.target.value,
                        extraValues.mediaCapture[1],
                      ],
                    })
                  }
                >
                  <option key="image" value="image">
                    image
                  </option>
                  <option key="video" value="video">
                    video
                  </option>
                </SelectM>
                <SelectM
                  defaultValue=""
                  value={extraValues.mediaCapture[1]}
                  onChange={(e) =>
                    setExtraValues({
                      ...extraValues,
                      mediaCapture: [
                        extraValues.mediaCapture[0],
                        e.target.value,
                      ],
                    })
                  }
                >
                  <option key="start" value="start">
                    start
                  </option>
                  <option key="complete" value="complete">
                    complete
                  </option>
                  <option key="cancel" value="cancel">
                    cancel
                  </option>
                </SelectM>
                <ButtonS
                  onClick={() => {
                    send(
                      "MEDIA_CAPTURE_ACTION",
                      JSON.stringify(extraValues.mediaCapture)
                    );
                  }}
                >
                  Load
                </ButtonS>
              </Row>

              <div>Reaction</div>
              <Row>
                <SelectM
                  defaultValue=""
                  value={selectedReaction}
                  onChange={(e) =>
                    setSelectedReaction(e.target.value as ReactionName)
                  }
                >
                  {ReactionNames.map((reactionName: ReactionName) => (
                    <option key={reactionName + "L"} value={reactionName}>
                      {reactionName}
                    </option>
                  ))}
                </SelectM>

                <SelectM
                  defaultValue=""
                  value={selectedReactionLevel}
                  onChange={(e) => {
                    setSelectedReactionLevel(parseInt(e.target.value));
                  }}
                >
                  {[0, 1, 2].map((reactionLevel) => (
                    <option key={reactionLevel + "L"} value={reactionLevel}>
                      {reactionLevel}
                    </option>
                  ))}
                </SelectM>
                <ButtonS
                  onClick={() => {
                    send(
                      "SEND_REACTION",
                      JSON.stringify([selectedReaction, selectedReactionLevel])
                    );
                  }}
                >
                  send
                </ButtonS>
              </Row>

              <div>Quests</div>
              <Row>
                <ButtonL
                  onClick={() => {
                    const quests = environment.quests;
                    const questsProgress: FromGameMessagesSchema["QuestsInfo"]["quests"] =
                      quests.map((quest) => {
                        const required = Math.floor(Math.random() * 100);
                        const collected = Math.floor(
                          Math.random() * required + 1
                        );
                        let state: "Active" | "Completed" | "NotStarted" =
                          "NotStarted";

                        switch (true) {
                          case collected === 0:
                            state = "NotStarted";
                            break;
                          case collected > 0 && required > collected:
                            state = "Active";
                            break;
                          case collected === required:
                            state = "Completed";
                            break;
                          default:
                            break;
                        }
                        return {
                          slug: quest.id,
                          currencyNeededAmount: required,
                          currencyCollectedAmount: collected,
                          state,
                        };
                      });
                    send("SEND_QUEST_STATUS", JSON.stringify([questsProgress]));
                  }}
                >
                  Send quest status
                </ButtonL>
              </Row>
            </Column>
          </EnvironmentFetcher>
        )}
      </Column>
    </Wrapper>
  );
};

export default DevtoolsSender;
