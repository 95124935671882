import styled, { useTheme } from "styled-components";
import tinycolor from "tinycolor2";
import Column from "../../../../components-ui/atoms/Column";
import Row from "../../../../components-ui/atoms/Row";
import Typo from "../../../../components-ui/atoms/Typo";
import { useText } from "../../../../core/i18n/i18nHooks";
import { steps } from "../../../../style/theme";
import NoNameAvatarText from "../NoNameAvatarText";

const Outer = styled.div<{
  $size: number;
  $hidden: boolean;
  $isSpeaking: boolean;
  $darkText: boolean;
  $isLocal: boolean;
}>`
  opacity: ${(p) => (p.$hidden ? 0 : 1)};
  transition:
    opacity 0.3s ease-in-out,
    color 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 4px;
  max-height: 30px;
  height: 30px;
  width: ${(p) => p.$size}px;
  text-overflow: ellipsis;
  * {
    user-select: none;
    transition: color 0.1s ease-in-out;
  }

  ${(p) => !p.$isLocal && "cursor: pointer;"}
`;

const SIZE = "5px";

export const Dot = styled.span`
  position: relative;
  width: ${SIZE};
  height: ${SIZE};
  border-radius: 5px;

  background-color: ${(p) => p.theme.colorGradient.purpleBlue.start};
  color: ${(p) => p.theme.colorGradient.purpleBlue.start};
  @keyframes dot-flashing {
    0% {
      background-color: ${(p) => p.theme.colorGradient.purpleBlue.start};
    }
    50%,
    100% {
      background-color: ${(p) =>
        tinycolor(p.theme.colorGradient.purpleBlue.start)
          .setAlpha(0.2)
          .toRgbString()};
    }
  }
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: -7px;
    inline-size: ${SIZE};
    inline-size: ${SIZE};
    border-radius: 5px;
    background-color: ${(p) => p.theme.colorGradient.purpleBlue.start};
    color: ${(p) => p.theme.colorGradient.purpleBlue.start};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 7px;
    inline-size: ${SIZE};
    inline-size: ${SIZE};
    border-radius: 5px;
    background-color: ${(p) => p.theme.colorGradient.purpleBlue.start};
    color: ${(p) => p.theme.colorGradient.purpleBlue.start};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
`;

export const ThreeDots = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${steps.font.f20.lineHight};
`;

type Props = {
  size?: number;
  name: string | null;
  isMuted?: boolean;
  isLocal?: boolean;
  isSpeaking?: boolean;
  hideTitle?: boolean;
  darkText?: boolean;
  isHovered?: boolean;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  onClick?: () => void;
};

const TitleBreakoutPlayerList: React.FC<Props> = ({
  size = 92,
  name,
  isMuted = false,
  isLocal = false,
  isSpeaking = false,
  hideTitle = false,
  darkText = false,
  isHovered = false,
  onMouseEnter,
  onMouseOut,
  onClick,
}) => {
  const theme = useTheme();
  const t = useText();

  let subtitle: React.ReactNode | string;
  let subTitleColor = theme.colorAbove2;
  switch (true) {
    case isLocal:
      subtitle = t("player_you");
      break;
    case isHovered:
      subtitle = t("player_open_profile");
      subTitleColor = theme.colorAbove4;
      break;
    case isMuted:
      subtitle = t("player_muted");
      break;
    case isSpeaking:
      subtitle = t("player_speaking");
      subTitleColor = theme.colorGradient.purpleBlue.end;
      break;
    default:
      subtitle = "";
  }

  return (
    <Outer
      $isLocal={isLocal}
      $size={size}
      $hidden={hideTitle}
      $darkText={darkText}
      $isSpeaking={isSpeaking}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      onClick={onClick}
    >
      <Column align="center">
        {!name ? (
          <NoNameAvatarText />
        ) : (
          <Typo.Title
            fontFamily="Manrope"
            size={steps.font.f20.size}
            noWrap
            weight={600}
            lineHeight={steps.font.f20.lineHight}
            align="center"
            style={{
              width: size,
              display: "block",
              textOverflow: "ellipsis",
              overflow: "hidden",
              pointerEvents: "none",
            }}
          >
            {name}
          </Typo.Title>
        )}
        {name && (
          <Row
            justify="center"
            onMouseEnter={onMouseEnter}
            onMouseOut={onMouseOut}
            style={{ pointerEvents: "none" }}
          >
            <Typo.Note align="center" color={subTitleColor}>
              &nbsp;{subtitle}&nbsp;
            </Typo.Note>
            {isHovered && !isLocal && (
              <Typo.Note align="center" color={theme.colorAbove2}>
                {">"}
              </Typo.Note>
            )}
          </Row>
        )}
      </Column>
    </Outer>
  );
};

export default TitleBreakoutPlayerList;
