import { ReactNode } from "react";
import { useTheme } from "styled-components";
import Button from "../../components-ui/atoms/Button";
import Column from "../../components-ui/atoms/Column";
import Icon from "../../components-ui/atoms/Icon";
import Space from "../../components-ui/atoms/Space";
import Typo from "../../components-ui/atoms/Typo";
import { LoginPageTestIds } from "../../constants/testIds";
import { grayscaleInvert } from "../../lib/color";
import { LoginPageLayout } from "./LoginPageUi";

export type PropsLoginPageUi = {
  layout: LoginPageLayout;
  title?: string;

  descriptionHeader?: string;
  description?: string;

  isSecondaryButtonVisible?: boolean;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
};

const LoginPageUiLandingText: React.FC<PropsLoginPageUi> = ({
  layout,
  title,

  descriptionHeader,
  description,

  isSecondaryButtonVisible,
  secondaryButtonLabel,
  onSecondaryButtonClick,
}) => {
  const theme = useTheme();

  let header: ReactNode | undefined;
  const inverted = theme.isLoginColorInverted;

  const colorAbove3 = inverted
    ? grayscaleInvert(theme.colorAbove3)
    : theme.colorAbove3;
  const colorAbove5 = inverted
    ? grayscaleInvert(theme.colorAbove5)
    : theme.colorAbove5;

  switch (descriptionHeader) {
    case "warning":
      header = (
        <Icon.Warning
          testId={LoginPageTestIds.warningIcon}
          color={colorAbove3}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Column
      width="min(100%, 550px)"
      justify="flex-end"
      align="center"
      textAlign="center"
    >
      <Typo.Title
        style={{ maxWidth: "505px" }}
        color={colorAbove5}
        testId={LoginPageTestIds.title}
        size={layout.titleFont.size}
        lineHeight={layout.titleFont.lineHight}
      >
        {title}
      </Typo.Title>
      <Space h={layout.titleBottomMargin} />
      {Boolean(header) && (
        <>
          {header}
          <Space h={3} />
        </>
      )}
      <Typo.Body
        testId={LoginPageTestIds.description}
        style={{ maxWidth: "480px" }}
        size={layout.bodyFont.size}
        lineHeight={layout.bodyFont.lineHight}
        color={colorAbove5}
      >
        {description}
      </Typo.Body>
      {isSecondaryButtonVisible && (
        <>
          <Space h={4} />
          <Button.Bright
            testId={LoginPageTestIds.secondaryBtn}
            onClick={onSecondaryButtonClick}
          >
            {secondaryButtonLabel}
          </Button.Bright>
        </>
      )}
    </Column>
  );
};

export default LoginPageUiLandingText;
