import { SvgProps } from "../../../types/svg";

const SpinningAnimatedIcon: React.FC<SvgProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 17.25C6.13401 17.25 2.75 14.1346 2.75 10C2.75 5.86543 6.13401 2.75 10 2.75"
      stroke="url(#paint0_linear_1917_584)"
      strokeWidth="1.5"
    >
      <animateTransform
        from="0 10 10"
        to="-360 10 10"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1000ms"
      />
    </path>
    <path
      d="M10 17.25C13.866 17.25 17.25 14.1408 17.25 10C17.25 5.85924 13.866 2.75 10 2.75"
      stroke="url(#paint1_linear_1917_584)"
      strokeWidth="1.5"
    >
      <animateTransform
        from="0 10 10"
        to="-360 10 10"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1000ms"
      />
    </path>
    <defs>
      <linearGradient
        id="paint0_linear_1917_584"
        x1="10"
        y1="3"
        x2="10"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" stopOpacity="0.5" />
        <stop offset="1" stopColor="currentColor" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1917_584"
        x1="10"
        y1="3"
        x2="10"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" stopOpacity="0.5" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default SpinningAnimatedIcon;
