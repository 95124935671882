import cuid from "cuid";
import {
  Config,
  adjectives,
  animals,
  colors,
  uniqueNamesGenerator,
} from "unique-names-generator";

export const customConfig: Config = {
  dictionaries: [adjectives, colors, animals],
  separator: "-",
  length: 3,
};

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
export function randomStringGenerator(length = 10): string {
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function randomElementFromArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export const randomColorGenerator = (): string => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export function randomSaneNameGenerator(): string {
  return uniqueNamesGenerator(customConfig);
}

export function randomBooleanGenerator(): boolean {
  return Math.random() >= 0.5;
}

export function randomUrlGenerator(): string {
  return `https://${randomStringGenerator()}.com`;
}

export function randomIntGenerator(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomSentenceGenerator(length = 15): string {
  const words = randomIntGenerator(1, length);
  let sentence = "";
  for (let i = 0; i < words; i++) {
    sentence += `${uniqueNamesGenerator(customConfig)} `;
  }
  return sentence.trim();
}

// We really want unique IDs for our mocks. We use a Set to keep track of the IDs we already used.
const ID_POOL = new Set<number>();
export function randomIntegerIdGenerator(): number {
  let id = randomIntGenerator(1, 1000);
  while (ID_POOL.has(id)) {
    id = randomIntGenerator(1, 1000);
  }
  ID_POOL.add(id);
  return id;
}

export function randomStringIdGenerator(): string {
  return cuid();
}

export function randomDateGenerator(): string {
  return new Date(
    Date.now() - randomIntGenerator(0, 1000 * 60 * 60 * 24 * 365)
  ).toISOString();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function randomEnumGenerator<T>(anEnum: T | any): T[keyof T] {
  const enumValues = Object.keys(anEnum)
    .map((n) => Number.parseInt(n))
    .filter((n) => !Number.isNaN(n)) as unknown as T[keyof T][];
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  return enumValues[randomIndex];
}
