import { useCallback, useEffect, useState } from "react";
import { SocketDisconnectPageProps } from "../features/utilityPages/SocketDisconnectPageUi";
import { useStore } from "../store/store";
import { StreamingStatus } from "./gameConnection/gameConnectionTypes";
import { WSStatus } from "./gameConnection/websocket/websocketTypes";
import { useText } from "./i18n/i18nHooks";

type Props = {
  displayPage: React.ComponentType<SocketDisconnectPageProps>;
  children: React.ReactNode;
};

const EnvironmentDisconnectHandler: React.FC<Props> = ({
  displayPage: DisplayPage,
  children,
}) => {
  const t = useText();
  const openPanel = useStore((s) => s.layout.openPanel);
  const [socketDisconnectTitle, setSocketDisconnectTitle] = useState<string>(
    t("socket_disconnect_title_default")
  );
  const [socketDisconnectDescription, setSocketDisconnectDescription] =
    useState<string>(t("socket_disconnect_description_default"));

  const [closeWs, setCloseWs] = useState<boolean>(false);

  const wsStatus = useStore((s) => s.gameConnection.wsStatus);
  const streamingStatus = useStore((s) => s.gameConnection.streamingStatus);
  const gameIsReady = useStore((s) => s.gameConnection.gameIsReady);
  const isExperiencePhase = useStore((s) => s.userFlow.isExperiencePhase());
  const cleanupHooks = useStore((s) => s.userFlow.cleanupHooks);
  const __unsafelySetCurrentStep = useStore(
    (s) => s.userFlow.__unsafelySetCurrentStep
  );
  const errorMultiplexer = useCallback(() => {
    switch (true) {
      case streamingStatus === StreamingStatus.ERROR:
        __unsafelySetCurrentStep("ended");
        setCloseWs(true);
        setSocketDisconnectDescription(
          t("socket_disconnect_description_error")
        );
        setSocketDisconnectTitle(t("socket_disconnect_title_error"));
        break;
      case wsStatus === WSStatus.CLOSED && gameIsReady && isExperiencePhase:
        __unsafelySetCurrentStep("ended");
        setCloseWs(true);

        break;
    }
  }, [
    streamingStatus,
    __unsafelySetCurrentStep,
    t,
    wsStatus,
    gameIsReady,
    isExperiencePhase,
  ]);

  useEffect(() => {
    errorMultiplexer();
  }, [errorMultiplexer]);

  if (closeWs) {
    Object.keys(cleanupHooks).forEach((hook) => {
      cleanupHooks[hook]();
    });
    return (
      <DisplayPage
        title={socketDisconnectTitle}
        description={socketDisconnectDescription}
        onReportButtonClick={() => openPanel("report")}
      >
        {children}
      </DisplayPage>
    );
  }

  return children;
};

export default EnvironmentDisconnectHandler;
