/* eslint-disable @typescript-eslint/no-explicit-any */
import { log } from "../../../lib/logger";
import { VideoConferenceParticipant } from "../VideoConferenceAdapter";

export function getParticipantData(
  data: any,
  isLocal: boolean
): VideoConferenceParticipant {
  const userId = getExternalId(data.connection.data);
  return {
    id: data.connection.id,
    userId,
    name: "",
    isLocal,
    isAudioOn: false,
    isVideoHidden: false,
    lastActive: Date.now() - 1000 * 30,
    isSpeaking: false,
    isPublishing: false,
    isVideoOn: false,
    streams: [],
  };
}
const isSpeakingMap: Record<string, { timestamp: number; speaking: boolean }> =
  {};

export function onAudioLevelUpdated(
  userId: string,
  event: any,
  callback: (userId: string, isSpeaking: boolean) => void
) {
  const now = Date.now();
  const activity = isSpeakingMap[userId];
  if (event.audioLevel > 0.1) {
    if (!activity) {
      isSpeakingMap[userId] = { timestamp: now, speaking: false };
    } else if (activity.speaking) {
      isSpeakingMap[userId].timestamp = now;
    } else if (now - activity.timestamp > 50) {
      // detected audio activity for more than 1s
      // for the first time.
      isSpeakingMap[userId].speaking = true;
      callback(userId, true);
    }
  } else if (activity && now - activity.timestamp > 700) {
    // detected low audio activity for more than 3s
    if (activity.speaking) {
      callback(userId, false);
    }
    delete isSpeakingMap[userId];
  }
}

export function getExternalId(data: string) {
  try {
    const parsedData = JSON.parse(data);
    return parsedData.externalId;
  } catch (error) {
    return null;
  }
}

export const mediaPermissionsChecker = async () => {
  const permissions = {
    camera: false,
    microphone: false,
  };

  if (!navigator || !navigator.permissions || !navigator.permissions.query) {
    log("VOICE/VIDEO", "This browser doesn't support the Permissions API");
    return permissions;
  }
  const promises: Promise<PermissionStatus>[] = [];
  // PermissionName is still waiting to be updated:
  // https://github.com/microsoft/TypeScript/issues/33923
  promises.push(
    navigator.permissions.query({ name: "camera" as PermissionName })
  );
  promises.push(
    navigator.permissions.query({ name: "microphone" as PermissionName })
  );
  const result = await Promise.allSettled(promises);
  for (const res of result) {
    if (res.status === "fulfilled") {
      if (res.value.name === "video_capture") {
        permissions.camera = res.value.state === "granted";
      } else if (res.value.name === "audio_capture") {
        permissions.microphone = res.value.state === "granted";
      }
    }
  }
  return permissions;
};
