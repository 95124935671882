import { useStore } from "../../../../store/store";
import { SettingName } from "../subpages/home/SettingsHomePage.ui";

const useDisabledSettings = () => {
  const videoConferenceEnabled = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );

  const disabled: SettingName[] = [];
  if (!videoConferenceEnabled) disabled.push("mic");

  return { disabled };
};

export default useDisabledSettings;
