import Column from "../../../../../../components-ui/atoms/Column";
import Space from "../../../../../../components-ui/atoms/Space";
import ButtonsSchema from "./ButtonsSchema";
import GesturesSchema from "./GesturesSchema";

const ControlsTouchUiPortrait: React.FC = () => {
  return (
    <>
      <Space h={4} />
      <Column justify="space-between" align="flex-start" gap={6}>
        <Column gap={2}>
          <ButtonsSchema />
        </Column>
        <Column gap={3}>
          <GesturesSchema />
        </Column>
      </Column>
    </>
  );
};

export default ControlsTouchUiPortrait;
