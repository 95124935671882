import { useEffect, useMemo } from "react";
import { useStore } from "../../store/store";
import { VideoConferenceState } from "../videoConference/VideoConferenceAdapter";

const useAudio = (videoElement: HTMLVideoElement | null): HTMLAudioElement => {
  const { muted } = useStore((s) => s.userMedia);
  const videoConferenceStatus = useStore((s) => s.videoConference.state);

  const streamAudio = useMemo(() => {
    const audio = new Audio();
    // Not necessary, but makes sense to be explicit here, especially in the
    // light of strange Safari behaviors of automatically setting .muted to true,
    // if a muted video autoplayed before (see comments in the startVideo() function)
    audio.muted = false;
    audio.defaultMuted = false;
    audio.volume = 1;
    audio.autoplay = true;
    audio.id = "streamingAudio";
    return audio;
  }, []);

  useEffect(() => {
    if (!videoElement) return;
    if (!streamAudio) return;
    streamAudio.muted = muted;
  }, [videoElement, muted, streamAudio]);

  useEffect(() => {
    if (!streamAudio) return;
    // A user interaction will trigger this event starting the audio
    const playExperienceAudioHandler = () => {
      if (streamAudio.paused) streamAudio.play();
    };
    window.addEventListener(
      "play-experience-audio",
      playExperienceAudioHandler
    );
    return () => {
      window.removeEventListener(
        "play-experience-audio",
        playExperienceAudioHandler
      );
    };
  }, [streamAudio]);

  useEffect(() => {
    if (videoConferenceStatus === VideoConferenceState.JOINED) {
      // Fade out the volume so the transition is not too abrupt
      const fadeout = setInterval(() => {
        if (streamAudio.volume > 0.4) {
          streamAudio.volume -= 0.05;
        } else {
          clearInterval(fadeout);
        }
      }, 100);
    }
  }, [videoConferenceStatus, streamAudio]);

  return streamAudio;
};

export default useAudio;
