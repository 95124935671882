import { SvgProps } from "../../types/svg";

const TearIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_222_50)">
      <path
        d="M6.73438 10.1281C6.86562 9.95625 7.13438 9.95625 7.26562 10.1281C7.31563 10.1906 7.37187 10.2562 7.42812 10.325C8.01875 11.0469 9 12.2406 9 13.1656C9 14.1812 8.10312 15 7 15C5.89688 15 5 14.1812 5 13.1656C5 12.2531 5.9625 11.075 6.55 10.3531C6.61563 10.2719 6.67813 10.1969 6.73438 10.1281ZM6.49375 12.075C6.1375 12.6125 6 12.9812 6 13.1656C6 13.5469 6.36563 14 7 14C7.63437 14 8 13.5469 8 13.1656C8 12.9812 7.8625 12.6094 7.50625 12.075C7.34687 11.8344 7.17188 11.6031 7 11.3875C6.83125 11.6031 6.65625 11.8344 6.49375 12.075ZM13.6156 13.6594C13.8031 13.8625 13.7937 14.1781 13.5906 14.3656C13.3875 14.5531 13.0687 14.5437 12.8844 14.3406C12.1 13.4937 11.0906 13 9.97188 13C9.725 13 9.47188 12.775 9.47188 12.5C9.47188 12.225 9.725 12 9.97188 12C11.4063 12 12.675 12.6406 13.6156 13.6594ZM12.4875 7.75C12.9031 7.75 13.2375 8.08437 13.2375 8.5C13.2375 8.91563 12.9031 9.25 12.4875 9.25C12.075 9.25 11.7375 8.91563 11.7375 8.5C11.7375 8.08437 12.075 7.75 12.4875 7.75ZM7.4875 9.25C7.075 9.25 6.7375 8.91563 6.7375 8.5C6.7375 8.08437 7.075 7.75 7.4875 7.75C7.90313 7.75 8.2375 8.08437 8.2375 8.5C8.2375 8.91563 7.90313 9.25 7.4875 9.25ZM10 18C5.58125 18 2 14.4187 2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10C18 14.4187 14.4187 18 10 18ZM17 10C17 6.13438 13.8656 3 10 3C6.13438 3 3 6.13438 3 10C3 13.8656 6.13438 17 10 17C13.8656 17 17 13.8656 17 10Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
    <defs>
      <clipPath id="clip0_222_50">
        <rect
          width="16"
          height="16"
          fill="currentColor"
          className="fill-color"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default TearIcon;
