import { useEffect, useState } from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import styled, { css } from "styled-components";
import Icon from "../../../../components-ui/atoms/Icon";
import Row from "../../../../components-ui/atoms/Row";
import Tooltip from "../../../../components-ui/molecules/Tooltip";
import { ReactionName } from "../../../actionBarPanel/lib/ReactionsTypes";
import EscalatingButton from "../component/EscalatingButton";
import PresentationBarItem from "../component/PresentationBarItem";
import { ReactionItem } from "../component/ReactionsBar";

export const Wrapper = styled.div<{
  $isOpen: boolean;
  $overflow: React.CSSProperties["overflow"];
  $nrOfChildren: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;

  border-radius: ${(p) => p.theme.radiusFull};
  width: 0px;
  height: calc(${(p) => p.theme.elements.actionButton.size});

  ${(p) =>
    p.$isOpen &&
    css`
      width: calc(${p.$nrOfChildren} * ${p.theme.elements.actionButton.size});
      padding: 2px;
      background-color: ${p.theme.colorBelow0};
      backdrop-filter: blur(${p.theme.blurLight});
    `}
  transform-origin: center;

  &:hover {
    background-color: ${(p) => p.theme.colorBelow1};
  }

  overflow: ${(p) => p.$overflow};

  transition:
    background-color 0.3s,
    width 0.5s,
    height 0.3s;

  pointer-events: all;
`;

const Divider = styled.div`
  width: 2px;
  height: 16px;
  background-color: ${(p) => p.theme.colorAbove1};
`;

const EmojisContainer = styled.div<{ $isOpen: boolean }>`
  pointer-events: all;
  display: flex;
  flex-direction: row-reverse;
  opacity: 0;
  ${(p) =>
    p.$isOpen &&
    css`
      opacity: 1;
    `};
  transition: opacity 0.3s;
`;

type Props = {
  items: ReactionItem[];
  handleReaction: (reaction: ReactionName, stage: number) => void;
  isOpen: boolean;
  micMuted: boolean;
  isVideoConferenceInitialized: boolean;
  toggleLocalAudio: () => void;
  webcamMuted: boolean;
  toggleVideo: () => void;
  activeScreenShare: boolean;
  toggleScreenShare: () => void;
  screenshareDisabled: boolean;
};

const AttendeeBarUiNormal: React.FC<Props> = ({
  items,
  handleReaction,
  isOpen,
  micMuted,
  isVideoConferenceInitialized,
  toggleLocalAudio,
  webcamMuted,
  toggleVideo,
  activeScreenShare,
  toggleScreenShare,
  screenshareDisabled,
}) => {
  const [overflow, setOverflow] = useState("hidden");

  useEffect(() => {
    if (!isOpen) {
      setOverflow("hidden");
    } else {
      setTimeout(() => {
        setOverflow("visible");
      }, 300);
    }
  }, [isOpen]);

  return (
    <Wrapper
      $isOpen={isOpen}
      $overflow={overflow}
      $nrOfChildren={3 + items.length}
    >
      <Row justify="center">
        <PresentationBarItem
          active={!micMuted}
          disabled={!isVideoConferenceInitialized}
          error={false}
          onIcon={<Icon.PurpleMicrophone inheritColor size="20px" />}
          offIcon={<Icon.MicrophoneOffThin inheritColor size="20px" />}
          onClick={toggleLocalAudio}
        />
        <PresentationBarItem
          active={!webcamMuted}
          disabled={!isVideoConferenceInitialized}
          error={false}
          onIcon={<Icon.PurpleVideo inheritColor size="20px" />}
          offIcon={<Icon.VideoOff inheritColor size="20px" />}
          onClick={toggleVideo}
        />
        <PresentationBarItem
          active={activeScreenShare}
          disabled={screenshareDisabled}
          error={false}
          onIcon={<Icon.PurpleScreenshare size="20px" />}
          offIcon={<Icon.ScreenShare inheritColor size="20px" />}
          onClick={toggleScreenShare}
        />
        {items.length > 0 && <Divider />}
        <EmojisContainer $isOpen={isOpen}>
          <RadixTooltip.Provider delayDuration={300} skipDelayDuration={1000}>
            {items.map((item) => (
              <Tooltip
                label={item.tooltip}
                key={item.value}
                skipProvider
                offset={13}
                side={"top"}
              >
                <EscalatingButton data={item} handleReaction={handleReaction} />
              </Tooltip>
            ))}
          </RadixTooltip.Provider>
        </EmojisContainer>
      </Row>
    </Wrapper>
  );
};

export default AttendeeBarUiNormal;
