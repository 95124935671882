import styled, { useTheme } from "styled-components";
import Typo from "../../components-ui/atoms/Typo";
import { useText } from "../../core/i18n/i18nHooks";

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const LogoutPageUi: React.FC = () => {
  const t = useText();
  const theme = useTheme();

  return (
    <Wrapper>
      <Typo.Label color={theme.colorAboveNoBackground}>
        {t("logging_out")}
      </Typo.Label>
    </Wrapper>
  );
};

export default LogoutPageUi;
