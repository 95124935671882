import { useEffect } from "react";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import adapter from "webrtc-adapter";
import { emit } from "../../../lib/bus";
import { log, logError, logWarn } from "../../../lib/logger";
import { roundDecimal } from "../../../lib/math";
import { useStore } from "../../../store/store";
import { DataChannelStatus, StreamingStatus } from "../gameConnectionTypes";
import useGameMessage from "../useGameMessage";
import { sendWSMessage } from "../websocket/websocketUtils";
import WebRtcPlayer from "./WebRtcPlayer";
import { MessageType, ToClientMessageType } from "./helpers/constants";
import {
  freezeFrame,
  invalidateFreezeFrameOverlay,
  receiveFreezeFrameData,
  startFreezeFrame,
} from "./helpers/freezeFrame";
import { EpicHTMLVideoElement, showOnScreenKeyboard } from "./helpers/inputs";
import { computeObjectFit } from "./helpers/utils";
import { sendGameMessage } from "./webRtcMessageHandlers";

let webRtcPlayerObj: WebRtcPlayer;
log("WEBRTC", `Loaded WebRTC adapter for: ${adapter.browserDetails.browser}`);

export const useWebRtcPlayer = (
  playerElement: HTMLDivElement | null,
  videoElement: HTMLVideoElement | null,
  streamAudio: HTMLAudioElement | null,
  forceTurnRelay: boolean
) => {
  const {
    rtcConfig: config,
    setDataChannelStatus,
    setStreamingError,
    setStreamingStatus,
    setVideoEncoderQP,
    audioQuality,
  } = useStore((s) => s.gameConnection);
  const dispatchGameMessage = useGameMessage();

  useEffect(() => {
    if (!playerElement) return;
    if (!videoElement) return;
    if (!streamAudio) return;
    if (!config) return;

    log("WEBRTC", "setupWebRtcPlayer()");
    if (webRtcPlayerObj) {
      logError("WEBRTC", "There is already a webRtcPlayerObj, aborting setup.");
      return;
    }

    webRtcPlayerObj = new WebRtcPlayer(
      videoElement,
      streamAudio,
      { peerConnectionOptions: config.peerConnectionOptions },
      forceTurnRelay
    );

    videoElement.addEventListener("play", () => {
      requestQualityControl();
      setStreamingStatus(StreamingStatus.PLAYING);
    });

    webRtcPlayerObj.setAudioQuality(audioQuality);

    webRtcPlayerObj.onWebRtcOffer = (offer) => {
      const offerStr = JSON.stringify(offer);
      log("WEBRTC", `-> WS: offer:\n${offerStr}`);
      sendWSMessage(offerStr);
    };

    webRtcPlayerObj.onWebRtcCandidate = (candidate) => {
      // The specific candidate will be logged by webRtcPlayer.onicecandidate
      // with the "ICE candidate (local)" prefix.
      log("WEBRTC", `-> WS of type "iceCandidate"`);
      sendWSMessage(
        JSON.stringify({ type: "iceCandidate", candidate: candidate })
      );
    };

    webRtcPlayerObj.onDataChannelConnected = () => {
      setDataChannelStatus(DataChannelStatus.DATA_CHANNEL_CONNECTED);

      // Let Unreal now that we are connected.
      // This is a temporary workaround until the PixelStreaming plugin
      // exposes callbacks for this functionality.
      const descriptor = { Connected: "" };
      emitUIInteraction(descriptor);

      const selectedPair = webRtcPlayerObj.getSelectedCandidatePair();
      if (selectedPair) {
        log(
          "WEBRTC",
          `Selected ICE candidate pair:\n${JSON.stringify(
            selectedPair,
            undefined,
            2
          )}`
        );
      }
    };

    webRtcPlayerObj.onDataChannelClosed = () => {
      setDataChannelStatus(DataChannelStatus.DATA_CHANNEL_CLOSED);
      // Also use this callback to register that the stream was closed.
      // Maybe we could use a different more correct callback, but it doesn't matter
      // too much for our use case currently.
      setStreamingStatus(StreamingStatus.CLOSED);
    };

    webRtcPlayerObj.onDataChannelMessage = (data) => {
      const view = new Uint8Array(data);
      if (freezeFrame.receiving) {
        receiveFreezeFrameData(view);
      } else if (view[0] === ToClientMessageType.QualityControlOwnership) {
        // let ownership = view[1] === 0 ? false : true;
        // If we own the quality control, we can't relinquish it. We only loose
        // quality control when another peer asks for it
        // if (qualityControlOwnershipCheckBox !== null) {
        //   qualityControlOwnershipCheckBox.disabled = ownership;
        //   qualityControlOwnershipCheckBox.checked = ownership;
        // }
      } else if (view[0] === ToClientMessageType.Response) {
        const response = new TextDecoder("utf-16").decode(data.slice(1));
        const obj = JSON.parse(response);
        responseEventListeners.forEach((listener /*, _key*/) => listener(obj));
      } else if (view[0] === ToClientMessageType.Command) {
        const commandAsString = new TextDecoder("utf-16").decode(data.slice(1));
        log("WEBRTC", commandAsString);
        const command = JSON.parse(commandAsString);
        if (command.command === "onScreenKeyboard") {
          showOnScreenKeyboard(command, videoElement as EpicHTMLVideoElement);
        }
      } else if (view[0] === ToClientMessageType.FreezeFrame) {
        startFreezeFrame(view);
      } else if (view[0] === ToClientMessageType.UnfreezeFrame) {
        invalidateFreezeFrameOverlay();
      } else if (view[0] === ToClientMessageType.VideoEncoderAvgQP) {
        // This event is only sent by Unreal 4.26+
        // QP could *maybe* stand for Quantization Parameter.
        const str = new TextDecoder("utf-16").decode(data.slice(1));
        const videoEncoderQP = parseInt(str);
        if (typeof videoEncoderQP === "number")
          setVideoEncoderQP(videoEncoderQP);
        else
          logWarn("WEBRTC", "VideoEncoderQP is not a number: ", videoEncoderQP);
      } else {
        logError("WEBRTC", `unrecognised data received, packet ID ${view[0]}`);
      }
    };

    addResponseEventListener("main", dispatchGameMessage);

    // Formerly createWebRtcOffer();
    if (webRtcPlayerObj) {
      log("WEBRTC", "Creating offer");
      setStreamingStatus(StreamingStatus.OFFERING);
      webRtcPlayerObj.createOffer();
    } else {
      log("WEBRTC", "WebRTC player not setup, cannot create offer");
      setStreamingError(
        new Error("Error: The WebRTC video could not be setup.")
      );
    }
  }, [
    playerElement,
    videoElement,
    streamAudio,
    config,
    forceTurnRelay,
    setDataChannelStatus,
    setStreamingError,
    setStreamingStatus,
    setVideoEncoderQP,
    dispatchGameMessage,
    audioQuality,
  ]);
};

const setupStats = () => {
  webRtcPlayerObj.onAggregatedStats = (aggregatedStats) => {
    // Save stats in the store for stats panel.
    emit("webRtcStreamingStats", aggregatedStats);
    // Send stats to backend/Unreal.
    sendGameMessage({
      type: "WebRtcStreamingStats",
      data: aggregatedStats,
    });
  };

  // Recompute stats every second.
  webRtcPlayerObj.aggregateStats(1000);
};

export function onWebRtcOffer(webRTCData: RTCSessionDescriptionInit) {
  webRtcPlayerObj.receiveOffer(webRTCData);
  setupStats();
}

export const onWebRtcAnswer = (webRTCData: RTCSessionDescriptionInit) => {
  webRtcPlayerObj.receiveAnswer(webRTCData);

  // We are exposing the webRtc object in the global window to help
  // playwright have better access to it (and to it's metrics) during
  // automated testing.

  window.__webRtcPlayerObj = webRtcPlayerObj;

  setupStats();
};

export const onWebRtcIceCandidateFromSignaling = (
  iceCandidate: RTCIceCandidateInit | RTCIceCandidate
) => {
  if (webRtcPlayerObj)
    webRtcPlayerObj.handleCandidateFromSignalingServer(iceCandidate);
};

/**
 * Send data across the datachannel
 * @param data
 * @returns true if there was an error, false if the message was sent.
 */
export const sendInputData = (data: string | ArrayBuffer): boolean => {
  if (!webRtcPlayerObj) {
    log("WEBRTC", "Could not send descriptor: WebRTCPlayer isn't initialised.");
    return false;
  }
  // resetAfkWarningTimer();
  return webRtcPlayerObj.send(data);
};

/**
 * Send data across the datachannel
 * A generic message has a type and a descriptor.

 * @returns true if there was an error, false if the message was sent.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emitDescriptor = (messageType: number, descriptor: any): boolean => {
  // Convert the descriptor object into a JSON string.
  const descriptorAsString = JSON.stringify(descriptor);

  // Add the UTF-16 JSON string to the array byte buffer, going two bytes at
  // a time.
  const data = new DataView(
    new ArrayBuffer(1 + 2 + 2 * descriptorAsString.length)
  );
  let byteIdx = 0;
  data.setUint8(byteIdx, messageType);
  byteIdx++;
  data.setUint16(byteIdx, descriptorAsString.length, true);
  byteIdx += 2;
  for (let i = 0; i < descriptorAsString.length; i++) {
    data.setUint16(byteIdx, descriptorAsString.charCodeAt(i), true);
    byteIdx += 2;
  }
  return sendInputData(data.buffer);
};

/**
 * A UI interaction will occur when the user presses a button powered by
 * JavaScript as opposed to pressing a button which is part of the pixel
 * streamed UI from the UE4 client.
 *
 * @param descriptor Content of the message
 * @returns true if there was an error, false if the message was sent.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emitUIInteraction = (descriptor: any): boolean => {
  return emitDescriptor(MessageType.UIInteraction, descriptor);
};

// A build-in command can be sent to UE4 client. The commands are defined by a
// JSON descriptor and will be executed automatically.
// The currently supported commands are:
//
// 1. A command to run any console command:
//    "{ ConsoleCommand: <string> }"
//
// 2. A command to change the resolution to the given width and height.
//    "{ Resolution: { Width: <value>, Height: <value> } }"
//
// 3. A command to change the encoder settings by reducing the bitrate by the
//    given percentage.
//    "{ Encoder: { BitrateReduction: <value> } }"
// Currently unused by the original app.js, I don't know why.
// const emitCommand = (descriptor: any) => {
//   emitDescriptor(MessageType.Command, descriptor);
// };

const requestQualityControl = () => {
  sendInputData(new Uint8Array([MessageType.RequestQualityControl]).buffer);
};

export const killWebRtcPlayer = () => {
  log("WEBRTC", "killWebRtcPlayer()");
  if (webRtcPlayerObj) {
    webRtcPlayerObj.close();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webRtcPlayerObj = null as any;
  }
};

const updateVideoStreamSize = (playerElement: HTMLElement) => {
  const descriptor = {
    Console:
      "setres " + playerElement.clientWidth + "x" + playerElement.clientHeight,
  };
  emitUIInteraction(descriptor);
  log("WEBRTC", descriptor);
};

const updateUnrealScreenSize = (
  playerElement: HTMLElement,
  vWidth: number,
  vHeight: number,
  fitStreamInViewPort: boolean
) => {
  const height = fitStreamInViewPort
    ? vHeight * (playerElement.clientWidth / vWidth)
    : playerElement.clientHeight;

  const { croppedX, croppedY } = computeObjectFit(
    playerElement.clientWidth || window.innerWidth,
    playerElement.clientHeight || window.innerHeight,
    vWidth,
    vHeight,
    fitStreamInViewPort
  );

  sendGameMessage({
    type: "ScreenSize",
    w: playerElement.clientWidth,
    h: height,
    croppedLeft: roundDecimal(croppedX, 10),
    croppedRight: roundDecimal(croppedX, 10),
    croppedTop: roundDecimal(croppedY, 10),
    croppedBottom: roundDecimal(croppedY, 10),
  });
};

// TODO: Remove this - workaround because of bug causing UE to crash when switching resolutions too quickly
const debouncedUpdateVideoStreamSize = debounce(updateVideoStreamSize, 1000, {
  leading: true,
});
const throttledUpdateUnrealScreenSize = throttle(updateUnrealScreenSize, 100, {
  leading: false,
  trailing: true,
});

export const onVideoSizeUpdate = (
  playerElement: HTMLElement,
  vWidth: number,
  vHeight: number,
  fitStreamInViewPort: boolean
) => {
  debouncedUpdateVideoStreamSize(playerElement);
  throttledUpdateUnrealScreenSize(
    playerElement,
    vWidth,
    vHeight,
    fitStreamInViewPort
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseEventListeners = new Map<string, (data: any) => void>();
export const addResponseEventListener = (
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listener: (data: any) => void
) => {
  responseEventListeners.set(key, listener);
};
export const removeResponseEventListener = (key: string) => {
  responseEventListeners.delete(key);
};
