import { ArrayBufferTarget, Muxer } from "mp4-muxer";

export type ReadableStreams = {
  videoStream: ReadableStream<VideoFrame>;
  audioStream: ReadableStream<AudioData>;
};

export class EncoderService {
  recording = false;
  muxer: Muxer<ArrayBufferTarget>;
  videoEncoder: VideoEncoder;
  audioEncoder: AudioEncoder;
  stopRecordingSignal?: AbortController;

  constructor(
    videoCodec: string,
    audioCodec: string,
    width: number,
    height: number,
    audioChannels: number,
    audioSampleRate: number
  ) {
    this.muxer = new Muxer({
      target: new ArrayBufferTarget(),
      video: {
        codec: "avc",
        width,
        height,
      },
      audio: {
        codec: "aac",
        sampleRate: audioSampleRate,
        numberOfChannels: audioChannels,
      },
      fastStart: "in-memory",
      firstTimestampBehavior: "offset",
    });

    this.videoEncoder = new VideoEncoder({
      output: (chunk, meta) => this.muxer.addVideoChunk(chunk, meta),
      error: () => undefined,
    });
    this.videoEncoder.configure({
      codec: videoCodec,
      width,
      height,
      framerate: 30,
    });

    this.audioEncoder = new AudioEncoder({
      output: (chunk, meta) => this.muxer.addAudioChunk(chunk, meta),
      error: () => undefined,
    });
    this.audioEncoder.configure({
      codec: audioCodec,
      numberOfChannels: audioChannels,
      sampleRate: audioSampleRate,
    });
  }

  start(
    videoStream: ReadableStream<VideoFrame>,
    audioStream: ReadableStream<AudioData>
  ) {
    this.recording = true;
    this.stopRecordingSignal = new AbortController();
    videoStream
      .pipeTo(
        new WritableStream({
          write: (videoFrame) => {
            if (this.recording) this.videoEncoder.encode(videoFrame);
            videoFrame.close();
          },
        }),
        {
          signal: this.stopRecordingSignal.signal,
        }
      )
      .catch(
        () =>
          undefined /* Ignore errors since aborting will always throw when stopping */
      );
    audioStream
      .pipeTo(
        new WritableStream({
          write: (audioData) => {
            if (this.recording) this.audioEncoder.encode(audioData);
            audioData.close();
          },
        }),
        {
          signal: this.stopRecordingSignal.signal,
        }
      )
      .catch(
        () =>
          undefined /* Ignore errors since aborting will always throw when stopping */
      );
  }

  async end() {
    this.recording = false;
    await Promise.all([this.videoEncoder.flush(), this.audioEncoder.flush()]);
    this.muxer.finalize();
    this.stopRecordingSignal?.abort("Recording stopped");
  }

  static getReadableStreams(
    video: MediaStream,
    audio: MediaStream
  ): ReadableStreams {
    const videoStream = video.getVideoTracks()[0];
    const videoTrackProcessor = new MediaStreamTrackProcessor({
      track: videoStream,
    });
    const audioStream = audio.getAudioTracks()[0];
    const audioTrackProcessor = new MediaStreamTrackProcessor({
      track: audioStream,
    });
    return {
      videoStream: videoTrackProcessor.readable,
      audioStream: audioTrackProcessor.readable,
    };
  }
}
