import { useTheme } from "styled-components";
import { useCurrentExperienceLogo } from "../../api/gyaradosHooks";
import { getImageUrl } from "../../api/gyaradosUtils";
import Logo from "../../components-ui/molecules/Logo";
import { LOGO_MAX_HEIGHT, LOGO_MAX_WIDTH } from "../../constants/configs";

const TopLogoPanelUi: React.FC = () => {
  const logo = useCurrentExperienceLogo();
  const theme = useTheme();
  const logoUrl = getImageUrl(logo, LOGO_MAX_WIDTH, LOGO_MAX_HEIGHT);

  return logoUrl ? (
    <Logo logoUrl={logoUrl} height={theme.topLogoHeight} />
  ) : null;
};

export default TopLogoPanelUi;
