/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment */
import { Publisher, Session, initSession } from "@opentok/client";
import { ActorRef, assign, enqueueActions, fromPromise, setup } from "xstate";
import { isFirefox } from "../../../constants/flags";
import { ConferenceError } from "../../../hooks/useConferenceErrors";
import { log, logError } from "../../../lib/logger";
import {
  getDefaultLocalCamera,
  getDefaultLocalMicrophone,
  getDefaultLocalSpeaker,
} from "../../../lib/mediaDevices";
import { filterEmptyDevices } from "../../../lib/voice";
import { useStore } from "../../../store/store";
import {
  VideoConferenceState,
  VideoConferenceStateType,
} from "../VideoConferenceAdapter";
import MediaDevicesService from "../mediaDevices";
import mediaDevicesPublisherMachine from "./mediaDevicesPublisher.machine";
import { sessionManagerMachine } from "./sessionManager.machine";
import { VonageStateMachineEvents } from "./types";
import { mediaPermissionsChecker } from "./util";

export type VonageSessionResponse = { token: string; sessionId: string };

const lobbyStateMachine = setup({
  types: {} as {
    context: {
      session: Session | null;
      token: string | null;
      sessionId: string | null;
      cameraSelectorRef: any | null;
      conferenceErrors: ConferenceError[];
      environmentId: string;
      userId: string;
      visitorToken: string;
      rootActor: ActorRef<any, any>;
      audioInputDevices: MediaDeviceInfo[];
      audioOutputDevices: MediaDeviceInfo[];
      videoInputDevices: MediaDeviceInfo[];
      selectedAudioInputDevice: MediaDeviceInfo | null;
      selectedAudioOutputDevice: MediaDeviceInfo | null;
      selectedVideoInputDevice: MediaDeviceInfo | null;
      mediaPermissions: { camera: boolean; microphone: boolean };
      goToDevicePreviewPressed: boolean;
      publisher: Publisher | null;
    };
    input: {
      rootActor: ActorRef<any, any>;
      environmentId: string;
      userId: string;
      visitorToken: string;
    };
    output: {
      publisher: Publisher | null;
    };
    events: VonageStateMachineEvents;
  },
  actors: {
    publishMediaDevices: mediaDevicesPublisherMachine,
    sessionManager: sessionManagerMachine,
    mediaDevicesFetcher: fromPromise<{
      audioInputDevices: MediaDeviceInfo[];
      audioOutputDevices: MediaDeviceInfo[];
      videoInputDevices: MediaDeviceInfo[];
    }>(async () => {
      const videoInputDevices = (
        await MediaDevicesService.enumerateVideoInputDevices()
      )?.filter((device) => device.deviceId !== "");

      const audioInputDevices = await (async () => {
        /*
         * Additional refreshing for permissions on Firefox due to assumption of lack of permissions bug
         */
        if (isFirefox) {
          await navigator.mediaDevices.getUserMedia({ audio: true });
        }
        const devices = filterEmptyDevices(
          await MediaDevicesService.enumerateAudioInputDevices()
        );

        return devices;
      })();

      const audioOutputDevices = filterEmptyDevices(
        await MediaDevicesService.enumerateAudioOutputDevices()
      );

      return {
        audioInputDevices,
        videoInputDevices,
        audioOutputDevices,
      };
    }),
    mediaPermissionsRequester: fromPromise<{
      camera: boolean;
      microphone: boolean;
    }>(async () => {
      const constraints = {
        audio: true,
        video: true,
      };
      const finalPermissions = {
        camera: false,
        microphone: false,
      };
      try {
        const initialPermissions = await mediaPermissionsChecker();
        finalPermissions.camera = initialPermissions?.camera || false;
        finalPermissions.microphone = initialPermissions?.microphone || false;
        if (!initialPermissions?.camera || !initialPermissions?.microphone) {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          if (stream) {
            log("VOICE/VIDEO", "Got media stream:", stream);
            stream.getTracks().forEach((track) => track.stop());
            finalPermissions.camera = true;
            finalPermissions.microphone = true;
          } else {
            log("VOICE/VIDEO", "Failed to get media stream");
          }
        }
      } catch (e) {
        if (
          e instanceof DOMException &&
          e.name == "AbortError" &&
          e.message == "Starting videoinput failed"
        ) {
          log(
            "VOICE/VIDEO",
            "User denied access to camera, trying to get audio only"
          );
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          if (stream) {
            log("VOICE/VIDEO", "Got audio stream:", stream);
            stream.getTracks().forEach((track) => track.stop());
            finalPermissions.microphone = true;
          } else {
            log("VOICE/VIDEO", "Failed to get media stream");
          }
        }
        logError("VOICE/VIDEO", "Failed to get media stream:", e);
      }
      return finalPermissions;
    }),
    connectToSession: fromPromise<
      Session,
      { sessionId: string; token: string; rootActor: any }
    >(async ({ input }) => {
      // Connect to session
      const rootActor = input.rootActor;
      const session = initSession(
        import.meta.env.VITE_VONAGE_APP_ID,
        input.sessionId
      );
      session.on({
        connectionCreated: (evt: any) => {
          return rootActor.send({
            type: "conference.participantConnected",
            data: evt,
          });
        },
        connectionDestroyed: (evt: any) =>
          rootActor.send({ type: "conference.participantLeft", data: evt }),
        streamCreated: (evt: any) => {
          return rootActor.send({
            type: "conference.streamCreated",
            data: evt,
          });
        },
        streamDestroyed: (evt: any) => {
          return rootActor.send({
            type: "conference.streamDestroyed",
            data: evt,
          });
        },
        streamPropertyChanged: (evt: any) =>
          rootActor.send({
            type: "conference.streamPropertyChanged",
            data: evt,
          }),
      });

      // TODO: Revisit this, we should not be using the session object directly
      // Vonage doesn't expose these properties
      // @ts-ignore
      if (session.currentState === "connected") {
        const sessionDisconnectionPromise = new Promise((resolve) => {
          session.on("sessionDisconnected", () => {
            session.off("sessionDisconnected");
            resolve(true);
          });
        });

        session.disconnect();
        await sessionDisconnectionPromise;
        // @ts-ignore
      } else if (session.currentState === "connecting") {
        return session;
      }

      await new Promise((resolve, reject) => {
        session.connect(input.token, async (error) => {
          if (error) {
            logError(
              "VOICE/VIDEO",
              "Error connecting to Vonage session",
              error
            );
            reject(error);
          }
          resolve(session);
        });
      });
      return session;
    }),
  },
  actions: {
    deviceListChanged: enqueueActions(({ event, enqueue }) => {
      if (event.type === "lugia.deviceListChanged") {
        const { audioInputDevices, audioOutputDevices, videoInputDevices } =
          event.data;
        enqueue.assign({
          audioInputDevices,
          audioOutputDevices,
          videoInputDevices,
        });
      }
    }),
    updateConferenceState: (
      { context },
      params: { state: VideoConferenceStateType }
    ) => {
      context.rootActor.send({
        type: "conference.updateConferenceState",
        data: params.state,
      });
    },
    setupPermissions: enqueueActions(
      (
        { context, enqueue },
        params: {
          camera: boolean;
          microphone: boolean;
        }
      ) => {
        enqueue.assign({
          mediaPermissions: {
            camera: params.camera,
            microphone: params.microphone,
          },
        });
        enqueue(() => {
          context.rootActor.send({
            type: "lugia.setupPermissions",
            data: {
              camera: params.camera,
              microphone: params.microphone,
            },
          });
        });
      }
    ),
    setupDevices: enqueueActions(
      (
        { context, enqueue },
        params: {
          audioInputDevices: MediaDeviceInfo[];
          audioOutputDevices: MediaDeviceInfo[];
          videoInputDevices: MediaDeviceInfo[];
        }
      ) => {
        const storeSelectedMic =
          useStore.getState().userMedia.selectedMicrophone;
        const storeSelectedCam = useStore.getState().userMedia.selectedCamera;
        const storeSelectedSpeakers =
          useStore.getState().userMedia.selectedSpeakers;

        const selectFinalDevice = (
          devices: MediaDeviceInfo[],
          storeSelectedDevice: MediaDeviceInfo | undefined,
          deviceKind: MediaDeviceInfo["kind"]
        ) => {
          if (storeSelectedDevice) {
            return storeSelectedDevice;
          } else if (devices.length > 0) {
            switch (deviceKind) {
              case "audioinput":
                return getDefaultLocalMicrophone({
                  microphonePermission: context.mediaPermissions.microphone,
                  audioInputDevices: devices,
                });
              case "audiooutput":
                return getDefaultLocalSpeaker({
                  audioOutputDevices: devices,
                });
              case "videoinput":
                return getDefaultLocalCamera({
                  cameraPermission: context.mediaPermissions.camera,
                  videoInputDevices: devices,
                });
            }
          }

          return null;
        };

        function filterCallback(selectedDevice: MediaDeviceInfo | null) {
          return (obj: MediaDeviceInfo) =>
            selectedDevice?.deviceId === obj.deviceId;
        }

        const selectedAudioInputDevice = selectFinalDevice(
          params.audioInputDevices,
          params.audioInputDevices.find(filterCallback(storeSelectedMic)),
          "audioinput"
        );
        const selectedVideoInputDevice = selectFinalDevice(
          params.videoInputDevices,
          params.videoInputDevices.find(filterCallback(storeSelectedCam)),
          "videoinput"
        );
        const selectedAudioOutputDevice = selectFinalDevice(
          params.audioOutputDevices,
          params.audioOutputDevices.find(filterCallback(storeSelectedSpeakers)),
          "audiooutput"
        );

        enqueue.assign({
          selectedAudioInputDevice,
          selectedVideoInputDevice,
          selectedAudioOutputDevice,
          audioInputDevices: params.audioInputDevices,
          audioOutputDevices: params.audioOutputDevices,
          videoInputDevices: params.videoInputDevices,
        });
        enqueue(() => {
          context.rootActor.send({
            type: "lugia.setupDevices",
            data: {
              audioInputDevices: params.audioInputDevices,
              audioOutputDevices: params.audioOutputDevices,
              videoInputDevices: params.videoInputDevices,
            },
          });
        });
        enqueue(() => {
          context.rootActor.send({
            type: "lugia.setupSelectedDevices",
            data: {
              selectedAudioInputDevice,
              selectedVideoInputDevice,
              selectedAudioOutputDevice,
            },
          });
        });
      }
    ),
    finalDeviceValidataion: enqueueActions(({ context, enqueue }) => {
      const selectedAudioInputDevice =
        useStore.getState().userMedia.selectedMicrophone;
      const selectedVideoInputDevice =
        useStore.getState().userMedia.selectedCamera;
      const selectedAudioOutputDevice =
        useStore.getState().userMedia.selectedSpeakers;

      const audioInputDevices = useStore.getState().userMedia.audioInputDevices;
      const videoInputDevices = useStore.getState().userMedia.videoInputDevices;

      if (selectedAudioInputDevice || selectedVideoInputDevice) {
        // syncronize the store and the machine both parent and child
        enqueue.assign({
          selectedAudioInputDevice,
          selectedVideoInputDevice,
          audioInputDevices,
          videoInputDevices,
        });
        enqueue(() => {
          context.rootActor.send({
            type: "lugia.setupSelectedDevices",
            data: {
              selectedAudioInputDevice,
              selectedVideoInputDevice,
              selectedAudioOutputDevice,
            },
          });
        });
        enqueue.raise({ type: "lugia.devicesReady" });
      } else {
        enqueue.raise({ type: "lugia.noDevicesAvailabe" });
      }
    }),
  },
}).createMachine({
  id: "LobbyStateMachine",
  initial: "InitializingSession",
  context: ({ input }) => ({
    session: null,
    rootActor: input.rootActor,
    environmentId: input.environmentId,
    userId: input.userId,
    visitorToken: input.visitorToken,
    cameraSelectorRef: null,
    token: "",
    sessionId: "",
    conferenceErrors: [],
    audioInputDevices: [],
    audioOutputDevices: [],
    videoInputDevices: [],
    selectedAudioInputDevice: null,
    selectedAudioOutputDevice: null,
    selectedVideoInputDevice: null,
    mediaPermissions: { camera: false, microphone: false },
    goToDevicePreviewPressed: false,
    publisher: null,
  }),

  output: ({ context }) => ({ publisher: context.publisher }),

  on: {
    "lugia.goToDevicePreview": {
      actions: assign({ goToDevicePreviewPressed: true }),
    },
    "lugia.deviceListChanged": {
      actions: ["deviceListChanged"],
    },
  },
  states: {
    hist: {
      type: "history",
      history: "deep",
    },
    InitializingSession: {
      entry: {
        type: "updateConferenceState",
        params: { state: VideoConferenceState.SESSION_INITIALIZING },
      },
      id: "InitializingSession",
      initial: "FetchingToken",
      states: {
        hist: {
          type: "history",
          history: "deep",
        },
        FetchingToken: {
          entry: {
            type: "updateConferenceState",
            params: { state: VideoConferenceState.SESSION_INITIALIZING },
          },
          invoke: {
            src: "sessionManager",
            id: "sessionManager",
            input: ({ context }) => ({
              environmentId: context.environmentId,
              userId: context.userId,
              visitorToken: context.visitorToken,
            }),
            onDone: {
              actions: assign({
                token: ({ event }) => event.output.token,
                sessionId: ({ event }) => event.output.sessionId,
                visitorToken: ({ event }) => event.output.visitorToken,
              }),
              target: "#InitializingSession.ConnectingToSession",
            },
            onError: {
              target: "#LobbyStateMachine.ConnectionFailed",
            },
          },
        },
        ConnectingToSession: {
          invoke: {
            id: "connectToSession",
            src: "connectToSession",
            input: ({ context }) => ({
              sessionId: context.sessionId!,
              token: context.token!,
              rootActor: context.rootActor,
            }),

            onDone: {
              actions: enqueueActions(({ event, enqueue, context }) => {
                enqueue.assign({
                  session: event.output,
                });
                enqueue(() => {
                  context.rootActor.send({
                    type: "lugia.setSession",
                    data: {
                      session: event.output,
                    },
                  });
                });
              }),
              target: "#LobbyStateMachine.SessionInitialized",
            },
            onError: {
              target: "#LobbyStateMachine.ConnectionFailed",
            },
          },
        },
      },
    },
    SessionInitialized: {
      entry: {
        type: "updateConferenceState",
        params: { state: VideoConferenceState.SESSION_INITIALIZED },
      },
      id: "SessionInitialized",
      always: [
        {
          target: "ConnectingToConference.SettingUpPermissions",
          guard: ({ context }) => context.goToDevicePreviewPressed,
        },
      ],
      on: {
        "lugia.goToDevicePreview": {
          target: "ConnectingToConference.SettingUpPermissions",
        },
      },
    },
    ConnectingToConference: {
      initial: "SettingUpPermissions",
      states: {
        hist: {
          type: "history",
          history: "deep",
        },
        SettingUpPermissions: {
          entry: {
            type: "updateConferenceState",
            params: { state: VideoConferenceState.PERMISSION_SETUP },
          },
          invoke: {
            id: "mediaPermissionsRequester",
            src: "mediaPermissionsRequester",
            onDone: [
              {
                target: "BlockedPermissions",
                actions: {
                  type: "setupPermissions",
                  params: ({ event }) => event.output,
                },
                guard: ({ event }) =>
                  !event.output.camera && !event.output.microphone,
              },
              {
                target: "SettingUpDevices",
                actions: {
                  type: "setupPermissions",
                  params: ({ event }) => event.output,
                },
                guard: ({ event }) =>
                  event.output.camera || event.output.microphone,
              },
            ],
          },
        },
        BlockedPermissions: {
          entry: {
            type: "updateConferenceState",
            params: { state: VideoConferenceState.BLOCKED_PERMISSIONS },
          },
        },
        NoDevices: {
          entry: {
            type: "updateConferenceState",
            params: { state: VideoConferenceState.NO_DEVICES },
          },
          // TODO Move this to Join as listener
          // here we catch an event of join as listener and go to success
        },
        SettingUpDevices: {
          entry: {
            type: "updateConferenceState",
            params: { state: VideoConferenceState.DEVICE_SETUP },
          },
          invoke: {
            id: "mediaDevicesFetcher",
            src: "mediaDevicesFetcher",
            onDone: [
              {
                actions: {
                  type: "setupDevices",
                  params: ({ event }) => event.output,
                },
                target: "NoDevices",
                guard: ({ event }) =>
                  event.output.audioInputDevices.length === 0 &&
                  event.output.videoInputDevices.length === 0 &&
                  event.output.audioOutputDevices.length === 0,
              },
              {
                actions: {
                  type: "setupDevices",
                  params: ({ event }) => event.output,
                },
                target: "ConferencePreview",
                guard: ({ event }) =>
                  event.output.audioInputDevices.length > 0 ||
                  event.output.videoInputDevices.length > 0 ||
                  event.output.audioOutputDevices.length > 0,
              },
            ],
          },
        },
        ConferencePreview: {
          entry: {
            type: "updateConferenceState",
            params: { state: VideoConferenceState.CONFERENCE_PREVIEW },
          },
          on: {
            "lugia.joinConference": {
              actions: ["finalDeviceValidataion"],
            },
            "lugia.devicesReady": {
              target: "PublishDevices",
            },
            "lugia.noDevicesAvailabe": {
              target: "NoDevices",
            },
          },
        },
        PublishDevices: {
          invoke: {
            id: "publishMediaDevices",
            src: "publishMediaDevices",
            input: ({ context }) => ({
              session: context.session!,
              rootActor: context.rootActor,
              videoInputSource: context.selectedVideoInputDevice,
              audioInputSource: context.selectedAudioInputDevice,
              videoInputDevices: context.videoInputDevices,
              audioInputDevices: context.audioInputDevices,
              mediaPermissions: context.mediaPermissions,
            }),
            onDone: [
              {
                guard: ({ event }) => Boolean(event.output.publisher),
                target: "#LobbyStateMachine.ConnectionSuccessful",
                actions: assign(({ event, context }) => {
                  return {
                    ...context,
                    publisher: event.output.publisher ?? null,
                  };
                }),
              },
            ],
          },
        },
      },
      on: {},
    },
    // this state will tell us how the visitor joined to the conference, listener, active, inactive
    ConnectionSuccessful: {
      type: "final",
    },
    ConnectionFailed: {
      type: "final",
    },
  },
});

export default lobbyStateMachine;
