import { useRef } from "react";
import styled, { css } from "styled-components";
import Typo from "./Typo";

export const GenericInputStyles = css`
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 1px solid ${(p) => p.theme.colorAbove3};
  border-radius: 50%;
  display: grid;
  place-content: center;
`;

const RadioLabel = styled.label<{ $width?: React.CSSProperties["width"] }>`
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  border-radius: ${(p) => p.theme.radiusTiny};
  transition: 300ms background-color ease;
  transition-property: background-color, box-shadow;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  vertical-align: middle;
  width: ${(p) => p.$width || "auto"};
  &span {
    color: inherit;
  }
  .noTouch &:hover {
    background-color: ${(p) => p.theme.colorAbove1};
  }
`;

const RadioInputDisplay = styled.span`
  margin: 0;
  font: inherit;
  cursor: pointer;
  width: 22px;
  height: 22px;
  border: 1px solid ${(p) => p.theme.colorAbove1};
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`;

const InternalRadioSelect = styled.span`
  content: "";
  inline-size: 16px;
  block-size: 16px;
  border-radius: 50%;
  display: block;
  transform: scale(0);
  transition: 150ms transform ease-in-out;
  box-shadow: inset 1em 1em ${() => "transparent"};
`;

const StyledRadioInput = styled.div`
  background-color: ${(p) => p.theme.colorAbove0};
  border-radius: ${(p) => p.theme.radiusTiny};
  color: ${(p) => p.theme.colorAbove3};
  &:hover:not(:checked) {
    color: ${(p) => p.theme.colorAbove4};
    ${RadioInputDisplay} {
      border: 1px solid ${(p) => p.theme.colorAbove2};
      color: ${(p) => p.theme.colorBelow2};
    }
  }
`;

const RadioInput = styled.input`
  ${GenericInputStyles};
  border-radius: 50%;
  display: none;
  &:checked + ${StyledRadioInput} {
    box-shadow: -16px 12px 24px 0px ${(p) => p.theme.colorShadow};
    transition: 300ms background-color ease;
    transition-property: background-color, box-shadow;
    background-color: ${(p) => p.theme.colorAbove5};
    color: ${(p) => p.theme.colorBelow2};
    &:hover {
      background-color: ${(p) => p.theme.colorAbove4};
    }
    label > ${RadioInputDisplay} {
      border: 1px solid ${(p) => p.theme.colorBelow1};
      > ${InternalRadioSelect} {
        box-shadow: inset 1em 1em ${(p) => p.theme.colorBelow1};
        transform: scale(1);
      }
    }
  }
`;

export type CheckboxProps = {
  name: string;
  label: string;
  value?: string;
  checked?: boolean;
  testId?: string;
  onChange?: (params: { name: string; checked: boolean }) => void;
  width?: React.CSSProperties["width"];
  style?: React.CSSProperties;
};

const RadioButton: React.FC<CheckboxProps> = ({
  label,
  checked,
  name,
  testId,
  onChange,
  style,
  width,
  value,
}) => {
  const radioButtonRef = useRef<HTMLInputElement>(null);
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({ name, checked: e.target.checked });
  };

  return (
    <div data-testid={testId}>
      <RadioInput
        ref={radioButtonRef}
        id={label}
        value={value ?? label}
        type="radio"
        name={name}
        checked={checked}
        onChange={_onChange}
      />
      <StyledRadioInput>
        <RadioLabel style={style} $width={width} htmlFor={label}>
          <RadioInputDisplay>
            <InternalRadioSelect />
          </RadioInputDisplay>
          <Typo.Label color="inherit">{label}</Typo.Label>
        </RadioLabel>
      </StyledRadioInput>
    </div>
  );
};

export default RadioButton;
