import { SvgProps } from "../../types/svg";

const SadIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_264_61)">
      <path
        d="M10 13C8.64375 13 7.6875 13.6812 7.12813 14.3281C6.94688 14.5375 6.63125 14.5594 6.42188 14.3781C6.2125 14.1969 6.19062 13.8812 6.37187 13.6719C7.06562 12.8719 8.27813 12 9.97188 12C11.7219 12 12.9313 12.8719 13.6281 13.6719C13.8094 13.8812 13.7875 14.1969 13.5781 14.3781C13.3688 14.5594 13.0531 14.5375 12.8719 14.3281C12.3094 13.6812 11.3563 13 9.97188 13H10ZM8.2625 8.5C8.2625 8.91563 7.925 9.25 7.5125 9.25C7.09687 9.25 6.7625 8.91563 6.7625 8.5C6.7625 8.08437 7.09687 7.75 7.5125 7.75C7.925 7.75 8.2625 8.08437 8.2625 8.5ZM11.7625 8.5C11.7625 8.08437 12.0969 7.75 12.5125 7.75C12.925 7.75 13.2625 8.08437 13.2625 8.5C13.2625 8.91563 12.925 9.25 12.5125 9.25C12.0969 9.25 11.7625 8.91563 11.7625 8.5ZM18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10ZM10 3C6.13438 3 3 6.13438 3 10C3 13.8656 6.13438 17 10 17C13.8656 17 17 13.8656 17 10C17 6.13438 13.8656 3 10 3Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
    <defs>
      <clipPath id="clip0_264_61">
        <rect
          width="16"
          height="16"
          fill="currentColor"
          className="fill-color"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SadIcon;
