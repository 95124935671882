import { useEffect, useRef, useState } from "react";
import cuid from "cuid";
import { useGetPopupByMintId } from "../../api/gyaradosHooks";
import { useEnvironmentContext } from "../../core/EnvironmentFetcher.core";
import { useText } from "../../core/i18n/i18nHooks";
import { useStore } from "../../store/store";

export const useMintEvent = () => {
  const t = useText();
  const { environment } = useEnvironmentContext();
  const currentMintEventId = useStore(
    (state) => state.minting.currentMintEventId
  );
  const setCurrentMintEventId = useStore(
    (state) => state.minting.setCurrentMintEventId
  );
  const visitorToken = useStore((state) => state.session.visitorToken);
  const walletId = useStore((state) => state.minting.currentWalletId);
  const email = useStore((state) => state.profile.profileData?.email);
  const address = walletId ?? email;
  const setPanelExtraOptions = useStore(
    (state) => state.layout.setPanelExtraOptions
  );
  const openPanel = useStore((state) => state.layout.openPanel);
  const closePanel = useStore((state) => state.layout.closePanel);
  const popup = useGetPopupByMintId(currentMintEventId);

  const currentMintEvent = currentMintEventId
    ? environment.mintEvents.find(
        (mintEvent) => mintEvent.id === currentMintEventId
      )
    : undefined;
  const isMinting = useRef<boolean>(false);
  const isPolling = useRef<boolean>(false);
  const [requestId, setRequestId] = useState<string | undefined>(undefined);
  const retries = useRef<number>(0);
  const pollTimeout = useRef<number | undefined>(undefined);
  // Have to keep track of the poap event id to show the link
  // The id is received from the server after the minting request
  const poapEventId = useRef<string | undefined>(undefined);
  const progressHintId = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (!visitorToken) return;
    if (!currentMintEvent) return;
    if (!address) return;
    if (isMinting.current) return;

    isMinting.current = true;

    // Have to keep track of the hint id to close it later
    // Have to have two of them to force rendering when the content changes
    const errorHintId = cuid();
    progressHintId.current = cuid();

    setPanelExtraOptions("hint", {
      content: t("minting_in_progress"),
    });
    openPanel("hint", { slug: progressHintId.current });

    fetch(
      `${import.meta.env.VITE_GYARALESS_URL}/mints/${environment.id}/mint/${currentMintEventId}`,
      {
        method: "POST",
        body: JSON.stringify({ walletId: address }),
        headers: {
          Authorization: `Bearer ${visitorToken}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        // Show an error hint if the minting fails
        if (!response.ok) {
          let content = t("minting_error_unknown");
          switch (true) {
            case currentMintEvent.service === "poap" && response.status === 409:
              content = t("minting_error_poap_already_claimed");
              break;
            case currentMintEvent.service === "poap" && response.status === 404:
              content = t("minting_error_poap_out_of_supply");
              break;
            case currentMintEvent.service === "poap" && response.status === 412:
              content = t("minting_error_poap_out_of_time");
              break;
            case currentMintEvent.service === "stendhal" &&
              response.status === 409:
              content = t("minting_error_nft_already_claimed");
              break;
            default:
              break;
          }
          setPanelExtraOptions("hint", {
            content,
          });
          openPanel("hint", { slug: errorHintId });
          return Promise.reject(new Error(`Failed to mint: ${content}`));
        }
        return response.json();
      })
      .then((data) => {
        // Set the request id to trigger the polling
        switch (true) {
          case currentMintEvent.service === "poap" && Boolean(walletId):
            // POAP uses the address as the request id
            setRequestId(walletId);
            poapEventId.current = data?.event_id;
            break;
          case currentMintEvent.service === "poap" && !walletId:
            closePanel("hint", { slug: progressHintId.current });
            // If POAP is reserved for an email we just show the POAP link
            setPanelExtraOptions("popup", {
              link: `https://collectors.poap.xyz/drop/${data?.event_id}`,
              extraText: t("minting_success_poap_email"),
            });
            setCurrentMintEventId(undefined);
            openPanel("popup", {
              slug: popup?.actionPanelId,
            });
            break;
          case currentMintEvent.service === "bmw" ||
            currentMintEvent.service === "stendhal":
            setRequestId(data?.requestId);
            break;
        }
      })
      .catch(() => {
        // Close the error hint after 5 seconds
        setTimeout(() => {
          closePanel("hint", { slug: errorHintId });
        }, 5000);
        setCurrentMintEventId(undefined);
      })
      .finally(() => {
        isMinting.current = false;
      });
  }, [
    walletId,
    currentMintEventId,
    currentMintEvent,
    visitorToken,
    environment.id,
    isMinting,
    address,
    setPanelExtraOptions,
    openPanel,
    closePanel,
    popup?.actionPanelId,
    t,
    setCurrentMintEventId,
  ]);

  useEffect(() => {
    if (!requestId) return;
    if (pollTimeout.current) return;

    const poll = async () => {
      if (!requestId) return;
      if (isPolling.current) return;

      isPolling.current = true;
      const response = await fetch(
        `${import.meta.env.VITE_GYARALESS_URL}/mints/${environment.id}/check/${currentMintEventId}`,
        {
          method: "POST",
          body: JSON.stringify({ requestId }),
          headers: {
            Authorization: `Bearer ${visitorToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      isPolling.current = false;
      const data = await response.json();
      const isSuccessful = response.ok && data?.completed === true;
      const isFinished = isSuccessful || retries.current >= 10;

      if (!isFinished) {
        retries.current += 1;
        pollTimeout.current = window.setTimeout(poll, 5000);
      } else {
        switch (currentMintEvent?.service) {
          case "poap":
            setPanelExtraOptions("popup", {
              link: isSuccessful
                ? `https://collectors.poap.xyz/token/${data?.tokenId}`
                : `https://collectors.poap.xyz/drop/${poapEventId.current}`,
              extraText: isSuccessful
                ? t("minting_success_poap_wallet")
                : t("minting_error_poap_not_found_in_time"),
            });
            break;
          case "bmw":
            setPanelExtraOptions("popup", {
              link: `https://polygonscan.com/tx/${requestId}`,
              extraText: t("minting_success_bmw_wallet"),
            });
            break;
          case "stendhal":
            setPanelExtraOptions("popup", {
              link: `https://amoy.polygonscan.com/tx/${requestId}`,
              extraText: t("minting_success_bmw_wallet"),
            });
            break;
        }
        closePanel("hint", { slug: progressHintId.current });
        openPanel("popup", {
          slug: popup?.actionPanelId,
        });

        // Reset the polling state
        retries.current = 0;
        poapEventId.current = undefined;
        setRequestId(undefined);
        setCurrentMintEventId(undefined);
        if (pollTimeout.current) {
          clearTimeout(pollTimeout.current);
          pollTimeout.current = undefined;
        }
      }
    };

    pollTimeout.current = window.setTimeout(poll, 3000);

    return () => {
      if (pollTimeout.current) {
        clearTimeout(pollTimeout.current);
        pollTimeout.current = undefined;
      }
    };
  }, [
    requestId,
    walletId,
    currentMintEventId,
    currentMintEvent,
    visitorToken,
    environment.id,
    isMinting,
    address,
    setPanelExtraOptions,
    openPanel,
    closePanel,
    popup?.actionPanelId,
    t,
    setCurrentMintEventId,
  ]);
};
