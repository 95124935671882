import styled, { useTheme } from "styled-components";
import Glass from "../../../components-ui/atoms/Glass";
import Image from "../../../components-ui/atoms/Image";
import Markdown from "../../../components-ui/atoms/Markdown";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import Card from "../../../components-ui/molecules/Card";
import CloseButton from "../../../components-ui/molecules/CloseButton";
import { steps } from "../../../style/theme";
import { Body } from "../infoCard/InfoCardPanel.ui.normal";
import { Props } from "./Map.ui";
import MapCardUiNormal from "./components/MapCard.ui.normal";
import MapPointUi from "./components/MapPoint.ui";

const Wrapper = styled.div<{
  $maxWidth: string;
  $minHorizontalPadding: string;
}>`
  width: 100%;
  height: 100%;
  padding: 0
    max(
      calc((100vw - ${(p) => p.$maxWidth}) / 2),
      ${(p) => p.$minHorizontalPadding}
    );
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colorAboveRoot};
  box-sizing: border-box;
  pointer-events: none;
`;

const RatioWrapper = styled.div<{ $ratio: number }>`
  position: relative;
  width: 100%;
  padding-top: ${(p) => p.$ratio * 100}%;
`;

const PanelContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${() => steps.spacing[2]};
  right: ${() => steps.spacing[2]};
  z-index: 1;
`;

const CardWrapper = styled.div`
  position: absolute;
  right: -200px;
  bottom: 64px;
  z-index: 2;
`;

const PointsWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
`;

const MapDescriptionWrapper = styled.div`
  position: absolute;
  top: 0;
  margin: 24px;
  z-index: 1;
`;

const TitleWrapper = styled.div<{ $bigTopPadding?: boolean }>`
  padding-top: 14px;
  padding-left: 19px;
  padding-right: 19px;
`;

const Padding = styled.div`
  padding-left: 19px;
  padding-right: 19px;
`;

const MapUiNormal: React.FC<Props> = ({
  onClose,
  imgRatio,
  imgSrc,
  points,
  onSelectPoint,
  selectedPoint,
  activeRegion,
  showCard,
  onTeleport,
  title,
  description,
}) => {
  const theme = useTheme();

  return (
    <Wrapper $maxWidth={"1280px"} $minHorizontalPadding="0px">
      <RatioWrapper $ratio={imgRatio}>
        <PanelContent>
          <Glass padding="0" width="85%" height="85%" blur="none">
            <Image
              src={imgSrc}
              styleOverrides={{
                zIndex: 0,
                borderRadius: theme.radiusBig,
              }}
            />
            <MapDescriptionWrapper>
              <Spatial rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
                <Card
                  width="368px"
                  style={{
                    boxShadow: "-25px -15px 25px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Body>
                    <TitleWrapper>
                      <Typo.Title size="18px" lineHeight="24px">
                        {title}
                      </Typo.Title>
                    </TitleWrapper>
                    <Space h={2} />
                    <Padding>
                      <Markdown content={description} textAlign="left" />
                      <Space h={4} />
                    </Padding>
                  </Body>
                </Card>
              </Spatial>
            </MapDescriptionWrapper>
            <CardWrapper>
              <MapCardUiNormal
                selectedPoint={selectedPoint}
                show={showCard}
                isCurrentLocation={selectedPoint.regionId === activeRegion}
                onTeleport={onTeleport}
              />
            </CardWrapper>
            <CloseButtonWrapper>
              <CloseButton isGlass onClick={onClose} />
            </CloseButtonWrapper>
            <PointsWrapper>
              {points.map((point) => (
                <MapPointUi
                  key={point.name}
                  point={point}
                  selectedPoint={selectedPoint}
                  activeRegion={activeRegion}
                  onSelectPoint={onSelectPoint}
                  onTeleport={onTeleport}
                />
              ))}
            </PointsWrapper>
          </Glass>
        </PanelContent>
      </RatioWrapper>
    </Wrapper>
  );
};

export default MapUiNormal;
