import { AnalyticsPlugin } from "analytics";
import { AnalyticsMessage } from "./messages/analyticsMessages";

export function logemonPlugin(config: {
  environmentId: string;
  visitorToken?: string;
}): AnalyticsPlugin {
  let visitorToken = "unknown";

  return {
    name: "logemon",
    identify: ({ payload }: { payload: { options?: { token?: string } } }) => {
      if (payload.options?.token) visitorToken = payload.options.token;
    },
    track: async ({
      payload: { properties },
    }: {
      payload: { properties: AnalyticsMessage };
    }) => {
      await fetch(
        `${import.meta.env.VITE_LOGEMON_URL}/${config.environmentId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${visitorToken}`,
            "Content-Type": "application/json",
            "X-Forwarded-Host": window.location?.host || "",
          },
          body: JSON.stringify({
            ...properties,
            id: crypto.randomUUID(),
            screen: `${window.screen?.width}x${window.screen?.height}`,
            createdAt: Date.now(),
          }),
        }
      );
    },
    loaded: () => {
      return true;
    },
  };
}
