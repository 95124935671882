import { useEffect, useRef, useState } from "react";

const useVideoStream = (activeScreenShare: MediaStream | null) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentStream, setCurrentStream] = useState<MediaStream | null>(null);

  const track = activeScreenShare?.getVideoTracks()[0];
  const isLoading = !currentStream && Boolean(activeScreenShare);
  useEffect(() => {
    if (!activeScreenShare || !track) {
      return;
    }
    if (
      activeScreenShare.id !== currentStream?.id ||
      activeScreenShare.active !== currentStream?.active
    ) {
      setCurrentStream(activeScreenShare);
    }
  }, [currentStream?.id, currentStream?.active, track, activeScreenShare]);

  useEffect(() => {
    if (videoRef?.current && currentStream) {
      videoRef.current.srcObject = currentStream;
    }
  }, [currentStream]);

  return { videoRef, isLoading };
};

export default useVideoStream;
