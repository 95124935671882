/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment */
import {
  Publisher,
  Session,
  Subscriber,
  setAudioOutputDevice,
} from "@opentok/client";
import * as OT from "@opentok/client";
import * as Sentry from "@sentry/react";
import {
  ActorRefFrom,
  assign,
  enqueueActions,
  fromCallback,
  fromPromise,
  sendTo,
  setup,
  stopChild,
} from "xstate";
import { ConferenceError } from "../../../hooks/useConferenceErrors";
import { log, logError, logInfo } from "../../../lib/logger";
import { useStore } from "../../../store/store";
import { Errors } from "../../tracking/errors";
import {
  VideoConferenceState,
  VideoConferenceStateType,
} from "../VideoConferenceAdapter";
import { cameraCheckerMachine } from "./cameraChecker.machine";
import cameraSelectorMachine from "./cameraSelector.machine";
import lobbyStateMachine from "./lobby.machine";
import mediaDevicesPublisherMachine from "./mediaDevicesPublisher.machine";
import peerParticipantMachine from "./peerParticipant.machine";
import { screenShareMachineActor } from "./screenShare.machine";
import {
  ConferenceLocalParticipantJoinedEvent,
  LugiaSetSessionEvent,
  VonageStateMachineEvents,
} from "./types";
import { getExternalId, getParticipantData } from "./util";

export function cleanUnusedConference(
  session: Session | null,
  publisher: Publisher | null,
  participants: (Subscriber | null)[]
) {
  // Vonage doesn't clean after it self, so we have to manually kill all machines
  for (const subscriber of Object.values(participants)) {
    if (!subscriber) continue;
    subscriber.off("audioLevelUpdated");
    subscriber.off("videoElementCreated");
    session?.unsubscribe(subscriber);
  }

  publisher?.off("audioLevelUpdated");
  publisher?.off("videoElementCreated");
  OT.off("exception");
  if (session) {
    publisher && session.unpublish(publisher);

    publisher?.destroy();
    session.off("connectionCreated");
    session.off("connectionDestroyed");
    session.off("streamCreated");
    session.off("streamPropertyChanged");
    session.off("streamDestroyed");
    session.off("sessionDisconnected");
    session.disconnect();
  }
}

export type VonageSessionResponse = { token: string; sessionId: string };

export const HandledErrors = {
  OT_NO_DEVICES_FOUND: "OT_NO_DEVICES_FOUND",
  OT_STREAM_DESTROYED: "OT_STREAM_DESTROYED",
  OT_UNABLE_TO_SUBSCRIBE: "OT_UNABLE_TO_SUBSCRIBE",
} as const;

const vonageVideoConferenceStateMachine = setup({
  types: {} as {
    context: {
      participants: Record<string, ActorRefFrom<typeof peerParticipantMachine>>;
      session: Session | null;
      publisher: Publisher | null;
      token: string | null;
      sessionId: string | null;
      cameraCheckerRef: ActorRefFrom<typeof cameraCheckerMachine> | null;
      cameraSelectorRef: any | null;
      conferenceErrors: ConferenceError[];
      joinConferencePressed: boolean;
      cameraSetupComplete: boolean;
      microphoneSetupComplete: boolean;
      speakerSetupComplete: boolean;
      permissionsSetupComplete: boolean;
      environmentId: string;
      userId: string;
      visitorToken: string;
      audioInputDevices: MediaDeviceInfo[];
      audioOutputDevices: MediaDeviceInfo[];
      videoInputDevices: MediaDeviceInfo[];
      selectedAudioInputDevice: MediaDeviceInfo | null;
      selectedAudioOutputDevice: MediaDeviceInfo | null;
      selectedVideoInputDevice: MediaDeviceInfo | null;
      mediaPermissions: { camera: boolean; microphone: boolean };
    };
    events: VonageStateMachineEvents;
  },
  actors: {
    conferenceSetup: lobbyStateMachine,
    publishMediaDevices: mediaDevicesPublisherMachine,
    cameraSelector: fromPromise<
      void,
      { publisher: Publisher | null; videoInputSource: MediaDeviceInfo }
    >(async ({ input }) => {
      // TODO handle further errors
      await input.publisher?.setVideoSource(input.videoInputSource?.deviceId);
    }),
    getActiveOuputDevice: fromPromise<
      void,
      { audioOuputDevices: MediaDeviceInfo[] }
    >(async ({ input }) => {
      const audioOutputDevice = await OT.getActiveAudioOutputDevice();

      const audioSelectedDevice = input.audioOuputDevices.find(
        (device) => device.deviceId === audioOutputDevice.deviceId
      );

      if (audioSelectedDevice) {
        useStore.getState().userMedia.setSelectedCamera(audioSelectedDevice);
      }
    }),
  },
  actions: {
    destroyPublisher: assign(({ context }) => {
      logInfo("VOICE/VIDEO", "Destroying publisher");
      const { session, publisher } = context;
      publisher?.off("audioLevelUpdated");
      publisher?.off("videoElementCreated");
      if (session) {
        publisher && session.unpublish(publisher);
      }
      publisher?.destroy();

      return {
        ...context,
        publisher: null,
      };
    }),
    setJoinConferencePressed: () => {
      assign({
        joinConferencePressed: true,
      });
    },
    forwardStreamEvent: ({ event, context }) => {
      if (
        event.type === "conference.streamCreated" ||
        event.type === "conference.streamDestroyed"
      ) {
        const eventData = event.data;
        switch (event.data.stream.videoType) {
          case "screen": {
            screenShareMachineActor.send({
              type: "conference.remoteScreensharingStarted",
              data: eventData,
            });
            break;
          }
          case "camera":
            {
              const userId = getExternalId(eventData.stream.connection.data);
              const peerParticipantMachine = context.participants[userId];
              if (peerParticipantMachine) {
                peerParticipantMachine.send({
                  type: event.type,
                  data: eventData,
                });
              }
            }
            break;
          // Were catching here the visitors who join without a camera
          default:
            if (event.data.stream.streamId) {
              const userId = getExternalId(eventData.stream.connection.data);
              const peerParticipantMachine = context.participants[userId];
              if (peerParticipantMachine) {
                peerParticipantMachine.send({
                  type: event.type,
                  data: eventData,
                });
              }
            }
            break;
        }
      }
    },
    selectAvailableCamera: assign(({ context, event, spawn }) => {
      if (event.type === "lugia.selectCamera") {
        const videoInputSource = context.videoInputDevices.find(
          (device) => device.deviceId === event.data.deviceId
        );
        if (videoInputSource) {
          spawn("cameraSelector", {
            id: "cameraSelector",
            systemId: "cameraSelector",
            input: {
              publisher: context.publisher,
              videoInputSource: videoInputSource,
            },
          });
        }
      }

      return context;
    }),
    selectCamera: assign(({ context, event, self, spawn }) => {
      if (event.type === "lugia.selectCamera") {
        const snapshot = self.getSnapshot();
        const videoInputSource = context.videoInputDevices.find(
          (device) => device.deviceId === event.data.deviceId
        );
        if (!snapshot.matches("ConnectedToConference") && videoInputSource) {
          return {
            selectedVideoInputDevice: videoInputSource,
          };
        } else if (!videoInputSource) {
          logError(
            "VOICE/VIDEO",
            "Error selecting camera",
            event.data.deviceId
          );
          return context;
        }
        context.cameraCheckerRef?.send({
          type: "lugia.stopCheckingForCamera",
        });
        stopChild(context.cameraSelectorRef);
        if (!videoInputSource) {
          logError(
            "VOICE/VIDEO",
            "Error selecting camera",
            event.data.deviceId
          );
          return context;
        }

        const machineRef = spawn(cameraSelectorMachine, {
          input: {
            rootActor: self,
            cameraCheckerRef: context.cameraCheckerRef,
            publisher: context.publisher,
            videoInputSource: videoInputSource,
            videoInputDevices: context.videoInputDevices,
            mediaPermissions: context.mediaPermissions,
          },
        });
        return {
          ...context,
          cameraSelectorMachine: machineRef,
          selectedVideoInputDevice: videoInputSource,
        };
      }
      return context;
    }),
    selectSpeaker: ({ event }) => {
      if (event.type === "lugia.selectSpeaker") {
        const deviceId = event.data.deviceId;
        setAudioOutputDevice(deviceId as string);
      }
    },
    selectMicrophone: ({ context, event }) => {
      if (event.type === "lugia.selectMicrophone") {
        return context.publisher?.setAudioSource(event.data.deviceId);
      }
    },
    deviceListChanged: assign(({ context, event }) => {
      if (event.type === "lugia.deviceListChanged") {
        const { audioInputDevices, audioOutputDevices, videoInputDevices } =
          event.data;
        return {
          ...context,
          audioInputDevices,
          audioOutputDevices,
          videoInputDevices,
        };
      }
      return context;
    }),
    deviceListChangedInConference: enqueueActions(
      ({ event, context, self, enqueue }) => {
        if (event.type === "lugia.deviceListChanged") {
          const { videoInputDevices, audioInputDevices } = event.data;
          const storeVideoInputDevices =
            useStore.getState().userMedia.videoInputDevices.length;
          const storeAudioInputDevices =
            useStore.getState().userMedia.audioInputDevices.length;

          if (videoInputDevices.length != storeVideoInputDevices) {
            const availableCamera = videoInputDevices?.find(
              (device) =>
                device.deviceId === event.data.selectedCamera?.deviceId
            );
            enqueue.assign({
              selectedVideoInputDevice: availableCamera ?? null,
            });

            if (!availableCamera) {
              useStore.getState().userMedia.setWebcamMuted(true);
              enqueue.raise({
                type: "lugia.stopCamera",
              });
            }
          }

          if (audioInputDevices.length != storeAudioInputDevices) {
            const availabeMicrophone = audioInputDevices?.find(
              (device) =>
                device.deviceId === event.data.selectedMicrophone?.deviceId
            );
            enqueue.assign({
              selectedAudioInputDevice: availabeMicrophone ?? null,
            });

            if (!availabeMicrophone) {
              useStore.getState().userMedia.setMicMuted(true);
              useStore
                .getState()
                .videoConference.setParticipantSpeaking(context.userId, false);
              enqueue.raise({
                type: "lugia.stopMicrophone",
              });
            }
          }

          if (
            videoInputDevices.length != storeVideoInputDevices ||
            audioInputDevices.length != storeAudioInputDevices
          ) {
            enqueue(() => {
              self.send({
                type: "conference.republishDevices",
              });
            });
          }
        }
      }
    ),
    destroyConference: assign(({ context }) => {
      const { session, publisher, participants } = context;

      const subscriberSnapshots = Object.values(participants).map(
        (sub) => sub.getSnapshot().context.subscriber
      );
      cleanUnusedConference(session, publisher, subscriberSnapshots);
      context.cameraCheckerRef && stopChild(context.cameraCheckerRef);
      context.cameraSelectorRef && stopChild(context.cameraSelectorRef);
      Object.values(participants).forEach((participant) => {
        stopChild(participant);
      });
      useStore.getState().videoConference.cleanConferenceState();
      return {
        publisher: null,
        session: null,
        participants: {},
        cameraCheckerRef: null,
        cameraSelectorRef: null,
        joinConferencePressed: false,
      };
    }),
    publishErrorHandler: enqueueActions(({ context, enqueue, event }) => {
      if (event.type === "conference.publisherError") {
        enqueue(() => {
          context.cameraCheckerRef?.send({
            type: "lugia.startCheckingForCamera",
            data: {
              videoInputSource: context.selectedVideoInputDevice,
              videoInputDevices: context.videoInputDevices,
              mediaPermissions: context.mediaPermissions,
            },
          });
        });
        enqueue.assign({
          conferenceErrors: () => ["cameraFeedError"],
        });
        // I can't figure out why ts is not happy with this line
        enqueue("STORE_setCameraErrors");
      }
    }),
    startCamera: ({ context, self }) => {
      context.publisher?.publishVideo(true, (error) => {
        if (error) {
          self.send({ type: "conference.publisherError", data: error });
        }
      });
    },
    stopCamera: ({ context }) => {
      context.publisher?.publishVideo(false);
    },
    forwardEventToPeerParticipant: ({ event, context }) => {
      if (
        event.type === "lugia.startParticipantVideo" ||
        event.type === "lugia.stopParticipantVideo"
      ) {
        const userId = event.data.participantId;
        const peerParticipantMachine = context.participants[userId];
        if (peerParticipantMachine) {
          peerParticipantMachine.send(event);
        }
      }
    },
    stopMicrophone: ({ context }) => {
      context.publisher?.publishAudio(false);
    },
    startMicrophone: ({ context }) => {
      context.publisher?.publishAudio(true);
    },
    spawnParticipant: assign({
      participants: ({ context, spawn, event, self }) => {
        if (event.type === "conference.participantConnected") {
          const eventData = event.data as any;
          const userId = getExternalId(eventData.connection.data);
          const isLocal = userId === context.userId;
          if (!isLocal) {
            const session = context.session || event.data.target;
            const participantMachineRef = spawn(peerParticipantMachine, {
              id: userId,
              input: {
                parent: self,
                connection: eventData.connection,
                session,
                userId,
              },
            });
            return {
              ...context.participants,
              [userId]: participantMachineRef,
            };
          }
        }
        return context.participants;
      },
    }),
    removeParticipant: assign({
      participants: ({ context, event }) => {
        if (event.type === "conference.participantLeft") {
          const eventData = event.data;
          const userId = getExternalId(eventData.connection.data);
          const participants = { ...context.participants };
          const peerParticipantMachine = participants[userId];
          if (peerParticipantMachine) stopChild(peerParticipantMachine);
          delete participants[userId];
          return participants;
        }
        return context.participants;
      },
    }),
    sendEventToPeerParticipants: (
      { context },
      params //{ event: ConferenceLocalParticipantJoinedEvent }
    ) => {
      // TODO: figure out how to type this events
      Object.values(context.participants).forEach((participant) => {
        participant.send(
          (params as Record<"event", ConferenceLocalParticipantJoinedEvent>)
            ?.event
        );
      });
    },
    setupPermissions: assign(({ event, context }) => {
      if (event.type === "lugia.setupPermissions") {
        return {
          mediaPermissions: event.data,
        };
      }
      return context;
    }),
    setupDevices: assign(({ event, context }) => {
      if (event.type === "lugia.setupDevices") {
        return {
          audioInputDevices: event.data.audioInputDevices,
          audioOutputDevices: event.data.audioOutputDevices,
          videoInputDevices: event.data.videoInputDevices,
        };
      }
      return context;
    }),
    STORE_setUpdatedDevices: ({ event }) => {
      if (event.type === "lugia.deviceListChanged") {
        useStore
          .getState()
          .userMedia.setAudioInputDevices(event.data.audioInputDevices);
        useStore
          .getState()
          .userMedia.setAudioOutputDevices(event.data.audioOutputDevices);
        useStore
          .getState()
          .userMedia.setVideoInputDevices(event.data.videoInputDevices);
        useStore
          .getState()
          .userMedia.setSelectedMicrophone(event.data.selectedMicrophone);
        useStore
          .getState()
          .userMedia.setSelectedCamera(event.data.selectedCamera);
        useStore
          .getState()
          .userMedia.setSelectedSpeakers(event.data.selectedSpeaker);
      }
    },
    setupSelectedDevices: assign(({ event, context }) => {
      if (event.type === "lugia.setupSelectedDevices") {
        return {
          selectedAudioInputDevice: event.data.selectedAudioInputDevice,
          selectedAudioOutputDevice: event.data.selectedAudioOutputDevice,
          selectedVideoInputDevice: event.data.selectedVideoInputDevice,
        };
      }
      return context;
    }),
    STORE_setUpdatedSelectedDevices: ({ event }) => {
      if (event.type === "lugia.setupSelectedDevices") {
        useStore
          .getState()
          .userMedia.setSelectedMicrophone(event.data.selectedAudioInputDevice);
        useStore
          .getState()
          .userMedia.setSelectedCamera(event.data.selectedVideoInputDevice);
        useStore
          .getState()
          .userMedia.setSelectedSpeakers(event.data.selectedAudioOutputDevice);
      }
    },
    STORE_setSetupDevices: ({ event }) => {
      if (event.type === "lugia.setupDevices") {
        useStore
          .getState()
          .userMedia.setAudioInputDevices(event.data.audioInputDevices);
        useStore
          .getState()
          .userMedia.setAudioOutputDevices(event.data.audioOutputDevices);
        useStore
          .getState()
          .userMedia.setVideoInputDevices(event.data.videoInputDevices);
      }
    },
    STORE_setSetupPermissions: ({ event }) => {
      if (event.type === "lugia.setupPermissions") {
        useStore.getState().userMedia.setPermissions({
          camera: event.data.camera,
          microphone: event.data.microphone,
        });
      }
    },
    STORE_selectMicrophone: ({ event }) => {
      if (event.type === "lugia.selectMicrophone")
        useStore.getState().userMedia.setSelectedMicrophone(event.data);
      if (event.type === "lugia.setupMicrophone") {
        useStore
          .getState()
          .userMedia.setSelectedMicrophone(event.data.audioInputSource);
      }
    },
    STORE_selectCamera: ({ event }) => {
      if (event.type === "lugia.selectCamera")
        useStore.getState().userMedia.setSelectedCamera(event.data);
    },
    STORE_selectSpeaker: ({ event }) => {
      if (event.type === "lugia.selectSpeaker")
        useStore.getState().userMedia.setSelectedSpeakers(event.data);
      if (event.type === "lugia.setupSpeaker") {
        useStore
          .getState()
          .userMedia.setSelectedSpeakers(event.data.audioOutputSource);
      }
    },
    STORE_setConferenceState: (
      _,
      params // { state: VideoConferenceStateType }
    ) => {
      useStore
        .getState()
        .videoConference.setState(
          (params as Record<"state", VideoConferenceStateType>).state
        );
    },
    STORE_setCameraErrors: ({ context }) => {
      useStore
        .getState()
        .userMedia.setVideoCameraFeedError(
          context.conferenceErrors.includes("cameraFeedError")
        );
    },
    STORE_streamPropertyChanged: ({ event }) => {
      if (event.type === "conference.streamPropertyChanged") {
        const userId = getExternalId(event.data.stream.connection.data);
        if (!userId) return;
        switch (event.data.changedProperty) {
          case "hasAudio":
            useStore
              .getState()
              .videoConference.setUpdateParticipantStatus(userId, {
                isAudioOn: event.data.newValue,
              });
            break;
          case "hasVideo":
            useStore
              .getState()
              .videoConference.setUpdateParticipantStatus(userId, {
                isVideoOn: event.data.newValue,
              });
            break;
          default:
            break;
        }
      }
    },
    STORE_resetScreenSharing: ({ event }) => {
      if (event.type === "conference.participantLeft") {
        const eventData = event.data;
        const userId = getExternalId(eventData.connection.data);
        const currentPresenter =
          useStore.getState().videoConference.screenSharer;
        if (currentPresenter?.userId === userId) {
          useStore.getState().videoConference.setScreenSharer(null);
          useStore.getState().videoConference.setActiveScreenShare(null);
        }
      }
    },
    STORE_setParticipantLeave: ({ event }) => {
      if (event.type === "conference.participantLeft") {
        const eventData = event.data;
        const userId = getExternalId(eventData.connection.data);
        useStore.getState().videoConference.setParticipantLeave(userId);
      }
    },
    STORE_setParticipantJoin: ({ context, event }) => {
      if (event.type === "conference.participantConnected") {
        const eventData = event.data as any;

        const userId = getExternalId(eventData.connection.data);
        const isLocal = userId === context.userId;
        const participantData = getParticipantData(eventData, isLocal);
        useStore.getState().videoConference.setParticipantJoin(participantData);
      }
    },
    STORE_setLocalParticipant: ({ event, context }) => {
      if (event.type === "conference.localVideoStreamReady") {
        const userId = context.userId;
        const eventData = event.data as any;
        if (!userId) return;
        const videoElement = eventData.element as HTMLVideoElement;
        const videoStream = videoElement.srcObject as MediaStream;
        const hasVideoTrack = videoStream?.getVideoTracks().length > 0;

        const isMicMuted = useStore.getState().userMedia.micMuted;
        const isCamMuted = useStore.getState().userMedia.webcamMuted;

        useStore.getState().videoConference.setUpdateParticipantStatus(userId, {
          streams: [videoStream] as MediaStream[],
          isPublishing: true,
          isAudioOn: !isMicMuted,
          isVideoOn: hasVideoTrack && !isCamMuted,
        });
        videoElement.onplay = () => {
          if (videoStream !== videoElement.srcObject) {
            const videoStream = videoElement.srcObject as MediaStream;
            const hasVideoTrack = videoStream?.getVideoTracks().length > 0;
            useStore
              .getState()
              .videoConference.setUpdateParticipantStatus(userId, {
                streams: [videoStream] as MediaStream[],
                isPublishing: true,
                isAudioOn: !isMicMuted,
                isVideoOn: hasVideoTrack,
              });
          }
        };
      }
    },
    STORE_openAvatarsPanel: () => {
      useStore.getState().layout.openPanel("videoAvatars");
    },
    logInfo: ({ event }) => {
      log("VOICE/VIDEO", event);
    },
    logError: ({ event, context }, params) => {
      Sentry.captureException(new Error(Errors.VIDEO_CONFERENCE_ERROR), {
        extra: {
          event,
          userId: context.userId,
          sessionId: context.sessionId,
        },
      });
      logError(
        "VOICE/VIDEO",
        `Error in video conferencing flow. Reason: ${(params as Record<"reason", string>).reason}`,
        event
      );
    },
    setSession: assign(({ event, context }, params) => {
      if (event.type === "lugia.setSession") {
        return {
          session: (params as LugiaSetSessionEvent).data.session,
        };
      }
      return context;
    }),
  },
}).createMachine({
  id: "VonageVideoConferenceStateMachine",
  initial: "Disconnected",
  exit: "destroyConference",
  invoke: {
    input: ({ context }) => context,
    src: fromCallback(({ input, sendBack }) => {
      OT.on("exception", (event) => {
        logError("VOICE/VIDEO", "Exception in Vonage", event);
        // @ts-ignore
        if (Object.values(HandledErrors).includes(event?.error?.name)) return;
        sendBack({
          type: "conference.genericError",
          data: event,
        });
        Sentry.captureException(new Error(Errors.VIDEO_CONFERENCE_ERROR), {
          extra: {
            event,
            userId: input.userId,
            sessionId: input.sessionId,
          },
        });
      });
    }),
  },
  context: ({ spawn, self }) => ({
    joinConferencePressed: false,
    cameraSetupComplete: false,
    microphoneSetupComplete: false,
    speakerSetupComplete: false,
    permissionsSetupComplete: false,
    participants: {},
    session: null,
    publisher: null,
    cameraCheckerRef: spawn(cameraCheckerMachine, {
      systemId: "cameraCheckerServiceMachine",
      input: {
        rootActor: self,
      },
    }),
    cameraSelectorRef: null,
    visitorToken: "",
    token: "",
    sessionId: "",
    environmentId: "",
    userId: "",
    conferenceErrors: [],
    audioInputDevices: [],
    audioOutputDevices: [],
    videoInputDevices: [],
    selectedAudioInputDevice: null,
    selectedAudioOutputDevice: null,
    selectedVideoInputDevice: null,
    mediaPermissions: { camera: false, microphone: false },
  }),

  on: {
    "conference.updateConferenceState": {
      actions: [
        {
          type: "STORE_setConferenceState",
          // @ts-ignore event is comming from the lobby machine defined as VideoConferenceType
          params: ({ event }) => ({
            state: event.data,
          }),
        },
      ],
    },
    "lugia.destroyConference": {
      target: "#VonageVideoConferenceStateMachine.Destroyed",
    },
    "conference.genericError": {
      target: "#VonageVideoConferenceStateMachine.VideoConferenceError",
      actions: {
        type: "logError",
        params: { reason: "Global Exception in Vonage conference" },
      },
    },
    "lugia.setupDevices": {
      actions: ["setupDevices", "STORE_setSetupDevices"],
    },
    "lugia.setupPermissions": {
      actions: ["setupPermissions", "STORE_setSetupPermissions"],
    },
    "lugia.setupSelectedDevices": {
      actions: ["setupSelectedDevices", "STORE_setUpdatedSelectedDevices"],
    },
    "conference.participantConnected": {
      actions: ["logInfo", "spawnParticipant", "STORE_setParticipantJoin"],
    },
    "conference.participantLeft": {
      actions: [
        "removeParticipant",
        "STORE_setParticipantLeave",
        "STORE_resetScreenSharing",
      ],
    },
    "conference.streamCreated": {
      actions: "forwardStreamEvent",
    },
    "conference.streamDestroyed": {
      actions: "forwardStreamEvent",
    },
    "lugia.setSession": {
      actions: {
        type: "setSession",
        // @ts-ignore event is comming from the lobby machine defined as session Event
        params: ({ event }) => ({ data: event.data }),
      },
    },
  },
  states: {
    hist: {
      type: "history",
      history: "shallow",
    },
    Disconnected: {
      entry: [
        {
          type: "STORE_setConferenceState",
          params: { state: VideoConferenceState.DISCONNECTED },
        },
      ],
      id: "Disconnected",
      on: {
        "lugia.joinConference": {
          actions: ["setJoinConferencePressed"],
          target: "Lobby",
        },
        "lugia.InitSession": {
          target: "Lobby",
          actions: assign({
            environmentId: ({ event }) => event.data.environmentId,
            userId: ({ event }) => event.data.userId,
            visitorToken: ({ event }) => event.data.visitorToken,
          }),
        },
      },
    },
    Lobby: {
      on: {
        "lugia.selectCamera": {
          actions: ["selectCamera", "STORE_selectCamera"],
        },
        "lugia.selectSpeaker": {
          actions: ["selectSpeaker", "STORE_selectSpeaker"],
        },
        "lugia.selectMicrophone": {
          actions: ["selectMicrophone", "STORE_selectMicrophone"],
        },
        "conference.localVideoStreamReady": {
          actions: ["STORE_setLocalParticipant"],
        },
        "conference.participantConnected": {
          actions: ["logInfo", "spawnParticipant", "STORE_setParticipantJoin"],
        },
        "conference.streamPropertyChanged": {
          actions: "STORE_streamPropertyChanged",
        },
        "lugia.deviceListChanged": {
          actions: ["STORE_setUpdatedDevices"],
        },
      },
      invoke: {
        id: "conferenceSetup",
        src: "conferenceSetup",
        input: ({ self, context }) => ({
          rootActor: self,
          environmentId: context.environmentId,
          userId: context.userId,
          visitorToken: context.visitorToken,
        }),
        onDone: {
          guard: ({ event }) => Boolean(event.output.publisher),
          target:
            "#VonageVideoConferenceStateMachine.ConnectedToConference.Initial",
          actions: assign(({ event }) => {
            return {
              publisher: event.output.publisher,
            };
          }),
        },
        onError: {
          target: "VideoConferenceError",
          actions: [
            {
              type: "logError",
              params: { reason: "Error initializing session" },
            },
          ],
        },
      },
      always: {
        actions: sendTo("conferenceSetup", ({ event }) => event),
      },
    },
    ConnectedToConference: {
      hist: {
        type: "history",
        history: "deep",
      },
      entry: [
        {
          type: "STORE_setConferenceState",
          params: { state: VideoConferenceState.JOINED },
        },
        {
          type: "sendEventToPeerParticipants",
          params: {
            event: {
              type: "conference.localParticipantJoined",
            },
          },
        },
        {
          type: "STORE_openAvatarsPanel",
        },
      ],
      initial: "Initial",
      states: {
        Initial: {
          on: {
            "conference.republishDevices": {
              target: "RepublishingDevices",
              actions: "destroyPublisher",
            },
          },
        },
        ListenerMode: {},
        RepublishingDevices: {
          invoke: {
            id: "republishDevices",
            input: ({ context, self }) => ({
              session: context.session!,
              rootActor: self,
              videoInputSource: context.selectedVideoInputDevice,
              audioInputSource: context.selectedAudioInputDevice,
              videoInputDevices: context.videoInputDevices,
              audioInputDevices: context.audioInputDevices,
              mediaPermissions: context.mediaPermissions,
            }),
            src: "publishMediaDevices",
            onDone: {
              target: "Initial",
              actions: [
                // TODO Make this a defined action
                enqueueActions(({ enqueue, event, context }) => {
                  enqueue.assign({
                    publisher: event.output.publisher ?? null,
                    conferenceErrors: ({ event }) =>
                      event.output.conferenceErrors,
                  });

                  if (event.output.publisher) {
                    const videoMediaDeviceInput =
                      event.output.publisher?.getVideoSource();
                    const audioMediaStreamTrack =
                      event.output.publisher?.getAudioSource();

                    const isMicAvaialble = useStore
                      .getState()
                      .userMedia.audioInputDevices.find((device) => {
                        const mediaStreamtrackSettings =
                          audioMediaStreamTrack?.getSettings();

                        return (
                          device.deviceId == mediaStreamtrackSettings?.deviceId
                        );
                      });

                    const isCamAvaialble = useStore
                      .getState()
                      .userMedia.videoInputDevices.find(
                        (device) =>
                          device.deviceId == videoMediaDeviceInput?.deviceId
                      );

                    if (isCamAvaialble) {
                      enqueue.assign({
                        selectedVideoInputDevice: isCamAvaialble,
                      });
                      useStore
                        .getState()
                        .userMedia.setSelectedCamera(isCamAvaialble);
                    }

                    if (isMicAvaialble) {
                      enqueue.assign({
                        selectedAudioInputDevice: isMicAvaialble,
                      });
                      useStore
                        .getState()
                        .userMedia.setSelectedMicrophone(isMicAvaialble);
                    }
                    enqueue.spawnChild("getActiveOuputDevice", {
                      id: "getActiveOutputDevice",
                      input: {
                        audioOuputDevices: context.audioOutputDevices,
                      },
                    });
                  }

                  enqueue("STORE_setCameraErrors");
                }),
              ],
            },
          },
        },
      },
      on: {
        "lugia.selectCamera": {
          actions: ["selectAvailableCamera", "STORE_selectCamera"],
        },
        "lugia.selectSpeaker": {
          actions: ["selectSpeaker", "STORE_selectSpeaker"],
        },
        "lugia.selectMicrophone": {
          actions: ["selectMicrophone", "STORE_selectMicrophone"],
        },
        "lugia.deviceListChanged": {
          actions: [
            "deviceListChanged",
            "deviceListChangedInConference",
            "STORE_setUpdatedDevices",
          ],
        },
        "lugia.startParticipantVideo": {
          actions: "forwardEventToPeerParticipant",
        },
        "lugia.stopParticipantVideo": {
          actions: "forwardEventToPeerParticipant",
        },
        "lugia.stopCamera": {
          actions: "stopCamera",
        },
        "lugia.startCamera": {
          actions: "startCamera",
        },
        "lugia.stopMicrophone": {
          actions: "stopMicrophone",
        },
        "lugia.startMicrophone": {
          actions: "startMicrophone",
        },
        "conference.publisherError": {
          actions: "publishErrorHandler",
        },
        "conference.streamPropertyChanged": {
          actions: "STORE_streamPropertyChanged",
        },
        "lugia.leaveConference": {
          target: "#VonageVideoConferenceStateMachine.Disconnected",
          actions: "destroyConference",
        },
        "conference.participantLeft": {
          actions: ["removeParticipant", "STORE_setParticipantLeave"],
        },
        "conference.participantConnected": {
          actions: ["logInfo", "spawnParticipant", "STORE_setParticipantJoin"],
        },
        "conference.streamCreated": {
          actions: "forwardStreamEvent",
        },
        "conference.localVideoStreamReady": {
          actions: "STORE_setLocalParticipant",
        },
      },
    },
    Destroyed: {
      entry: "destroyConference",
      type: "final",
    },
    BlockedPermissions: {
      type: "final",
      entry: [
        {
          type: "logError",
          params: { reason: "Blocked permissions" },
        },
        "destroyConference",
        {
          type: "STORE_setConferenceState",
          params: { state: VideoConferenceState.BLOCKED_PERMISSIONS },
        },
      ],
    },
    VideoConferenceError: {
      description:
        "This state is reached when an error occurs in the video conference. After a second, it will go into a Lobby state.",
      entry: [
        "destroyConference",
        {
          type: "STORE_setConferenceState",
          params: { state: VideoConferenceState.ERROR },
        },
      ],
      after: {
        1000: {
          target: "Lobby",
        },
      },
    },
  },
});

export default vonageVideoConferenceStateMachine;
