import { SvgProps } from "../../types/svg";

const ControllerIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3775 7.9375C13.8253 7.9375 13.4 8.35727 13.4 8.875C13.4 9.39273 13.8478 9.79141 14.3775 9.79141C14.9298 9.79141 15.355 9.37164 15.355 8.875C15.3775 8.35703 14.9525 7.9375 14.3775 7.9375ZM14.3775 9.4375C14.0468 9.4375 13.8 9.18508 13.8 8.875C13.8 8.56492 14.0693 8.3125 14.3775 8.3125C14.6858 8.3125 14.955 8.56492 14.955 8.875C14.955 9.18508 14.73 9.4375 14.3775 9.4375ZM7.5775 9.8125H6.1775V8.5C6.1775 8.39673 6.0877 8.3125 5.97755 8.3125C5.8674 8.3125 5.7776 8.39673 5.7776 8.5V9.79141H4.3995C4.28935 9.79141 4.1995 9.87564 4.1995 9.97891C4.1995 10.0822 4.2895 10.1875 4.3995 10.1875H5.79925V11.5C5.79925 11.6033 5.88905 11.6875 5.9992 11.6875C6.10935 11.6875 6.19915 11.6033 6.19915 11.5V10.1875H7.5989C7.70905 10.1875 7.7989 10.1034 7.7989 10.0001C7.7989 9.89683 7.71 9.8125 7.5775 9.8125ZM12.7775 10.1875C12.2253 10.1875 11.8 10.6073 11.8 11.125C11.8 11.6427 12.2478 12.0414 12.7775 12.0414C13.3073 12.0414 13.755 11.6216 13.755 11.125C13.755 10.6284 13.3525 10.1875 12.7775 10.1875ZM12.7775 11.6875C12.4468 11.6875 12.2 11.4351 12.2 11.125C12.2 10.8149 12.4693 10.5625 12.7775 10.5625C13.1083 10.5625 13.355 10.8149 13.355 11.125C13.355 11.4351 13.13 11.6875 12.7775 11.6875ZM13.2 5.5H6.8C4.149 5.5 2 7.49453 2 10C2 12.5055 4.149 14.5 6.8 14.5H13.2C15.85 14.5 18 12.4853 18 10C18 7.51469 15.85 5.5 13.2 5.5ZM13.2 14.125H6.8C4.37375 14.125 2.4 12.2746 2.4 10C2.4 7.72539 4.37375 5.875 6.8 5.875H13.2C15.6263 5.875 17.6 7.72539 17.6 10C17.6 12.2746 15.625 14.125 13.2 14.125Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ControllerIcon;
