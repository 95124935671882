export const shiftArray = <T = unknown>(array: T[], shift: number) => {
  if (shift === 0) {
    return array;
  }

  const shiftedArray = [...array];

  if (shiftedArray.length === 0) {
    return shiftedArray;
  }

  if (shift > 0) {
    for (let i = 0; i < shift; i++) {
      shiftedArray.unshift(shiftedArray.pop() as T);
    }
  } else {
    for (let i = 0; i < Math.abs(shift); i++) {
      shiftedArray.push(shiftedArray.shift() as T);
    }
  }

  return shiftedArray;
};

/**
 * Returns a frame of an array, starting at the given index and with the given length.
 * @param array The array to get the frame from.
 * @param startIndex The index to start the frame at.
 * @param frameLength  The length of the frame.
 * @param repeat Whether to repeat the array if the frame is longer than the array.
 * @returns The frame of the array.
 */
export const getArrayFrame = <T = unknown>(
  array: T[],
  startIndex: number,
  frameLength: number,
  repeat = false
) => {
  if (array.length === 0) return [];
  const frame = array.slice(startIndex, startIndex + frameLength);

  let lastSlideIndex = startIndex + frame.length;
  while (frame.length < frameLength) {
    if (!repeat) {
      frame.push(undefined as unknown as T);
      continue;
    }
    lastSlideIndex = lastSlideIndex % array.length;
    frame.push(array[lastSlideIndex]);
    lastSlideIndex++;
  }

  return frame;
};

export const reverseArray = <T = unknown>(array: T[]) => {
  const reversedArray = [...array];
  reversedArray.reverse();
  return reversedArray;
};

export const randomArrayElement = <T = unknown>(array: T[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

/**
 * Chunks an array of objects into groups based on the value of a given key.
 */
export const groupArrayBy = <
  T extends { [key in K]?: string } & Record<string, unknown>,
  K extends string,
>(
  array: T[],
  keyOfGroupingValue: K
) => {
  const groups = {} as Record<string, T[]>;
  array.forEach((it) => {
    const groupingValue = it[keyOfGroupingValue] as string;
    if (groupingValue) {
      if (!groups[groupingValue]) groups[groupingValue] = [];
      groups[groupingValue].push(it);
    }
  });

  const list = [] as T[][];
  for (const key in groups) {
    list.push(groups[key]);
  }
  return list;
};

export function scrambleArray<T>(array: T[]): T[] {
  return [...array.sort(() => Math.random() - 0.5)];
}
