import { SvgProps } from "../../types/svg";

const SadCryFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 17.4187C12.075 17.7937 11.0594 18 10 18C8.94062 18 7.925 17.7937 7 17.4187V11C7 10.725 6.775 10.5 6.5 10.5C6.225 10.5 6 10.725 6 11V16.9312C3.60875 15.5469 2 12.9625 2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10C18 12.9625 16.3906 15.5469 14 16.9312V11C14 10.725 13.775 10.5 13.5 10.5C13.225 10.5 13 10.725 13 11V17.4187ZM8.8 9.4C9.02188 9.23438 9.06563 8.92187 8.9 8.7C7.95 7.43437 6.05 7.43437 5.1 8.7C4.93437 8.92187 4.97906 9.23438 5.2 9.4C5.42188 9.56563 5.73438 9.52188 5.9 9.3C6.45 8.56563 7.55 8.56563 8.1 9.3C8.26562 9.52188 8.57813 9.56563 8.8 9.4ZM11.9 9.3C12.45 8.56563 13.55 8.56563 14.1 9.3C14.2656 9.52188 14.5781 9.56563 14.8 9.4C15.0219 9.23438 15.0656 8.92187 14.9 8.7C13.95 7.43437 12.05 7.43437 11.1 8.7C10.9344 8.92187 10.9781 9.23438 11.2 9.4C11.4219 9.56563 11.7344 9.52188 11.9 9.3ZM8.5 13.5C8.5 14.3281 9.17188 15 10 15C10.8281 15 11.5 14.3281 11.5 13.5V12.5C11.5 11.6719 10.8281 11 10 11C9.17188 11 8.5 11.6719 8.5 12.5V13.5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default SadCryFilledIcon;
