import { useCallback, useEffect, useRef, useState } from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";
import cuid from "cuid";
import { css, styled } from "styled-components";

export type TransitionStatus = "entering" | "entered" | "exiting";

type SimpleElement = ReactElement | null | undefined | string | false;

type Child = {
  element: SimpleElement;
  state: TransitionStatus;
  key: string;
};

const Wrapper = styled.div<{
  $speed: number;
  $grow?: boolean;
  $width?: React.CSSProperties["width"];
  $height?: React.CSSProperties["height"];
  $zIndex?: number;
}>`
  --transition-speed: ${(p) => p.$speed}ms;
  position: relative;
  width: ${(p) => p.$width ?? "100%"};
  height: ${(p) => p.$height ?? "100%"};

  ${(p) =>
    p.$zIndex &&
    css`
      z-index: ${p.$zIndex};
    `};
`;

export type SlideDir = "up" | "left" | "down" | "right";

const transformation: Record<SlideDir, string> = {
  up: "translate(0%, -100%)",
  down: "translate(0%, 100%)",
  left: "translate(-100%, 0%)",
  right: "translate(100%, 0%)",
};

const AnimatedChild = styled.div<{
  $visible: boolean;
  $speed: number;
  $fade?: boolean;
  $slide?: SlideDir;
  $justify: React.CSSProperties["justifyContent"];
  $align: React.CSSProperties["alignItems"];
}>`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;

  justify-content: ${(p) => p.$justify};
  align-items: ${(p) => p.$align};

  transition:
    opacity ${(p) => p.$speed}ms ease-in-out,
    transform ${(p) => p.$speed}ms ease-in-out;
  opacity: 0;
  opacity: ${(p) => (p.$fade && p.$visible ? 1 : 0)};

  ${(p) =>
    p.$slide &&
    css`
      transform: ${p.$visible ? "translate(0%, 0%)" : transformation[p.$slide]};
    `}
`;

export type Props = {
  children: SimpleElement;
  /** Whenever the values passed to watch will change, a transition will occour. */
  watch: unknown;
  noTransitionOnMount?: boolean;
  speed?: number;
  delay?: number;
  fade?: boolean;
  /** Add a slide effect. */
  slideIn?: SlideDir;
  slideOut?: SlideDir;
  width?: string;
  height: React.CSSProperties["height"];
  zIndex?: number;
  justify?: React.CSSProperties["justifyContent"];
  align?: React.CSSProperties["alignItems"];
  className?: string;
};

/** Transition its children when the "watch" prop changes.
 *  Because of this, a change in the children's props will not cause a rerender.
 *
 *  The children can rerender only if:
 *  - a transition has occurred.
 *  - they rerender without their props changing (internal state changes).
 *
 *  Generally the values that should trigger a transition (so included in watch)
 *  should only be consumed by the children through props (and not internally through the store).
 *
 *
 *  HOW THIS COMPONENT WORKS:
 *  Component has children which need to be transitioned.
 *  One of the watched properties changes -> setShouldTransition(true) -> swapChild(children)
 *  Calling the swapChild does the following:
 *  - Set the state of all children to "exiting" and add the new child with the state "entering".
 *  - After the exit animation is done, remove all children with the state "exiting".
 *  - After the exit animation is done, set the state of the new child to "entered".
 */
const Transition: React.FC<Props> = ({
  children,
  watch,
  speed = 300,
  delay = 0,
  fade = true,
  slideIn,
  slideOut,
  noTransitionOnMount,
  width,
  height,
  zIndex,
  justify = "center",
  align = "center",
  className,
}) => {
  const mounted = useRef(false);
  const oldWatch = useRef(noTransitionOnMount ? watch : undefined);
  const [shouldTransition, setShouldTransition] = useState<boolean>(false);
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const [childList, setChildList] = useState<Child[]>(
    noTransitionOnMount
      ? [
          {
            element: children,
            key: cuid(),
            state: "entered",
          },
        ]
      : []
  );

  const exitChildIdRef = useRef<number>();
  const enterChildIdRef = useRef<number>();

  const swapChild = useCallback(
    (newChild?: SimpleElement) => {
      const newKey = cuid();

      const exitChild = () =>
        setChildList((childList) =>
          childList.filter((child) => child.state !== "exiting")
        );

      const enterChild = () =>
        setChildList((childList) =>
          childList.map((child) => {
            // When the new child is "false" or "null", the list is not updated with "entering child" (Doesn't make sense to transition empty space)
            // So it will stay in the list with state "exited". So, we have to transition the children which are in state of "entering"
            if (child.key === newKey && child.state === "entering") {
              return { ...child, state: "entered" };
            }
            return child;
          })
        );

      setChildList((childList) => {
        clearTimeout(exitChildIdRef.current);
        clearTimeout(enterChildIdRef.current);
        /**
         * Delete all items that have the state "exiting" after waiting for
         * the exit animation to finish.
         */

        exitChildIdRef.current = window.setTimeout(exitChild, speed);

        /**
         * Wait at least 20ms before setting the new state to "entered". This is
         * to prevent the "entering" state from being skipped if the element is
         * not mounted yet.
         */

        enterChildIdRef.current = window.setTimeout(
          enterChild,
          Math.max(20, childList.length > 0 ? delay : 0)
        );

        /**
         * Set the state of all items to "exiting" and add the new item with
         * the state "entering".
         */

        const updatedChildList = [
          ...childList.map((child) => {
            child.state = "exiting";
            return child;
          }),
        ];
        if (!newChild) return updatedChildList;

        updatedChildList.push({
          key: newKey,
          state: "entering",
          element: newChild,
        });
        return updatedChildList;
      });
    },
    [delay, speed]
  );
  useEffect(() => {
    if (!wrapperRef) return;
    mounted.current = true;
  }, [wrapperRef]);

  // Listen for changes in watch and request a transition.
  useEffect(() => {
    if (!mounted.current && noTransitionOnMount) return;
    // This more precise comparison is necessary as watch could be an array or an object.
    if (JSON.stringify(oldWatch.current) !== JSON.stringify(watch)) {
      setShouldTransition(true);
      oldWatch.current = watch;
    }
  }, [noTransitionOnMount, watch]);

  // If watch has checnged apply transition.
  // If only children has changed instantly update the children without transition.
  useEffect(() => {
    if (!mounted.current && noTransitionOnMount) return;

    if (shouldTransition) {
      swapChild(children);
    }
    if (shouldTransition) setShouldTransition(false);
  }, [shouldTransition, noTransitionOnMount, swapChild, children]);

  return (
    <Wrapper
      ref={setWrapperRef}
      $speed={speed}
      $width={width}
      $height={height}
      $zIndex={zIndex}
      className={className}
    >
      {childList.map((child) => (
        <AnimatedChild
          key={child.key}
          className={"transition-" + child.state}
          $justify={justify}
          $align={align}
          $visible={child.state === "entered"}
          $fade={fade}
          $slide={child.state === "exiting" ? slideOut : slideIn}
          $speed={speed}
        >
          {child.element}
        </AnimatedChild>
      ))}
    </Wrapper>
  );
};

export default Transition;
