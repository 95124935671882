import { Vec } from "./Vectors";

type MouseFollowerConfig = {
  mouseEventSourceElement: HTMLElement | null;
  followerElement: HTMLElement | null;
  reactivity: number;
  centerMouseOnExit?: boolean;
};

const MAX_SPEED = 20;

export default class MouseFollower {
  mouseEventSourceElement: HTMLElement;
  followerElement: HTMLElement;
  /** 0 doesn't moce, 1 follows perfectly. */
  reactivity: number;
  centerMouseOnExit?: boolean;

  frameRequestId: number | null;

  updateBind: () => void;
  onMouseEnterBind: (event: MouseEvent) => void;
  onMouseMoveBind: (event: MouseEvent) => void;
  onMouseLeaveBind: EventListenerOrEventListenerObject;

  speed: Vec;
  position: Vec;
  target: Vec;

  isFollowing: boolean;

  constructor(config: MouseFollowerConfig) {
    if (!(config.mouseEventSourceElement instanceof Node)) {
      throw new Error(
        "MouseFollower failed to construct. The mouseEventSourceElement you passed is not a Node."
      );
    }
    if (!(config.followerElement instanceof Node)) {
      throw new Error(
        "MouseFollower failed to construct. The followerElement you passed is not a Node."
      );
    }

    this.mouseEventSourceElement = config.mouseEventSourceElement;
    this.followerElement = config.followerElement;
    this.reactivity = config.reactivity;
    this.centerMouseOnExit = config.centerMouseOnExit;

    this.speed = new Vec(0, 0);
    const rect = this.mouseEventSourceElement.getBoundingClientRect();
    this.target = new Vec(rect.width / 2, rect.height / 2);
    this.position = this.target.copy();

    this.frameRequestId = null;
    this.updateBind = this.update.bind(this);
    this.onMouseEnterBind = this.onMouseEnter.bind(this);
    this.onMouseMoveBind = this.onMouseMove.bind(this);
    this.onMouseLeaveBind = this.onMouseLeave.bind(this);

    this.isFollowing = false;

    this.addEventListeners();
    this.update();
  }

  static init(config: MouseFollowerConfig) {
    if (!(config.followerElement && "_follower" in config.followerElement)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (config.followerElement as any)._follower = new MouseFollower(config);
    }
  }

  update() {
    // Update element based on position.
    const rect = this.mouseEventSourceElement.getBoundingClientRect();
    this.followerElement.style.transform = `translate(
      ${this.position.x - rect.width / 2}px,
      ${this.position.y - rect.height / 2}px)`;

    // Update position based on speed.
    this.position.add(this.speed);

    const delta = Vec.getDelta(this.position, this.target);

    const distance = delta.getMagnitude();
    const direction = delta.getNormalised();

    direction.scale(distance * this.reactivity);
    this.speed = direction.copy();

    // Set max speed.
    if (this.speed.getMagnitude() > MAX_SPEED)
      this.speed.setMagnitude(MAX_SPEED);

    // Decide if the animation should continue.
    if (distance > 2) this.newFrame();
  }

  private newFrame() {
    if (this.isFollowing) {
      if (this.frameRequestId !== null)
        cancelAnimationFrame(this.frameRequestId);
      this.frameRequestId = requestAnimationFrame(this.updateBind);
    }
  }

  private onMouseEnter(event: MouseEvent) {
    this.followerElement.style.willChange = "transform";
    this.target = new Vec(event.clientX, event.clientY);
    this.newFrame();
  }

  private onMouseMove(event: MouseEvent) {
    this.target = new Vec(event.clientX, event.clientY);
    this.newFrame();
  }

  private onMouseLeave() {
    this.followerElement.style.willChange = "";
    const rect = this.mouseEventSourceElement.getBoundingClientRect();
    this.target = new Vec(rect.width / 2, rect.height / 2);
    this.newFrame();
  }

  private addEventListeners() {
    this.mouseEventSourceElement.addEventListener(
      "mouseenter",
      this.onMouseEnterBind as EventListenerOrEventListenerObject
    );
    this.mouseEventSourceElement.addEventListener(
      "mouseleave",
      this.onMouseLeaveBind
    );
    this.mouseEventSourceElement.addEventListener(
      "mousemove",
      this.onMouseMoveBind as EventListenerOrEventListenerObject
    );
  }

  private removeEventListeners() {
    this.mouseEventSourceElement?.removeEventListener(
      "mouseenter",
      this.onMouseEnterBind as EventListenerOrEventListenerObject
    );
    this.mouseEventSourceElement?.removeEventListener(
      "mouseleave",
      this.onMouseLeaveBind
    );
    this.mouseEventSourceElement?.removeEventListener(
      "mousemove",
      this.onMouseMoveBind as EventListenerOrEventListenerObject
    );
  }

  follow() {
    this.isFollowing = true;
    this.newFrame();
  }

  unfollow() {
    this.isFollowing = false;
  }

  destroy() {
    this.removeEventListeners();
    if (this.frameRequestId !== null) cancelAnimationFrame(this.frameRequestId);
    this.followerElement.style.willChange = "";

    // Remove the injected _follower object.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.followerElement as any)._follower = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (this.followerElement as any)._follower;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.followerElement as any) = null;
  }
}
