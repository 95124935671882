import { AllPanelSubPagesNames } from "../../../../../../core/layout/panelsTypes";
import { VideoConferenceState } from "../../../../../../core/videoConference/VideoConferenceAdapter";
import { useStore } from "../../../../../../store/store";
import { useWindowSize } from "../../../../report/hooks/reportHooks";

const MIN_WINDOW_HEIGHT = 830;
const DEFAULT_HEIGHT = 690;
const MIN_HEIGHT = 540;

/** Set the Settings panel height so it transitions between the video conference states */
export const useGetSettingsVideoHeight = (subpage?: AllPanelSubPagesNames) => {
  const { height: windowHeight } = useWindowSize();
  const videoConferenceState = useStore((s) => s.videoConference.state);

  const heightDiff =
    windowHeight < MIN_WINDOW_HEIGHT ? MIN_WINDOW_HEIGHT - windowHeight : 0;
  const newHeight = DEFAULT_HEIGHT - heightDiff;
  const height = (newHeight < MIN_HEIGHT ? MIN_HEIGHT : newHeight) + "px";
  const hasJoinedConference =
    videoConferenceState !== VideoConferenceState.SESSION_INITIALIZED &&
    videoConferenceState !== VideoConferenceState.SESSION_INITIALIZING;

  return {
    height:
      subpage === "settings/video"
        ? hasJoinedConference
          ? height
          : "500px"
        : undefined,
  };
};
