import Button from "../../../../components-ui/atoms/Button";
import Icon from "../../../../components-ui/atoms/Icon";
import { useText } from "../../../../core/i18n/i18nHooks";

type Props = {
  onTeleport?: () => void;
};

const TeleportButton: React.FC<Props> = ({ onTeleport }) => {
  const t = useText();

  return (
    <Button.Primary
      onClick={onTeleport}
      iconLeft={<Icon.Crosshair inheritColor size="15px" />}
      gap={1}
      large={false}
      override={{
        height: "32px",
      }}
    >
      {t("map_teleport")}
    </Button.Primary>
  );
};

export default TeleportButton;
