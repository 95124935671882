import styled from "styled-components";
import Button from "../../../../../../components-ui/atoms/Button";
import HeaderBadge from "../../../../../../components-ui/atoms/HeaderBadge";
import Icon from "../../../../../../components-ui/atoms/Icon";
import Space from "../../../../../../components-ui/atoms/Space";
import Typo from "../../../../../../components-ui/atoms/Typo";
import { useText } from "../../../../../../core/i18n/i18nHooks";
import { BackButtonPositioner } from "./FullPlayerProfile.ui.normal";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CircleAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: span 2;
  padding: 0 24px;
`;

type Props = {
  onPrevious: () => void;
};
const UnknownPlayerProfileUi: React.FC<Props> = ({ onPrevious }) => {
  const t = useText();
  return (
    <ProfileContainer>
      <BackButtonPositioner>
        <Button.Tertiary onClick={onPrevious} circular>
          <Icon.Chevron size="1rem" rotate="-90deg" shiftY="-0.1rem" />
        </Button.Tertiary>
      </BackButtonPositioner>
      <CircleAndTitleWrapper>
        <HeaderBadge rippleLineWidth={5} noBackground color="inverted">
          <Icon.Warning color="white" size="60px" inheritColor />
        </HeaderBadge>
        <Typo.Subtitle
          align="center"
          style={{
            wordBreak: "break-word",
          }}
        >
          {t("player_left")}
        </Typo.Subtitle>
        <Space h={3} />
        <Typo.Body
          align="center"
          style={{
            wordBreak: "break-word",
          }}
        >
          {t("player_unknown")}
        </Typo.Body>
      </CircleAndTitleWrapper>
    </ProfileContainer>
  );
};

export default UnknownPlayerProfileUi;
