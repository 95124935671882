import { readOsInformation } from "./os";

export const readDeviceInformation = () => {
  const os = readOsInformation();
  const userAgent = window.navigator.userAgent;

  const isMobile = os.isIos || os.isAndroid;
  const isIpad = os.isIpad;
  const isTablet =
    os.isIpad ||
    /(ipad|tablet|((a|A)ndroid(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );

  const isTouch = isMobile || isIpad || isTablet;

  return {
    isMobile,
    isTablet,
    isIpad,
    isTouch,
  };
};
