import { SvgProps } from "../../types/svg";

const ScreenShareIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_195_8)">
      <path
        d="M2.44389 11.7555C2.19856 11.7555 2 11.9523 2 12.193C2 12.4336 2.19856 12.6299 2.44389 12.6299C4.15722 12.6299 5.55222 14.002 5.55222 15.687C5.55278 15.9309 5.75 16.125 5.99444 16.125C6.23889 16.125 6.43833 15.9295 6.43833 15.688C6.43889 13.5191 4.64722 11.7555 2.44389 11.7555ZM2.90528 14.3613C2.415 14.3613 2.0175 14.7526 2.0175 15.2352C2.0175 15.7176 2.415 16.1089 2.90528 16.1089C3.39556 16.1089 3.79306 15.7176 3.79306 15.2352C3.79306 14.7523 3.39556 14.3613 2.90528 14.3613ZM2.46139 9.1168C2.216 9.1168 2.0175 9.31217 2.0175 9.55375C2.0175 9.79514 2.21606 9.9907 2.46139 9.9907C5.64472 9.9907 8.23361 12.5386 8.23361 15.67C8.23361 15.9114 8.43217 16.107 8.6775 16.107C8.92294 16.107 9.12139 15.9115 9.12139 15.67C9.11944 12.0562 6.13333 9.1168 2.46139 9.1168ZM16.1417 3.875H3.87639C2.85139 3.875 2.01736 4.70789 2.01736 5.73191V7.37008C2.01736 7.61158 2.21592 7.80703 2.46125 7.80703C2.70658 7.80703 2.90514 7.61166 2.90514 7.37008V5.73191C2.90514 5.18996 3.34042 4.74891 3.87625 4.74891H16.1401C16.676 4.74891 17.1113 5.18996 17.1113 5.73191V14.2522C17.1113 14.7942 16.676 15.2352 16.1401 15.2352H10.8972C10.6518 15.2352 10.4533 15.4306 10.4533 15.6722C10.4533 15.9136 10.6519 16.1091 10.8972 16.1091H16.1417C17.1417 16.1086 18 15.2746 18 14.252V5.73191C18 4.70789 17.1417 3.875 16.1417 3.875Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
    <defs>
      <clipPath id="clip0_195_8">
        <rect width="16" height="14" fill="white" transform="translate(2 3)" />
      </clipPath>
    </defs>
  </svg>
);
export default ScreenShareIcon;
