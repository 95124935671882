import {
  CSSProperties,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { zIndex } from "../../style/theme";
import { PrefixWith$ } from "../../types/typescript";

const Div = styled.div<PrefixWith$<StyleProps>>`
  width: ${(p) => p.$width || "auto"};
  height: ${(p) => p.$height || "auto"};

  transition:
    transform ${(p) => p.$speed || 300}ms,
    opacity ${(p) => p.$speed || 300}ms;
  transition-timing-function: ease-in-out;
  transform-origin: center center;
  opacity: 1;
  transform: translate(0px, 0px);
  ${(p) =>
    p.$overlay &&
    css`
      position: absolute;
      z-index: ${zIndex.overlays};
    `}
  ${(p) =>
    p.$hide &&
    css`
      opacity: ${p.$noFade ? "1" : "0"};
      transform: translate(${p.$shiftX || "0px"}, ${p.$shiftY || "0px"});

      pointer-events: none !important;
      * {
        pointer-events: none !important;
      }
    `}
`;

type StyleProps = {
  hide?: boolean;
  shiftX?: string;
  shiftY?: string;
  /** Milliseconds. */
  speed?: number;
  noFade?: boolean;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  overlay?: boolean;
  style?: CSSProperties;
};

export type Props = {
  unMount?: boolean;
  children: ReactNode;
  /** Notifies you of when the opening animation has been done. */
  onVisible?: () => void;
  /** Notifies you of when the closing animation has been done. */
  onHidden?: () => void;
} & StyleProps;

/** Quickly hide or unmount components with transitions. */
const Hide: React.FC<Props> = forwardRef<HTMLDivElement, Props>(
  (
    {
      unMount,
      children,
      hide,
      width,
      height,
      shiftX,
      shiftY,
      speed = 300,
      noFade,
      onHidden,
      onVisible,
      overlay,
      style,
      ...styledProp
    },
    ref
  ) => {
    const [previousHide, setPreviousHide] = useState(hide);
    const [dynamicMount, setDynamicMount] = useState(!hide);
    const [timerId, setTimerId] = useState<number>();

    // Dynamicaly unmount the component after the animation.
    useEffect(() => {
      if (Boolean(hide) === Boolean(previousHide)) return;
      if (hide) {
        if (timerId) window.clearTimeout(timerId);
        const timer = window.setTimeout(() => {
          setDynamicMount(false);
          onHidden?.();
        }, speed);
        setTimerId(timer);
      } else {
        if (timerId) window.clearTimeout(timerId);
        setDynamicMount(true);
        onVisible?.();
      }
    }, [hide, onHidden, onVisible, previousHide, speed, unMount, timerId]);

    // Keep me as the last useEffect
    useEffect(() => {
      setPreviousHide(hide);
    }, [hide]);

    const mountChildren = unMount ? dynamicMount : true;

    return (
      <Div
        ref={ref}
        $hide={hide}
        $shiftX={shiftX}
        $shiftY={shiftY}
        $speed={speed}
        $noFade={noFade}
        $width={width}
        $height={height}
        $overlay={overlay}
        {...styledProp}
        style={style}
      >
        {mountChildren ? children : null}
      </Div>
    );
  }
);

export default Hide;
