import { SvgProps } from "../../types/svg";

const PlayIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6016 8.53683C17.1602 8.85117 17.5 9.40484 17.5 10.0014C17.5 10.5979 17.1602 11.1516 16.6016 11.4337L5.35273 17.7205C4.77383 18.0741 4.04922 18.0884 3.45781 17.7848C2.86629 17.4812 2.5 16.9097 2.5 16.2881V3.71461C2.5 3.0945 2.86629 2.52262 3.45781 2.219C4.04922 1.91574 4.77383 1.92824 5.35273 2.25151L16.6016 8.53683Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PlayIcon;
