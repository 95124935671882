/* eslint-disable @typescript-eslint/no-explicit-any */
import { assign, createMachine, fromPromise } from "xstate";
import { VonageSessionResponse } from "./vonageVideoConferenceState.machine";

type FetchTokenAndSessionMachineContext = VonageVideoConferenceTokenParams & {
  sessionId: string | null;
  token: string | null;
};

type VonageVideoConferenceTokenParams = {
  environmentId: string;
  userId: string;
  visitorToken: string;
};
export const sessionManagerMachine = createMachine(
  {
    initial: "Idle",
    types: {
      context: {} as FetchTokenAndSessionMachineContext,
      input: {} as VonageVideoConferenceTokenParams,
    },
    context: ({ input }) => ({
      environmentId: input.environmentId,
      userId: input.userId,
      visitorToken: input.visitorToken,
      sessionId: null,
      token: null,
    }),
    output: ({ context }) => ({
      sessionId: context.sessionId,
      token: context.token,
      visitorToken: context.visitorToken,
    }),
    states: {
      Idle: {
        always: [
          {
            target: "Fetching",
          },
        ],
      },
      Fetching: {
        description: "Fetching Vonage Session Id and Token",
        invoke: {
          id: "getSessionIdAndToken",
          src: "tokenFetcher",
          input: ({ context }) => {
            return {
              environmentId: context.environmentId,
              userId: context.userId,
              visitorToken: context.visitorToken,
            };
          },
          onDone: {
            target: "Success",
            actions: "assignSessionIdAndToken",
          },
          onError: {
            target: "RefreshingVisitorToken",
          },
        },
      },
      Success: {
        type: "final",
      },
      RefreshingVisitorToken: {
        description: "The visitor token is invalid, we are refreshing it.",
        invoke: {
          id: "visitorTokenRefresher",
          src: "visitorTokenRefresher",
          input: ({ context }) => {
            return {
              environmentId: context.environmentId,
              visitorToken: context.visitorToken,
            };
          },
          onDone: {
            actions: assign({
              visitorToken: ({ event }) => event.output.token,
            }),
            target: "Fetching",
          },
          onError: {
            target: "FinalFailure",
          },
        },
      },
      FinalFailure: {
        type: "final",
      },
    },
  },
  {
    actors: {
      visitorTokenRefresher: fromPromise<
        { token: string; error: any },
        VonageVideoConferenceTokenParams
      >(async ({ input: { environmentId, visitorToken } }) => {
        const url = `${import.meta.env.VITE_GYARALESS_URL}/check-token/${environmentId}`;
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${visitorToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Error refreshing visitor token token");
        }
        return response.json();
      }),

      tokenFetcher: fromPromise<
        VonageSessionResponse,
        VonageVideoConferenceTokenParams
      >(async ({ input: { environmentId, userId, visitorToken } }) => {
        const url = `${import.meta.env.VITE_GYARALESS_URL}/conferences/${environmentId}/vonage-token?externalId=${userId}`;
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${visitorToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Error fetching token");
        }
        return response.json();
      }),
    },
    actions: {
      assignSessionIdAndToken: assign({
        sessionId: ({ event }) => event.output.sessionId,
        token: ({ event }) => event.output.token,
      }),
    },
  }
);
