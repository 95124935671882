import { useEffect } from "react";
import LogoutPageUi from "../../../features/utilityPages/LogoutPageUi";
import { useStore } from "../../../store/store";
import { useGetLoginPath } from "../routingHooks";

const LogoutRoute: React.FC = () => {
  const path = useGetLoginPath();
  const setVisitorToken = useStore((s) => s.session.setVisitorToken);

  useEffect(() => {
    setVisitorToken("");
    window.location.href = path;
  });

  return <LogoutPageUi />;
};

export default LogoutRoute;
