import { SvgProps } from "../../types/svg";

const AreaLock: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.35 17.2875C8.7875 16.7406 9.375 15.9844 10 15.1156V15.9625C9.52187 16.6062 9.08437 17.1688 8.74062 17.6C8.35625 18.0781 7.64375 18.0781 7.25938 17.6C5.62813 15.5938 2 10.7312 2 8C2 4.68625 4.68625 2 8 2C10.9906 2 13.4406 4.18625 13.925 7.04688C13.7594 7.075 13.5938 7.1125 13.4094 7.16563C13.0344 4.52344 10.7531 2.5 7.97188 2.5C4.9625 2.5 2.47188 4.9625 2.47188 8C2.47188 8.58437 2.69906 9.32812 3.05687 10.175C3.41094 11.0156 3.90406 11.9187 4.45375 12.8094C5.55312 14.5656 6.85313 16.2906 7.65 17.2875C7.83437 17.5156 8.16563 17.5156 8.35 17.2875ZM5.5 8C5.5 6.61875 6.61875 5.5 8 5.5C9.38125 5.5 10.5 6.61875 10.5 8C10.5 9.38125 9.38125 10.5 8 10.5C6.61875 10.5 5.5 9.38125 5.5 8ZM8 6C6.89687 6 6 6.89687 6 8C6 9.10313 6.89687 10 8 10C9.10313 10 10 9.10313 10 8C10 6.89687 9.10313 6 8 6ZM14.5 8C15.6031 8 16.5 8.89687 16.5 10V12H17C17.5531 12 18 12.4469 18 13V17C18 17.5531 17.5531 18 17 18H12C11.4469 18 11 17.5531 11 17V13C11 12.4469 11.4469 12 12 12H12.5V10C12.5 8.89687 13.3969 8 14.5 8ZM14.5 8.5C13.6719 8.5 13 9.17188 13 10V12H16V10C16 9.17188 15.3281 8.5 14.5 8.5ZM11.5 13V17C11.5 17.275 11.725 17.5 12 17.5H17C17.275 17.5 17.5 17.275 17.5 17V13C17.5 12.725 17.275 12.5 17 12.5H12C11.725 12.5 11.5 12.725 11.5 13Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default AreaLock;
