/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActorRefFrom, SnapshotFrom } from "xstate";
import peerParticipantMachine from "../core/videoConference/vonageStateMachine/peerParticipant.machine";
import VonageVideoConferenceStateMachine from "../core/videoConference/vonageStateMachine/vonageVideoConferenceState.machine";

function sanitizeJson(json: any) {
  return JSON.parse(JSON.stringify(json));
}

export function getSanitizedSnapshot(
  actor: ActorRefFrom<typeof VonageVideoConferenceStateMachine>
) {
  const snapshot = actor.getSnapshot();

  const sanitizedContext = {
    session: snapshot.context.session,
    publisher: snapshot.context.publisher,
    sessionId: snapshot.context.sessionId,
    conferenceErrors: snapshot.context.conferenceErrors,
    joinConferencePressed: snapshot.context.joinConferencePressed,
    cameraSetupComplete: snapshot.context.cameraSetupComplete,
    microphoneSetupComplete: snapshot.context.microphoneSetupComplete,
    speakerSetupComplete: snapshot.context.speakerSetupComplete,
    permissionsSetupComplete: snapshot.context.permissionsSetupComplete,
    environmentId: snapshot.context.environmentId,
    userId: snapshot.context.userId,
    audioInputDevices: snapshot.context.audioInputDevices,
    audioOutputDevices: snapshot.context.audioOutputDevices,
    videoInputDevices: snapshot.context.videoInputDevices,
    selectedAudioInputDevice: sanitizeJson(
      snapshot.context.selectedAudioInputDevice
    ),
    selectedAudioOutputDevice: sanitizeJson(
      snapshot.context.selectedAudioOutputDevice
    ),
    selectedVideoInputDevice: sanitizeJson(
      snapshot.context.selectedVideoInputDevice
    ),
    mediaPermissions: snapshot.context.mediaPermissions,
    participants: [] as ReturnType<typeof sanitizePeerStateMachineSnapshot>[],
  };

  sanitizedContext.participants = Object.values(
    snapshot.context.participants
  ).map((value) => {
    return sanitizePeerStateMachineSnapshot(value.getSnapshot());
  }, []);

  return {
    value: snapshot.value,
    context: sanitizedContext,
    history: sanitizeJson(snapshot.historyValue),
  };
}

export function sanitizePeerStateMachineSnapshot(
  snapshot: SnapshotFrom<typeof peerParticipantMachine>
) {
  return {
    history: sanitizeJson(snapshot.historyValue),
    context: {
      userId: snapshot.context.userId,
      subscriber: snapshot.context.subscriber?.id,
    },
    value: snapshot.value,
  };
}
