import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { log, logWarn } from "../../lib/logger";
import { useStore } from "../../store/store";
import { FromGameMessagesSchema } from "../gameConnection/messages/fromGameMessages";
import { sendGameMessage } from "../gameConnection/webrtc/webRtcMessageHandlers";
import {
  AllPanelSubPagesNames,
  PanelName,
  PanelSubElementName,
} from "../layout/panelsTypes";
import {
  useEnvironmentPath,
  useGetDevControlsPath,
  useGetDevPath,
  useGetIframePath,
  useGetLoginPath,
  useGetLogoutPath,
  useGetTalebookPath,
} from "../routing/routingHooks";
import { parseDebugMessage, parseRecentEnvString } from "./DevtoolsUtils";

/** Adds the ability to open a devtools popup window which can dispatch debug actions. */
const DevtoolsReceiver: React.FC = () => {
  const controlsPath = useGetDevControlsPath();
  const { envSlug } = useEnvironmentPath();

  // Listen for the "Alt+B" hotkey and open the debug controls on a popup.
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.altKey && event.code === "KeyB") {
        window.open(
          controlsPath,
          "debugControlsWindow",
          "popup=true,width=390px,height=800,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no"
        );
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [controlsPath]);

  return (
    <>
      {<DevtoolsNavigationReceiver />}
      {envSlug && <DevtoolsControlsReceiver />}
    </>
  );
};

const DevtoolsNavigationReceiver: React.FC = () => {
  const navigate = useNavigate();

  // Listen for a navigation messages from the devtools popup window.
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const message = parseDebugMessage(event.data);
      if (message?.prefix !== "debug") return;
      if (message.type === "GO_TO_ENV") {
        log("GENERIC", message.extra1, parseRecentEnvString(message.extra1));

        navigate(parseRecentEnvString(message.extra1).route);
        navigate(0);
      }
    };
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [navigate]);
  return null;
};

const DevtoolsControlsReceiver: React.FC = () => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const panelStates = useStore((s) => s.layout.panels);
  const dispatchLoadLevelControllerMessage = useStore(
    (s) => s.loadLevelController.dispatchLoadLevelControllerMessage
  );
  const logoutPath = useGetLogoutPath();
  const devPath = useGetDevPath();
  const talebookPath = useGetTalebookPath();
  const iframePath = useGetIframePath();
  const loginPath = useGetLoginPath();
  const navigate = useNavigate();
  const addInactivityException = useStore(
    (s) => s.session.addInactivityException
  );
  const removeInactivityException = useStore(
    (s) => s.session.removeInactivityException
  );
  const inactivityExceptions = useStore((s) => s.session.inactivityExceptions);
  const dispatchMapMessage = useStore((s) => s.map.dispatchMapMessage);
  const dispatchTextChatMessage = useStore(
    (s) => s.textChat.dispatchTextChatMessage
  );
  const dispatchMediaCaptureMessage = useStore(
    (s) => s.mediaCapture.dispatchMediaCaptureMessage
  );
  const dispatchSocialMessage = useStore((s) => s.social.dispatchSocialMessage);
  const dispatchQuestMessage = useStore((s) => s.quest.dispatchQuestMessage);

  const messageTypeMultiplexer = useCallback(
    (
      messageType: MessageEvent["type"],
      messageExtra: unknown,
      messageExtra2: unknown
    ) => {
      switch (messageType) {
        case "GO_TO_DEV":
          navigate(devPath, {
            relative: "path",
          });
          break;
        case "GO_TO_EXPERIENCE":
          navigate(loginPath, {
            relative: "path",
          });
          navigate(0); // refresh
          break;
        case "GO_TO_TALEBOOK":
          navigate(talebookPath, {
            relative: "path",
          });
          break;
        case "GO_TO_IFRAME":
          navigate(iframePath, {
            relative: "path",
          });
          break;
        case "LOGOUT":
          navigate(logoutPath, {
            relative: "path",
          });
          break;
        case "OPEN_PANEL":
          openPanel(
            messageExtra as
              | PanelName
              | PanelSubElementName
              | AllPanelSubPagesNames,
            messageExtra2
              ? {
                  slug: messageExtra2 as string,
                }
              : undefined
          );
          break;
        case "CLOSE_PANEL":
          closePanel(
            messageExtra as
              | PanelName
              | PanelSubElementName
              | AllPanelSubPagesNames,
            messageExtra2
              ? {
                  slug: messageExtra2 as string,
                }
              : undefined
          );
          break;
        case "TRIGGER_EXPERIENCE_AUDIO":
          window.dispatchEvent(new CustomEvent("play-experience-audio"));
          break;
        case "TRIGGER_BUSINESSCARD_MESSAGE":
          sendGameMessage({
            type: "EditingBusinessCard",
            opened: false,
          });
          break;
        case "TOGGLE_ACTIVITY_CHECK":
          {
            const isActivityCheckDisabled = !inactivityExceptions["debug"];
            if (!isActivityCheckDisabled) {
              addInactivityException("debug");
            } else {
              removeInactivityException("debug");
            }
            log(
              "GENERIC",
              `Activity check is now ${
                isActivityCheckDisabled ? "enabled" : "disabled"
              }`
            );
          }
          break;
        case "TRIGGER_LEVEL_LOAD":
          dispatchLoadLevelControllerMessage({
            type: "LoadingLevelStart",
            levelId: JSON.parse(messageExtra as string)[0],
          });
          setTimeout(
            () => {
              dispatchLoadLevelControllerMessage({
                type: "LoadingLevelEnd",
                levelId: JSON.parse(messageExtra as string)[0],
              });
            },
            JSON.parse(messageExtra as string)[1]
          );
          break;
        case "SET_ACTIVE_REGION":
          dispatchMapMessage({
            type: "ActiveRegion",
            regionId: messageExtra as string,
            categoryId: "Map",
          });
          break;
        case "TRIGGER_CHAT_NOTIFICATION":
          dispatchTextChatMessage({
            type: "ReceivedChatMessage",
            content: messageExtra as string,
            sender: "Guide",
            senderId: -1,
            messageId: 0,
            roomId: "public",
          });
          openPanel("textChatPreview");
          break;
        case "MEDIA_CAPTURE_ACTION":
          {
            const [mediaType, action] = JSON.parse(messageExtra as string);
            dispatchMediaCaptureMessage({
              type: "MediaCaptureAction",
              mediaType: mediaType,
              action: action,
            });
          }
          break;
        case "SEND_REACTION":
          {
            const [reaction, reactionLevel] = JSON.parse(
              messageExtra as string
            );
            dispatchSocialMessage({
              type: "Reaction",
              reaction: reaction,
              stage: reactionLevel,
              roomId: "1",
              playerId: 1,
              broadcast: true,
            });
          }
          break;
        case "SEND_QUEST_STATUS":
          {
            const [quests] = JSON.parse(messageExtra as string);
            dispatchQuestMessage({
              type: "QuestsInfo",
              quests: quests as FromGameMessagesSchema["QuestsInfo"]["quests"],
            });
          }
          break;
        default:
          logWarn(
            "GENERIC",
            `Unknown debug action received ${messageType}:${messageExtra as string}:${messageExtra2 as string}`
          );
          break;
      }
    },
    [
      dispatchQuestMessage,
      addInactivityException,
      closePanel,
      devPath,
      dispatchLoadLevelControllerMessage,
      dispatchMapMessage,
      dispatchTextChatMessage,
      iframePath,
      inactivityExceptions,
      loginPath,
      logoutPath,
      navigate,
      openPanel,
      removeInactivityException,
      talebookPath,
      dispatchMediaCaptureMessage,
      dispatchSocialMessage,
    ]
  );

  // Listen for messages from the devtools popup window.
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const message = parseDebugMessage(event.data);
      if (!message) return;
      if (message.prefix !== "debug") return;
      log("GENERIC", "debug message:", message);

      messageTypeMultiplexer(message.type, message.extra1, message.extra2);
    };
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [
    messageTypeMultiplexer,
    panelStates.cinematicView.active,
    panelStates.map.active,
    panelStates.photo.active,
    panelStates.settings.active,
    panelStates.social.active,
    panelStates.profile.active,
    panelStates.actionBar.active,
  ]);
  return null;
};

export default DevtoolsReceiver;
