import { ReactNode } from "react";
import FullScreenLoader from "../../components-ui/molecules/FullScreenLoader";
import ErrorPageUi from "../../features/utilityPages/ErrorPageUi";
import NotFoundPageUi from "../../features/utilityPages/NotFoundPageUi";
import EnvironmentWrapper from "../EnvironmentWrapper";
import Debug from "../dev/Devtools";

type Props = {
  children: ReactNode;
};

/** A wrapper shared among all routes. */
const WithEnvironmentWithDebug: React.FC<Props> = ({ children }) => {
  return (
    <EnvironmentWrapper
      loadingPage={FullScreenLoader}
      notFoundPage={NotFoundPageUi}
      errorPage={ErrorPageUi}
    >
      <Debug />
      {children}
    </EnvironmentWrapper>
  );
};

export default WithEnvironmentWithDebug;
