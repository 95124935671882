import upperFirst from "lodash/upperFirst";
import { styled } from "styled-components";
import Column from "../../../../components-ui/atoms/Column";
import ListItem from "../../../../components-ui/atoms/ListItem";
import Space from "../../../../components-ui/atoms/Space";
import { getLocaleName } from "../../../../components-ui/molecules/LanguageSelectionButton";

const ListWrapper = styled.div<{ $padding: React.CSSProperties["padding"] }>`
  padding: ${(p) => p.$padding};
  overflow: visible;
`;

type Props = {
  locales: string[];
  currentLocale?: string;
  padding?: React.CSSProperties["padding"];
  onLanguageSelected: (locale: string) => void;
};

const LanguageCardList: React.FC<Props> = ({
  padding = "0px 24px",
  locales,
  onLanguageSelected,
  currentLocale,
}) => {
  return (
    <Column align="stretch">
      <Space h={4} />
      <ListWrapper $padding={padding}>
        {locales.map((locale) => (
          <ListItem
            name={locale}
            onChange={({ name }) => onLanguageSelected(name)}
            checked={currentLocale === locale}
            key={locale}
            label={upperFirst(getLocaleName(locale)) ?? "Unknown"}
          />
        ))}
      </ListWrapper>
      <Space h={4} />
    </Column>
  );
};

export default LanguageCardList;
