import { useCallback } from "react";
import { useGetPollBySlug } from "../../../api/gyaradosHooks";
import { PanelTestIds } from "../../../constants/testIds";
import { useStore } from "../../../store/store";
import PollUi from "./Poll.ui";

type Props = {
  slug?: string;
};

const PollLogic: React.FC<Props> = ({ slug }) => {
  const closePanel = useStore((s) => s.layout.closePanel);
  const poll = useGetPollBySlug(slug);
  const visitorToken = useStore((s) => s.session.visitorToken);
  const visitorTokenData = useStore((s) => s.session.visitorTokenData);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const playerId = useStore((s) => s.gameConnection.playerId);
  const clientPersistedId = useStore((s) => s.session.clientPersistedId);

  const handleDismiss = useCallback(() => {
    closePanel("poll", { slug });
  }, [closePanel, slug]);

  return (
    <PollUi
      visitorToken={visitorToken}
      visitorTokenData={visitorTokenData}
      roomId={roomId}
      playerId={playerId}
      clientPersistedId={clientPersistedId}
      poll={poll}
      handleDismiss={handleDismiss}
      testId={PanelTestIds.poll.root}
    />
  );
};

export default PollLogic;
