import React from "react";
import { SvgProps } from "../../types/svg";

const FacebookIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0078 11L14.4522 8.10437H11.6737V6.22531C11.6737 5.43313 12.0619 4.66094 13.3063 4.66094H14.5694V2.19563C14.5694 2.19563 13.4231 2 12.3272 2C10.0391 2 8.54344 3.38688 8.54344 5.8975V8.10437H6V11H8.54344V18H11.6737V11H14.0078Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default FacebookIcon;
