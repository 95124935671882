import { styled } from "styled-components";
import CardContentAlignment from "../../../../components-ui/atoms/CardContentAlignment";
import Hide from "../../../../components-ui/atoms/Hide";
import Markdown from "../../../../components-ui/atoms/Markdown";
import Space from "../../../../components-ui/atoms/Space";
import Typo from "../../../../components-ui/atoms/Typo";
import CardFullLandscape from "../../../../components-ui/molecules/CardFullLandscape";
import { Props } from "./MapCard.ui.normal";
import MapCardImage from "./MapCardImage";
import TeleportButton from "./TeleportButton";

const ContentWrapper = styled.div`
  padding-left: 32px;
  padding-right: max(var(--safe-margin-right), 64px);
`;

/*
 * Card that shows the Map point information when in small sized screens on landscape orientation
 * This component should be kept as similar as possible to InfoCardPanelSmallLandscapeUi (InfoCard.ui.landscape)
 */
const MapCardUiSmallLandscape: React.FC<Props> = ({
  selectedPoint,
  isCurrentLocation,
  show,
  onTeleport,
  onClose,
}) => {
  const { image, title, description, spawnPoint } = selectedPoint;

  return (
    <Hide hide={!show} unMount width="100dvw">
      <CardFullLandscape
        topSection={
          <MapCardImage image={image} isCurrentLocation={isCurrentLocation} />
        }
        onClose={onClose}
      >
        <ContentWrapper>
          <Space h={8} />
          <CardContentAlignment>
            {title && <Typo.Title>{title}</Typo.Title>}
            <Space h={2} />
            {description && <Markdown content={description} textAlign="left" />}
            {spawnPoint !== undefined && (
              <>
                <Space h={5} />
                <TeleportButton onTeleport={() => onTeleport(selectedPoint)} />
              </>
            )}
          </CardContentAlignment>
        </ContentWrapper>
      </CardFullLandscape>
    </Hide>
  );
};

export default MapCardUiSmallLandscape;
