import { styled } from "styled-components";
import Hide from "../../components-ui/atoms/Hide";
import { BaseLayout } from "../../core/layout/PanelsLayout";
import { useStore } from "../../store/store";
import TopLogoPanelLogic from "./TopLogoPanelLogic";

const Layout = styled(BaseLayout)`
  position: absolute;
  top: max(var(--safe-margin-top), 2px);
  left: max(var(--safe-margin-left), 10px);
  right: max(var(--safe-margin-right), 10px);
  bottom: 0;
`;

const TopLogoPanelLayout: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.logo.visible);

  return (
    <Layout id="panel-logo">
      <Hide width="100%" height="100%" hide={!visible}>
        <TopLogoPanelLogic />
      </Hide>
    </Layout>
  );
};

export default TopLogoPanelLayout;
