import HuePicker from "react-color/lib/components/hue/Hue";
import { css, styled } from "styled-components";
import tinycolor from "tinycolor2";
import { GameColor } from "../../core/gameConnection/messages/sharedDataTypes";

const Wrapper = styled.div<{
  $direction?: "horizontal" | "vertical";
  $bright?: boolean;
}>`
  padding: ${(p) => (p.$direction === "vertical" ? "22px 16px" : "16px 22px")};
  background-color: ${(p) => p.theme.colorAbove0};
  border-radius: ${(p) => p.theme.radiusFull};

  ${(p) =>
    p.$bright &&
    css`
      background-color: ${(p) => p.theme.colorBelow1};
      box-shadow: 0px 1px 2px 0px ${(p) => p.theme.colorShadow};
      backdrop-filter: blur(${(p) => p.theme.blurLight});
    `}
`;

const StyledHuePicker = styled(HuePicker)`
  .hue-picker {
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    box-shadow: 0 0 4px ${(p) => p.theme.colorShadow};
    background: ${(p) => p.theme.colorBelow0};
    box-sizing: border-box;
  }

  .hue-vertical,
  .hue-horizontal {
    border-radius: ${(p) => p.theme.radiusFull} !important;
    & > div {
      & > div {
        inline-size: 26px !important;
        block-size: 26px !important;
        transform: translate(-50%, -50%) !important;
        cursor: pointer;
        background-color: ${(p) => p.theme.colorBelow2} !important;

        &::before {
          content: "";
          position: absolute;
          inset-block-start: 50%;
          inset-inline-start: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .hue-vertical {
    border-radius: ${(p) => p.theme.radiusFull} !important;
    inline-size: 2px;
    margin-inline-start: 1px;
    block-size: calc(100% + 12px) !important;
    inset-block-start: -6px;
    & > div {
      inset-inline-start: 50% !important;
      & > div {
        &::before {
          inline-size: 50%;
          block-size: 8%;
          border-block-start: 1px solid ${(p) => p.theme.colorAbove2};
          border-block-end: 1px solid ${(p) => p.theme.colorAbove2};
        }
      }
    }
  }

  .hue-horizontal {
    border-radius: ${(p) => p.theme.radiusFull} !important;

    & > div {
      inset-block-start: 50% !important;
      & > div {
        &::before {
          inline-size: 13%;
          block-size: 50%;
          border-inline-start: 1px solid ${(p) => p.theme.colorAbove2};
          border-inline-end: 1px solid ${(p) => p.theme.colorAbove2};
        }
      }
    }
  }
`;

const ColorPicker: React.FC<{
  color?: GameColor;
  onChange?: (color: string) => void;
  direction?: "vertical" | "horizontal";
  bright?: boolean;
  name?: string;
  length?: string;
}> = ({ color, direction, onChange, name, bright, length = "270px" }) => {
  return (
    <Wrapper $direction={direction} $bright={bright}>
      <input name={name} type="hidden" value={tinycolor(color).toHex()} />
      <StyledHuePicker
        color={color}
        onChange={(color) => {
          onChange?.(color.hex);
        }}
        direction={direction}
        height={direction === "vertical" ? length : "7px"}
        width={direction === "vertical" ? "7px" : `calc(${length} - 44px)`}
      />
    </Wrapper>
  );
};

export default ColorPicker;
