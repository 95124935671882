import React from "react";
import { SvgProps } from "../../types/svg";

const StarsIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3814 10.35L10.1065 9.8725L8.6441 6.89687C8.51286 6.63125 8.25349 6.49687 7.99725 6.49687C7.74139 6.49687 7.48821 6.62962 7.35571 6.89687L5.89108 9.84688L2.61652 10.35C2.02685 10.4359 1.78999 11.1625 2.21966 11.5766L4.59051 13.8891L4.05615 17.1578C3.95084 17.625 4.3202 18 4.7355 18C4.84621 18 4.96005 17.9739 5.06955 17.9155L8.00038 16.3723L10.9296 17.9155C11.0344 17.9712 11.1474 17.9991 11.2615 17.9991C11.411 17.9991 11.5614 17.9519 11.6877 17.8611C11.9064 17.701 12.0196 17.4264 11.9727 17.1608L11.4127 13.8906L13.7852 11.5781C14.2095 11.1656 13.972 10.4094 13.3814 10.35ZM13.4345 11.2219L10.8759 13.7178L11.479 17.2209C11.5082 17.3867 11.3242 17.5321 11.1615 17.4456L7.99913 15.7799L4.83518 17.475C4.67369 17.5613 4.4927 17.4167 4.52082 17.247L5.12767 13.7188L2.56683 11.2188C2.43487 11.0916 2.51293 10.8728 2.69023 10.8472L6.22447 10.3328L7.80442 7.12031C7.85663 7.01562 7.95038 7 7.97225 7L8.168 7.12059L9.77531 10.3344L13.3096 10.8488C13.4877 10.8469 13.5658 11.0938 13.4345 11.2219ZM10.2503 4.75H12.2502V6.75C12.2502 6.88769 12.3626 7.00016 12.5004 7.00016C12.6381 7.00016 12.7502 6.8875 12.7502 6.75V4.75H14.7501C14.8878 4.75 15.0002 4.63775 15.0002 4.50006C15.0002 4.36238 14.8876 4.25 14.7501 4.25H12.7502V2.25C12.7502 2.11231 12.638 2 12.5003 2C12.3626 2 12.2502 2.11231 12.2502 2.25V4.25H10.2503C10.1126 4.25 10.0005 4.36238 10.0005 4.50006C10.0005 4.63775 10.1128 4.75 10.2503 4.75ZM17.75 8.75H16.2501V7.25C16.2501 7.11231 16.1378 7.00012 16.0001 7.00012C15.8624 7.00012 15.7501 7.1125 15.7501 7.25V8.75H14.2501C14.1125 8.75 14.0002 8.86247 14.0002 9.00019C14.0002 9.13791 14.1126 9.25 14.2501 9.25H15.7501V10.75C15.7501 10.8877 15.8624 11.0002 16.0001 11.0002C16.1378 11.0002 16.2501 10.8875 16.2501 10.75V9.25H17.75C17.8877 9.25 18 9.13788 18 9.00019C18 8.8625 17.8875 8.75 17.75 8.75Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default StarsIcon;
