import { CSSProperties, ReactNode } from "react";
import styled, { css } from "styled-components";
import { getSafeStepsSpacing } from "../../lib/ui";
import { PrefixWith$ } from "../../types/typescript";

const DivFlex = styled.div<PrefixWith$<Omit<Props, "children">>>`
  width: ${(p) => p.$width || "100%"};
  height: ${(p) => p.$height || "auto"};

  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.$justify || "flex-start"};
  align-items: ${(p) => p.$align || "flex-start"};
  flex-wrap: ${(p) => (p.$flexWrap ? "wrap" : "nowrap")};

  > * {
    flex-shrink: 0;
    ${(p) =>
      p.$textAlign &&
      css`
        text-align: ${p.$textAlign};
      `}
  }

  // This is a polyfill for the flexbox gap property which has not the best support.
  // https://caniuse.com/flexbox-gap
  > * + * {
    ${(p) => p.$gap && `margin-top: ${getSafeStepsSpacing(p.$gap)};`}
  }
`;

export type Props = {
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  align?: "center" | "flex-start" | "flex-end" | "stretch" | "baseline";
  textAlign?: CSSProperties["textAlign"];
  /** A geometric spacing scale https://www.radix-ui.com/themes/docs/theme/layout#spacing-scale */
  gap?: number;
  testId?: string;
  width?: string;
  height?: string;
  flexWrap?: boolean;
  children?: ReactNode;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

/** A quick and readable way to apply the most commons flexbox vertical layouts. */
const Column: React.FC<Props> = ({ children, ...props }) => {
  const {
    testId,
    justify,
    align,
    gap,
    width,
    height,
    flexWrap,
    textAlign,
    style,
    ...restProps
  } = props;
  return (
    <DivFlex
      data-testid={testId}
      $justify={justify}
      $align={align}
      $gap={gap}
      $width={width}
      $height={height}
      $flexWrap={flexWrap}
      $textAlign={textAlign}
      style={style}
      {...restProps}
    >
      {children}
    </DivFlex>
  );
};

export default Column;
