import { useCallback, useMemo } from "react";
import { useVideoConferenceControlsContext } from "../../../../../core/videoConference/VideoConference";
import { useStore } from "../../../../../store/store";
import { SearchableProfile } from "../../../profile/lib/profileTypes";
import { getLocalPlayerName } from "../../../profile/profileSlice";
import LocalVideoAvatarCircle from "../../../videoAvatars/avatarCircle/LocalVideoAvatarCircle";
import PeerVideoAvatarCircle from "../../../videoAvatars/avatarCircle/PeerVideoAvatarCircle";
import TitleBreakoutPlayerList from "../../../videoAvatars/avatarCircle/TitleBreakoutPlayerList";
import { getPlayerKey } from "../../socialUtils";

const useVoiceChatParticipants = () => {
  const { toggleLocalAudio } = useVideoConferenceControlsContext();
  const videoConferenceStatus = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const allPlayers = useStore((s) => s.gameConnection.allPlayers);
  const profileData = useStore((s) => s.profile.profileData);
  const participants = useStore((s) => s.videoConference.participants);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const openPanel = useStore((s) => s.layout.openPanel);
  const { toggleVideo } = useVideoConferenceControlsContext();
  const micMuted = useStore((s) => s.userMedia.micMuted);

  const participantsAsArray = useMemo(() => {
    const allParticipants = Object.values(participants).filter(
      (p) => p.isPublishing
    );
    const localParticipant = allParticipants.find((p) => p.isLocal);
    const restParticipants = allParticipants.filter((p) => !p.isLocal);
    if (!localParticipant) return restParticipants;
    return [localParticipant, ...restParticipants];
  }, [participants]);

  const onAvatarClick = useCallback(
    (player: SearchableProfile) => {
      if (!player?.photonPlayerId || !player.photonRoomId) return;

      const externalId = getPlayerKey({
        roomId: player.photonRoomId,
        playerId: player.photonPlayerId,
      });
      openPanel(`social/playerProfile/${externalId}`);
    },
    [openPanel]
  );

  return useMemo(() => {
    if (!roomId) return [];
    if (!videoConferenceStatus) return [];

    return participantsAsArray
      .filter((p) => !!p?.userId)
      .map((p) => {
        const player = allPlayers[p.userId];

        const name = p.isLocal ? getLocalPlayerName(profileData) : player?.name;

        const avatarColor = p.isLocal
          ? profileData?.avatarColor
          : player?.avatarColor;

        if (p.isLocal) {
          return (
            <LocalVideoAvatarCircle
              renderTitleBreakout={(props) => {
                return <TitleBreakoutPlayerList {...props} isLocal darkText />;
              }}
              micMuted={micMuted}
              noBorder
              playerName={name}
              avatarColor={avatarColor}
              playerKey={p.userId}
              toggleLocalAudio={toggleLocalAudio}
              key={p.id}
              participant={p}
              size={68}
              toggleVideo={toggleVideo}
            />
          );
        }

        return (
          <PeerVideoAvatarCircle
            renderTitleBreakout={(props) => {
              return <TitleBreakoutPlayerList {...props} isLocal={p.isLocal} />;
            }}
            speakingIndicatorPosition="back"
            noBorder
            playerName={name}
            avatarColor={avatarColor}
            playerKey={p.userId}
            key={p.id}
            participant={p}
            size={68}
            onClick={() => onAvatarClick(player)}
          />
        );
      });
  }, [
    roomId,
    videoConferenceStatus,
    participantsAsArray,
    allPlayers,
    profileData,
    micMuted,
    toggleLocalAudio,
    toggleVideo,
    onAvatarClick,
  ]);
};

export default useVoiceChatParticipants;
