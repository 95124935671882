import Space from "../../../components-ui/atoms/Space";
import CardPartialPortrait from "../../../components-ui/molecules/CardPartialPortrait";
import RestrictionMessageUi from "./component/RestrictionMessageUi";

const RestrictionUiPortrait: React.FC = () => {
  return (
    <CardPartialPortrait padding="0px" topSection={<RestrictionMessageUi />}>
      <Space h={8} />
    </CardPartialPortrait>
  );
};

export default RestrictionUiPortrait;
