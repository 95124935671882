/* eslint-disable no-prototype-builtins,@typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";
import cloneDeep from "lodash/cloneDeep";
import { isAndroid, isWebkit } from "../../../constants/flags";
import { log, logError, logInfo, logWarn } from "../../../lib/logger";
import { AudioQuality } from "../../../types/webrtc";
import { StreamingStats } from "../messages/sharedDataTypes";
import { ws } from "../websocket/websocketUtils";
import { PlayerParameters } from "./webRtcTypes";

const getCandidateString = (c: RTCIceCandidate) => {
  const part1 = `"${c.type}", ${c.protocol} on ${c.address}:${c.port}`;
  if (!c.relatedAddress) return part1;
  else
    return part1 + ` (related address: ${c.relatedAddress}:${c.relatedPort})`;
};

class WebRtcPlayer {
  parOptions: PlayerParameters;
  readonly cfg: RTCConfiguration;
  aggregatedStats?: StreamingStats;
  aggregateStatsIntervalId = -1;
  pcClient: RTCPeerConnection | null = null;
  dcClient: RTCDataChannel | null = null;
  sdpConstraints = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
  };
  // See https://www.w3.org/TR/webrtc/#dom-rtcdatachannelinit for values
  dataChannelOptions = { ordered: true };
  video: HTMLVideoElement;
  streamAudio: HTMLAudioElement;
  audioBitrate: number = 128000;
  static audioQualityBitRateMap: Record<AudioQuality, number> = {
    low: 128000,
    medium: 256000,
    high: 360000,
  };

  // Callback set externally
  // With the new eslint/TS dependencies upgrade, this warning popped up
  // for type declaration here and is wrong. There might be a better way to
  // "fix" the issue. (The code could be refactored to be more elegant anyway.)
  onDataChannelConnected: null | (() => void) = null;
  onDataChannelMessage: null | ((data: any) => void) = null;
  onDataChannelClosed: null | (() => void) = null;
  onAggregatedStats: null | ((stats: StreamingStats) => void) = null;
  onWebRtcCandidate: null | ((candidate: RTCIceCandidate) => void) = null;
  onWebRtcOffer: null | ((offer: RTCSessionDescriptionInit) => void) = null;
  onSignalingStateChange:
    | null
    | ((state: RTCSignalingState | undefined) => void) = null;
  onIceConnectionStateChange:
    | null
    | ((state: RTCIceConnectionState | undefined) => void) = null;
  onIceGatheringStateChange:
    | null
    | ((state: RTCIceGatheringState | undefined) => void) = null;

  constructor(
    videoElement: HTMLVideoElement,
    audioElement: HTMLAudioElement,
    parOptions: PlayerParameters,
    forceTurnRelay: boolean
  ) {
    // The parOptions argument comes from Redux and shouldn't be modified.
    this.parOptions = cloneDeep(parOptions) || {
      peerConnectionOptions: {
        iceServers: [],
      },
    };
    this.cfg = this.parOptions.peerConnectionOptions || {};
    if (forceTurnRelay) {
      this.cfg.iceTransportPolicy = "relay";
    }
    (this.cfg as any).sdpSemantics = "unified-plan";
    // Fix for the Chrome 89 change that makes Unreal crash.
    // https://www.chromestatus.com/feature/6269234631933952
    // https://docs.google.com/document/d/1PY2syYX5w9PYwGOCJchNs366NsWpuzVWIQYKscblYco/edit
    // https://bugs.chromium.org/p/webrtc/issues/detail?id=9985
    // https://waltzbinaire.slack.com/files/U1C9M9GMC/F01QA57GG5U/image.png
    (this.cfg as any).offerExtmapAllowMixed = false;
    this.video = videoElement;
    this.streamAudio = audioElement;
  }

  onsignalingstatechange = (state: any) => {
    const pc = state?.target as RTCPeerConnection | undefined;
    const s = pc?.signalingState;
    logInfo("WEBRTC", "signaling state change:", s);
    if (this.onSignalingStateChange) this.onSignalingStateChange(s);
  };

  oniceconnectionstatechange = (state: any) => {
    const pc = state?.target as RTCPeerConnection | undefined;
    const s = pc?.iceConnectionState;
    logInfo("WEBRTC", "ice connection state change:", s);
    if (this.onIceConnectionStateChange) this.onIceConnectionStateChange(s);
  };

  onicegatheringstatechange = (state: any) => {
    const pc = state?.target as RTCPeerConnection | undefined;
    const s = pc?.iceGatheringState;
    logInfo("WEBRTC", "ice gathering state change:", s);
    if (this.onIceGatheringStateChange) this.onIceGatheringStateChange(s);
  };

  handleOnTrack = (e: RTCTrackEvent) => {
    if (e.track.kind === "audio") {
      const audioMediaStream = e.streams[0];
      window.__streamingAudio = audioMediaStream;
      const isVideoSharingTheSameSource =
        this.video.srcObject == audioMediaStream;

      if (!isVideoSharingTheSameSource) {
        this.streamAudio.srcObject = audioMediaStream;
      }
    } else if (e.track.kind === "video") {
      if (this.video.srcObject !== e.streams[0]) {
        log("WEBRTC", "setting video stream from ontrack");
        this.video.srcObject = e.streams[0];
        window.__streamingVideo = e.streams[0];

        if (this.streamAudio.srcObject == e.streams[0]) {
          // Video and Audio share the same source (before Unreal 4.27).
          // Unhook the audio src in case we received the audio track before the video one.
          this.streamAudio.srcObject = null;
        }

        // I'm not really sure why this is needed. In the original Epic
        // implementation, the video element is added by the WebRTCPlayer,
        // but in our case we want full control on it via React.
        // Removing and re-appending the child also does the trick.
        // Without this line, the "loadedmetadata" event isn't fired and
        // the video never really plays.
        this.video.load();
      }
    } else {
      const msg = `Track is not video nor audio, but is ${e.track.kind}`;
      logError("WEBRTC", msg);
      Sentry.captureException(new Error(msg));
    }
  };

  setupDataChannel = (
    pc: RTCPeerConnection,
    label: string,
    options: RTCDataChannelInit
  ) => {
    try {
      const datachannel = pc.createDataChannel(label, options);
      datachannel.binaryType = "arraybuffer";
      log("GENERIC", `Created datachannel "${label}"`);
      datachannel.onopen = (/*_e*/) => {
        log("GENERIC", `Data channel "${label}" connected.`);
        if (this.onDataChannelConnected) this.onDataChannelConnected();
      };

      datachannel.onclose = (/*_e*/) => {
        log("GENERIC", `Data channel "${label}" closed.`);
        if (this.onDataChannelClosed) this.onDataChannelClosed();
      };

      datachannel.onmessage = (e) => {
        if (this.onDataChannelMessage) this.onDataChannelMessage(e.data);
      };

      return datachannel;
    } catch (e) {
      logWarn("GENERIC", "No data channel.", e);
      return null;
    }
  };

  /**
   * This is called when local candidates are found. They typically contain the
   * address of the local client machine or of the TURN server.
   */
  onicecandidate = (e: RTCPeerConnectionIceEvent) => {
    // If the event's candidate property is null, ICE gathering has
    // finished. This message should not be sent to the remote peer.
    // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/onicecandidate
    log(
      "WEBRTC",
      `ICE candidate (local): ${
        e.candidate ? getCandidateString(e.candidate) : "falsy"
      }`
    );
    if (e.candidate && e.candidate.candidate && this.onWebRtcCandidate) {
      this.onWebRtcCandidate(e.candidate);
    }
  };

  // Snippet from Nico.
  // if(self.onWebRtcOffer) {
  //   //set start min && max bitrate
  //   offer.sdp = offer.sdp?.replace(/(a=fmtp:\d+ .*level-asymmetry-allowed=.*)\r\n/gm, "$1;x-google-start-bitrate=12000;x-google-min-bitrate=6000;x-google-max-bitrate=16000\r\n");
  //   self.onWebRtcOffer(offer);
  // }
  handleCreateOffer = (pc: RTCPeerConnection) => {
    pc.createOffer(this.sdpConstraints).then(
      (offer) => {
        // Implement audio bitrate/stereo quality fix from:
        // https://answers.unrealengine.com/questions/971708/pixel-streaming-audio-has-bad-quality.html
        offer.sdp = offer.sdp?.replace(
          "useinbandfec=1",
          `useinbandfec=1;stereo=1;maxaveragebitrate=${this.audioBitrate}`
        );
        // Fix Chrome 94 crashing Unreal
        offer.sdp = offer.sdp?.replace("a=extmap-allow-mixed\r\n", "");
        // Fix Safari 15 crashing Unreal 4.27 (preview only?)
        // This is a temporary workaround. UE should end up supporting sendrecv
        // as they did before.
        // Source: https://forums.unrealengine.com/t/unreal-engine-4-27-preview/234295/85
        // Note: it's important to not apply this fix on Firefox
        if (isWebkit) offer.sdp = offer.sdp?.replace(/sendrecv/gm, "recvonly");
        pc.setLocalDescription(offer);
        if (this.onWebRtcOffer) {
          // Andriy:
          // increase start bitrate from 300 kbps to 20 mbps and max bitrate from 2.5 mbps to 100 mbps
          // (100 mbps means we don't restrict encoder at all)
          // after we `setLocalDescription` because other browsers are not c happy to see google-specific config
          // // Nathan Vogel:
          // // We were using the Journee defaults (12000, 2000, and 16000) for months without issue, but our
          // // very heavy Joytopia world was having complete freeze issues on start with those high settings.
          // // Hence, we use lower settings on Android. There are still issues, but they seem less frequent.
          const startBitrate = isAndroid ? 3000 : 12000;
          const minBitrate = isAndroid ? 1000 : 2000;
          const maxBitrate = isAndroid ? 6000 : 16000;
          // const startBitrate = 12000;
          // const minBitrate = 2000;
          // const maxBitrate = 16000;
          offer.sdp = offer.sdp?.replace(
            /(a=fmtp:\d+ .*level-asymmetry-allowed=.*)\r\n/gm,
            `$1;x-google-start-bitrate=${startBitrate};x-google-min-bitrate=${minBitrate};x-google-max-bitrate=${maxBitrate}\r\n`
          );
          this.onWebRtcOffer(offer);
        }
      },
      () => {
        logWarn("WEBRTC", "Couldn't create offer");
      }
    );
  };

  setupPeerConnection = (pc: RTCPeerConnection) => {
    if ((pc as any).SetBitrate)
      logWarn(
        "WEBRTC",
        "Hurray! there's RTCPeerConnection.SetBitrate function"
      );

    //Setup peerConnection events
    pc.onsignalingstatechange = this.onsignalingstatechange;
    pc.oniceconnectionstatechange = this.oniceconnectionstatechange;
    pc.onicegatheringstatechange = this.onicegatheringstatechange;

    pc.ontrack = this.handleOnTrack;
    pc.onicecandidate = this.onicecandidate;
  };

  generateAggregatedStatsFunction = () => {
    if (!this.aggregatedStats) this.aggregatedStats = {} as StreamingStats;

    return (stats: RTCStatsReport) => {
      const newStat: StreamingStats = {} as StreamingStats;
      stats.forEach((stat) => {
        if (
          stat.type === "inbound-rtp" &&
          !stat.isRemote &&
          (stat.mediaType === "video" ||
            stat.id.toLowerCase().includes("video"))
        ) {
          newStat.timestamp = stat.timestamp;
          newStat.bytesReceived = stat.bytesReceived;
          newStat.framesDecoded = stat.framesDecoded;
          newStat.frameWidth = stat.frameWidth;
          newStat.frameHeight = stat.frameHeight;

          //Network Packet loss
          newStat.sessionPacketsLost = stat.packetsLost;
          newStat.sessionPacketsReceived = stat.packetsReceived;
          if (this.aggregatedStats) {
            newStat.currentPacketLostPercent =
              ((newStat.sessionPacketsLost -
                this.aggregatedStats.sessionPacketsLost) /
                (newStat.sessionPacketsReceived -
                  this.aggregatedStats.sessionPacketsReceived)) *
              100;
          }
          newStat.packetsLost = (stat.packetsLost / stat.packetsReceived) * 100;

          //Video Freezes
          newStat.sessionFreezeCount = stat.freezeCount;
          newStat.sessionTotalFreezesDuration = stat.totalFreezesDuration;
          newStat.sessionAvgFreezesDuration =
            stat.freezeCount > 0
              ? newStat.sessionTotalFreezesDuration / newStat.sessionFreezeCount
              : 0;

          newStat.framesDropped = stat.framesDropped;

          if (this.aggregatedStats) {
            newStat.currentFreezeCount =
              newStat.sessionFreezeCount -
              this.aggregatedStats.sessionFreezeCount;
            /* Percentage of time freezed during last second */
            const milisecondsFreezed =
              (newStat.sessionTotalFreezesDuration -
                this.aggregatedStats.sessionTotalFreezesDuration) *
              1000;
            newStat.currentFreezeDurationPercent =
              (milisecondsFreezed / 1000) * 100;
          }

          //Jitter
          newStat.jitter = stat.jitter;
          newStat.jitterBufferDelay = stat.jitterBufferDelay;
          newStat.jitterBufferEmittedCount = stat.jitterBufferEmittedCount;
          newStat.jitterBufferDelayAvg =
            stat.jitterBufferDelay / stat.jitterBufferEmittedCount;

          if (this.aggregatedStats) {
            const delayGap =
              stat.jitterBufferDelay - this.aggregatedStats.jitterBufferDelay;
            const emitedCount =
              stat.jitterBufferEmittedCount -
              this.aggregatedStats.jitterBufferEmittedCount;
            newStat.currentJitterBufferDelay = (delayGap * 1000) / emitedCount; //ms
          }

          //Processing delay
          if (this.aggregatedStats) {
            const delay =
              stat.totalProcessingDelay -
              this.aggregatedStats.totalProcessingDelay;
            const frames =
              stat.framesDecoded - this.aggregatedStats.framesDecoded;
            newStat.currentProcessingDelay = (delay / frames) * 1000; //ms
          }
          newStat.sessionAvgProcessingDelay =
            (stat.totalProcessingDelay / stat.framesDecoded) * 1000; //ms

          //Decoding
          newStat.totalDecodeTime = stat.totalDecodeTime;
          newStat.sessionAvgDecodingDelay =
            (stat.totalDecodeTime / stat.framesDecoded) * 1000; //ms
          if (this.aggregatedStats) {
            const delay =
              stat.totalDecodeTime - this.aggregatedStats.totalDecodeTime;
            const frames =
              stat.framesDecoded - this.aggregatedStats.framesDecoded;
            newStat.currentDecodeDelay = (delay / frames) * 1000; //ms
          }

          newStat.totalInterFrameDelay = stat.totalInterFrameDelay;
          newStat.totalProcessingDelay = stat.totalProcessingDelay;
          newStat.bytesReceivedStart =
            this.aggregatedStats && this.aggregatedStats.bytesReceivedStart
              ? this.aggregatedStats.bytesReceivedStart
              : stat.bytesReceived;
          newStat.framesDecodedStart =
            this.aggregatedStats && this.aggregatedStats.framesDecodedStart
              ? this.aggregatedStats.framesDecodedStart
              : stat.framesDecoded;
          newStat.timestampStart =
            this.aggregatedStats && this.aggregatedStats.timestampStart
              ? this.aggregatedStats.timestampStart
              : stat.timestamp;

          if (this.aggregatedStats && this.aggregatedStats.timestamp) {
            if (this.aggregatedStats.bytesReceived) {
              // bitrate = bits received since last time / number of ms since last time
              //This is automatically in kbits (where k=1000) since time is in ms and stat we want is in seconds (so a '* 1000' then a '/ 1000' would negate each other)
              newStat.bitrate =
                (8 *
                  (newStat.bytesReceived -
                    this.aggregatedStats.bytesReceived)) /
                (newStat.timestamp - this.aggregatedStats.timestamp);
              newStat.bitrate = Math.floor(newStat.bitrate);
              newStat.lowBitrate =
                this.aggregatedStats.lowBitrate &&
                this.aggregatedStats.lowBitrate < newStat.bitrate
                  ? this.aggregatedStats.lowBitrate
                  : newStat.bitrate;
              newStat.highBitrate =
                this.aggregatedStats.highBitrate &&
                this.aggregatedStats.highBitrate > newStat.bitrate
                  ? this.aggregatedStats.highBitrate
                  : newStat.bitrate;
            }

            if (this.aggregatedStats.bytesReceivedStart) {
              newStat.avgBitrate =
                (8 *
                  (newStat.bytesReceived -
                    this.aggregatedStats.bytesReceivedStart)) /
                (newStat.timestamp - this.aggregatedStats.timestampStart);
              newStat.avgBitrate = Math.floor(newStat.avgBitrate);
            }

            if (this.aggregatedStats.framesDecoded) {
              newStat.framerate = Math.floor(stat.framesPerSecond);
              newStat.lowFramerate =
                this.aggregatedStats.lowFramerate &&
                this.aggregatedStats.lowFramerate < newStat.framerate
                  ? this.aggregatedStats.lowFramerate
                  : newStat.framerate;
              newStat.highFramerate =
                this.aggregatedStats.highFramerate &&
                this.aggregatedStats.highFramerate > newStat.framerate
                  ? this.aggregatedStats.highFramerate
                  : newStat.framerate;
            }

            if (this.aggregatedStats.framesDecodedStart) {
              newStat.avgframerate =
                (newStat.framesDecoded -
                  this.aggregatedStats.framesDecodedStart) /
                ((newStat.timestamp - this.aggregatedStats.timestampStart) /
                  1000);
              newStat.avgframerate = Math.floor(newStat.avgframerate);
            }
          }
        }

        /*
        //Read video track stats
        if (
          stat.type === "track" &&
          (stat.trackIdentifier === "video_label" || stat.kind === "video")
        ) {
          newStat.framesReceived = stat.framesReceived;
          newStat.framesDroppedPercentage =
            (stat.framesDropped / stat.framesReceived) * 100;
          newStat.frameHeight = stat.frameHeight;
          newStat.frameWidth = stat.frameWidth;
          newStat.frameHeightStart =
            this.aggregatedStats && this.aggregatedStats.frameHeightStart
              ? this.aggregatedStats.frameHeightStart
              : stat.frameHeight;
          newStat.frameWidthStart =
            this.aggregatedStats && this.aggregatedStats.frameWidthStart
              ? this.aggregatedStats.frameWidthStart
              : stat.frameWidth;
        }
        */

        if (stat.type === "candidate-pair") {
          if (
            stat.state === "succeeded" &&
            stat.hasOwnProperty("currentRoundTripTime")
          ) {
            newStat.currentRoundTripTime = stat.currentRoundTripTime * 1000; //ms
          }
        }
      });
      this.aggregatedStats = newStat;
      if (this.onAggregatedStats) this.onAggregatedStats(newStat);
    };
  };

  //**********************
  // Public functions
  //**********************

  /**
   * This is called when receiving new ICE candidate indvidually,
   * instead of as part of the offer. These  typically contain the
   * address of the remote Journee server.
   */
  handleCandidateFromSignalingServer = (
    iceCandidate: RTCIceCandidateInit | RTCIceCandidate
  ) => {
    const candidate = new RTCIceCandidate(iceCandidate);
    log("WEBRTC", `ICE candidate (remote): ${getCandidateString(candidate)}`);

    if (!this.pcClient) {
      logWarn("WEBRTC", "Missing RTCPeerConnection to add candidate.");
      return;
    }

    this.pcClient.addIceCandidate(candidate).catch((e) => {
      logError(
        "WEBRTC",
        `Failed to add ICE candidate from server:`,
        e,
        iceCandidate
      );
    });
  };

  /**
   * Called externally to create an offer for the server
   */
  createOffer = () => {
    if (this.pcClient) {
      log("WEBRTC", "Closing existing PeerConnection");
      this.pcClient.close();
      this.pcClient = null;
    }
    this.pcClient = new RTCPeerConnection(this.cfg);
    this.setupPeerConnection(this.pcClient);
    this.dcClient = this.setupDataChannel(
      this.pcClient,
      "cirrus",
      this.dataChannelOptions
    );
    this.handleCreateOffer(this.pcClient);
  };

  setupTransceiversAsync = async (pc: RTCPeerConnection) => {
    // Setup a transceiver for getting UE video
    pc.addTransceiver("video", { direction: "recvonly" });

    // Setup a transceiver for sending mic audio to UE and receiving audio from UE
    pc.addTransceiver("audio", { direction: "recvonly" });
  };

  mungeSDP = (offer: RTCSessionDescriptionInit) => {
    let audioSDP = "";

    // set max bitrate to highest bitrate Opus supports
    audioSDP += "maxaveragebitrate=510000;";

    // enable in-band forward error correction for opus audio
    audioSDP += "useinbandfec=1";

    // We use the line 'useinbandfec=1' (which Opus uses) to set our Opus specific audio parameters.
    offer.sdp = offer.sdp?.replace("useinbandfec=1", audioSDP);
  };

  onWebRtcAnswer = (answer: RTCSessionDescription) => {
    if (ws && ws.readyState === 1) {
      const answerStr = JSON.stringify(answer);
      log(
        "WEBRTC",
        "%c[Outbound SS message (answer)]",
        "background: lightgreen; color: black",
        answer
      );
      ws.send(answerStr);
    }
  };

  /**
   * Called externally when an offer is received from the server
   */
  receiveOffer = (offer: RTCSessionDescriptionInit) => {
    if (this.pcClient) return;

    log("WEBRTC", "Creating a new PeerConnection in the browser.");
    this.pcClient = new RTCPeerConnection(this.cfg);
    this.setupPeerConnection(this.pcClient);

    // Put things here that happen post transceiver setup
    this.pcClient.setRemoteDescription(offer).then(() => {
      this.setupTransceiversAsync(this.pcClient as RTCPeerConnection).finally(
        () => {
          this.pcClient
            ?.createAnswer()
            .then((answer) => {
              this.mungeSDP(answer);
              return this.pcClient?.setLocalDescription(answer);
            })
            .then(() => {
              if (this.onWebRtcAnswer) {
                this.onWebRtcAnswer(
                  this.pcClient
                    ?.currentLocalDescription as RTCSessionDescription
                );
              }
            })
            .then(() => {
              const receivers =
                this.pcClient?.getReceivers() as RTCRtpReceiver[];
              for (const receiver of receivers) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                receiver.playoutDelayHint = 0;
              }
            })
            .catch((error) =>
              logError("WEBRTC", "createAnswer() failed:", error)
            );
        }
      );
    });
  };

  /**
   * Called externally when an answer is received from the server
   */
  receiveAnswer = (answer: RTCSessionDescriptionInit) => {
    log("WEBRTC", `Received answer:`, answer);
    const answerDesc = new RTCSessionDescription(answer);
    this.pcClient?.setRemoteDescription(answerDesc);
  };

  close = () => {
    if (this.pcClient) {
      log("WEBRTC", "Closing existing peerClient");
      this.pcClient.close();
      this.pcClient = null;
    }
    if (this.aggregateStatsIntervalId)
      clearInterval(this.aggregateStatsIntervalId);
  };

  /**
   * Send data across the datachannel
   * @param data
   * @returns true if there was an error, false if the message was sent.
   */
  send = (data: string | ArrayBuffer): boolean => {
    if (!this.dcClient) {
      log(
        "WEBRTC",
        "Could not send descriptor: datachannel is not initialized"
      );
      return true;
    }
    if (this.dcClient.readyState !== "open") {
      log("WEBRTC", "Could not send descriptor: Data Channel isn't ready.");
      return true;
    }
    this.dcClient.send(data as string);
    return false;
  };

  getStats = (onStats: (stats: RTCStatsReport) => void) => {
    if (this.pcClient && onStats) {
      this.pcClient.getStats(null).then((stats) => {
        onStats(stats);
      });
    }
  };

  aggregateStats = (checkInterval: number) => {
    const calcAggregatedStats = this.generateAggregatedStatsFunction();
    const printAggregatedStats = () => {
      this.getStats(calcAggregatedStats);
    };
    // TODO: Should we always collect stats, or only when requested?
    this.aggregateStatsIntervalId = window.setInterval(
      printAggregatedStats,
      checkInterval
    );
  };

  // For external use.
  getPCStats = async () => {
    return await this?.pcClient?.getStats();
  };

  getSelectedCandidatePair = () => {
    // Solution from: https://stackoverflow.com/a/64172130/1017472
    // This only works in Chromium, not standard yet.
    // TODO: It's possible to add Firefox support.
    const iceTransport = this.pcClient?.sctp?.transport?.iceTransport;
    if (!iceTransport || !iceTransport.getSelectedCandidatePair) return;
    return iceTransport.getSelectedCandidatePair();
  };

  setAudioQuality = (quality: AudioQuality) => {
    this.audioBitrate = WebRtcPlayer.audioQualityBitRateMap[quality];
  };
}

export default WebRtcPlayer;
