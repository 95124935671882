import { useRef, useState } from "react";
import { GyaradosSDK } from "@journee-live/gyarados";
import * as Form from "@radix-ui/react-form";
import { styled, useTheme } from "styled-components";
import Button from "../../../../components-ui/atoms/Button";
import Checkbox from "../../../../components-ui/atoms/Checkbox";
import Column from "../../../../components-ui/atoms/Column";
import Icon, { IconName } from "../../../../components-ui/atoms/Icon";
import InputText from "../../../../components-ui/atoms/InputText";
import Markdown from "../../../../components-ui/atoms/Markdown";
import RadioButton from "../../../../components-ui/atoms/RadioButton";
import Space from "../../../../components-ui/atoms/Space";
import StarsRating from "../../../../components-ui/atoms/StarsRating";
import Transition from "../../../../components-ui/atoms/Transition";
import Typo, { TypographyNames } from "../../../../components-ui/atoms/Typo";
import { PanelTestIds } from "../../../../constants/testIds";
import { useEnvironmentContext } from "../../../../core/EnvironmentFetcher.core";
import { sendGameMessage } from "../../../../core/gameConnection/webrtc/webRtcMessageHandlers";
import { useText } from "../../../../core/i18n/i18nHooks";
import { VisitorTokenData } from "../../../login/loginUtils";
import PollCardHeader from "../component/PollCardHeader";
import useGetPollResultsApi from "./useGetPollResultsApi";

const pollResponseTypes = {
  Text: "text",
  MultipleChoices: "multipleChoices",
  SingleChoice: "singleChoice",
  Stars: "stars",
} as const;

const Hide = styled.div<{
  $hide: boolean;
  $height?: number;
  $hideOverflow?: boolean;
  $heightSpeed?: number;
}>`
  height: ${(p) => (p.$hide ? "0px" : p.$height + "px")};
  opacity: ${(p) => (p.$hide ? 0 : 1)};
  ${(p) => p.$hideOverflow && "overflow: hidden;"}

  transition:
    height ${(p) => p.$heightSpeed || 700}ms,
    opacity 300ms;
`;

const useGetPollElements = (params: {
  poll: GyaradosSDK.PollResponseDto | undefined;
  handleDismiss: () => void;
  isLeftAlign?: boolean;
  visitorToken: string | null;
  visitorTokenData: VisitorTokenData | null;
  roomId: string | null;
  playerId: number | null;
  clientPersistedId: string | null;
}): {
  Header: React.ReactNode;
  Body: React.ReactNode;
  Button: React.ReactNode;
  Title: React.ReactNode;
  Subtitle: React.ReactNode;
  ErrorMessage: React.ReactNode;
} => {
  const {
    poll,
    handleDismiss,
    isLeftAlign,
    visitorToken,
    visitorTokenData,
    roomId,
    playerId,
    clientPersistedId,
  } = params;
  const formRef = useRef<HTMLFormElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const t = useText();
  const theme = useTheme();
  const { environment } = useEnvironmentContext();

  const pollResultsApi = useGetPollResultsApi();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>("");
  const [validationError, setValidationError] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const columnAlign = isLeftAlign ? "flex-start" : "center";
  const textAlign = isLeftAlign ? "left" : "center";
  let elements: {
    answerType: string;
    title: string;
    header: Extract<IconName, "Stars" | "Poll" | "Warning">;
    body: React.ReactNode;
  };

  const handleOnSubmit = async () => {
    if (submitError || isSubmitted) handleDismiss();
    setIsLoading(true);
    setSubmitError("");
    const formData = new FormData(formRef.current || undefined);
    const userEntries = Array.from(formData.entries()).map(([, value]) =>
      value.toString()
    );

    if (
      userEntries.length === 0 ||
      userEntries[0] === "0" ||
      (userEntries.length === 1 && userEntries[0] === "")
    ) {
      setValidationError(t("poll_answer_question"));
      setIsLoading(false);
      return null;
    }

    if (visitorToken) {
      try {
        if (
          visitorTokenData?.id != null &&
          environment.id != null &&
          environment.partitionId != null &&
          environment.organisationId != null &&
          environment.projectId != null &&
          poll
        ) {
          await pollResultsApi.pollResultsControllerCreate(
            {
              environmentId: environment.id,
              body: {
                pollId: poll.id,
                userEntries,
                photonPlayerId:
                  playerId != null ? playerId.toString() : "undefined",
                photonRoomId: roomId || "",
                localStorageId: clientPersistedId || "",
                userId: visitorTokenData.id,
                partitionId: environment.partitionId,
                organisationId: environment.organisationId,
                projectId: environment.projectId,
                environmentId: environment.id,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${visitorToken}`,
              },
            }
          );
          const entries = userEntries.join("|");
          sendGameMessage({
            type: "PollResultSubmitted",
            userId: visitorTokenData.id,
            slug: poll.pollId,
            entries,
          });
          window.analytics?.track("poll", {
            type: "poll",
            name: poll.pollId,
            detail: entries,
          });
        }
      } catch (error) {
        setTimeout(() => {
          setSubmitError("There was an error submitting your poll.");
        }, 2000);
      } finally {
        // fake loading
        setTimeout(() => {
          setIsLoading(false);
          setIsSubmitted(true);
        }, 2000);
      }
    }
  };

  // get the right elements for the poll type
  switch (poll?.responseType) {
    case pollResponseTypes.MultipleChoices:
      elements = {
        header: "Poll",
        title: poll?.title || t("poll_title_default"),
        body: (
          <Form.Root ref={formRef} onSubmit={(e) => e.preventDefault()}>
            {poll?.choices.map?.((choice, idx) => (
              <div key={choice.name}>
                <Checkbox
                  testId={PanelTestIds.poll.multipleChoices}
                  name={choice.name}
                  value={choice.name}
                  label={choice.label}
                  checked={false}
                  onChange={() => setValidationError("")}
                />
                {idx + 1 !== poll.choices.length && <Space h={1} />}
              </div>
            ))}
            {poll?.showTextAreaForChoices && (
              <>
                {Boolean(poll?.choices.length) && <Space h={4} />}
                <InputText
                  textarea
                  inputTestId={PanelTestIds.poll.multipleChoicesInput}
                  name="poll_comment"
                  label={t("poll_comment")}
                  onChange={() => setValidationError("")}
                />
              </>
            )}
          </Form.Root>
        ),
        answerType: "Answer",
      };
      break;

    case pollResponseTypes.Stars:
      elements = {
        header: "Stars",
        title: poll?.title || t("poll_title_stars"),
        body: (
          <Column align="center">
            <Form.Root ref={formRef} onSubmit={(e) => e.preventDefault()}>
              <StarsRating
                testId={PanelTestIds.poll.stars}
                onChange={() => setValidationError("")}
              />
            </Form.Root>
          </Column>
        ),
        answerType: "Rating",
      };
      break;
    case pollResponseTypes.SingleChoice:
      elements = {
        header: "Poll",
        title: poll?.title || t("poll_title_default"),
        body: (
          <Form.Root ref={formRef} onSubmit={(e) => e.preventDefault()}>
            {poll?.choices.map?.((choice, idx) => (
              <div key={choice.name}>
                <RadioButton
                  testId={PanelTestIds.poll.singleChoice}
                  key={choice.name}
                  value={choice.name}
                  name={poll.responseType}
                  label={choice.label}
                  onChange={() => setValidationError("")}
                />
                {idx + 1 !== poll.choices.length && <Space h={1} />}
              </div>
            ))}
            {poll?.showTextAreaForChoices && (
              <>
                {Boolean(poll?.choices.length) && <Space h={4} />}
                <InputText
                  textarea
                  name="poll_comment"
                  label={t("poll_comment")}
                  onChange={() => setValidationError("")}
                />
              </>
            )}
          </Form.Root>
        ),
        answerType: "Answer",
      };
      break;

    case pollResponseTypes.Text:
    default:
      elements = {
        header: "Poll",
        title: poll?.title || t("poll_title_default"),
        body: <>{"Implementation of the other poll Response types"}</>,
        answerType: "Answer",
      };
  }

  const getButton = () => {
    if (isLoading)
      return (
        <Button.Secondary
          testId={PanelTestIds.poll.submitLoading}
          large
          iconLeft={
            <Icon.SpinningAnimated size="10px" color={theme.colorAbove4} />
          }
          disabled
        >
          {t("poll_loading")}
        </Button.Secondary>
      );
    if (isSubmitted)
      return (
        <Button.Primary
          onClick={handleDismiss}
          testId={PanelTestIds.poll.submitDone}
        >
          {t("poll_close")}
        </Button.Primary>
      );

    return (
      <Button.Primary
        onClick={handleOnSubmit}
        testId={PanelTestIds.poll.submit}
      >
        {t("poll_submit")}
      </Button.Primary>
    );
  };

  const getTitle = () => {
    if (submitError) return t("poll_title_error");
    if (isSubmitted) return t("poll_title_submitted");
    return elements.title;
  };

  // check state and add animations
  return {
    Header: (
      <Transition
        align="start"
        watch={[submitError]}
        height="70px"
        width="100%"
        speed={500}
      >
        <PollCardHeader icon={submitError ? "Warning" : elements.header} />
      </Transition>
    ),
    Title: (
      <Transition
        align="start"
        watch={[isSubmitted]}
        height="30px"
        width="100%"
        speed={500}
      >
        <Column align={columnAlign}>
          <Typo.Title testId={PanelTestIds.poll.titleError}>
            {getTitle()}
          </Typo.Title>
        </Column>
      </Transition>
    ),
    Body: (
      <Hide
        ref={bodyRef}
        $height={bodyRef.current?.clientHeight}
        $hide={isSubmitted}
      >
        <Column align={columnAlign}>
          <Typo.Body align={textAlign}>{poll?.question}</Typo.Body>
          {poll?.text && (
            <>
              <Space h={2} />
              <Markdown
                BodyTypographyStyle={TypographyNames.Note}
                textAlign={textAlign}
                content={poll.text}
              />
            </>
          )}
        </Column>
        <Space h={5} />
        {elements.body}
      </Hide>
    ),
    Button: (
      <>
        <Hide $hide={isSubmitted} $height={16}>
          <Space h={4} />
        </Hide>
        <Space h={5} />
        <Transition
          watch={[isSubmitted, isLoading]}
          height="50px"
          width="100%"
          speed={500}
        >
          <Column align={columnAlign}>{getButton()}</Column>
        </Transition>
      </>
    ),
    Subtitle: (
      <Hide $hide={!isSubmitted} $height={submitError ? 40 : 20}>
        <Column align={columnAlign}>
          <Typo.Body align={textAlign} testId={PanelTestIds.poll.subtitleError}>
            {submitError
              ? t("poll_subtitle_error")
              : t("poll_subtitle_submitted")}
          </Typo.Body>
        </Column>
      </Hide>
    ),
    ErrorMessage: (
      <Hide $hide={!validationError} $height={20} $heightSpeed={300}>
        <Column align={columnAlign}>
          <Typo.Label
            color={theme.colorDanger}
            testId={PanelTestIds.poll.errorMessage}
          >
            {validationError}
          </Typo.Label>
        </Column>
      </Hide>
    ),
  };
};

export default useGetPollElements;
