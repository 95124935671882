import { useEffect, useState } from "react";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { useStore } from "../../../store/store";

const WalletConnectModalLogic: React.FC = () => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const [isShown, setIsShown] = useState<boolean>(false);
  const { open, close } = useWeb3Modal();
  const { open: openState } = useWeb3ModalState();

  useEffect(() => {
    if (isShown) return;
    open();
    setIsShown(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!openState && isShown) {
      closePanel("walletConnect");
      close();
      openPanel("settings/walletconnect");
      setIsShown(false);
    }
  }, [openState, closePanel, close, openPanel, isShown]);

  return null;
};

export default WalletConnectModalLogic;
