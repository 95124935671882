import { ReactNode, useEffect, useRef, useState } from "react";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import styled, { css } from "styled-components";
import useResizeObserver from "use-resize-observer";
import ArrowButton from "../molecules/ArrowButton";
import Space from "./Space";

const ScrollAreaRoot = styled(ScrollArea.Root)`
  position: relative;
  width: 100%;
  height: auto;
`;

const ScrollBottomWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: max(var(--safe-margin-right), 64px);
  z-index: 1;
`;

const ScrollAreaViewport = styled(ScrollArea.Viewport)<{
  $height: string;
  $maxHeight: string;
  $fadeTop?: boolean;
  $fadeBottom?: boolean;
}>`
  width: 100%;
  height: ${(p) => p.$height};
  ${(p) =>
    p.$maxHeight &&
    css`
      max-height: ${p.$maxHeight};
    `};
  min-width: 0;
  display: flex;
  box-sizing: border-box;
  ${(p) => {
    if (!p.$fadeBottom && !p.$fadeTop) return "";
    const gradient = `linear-gradient(
        to bottom,
        ${p.$fadeTop ? "transparent" : "black"} 0%,
        black 100px,
        black calc(100% - 30px),
        ${p.$fadeBottom ? "transparent" : "black"} 100%
      )`;
    return css`
      mask-image: ${gradient};
    `;
  }}

  // ScrollArea.Viewport adds a div as a child. We also
  // set its height so the childRef 100% height works.
  & > div {
    block-size: ${(p) => p.$height};
  }
`;

const ScrollAreaScrollbar = styled(ScrollArea.Scrollbar)<{
  $barPaddingTop: number;
  $barPaddingBottom: number;
}>`
  display: flex;
  /* ensures no selection */
  user-select: none;
  /* disable browser handling of all panning and zooming gestures on touch devices */
  touch-action: none;
  background: transparent;
  position: relative;
  box-sizing: content-box;
  /* The scrollbar needs some complex behaviour to stop at the right points
  and never overlapps with the rounded corners and close button. */
  ${(p) => {
    return `height: calc(100% - ${p.$barPaddingTop + p.$barPaddingBottom}px);
padding-top: ${p.$barPaddingTop}px;`;
  }}
`;

const ScrollAreaThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  position: relative;
  width: 6px !important;
  background-color: ${(p) => p.theme.colorAbove2};
  border-radius: ${(p) => p.theme.radiusSmall};

  ::before {
    content: "";
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
    inline-size: 100%;
    block-size: 100%;
    min-inline-size: 44px;
    min-block-size: 44px;
  }
`;

export type Props = {
  height?: string;
  maxHeight?: string;
  barPaddingTop?: number;
  barPaddingBottom?: number;
  children: ReactNode;
  fadeTop?: boolean;
  showFade?: boolean;
  /** Hides the scroll to bottom button. */
  hideScrollToBottom?: boolean;
  /** Hides the space at the bottom of the scroll area. */
  hideScrollSpace?: boolean;
};

/** A scroll area with a pretty scrollbar and auto fading. */
const Scroll: React.FC<Props> = ({
  height,
  maxHeight,
  barPaddingTop,
  barPaddingBottom,
  fadeTop,
  showFade,
  hideScrollToBottom,
  hideScrollSpace,
  children,
}) => {
  const nullElementRef = useRef<HTMLDivElement>(null);

  const { ref: childRef, height: childHeight } =
    useResizeObserver<HTMLDivElement>();
  const { ref: srcollRef, height: srcollHeight } =
    useResizeObserver<HTMLDivElement>();

  const [isScrollNeeded, setIsScrollNeeded] = useState(false);

  useEffect(() => {
    if (childHeight && srcollHeight)
      // We keep 30px tollerance to avoid prompting the user for micro scrolls.
      setIsScrollNeeded(childHeight > srcollHeight + 30);
  }, [childHeight, srcollHeight]);

  const onScrollToBottomClick: React.MouseEventHandler = (
    e: React.MouseEvent
  ) => {
    e.preventDefault();
    if (nullElementRef.current) {
      nullElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  return (
    <ScrollAreaRoot>
      <ScrollBottomWrapper>
        {!hideScrollToBottom && isScrollNeeded && (
          <ArrowButton
            rotate="180deg"
            onClick={onScrollToBottomClick}
            size="40px"
          />
        )}
      </ScrollBottomWrapper>
      <ScrollAreaViewport
        $fadeBottom={isScrollNeeded || showFade}
        $fadeTop={(isScrollNeeded && fadeTop) || showFade}
        ref={srcollRef}
        $height={height ?? "100%"}
        $maxHeight={maxHeight ?? "100%"}
      >
        <div
          style={{
            height: "100%",
          }}
          ref={childRef}
        >
          {children}
        </div>
        {isScrollNeeded && !hideScrollSpace && <Space h={5} />}
        <div ref={nullElementRef} />
      </ScrollAreaViewport>
      {/* Keep the scrollbar visible by default on touch devices, if there the content is scrollable. */}
      <ScrollAreaScrollbar
        orientation="vertical"
        $barPaddingTop={barPaddingTop || 3}
        $barPaddingBottom={barPaddingBottom || 3}
      >
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
      <ScrollArea.Corner />
    </ScrollAreaRoot>
  );
};

export default Scroll;
