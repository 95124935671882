import { useMemo } from "react";
import { t } from "i18next";
import Button from "../../../components-ui/atoms/Button";
import Column from "../../../components-ui/atoms/Column";
import Glass from "../../../components-ui/atoms/Glass";
import Hide from "../../../components-ui/atoms/Hide";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import {
  isAndroid,
  isIFrame,
  isInstagramAgent,
  isIos,
  isIpad,
} from "../../../constants/flags";
import { useIsLandscape, useIsSmallScreen } from "../../../hooks/ui";
import useFullscreenMode from "../../../hooks/useFullscreenMode";
import { useStore } from "../../../store/store";
import { zIndex } from "../../../style/theme";
import useDetectIsScrolledToBottom from "./useDetectIsScrolledToBottom";
import useDetectSafariAddressBar from "./useDetectSafariAddressBar";

const EXTRA_SCROLL_HEIGHT = 40;

const FullscreenHelper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const isProfilePanelVisible = useStore(
    (s) => s.layout.panels.profile.visible
  );
  const isPollVisible = useStore((s) => s.layout.panels.poll.visible);
  const hasTextInput = isProfilePanelVisible || isPollVisible;
  const isLandscape = useIsLandscape();

  // Swipe up helper should be shown in landscape mode only on iPhones (not on iPads).
  const hasSafariAddressBar = useDetectSafariAddressBar();
  const isScrolledToBottom = useDetectIsScrolledToBottom();
  const hideSwipeUp = useMemo(() => {
    const showSwipeUp = isLandscape && currentStep === "experience:ready";
    const isScrollNeeded = hasSafariAddressBar && !isScrolledToBottom;
    return !showSwipeUp || !isScrollNeeded;
  }, [isLandscape, currentStep, hasSafariAddressBar, isScrolledToBottom]);

  const SwipeUp = () => (
    <Hide
      hide={hideSwipeUp}
      speed={600}
      unMount
      width="100%"
      style={{
        position: "relative",
        // Swipe up should be under panels with text input
        // so it doesn't cover the inputs when the keyboard is open
        ...(!hasTextInput && { zIndex: zIndex.overlays }),
      }}
    >
      <Glass
        borderRadius="0px"
        height={EXTRA_SCROLL_HEIGHT + 100 + "vh"}
        width="100%"
      >
        <Column align="center" width="100%" height="100%">
          <Space h={10} />
          <Space h={10} />
          <Typo.Title>{t("login_swipe_up")}</Typo.Title>
        </Column>
      </Glass>
    </Hide>
  );

  // Fullscreen helper should be shown in landscape mode only on Android mobile devices (excluding tablets)
  // and only if they support Fullscreen WebAPI.
  const { canFullscreen, toggleFullscreen } = useFullscreenMode();
  const { isFullscreen } = useStore((s) => s.ui.fullscreen);
  const hideFullscreenButton = useMemo(() => {
    return (
      !isLandscape ||
      isFullscreen ||
      currentStep !== "experience:ready" ||
      isInstagramAgent
    );
  }, [isLandscape, isFullscreen, currentStep]);

  const FullscreenButton = () => (
    <Hide hide={hideFullscreenButton} speed={600} unMount overlay width="100%">
      <Glass borderRadius="0px" height="100dvh" width="100%">
        <Column justify="center" align="center" width="100%" height="100%">
          <Button.Secondary onClick={toggleFullscreen}>
            {t("login_go_fullscreen")}
          </Button.Secondary>
        </Column>
      </Glass>
    </Hide>
  );

  // Constants below depends on a device,
  // in opposite to isLandscape for example, that may change during a session.
  const isSmallScreen = useIsSmallScreen();
  const includeSwipeUp = isIos && !isIpad && !isIFrame;
  const includeFullscreen =
    isAndroid && isSmallScreen && canFullscreen && !isIFrame;
  return (
    <>
      {children}
      {includeSwipeUp && <SwipeUp />}
      {includeFullscreen && <FullscreenButton />}
    </>
  );
};

export default FullscreenHelper;
