import { MouseEventHandler, ReactNode, forwardRef } from "react";
import { useTheme } from "styled-components";
import Clickable from "../atoms/Clickable";
import Typo from "../atoms/Typo";

export type Props = {
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  gap?: number;
  testId?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  bright?: boolean;
  padding?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
} & React.ComponentPropsWithoutRef<"button">;

const ButtonText = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      gap = 1,
      disabled,
      onClick,
      iconLeft,
      iconRight,
      bright,
      testId,
      padding,
      height,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    return (
      <Clickable
        testId={testId}
        buttonProps={rest}
        ref={ref}
        onClick={onClick}
        gap={gap}
        padding={padding}
        height={height}
        disabled={disabled}
        color={bright ? theme.colorBelow0 : theme.colorAbove2}
        hoverColor={bright ? theme.colorBelow2 : theme.colorAbove5}
        backgroundColor="transparent"
        hoverBackground="transparent"
      >
        {iconLeft}
        <Typo.Note color="inherit">{children}</Typo.Note>
        {iconRight}
      </Clickable>
    );
  }
);

export default ButtonText;
