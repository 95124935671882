import defaultTranslations from "../../../public/locales/en/translation.json";

// We use the english translation JSON file to
// generate all our language autocomplete keys.
type KeyList<T> = { readonly [P in keyof T]: P };

// Convert the JSON file to a keylist that can autocomplete.
const i18nKeys: { [key: string]: string } = {};
for (const key in defaultTranslations) i18nKeys[key] = key;

export default i18nKeys as KeyList<typeof defaultTranslations>;
