import { ConferenceError } from "../../hooks/useConferenceErrors";

export type UpdatedList = {
  list: {
    videoInput: MediaDeviceInfo[];
    audioInput: MediaDeviceInfo[];
    audioOutput: MediaDeviceInfo[];
  };
};

export interface IMediaDevicesService {
  onDeviceListChanged(handler: (updatedList: UpdatedList) => void): () => void;
}

export default class MediaDevicesService implements IMediaDevicesService {
  enumerateDevicesTimer: number | null = null;

  public static async enumerateMediaDevices() {
    return window.navigator.mediaDevices.enumerateDevices();
  }

  public static async enumerateVideoInputDevices() {
    const mediaDevices = await MediaDevicesService.enumerateMediaDevices();
    return mediaDevices.filter((device) => device.kind === "videoinput");
  }

  public static async enumerateAudioInputDevices() {
    const mediaDevices = await MediaDevicesService.enumerateMediaDevices();
    return mediaDevices.filter((device) => device.kind === "audioinput");
  }

  public static async enumerateAudioOutputDevices() {
    const mediaDevices = await MediaDevicesService.enumerateMediaDevices();
    return mediaDevices.filter((device) => device.kind === "audiooutput");
  }

  public listenForDeviceChanges(handler: (updatedList: UpdatedList) => void) {
    if (!this.enumerateDevicesTimer) {
      this.enumerateDevicesTimer = window.setInterval(() => {
        this.deviceChangeHandler(handler);
      }, 1000);
    }
    return () => {
      if (this.enumerateDevicesTimer) {
        window.clearInterval(this.enumerateDevicesTimer);
        this.enumerateDevicesTimer = null;
      }
    };
  }

  public async deviceChangeHandler(
    handler: (updatedList: UpdatedList) => void
  ) {
    const windowMediaDevices =
      await MediaDevicesService.enumerateMediaDevices();
    const videoInput = windowMediaDevices.filter(
      (device) => device.kind === "videoinput"
    );
    const audioInput = windowMediaDevices.filter(
      (device) => device.kind === "audioinput"
    );
    const audioOutput = windowMediaDevices.filter(
      (device) => device.kind === "audiooutput"
    );
    handler({ list: { videoInput, audioInput, audioOutput } });
  }

  public onDeviceListChanged(handler: (updatedList: UpdatedList) => void) {
    if (!navigator.mediaDevices || navigator.mediaDevices.ondevicechange) {
      return () => {};
    }

    navigator.mediaDevices.ondevicechange = this.deviceChangeHandler.bind(
      this,
      handler
    );
    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }
  static async checkForCameraAvailability(
    hasCameras: boolean,
    hasCameraPermission: boolean,
    videoDeviceId?: string
  ): Promise<ConferenceError[]> {
    if (!hasCameras) {
      return ["noCameraAvailable"];
    }
    if (!hasCameraPermission) {
      return ["cameraAccessDenied"];
    }

    const isCameraStreamAvailable =
      await this.isCameraStreamAvailable(videoDeviceId);
    if (!isCameraStreamAvailable) {
      return ["cameraFeedError"];
    }
    return [];
  }

  static async isCameraStreamAvailable(videoDeviceId?: string) {
    try {
      await window.navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: videoDeviceId ? { exact: videoDeviceId } : undefined,
        },
      });
    } catch (error) {
      return false;
    }
    return true;
  }
}
