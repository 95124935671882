import { SliceCreator } from "../../store/store";

/**
 * Middleware to handle legacy implementation for loading levels Messages.
 */
const sliceName = "minting";

export type MintingState = {
  currentMintEventId: string | undefined;
  currentWalletId: string | undefined;
  setCurrentMintEventId: (mintEventId: string | undefined) => void;
  setCurrentWalletId: (walletId: string | undefined) => void;
};

type State = {
  minting: MintingState;
};

export const createMintingSlice: SliceCreator<State> = (set) => ({
  minting: {
    currentMintEventId: undefined,
    setCurrentMintEventId: (mintEventId: string | undefined) =>
      set(
        (state) => {
          state.minting.currentMintEventId = mintEventId;
        },
        false,
        sliceName + "/setCurrentMintEventId"
      ),

    currentWalletId: undefined,
    setCurrentWalletId: (walletId: string | undefined) =>
      set(
        (state) => {
          state.minting.currentWalletId = walletId;
        },
        false,
        sliceName + "/setCurrentWalletId"
      ),
  },
});
