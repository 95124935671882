import { styled } from "styled-components";
import Hide from "../../../components-ui/atoms/Hide";
import { useStore } from "../../../store/store";
import StatsLogic from "./Stats.logic";
import StatsLogicPersistent from "./Stats.logic.persistent";

const Layout = styled.div`
  position: absolute;
  top: 40px;
  right: max(var(--safe-margin-right), 24px);
  max-width: 416px;
`;

const StatsPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.stats.visible);

  return (
    <Layout id="layout-stats">
      <Hide hide={!visible} width="100%" height="100%" unMount>
        <StatsLogic />
      </Hide>
      <StatsLogicPersistent />
    </Layout>
  );
};

export default StatsPanel;
