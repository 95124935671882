import { NavigateFunction } from "react-router";

export const getQueryStrings = (): Record<string, string | undefined> => {
  return Object.fromEntries(new URLSearchParams(location.search));
};

export const getQueryStringsAsBooleans = (): Record<
  string,
  boolean | undefined
> => {
  const Queries = getQueryStrings();
  const QueriesBoolean: Record<string, boolean | undefined> = {};

  Object.keys(Queries).forEach((key) => {
    QueriesBoolean[key] = Boolean(Queries[key]);
  });

  return QueriesBoolean;
};

export const updateQueryString = (
  navigate: NavigateFunction,
  key: string,
  value?: string
) => {
  const url = new URL(window.location.href);
  if (value === undefined) {
    url.searchParams.delete(key);
  } else {
    url.searchParams.set(key, value);
  }
  navigate(
    {
      pathname: url.pathname,
      search: url.search,
    },
    { replace: true }
  );
};

export const removeQueryString = (navigate: NavigateFunction, key: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(key);

  navigate(
    {
      pathname: url.pathname,
      search: url.search,
    },
    { replace: true }
  );
};
