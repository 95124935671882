import { SvgProps } from "../../types/svg";

const PollsIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 5.42857C10.6321 5.42857 11.1429 5.93929 11.1429 6.57143C11.1429 7.20357 10.6321 7.71429 10 7.71429H6.57143C5.93929 7.71429 5.42857 7.20357 5.42857 6.57143C5.42857 5.93929 5.93929 5.42857 6.57143 5.42857H10ZM6.57143 7.14286H10C10.3143 7.14286 10.5714 6.88571 10.5714 6.57143C10.5714 6.25714 10.3143 6 10 6H6.57143C6.25714 6 6 6.25714 6 6.57143C6 6.88571 6.25714 7.14286 6.57143 7.14286ZM13.4286 8.85714C14.0607 8.85714 14.5714 9.36786 14.5714 10C14.5714 10.6321 14.0607 11.1429 13.4286 11.1429H6.57143C5.93929 11.1429 5.42857 10.6321 5.42857 10C5.42857 9.36786 5.93929 8.85714 6.57143 8.85714H13.4286ZM6.57143 10.5714H13.4286C13.7429 10.5714 14 10.3143 14 10C14 9.68571 13.7429 9.42857 13.4286 9.42857H6.57143C6.25714 9.42857 6 9.68571 6 10C6 10.3143 6.25714 10.5714 6.57143 10.5714ZM7.71429 12.2857C8.34643 12.2857 8.85714 12.7964 8.85714 13.4286C8.85714 14.0607 8.34643 14.5714 7.71429 14.5714H6.57143C5.93929 14.5714 5.42857 14.0607 5.42857 13.4286C5.42857 12.7964 5.93929 12.2857 6.57143 12.2857H7.71429ZM6.57143 14H7.71429C8.02857 14 8.28571 13.7429 8.28571 13.4286C8.28571 13.1143 8.02857 12.8571 7.71429 12.8571H6.57143C6.25714 12.8571 6 13.1143 6 13.4286C6 13.7429 6.25714 14 6.57143 14ZM15.7143 2C16.975 2 18 3.02321 18 4.28571V15.7143C18 16.975 16.975 18 15.7143 18H4.28571C3.02321 18 2 16.975 2 15.7143V4.28571C2 3.02321 3.02321 2 4.28571 2H15.7143ZM15.7143 2.57143H4.28571C3.33893 2.57143 2.57143 3.33893 2.57143 4.28571V15.7143C2.57143 16.6607 3.33893 17.4286 4.28571 17.4286H15.7143C16.6607 17.4286 17.4286 16.6607 17.4286 15.7143V4.28571C17.4286 3.33893 16.6607 2.57143 15.7143 2.57143Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PollsIcon;
