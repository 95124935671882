import { useCallback, useEffect } from "react";
import { useGetCurrentLevelVideoBySlug } from "../../../../api/gyaradosHooks";
import { log } from "../../../../lib/logger";
import { useStore } from "../../../../store/store";

const LegacyLoadLevelPanelLogic = () => {
  const levelVideoId = useStore((s) => s.loadLevelController.currentLevelId);
  const levelIsLoading = useStore((s) => s.loadLevelController.levelIsLoading);
  const resetState = useStore((s) => s.loadLevelController.resetState);
  const videoHasEndedOnce = useStore(
    (s) => s.loadLevelController.videoHasEndedOnce
  );
  const videoIsEnding = useStore((s) => s.loadLevelController.videoIsEnding);
  const videoError = useStore((s) => s.loadLevelController.videoError);

  const fullVideoSlug = useStore((s) => s.layout.panels.fullscreenVideo.slug);

  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const panelVisible = useStore((s) => s.layout.panels.fullscreenVideo.visible);
  const levelVideo = useGetCurrentLevelVideoBySlug(levelVideoId);

  useEffect(() => {
    if (fullVideoSlug && (videoIsEnding || videoHasEndedOnce || videoError)) {
      resetState();
    }
  }, [fullVideoSlug, resetState, videoIsEnding, videoHasEndedOnce, videoError]);

  const closeVideoPlayer = useCallback(() => {
    if (levelIsLoading) {
      log(
        "VIDEOPLAY",
        "Still loading a level, can't stop playing the level video."
      );
      return;
    }
    closePanel("fullscreenVideo", { slug: fullVideoSlug });
    closePanel("fullscreenVideo/skip", { slug: fullVideoSlug });
  }, [closePanel, levelIsLoading, fullVideoSlug]);

  // Display the skip video button as soon as it's allowed.
  useEffect(() => {
    if (!levelIsLoading && levelVideo && levelVideo.canBeSkipped) {
      openPanel("fullscreenVideo/skip", { slug: levelVideoId });
    }
  }, [
    levelIsLoading,
    levelVideo,
    levelVideo?.canBeSkipped,
    openPanel,
    levelVideoId,
  ]);

  // Automatically continue the experience as soon as
  // the level is loaded and the video is ending.
  useEffect(() => {
    if (!panelVisible) return;
    if (!levelIsLoading && (videoIsEnding || videoHasEndedOnce)) {
      closeVideoPlayer();
    }
  }, [
    closeVideoPlayer,
    levelIsLoading,
    videoIsEnding,
    videoHasEndedOnce,
    panelVisible,
  ]);

  useEffect(() => {
    if (levelIsLoading && levelVideo && levelVideo?.levelId) {
      openPanel("fullscreenVideo", {
        slug: levelVideo.levelId,
      });
    }
  }, [levelIsLoading, levelVideo, levelVideo?.levelId, openPanel]);

  // The experience should continue when there was an error playing the video.
  useEffect(() => {
    if (!panelVisible) return;
    if (!levelIsLoading && videoError) {
      closeVideoPlayer();
    }
  }, [levelIsLoading, closeVideoPlayer, videoError, panelVisible]);

  return null;
};

export default LegacyLoadLevelPanelLogic;
