import { SvgProps } from "../../types/svg";

const PurpleVideo: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 13C12.1572 13 13.5 11.6572 13.5 10V5C13.5 3.34281 12.1572 2 10.5 2C8.84281 2 7.5 3.34281 7.5 5V10C7.5 11.6562 8.81562 13 10.5 13ZM15.25 8C14.8344 8 14.5 8.33438 14.5 8.72188V10C14.5 12.2916 12.5634 14.1375 10.2406 13.9906C8.17562 13.8603 6.5 11.9097 6.5 9.84062V8.72188C6.5 8.33438 6.16406 8 5.75 8C5.33594 8 5 8.33438 5 8.72188V9.72656C5 12.5284 6.99906 15.0266 9.75 15.4047V16.5H8.5C7.93156 16.5 7.47375 16.9744 7.50125 17.5491C7.51344 17.8063 7.74375 18 8 18H13C13.2569 18 13.4866 17.8058 13.4987 17.5491C13.525 16.975 13.0688 16.5 12.5 16.5H11.25V15.4447C13.9281 15.0781 16 12.7781 16 10V8.72188C16 8.33438 15.6656 8 15.25 8Z"
      fill="#111111"
      fillOpacity="0.75"
    />
    <path
      d="M10.5 13C12.1572 13 13.5 11.6572 13.5 10V5C13.5 3.34281 12.1572 2 10.5 2C8.84281 2 7.5 3.34281 7.5 5V10C7.5 11.6562 8.81562 13 10.5 13ZM15.25 8C14.8344 8 14.5 8.33438 14.5 8.72188V10C14.5 12.2916 12.5634 14.1375 10.2406 13.9906C8.17562 13.8603 6.5 11.9097 6.5 9.84062V8.72188C6.5 8.33438 6.16406 8 5.75 8C5.33594 8 5 8.33438 5 8.72188V9.72656C5 12.5284 6.99906 15.0266 9.75 15.4047V16.5H8.5C7.93156 16.5 7.47375 16.9744 7.50125 17.5491C7.51344 17.8063 7.74375 18 8 18H13C13.2569 18 13.4866 17.8058 13.4987 17.5491C13.525 16.975 13.0688 16.5 12.5 16.5H11.25V15.4447C13.9281 15.0781 16 12.7781 16 10V8.72188C16 8.33438 15.6656 8 15.25 8Z"
      fill="url(#paint0_linear_472_13)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_472_13"
        x1="10.5"
        y1="2"
        x2="10.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA61F2" />
        <stop offset="1" stopColor="#634BF3" />
      </linearGradient>
    </defs>
  </svg>
);
export default PurpleVideo;
