import { SliceCreator } from "../../store/store";
import { FromGameMessage } from "../gameConnection/messages/fromGameMessages";

/**
 * Middleware to handle legacy implementation for loading levels Messages.
 */
const sliceName = "loadLevelController";

export type LoadLevelControllerState = {
  levelIsLoading: boolean;
  currentLevelId: string | undefined;
  videoHasEndedOnce: boolean;
  videoIsEnding: boolean;
  videoError: boolean;
  setVideoError: (error: boolean) => void;
  setVideoIsEnding: (isEnding: boolean) => void;
  setVideoHasEndedOnce: (ended: boolean) => void;
  resetState: () => void;
  useLegacyLoadLevel: boolean;
  dispatchLoadLevelControllerMessage: (message: FromGameMessage) => void;
};

type State = {
  loadLevelController: LoadLevelControllerState;
};

export const createLoadLevelControllerSlice: SliceCreator<State> = (set) => ({
  loadLevelController: {
    levelIsLoading: false,
    videoHasEndedOnce: false,
    videoIsEnding: false,
    videoError: false,
    currentLevelId: undefined,
    useLegacyLoadLevel: false,

    setVideoIsEnding: (isEnding: boolean) =>
      set(
        (state) => {
          state.loadLevelController.videoIsEnding = isEnding;
        },
        false,
        sliceName + "/setVideoIsEnding"
      ),
    setVideoHasEndedOnce: (ended: boolean) =>
      set(
        (state) => {
          state.loadLevelController.videoHasEndedOnce = ended;
        },
        false,
        sliceName + "/setVideoHasEndedOnce"
      ),
    setVideoError: (error: boolean) =>
      set(
        (state) => {
          state.loadLevelController.videoError = error;
        },
        false,
        sliceName + "/setVideoError"
      ),
    resetState: () =>
      set(
        (state) => {
          state.loadLevelController.videoHasEndedOnce = false;
          state.loadLevelController.videoIsEnding = false;
          state.loadLevelController.videoError = false;
          state.loadLevelController.currentLevelId = undefined;
        },
        false,
        sliceName + "/resetState"
      ),
    dispatchLoadLevelControllerMessage: (message) =>
      set(
        (state) => {
          switch (message.type) {
            case "LoadingLevelStart":
              state.loadLevelController.levelIsLoading = true;
              state.loadLevelController.currentLevelId = message.levelId;
              break;
            case "LoadingLevelEnd":
              state.loadLevelController.levelIsLoading = false;
              state.loadLevelController.currentLevelId = message.levelId;
              break;
            default:
              break;
          }
        },
        false,
        sliceName + "/dispatchLoadLevelControllerMessage"
      ),
  },
});
