import { useEffect } from "react";
import { styled } from "styled-components";
import { BaseLayout } from "../../../core/layout/PanelsLayout";
import { useStore } from "../../../store/store";
import QuestHintLogic from "./QuestHint.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const QuestHintPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.questHint.visible);
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);

  const currentQuestProgress = useStore((s) => s.quest.currentQuestProgress);

  // Show the quest hint for 5 seconds
  useEffect(() => {
    let id: number;
    if (currentQuestProgress?.slug) {
      openPanel("questHint", {
        options: {
          slug: currentQuestProgress?.slug,
        },
      });

      id = window.setTimeout(() => closePanel("questHint"), 5000);
    }

    return () => {
      window.clearTimeout(id);
    };
  }, [closePanel, currentQuestProgress, openPanel]);

  return (
    <Layout id="panel-questHint">
      <QuestHintLogic visible={visible} />
    </Layout>
  );
};

export default QuestHintPanel;
