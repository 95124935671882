import { ReactNode, useEffect, useState } from "react";
import Hide from "./Hide";

export type Props = {
  children: ReactNode;
};

/** Wrapper to animate the fade in of a component on mount. */
export const FadeIn: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <Hide speed={600} hide={!fadeIn} height="100%" width="100%">
      {children}
    </Hide>
  );
};

export default FadeIn;
