import styled from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Hide from "../../../components-ui/atoms/Hide";
import Icon from "../../../components-ui/atoms/Icon";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import CloseButton from "../../../components-ui/molecules/CloseButton";
import { Ring } from "../../../components-ui/molecules/StartButton";
import { useText } from "../../../core/i18n/i18nHooks";
import { steps, zIndex } from "../../../style/theme";
import AvatarsRow from "../videoAvatars/lib/AvatarsRow";
import { Props } from "./ScreenSharing.ui";
import { Loader } from "./component/Common";
import useVideoStream from "./hooks/useVideoStream";

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${(p) => p.theme.colorAbove4};
  backdrop-filter: blur(${(p) => p.theme.blurLight});
  z-index: ${zIndex.overExperience};
  * {
    pointer-events: all;
  }
`;

const StopScreenShareButton = styled.div`
  top: 40px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.popupMenues};
`;

const Container = styled.div`
  position: absolute;
  top: ${steps.spacing[8]};
  left: ${steps.spacing[8]};
  bottom: ${steps.spacing[8]};
  right: ${steps.spacing[8]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.overExperience + 1};

  * {
    pointer-events: all;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${steps.spacing[2]};
  right: ${steps.spacing[2]};
  z-index: ${zIndex.popupMenues};
  pointer-events: all;
`;

const ScreenShareContainer = styled.div`
  background: transparent;
  position: relative;
  object-fit: contain;
  padding: 0px;
  height: 100%;
  width: 100%;
  z-index: ${zIndex.overExperience};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScreenShare = styled.video`
  object-fit: contain;
  border-radius: ${steps.borderRadius.b40};
  max-height: 100%;
  max-width: 100%;
`;

const AvatarsContainer = styled(Hide)`
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: ${zIndex.overExperience + 2};
`;

const PanelContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ScreenSharingUiNormal: React.FC<Props> = ({
  amIScreenSharer,
  activeScreenShare,
  allParticipants,
  activeParticipants,
  localParticipant,
  nrOfParticipants,
  profileData,
  toggleVideo,
  screenSharer,
  allPlayers,
  openPlayerList,
  playersRowHidden,
  toggleLocalAudio,
  onMinimizeScreenShare,
  stopScreenShare,
  roomId,
  micMuted,
  userId,
}) => {
  const t = useText();
  const { isLoading, videoRef } = useVideoStream(activeScreenShare);

  if (!roomId) {
    return (
      <>
        <Typo.Subtitle>{t("player_joining_room")}</Typo.Subtitle>
        <Typo.Note>{t("player_please_wait")}</Typo.Note>
        <Space h={5} />
        <Ring isSpinning strokeWidth="4" reverse size={100} />
      </>
    );
  }
  return (
    <Backdrop>
      <Container>
        <CloseButtonWrapper>
          <CloseButton isGlass size="32px" onClick={onMinimizeScreenShare} />
        </CloseButtonWrapper>
        <PanelContainer>
          <ScreenShareContainer>
            <Loader $visible={isLoading}>
              <Ring isSpinning strokeWidth="4" reverse size={60} />
            </Loader>
            <ScreenShare
              ref={videoRef}
              muted={amIScreenSharer}
              autoPlay
              playsInline
              id="screen-sharing-video"
            />
          </ScreenShareContainer>
          <AvatarsContainer hide={playersRowHidden}>
            <AvatarsRow
              activeParticipants={activeParticipants}
              micMuted={micMuted}
              showControls={false}
              userId={userId}
              transformOrigin="bottom center"
              screenSharer={screenSharer}
              profileData={profileData}
              toggleVideo={toggleVideo}
              openPlayerList={openPlayerList}
              players={allPlayers}
              roomId={roomId}
              toggleLocalAudio={toggleLocalAudio}
              allParticipants={allParticipants}
              nrOfParticipants={nrOfParticipants}
              localParticipant={localParticipant}
            />
          </AvatarsContainer>
          {amIScreenSharer && (
            <StopScreenShareButton>
              <Button.Bright
                onClick={stopScreenShare}
                iconLeft={<Icon.ScreenShare inheritColor size="18px" />}
              >
                {t("screen_sharing_stop")}
              </Button.Bright>
            </StopScreenShareButton>
          )}
        </PanelContainer>
      </Container>
    </Backdrop>
  );
};

export default ScreenSharingUiNormal;
