import { AnalyticsPlugin } from "analytics";
import { AnalyticsMessage } from "./messages/analyticsMessages";

function getUmamiEventValue(message: AnalyticsMessage) {
  let eventValue = "";

  switch (message.type) {
    case "ui":
      eventValue = `${message.name?.replaceAll("/", "_") ?? "unknown"}${
        message.state ? `_${message.state}` : ""
      }${message.detail ? `_${message.detail}` : ""}`;
      break;
    case "region":
      eventValue = `${message.name?.replaceAll("/", "_") ?? "unknown"}${
        message.state ? `_${message.state}` : ""
      }`;
      break;
    case "login":
    case "share":
    case "emoji":
    case "reaction":
    case "ai_webcam_prompt_change":
    case "game":
    case "item_add":
      eventValue = message.name;
      break;
    case "ai_webcam_running":
    case "queue":
      break;
    default:
      eventValue = `${message.name}${
        message.detail ? `_${message.detail}` : ""
      }`;
      break;
  }

  return eventValue;
}

export function umamiOnePlugin(config: {
  umamiWebsiteUuid: string;
}): AnalyticsPlugin {
  return {
    name: "umami-one-plugin",
    config: {
      umamiWebsiteUuid: config.umamiWebsiteUuid,
    },
    initialize: () => {
      const hasUmami = document.querySelector("script[data-website-id]");
      if (hasUmami) return;

      const script = document.createElement("script");
      script.src = "https://umami.go.journee.live/umami.js";
      script.async = true;
      script.defer = true;
      script.setAttribute("data-website-id", `${config.umamiWebsiteUuid}`);
      document.body.appendChild(script);
    },
    page: () => {
      window.umami?.trackView();
    },
    track: ({
      payload: { properties },
    }: {
      payload: { properties: AnalyticsMessage };
    }) => {
      window.umami?.trackEvent(getUmamiEventValue(properties), properties.type);
    },
    loaded: () => {
      return Boolean(document.querySelector("script[data-website-id]"));
    },
  };
}
