import { SvgProps } from "../../types/svg";

const ThumbsUpFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 15.9719V8.97188C6 8.41969 5.55219 8 5 8H3C2.44781 8 2 8.44781 2 8.97188V15.9438C2 16.4959 2.44781 16.9156 3 16.9156H5C5.55313 16.9719 6 16.55 6 15.9719ZM18 9.00313C18 8.175 17.3288 7.50375 16.5 7.50375H11.9219C12.6334 6.31906 13.0006 4.97625 13.0006 4.50312C13 3.76625 12.4219 3 11.4531 3C9.48031 3 10.6294 5.37969 8.07188 7.425L7.5625 7.83125C7.19375 8.12813 7.00625 8.5625 7.00313 9L7 14C7 14.4719 7.22228 14.9166 7.6 15.1997L8.66687 15.9994C9.53125 16.65 10.5844 17 11.6656 17H13.5C14.3288 17 15 16.3291 15 15.5006C15 15.387 14.985 15.2774 14.9611 15.1709C15.5625 14.975 16 14.4187 16 13.75C16 13.4641 15.9157 13.1997 15.7769 12.9725C16.4719 12.8437 17 12.2344 17 11.5031C17 11.1125 16.8471 10.76 16.6025 10.4931C17.3812 10.4406 18 9.79688 18 9.00313Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ThumbsUpFilledIcon;
