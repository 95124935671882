import * as Select from "@radix-ui/react-select";
import styled, { css, useTheme } from "styled-components";
import Icon from "../atoms/Icon";
import { ColumnShrink, InputContainer, Label } from "../atoms/InputText";
import Row from "../atoms/Row";
import Typo from "../atoms/Typo";
import {
  SelectContent,
  SelectItem,
  SelectPortal,
  SelectScrollUpButton,
  SelectViewport,
} from "./SelectText";

const SelectValue = styled(Select.Value)``;
const SelectIcon = styled(Select.Icon)`
  position: absolute;
  right: 0;
  top: 0;
`;

const SelectItemText = styled(Select.ItemText)``;
const SelectScrollDownButton = styled(Select.ScrollDownButton)`
  display: flex;
  justify-content: center;
  padding: 3px;
`;
const SelectTrigger = styled(Select.Trigger)`
  background: transparent;
  width: 100%;
  border: none;
`;

const Wrapper = styled.div<{ $disabled?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

const TriggerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  color: ${(p) => p.theme.colorAbove5};
`;

const SelectWrapper = styled.div<{ $hasError: boolean }>`
  border: ${(p) => (p.$hasError ? `1px solid ${p.theme.colorAbove1}` : "none")};
  border-radius: ${(p) => p.theme.radiusTiny};
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
`;

const SelectRoot = styled(Select.Root)``;

export type Props = {
  disabled?: boolean;
  label: string;
  selected?: string;
  errorMessage?: React.ReactNode;
  infoMessage?: React.ReactNode;
  options: { label?: string; value: string }[];
  onOpen?: () => void;
  onSelect?: (value: string) => void;
};

const DropdownSelect: React.FC<Props> = ({
  disabled,
  selected,
  label,
  errorMessage,
  infoMessage,
  options,
  onOpen,
  onSelect,
}) => {
  const theme = useTheme();
  const hasError = Boolean(errorMessage);
  return (
    <Wrapper $disabled={disabled}>
      <SelectRoot
        onOpenChange={onOpen}
        onValueChange={onSelect}
        disabled={disabled || !options?.length}
        value={selected}
      >
        <SelectWrapper $hasError={hasError}>
          <InputContainer
            $hasError={hasError}
            $isFocused={false}
            $isTextArea={false}
            $disabled={disabled}
          >
            <Row>
              <SelectTrigger>
                <ColumnShrink height={theme.elements.field.height}>
                  <Label $isFloating $hasError={hasError}>
                    {label}
                  </Label>
                  <Row>
                    <TriggerWrapper>
                      <SelectValue />
                      <SelectIcon>
                        <Icon.Chevron flip="y" size="10px" inheritColor />
                      </SelectIcon>
                    </TriggerWrapper>
                  </Row>
                </ColumnShrink>
              </SelectTrigger>
            </Row>
          </InputContainer>
          {errorMessage && (
            <Typo.Note color={theme.colorError} noWrap>
              {errorMessage}
            </Typo.Note>
          )}
          {infoMessage && <Typo.Note noWrap>{infoMessage}</Typo.Note>}
        </SelectWrapper>
        <SelectPortal>
          <SelectContent side="bottom" align="end">
            <SelectScrollUpButton>
              <Icon.Chevron color={theme.coorAbove2} size="10px" />
            </SelectScrollUpButton>
            <SelectViewport>
              {options?.map((it) => (
                <SelectItem key={it.value} value={it.value}>
                  <SelectItemText>
                    <Typo.Note
                      color="inherit"
                      noWrap
                      style={{
                        maxWidth: "300px",
                        display: "inline-block",
                      }}
                    >
                      {it.label ?? it.value}{" "}
                    </Typo.Note>
                  </SelectItemText>
                </SelectItem>
              ))}
            </SelectViewport>
            <SelectScrollDownButton>
              <Icon.Chevron flip="y" color={theme.colorAbove2} size="10px" />
            </SelectScrollDownButton>
          </SelectContent>
        </SelectPortal>
      </SelectRoot>
    </Wrapper>
  );
};
export default DropdownSelect;
