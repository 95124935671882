import { SvgProps } from "../../types/svg";

const HeartStarsFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7.96563V7.78438C2 5.6 3.57875 3.73688 5.73125 3.37813C7.12813 3.14094 8.60625 3.60531 9.625 4.62563L10 5L10.3469 4.62563C11.3938 3.60531 12.8438 3.14094 14.2688 3.37813C16.4219 3.73688 18 5.6 18 7.78438V7.96563C18 9.2625 17.4625 10.5031 16.5125 11.3875L10.8656 16.6594C10.6313 16.8781 10.3219 17 10 17C9.67812 17 9.36875 16.8781 9.13438 16.6594L3.48719 11.3875C2.53844 10.5031 2 9.2625 2 7.96563ZM10.4219 10.4062L11.0644 8.86531C11.0959 8.80188 11.179 8.75031 11.25 8.75C11.3212 8.75 11.4053 8.80156 11.4369 8.86531L12.0837 10.4166L13.635 11.0634C13.6969 11.0969 13.75 11.1781 13.75 11.2219C13.75 11.2928 13.6984 11.3766 13.635 11.4081L12.0837 12.055L11.4369 13.6062C11.405 13.67 11.3212 13.7219 11.25 13.7219C11.179 13.7213 11.0956 13.67 11.064 13.6062L10.4165 12.0553L8.86561 11.4094C8.80249 11.3778 8.75124 11.2944 8.75061 11.2238C8.75092 11.1528 8.80217 11.0681 8.86561 11.0366L10.4097 10.3928L10.4219 10.4062ZM7.22188 7.25L6.31875 7.6375C6.25313 7.65625 6.25 7.70625 6.25 7.72188C6.25 7.79375 6.25313 7.81563 6.31875 7.8625L7.22188 8.22188L7.6375 9.18125C7.64127 9.18879 7.64403 9.19607 7.64661 9.2029C7.65689 9.23005 7.66445 9.25 7.72188 9.25C7.78156 9.25 7.80676 9.22845 7.84043 9.19968C7.84731 9.1938 7.85455 9.18761 7.8625 9.18125L8.22188 8.22188L9.18125 7.86125C9.21938 7.84219 9.25031 7.79219 9.25031 7.74937C9.25031 7.70656 9.21938 7.65656 9.18125 7.6375L8.22188 7.25L7.83375 6.31938C7.81469 6.28094 7.7375 6.25 7.72188 6.25C7.70625 6.25 7.65625 6.28094 7.61 6.31906L7.22188 7.25Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default HeartStarsFilledIcon;
