import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { VideoConferenceParticipant } from "../../../../core/videoConference/VideoConferenceAdapter";
import { computeActiveParticipants } from "../../../../core/videoConference/util.core";

type HookShape = (params: {
  allParticipants: VideoConferenceParticipant[];
}) => VideoConferenceParticipant[];

const useActiveParticipants: HookShape = ({ allParticipants }) => {
  const [activeParticipants, setActiveParticipants] = useState<
    VideoConferenceParticipant[]
  >([]);
  const activeParticipantsRef = useRef(activeParticipants);
  useEffect(() => {
    const prevActiveParticipants = activeParticipantsRef.current;
    activeParticipantsRef.current = computeActiveParticipants(
      allParticipants,
      activeParticipantsRef.current
    );
    if (isEqual(activeParticipantsRef.current, prevActiveParticipants)) return;
    setActiveParticipants(activeParticipantsRef.current);
  }, [allParticipants]);
  return activeParticipants;
};

export default useActiveParticipants;
