import { useEffect } from "react";
import { isIFrame } from "../../constants/flags";
import { log } from "../../lib/logger";
import { useStore } from "../../store/store";
import {
  MessageType,
  SpecialKeyCodes,
  inputOptions,
} from "./webrtc/helpers/constants";
import { sendInputData } from "./webrtc/webRtcConnection";

const pressedKeys: { [key: number]: boolean } = {};
const sendKeyDown = (key: number, repeat: boolean) => {
  sendInputData(
    new Uint8Array([MessageType.KeyDown, key, repeat ? 1 : 0]).buffer
  );
  pressedKeys[key] = true;
};
const sendKeyUp = (key: number) => {
  sendInputData(new Uint8Array([MessageType.KeyUp, key]).buffer);
  delete pressedKeys[key];
};
const clearPressedKeys = (e: Event) => {
  log("KEYBOARD", `Keyboard event ${e.type}`);
  for (const key in pressedKeys) {
    if (pressedKeys[key]) {
      sendKeyUp(parseInt(key));
      log("KEYBOARD", "Clearing possibly stuck key:", key);
    }
  }
};
window.addEventListener("dragend", clearPressedKeys);
window.addEventListener("focus", clearPressedKeys);
window.addEventListener("focusout", clearPressedKeys);
window.addEventListener("focusin", clearPressedKeys);
window.addEventListener("beforeunload", clearPressedKeys);

// We want to be able to differentiate between left and right versions of some
// keys.
function getKeyCode(e: KeyboardEvent): number {
  if (e.keyCode === SpecialKeyCodes.Shift && e.code === "ShiftRight")
    return SpecialKeyCodes.RightShift;
  else if (e.keyCode === SpecialKeyCodes.Control && e.code === "ControlRight")
    return SpecialKeyCodes.RightControl;
  else if (e.keyCode === SpecialKeyCodes.Alt && e.code === "AltRight")
    return SpecialKeyCodes.RightAlt;
  else return e.keyCode;
}

function isSuppressedKey(e: KeyboardEvent) {
  // Suppress browser keys when the option is set.
  const isSuppresedBrowserKey =
    inputOptions.suppressBrowserKeys &&
    ((e.keyCode >= 112 && e.keyCode <= 123) || e.keyCode === 9);
  // Suppress arrow keys when in an iframe.
  const isSuppresedNavigationKey =
    isIFrame && e.keyCode >= 37 && e.keyCode <= 40;
  return isSuppresedBrowserKey || isSuppresedNavigationKey;
}

const useKeyboard = () => {
  const isStepReached = useStore((s) => s.userFlow.isStepReached);
  const isExperienceReady = isStepReached("experience:ready");

  useEffect(() => {
    // Only enable keyboard input when the experience is ready.
    if (!isExperienceReady) return;

    const onKeyPress = function (e: { charCode: number }) {
      log("KEYBOARD", `key press ${e.charCode}`);
      const data = new DataView(new ArrayBuffer(3));
      data.setUint8(0, MessageType.KeyPress);
      data.setUint16(1, e.charCode, true);
      sendInputData(data.buffer);
    };

    const onKeyDown = function (e: KeyboardEvent) {
      sendKeyDown(getKeyCode(e), e.repeat);
      // Backspace is not considered a keypress in JavaScript but we need it
      // to be so characters may be deleted in a UE4 text entry field.
      if (e.keyCode === SpecialKeyCodes.BackSpace) {
        onKeyPress({ charCode: SpecialKeyCodes.BackSpace });
      }
      if (isSuppressedKey(e)) {
        e.preventDefault();
      }
    };

    const onKeyUp = function (e: KeyboardEvent) {
      log("KEYBOARD", `key up ${e.keyCode}`);
      sendKeyUp(getKeyCode(e));
      if (isSuppressedKey(e)) {
        e.preventDefault();
      }
    };

    document.addEventListener("keypress", onKeyPress);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keypress", onKeyPress);
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [isExperienceReady]);
};

export default useKeyboard;
