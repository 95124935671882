import { SvgProps } from "../../types/svg";

const MicrophoneOffThinIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.50293 5C7.50293 3.62156 8.62494 2.5 10.0039 2.5C11.3829 2.5 12.5049 3.62156 12.5049 5V9.1125L13.0051 9.50938L12.977 5C12.977 3.34281 11.6336 2 10.0039 2C8.4283 2 7.14967 3.21719 7.02775 4.76L7.50293 5.1375V5ZM13.7554 17.5H10.254V14.9747C10.904 14.9418 11.5208 14.7832 12.0829 14.5262L11.6464 14.1794C11.1356 14.3812 10.5854 14.5 10.0039 14.5C7.52138 14.5 5.50215 12.4816 5.50215 10V9.29688L5.00195 8.9V10C5.00195 12.6713 7.11403 14.8406 9.75382 14.975V17.5H6.25244C6.11489 17.5 6.00234 17.6125 6.00234 17.75C6.00234 17.8875 6.11489 18 6.25244 18H13.7554C13.8931 18 14.0055 17.8877 14.0055 17.75C14.0055 17.6123 13.8929 17.5 13.7554 17.5ZM14.9777 10L15.0059 8.25C15.0059 8.11231 14.8935 8.00003 14.7558 8.00003C14.618 8.00003 14.5057 8.1125 14.5057 8.25V10C14.5057 10.2227 14.4713 10.4362 14.4399 10.65L14.8982 11.0141C14.9652 10.6594 14.9777 10.3469 14.9777 10ZM10.1446 12.9844L9.44745 12.4344C8.70653 12.2625 8.09692 11.7625 7.76866 11.0969L7.05588 10.5312C7.30911 11.9344 8.52834 13 10.0039 13C10.0508 13 10.0696 12.9875 10.1446 12.9844ZM19.8859 17.5531L0.406086 2.05406C0.360443 2.0177 0.305453 2 0.250869 2C0.177372 2 0.104281 2.03222 0.0543234 2.09413C-0.0316478 2.20253 -0.0135782 2.35975 0.0943704 2.44569L19.5739 17.9457C19.6483 17.9812 19.7015 18 19.7296 18C19.8029 18 19.8762 17.9678 19.9255 17.9058C20.0391 17.7969 20.0203 17.6406 19.8859 17.5531Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MicrophoneOffThinIcon;
