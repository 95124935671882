import { SvgProps } from "../../types/svg";

const CircleMicrophoneIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 12C11.1031 12 12 11.1031 12 10V7C12 5.89687 11.1031 5 10 5C8.89687 5 8 5.89687 8 7V10C8 11.1031 8.89687 12 10 12ZM8.5 7C8.5 6.17281 9.17281 5.5 10 5.5C10.8272 5.5 11.5 6.17281 11.5 7V10C11.5 10.8272 10.8272 11.5 10 11.5C9.17281 11.5 8.5 10.8281 8.5 10V7ZM13.25 8.75C13.1125 8.75 13 8.8625 13 9V10C13 11.6544 11.6544 13 10 13C8.34562 13 7 11.6531 7 10V9C7 8.86181 6.88819 8.75 6.75 8.75C6.61181 8.75 6.47188 8.8625 6.47188 9L6.5 10C6.5 11.8441 7.93875 13.3438 9.75 13.475V15C9.75 15.1382 9.86181 15.25 10 15.25C10.1382 15.25 10.25 15.1382 10.25 15V13.4747C12.0344 13.3438 13.5 11.8438 13.5 10V9C13.5 8.8625 13.3875 8.75 13.25 8.75ZM10 2C5.58125 2 2 5.58125 2 10C2 14.4187 5.58125 18 10 18C14.4187 18 18 14.4187 18 10C18 5.58125 14.4187 2 10 2ZM10 17.5C5.86562 17.5 2.5 14.1344 2.5 10C2.5 5.86562 5.86562 2.5 10 2.5C14.1344 2.5 17.5 5.86562 17.5 10C17.5 14.1344 14.1344 17.5 10 17.5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CircleMicrophoneIcon;
