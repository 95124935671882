import { styled } from "styled-components";
import Hide from "../../components-ui/atoms/Hide";
import { BaseLayout } from "../../core/layout/PanelsLayout";
import { useStore } from "../../store/store";
import ActionBarPanelLogic from "./ActionBarPanelLogic";

const Layout = styled(BaseLayout)`
  position: absolute;
  top: 0;
  left: max(var(--safe-margin-left), 16px);
  right: max(var(--safe-margin-right), 16px);
  bottom: max(var(--safe-margin-bottom), 6px);
  padding-top: 15px;
  box-sizing: border-box;
`;

const ActionBarPanelLayout: React.FC = () => {
  const actionBarlVisible = useStore((s) => s.layout.panels.actionBar.visible);

  return (
    <Layout id="panel-actionBar">
      <Hide width="100%" height="100%" hide={!actionBarlVisible}>
        <ActionBarPanelLogic />
      </Hide>
    </Layout>
  );
};

export default ActionBarPanelLayout;
