import { SvgProps } from "../../types/svg";

const VideoIcon: React.FC<SvgProps> = (props) => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 4H3C1.34312 4 0 5.34312 0 7V25C0 26.6569 1.34312 28 3 28H21C22.6569 28 24 26.6569 24 25V7C24 5.34312 22.6562 4 21 4ZM23 25C23 26.1025 22.1025 27 21 27H3C1.8975 27 1 26.1025 1 25V7C1 5.8975 1.8975 5 3 5H21C22.1025 5 23 5.8975 23 7V25ZM34.7313 6.28125C33.9344 5.86037 32.9712 5.91506 32.2331 6.42188L26.225 10.41C25.9965 10.5633 25.934 10.8739 26.0863 11.1031C26.2387 11.3346 26.5492 11.3932 26.7794 11.2437L32.7938 7.25C33.2391 6.94631 33.7863 6.91306 34.2663 7.16506C34.725 7.40625 35 7.85625 35 8.36875V23.625C35 24.1377 34.7246 24.5887 34.2637 24.8312C33.7872 25.0842 33.2325 25.05 32.7831 24.7404L26.775 20.7573C26.5484 20.6069 26.2359 20.6665 26.0837 20.8979C25.9314 21.1284 25.9939 21.438 26.2244 21.5911L32.2244 25.5698C32.6365 25.853 33.115 25.9976 33.5975 25.9976C33.9842 25.9976 34.3731 25.9038 34.7281 25.7163C35.525 25.3 36 24.5125 36 23.5688V8.36875C36 7.48125 35.525 6.7 34.7313 6.28125Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default VideoIcon;
