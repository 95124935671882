import { screenShareMachineActor } from "../vonageStateMachine/screenShare.machine";
import { ScreenShareProviderInterface } from "./ScreenShareProviderInterface";

export class VonageScreenShareProvider implements ScreenShareProviderInterface {
  public onEndedCallback: (() => void) | null = null;

  async init(): Promise<void> {}

  async stopListeningForScreenShare(): Promise<void> {}

  async cleanUpScreensharing(): Promise<void> {}

  async listenForScreenShare(): Promise<void> {}

  public async stopScreenShare(): Promise<void> {
    screenShareMachineActor.send({ type: "lugia.screenSharingStopped" });
  }

  async startScreenShare() {
    screenShareMachineActor.send({ type: "lugia.screenSharingStarted" });
    return null;
  }
}

export default VonageScreenShareProvider;
