import { PanelName } from "../../../core/layout/panelsTypes";
import { VideoConferenceParticipant } from "../../../core/videoConference/VideoConferenceAdapter";
import { ProfileData, SearchableProfile } from "../profile/lib/profileTypes";
import VideoAvatarsUiNormal from "./VideoAvatars.ui.normal";

export type Props = {
  screenSharer: VideoConferenceParticipant | null;
  activeParticipants: VideoConferenceParticipant[];
  allParticipants: VideoConferenceParticipant[];
  toggleLocalAudio: () => void;
  players: Record<string, SearchableProfile>;
  roomId: string | null;
  showControls?: boolean;
  transformOrigin?: React.CSSProperties["transformOrigin"];
  profileData: ProfileData;
  userId?: string;
  toggleVideo?: () => void;
  localParticipant: VideoConferenceParticipant | null;
  micMuted: boolean;
  nrOfParticipants: number;
  openPanel?: (panel: PanelName) => void;
  closePanel?: (panel: PanelName) => void;
  openPlayerList: () => void;
};

const VideoAvatarsUi: React.FC<Props> = (props) => {
  return <VideoAvatarsUiNormal {...props} />;
};

export default VideoAvatarsUi;
