import { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { sendGameMessage } from "../../../../../core/gameConnection/webrtc/webRtcMessageHandlers";
import { useText } from "../../../../../core/i18n/i18nHooks";
import { useIsSmallScreen } from "../../../../../hooks/ui";
import { useStore } from "../../../../../store/store";
import TextChatThread from "./TextChatThread";
import { useCurrentTextChatChannel } from "./textChatHooks";
import { TextChatMessage } from "./textChatTypes";

// TODO: figure out this prop
// type Props = {
//   autoSubmitVirtualKeyboard?: boolean;
// };

const Wrapper = styled.div<{ $isSmallScreen: boolean }>`
  padding-top: 60px;
  min-height: ${(p) => (p.$isSmallScreen ? "calc(100dvh - 150px)" : "400px")};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

type Props = {
  visible?: boolean;
};

const TextChat: React.FC<Props> = ({ visible }) => {
  const t = useText();
  const isModerating = useStore((s) => s.textChat.isModerating);
  const setIsModerating = useStore((s) => s.textChat.setIsModerating);
  const isModerator = useStore((s) => s.session.isModerator());
  const isAdmin = useStore((s) => s.session.isAdmin());
  const isSmallScreen = useIsSmallScreen();
  const canModerate = isModerator || isAdmin;
  const { type, thread, fixedMessages, lastTyping } =
    useCurrentTextChatChannel();
  const chatThread = useStore((s) => s.textChat.channels.public.thread);
  const setUnseenMessagesCount = useStore(
    (s) => s.textChat.setUnseenMessagesCount
  );
  const [deletedMessages, setDeletedMessages] = useState(new Set<string>());
  const [isTyping, setIsTyping] = useState(false);

  // Activating the typing for 5 seconds each time we get a new typing event.
  // This relies on the unreal game sending "npcTyping" every few seconds (< 5).
  useEffect(() => {
    let timer: number | undefined;
    const typing = type === "smartChat" && Boolean(lastTyping);
    setIsTyping(typing);

    if (typing) {
      timer = window.setTimeout(() => {
        setIsTyping(false);
      }, 5000);
    } else if (timer) {
      clearTimeout(timer);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [lastTyping, type]);

  // Clear typing effect after every new message
  useEffect(() => {
    setIsTyping(false);
  }, [thread]);

  useEffect(() => {
    if (visible) setUnseenMessagesCount(0);
  }, [chatThread, setUnseenMessagesCount, visible]);

  const handleMessageDelete = useCallback((message: TextChatMessage) => {
    if (message.messageId !== null && message.messageId !== undefined) {
      const id = `${message.senderId}_${message.messageId}`;
      setDeletedMessages((prev) => new Set(prev.add(id)));
      sendGameMessage({
        type: "DeleteMessage",
        messageId: message.messageId,
        userId: message.senderId,
      });
    }
  }, []);

  return (
    <Wrapper $isSmallScreen={isSmallScreen}>
      <TextChatThread
        groupedMessageThread={thread}
        isModerationOn={isModerating && canModerate}
        onDelete={handleMessageDelete}
        deletedMessages={deletedMessages}
        fixedFirstMessagesGroup={fixedMessages}
        isTyping={isTyping}
      />
      {canModerate && !isModerating && (
        <button
          onClick={() => {
            setIsModerating(true);
          }}
        >
          {t("chat_moderate")}
        </button>
      )}
    </Wrapper>
  );
};

export default TextChat;
