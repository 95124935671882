import { SvgProps } from "../../types/svg";

const MicrophoneIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 8C14.225 8 14 8.225 14 8.5V10C14 12.2969 12.0547 14.1437 9.725 13.9937C7.60312 13.8469 6 11.9875 6 9.85938V8.5C6 8.225 5.775 8 5.5 8C5.225 8 5 8.225 5 8.5V9.82438C5 12.43 6.92594 14.7025 9.5 14.9619V17H7.5C7.225 17 7 17.225 7 17.5C7 17.775 7.225 18 7.5 18H12.5C12.775 18 13 17.775 13 17.5C13 17.225 12.775 17 12.5 17H10.5V14.9688C13.025 14.7156 15 12.5875 15 10V8.5C15 8.225 14.775 8 14.5 8ZM10 13C11.6562 13 13 11.6562 13 10V5C13 3.37188 11.6562 2 10 2C8.34375 2 7 3.34375 7 5V10C7 11.6562 8.34375 13 10 13ZM8 5C8 3.89844 8.89844 3 10 3C11.1016 3 12 3.89844 12 5V10C12 11.1016 11.1016 12 10 12C8.89844 12 8 11.1031 8 10V5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MicrophoneIcon;
