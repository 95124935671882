import styled, { useTheme } from "styled-components";
import Icon from "../../../../components-ui/atoms/Icon";
import { Ring } from "../../../../components-ui/molecules/StartButton";
import { PanelTestIds } from "../../../../constants/testIds";
import { isColorBright } from "../../../../lib/color";
import { zIndex } from "../../../../style/theme";

const CameraHoverOverlay = styled.div<{
  $size: string;
  $isVideoAvailable: boolean;
  $backgroundColor: React.CSSProperties["backgroundColor"];
}>`
  position: absolute;
  cursor: pointer;
  display: flex;
  width: ${(p) => p.$size};
  height: ${(p) => p.$size};
  border-radius: 50%;
  justify-content: center;
  opacity: 0;
  color: ${(p) => p.theme.colorAboveBrand};
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  z-index: ${zIndex.overlays};
  background: ${(p) =>
    p.$isVideoAvailable
      ? p.theme.colorAbove2
      : `
          radial-gradient(
          100% 100% at 50% 0%,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0) 100%
          ),
          ${p.$backgroundColor};
          `};
`;

const Loader = styled.div<{
  $visible: boolean;
  $size: number;
  $backgroundColor: React.CSSProperties["backgroundColor"];
}>`
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  background: ${(p) => `
      radial-gradient(
      100% 100% at 50% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
      ),
      ${p.$backgroundColor};
      `};
  z-index: ${zIndex.overlays};
`;

type Props = {
  isLoading: boolean;
  isVideoAvailable: boolean;
  toggleVideo?: () => void;
  size: number;
  playerAvatarColor: string;
};

const CameraCover: React.FC<Props> = ({
  isLoading,
  size,
  playerAvatarColor,
  toggleVideo,
  isVideoAvailable,
}) => {
  const theme = useTheme();
  const iconColor = isColorBright(playerAvatarColor)
    ? theme.colorAbove4
    : theme.colorBelow2;
  const finalIconColor = isVideoAvailable
    ? { inheritColor: true }
    : { color: iconColor };

  return isLoading ? (
    <Loader
      $visible={isLoading}
      $size={size}
      $backgroundColor={playerAvatarColor}
    >
      <Ring
        color={theme.colorBelow1}
        isSpinning
        strokeWidth="4"
        reverse
        size={size / 3}
      />
    </Loader>
  ) : (
    toggleVideo && (
      <CameraHoverOverlay
        data-testid={PanelTestIds.videoAvatars.videoAvatarCircle.videoButton}
        onClick={toggleVideo}
        className="camera-hover-overlay"
        $size={`${size}px`}
        $isVideoAvailable={isVideoAvailable}
        $backgroundColor={playerAvatarColor}
      >
        {isVideoAvailable ? (
          <Icon.VideoOff size="20px" {...finalIconColor} />
        ) : (
          <Icon.VideoOn size="20px" {...finalIconColor} />
        )}
      </CameraHoverOverlay>
    )
  );
};

export default CameraCover;
