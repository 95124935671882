import CardFullLandscape from "../../../components-ui/molecules/CardFullLandscape";
import { Props, generateGradientFromColor } from "./Social.ui";

const SocialUiLandscape: React.FC<Props> = ({ onClose, selectedTab, tabs }) => {
  return (
    <CardFullLandscape
      floatingSection={tabs}
      bottomSection={selectedTab.drawer}
      onClose={onClose}
      hideScrollButton
      noPadding
      style={{
        background: generateGradientFromColor(selectedTab.backgroundColor),
      }}
      showFade={selectedTab.showFade}
    >
      {selectedTab.content}
    </CardFullLandscape>
  );
};

export default SocialUiLandscape;
