import { SvgProps } from "../../types/svg";

const MicrophoneOffFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.58 15.2813L10.6023 15.2813V14.4239C11.1174 14.3522 11.602 14.1851 12.0449 13.9486L10.9631 13.0876C10.5965 13.2057 10.2059 13.2699 9.79584 13.2436C8.14417 13.1384 6.77882 11.5515 6.77882 9.87068V9.77674L5.57851 8.81954L5.57852 9.79985C5.57852 12.0761 7.17817 14.106 9.35698 14.4132V15.2803L8.35672 15.2803C7.91486 15.2803 7.57902 15.6441 7.57902 16.0699C7.57902 16.3172 7.78157 16.5 7.97912 16.5H11.9576C12.1786 16.5 12.3352 16.3181 12.3352 16.1166C12.3802 15.6444 12.0452 15.2813 11.58 15.2813ZM17.7741 15.4108L13.7906 12.2396C14.175 11.581 14.404 10.82 14.404 9.99992V8.98457C14.404 8.64816 14.1352 8.37522 13.8264 8.37522C13.495 8.37522 13.2262 8.64816 13.2262 8.98457L13.2262 9.97732C13.2262 10.5136 13.0872 11.0125 12.8569 11.456L12.2137 10.9442C12.3446 10.6476 12.4261 10.3241 12.4261 9.97732L12.4261 6.03934C12.4261 4.70459 11.4128 3.54072 10.0987 3.50111C8.73912 3.46005 7.62483 4.56622 7.62483 5.93727V7.31468L2.9706 3.63011C2.86057 3.54282 2.73004 3.50035 2.601 3.50035C2.42296 3.50035 2.24614 3.58053 2.12813 3.73365C1.92341 3.99872 1.96884 4.38186 2.22986 4.58929L17.0111 16.3474C17.2737 16.5557 17.6505 16.508 17.8536 16.2438C18.0817 16.0024 18.0342 15.619 17.7741 15.4108Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MicrophoneOffFilledIcon;
