import { useReflow } from "../../../../../../hooks/ui";
import ControlsTouchUiLandscape from "./ControlsTouch.landscape";
import ControlsTouchUiPortrait from "./ControlsTouch.portrait";

const ControlsTouchUi: React.FC = () => {
  return useReflow({
    portrait: <ControlsTouchUiPortrait />,
    landscape: <ControlsTouchUiLandscape />,
    normal: <ControlsTouchUiPortrait />,
  });
};

export default ControlsTouchUi;
