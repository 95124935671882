import { SvgProps } from "../../types/svg";

const PlusIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 9.5H17.5C17.775 9.5 18 9.725 18 10C18 10.275 17.775 10.5 17.5 10.5H10.5V17.5C10.5 17.775 10.275 18 9.97188 18C9.725 18 9.47188 17.775 9.47188 17.5V10.5H2.5C2.22387 10.5 2 10.275 2 10C2 9.725 2.22387 9.5 2.5 9.5H9.47188V2.5C9.47188 2.22387 9.725 2 9.97188 2C10.275 2 10.5 2.22387 10.5 2.5V9.5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PlusIcon;
