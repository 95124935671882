import { useEffect, useRef } from "react";

type Props = {
  callback: () => void;
};

type Returns = {
  restartTimer: (durationMs?: number) => void;
  stopTimer: () => void;
};

export const useTimer = (props: Props): Returns => {
  const timerId = useRef<NodeJS.Timeout>();

  const stopTimer = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
      timerId.current = undefined;
    }
  };

  const restartTimer = (durationMs?: number) => {
    if (!durationMs) return;
    stopTimer();
    timerId.current = setTimeout(props.callback, durationMs);
  };

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  return { restartTimer, stopTimer };
};
