import { useCallback, useState } from "react";
import { useWindowSize } from "../../hooks/ui";
import Column, { Props as ColumnProps } from "./Column";

const TOP_AND_BOTTOM_PADDING = 128;

type Props = {
  children: React.ReactNode;
};

const CardContentAlignment: React.FC<Props> = ({ children }) => {
  const { height } = useWindowSize();
  const [bodyHeight, setBodyHeight] = useState<number>(0);
  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setBodyHeight(node.offsetHeight);
    }
  }, []);
  const centeredHeight = height - TOP_AND_BOTTOM_PADDING;
  const isCentered = centeredHeight >= bodyHeight;
  // If the content is short, center it
  const centerProps: {
    justify: ColumnProps["justify"];
    height: string;
  } = {
    justify: "center",
    height: centeredHeight + "px",
  };

  return (
    <Column {...(isCentered ? centerProps : {})}>
      {/* Not sure why measuredRef only works with this div*/}
      <div ref={measuredRef}>{children}</div>
    </Column>
  );
};

export default CardContentAlignment;
