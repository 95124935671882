import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  isChromeIos,
  isIFrame,
  isIos,
  isMobile,
  isSafariIos,
} from "../../../constants/flags";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { useText } from "../../../core/i18n/i18nHooks";
import { useStore } from "../../../store/store";
import { PropsLoginPageUi } from "../LoginPageUi";

const useBrowserCheckStep = (
  setLoginPageProps: (props: PropsLoginPageUi) => void
) => {
  const { environment } = useEnvironmentContext();
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const [linkCopied, setLinkCopied] = useState(false);
  const t = useText();

  // Only allow Safari and Chrome on iOS.
  // These browsers can cause quite a bit of trouble as they all
  // have unique scrolling behaviors that evolve over time,
  // making our fullscreen hack risky or even impossible.
  // We allow all browsers if the experience is accessed in an iframe as
  // anyway the fullscreen hack is not possible.
  const isSupportedBrowser = !isIos || isSafariIos || isChromeIos || isIFrame;
  const isBlockedAsMobile = isMobile && environment.allowMobiles === false;
  const hasWebRTC = !!window.RTCPeerConnection;

  const handleSecondaryClick = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setLinkCopied(true);
    } catch (error: unknown) {
      Sentry.captureException(
        new Error(
          "Failed to copy the current URL to clipboard during the browser check."
        )
      );
    }
    window.analytics?.track("click", {
      type: "click",
      name: "copy_blocked_link",
    });
  };

  useEffect(() => {
    if (currentStep !== "login:check-browser") return;
    if (isSupportedBrowser && hasWebRTC && !isBlockedAsMobile) {
      completeStep("login:check-browser");
    }
  }, [
    completeStep,
    isSupportedBrowser,
    hasWebRTC,
    isBlockedAsMobile,
    currentStep,
  ]);

  useEffect(() => {
    if (currentStep !== "login:check-browser") return;

    if (!isSupportedBrowser)
      setLoginPageProps({
        title: "",
        descriptionHeader: "warning",
        description: t("login_browser_check_unsupported_browser"),
        isMainButtonVisible: false,
        isSecondaryButtonVisible: true,
        onSecondaryButtonClick: handleSecondaryClick,
        secondaryButtonLabel: linkCopied
          ? t("login_browser_check_link_copied")
          : t("login_browser_check_copy_link"),
      });
    else if (!hasWebRTC)
      setLoginPageProps({
        title: "",
        descriptionHeader: "warning",
        description: t("login_browser_check_missing_webrtc"),
        isMainButtonVisible: false,
      });
    else if (isBlockedAsMobile)
      setLoginPageProps({
        title: "",
        descriptionHeader: "warning",
        description: t("login_browser_check_mobile_blocked"),
        isMainButtonVisible: false,
      });
  }, [
    currentStep,
    hasWebRTC,
    isBlockedAsMobile,
    isSupportedBrowser,
    linkCopied,
    setLoginPageProps,
    t,
  ]);
};

export default useBrowserCheckStep;
