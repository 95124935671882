import React from "react";
import ReactMarkdown from "react-markdown";
import { styled, useTheme } from "styled-components";
import Icon from "./Icon";
import Typo from "./Typo";

const HintMarkdown = styled(ReactMarkdown)`
  word-break: break-all;
  text-align: start;
  a {
    display: inline-block;
    text-decoration: underline;
    :hover {
      text-decoration-color: ${(p) => p.theme.colorAboveBrand};
    }
  }
  em,
  b {
    font-weight: 600;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 0;
  min-width: 0;
  text-wrap: wrap;
  width: auto;
  padding: 12px 16px;
  border-radius: ${(p) => p.theme.radiusTiny};
  border: 1px solid ${(p) => p.theme.colorAbove1};
`;

interface InputHintProps {
  hint: string;
}

const InputHint: React.FC<InputHintProps> = ({ hint }) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <Icon.CircleInfo size="18px" color={theme.colorAbove3} />
      <HintMarkdown
        components={{
          p: ({ children }) => (
            <Typo.Note color={theme.colorAbove3}>{children}</Typo.Note>
          ),
          a: ({ children, ...p }) => (
            <a target="_blank" rel="noopener noreferrer" {...p}>
              <Typo.Note color={theme.colorAbove3}>{children}</Typo.Note>
            </a>
          ),
        }}
      >
        {hint}
      </HintMarkdown>
    </Wrapper>
  );
};

export default InputHint;
