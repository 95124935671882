import styled from "styled-components";
import Typo from "../../../../components-ui/atoms/Typo";
import { defaultTheme, steps } from "../../../../style/theme";

const Wrapper = styled.div`
  background-color: ${defaultTheme.colorAbove1};
  padding: 4px 8px;
  border-radius: ${defaultTheme.radiusSmall};
  backdrop-filter: blur(${defaultTheme.blurStrong});
`;

type Props = {
  text: string;
};

/**
 * Name tag og the Flying Emoji component in ReactionsBar.
 * This component is manually injected into the DOM and should not use the theme.
 */
const FlyingEmojiTag: React.FC<Props> = ({ text }) => {
  return (
    <Wrapper>
      <Typo.Body
        fontFamily={defaultTheme.fontMain}
        size={steps.font.f10.size}
        color={defaultTheme.colorBelow1}
        weight={steps.fontWeight.medium}
      >
        {text}
      </Typo.Body>
    </Wrapper>
  );
};

export default FlyingEmojiTag;
