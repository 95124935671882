import Row from "../../../../../../components-ui/atoms/Row";
import Typo from "../../../../../../components-ui/atoms/Typo";
import { useControls } from "../controlHooks";

const GesturesSchema: React.FC = () => {
  const { gestures } = useControls();

  return (
    <>
      {gestures.map(({ label, description, icon }, gestureIndex) => (
        <Row key={gestureIndex} justify="space-between">
          <Typo.Body>{label}</Typo.Body>
          <div>
            <Row gap={1}>
              <Typo.Label>{description}</Typo.Label>
              {icon && <Typo.Label>{icon}</Typo.Label>}
            </Row>
          </div>
        </Row>
      ))}
    </>
  );
};

export default GesturesSchema;
