import styled from "styled-components";
import { getImageUrl } from "../../../api/gyaradosUtils";
import Image from "../../../components-ui/atoms/Image";
import Markdown from "../../../components-ui/atoms/Markdown";
import Row from "../../../components-ui/atoms/Row";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import Card from "../../../components-ui/molecules/Card";
import { PanelTestIds } from "../../../constants/testIds";
import { Props } from "./InfoCard.ui";
import InfoCardActions, {
  getActionBooleans,
} from "./component/InfoCardActions";

const Padding = styled.div`
  padding: 0 19px;
`;

const TitleWrapper = styled.div<{ $hasImage: boolean }>`
  padding-top: ${(p) => (p.$hasImage ? 24 : 16)}px;
  padding-left: 19px;
  padding-right: ${(p) => (p.$hasImage ? 0 : 38)}px;
`;

export const Body = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    text-align: start;
    flex: 1;
    min-inline-size: 0;
  }
  min-width: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const InfoCardPanelUiNormal: React.FC<Props> = ({
  data,
  handleDismiss,
  onButtonClick,
  onSecondaryButtonClick,
  testId,
}) => {
  const { image, title, description } = data;
  const { hasActions } = getActionBooleans(data);

  return (
    <Spatial tiltX={-6} rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
      <Card
        topSection={
          image && (
            <Image
              testId={PanelTestIds.infoCard.image}
              src={getImageUrl(image, 700, 700) ?? ""}
              width="100%"
              height="100%"
              cover
            />
          )
        }
        topSectionHeight="190px"
        width="260px"
        brightCloseButton={Boolean(image)}
        onClose={handleDismiss}
        testId={testId}
      >
        <Body>
          {title && (
            <TitleWrapper $hasImage={Boolean(image)}>
              <Typo.Title
                testId={PanelTestIds.infoCard.title}
                size="18px"
                lineHeight="24px"
              >
                {title}
              </Typo.Title>
            </TitleWrapper>
          )}
          <Space h={2} />
          <Padding>
            {description && <Markdown content={description} textAlign="left" />}
            <Space h={5} />
            {hasActions && (
              <>
                <Row>
                  <InfoCardActions
                    data={data}
                    onButtonClick={onButtonClick}
                    onSecondaryButtonClick={onSecondaryButtonClick}
                  />
                </Row>
                <Space h={5} />
              </>
            )}
          </Padding>
        </Body>
      </Card>
    </Spatial>
  );
};

export default InfoCardPanelUiNormal;
