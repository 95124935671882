import { styled } from "styled-components";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Card from "../../../components-ui/molecules/Card";
import { steps } from "../../../style/theme";
import { Props } from "./Poll.ui";
import useGetPollElements from "./hooks/useGetPollElements";

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const PollContainer = styled.div`
  padding: 0 ${steps.spacing[8]};
`;

const PollUiNormal: React.FC<Props> = ({
  handleDismiss,
  poll,
  testId,
  ...rest
}) => {
  const { Header, Body, Button, Title, Subtitle, ErrorMessage } =
    useGetPollElements({ poll, handleDismiss, ...rest });

  return (
    <Wrapper>
      <Spatial rim glare mouseZoom mouseTiltX mouseTiltY dragMotion>
        <Card
          maxHeight="500px"
          testId={testId}
          onClose={poll?.dismissible ? handleDismiss : undefined}
          topSection={
            <PollContainer>
              <Space h={8} />
              {Header}
              <Space h={4} />
              {Title}
              <Space h={5} />
              {Subtitle}
              {Body}
              {Button}
              <Space h={2} />
              {ErrorMessage}
              <Space h={8} />
            </PollContainer>
          }
        />
      </Spatial>
    </Wrapper>
  );
};

export default PollUiNormal;
