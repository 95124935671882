import { useReflow } from "../../../../hooks/ui";
import { ReactionName } from "../../../actionBarPanel/lib/ReactionsTypes";
import { ReactionItem } from "../component/ReactionsBar";
import AttendeeBarUiNormal from "./AttendeeBar.ui.normal";

type Props = {
  items: ReactionItem[];
  handleReaction: (reaction: ReactionName, stage: number) => void;
  isOpen: boolean;
  micMuted: boolean;
  isVideoConferenceInitialized: boolean;
  toggleLocalAudio: () => void;
  webcamMuted: boolean;
  toggleVideo: () => void;
  activeScreenShare: boolean;
  toggleScreenShare: () => void;
  screenshareDisabled: boolean;
};

const AttendeeBarUi: React.FC<Props> = ({
  items,
  handleReaction,
  isOpen,
  micMuted,
  isVideoConferenceInitialized,
  toggleLocalAudio,
  webcamMuted,
  toggleVideo,
  activeScreenShare,
  toggleScreenShare,
  screenshareDisabled,
}) => {
  return useReflow({
    normal: (
      <AttendeeBarUiNormal
        items={items}
        handleReaction={handleReaction}
        isOpen={isOpen}
        micMuted={micMuted}
        isVideoConferenceInitialized={isVideoConferenceInitialized}
        toggleLocalAudio={toggleLocalAudio}
        webcamMuted={webcamMuted}
        toggleVideo={toggleVideo}
        activeScreenShare={Boolean(activeScreenShare)}
        toggleScreenShare={toggleScreenShare}
        screenshareDisabled={screenshareDisabled}
      />
    ),
    portrait: (
      <AttendeeBarUiNormal
        items={items}
        handleReaction={handleReaction}
        isOpen={isOpen}
        micMuted={micMuted}
        isVideoConferenceInitialized={isVideoConferenceInitialized}
        toggleLocalAudio={toggleLocalAudio}
        webcamMuted={webcamMuted}
        toggleVideo={toggleVideo}
        activeScreenShare={Boolean(activeScreenShare)}
        toggleScreenShare={toggleScreenShare}
        screenshareDisabled={screenshareDisabled}
      />
    ),
    landscape: (
      <AttendeeBarUiNormal
        items={items}
        handleReaction={handleReaction}
        isOpen={isOpen}
        micMuted={micMuted}
        isVideoConferenceInitialized={isVideoConferenceInitialized}
        toggleLocalAudio={toggleLocalAudio}
        webcamMuted={webcamMuted}
        toggleVideo={toggleVideo}
        activeScreenShare={Boolean(activeScreenShare)}
        toggleScreenShare={toggleScreenShare}
        screenshareDisabled={screenshareDisabled}
      />
    ),
  });
};

export default AttendeeBarUi;
