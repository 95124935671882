import React from "react";
import { SvgProps } from "../../types/svg";

const PersonTeleportIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 4C8 2.89531 8.89687 2 10 2C11.1031 2 12 2.89531 12 4C12 5.10469 11.1031 6 10 6C8.89687 6 8 5.10469 8 4ZM10 5C10.5531 5 11 4.55219 11 4C11 3.44781 10.5531 3 10 3C9.44688 3 9 3.44781 9 4C9 4.55219 9.44688 5 10 5ZM7 9C7 7.89687 7.89687 7 9 7H11C12.1031 7 13 7.89687 13 9V10.75C13 11.2531 12.6281 11.6688 12.1469 11.7406L11.8687 14.3813C11.8 15.0156 11.2656 15.5 10.5969 15.5H9.375C8.73438 15.5 8.2 15.0156 8.13125 14.3813L7.85313 11.7406C7.37188 11.6688 7 11.2531 7 10.75V9ZM8.76875 10.8625L9.12813 14.275C9.14062 14.4031 9.24687 14.5 9.375 14.5H10.5969C10.7531 14.5 10.8594 14.4031 10.8719 14.275L11.2312 10.8625L11.9719 10.75L12 8.97188C12 8.44688 11.5531 7.97188 11 7.97188H9C8.44688 7.97188 8 8.44688 8 8.97188V10.7219L8.76875 10.8625ZM3.65531 14.7125C3.39188 14.8531 3.21688 14.9875 3.11344 15.1031C3.0125 15.2156 3 15.2875 3 15.3188C3 15.3719 3.03906 15.5281 3.37281 15.7563C3.69031 15.975 4.18281 16.1906 4.83625 16.3813C6.1375 16.7594 7.9625 17 10 17C12.0375 17 13.8625 16.7594 15.1625 16.3813C15.8188 16.1906 16.3094 15.975 16.6281 15.7563C16.9594 15.5281 17 15.3719 17 15.3188C17 15.2875 16.9875 15.2156 16.8875 15.1031C16.7844 14.9875 16.6094 14.8531 16.3438 14.7125C15.8188 14.4344 15.0312 14.1813 14.0437 13.9906C13.7719 13.9375 13.5938 13.675 13.6469 13.3781C13.7 13.1344 13.9625 12.9562 14.2312 13.0094C15.2688 13.2094 16.1656 13.4844 16.8125 13.8281C17.1375 14 17.4219 14.2 17.6313 14.4094C17.8469 14.675 18 14.9719 18 15.3188C18 15.8688 17.6188 16.2875 17.1938 16.5813C16.7531 16.8844 16.1469 17.1375 15.4438 17.3406C14.0281 17.7531 12.1031 18 10 18C7.89687 18 5.97188 17.7531 4.55719 17.3406C3.85344 17.1375 3.2475 16.8844 2.80625 16.5813C2.38062 16.2875 2 15.8688 2 15.3188C2 14.9719 2.15459 14.675 2.3675 14.4094C2.57781 14.2 2.86344 14 3.18719 13.8281C3.83563 13.4844 4.73 13.2094 5.76875 13.0094C6.0375 12.9562 6.3 13.1344 6.35313 13.3781C6.37813 13.675 6.22813 13.9375 5.95625 13.9906C4.96812 14.1813 4.18125 14.4344 3.65531 14.7125Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PersonTeleportIcon;
