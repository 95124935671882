import styled, { css } from "styled-components";
import { INDICATOR_SIZE } from "../../features/panels/videoAvatars/lib/videoAvatarsConstants";

function getNumberOfBars(size: number) {
  const nrOfBars = size < INDICATOR_SIZE.LARGE ? 3 : 5;
  return Array.from({ length: nrOfBars });
}

function getAnimationName() {
  const randomNumber = Math.floor(Math.random() * 4);
  switch (randomNumber) {
    case 0:
      return "quiet";
    case 1:
      return "normal";
    case 2:
      return "quiet";
    case 3:
      return "loud";
    default:
      return "quiet";
  }
}

const SpeakingIndicator = styled.div<{
  $size: number;
  $color?: string;
}>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  overflow: hidden;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  @keyframes quiet {
    25% {
      transform: scaleY(0.6);
    }
    50% {
      transform: scaleY(0.4);
    }
    75% {
      transform: scaleY(0.8);
    }
  }

  @keyframes normal {
    25% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(0.4);
    }
    75% {
      transform: scaleY(0.6);
    }
  }
  @keyframes loud {
    25% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(0.4);
    }
    75% {
      transform: scaleY(1);
    }
  }
  .box {
    transform: scaleY(0.1);
    block-size: calc(${(p) => p.$size}px * 0.6);
    inline-size: calc(${(p) => p.$size}px * 0.01 + 1px);
    max-inline-size: calc(${(p) => p.$size}px * 0.01+ 1px);
    min-inline-size: calc(${(p) => p.$size}px * 0.01+ 1px);
    background: ${(p) => p.$color || p.theme.colorBelowRoot};
    animation-play-state: running;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    &:not(:last-child) {
      margin-inline-end: calc(${(p) => p.$size}px * 0.015 + 1px);
    }
  }
  ${(p) => css`
    ${getNumberOfBars(p.$size).map((_, i) => {
      return css`
        .box${i + 1} {
          animation-name: ${getAnimationName()};
        }
      `;
    })}
  `}
`;

export type Props = {
  size?: number;
  color?: string;
};

const SpeakingDancingBars: React.FC<Props> = ({
  size = INDICATOR_SIZE.LARGE,
  color,
}) => {
  return (
    <SpeakingIndicator $size={size} $color={color}>
      {getNumberOfBars(size).map((_, i) => {
        return <div key={i} className={`box box${i + 1}`} />;
      })}
    </SpeakingIndicator>
  );
};

export default SpeakingDancingBars;
