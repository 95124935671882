import { useReflow } from "../../../../../../hooks/ui";
import ControlsDefaultUiLandscape from "./ControlsDefault.ui.landscape";
import ControlsDefaultUiNormal from "./ControlsDefault.ui.normal";
import ControlsDefaultUiPortrait from "./ControlsDefault.ui.portrait";

const ControlsDefaultUi: React.FC = () => {
  return useReflow({
    normal: <ControlsDefaultUiNormal />,
    landscape: <ControlsDefaultUiLandscape />,
    portrait: <ControlsDefaultUiPortrait />,
  });
};

export default ControlsDefaultUi;
