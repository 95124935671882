import { GyaradosAxios } from "@journee-live/gyarados";
import { medias } from "@journee-live/mew";

/**
 * Returns the full url of an image object in our S3 bucket.
 * If dimensions are provided, it returns the url of a resized image using our image optimizing service.
 * The resizing is done preserving aspect ratio, it will resize the image to be as large as possible
 * while ensuring its dimensions are less than or equal to both those specified.
 * If a format is provided, it returns the url of a converted image using our image optimizing service.
 */
export const getImageUrl = (
  image?: GyaradosAxios.ImageResponseDto | null | string,
  width?: number,
  height?: number,
  format?: "webp" | "png" | "jpg" | "jpeg"
) => {
  if (typeof image === "string") return image;
  if (!image?.s3Path) return;
  let url: string | undefined = `${import.meta.env.VITE_MEDIAS_BUCKET_URL}/${
    image.s3Path
  }`;
  if (width && height) {
    url = medias.getUrlOfResizedImage(url, width, height);
  }
  if (format) {
    url = medias.getUrlOfOptimizedImage(url, format);
  }
  return url;
};
