import { SvgProps } from "../../types/svg";

const PlayIcon: React.FC<SvgProps> = (props) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2113_4000)">
      <path
        d="M5.5 11H1C0.692188 11 0.401251 10.8594 0.211813 10.6156C0.0222915 10.3719 -0.0448116 10.0563 0.0298603 9.75625L0.225063 8.97812C0.547188 7.6875 1.33063 6.60938 2.37281 5.90312L2.75 1H2C1.90969 1 1.82531 0.97625 1.75219 0.934375C1.60156 0.848125 1.5 0.685938 1.5 0.5C1.5 0.223875 1.72375 0 2 0H10C10.275 0 10.5 0.223875 10.5 0.5C10.5 0.685938 10.4 0.848125 10.2469 0.934375C10.175 0.97625 10.0906 1 10 1H9.25L9.62813 5.90312C10.6688 6.60938 11.4531 7.6875 11.775 8.97812L11.9688 9.75625C12.0438 10.0563 11.9781 10.3719 11.7875 10.6156C11.6 10.8594 11.3094 11 11 11H6.5V15.5C6.5 15.775 6.275 16 5.97188 16C5.725 16 5.47188 15.775 5.47188 15.5L5.5 11ZM3.33438 6.4625L2.93406 6.73125C2.08969 7.30312 1.45594 8.175 1.19531 9.21875L1 10H5.5V7C5.5 6.725 5.725 6.5 6 6.5C6.275 6.5 6.5 6.725 6.5 7V10H11L10.8063 9.21875C10.5438 8.175 9.90938 7.30312 9.06563 6.73125L8.66875 6.4625L8.24688 1H3.75313L3.33438 6.4625Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
    <defs>
      <clipPath id="clip0_2113_4000">
        <rect
          width="12"
          height="16"
          fill="currentColor"
          className="fill-color"
        />
      </clipPath>
    </defs>
  </svg>
);
export default PlayIcon;
