import { useEffect } from "react";
import googleAnalytics from "@analytics/google-analytics";
import { Analytics } from "analytics";
import { VisitorTokenData } from "../../features/login/loginUtils";
import { useStore } from "../../store/store";
import { useEnvironmentContext } from "../EnvironmentFetcher.core";
import { wrapGAPlugin } from "./gaPlugin";
import { logemonPlugin } from "./logemonPlugin";
import { umamiOnePlugin } from "./umamiPlugin";

export const useUmamiAnalytics = (
  umamiWebsiteUuid: string,
  disabled: boolean
) => {
  useEffect(() => {
    if (disabled) return;
    if (window.analytics) return;

    const plugins = [umamiOnePlugin({ umamiWebsiteUuid })];
    window.analytics = Analytics({
      app: `journee-lugia-ai-demo`,
      plugins,
    });
  }, [umamiWebsiteUuid, disabled]);
};

export const useAnalytics = (disabled: boolean) => {
  const { environment } = useEnvironmentContext();

  useEffect(() => {
    if (!environment?.id) return;
    if (disabled) return;
    // Do not initialize if already initialized
    if (window.analytics) return;

    const plugins: Record<string, unknown>[] = [
      logemonPlugin({ environmentId: environment.id }),
    ];

    if (environment.umamiWebsiteUuid)
      plugins.push(
        umamiOnePlugin({ umamiWebsiteUuid: environment.umamiWebsiteUuid })
      );

    if (environment.gaSettings?.measurementIds?.length) {
      const { measurementIds, debug, gtagConfig, gtagName } =
        environment.gaSettings;
      plugins.push(
        wrapGAPlugin(
          googleAnalytics({
            measurementIds,
            debug,
            ...(gtagConfig && { gtagConfig }),
            ...(gtagName && { gtagName }),
          }),
          environment?.id
        )
      );
    }

    window.analytics = Analytics({
      app: `journee-lugia-${environment.id}`,
      plugins,
    });
  }, [
    disabled,
    environment?.id,
    environment?.umamiWebsiteUuid,
    environment?.gaSettings,
  ]);
};

export const useAnalyticsIdentify = (disabled: boolean) => {
  const { environment } = useEnvironmentContext();
  const visitorId = useStore((s) => s.session.visitorTokenData?.id);
  const visitorToken = useStore((s) => s.session.visitorToken);
  const visitorTokenData = useStore((s) => s.session.visitorTokenData);
  const currentStep = useStore((s) => s.userFlow.currentStep);

  useEffect(() => {
    if (disabled) return;
    if (!environment?.id) return;
    if (!visitorId || !visitorToken || !visitorTokenData) return;
    if (!window.analytics) return;
    // Check if the user has reached the experience
    // Because before that, we are not sure if the visitor token is valid
    if (currentStep !== "login:availability") return;

    const traits: Record<string, unknown> = {
      environment_id: environment.id,
    };

    // Get trait map for GA
    const isGAEnabled = Boolean(environment.gaSettings?.measurementIds?.length);
    if (isGAEnabled) {
      const entries = Object.entries(environment.gaSettings.traitMap || {}) as [
        keyof VisitorTokenData,
        string,
      ][];
      for (const [key, value] of entries) {
        if (visitorTokenData[key as keyof typeof visitorTokenData]) {
          traits[value] = visitorTokenData[key];
        }
      }
    }

    window.analytics.identify(visitorId, traits, {
      // Send the visitor token to Logemon
      token: visitorToken,
    });
  }, [
    disabled,
    environment?.id,
    visitorId,
    visitorToken,
    currentStep,
    visitorTokenData,
    environment?.gaSettings,
  ]);
};
