import { getQueryStringsAsBooleans } from "../core/routing/routingUtils";
import { readBrowserInformation } from "../lib/browser";
import { readDeviceInformation } from "../lib/device";
import { readOsInformation } from "../lib/os";

// Place in this file all constant which are dynamically computed.
// All flags can be overriddden by query parameters.

// Environments
// Don't use flags. Use these directly:
// import.meta.env.MODE === "production";
// import.meta.env.MODE === "development";

// OSs
export let isIos = false;
export let isAndroid = false;
export let isWindows = false;

// Devices
export let isMobile = false;
export let isTablet = false;
export let isIpad = false;
export let isDesktop = false;

// Browsers
export let isChrome = false;
export let isChromeIos = false;
export let isOpera = false;
export let isFirefox = false;
export let isEdge = false;
export let isWebkit = false;
export let isSafariIos = false;
export let isSafari = false;
export let isSocialMediaBrowser = false;
export let isInstagramAgent = false;

// Inputs
export let isTouch = false;

// Others
export let isIFrame = false;
export let isForceDev = false;

export const initializeFlags = () => {
  const q = getQueryStringsAsBooleans();
  const browser = readBrowserInformation();
  const os = readOsInformation();
  const device = readDeviceInformation();

  isIos = q.isIos ?? os.isIos;
  isAndroid = q.isAndroid ?? os.isAndroid;
  isWindows = q.isWindows ?? os.isWindows;

  isMobile = q.isMobile ?? device.isMobile;
  isTablet = q.isTablet ?? device.isTablet;
  isIpad = q.isIpad ?? device.isIpad;
  isDesktop = !isMobile && !isTablet;

  isChrome = q.isChrome ?? browser.isChrome;
  isChromeIos = q.isChromeIos ?? browser.isChromeIos;
  isOpera = q.isOpera ?? browser.isOpera;
  isFirefox = q.isFirefox ?? browser.isFirefox;
  isEdge = q.isEdge ?? browser.isEdge;
  isWebkit = q.isWebkit ?? browser.isWebkit;
  isSafariIos = q.isSafariIos ?? browser.isSafariIos;
  isSafari = q.isSafari ?? browser.isSafari;
  isSocialMediaBrowser = q.isSocialMediaBrowser ?? browser.isSocialMediaBrowser;
  isInstagramAgent = q.isInstagramAgent ?? browser.isInstagramAgent;

  isTouch = q.isTouch ?? device.isTouch;

  isIFrame = q.isIFrame ?? browser.isIFrame;
  isForceDev = Boolean(q.isForceDev);
};
