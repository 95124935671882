import { ReactNode, forwardRef, useState } from "react";
import styled from "styled-components";
import Glass from "../atoms/Glass";
import Scroll from "../atoms/Scroll";
import CloseButton from "./CloseButton";

const ContentWrapper = styled.div<{ $noPadding?: boolean }>`
  ${(p) => !p.$noPadding && "position: absolute"};
  width: 100%;
  height: 100%;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: max(var(--safe-margin-top), 8px);
  right: max(var(--safe-margin-right), 8px);
  background: none;
  border: none;
`;

const Padding = styled.div`
  padding: 6px;
  height: calc(100% - 12px);
  width: calc(100% - 12px);
`;

const NoPadding = styled.div`
  height: 100%;
  width: 100%;
`;

const BottomWrapper = styled.div`
  overflow: hidden;
  height: auto;
  bottom: 0;
  position: absolute;
  width: 100%;
`;

const TopWrapper = styled.div<{ $height?: string }>`
  overflow: hidden;
  height: ${(p) => p.$height ?? "auto"};
  width: 100%;
`;

export interface Props {
  floatingSection?: ReactNode;
  brightCloseButton?: boolean;
  topSection?: ReactNode;
  topSectionHeight?: string;
  bottomSection?: ReactNode;
  hideScrollButton?: boolean;
  showFade?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  noPadding?: boolean;
  testId?: string;
  style?: React.CSSProperties;
}

/** The fullscreen+portrait version of the card component. */
const CardFullPortrait = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      floatingSection,
      bottomSection,
      onClose,
      topSection,
      topSectionHeight,
      brightCloseButton,
      hideScrollButton,
      showFade,
      noPadding,
      testId,
      style,
    },
    ref
  ) => {
    const [bottomElement, setBottomElement] = useState<HTMLDivElement | null>(
      null
    );
    const bottomHeight = bottomElement?.offsetHeight || 0;
    const computedHeight = `calc(100dvh - ${bottomHeight}px)`;

    return (
      <Glass
        testId={testId}
        width="100vw"
        height="100dvh"
        padding="0px"
        borderRadius="0px"
        blur="strong"
        ref={ref}
        style={style}
      >
        <ContentWrapper $noPadding={noPadding}>
          <Scroll
            height={computedHeight}
            barPaddingTop={onClose ? 40 : 0}
            fadeTop={Boolean(topSection || floatingSection)}
            hideScrollToBottom={hideScrollButton}
            showFade={showFade}
          >
            {Boolean(topSection) && (
              <TopWrapper $height={topSectionHeight}>{topSection}</TopWrapper>
            )}
            {noPadding ? (
              <NoPadding> {children}</NoPadding>
            ) : (
              <Padding>{children}</Padding>
            )}
          </Scroll>
        </ContentWrapper>

        {Boolean(bottomSection) && (
          <BottomWrapper ref={setBottomElement}>{bottomSection}</BottomWrapper>
        )}
        {floatingSection && <div>{floatingSection}</div>}
        {Boolean(onClose) && (
          <CloseWrapper>
            <CloseButton onClick={onClose} isGlass={brightCloseButton} />
          </CloseWrapper>
        )}
      </Glass>
    );
  }
);

export default CardFullPortrait;
