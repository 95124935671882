import { logWarn } from "../../../lib/logger";

interface RoomAndPlayer {
  playerId: number;
  roomId: string;
}

export const getPlayerKey = (
  data: RoomAndPlayer | string,
  playerId?: number
) => {
  if (typeof data === "string") return `${data}__${playerId}`;
  else return `${data.roomId}__${data.playerId}`;
};

export const parsePlayerKey = (
  key: string
): { playerId: number; roomId: string } | null => {
  if (!key || typeof key !== "string") {
    logWarn("GENERIC", "Not a player key:", key);
    return null;
  }
  const arr = key.split("__");
  if (arr.length !== 2) {
    logWarn("GENERIC", "Not a player key:", key);
    return null;
  }
  const playerId = parseInt(arr[1]);
  if (typeof playerId !== "number") {
    logWarn("GENERIC", "Not a player key:", key);
    return null;
  }
  return { playerId, roomId: arr[0] };
};

export const getWordInitial = (str: string | null | undefined) => {
  if (!str) return "-";
  const s = str.trim();
  if (!s || s.length < 1) return "-";
  return s.substr(0, 1).toUpperCase();
};

export const getNameInitials = (
  str: string | null | undefined,
  max: number = 3
) => {
  if (!str) return "-";
  const words = str.trim().split(/\s+/).filter(Boolean);
  return words
    .slice(0, max)
    .map((w) => getWordInitial(w))
    .join("");
};
