import { GyaradosAxios } from "@journee-live/gyarados";
import { useReflow } from "../../../hooks/ui";
import PopupUiLandscape from "./Popup.ui.landscape";
import PopupUiNormal from "./Popup.ui.normal";
import PopupUiPortrait from "./Popup.ui.portrait";

export type Props = {
  onClose?: () => void;
  handleTeleport?: () => void;
  handleExternalLink?: () => void;
  popup?: Partial<GyaradosAxios.ActionPanelResponseDto>;
  link?: string;
  extraText?: string;
};

const PopupUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <PopupUiNormal {...props} />,
    landscape: <PopupUiLandscape {...props} />,
    portrait: <PopupUiPortrait {...props} />,
  });
};

export default PopupUi;
