class SimpleAudioMeter {
  private audioContext: AudioContext;
  private analyser: AnalyserNode;
  private dataArray: Uint8Array;
  private animationFrameId: number | null = null;
  private isSpeaking: ((speaking: number) => void) | null = null;

  constructor(stream: MediaStream) {
    this.audioContext = new AudioContext();
    const source = this.audioContext.createMediaStreamSource(stream);
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 256;
    source.connect(this.analyser);

    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
  }

  start(callback: (volume: number) => void) {
    this.isSpeaking = callback;
    this.update();
  }

  private update = () => {
    this.analyser.getByteFrequencyData(this.dataArray);
    const sum = this.dataArray.reduce((acc, value) => acc + value, 0);
    const volume = sum / this.dataArray.length / 255; // Normalize to 0-1 range

    if (this.isSpeaking) {
      this.isSpeaking(volume);
    }

    this.animationFrameId = requestAnimationFrame(this.update);
  };

  stop() {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  close() {
    this.stop();
    this.audioContext.close();
  }
}

export default SimpleAudioMeter;
