/**
 * Base64url decoding is adapted from:
 * https://github.com/denoland/deno_std/blob/main/encoding/base64url.ts
 */
export function decodeBase64Url(b64url: string): Uint8Array {
  if (!/^[-_A-Z0-9]*?={0,2}$/i.test(b64url)) {
    // Contains characters not part of base64url spec.
    throw new TypeError("Failed to decode base64url: invalid character");
  }
  // Add padding
  let padded = b64url;
  switch (b64url.length % 4) {
    case 0:
      break;
    case 2:
      padded += "==";
      break;
    case 3:
      padded += "=";
      break;
    default:
      throw new TypeError("Illegal base64url string!");
  }
  const b64 = padded.replace(/-/g, "+").replace(/_/g, "/");
  const binString = atob(b64);
  const size = binString.length;
  const bytes = new Uint8Array(size);
  for (let i = 0; i < size; i++) {
    bytes[i] = binString.charCodeAt(i);
  }
  return bytes;
}

export function decodeJWTPayload<T>(jwt: string): T {
  const [, payload] = jwt.split(".");
  if (!payload) {
    throw new Error("JWT payload isn't found");
  }
  const textDecoder = new TextDecoder();
  return JSON.parse(textDecoder.decode(decodeBase64Url(payload)));
}
