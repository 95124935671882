import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { listen, unlisten } from "../../lib/bus";
import { hash } from "../../lib/hash";
import { logWarn } from "../../lib/logger";
import { CustomEventsSchema } from "../../types/events";
import { useEnvironmentPath } from "../routing/routingHooks";
import { Errors } from "./errors";

/** The sentry collector is used to dipatch errors to sentry from custom event.
 * This is usefull for 2 reasons:
 * - Allows atomics components to be decoupled from the error reporting logic.
 * - Filters out some errors in special conditions (cms users caused).
 * - Avoid sending an error twice if coming from the same place.
 */

// Some errors (e.g. broken links) can be caused mostly by bad settings in the CMS.
// For this reason is usefuly to distinghish them and track them only for our
// Journee organisation users.
const errorsCausedByCmsUsers: (keyof typeof Errors)[] = [
  "IMAGE_COULDN_NOT_LOAD",
  "LOGIN_VIDEO_BG_COULD_NOT_LOAD",
];

const pastCalls: number[] = [];

export const useSentryCollector = () => {
  const { orgSlug } = useEnvironmentPath();

  useEffect(() => {
    const handler = (payload: CustomEventsSchema["errorReport"]) => {
      // Don't report the same error twice.
      if (payload.signature) {
        const id = hash(payload.signature);
        if (pastCalls.includes(id)) return;
        pastCalls.push(id);
      }

      logWarn("GENERIC", Errors[payload.type], payload.payload);

      // Send the user caused error to sentry only if it belong to our Journee organisation.
      if (errorsCausedByCmsUsers.includes(payload.type) && orgSlug) return;

      Sentry.captureException(new Error(Errors[payload.type]), {
        extra: payload.payload,
      });
    };

    listen("errorReport", handler);
    return () => {
      if (handler) unlisten("errorReport", handler);
    };
  }, [orgSlug]);
};
