import loadable from "@loadable/component";
import { useEnvironmentContext } from "../EnvironmentFetcher.core";

const Web3Modal = loadable(() => import("./web3ModalConfig"));

type Props = {
  children?: React.ReactNode;
};

const Web3ContextProvider: React.FC<Props> = ({ children }) => {
  const { environment } = useEnvironmentContext();
  const shouldUseWeb3 = environment?.enableWeb3;

  if (!shouldUseWeb3) return <>{children}</>;

  return <Web3Modal>{children}</Web3Modal>;
};

export default Web3ContextProvider;
