import { useEffect } from "react";
import { useGetPopupBySlug } from "../../../api/gyaradosHooks";
import Overlay from "../../../components-ui/molecules/Overlay";
import { useStore } from "../../../store/store";
import PopupLogic from "./Popup.logic";

const PopupPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.popup.visible);
  const closePanel = useStore((s) => s.layout.closePanel);
  const popupId = useStore((s) => s.layout.panels.popup?.slug);
  const link = useStore((s) => s.layout.panels.popup?.options?.link);
  const extraText = useStore((s) => s.layout.panels.popup?.options?.extraText);
  const data = useGetPopupBySlug(popupId);

  // Close the popup if it's visible but the data is not loaded
  useEffect(() => {
    if (visible && !data?.actionPanelId) {
      closePanel("popup", { slug: popupId });
    }
  }, [visible, data?.actionPanelId, closePanel, popupId]);

  return (
    <Overlay
      id="layout-popup"
      open={visible}
      onClose={() => {
        if (data?.dismissible) closePanel("popup", { slug: popupId });
      }}
      watch={[popupId, visible]}
    >
      <PopupLogic popupId={popupId} link={link} extraText={extraText} />
    </Overlay>
  );
};

export default PopupPanel;
