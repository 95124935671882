import { SvgProps } from "../../types/svg";

const CheckIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.8313 4.18532C18.0562 4.43004 18.0562 4.83264 17.8313 5.07736L8.11752 15.8135C7.8961 16.0622 7.53183 16.0622 7.31042 15.8135L2.16735 10.1297C1.94422 9.88494 1.94422 9.48234 2.16735 9.23762C2.39034 8.98895 2.7521 8.98895 2.9753 9.23762L7.71397 14.4754L17.0242 4.18532C17.2456 3.93823 17.6098 3.93823 17.8313 4.18532Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CheckIcon;
