import { useState } from "react";

const HIDE_GLOW_DATA = {
  hide: true,
  stage: 0,
  opacity: 0,
};

export type GlowData = ReturnType<typeof useReactionsGlow>["glowData"];
export type SetGlowData = ReturnType<typeof useReactionsGlow>["setGlowData"];

const useReactionsGlow = () => {
  const [thumbsUp, setThumbsUp] = useState(HIDE_GLOW_DATA);
  const [love, setLove] = useState(HIDE_GLOW_DATA);
  const [laughing, setLaughing] = useState(HIDE_GLOW_DATA);
  const [celebrate, setCelebrate] = useState(HIDE_GLOW_DATA);
  const [sad, setSad] = useState(HIDE_GLOW_DATA);
  const [surprised, setSurprised] = useState(HIDE_GLOW_DATA);
  const glowData = {
    thumbsUp,
    love,
    laughing,
    celebrate,
    sad,
    surprised,
  };
  const setGlowData = {
    thumbsUp: setThumbsUp,
    love: setLove,
    laughing: setLaughing,
    celebrate: setCelebrate,
    sad: setSad,
    surprised: setSurprised,
  };

  return {
    glowData,
    setGlowData,
  };
};

export default useReactionsGlow;
