import { ReactNode } from "react";
import { useAnalytics, useAnalyticsIdentify } from "../analytics/useAnalytics";
import { useLogrocket } from "./logrocket";
import { useSentryWithEnvironment } from "./sentry";
import { useWebRTCStats } from "./webrtc";

type Props = {
  /** Record sentry errors. */
  sentry?: boolean;
  /** Record the browser session and send it to the logrocket platform for playback. */
  logrocket?: boolean;
  analytics?: boolean;
  children?: ReactNode;
};

/** Adds and initialize different tracking tools that are related to the environment. */
const EnvironmentTracking: React.FC<Props> = ({
  sentry,
  logrocket,
  analytics,
  children,
}) => {
  useSentryWithEnvironment(!sentry);
  useLogrocket(!logrocket);
  useAnalytics(!analytics);
  useAnalyticsIdentify(!analytics);
  useWebRTCStats();
  return <>{children} </>;
};

export default EnvironmentTracking;
