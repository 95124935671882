import * as RadixTooltip from "@radix-ui/react-tooltip";
import styled, { css } from "styled-components";
import { iconMenuTestIds } from "../../../constants/testIds";
import Button from "../../atoms/Button";
import Tooltip from "../Tooltip";
import { DynamicButtonMenuItem } from "./DynamicButtonMenu";

const Wrapper = styled.div<{
  $isOpen: boolean;
  $isFocused: boolean;
  $nrOfChildren: number;
  $isColumn?: boolean;
  /** The menu is not attached to an anchor button */
  $standAlone?: boolean;
}>`
  pointer-events: none;
  display: flex;
  flex-direction: ${(p) => (p.$isColumn ? "column" : "row-reverse")};
  align-items: center;
  justify-content: space-around;

  position: absolute;
  z-index: 1;

  ${(p) =>
    !p.$standAlone &&
    css`
      bottom: 0;
      left: 0;
      right: 0;
    `}

  padding-top: 0px;
  padding-bottom: 0px;

  border-radius: ${(p) => p.theme.radiusFull};
  overflow: hidden;

  width: calc(${(p) => p.theme.elements.actionButton.size});
  height: calc(${(p) => p.theme.elements.actionButton.size});

  background-color: ${(p) =>
    p.$isFocused ? p.theme.colorBelow1 : "transparent"};

  ${(p) =>
    p.$isOpen &&
    `
      ${
        p.$isColumn
          ? `height: calc(${p.$nrOfChildren} * ${p.theme.elements.actionButton.size}`
          : `width: calc(${p.$nrOfChildren} * ${p.theme.elements.actionButton.size}`
      });
      padding: 2px;
      background-color: ${p.theme.colorBelow1};
      backdrop-filter: blur(${p.theme.blurLight});
    `}

  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  transition-property: background-color, height, width;
`;

type Props = {
  items?: DynamicButtonMenuItem[];
  closeOnSelect?: boolean;
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  focused: boolean;
  direction: "column" | "row";
  standAlone?: boolean;
};

const DynamicButtonMenuDrawer: React.FC<Props> = ({
  items,
  closeOnSelect,
  isOpen,
  setIsOpen,
  focused,
  direction,
  standAlone,
}) => {
  if (!items) {
    return null;
  }
  const isColumn = direction === "column";
  const onItemClick = (handler?: () => void) => {
    if (closeOnSelect && setIsOpen) {
      setIsOpen(false);
    }
    handler?.();
  };

  return (
    <Wrapper
      $isOpen={isOpen}
      $nrOfChildren={items?.length || 0}
      $isFocused={focused}
      $isColumn={isColumn}
      $standAlone={standAlone}
    >
      {isOpen ? (
        <RadixTooltip.Provider delayDuration={300} skipDelayDuration={1000}>
          {items?.map((item, i) => (
            <Tooltip
              label={item.tooltip}
              key={item.value}
              skipProvider
              offset={13}
              side={isColumn ? "right" : "top"}
            >
              <Button.Subtle
                testId={iconMenuTestIds.menuItem(`${i}`)}
                onClick={() => onItemClick(item.onClick)}
                override={{ height: "40px", width: "40px" }}
                circular
              >
                {item.componentFilled}
              </Button.Subtle>
            </Tooltip>
          ))}
        </RadixTooltip.Provider>
      ) : null}
    </Wrapper>
  );
};

export default DynamicButtonMenuDrawer;
