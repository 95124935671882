import { styled } from "styled-components";
import Row from "../../../../../../components-ui/atoms/Row";
import ButtonsSchema from "./ButtonsSchema";
import KeysSchema from "./KeysSchema";

const Content = styled.div`
  padding-right: max(var(--safe-margin-right), 64px);
`;

const ControlsDefaultUiLandscape: React.FC = () => {
  return (
    <Content>
      <Row
        gap={6}
        justify="space-between"
        align="flex-start"
        style={{ minWidth: 0 }}
      >
        <ButtonsSchema />
        <KeysSchema />
      </Row>
    </Content>
  );
};

export default ControlsDefaultUiLandscape;
