import ButtonCard from "../../../../../components-ui/molecules/ButtonCard";
import { useText } from "../../../../../core/i18n/i18nHooks";
import { settingsOptions } from "../../Settings.logic";
import { Props } from "./SettingsHomePage.ui";

const CardList: React.FC<Props> = ({
  inactive,
  hidden,
  disabled,
  onClick,
  itemHeight = "80px",
}) => {
  const t = useText();

  return settingsOptions.map(
    (option) =>
      !hidden?.includes(option.name) && (
        <ButtonCard
          height={itemHeight}
          width="100%"
          key={option.name}
          testId={option.testId}
          label={t(option.label)}
          disabled={disabled?.includes(option.name)}
          icon={
            inactive?.includes(option.name)
              ? option.iconInactive ?? option.icon
              : option.icon
          }
          onClick={() => onClick?.(option.name)}
          iconProps={{ size: option.iconSize }}
        />
      )
  );
};

export default CardList;
