import { useEffect, useState } from "react";
import { sendGameMessage } from "../../../../../core/gameConnection/webrtc/webRtcMessageHandlers";
import { useText } from "../../../../../core/i18n/i18nHooks";
import { useIsLandscape, useIsSmallScreen } from "../../../../../hooks/ui";
import { useStore } from "../../../../../store/store";
import TextChatInputUi from "./TextChatInputUi";
import { useCurrentTextChatChannel } from "./textChatHooks";

const TextChatInput: React.FC = () => {
  const t = useText();
  const addTextChatMessage = useStore((s) => s.textChat.addTextChatMessage);
  const isLandscape = useIsLandscape();
  const isSmallScreen = useIsSmallScreen();
  const [userHasJustSentMessage, setUserHasJustSentMessage] = useState(false);
  const { type, slug } = useCurrentTextChatChannel();
  const playerId = useStore((s) => s.gameConnection.playerId);
  const [value, setValue] = useState("");

  // Reset the state of userHasJustSentMessage after few seconds
  useEffect(() => {
    let timer: number | undefined;
    if (userHasJustSentMessage && !timer)
      timer = window.setTimeout(() => {
        setUserHasJustSentMessage(false);
      }, 3000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [userHasJustSentMessage]);

  const handleSendMessage = () => {
    if (value.trim() === "") {
      setValue("");
      return;
    }
    if (!value) return;
    setUserHasJustSentMessage(true);

    if (type === "smartChat" && slug) {
      sendGameMessage({
        type: "SmartChatUserPrompt",
        smartChatSlug: slug,
        message: value,
      });
    } else {
      sendGameMessage({
        type: "SendChatMessage",
        content: value,
      });
    }
    if (slug) addTextChatMessage(slug, t("chat_me"), playerId || 0, value);
    setValue("");
  };

  return (
    <TextChatInputUi
      value={value}
      onChange={setValue}
      onSend={handleSendMessage}
      placeholder={t("chat_enter_message")}
      isSmallscreenLandscape={isLandscape && isSmallScreen}
    />
  );
};

export default TextChatInput;
