import Icon from "../../../../components-ui/atoms/Icon";
import { logError } from "../../../../lib/logger";
import { squeezeString } from "../lib/statsUtils";

type Props = {
  value?: string | number;
  low?: number;
  high?: number;
  copiable?: boolean;
  minimize?: boolean;
  children?: React.ReactNode;
  unit?: string;
  fixed?: number;
  squeezed?: number;
};

function compare(a: number, b: number, reverse = false) {
  return (reverse ? b - a : a - b) <= 0;
}

const StatsValue: React.FC<Props> = ({
  value,
  low,
  high,
  copiable,
  minimize,
  children,
  unit,
  fixed = 0,
  squeezed,
}) => {
  const isNumber = typeof value === "number";
  const isValidNumber = isNumber && !isNaN(value);
  const isLimited = isValidNumber && low !== undefined && high !== undefined;
  const sorting = minimize ? [high, low] : [low, high];
  const color = isLimited
    ? sorting[0] && compare(value, sorting[0], minimize)
      ? "red"
      : sorting[1] && compare(value, sorting[1], minimize)
        ? "orange"
        : "inherit"
    : "inherit";
  const formattedValue = isNumber
    ? isValidNumber
      ? value.toFixed(fixed)
      : "N/A"
    : squeezed
      ? squeezeString(value || "", squeezed)
      : value;
  return (
    <span style={{ display: "inline-flex", color, alignItems: "center" }}>
      {children || formattedValue}
      {unit && ` ${unit}`}
      {copiable && (
        <Icon.Clipboard
          size="12px"
          style={{ cursor: "pointer", padding: "0 0 0 0.5rem" }}
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(String(value));
            } catch (error) {
              logError("GENERIC", error);
            }
          }}
        />
      )}
    </span>
  );
};

export default StatsValue;
