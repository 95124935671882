import { useEffect } from "react";
import { styled } from "styled-components";
import Hide from "../../../components-ui/atoms/Hide";
import { useStore } from "../../../store/store";
import TextChatPreviewLogic from "./TextChatPreview.logic";

const Layout = styled.div`
  position: absolute;
  left: 0px;
  bottom: -8px;
`;

const TextChatPreview: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.textChatPreview.visible);
  const isSocialPanelVisible = useStore((s) => s.layout.panels.social.visible);
  const isTextChatTabOpen = useStore((s) => s.layout.panels.social.subpage);
  const closePanel = useStore((s) => s.layout.closePanel);
  const openPanel = useStore((s) => s.layout.openPanel);
  const isTextChatOpen =
    isSocialPanelVisible && isTextChatTabOpen === "social/chat";
  useEffect(() => {
    if (!isTextChatOpen) {
      openPanel("textChatPreview");
    } else {
      closePanel("textChatPreview");
    }
  }, [closePanel, isTextChatOpen, openPanel]);
  return (
    <Layout id="text-chat-preview-panel">
      <Hide hide={!visible} height="100%" width="100%">
        <TextChatPreviewLogic />
      </Hide>
    </Layout>
  );
};

export default TextChatPreview;
