import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import AvatarCarousel from "./AvatarCarousel";
import AvatarGrid from "./AvatarGrid";

type Props = {
  /** We need a minimum of 3 options to make the carousel work. */
  /** so we extend the array if it has less than 3 options. */
  options: AvatarResponseDto[];
  initialAvatarId: string;
  onSelect: (avatarId: string) => void;
  openReadyPlayerMeEditor: () => void;
  isLandscape?: boolean;
};

const AvatarSelector: React.FC<Props> = ({
  options,
  initialAvatarId,
  onSelect,
  openReadyPlayerMeEditor,
  isLandscape,
}) => {
  const isCarousel = options.length > 3;

  return (
    <>
      {isCarousel ? (
        <AvatarCarousel
          options={options}
          initialAvatarId={initialAvatarId}
          onSelect={onSelect}
          openReadyPlayerMeEditor={openReadyPlayerMeEditor}
        />
      ) : (
        <AvatarGrid
          options={options}
          initialAvatarId={initialAvatarId}
          onSelect={onSelect}
          openReadyPlayerMeEditor={openReadyPlayerMeEditor}
          isLandscape={isLandscape}
        />
      )}
    </>
  );
};

export default AvatarSelector;
