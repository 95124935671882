import { useCallback, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { styled, useTheme } from "styled-components";
import Hide from "../../../../../components-ui/atoms/Hide";
import Icon from "../../../../../components-ui/atoms/Icon";
import Space from "../../../../../components-ui/atoms/Space";
import { useText } from "../../../../../core/i18n/i18nHooks";
import { steps } from "../../../../../style/theme";

const InputContainer = styled.div`
  pointer-events: all;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;

  align-items: center;
  justify-content: flex-start;
  border-radius: ${steps.borderRadius.b100};
  height: ${steps.elementHeight.e10};
  width: calc(100% - 64px);
  padding: 0 32px;
  transform-origin: left;
  background-color: ${(p) => p.theme.colorAbove0};
  transition: 0.3s ease-in-out;
`;

const SearchIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 12px;
`;

const ClearIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 12px;
`;

const Input = styled.input`
  all: unset;
  font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  font-size: ${steps.font.f20.size};
  color: ${(p) => p.theme.colorAbove5};
  text-align: start;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 0.1s ease-in-out;
`;

type Props = {
  onSearch: (value: string) => void;
  onClear: () => void;
};

const SearchPlayers: React.FC<Props> = ({ onSearch, onClear }) => {
  const t = useText();
  const theme = useTheme();

  const [localValue, setLocalValue] = useState("");
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(debounce(onSearch, 200), [onSearch]);

  const handleChange = (value: string) => {
    setLocalValue(value);
    debouncedOnSearch(value);
  };
  const handleOnClear: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLocalValue("");
    onClear();
  };

  return (
    <InputContainer ref={inputWrapperRef}>
      <SearchIconWrapper>
        <Icon.MagnifyingGlass size="18px" />
      </SearchIconWrapper>

      <Space w={2} />
      <Input
        ref={inputRef}
        value={localValue}
        type="text"
        placeholder={t("search_for_people")}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDownCapture={(e) => e.stopPropagation()}
      />
      <ClearIconWrapper>
        <Hide hide={!localValue}>
          <Icon.Cross
            size="16px"
            onClick={handleOnClear}
            color={theme.colorAbove3}
          />
        </Hide>
      </ClearIconWrapper>
    </InputContainer>
  );
};

export default SearchPlayers;
