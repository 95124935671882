import { styled } from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Icon from "../../../components-ui/atoms/Icon";
import Row from "../../../components-ui/atoms/Row";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import CardFullLandscape from "../../../components-ui/molecules/CardFullLandscape";
import { PanelTestIds } from "../../../constants/testIds";
import { Props } from "./Settings.ui";
import BottomBar from "./component/BottomBar";
import VolumeFullScreenTogglesLandscape from "./component/VolumeAndFullscreenToggles.landscape";

const Content = styled.div`
  padding-top: max(var(--safe-margin-top), 32px);
  padding-left: max(var(--safe-margin-left), 64px);
  padding-right: max(var(--safe-margin-left), 40px);
`;

const SettingsUiLandscape: React.FC<Props> = ({
  children,
  onClose,
  onPrevious,
  title,
  isMuted,
  links,
  locales,
  selectedLocaleCode,
  handleSettingClick,
  onShowStats,
  openLanguagePanel,
}) => {
  const content = (
    <Content>
      <Row gap={2}>
        {onPrevious && (
          <Button.Tertiary onClick={onPrevious} circular>
            <Icon.Chevron size="1rem" rotate="-90deg" shiftY="-0.1rem" />
          </Button.Tertiary>
        )}
        <Typo.Title>{title}</Typo.Title>
      </Row>
      <Space h={6} />
      {children}
    </Content>
  );

  return (
    <CardFullLandscape
      hideScrollButton
      testId={PanelTestIds.settings.landscape.root}
      onClose={onClose}
      closeSection={
        <VolumeFullScreenTogglesLandscape
          isMuted={isMuted}
          handleSettingClick={handleSettingClick}
        />
      }
      bottomSection={
        <BottomBar
          openLanguagePanel={openLanguagePanel}
          locales={locales}
          selectedLocaleCode={selectedLocaleCode}
          links={links}
          onShowStats={onShowStats}
        />
      }
    >
      {content}
    </CardFullLandscape>
  );
};

export default SettingsUiLandscape;
