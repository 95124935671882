import { ReactNode, useCallback, useEffect, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import styled, { useTheme } from "styled-components";
import { useGetCurrentAuthMode } from "../../../api/gyaradosHooks";
import CardContentAlignment from "../../../components-ui/atoms/CardContentAlignment";
import Column from "../../../components-ui/atoms/Column";
import HeaderBadge from "../../../components-ui/atoms/HeaderBadge";
import Icon from "../../../components-ui/atoms/Icon";
import Row from "../../../components-ui/atoms/Row";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import Card from "../../../components-ui/molecules/Card";
import CardFullLandscape from "../../../components-ui/molecules/CardFullLandscape";
import CardPartialPortrait from "../../../components-ui/molecules/CardPartialPortrait";
import Overlay from "../../../components-ui/molecules/Overlay";
import { HCAPTCHA_SITE_KEY } from "../../../constants/configs";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { useIsLandscape, useIsSmallScreen } from "../../../hooks/ui";
import { useStore } from "../../../store/store";
import { steps } from "../../../style/theme";
import { NudgeRight } from "../../panels/poll/Poll.ui.landscape";
import { PropsLoginPageUi } from "../LoginPageUi";
import AuthPasswordForm from "../forms/AuthPasswordForm";
import AuthSSOForm from "../forms/AuthSSOForm";

const FormContainer = styled.div`
  padding: 0 ${steps.spacing[6]};
`;

const useAuthManualCheckStep = (
  setLoginPageProps: (props: PropsLoginPageUi) => void
): {
  dialog?: ReactNode;
} => {
  const theme = useTheme();
  const { environmentId } = useEnvironmentContext();
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const visitorToken = useStore((s) => s.session.visitorToken);
  const { environment } = useEnvironmentContext();
  const authMode = useGetCurrentAuthMode();
  const isCaptchaEnabled = environment.enableHCaptcha;
  const setVisitorToken = useStore((s) => s.session.setVisitorToken);
  const [loginError, setLoginError] = useState<Error | null>(null);
  const [isValidatingLogin, setIsValidatingLogin] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(
    undefined
  );
  const [isRestricted, setIsRestricted] = useState(false);
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();

  const handleLogin = useCallback(
    async (password?: string) => {
      if (authMode === "sso") return;
      if (isValidatingLogin) return;
      setIsValidatingLogin(true);

      window.analytics?.track("login", {
        type: "login",
        name: authMode,
      });

      try {
        const { token, error }: { token?: string; error?: string } =
          await fetch(
            `${import.meta.env.VITE_GYARALESS_URL}/login/${environmentId}`,
            {
              method: "POST",
              body: JSON.stringify({
                ...(password && { password }),
                ...(captchaToken && { captcha: captchaToken }),
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ).then((response) => response.json());
        if (!token) {
          // Handle visitor restriction
          if (error === "Visitor is restricted") setIsRestricted(true);
          throw new Error(error);
        }
        setVisitorToken(token);
        setLoginError(null);
        completeStep("login:auth-check");
      } catch (error: unknown) {
        setLoginError(error as Error);
      } finally {
        setIsValidatingLogin(false);
      }
    },
    [
      authMode,
      captchaToken,
      completeStep,
      environmentId,
      isValidatingLogin,
      setVisitorToken,
      setIsRestricted,
    ]
  );

  useEffect(() => {
    if (currentStep !== "login:auth-manual") return;
    if (visitorToken) {
      // Skip step if token is already set
      completeStep("login:auth-manual");
      window.analytics?.track("flow", {
        type: "flow",
        name: "authorized",
      });
    } else if (authMode === "open" && !loginError) {
      // Auto login if auth mode is open and there was no error
      handleLogin();
    }
  }, [
    visitorToken,
    currentStep,
    completeStep,
    authMode,
    handleLogin,
    loginError,
  ]);

  useEffect(() => {
    if (currentStep !== "login:auth-manual" || isValidatingLogin) return;
    setLoginPageProps({
      isMainButtonVisible: false,
      isRestricted,
    });
  }, [currentStep, isValidatingLogin, setLoginPageProps, isRestricted]);

  let dialog;
  if (visitorToken || authMode === "open" || isRestricted) {
    dialog = null;
  } else {
    const error = loginError && (
      <>
        <Space h={3} />
        <Typo.Body color={theme.colorDanger}>{loginError?.message}</Typo.Body>
      </>
    );

    const captcha = isCaptchaEnabled && (
      <HCaptcha
        sitekey={HCAPTCHA_SITE_KEY}
        onError={(error) => {
          setLoginError(new Error(error));
        }}
        onVerify={setCaptchaToken}
      />
    );

    const header = (
      <Row width="100%" align="center" justify="center">
        <HeaderBadge>
          <Icon.AreaLock inheritColor size="40px" />
        </HeaderBadge>
      </Row>
    );

    const form =
      authMode === "sso" ? (
        <AuthSSOForm error={error} />
      ) : authMode === "password" ? (
        <AuthPasswordForm
          environment={environment.name}
          onAuth={handleLogin}
          error={error}
          captcha={captcha}
        />
      ) : null;

    const card = isSmallScreen ? (
      isLandscape ? (
        <CardFullLandscape topSection={<NudgeRight>{header}</NudgeRight>}>
          <FormContainer>
            <Space h={9} />
            <CardContentAlignment>{form}</CardContentAlignment>
          </FormContainer>
        </CardFullLandscape>
      ) : (
        <CardPartialPortrait
          topSection={
            <>
              <Space h={8} />
              {header}
            </>
          }
          topSectionHeight="120px"
          padding="0"
        >
          <FormContainer>
            <Column align="center">
              <Space h={4} />
              {form}
              <Space h={8} />
            </Column>
          </FormContainer>
        </CardPartialPortrait>
      )
    ) : (
      <Spatial rim glare mouseZoom mouseTiltX mouseTiltY dragMotion>
        <Card
          width="416px"
          topSection={
            <>
              <Space h={8} />
              {header}
            </>
          }
          topSectionHeight="120px"
        >
          <Column align="center">
            <Space h={4} />
            {form}
            <Space h={7} />
          </Column>
        </Card>
      </Spatial>
    );
    dialog = (
      <Overlay
        open={currentStep === "login:auth-manual" && !isValidatingLogin}
        noEscClosing
        noOverlayClosing
        watch={[isSmallScreen, isLandscape]}
      >
        {card}
      </Overlay>
    );
  }

  return {
    dialog,
  };
};

export default useAuthManualCheckStep;
