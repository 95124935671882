export function stopVideoStream(mediaStream: MediaStream) {
  mediaStream.getVideoTracks().forEach((track) => {
    track.stop();
    track.enabled = false;
  });
}

export function stopAudioStream(mediaStream: MediaStream) {
  mediaStream.getAudioTracks().forEach((track) => {
    track.stop();
    track.enabled = false;
  });
}
