import { SvgProps } from "../../types/svg";

const ScreenShareFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.23722 4H15.7806C17.0056 4 18 4.95946 18 6.14018V13.8438C18 15.025 17.0056 15.9839 15.7786 15.9837H10.3953C10.4295 15.7024 10.452 15.4179 10.452 15.1279C10.452 14.9888 10.4468 14.8511 10.4377 14.7143H10.43C10.1958 11.0384 7.14535 8.09693 3.33333 7.87109V7.86542C3.19172 7.85683 3.04905 7.85197 2.90514 7.85197C2.60431 7.85197 2.30903 7.87364 2.01736 7.9067V6.14018C2.01736 4.95946 3.01278 4 4.23722 4Z"
      fill="currentColor"
      className="fill-color"
    />
    <path
      d="M2.0175 15.1284C2.0175 14.6556 2.415 14.2723 2.90528 14.2723C3.39556 14.2723 3.79306 14.6556 3.79306 15.1284C3.79306 15.6012 3.39556 15.9842 2.90528 15.9842C2.415 15.9842 2.0175 15.6012 2.0175 15.1284Z"
      fill="currentColor"
      className="fill-color"
    />
    <path
      d="M2 12.5768C2 12.1027 2.39722 11.7196 2.88778 11.7196C4.84722 11.7196 6.43889 13.2545 6.44056 15.1442C6.44056 15.6172 6.04444 16 5.55278 16C5.06111 16 4.66389 15.617 4.66361 15.1445C4.66361 14.2008 3.86667 13.4326 2.88778 13.4326C2.39722 13.4326 2 13.0498 2 12.5768Z"
      fill="currentColor"
      className="fill-color"
    />
    <path
      d="M2.0175 9.99062C2.0175 9.51759 2.41444 9.13482 2.90528 9.13482C6.30833 9.13482 9.11945 11.8241 9.12 15.1268C9.12 15.5998 8.72278 15.9826 8.23194 15.9826C7.74139 15.9826 7.34417 15.5998 7.34417 15.1268C7.34417 12.7664 5.35306 10.8464 2.90528 10.8464C2.41472 10.8464 2.0175 10.4637 2.0175 9.99062Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ScreenShareFilledIcon;
