import { GyaradosSDK } from "@journee-live/gyarados";
import { useReflow } from "../../../hooks/ui";
import FullscreenVideoUiNormal from "./FullscreenVideo.ui.normal";

export type Props = {
  videoURL: GyaradosSDK.LevelVideoDto["videoURL"] | undefined;
  backgroundImageUrl: string | undefined;
  isGameMuted?: boolean;
  showingPlayer?: boolean;
  showSkipButton?: boolean;
  onSkipClick: () => void;
  onError?: () => void;
  isContained?: boolean;
  handleVideoEnded: () => void;
};

const FullscreenVideoUi: React.FC<Props> = (props) => {
  const render = useReflow({
    normal: <FullscreenVideoUiNormal {...props} />,
  });

  return render;
};

export default FullscreenVideoUi;
