import { SvgProps } from "../../types/svg";

const PurpleScreenShare: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7806 3.5H4.23722C3.01278 3.5 2.01736 4.53942 2.01736 5.81853V7.73225C2.30903 7.69645 2.60431 7.67297 2.90514 7.67297C3.20597 7.67297 3.50139 7.69598 3.79306 7.7308V5.81853C3.79306 5.56302 3.9925 5.35482 4.23694 5.35482H15.7786C16.0232 5.35482 16.2225 5.56305 16.2225 5.81853V14.1641C16.2225 14.4196 16.0231 14.6278 15.7786 14.6278H10.3953C10.4295 14.9325 10.452 15.2409 10.452 15.5552C10.452 15.8694 10.4295 16.1776 10.3953 16.4823H15.7786C17.0056 16.4826 18 15.4437 18 14.1641V5.81853C18 4.53942 17.0056 3.5 15.7806 3.5ZM2.90528 14.6283C2.415 14.6283 2.0175 15.0436 2.0175 15.5558C2.0175 16.0679 2.415 16.4829 2.90528 16.4829C3.39556 16.4829 3.79306 16.0679 3.79306 15.5558C3.79306 15.0436 3.39556 14.6283 2.90528 14.6283ZM2.88778 11.8629C2.39722 11.8629 2 12.2779 2 12.7915C2 13.304 2.39722 13.7186 2.88778 13.7186C3.86667 13.7186 4.66361 14.5509 4.66361 15.5732C4.66389 16.085 5.06111 16.5 5.55278 16.5C6.04444 16.5 6.44056 16.0853 6.44056 15.5729C6.43889 13.5257 4.84722 11.8629 2.88778 11.8629ZM2.90528 9.06272C2.41444 9.06272 2.0175 9.47739 2.0175 9.98984C2.0175 10.5023 2.41472 10.917 2.90528 10.917C5.35306 10.917 7.34417 12.997 7.34417 15.554C7.34417 16.0665 7.74139 16.4811 8.23194 16.4811C8.72278 16.4811 9.12 16.0665 9.12 15.554C9.11944 11.9761 6.30833 9.06272 2.90528 9.06272Z"
      fill="#111111"
      fillOpacity="0.75"
    />
    <path
      d="M15.7806 3.5H4.23722C3.01278 3.5 2.01736 4.53942 2.01736 5.81853V7.73225C2.30903 7.69645 2.60431 7.67297 2.90514 7.67297C3.20597 7.67297 3.50139 7.69598 3.79306 7.7308V5.81853C3.79306 5.56302 3.9925 5.35482 4.23694 5.35482H15.7786C16.0232 5.35482 16.2225 5.56305 16.2225 5.81853V14.1641C16.2225 14.4196 16.0231 14.6278 15.7786 14.6278H10.3953C10.4295 14.9325 10.452 15.2409 10.452 15.5552C10.452 15.8694 10.4295 16.1776 10.3953 16.4823H15.7786C17.0056 16.4826 18 15.4437 18 14.1641V5.81853C18 4.53942 17.0056 3.5 15.7806 3.5ZM2.90528 14.6283C2.415 14.6283 2.0175 15.0436 2.0175 15.5558C2.0175 16.0679 2.415 16.4829 2.90528 16.4829C3.39556 16.4829 3.79306 16.0679 3.79306 15.5558C3.79306 15.0436 3.39556 14.6283 2.90528 14.6283ZM2.88778 11.8629C2.39722 11.8629 2 12.2779 2 12.7915C2 13.304 2.39722 13.7186 2.88778 13.7186C3.86667 13.7186 4.66361 14.5509 4.66361 15.5732C4.66389 16.085 5.06111 16.5 5.55278 16.5C6.04444 16.5 6.44056 16.0853 6.44056 15.5729C6.43889 13.5257 4.84722 11.8629 2.88778 11.8629ZM2.90528 9.06272C2.41444 9.06272 2.0175 9.47739 2.0175 9.98984C2.0175 10.5023 2.41472 10.917 2.90528 10.917C5.35306 10.917 7.34417 12.997 7.34417 15.554C7.34417 16.0665 7.74139 16.4811 8.23194 16.4811C8.72278 16.4811 9.12 16.0665 9.12 15.554C9.11944 11.9761 6.30833 9.06272 2.90528 9.06272Z"
      fill="url(#paint0_linear_472_20)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_472_20"
        x1="10"
        y1="3.5"
        x2="10"
        y2="16.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA61F2" />
        <stop offset="1" stopColor="#634BF3" />
      </linearGradient>
    </defs>
  </svg>
);
export default PurpleScreenShare;
