import styled from "styled-components";
import Column from "../../../../../components-ui/atoms/Column";
import Icon from "../../../../../components-ui/atoms/Icon";
import Space from "../../../../../components-ui/atoms/Space";
import Typo from "../../../../../components-ui/atoms/Typo";
import { useText } from "../../../../../core/i18n/i18nHooks";

type Props = {
  title?: string;
  players?: (JSX.Element | null)[];
  isAlone?: boolean;
  NoContentElement?: React.ReactNode;
  paddedNoContentBox?: boolean;
};

const NoContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
  justify-content: center;
  width: 100%;
`;

const PlayerGrid = styled.div`
  padding-top: 0px;
  display: grid;
  grid-gap: 21px 28px;
  grid-template-columns: repeat(3, 1fr);
`;

const PlayerListSection: React.FC<Props> = ({
  title,
  players,
  isAlone,
  NoContentElement,
}) => {
  const t = useText();

  return (
    <Column height={isAlone ? "100%" : undefined}>
      {title && (
        <>
          <Typo.Subtitle>{title}</Typo.Subtitle>
          <Space h={4} />
        </>
      )}
      {players && players.length > 0 ? (
        <PlayerGrid>{players}</PlayerGrid>
      ) : (
        <NoContent>
          {NoContentElement || (
            <>
              <Icon.Bust size="32px" />
              <Typo.Body>{t("players_nearby_empty")}</Typo.Body>
            </>
          )}
        </NoContent>
      )}
      <Space h={5} />
    </Column>
  );
};

export default PlayerListSection;
