import { SvgProps } from "../../types/svg";

const ArrowIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.19634 10.0002C2.64287 10.0002 2.1367 9.58483 2.02324 9.00789C1.90977 8.43096 2.22055 7.85402 2.76479 7.63094L16.3044 2.0916C16.7621 1.90391 17.289 2.01006 17.639 2.36045C17.989 2.71123 18.0967 3.23816 17.9082 3.69702L12.3696 17.235C12.1466 17.7811 11.5696 18.0888 10.9927 17.9773C10.4157 17.8619 9.96574 17.3581 9.96574 16.7696V10.0002H3.19634ZM11.2312 8.76943V16.7696L16.7697 3.23086L3.19634 8.76943H11.2312Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ArrowIcon;
