export const DataChannelStatus = {
  INIT: 0,
  // Currently, no need to setup this callback.
  // DATA_CHANNEL_CONNECTING,
  DATA_CHANNEL_CONNECTED: 1,
  DATA_CHANNEL_CLOSED: 2,
} as const;
export type DataChannelStatus =
  (typeof DataChannelStatus)[keyof typeof DataChannelStatus];

export const StreamingStatus = {
  INIT: 0,
  OFFERING: 1,
  PLAYING: 2,
  CLOSED: 3,
  ERROR: 4,
} as const;

export type StreamingStatus =
  (typeof StreamingStatus)[keyof typeof StreamingStatus];
