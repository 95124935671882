import { useReflow } from "../../../hooks/ui";
import ForceLandscapeUiPortrait from "./ForceLandscape.ui.portrait";

const ForceLandscapeUi: React.FC = (props) => {
  return useReflow({
    normal: null,
    portrait: <ForceLandscapeUiPortrait {...props} />,
    landscape: null,
  });
};

export default ForceLandscapeUi;
