import * as RTooltip from "@radix-ui/react-tooltip";
import { styled, useTheme } from "styled-components";
import Row from "../../../components-ui/atoms/Row";
import Typo from "../../../components-ui/atoms/Typo";
import ArrowButton from "../../../components-ui/molecules/ArrowButton";
import CloseButton from "../../../components-ui/molecules/CloseButton";
import Flash from "../components/Flash";
import {
  AbsoluteOverlay,
  CameraFrameBorders,
  TopLeftButton,
  TopRightButton,
} from "../components/Wrappers";
import { Props } from "./MediaShare.ui";
import ReadyHint from "./components/ReadyHint";
import ShareButton from "./components/ShareButton";

const ShareButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 70px;
`;

const MediaShareUiNormal: React.FC<Props> = ({
  shareButtons,
  preview,
  isLoading,
  warningMessage,
  errorMessage,
  infoMessage,
  onClose,
  onBack,
  previousPanel,
  hideTabs,
}) => {
  const theme = useTheme();
  const isPhotoShare = previousPanel === "photo";

  return (
    <AbsoluteOverlay>
      <CameraFrameBorders />
      {isPhotoShare && <Flash />}
      {Boolean(onBack) && (
        <TopLeftButton>
          <ArrowButton onClick={onBack} isGlass size="48px" />
        </TopLeftButton>
      )}
      <TopRightButton>
        <CloseButton onClick={onClose} isGlass size="48px" />
      </TopRightButton>
      <ReadyHint isPhotoShare={isPhotoShare} hideTabs={hideTabs} />
      {preview}
      {warningMessage && <Typo.Body>{warningMessage}</Typo.Body>}
      <ShareButtonWrapper>
        <RTooltip.Provider>
          <Row justify="center" gap={2}>
            {shareButtons?.map((it) => (
              <ShareButton key={it.name} {...it} loading={isLoading} />
            ))}
          </Row>
        </RTooltip.Provider>
      </ShareButtonWrapper>
      {errorMessage && (
        <Typo.Body color={theme.colorDanger}>{errorMessage}</Typo.Body>
      )}
      {infoMessage && <Typo.Body>{infoMessage}</Typo.Body>}
    </AbsoluteOverlay>
  );
};

export default MediaShareUiNormal;
