import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Hide from "../../../components-ui/atoms/Hide";
import Icon from "../../../components-ui/atoms/Icon";
import Row from "../../../components-ui/atoms/Row";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import { Ring } from "../../../components-ui/molecules/StartButton";
import { useText } from "../../../core/i18n/i18nHooks";
import { steps, zIndex } from "../../../style/theme";
import { Props } from "./ScreenSharing.ui";
import { Loader } from "./component/Common";
import useVideoStream from "./hooks/useVideoStream";

const MAX_WIDTH = "320px";
const MAX_HEIGHT = "200px";

const Container = styled.div`
  position: absolute;

  top: ${steps.spacing[5]};
  right: ${steps.spacing[5]};
  bottom: ${steps.spacing[5]};
  left: ${steps.spacing[5]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  * {
    pointer-events: all;
  }
`;

const ScreenShareContainer = styled.div`
  background: transparent;
  position: relative;
  object-fit: contain;
  padding: 0px;
  min-height: 0px;
  min-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PresenterName = styled(Row)`
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: ${zIndex.overlays};
`;

const ScreenShare = styled.video`
  border-radius: ${steps.borderRadius.b20};
  max-height: ${MAX_HEIGHT};
  max-width: ${MAX_WIDTH};
`;

const PanelContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-height: ${MAX_HEIGHT};
  max-width: ${MAX_WIDTH};
`;

const FullscreenOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border-radius: ${steps.borderRadius.b20};
  background: rgba(0, 0, 0, 0.4);
  z-index: ${zIndex.overlays};
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
  height: 100%;
  width: 100%;
`;

const PlaceholderVideo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: ${(p) => p.theme.colorBelow0};
  border-radius: ${steps.borderRadius.b20};
`;

const ScreenSharingUiPreview: React.FC<Props> = ({
  activeScreenShare,
  screenSharerName,
  stopScreenShare,
  amIScreenSharer,
  roomId,
  onMaximizeScreenShare,
}) => {
  const t = useText();
  const theme = useTheme();
  const { videoRef } = useVideoStream(activeScreenShare);
  const [showButton, setShowButton] = useState(false);
  const hasVideoStream = Boolean(videoRef?.current?.srcObject);

  // Delay showing the button so the fade is smooth
  useEffect(() => {
    if (hasVideoStream) {
      setTimeout(() => {
        setShowButton(true);
      }, 600);
    }
  }, [hasVideoStream]);

  if (!roomId) {
    return (
      <>
        <Typo.Subtitle>{t("player_joining_room")}</Typo.Subtitle>
        <Typo.Note>{t("player_please_wait")}</Typo.Note>
        <Space h={5} />
        <Ring isSpinning strokeWidth="4" reverse size={100} />
      </>
    );
  }

  return (
    <Container>
      <PanelContainer>
        <ScreenShareContainer>
          {hasVideoStream && (
            <FullscreenOverlay onClick={onMaximizeScreenShare}>
              <Icon.Fullscreen inheritColor size="32px" />
              <PresenterName gap={2} justify="flex-start">
                <Icon.ScreenShare color={theme.colorBelow1} size="18px" />
                <Typo.Label color={theme.colorBelow1}>
                  {amIScreenSharer ? t("player_you") : screenSharerName}
                </Typo.Label>
              </PresenterName>
            </FullscreenOverlay>
          )}

          <Hide
            hide={Boolean(hasVideoStream)}
            speed={1000}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: MAX_HEIGHT,
              width: MAX_WIDTH,
            }}
          >
            <Loader $visible={!hasVideoStream}>
              <Ring
                isSpinning
                strokeWidth="4"
                reverse
                size={60}
                color={theme.colorAbove2}
              />
            </Loader>
            <PlaceholderVideo />
          </Hide>

          <Hide hide={!hasVideoStream} speed={1000}>
            <ScreenShare
              ref={videoRef}
              muted={amIScreenSharer}
              autoPlay
              playsInline
              id="screen-sharing-video"
            />
          </Hide>
        </ScreenShareContainer>
        <Space h={4} />
        <Row justify="center">
          {amIScreenSharer && (
            <Hide hide={!showButton} speed={800}>
              <Button.Bright
                onClick={stopScreenShare}
                iconLeft={<Icon.ScreenShare inheritColor size="18px" />}
              >
                {t("screen_sharing_stop")}
              </Button.Bright>
            </Hide>
          )}
        </Row>
      </PanelContainer>
    </Container>
  );
};

export default ScreenSharingUiPreview;
