export const INDICATOR_SIZE = {
  SMALL: 24,
  LARGE: 32,
  OFFSET: 3,
};

export const VIDEO_AVATAR_CIRCLE_SIZE = {
  LARGE: 136,
};

export const TITLE_HEIGHT = "30px";
