import { SvgProps } from "../../types/svg";

const ChampagneGlassIcon: React.FC<SvgProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_15_2607)">
      <path
        d="M6.5 15H4.5V10.9344C6.46594 10.6803 8 9 8 6.93125C8 5.83469 7.58531 2.86562 6.96812 0.19375C6.94062 0.0800625 6.84062 0 6.725 0H1.27531C1.15906 0 1.05813 0.0800625 1.03188 0.193375C0.414688 2.86531 0 5.83438 0 6.93125C0 8.99875 1.53406 10.6781 3.5 10.9344V15H1.5C1.22375 15 1 15.225 1 15.5C1 15.775 1.22375 16 1.5 16H6.5C6.77612 16 7 15.7761 7 15.5C7 15.225 6.775 15 6.5 15ZM6.09688 1C6.31741 2.01813 6.505 3.05875 6.65156 4H1.32156C1.46806 3.05844 1.65562 2.01813 1.87625 1H6.09688ZM1 6.93125C1 6.53438 1.06562 5.84062 1.17687 5H6.82375C6.93437 5.84062 7 6.53438 7 6.93125C7 8.54156 5.76063 9.87938 4.17844 9.97688C3.95938 9.99036 4.04416 9.99055 3.82188 9.97688C2.23938 9.88125 1 8.54062 1 6.93125Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
  </svg>
);
export default ChampagneGlassIcon;
