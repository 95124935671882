import { logWarn } from "../lib/logger";

/**
 * Abstract the dirty details of stalled handling in the context
 * of knowing if autoplay works or not.
 *
 * @param videoElement The video element to monitor
 * @param onError Called when there is a failure to play the video,
 *                or any <video> error event
 * @returns A cleanup function to stop monitoring.
 */
export const monitorAutoplayFailures = (
  videoElement: HTMLVideoElement,
  onError: (error: Error) => void,
  onAutoplaySuccess?: () => void
) => {
  let stalledForRealTimeoutId: number | undefined;
  let isPlaying = false;
  const stalledListener = () => {
    if (isPlaying) return;
    stalledForRealTimeoutId = window.setTimeout(() => {
      logWarn(
        "VIDEOPLAY",
        "Video load stalled: autoplay blocked / missing permissions / bad network."
      );
      onError(new Error("Video stalled"));
    }, 1000);
  };
  const playListener = () => {
    clearTimeout(stalledForRealTimeoutId);
    isPlaying = true;
    onAutoplaySuccess?.();
  };

  const waitingListener = () => {
    isPlaying = false;
    logWarn("VIDEOPLAY", "Video waiting event");
  };

  videoElement.addEventListener("waiting", waitingListener);
  videoElement.addEventListener("stalled", stalledListener);
  videoElement.addEventListener("playing", playListener);

  return {
    stop: () => {
      clearTimeout(stalledForRealTimeoutId);
      videoElement.removeEventListener("waiting", waitingListener);
      videoElement.removeEventListener("stalled", stalledListener);
      videoElement.removeEventListener("playing", playListener);
    },
  };
};
