import { styled, useTheme } from "styled-components";
import Column from "../../../components-ui/atoms/Column";
import Glass from "../../../components-ui/atoms/Glass";
import Typo from "../../../components-ui/atoms/Typo";
import { useText } from "../../../core/i18n/i18nHooks";

const PhoneLandscapeSvg: React.FC = () => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68 2H12C6.47716 2 2 6.47715 2 12V82.202C1.23765 82.98 0.565363 83.8466 2.97237e-06 84.7848V12C2.97237e-06 5.37258 5.37259 0 12 0H68C74.6274 0 80 5.37258 80 12V35H79.9996C79.3279 35 78.6611 35.0229 78 35.068V12C78 6.47715 73.5229 2 68 2ZM79.9996 41H80V78L78 78V41.0858C78.6595 41.029 79.3264 41 79.9996 41ZM2 148L2 92C2 86.4771 6.47715 82 12 82L148 82C153.523 82 158 86.4771 158 92V148C158 153.523 153.523 158 148 158H12C6.47715 158 2 153.523 2 148ZM12 160C5.37258 160 2.97237e-06 154.627 2.97237e-06 148L0 92C-2.89694e-07 85.3726 5.37258 80 12 80L148 80C154.627 80 160 85.3726 160 92V148C160 154.627 154.627 160 148 160H12ZM105 41C105 40.4492 105.449 40 106 40C106.55 40 107 40.4487 107 41V57C107 57.5508 106.55 58 106 58H88.9995C88.4488 58 87.9995 57.55 87.9995 57C87.9995 56.45 88.4495 56 88.9995 56H103.612C100.2 45.945 90.7245 39 79.9995 39C67.287 39 56.6133 48.5125 55.1643 61.1133C55.1057 61.625 54.6759 62 54.172 62C54.1601 62 54.1478 61.9877 54.1352 61.9751C54.1096 61.9495 54.0826 61.9224 54.0534 61.993C53.5027 61.9305 53.112 61.4344 53.1745 60.8875C54.737 47.27 66.2745 37 79.9995 37C91.0995 37 100.95 43.8862 105 54V41ZM154 113C154 112.448 154.448 112 155 112C155.552 112 156 112.448 156 113V127C156 127.552 155.552 128 155 128C154.448 128 154 127.552 154 127V113ZM32 5C32 5.55228 32.4477 6 33 6H47C47.5523 6 48 5.55228 48 5C48 4.44772 47.5523 4 47 4H33C32.4477 4 32 4.44772 32 5Z"
        fill="white"
        fillOpacity="0.75"
      />
    </svg>
  );
};

const RotatePhoneAnimation = styled.div`
  width: 250px;
  height: 210px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ForceLandscapeUiPortrait: React.FC = () => {
  const t = useText();
  const theme = useTheme();
  return (
    <Glass borderRadius="0px" height="100dvh" width="100%">
      <Column justify="center" align="center" height="100%">
        <RotatePhoneAnimation>
          <PhoneLandscapeSvg />
        </RotatePhoneAnimation>
        <Typo.Label color={theme.colorLightText}>
          {t("rotate_your_device")}
        </Typo.Label>
      </Column>
    </Glass>
  );
};

export default ForceLandscapeUiPortrait;
