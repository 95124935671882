import Transition from "../../../components-ui/atoms/Transition";
import { FullAbsoluteLayout } from "../../../core/layout/PanelsLayout";
import { useStore } from "../../../store/store";
import MediaShareLogic from "./MediaShare.logic";

const MediaSharePanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.mediaShare.visible);

  return (
    <FullAbsoluteLayout id="panel-mediaShare">
      <Transition watch={[visible]} height="100%">
        {visible && <MediaShareLogic />}
      </Transition>
    </FullAbsoluteLayout>
  );
};

export default MediaSharePanel;
