import { styled } from "styled-components";
import { BaseLayout } from "../../../core/layout/PanelsLayout";
import { useStore } from "../../../store/store";
import AttendeeBarLogic from "./attendeeBar/AttendeeBar.logic";
import ReactionsBar from "./component/ReactionsBar";
import PresenterBarLogic from "./presenterBar/PresenterBar.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const ReactionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -48px;
  height: 50px;
`;

const PresentationBarPanel: React.FC = () => {
  const isConferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const amIThePresenter = useStore((s) => s.videoConference.amIPresenter());
  const attendeeBarVisible = isConferenceInitialized && !amIThePresenter;
  const presenterBarVisible = isConferenceInitialized && amIThePresenter;
  const reactionsBarVisible = isConferenceInitialized;
  return (
    <Layout id="panel-presentationBar">
      <PresenterBarLogic isOpen={presenterBarVisible} />
      <AttendeeBarLogic isOpen={attendeeBarVisible} />
      <ReactionsWrapper>
        {reactionsBarVisible && <ReactionsBar />}
      </ReactionsWrapper>
    </Layout>
  );
};

export default PresentationBarPanel;
