import { useEffect } from "react";
import LoginVideoBackground from "../../../features/login/LoginVideoBackground";
import { useLogin } from "../../../features/login/loginHooks";
import EndingPanel from "../../../features/panels/ending/Ending.logic";
import { useStore } from "../../../store/store";
import { useEnvironmentContext } from "../../EnvironmentFetcher.core";
import ExperiencePage from "../../ExperiencePage";
import MapImageContextProvider from "../../MapImageContext";
import ReactionsProvider from "../../ReactionsContext";
import VideoPlayerContextProvider from "../../VideoPlayerContext";
import { useCopywriting } from "../../i18n/i18nHooks";
import { useIdentifyLogRocketUser } from "../../tracking/logrocket";
import VideoConferenceProvider from "../../videoConference/VideoConferenceProvider";
import Web3ContextProvider from "../../web3WalletConnect/web3Context";

const DevExperienceRoute: React.FC = () => {
  const __unsafelySetCurrentStep = useStore(
    (s) => s.userFlow.__unsafelySetCurrentStep
  );
  const setVisitorToken = useStore((s) => s.session.setVisitorToken);
  const setDevRoomAndPlayerId = useStore(
    (s) => s.gameConnection.setDevRoomAndPlayerId
  );
  const { environment } = useEnvironmentContext();

  useLogin(environment.id, setVisitorToken);
  setDevRoomAndPlayerId(environment.id, Math.floor(Math.random() * 99999) + 1);
  useCopywriting();
  useIdentifyLogRocketUser();

  useEffect(() => {
    if (environment) {
      // Directly go to experience.
      __unsafelySetCurrentStep("experience:ready");
    }
  }, [__unsafelySetCurrentStep, environment]);

  return (
    <>
      <LoginVideoBackground isDevRoute />
      <EndingPanel />
      <VideoPlayerContextProvider>
        <MapImageContextProvider>
          <Web3ContextProvider>
            <VideoConferenceProvider>
              <ReactionsProvider>
                <ExperiencePage />
              </ReactionsProvider>
            </VideoConferenceProvider>
          </Web3ContextProvider>
        </MapImageContextProvider>
      </VideoPlayerContextProvider>
    </>
  );
};

export default DevExperienceRoute;
