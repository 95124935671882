import { SvgProps } from "../../types/svg";

const GlobeIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.71207 12.2387C4.80789 12.1387 4.9628 12.1387 5.05862 12.2387C7.45016 14.7581 11.3258 14.7581 13.7168 12.2387C16.1077 9.71935 16.1077 5.63548 13.7168 3.11548C13.6219 3.01452 13.6219 2.85129 13.7168 2.75032L14.3566 2.07548C14.4546 1.97484 14.6076 1.97484 14.7056 2.07548C14.8005 2.17645 14.8005 2.33968 14.7056 2.44065L14.2341 2.93613C16.6434 5.66774 16.5883 9.94516 14.0627 12.6032C12.9606 13.7677 11.5616 14.4323 10.1227 14.6032V17.4839H14.5311C14.6658 17.4839 14.776 17.6 14.776 17.7419C14.776 17.8839 14.6658 18 14.5311 18H5.22455C5.08923 18 4.97964 17.8839 4.97964 17.7419C4.97964 17.6 5.08923 17.4839 5.22455 17.4839H9.60536V14.6419C7.93692 14.7065 6.21949 14.0871 4.88841 12.7839L4.41818 13.2806C4.32236 13.3806 4.16746 13.3806 4.07164 13.2806C3.97612 13.1774 3.97612 13.0161 4.07164 12.9129L4.71207 12.2387ZM14.2862 7.64839C14.2862 10.529 12.0943 12.8387 9.388 12.8387C6.68176 12.8387 4.48982 10.529 4.48982 7.64839C4.48982 4.82581 6.68176 2.4871 9.388 2.4871C12.0943 2.4871 14.2862 4.82581 14.2862 7.64839ZM9.388 3.00323C6.95422 3.00323 4.97964 5.1129 4.97964 7.64839C4.97964 10.2419 6.95422 12.3226 9.388 12.3226C11.8218 12.3226 13.7964 10.2419 13.7964 7.64839C13.7964 5.1129 11.8218 3.00323 9.388 3.00323Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default GlobeIcon;
