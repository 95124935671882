import DynamicButtonMenu from "../../components-ui/molecules/dynamicButtonMenu/DynamicButtonMenu";
import useDynamicButtonMenuMovements from "../../hooks/useDynamicButtonMenuMovements";

interface Props {
  focused?: boolean;
}

const MovementButton: React.FC<Props> = ({ focused }) => {
  const { movementsOptions, SelectedMovementIcon } =
    useDynamicButtonMenuMovements();

  return (
    <DynamicButtonMenu
      focused={focused}
      items={movementsOptions}
      closeOnSelect
      anchor={<SelectedMovementIcon inheritColor size="24px" />}
    />
  );
};

export default MovementButton;
