import styled from "styled-components";
import { zIndex } from "../../../../style/theme";

export const Loader = styled.div<{ $visible: boolean }>`
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.overlays};
`;
