import { Fragment } from "react";
import { styled } from "styled-components";
import Column from "../../../../../../components-ui/atoms/Column";
import Row from "../../../../../../components-ui/atoms/Row";
import Space from "../../../../../../components-ui/atoms/Space";
import Typo from "../../../../../../components-ui/atoms/Typo";
import { PanelTestIds } from "../../../../../../constants/testIds";
import { useControls } from "../controlHooks";

const ScreenPlaceholder = styled.div<{ image?: string }>`
  height: 207px;
  background-color: ${(p) => p.theme.colorAbove0};
  border: 1px solid ${(p) => p.theme.colorAbove1};
  border-radius: ${(p) => p.theme.radiusTiny};
  ${(p) =>
    p.image &&
    `background-image: url(${p.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `}
  position: relative;
  width: 100%;
`;

const ButtonsLine = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  justify-content: space-between;
`;

const ButtonsSchema: React.FC = () => {
  const { leftButtons, rightButtons, image } = useControls();

  return (
    <Column
      width="auto"
      style={{ flex: 1 }}
      testId={PanelTestIds.controls.default.common.buttonsSchema}
    >
      <ScreenPlaceholder image={image}>
        <ButtonsLine>
          <Row
            gap={4}
            justify="space-between"
            width="auto"
            key={"buttons-group-left"}
          >
            {leftButtons.map((button) => button.icon)}
          </Row>
          <Row
            gap={4}
            justify="space-between"
            width="auto"
            key={"buttons-group-right"}
          >
            {rightButtons.map((button) => button.icon)}
          </Row>
        </ButtonsLine>
      </ScreenPlaceholder>
      <Space h={2} />
      <Row justify="space-between">
        <Row
          gap={1}
          justify="space-between"
          width="auto"
          key="button-group-left-description"
        >
          {leftButtons.map((button, buttonIndex) => (
            <Fragment key={button.label}>
              {buttonIndex !== 0 ? <Typo.Body>&bull;</Typo.Body> : null}
              <Typo.Body>{button.label}</Typo.Body>
            </Fragment>
          ))}
        </Row>
        <Row
          gap={1}
          justify="space-between"
          width="auto"
          key="button-group-right-description"
        >
          {rightButtons.map((button, buttonIndex) => (
            <Fragment key={button.label}>
              {buttonIndex !== 0 ? <Typo.Body>&bull;</Typo.Body> : null}
              <Typo.Body>{button.label}</Typo.Body>
            </Fragment>
          ))}
        </Row>
      </Row>
    </Column>
  );
};

export default ButtonsSchema;
