import { SvgProps } from "../../types/svg";

const CameraIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 11C6.5 9.06563 8.06563 7.5 10 7.5C11.9344 7.5 13.5 9.06563 13.5 11C13.5 12.9344 11.9344 14.5 10 14.5C8.06563 14.5 6.5 12.9344 6.5 11ZM10 8.5C8.61875 8.5 7.5 9.61875 7.5 11C7.5 12.3813 8.61875 13.5 10 13.5C11.3813 13.5 12.5 12.3813 12.5 11C12.5 9.61875 11.3813 8.5 10 8.5ZM13.3406 4.02562L13.6656 5H16C17.1031 5 18 5.89687 18 7V15C18 16.1031 17.1031 17 16 17H4C2.89531 17 2 16.1031 2 15V7C2 5.89687 2.89531 5 4 5H6.33437L6.65938 4.02562C6.8625 3.41312 7.43438 3 8.08125 3H11.9187C12.5656 3 13.1375 3.41312 13.3406 4.02562ZM4 6C3.44781 6 3 6.44688 3 7V15C3 15.5531 3.44781 16 4 16H16C16.5531 16 17 15.5531 17 15V7C17 6.44688 16.5531 6 16 6H12.9469L12.3938 4.34188C12.325 4.13781 12.1344 4 11.9187 4H8.08125C7.86563 4 7.675 4.13781 7.60625 4.34188L7.05312 6H4Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CameraIcon;
