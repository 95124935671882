import { SvgProps } from "../../types/svg";

const VideoOffIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3127 8.35938L17.2048 6.25001V13.5303L17.997 14.1815C18.0629 14.0518 18.1051 13.9053 18.1051 13.7236V6.25001C18.1051 5.89903 17.9196 5.58028 17.6203 5.41827C17.3224 5.2576 16.9629 5.27879 16.6877 5.47973L13.7956 7.59028C13.5916 7.73858 13.5424 8.0312 13.6848 8.24301C13.8035 8.45606 14.1073 8.50586 14.3127 8.35938ZM10.9029 5.31251C11.3992 5.31251 11.8032 5.73321 11.8032 6.25001V9.09092L12.7034 9.83096V6.25001C12.7034 5.21436 11.8974 4.37501 10.9029 4.37501H6.06675L7.207 5.31251H10.9029ZM18.8337 16.6621L1.72913 2.6002C1.53394 2.44045 1.25086 2.47569 1.09668 2.67758C0.942822 2.88084 0.976245 3.17562 1.17098 3.33617L18.2761 17.3987C18.3583 17.4678 18.4567 17.5 18.5299 17.5C18.6631 17.5 18.795 17.4387 18.8838 17.3219C19.0616 17.0928 19.0278 16.8232 18.8337 16.6621ZM10.9029 14.6875H3.70072C3.20445 14.6875 2.80045 14.2668 2.80045 13.75V6.4668L1.96432 5.75489C1.92662 5.93067 1.90018 6.08594 1.90018 6.25001V13.75C1.90018 14.7856 2.7062 15.625 3.70072 15.625H10.9029C11.6451 15.625 12.2814 15.1568 12.5571 14.4892L11.7832 13.8528C11.7328 14.3184 11.3643 14.6875 10.9029 14.6875Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default VideoOffIcon;
