import { GyaradosSDK } from "@journee-live/gyarados";
import { useReflow } from "../../../hooks/ui";
import { VisitorTokenData } from "../../login/loginUtils";
import PollPanelSmallLandscapeUi from "./Poll.ui.landscape";
import PollUiNormal from "./Poll.ui.normal";
import PollPanelSmallPortraitUi from "./Poll.ui.portrait";

export type Props = {
  handleDismiss: () => void;
  testId?: string;
  poll: GyaradosSDK.PollResponseDto | undefined;
  visitorToken: string | null;
  visitorTokenData: VisitorTokenData | null;
  roomId: string | null;
  playerId: number | null;
  clientPersistedId: string | null;
};

const PollUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <PollUiNormal {...props} />,
    landscape: <PollPanelSmallLandscapeUi {...props} />,
    portrait: <PollPanelSmallPortraitUi {...props} />,
  });
};

export default PollUi;
