import { useEffect } from "react";

/**
 * Adds the event Listeners to the videoPlayer Element
 *
 * @param videoPlayer
 */
const useVideoPlayerListeners = (
  VideoPlayer: HTMLVideoElement,
  onWait: () => void,
  onLoadStart: () => void,
  onPlaying: () => void,
  onLoadedData: () => void,
  onVideoPlayerError: () => void,
  onEnded: () => void,
  onTimeUpdate: () => void,
  onCanPlay: () => void
) => {
  useEffect(() => {
    VideoPlayer.addEventListener("ended", onEnded);
    return () => {
      VideoPlayer.removeEventListener("ended", onEnded);
    };
  }, [onEnded, VideoPlayer]);

  useEffect(() => {
    VideoPlayer.addEventListener("timeupdate", onTimeUpdate);
    return () => {
      VideoPlayer.removeEventListener("timeupdate", onTimeUpdate);
    };
  }, [VideoPlayer, onTimeUpdate]);

  useEffect(() => {
    VideoPlayer.addEventListener("error", onVideoPlayerError);
    return () => {
      VideoPlayer.removeEventListener("error", onVideoPlayerError);
    };
  }, [onVideoPlayerError, VideoPlayer]);

  useEffect(() => {
    VideoPlayer.addEventListener("waiting", onWait);
    return () => {
      VideoPlayer.removeEventListener("waiting", onWait);
    };
  }, [onWait, VideoPlayer]);

  useEffect(() => {
    VideoPlayer.addEventListener("loadstart", onLoadStart);
    return () => {
      VideoPlayer.removeEventListener("loadstart", onLoadStart);
    };
  }, [onLoadStart, VideoPlayer]);

  useEffect(() => {
    VideoPlayer.addEventListener("playing", onPlaying);
    return () => {
      VideoPlayer.removeEventListener("playing", onPlaying);
    };
  }, [onPlaying, VideoPlayer]);

  useEffect(() => {
    VideoPlayer.addEventListener("loadeddata", onLoadedData);
    return () => {
      VideoPlayer.removeEventListener("loadeddata", onLoadedData);
    };
  }, [onLoadedData, VideoPlayer]);

  useEffect(() => {
    VideoPlayer.addEventListener("canplay", onCanPlay);
    return () => {
      VideoPlayer.removeEventListener("canplay", onCanPlay);
    };
  }, [onCanPlay, VideoPlayer]);
};

export default useVideoPlayerListeners;
