import styled, { css, useTheme } from "styled-components";
import { steps } from "../../style/theme";
import Row from "./Row";
import Typo from "./Typo";

const Wrapper = styled.div<{
  $backgroundColor?: string;
  $blur?: "none" | "default" | "strong";
}>`
  background-color: ${(p) => p.$backgroundColor || p.theme.colorBelow0};
  padding: 4px 8px;
  border-radius: ${(p) => p.theme.radiusSmall};
  ${(p) => {
    if (p.$blur !== "none") {
      const blur =
        p.$blur === "strong" ? p.theme.blurStrong : p.theme.blurLight;
      return css`
        backdrop-filter: blur(${blur});
      `;
    }
  }}
`;

type Props = {
  text: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  textColor?: string;
  backgroundColor?: string;
  blur?: "none" | "default" | "strong";
};

const Tag: React.FC<Props> = ({
  text,
  iconLeft,
  iconRight,
  textColor,
  backgroundColor,
  blur = "strong",
}) => {
  const theme = useTheme();

  return (
    <Wrapper $backgroundColor={backgroundColor} $blur={blur}>
      <Row gap={1}>
        {iconLeft}
        <Typo.Body
          size={steps.font.f10.size}
          color={textColor || theme.colorAbove4}
        >
          {text}
        </Typo.Body>
        {iconRight}
      </Row>
    </Wrapper>
  );
};

export default Tag;
