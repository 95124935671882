import { useReflow } from "../../../../hooks/ui";
import PresenterBarUiNormal from "./PresenterBar.ui.normal";

type Props = {
  isOpen: boolean;
  micMuted: boolean;
  isVideoConferenceInitialized: boolean;
  toggleLocalAudio: () => void;
  webcamMuted: boolean;
  toggleVideo: () => void;
  activeScreenShare: boolean;
  toggleScreenShare: () => void;
  stopPresenting: () => void;
};

const PresenterBarUi: React.FC<Props> = ({
  isOpen,
  micMuted,
  isVideoConferenceInitialized,
  toggleLocalAudio,
  webcamMuted,
  toggleVideo,
  activeScreenShare,
  toggleScreenShare,
  stopPresenting,
}) => {
  return useReflow({
    normal: (
      <PresenterBarUiNormal
        isOpen={isOpen}
        micMuted={micMuted}
        isVideoConferenceInitialized={isVideoConferenceInitialized}
        toggleLocalAudio={toggleLocalAudio}
        webcamMuted={webcamMuted}
        toggleVideo={toggleVideo}
        activeScreenShare={Boolean(activeScreenShare)}
        toggleScreenShare={toggleScreenShare}
        stopPresenting={stopPresenting}
      />
    ),
    portrait: (
      <PresenterBarUiNormal
        isOpen={isOpen}
        micMuted={micMuted}
        isVideoConferenceInitialized={isVideoConferenceInitialized}
        toggleLocalAudio={toggleLocalAudio}
        webcamMuted={webcamMuted}
        toggleVideo={toggleVideo}
        activeScreenShare={Boolean(activeScreenShare)}
        toggleScreenShare={toggleScreenShare}
        stopPresenting={stopPresenting}
      />
    ),
    landscape: (
      <PresenterBarUiNormal
        isOpen={isOpen}
        micMuted={micMuted}
        isVideoConferenceInitialized={isVideoConferenceInitialized}
        toggleLocalAudio={toggleLocalAudio}
        webcamMuted={webcamMuted}
        toggleVideo={toggleVideo}
        activeScreenShare={Boolean(activeScreenShare)}
        toggleScreenShare={toggleScreenShare}
        stopPresenting={stopPresenting}
      />
    ),
  });
};

export default PresenterBarUi;
