import styled from "styled-components";
import { getImageUrl } from "../../../api/gyaradosUtils";
import CardContentAlignment from "../../../components-ui/atoms/CardContentAlignment";
import CardImage from "../../../components-ui/atoms/CardImage";
import Column from "../../../components-ui/atoms/Column";
import Markdown from "../../../components-ui/atoms/Markdown";
import Row from "../../../components-ui/atoms/Row";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import CardFullLandscape from "../../../components-ui/molecules/CardFullLandscape";
import { PanelTestIds } from "../../../constants/testIds";
import { steps } from "../../../style/theme";
import { Props } from "./InfoCard.ui";
import InfoCardActions, {
  getActionBooleans,
} from "./component/InfoCardActions";

const Content = styled.div`
  padding-right: max(var(--safe-margin-right), 64px);
  padding-left: 32px;
  padding-bottom: max(var(--safe-margin-bottom), 0px);
`;

const InfoCardUiLandscape: React.FC<Props> = ({
  data,
  onButtonClick,
  onSecondaryButtonClick,
  handleDismiss,
  testId,
}) => {
  const { image, title, description } = data;
  const { hasActions } = getActionBooleans(data);

  return (
    <CardFullLandscape
      testId={testId}
      topSection={
        <CardImage
          testId={
            image
              ? PanelTestIds.infoCard.image
              : PanelTestIds.infoCard.iconLandscape
          }
          height="100%"
          width="100%"
          src={getImageUrl(image, 1000, 1000)}
          styleOverrides={{
            borderTopLeftRadius: steps.borderRadius.b40,
            borderBottomLeftRadius: steps.borderRadius.b40,
          }}
          orientation="landscape"
        />
      }
      onClose={handleDismiss}
    >
      <Content>
        <Space h={9} />
        <CardContentAlignment>
          <Column gap={3} justify="center" height="100%">
            {title && (
              <Typo.Title testId={PanelTestIds.infoCard.title}>
                {title}
              </Typo.Title>
            )}
            {description && <Markdown content={description} textAlign="left" />}
            <Space h={0} />
            {hasActions && (
              <Row width="100%" align="center">
                <InfoCardActions
                  data={data}
                  onButtonClick={onButtonClick}
                  onSecondaryButtonClick={onSecondaryButtonClick}
                />
              </Row>
            )}
          </Column>
        </CardContentAlignment>
      </Content>
    </CardFullLandscape>
  );
};

export default InfoCardUiLandscape;
