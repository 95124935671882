import { SvgProps } from "../../types/svg";

const SadTearFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 2C14.4187 2 18 5.58125 18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10C2 5.58125 5.58125 2 10 2ZM10 13C11.0906 13 12.1 13.4937 12.8844 14.3406C13.0719 14.5437 13.3875 14.5531 13.5906 14.3656C13.7937 14.1781 13.8031 13.8625 13.6156 13.6594C12.675 12.6406 11.4062 12 10 12C9.725 12 9.5 12.225 9.5 12.5C9.5 12.775 9.725 13 10 13ZM8.5 13.5312C8.5 12.9062 7.6125 11.6437 7.2 11.1031C7.1 10.9656 6.9 10.9656 6.8 11.1031C6.39375 11.6437 5.5 12.9062 5.5 13.5312C5.5 14.3438 6.17188 15 7 15C7.82812 15 8.5 14.3438 8.5 13.5312ZM11.4875 8.5C11.4875 9.05313 11.9094 9.5 12.4875 9.5C13.0406 9.5 13.4875 9.05313 13.4875 8.5C13.4875 7.94687 13.0406 7.5 12.4875 7.5C11.9094 7.5 11.4875 7.94687 11.4875 8.5ZM8.4875 8.5C8.4875 7.94687 8.04063 7.5 7.4875 7.5C6.90938 7.5 6.4875 7.94687 6.4875 8.5C6.4875 9.05313 6.90938 9.5 7.4875 9.5C8.04063 9.5 8.4875 9.05313 8.4875 8.5Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default SadTearFilledIcon;
