export type VideoConferenceParticipant = {
  id: string;
  userId: string;
  name?: string;
  isLocal: boolean;
  isPublishing: boolean;
  isAudioOn: boolean;
  isSpeaking: boolean;
  isVideoOn: boolean;
  isVideoHidden: boolean;
  lastActive: number;
  streams: MediaStream[];
};

export const VideoConferenceState = {
  DISCONNECTED: "DISCONNECTED",
  DISCONNECTING: "DISCONNECTING",
  PERMISSION_SETUP: "PERMISSION_SETUP",
  BLOCKED_PERMISSIONS: "BLOCKED_PERMISSIONS",
  NO_DEVICES: "NO_DEVICES",
  SESSION_INITIALIZING: "SESSION_INITIALIZING",
  SESSION_INITIALIZED: "SESSION_INITIALIZED",
  DEVICE_SETUP: "DEVICE_SETUP",
  CONFERENCE_PREVIEW: "CONFERENCE_PREVIEW",
  JOINING: "JOINING",
  JOINED: "JOINED",
  ERROR: "ERROR",
} as const;

export type VideoConferenceStateType =
  (typeof VideoConferenceState)[keyof typeof VideoConferenceState];

export type ConferenceEvent =
  | "participantJoined"
  | "participantLeft"
  | "screenShareStarted"
  | "screenShareStopped";

interface VideoConferenceAdapter {
  status: (typeof VideoConferenceState)[keyof typeof VideoConferenceState];
  localParticipant: VideoConferenceParticipant | null;
  registerEventCallback(
    event: ConferenceEvent,
    callback: (participant: VideoConferenceParticipant) => void
  ): void;
  events: {
    screenShareStarted: (stream: MediaStream) => void;
    screenShareStopped: () => void;
    participantJoined: (participant: VideoConferenceParticipant) => void;
    participantLeft: (participant: VideoConferenceParticipant) => void;
  };
  initSession?: (params: {
    environmentId: string;
    visitorToken: string;
    userId: string;
  }) => Promise<void>;
  joinVideoConference: (params: {
    environmentId: string;
    visitorToken: string;
    userId: string;
    hasCameras: boolean;
    hasMicrophones: boolean;
    mediaPermissions: { camera: boolean; microphone: boolean };
    selectedCamera: MediaDeviceInfo | null;
    selectedMicrophone: MediaDeviceInfo | null;
  }) => Promise<void>;
  destroy: () => Promise<void> | void;
  selectCamera: (deviceId: string) => Promise<void> | void;
  selectMicrophone: (deviceId: string) => Promise<void> | void;
  selectSpeaker: (deviceId: string) => Promise<void> | void;
  getSelectedCamera: () => string | null;
  subscribeToParticipantVideo: (participantId: string, value: boolean) => void;
  getSelectedMicrophone: () => string | null;
  getSelectedSpeaker: () => string | null | Promise<string | null>;
  startVideo: () => Promise<void> | void;
  stopVideo: () => Promise<void> | void;
  stopLocalAudio: () => Promise<void> | void;
  startLocalAudio: () => Promise<void> | void;
  stopScreenShare: () => Promise<void> | void;
  startScreenShare: () => Promise<MediaStream> | MediaStream;
}
export default VideoConferenceAdapter;
