import { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from "react";
import { useTheme } from "styled-components";
import { multiplyAlpha } from "../../lib/color";
import Clickable, { PropsClickable } from "./Clickable";
import Typo from "./Typo";

const PADDING_LARGE = "25px 32px";
const PADDING_SMALL = "11px 16px";

const buttonTypeNames = [
  "Primary",
  "Secondary",
  "Tertiary",
  "Subtle",
  "Purple",
  "Bright",
  "Glass",
] as const;
type ButtonTypeName = (typeof buttonTypeNames)[number];

export type Props = {
  type?: ButtonTypeName;
  disabled?: boolean;
  silent?: boolean;
  opaque?: boolean;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  gap?: number;
  circular?: boolean;
  testId?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  large?: boolean;
  override?: PropsClickable;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

/** The main component to place text in the application. */
const BaseButton: React.FC<Props> = ({
  children,
  gap,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  type,
  iconLeft,
  iconRight,
  circular,
  silent,
  large,
  override,
  buttonType,
  testId,
}) => {
  const theme = useTheme();
  if (!type) return null;

  let config: PropsClickable;
  const content =
    typeof children === "string" ? (
      <Typo.Label color="inherit">{children}</Typo.Label>
    ) : (
      children
    );

  switch (type) {
    case "Glass":
      config = {
        color: theme.colorAbove3,
        backgroundColor: theme.colorBelow0,
        hoverBackground: multiplyAlpha(theme.colorBelow0, 2),
        borderRadius: theme.radiusFull,
        padding: large ? PADDING_LARGE : PADDING_SMALL,
        hoverColor: theme.colorAbove5,
        disabledOpacity: 1,
        disabledBackground: theme.colorAbove0,
        disabledColor: theme.colorAbove4,
        textTransform: "capitalize",
        css: {
          backdropFilter: `blur(${theme.blurLight})`,
        },
        transitionMs: 300,
        excite: {
          hoverResize: "1.06",
          clickResize: "1.01",
          clickSpeedMs: 120,
        },
      };
      if (circular) {
        config.width = "32px";
        config.height = "32px";
        config.padding = "0px";
      }
      break;
    case "Bright":
      config = {
        color: theme.colorAbove4,
        backgroundColor: theme.colorBelow1,
        borderRadius: theme.radiusFull,
        padding: large ? PADDING_LARGE : PADDING_SMALL,
        textTransform: "capitalize",
        border: "1px solid " + theme.colorBelow0,
        boxShadow: "0px 1px 2px 0px " + theme.colorShadow,
        boxShadowOpacity: 1,
        hoverBoxShadowOpacity: 0.7,
        css: {
          backdropFilter: `blur(${theme.blurLight})`,
        },
        excite: {
          hoverResize: "1.06",
          clickResize: "1.01",
          clickSpeedMs: 120,
        },
      };
      if (circular) {
        config.width = "32px";
        config.height = "32px";
        config.padding = "0px";
      }
      break;
    case "Subtle":
      config = {
        color: theme.colorAbove4,
        backgroundColor: "rgba(0, 0, 0, 0)",
        hoverBackground: theme.colorAbove0,
        textTransform: "capitalize",
        borderRadius: theme.radiusFull,
        padding: large ? PADDING_LARGE : PADDING_SMALL,
        transitionMs: 400,
        excite: {
          clickResize: "0.97",
          clickSpeedMs: 100,
        },
      };
      if (circular) {
        config.width = "32px";
        config.height = "32px";
        config.padding = "0px";
      }
      break;
    case "Tertiary":
      config = {
        color: theme.colorAbove3,
        backgroundColor: theme.colorAbove0,
        borderRadius: theme.radiusFull,
        padding: large ? PADDING_LARGE : PADDING_SMALL,
        hoverColor: theme.colorAbove5,
        hoverBackground: multiplyAlpha(theme.colorAbove0, 2),
        textTransform: "capitalize",
        excite: {
          clickResize: "0.97",
          clickSpeedMs: 100,
        },
      };
      if (circular) {
        config.width = "32px";
        config.height = "32px";
        config.padding = "0px";
      }
      break;
    case "Secondary":
      config = {
        color: theme.colorAbove4,
        backgroundColor: theme.colorAbove0,
        borderRadius: theme.radiusFull,
        padding: large ? PADDING_LARGE : PADDING_SMALL,
        hoverColor: theme.colorAbove5,
        hoverBackground: multiplyAlpha(theme.colorAbove0, 2),
        textTransform: "capitalize",
        excite: {
          clickResize: "0.97",
          clickSpeedMs: 100,
        },
      };
      if (circular) {
        config.width = "32px";
        config.height = "32px";
        config.padding = "0px";
      }
      break;
    case "Purple":
      config = {
        textTransform: "capitalize",
        color: silent ? theme.colorAbove4 : theme.colorBelow1,
        width: "48px",
        height: "48px",
        backdropFilter: `blur(${blur})`,
        background: silent
          ? theme.colorAbove0
          : theme.colorGradient.purpleBlue.value,
        borderRadius: theme.radiusFull,
        border: "none",
        // Purple button is large by default. Expicitly set large to false to make it small.
        padding: large === false ? PADDING_SMALL : PADDING_LARGE,
        transitionMs: 300,
        excite: {
          hoverResize: "1.1",
          clickResize: "1.05",
          clickSpeedMs: 100,
        },
        disabledBackground: theme.colorAbove0,
      };
      if (circular) {
        config.width = "52px";
        config.height = "52px";
        config.padding = "0px";
      }
      break;
    case "Primary":
    default:
      config = {
        textTransform: "capitalize",
        color: theme.colorAboveBrand,
        backgroundColor: theme.colorBelowBrand,
        borderRadius: theme.radiusFull,
        // Primary button is large by default. Expicitly set large to false to make it small.
        padding: large === false ? PADDING_SMALL : PADDING_LARGE,
        transitionMs: 400,
        excite: {
          hoverResize: "1.1",
          clickResize: "1.05",
          clickSpeedMs: 100,
        },
        disabledBackground: theme.colorAbove0,
        disabledColor: theme.colorAbove3,
        hoverOpacity: 0.8,
        // Added !important to override global.css focus css
        // TODO maybe only black always?
        boxShadow: "-12px 10px 20px 0px " + theme.colorShadow,
        boxShadowOpacity: 0.3,
        hoverBoxShadowOpacity: 0.5,
      };
      if (circular) {
        config.width = "52px";
        config.height = "52px";
        config.padding = "0px";
      }
  }

  config.disabled = disabled;
  config.gap = gap ?? 2;

  // As Excite wraps our button, to properly override our button width we need to target the width of the Excite wrapper as well.
  if (override?.width) {
    if (!config.excite) config.excite = {};
    config.excite.width = override.width;
  }

  config = { ...config, ...override };

  return (
    <Clickable
      buttonType={buttonType}
      {...config}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      testId={testId}
    >
      {iconLeft}
      {content}
      {iconRight}
    </Clickable>
  );
};

// Below is a cheeky way to give the Button component this sexy API: <Button.Prmary />

const buttonArray = buttonTypeNames.map((name) => {
  const specificButton: React.FC<Props> = (props) => (
    <BaseButton {...props} type={name} />
  );
  return [name, specificButton];
});

const Button = Object.fromEntries(buttonArray) as Record<
  ButtonTypeName,
  typeof BaseButton
>;

export default Button;
