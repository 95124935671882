import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createRoot } from "react-dom/client";
import { useTheme } from "styled-components";
import { BaseIcon, IconName } from "../components-ui/atoms/Icon";
import useReactionsGlow, {
  GlowData,
  SetGlowData,
} from "../features/actionBarPanel/hooks/useReactionsGlow";
import useReactionsStartPosition from "../features/actionBarPanel/hooks/useReactionsStartPosition";
import { Reactions } from "../features/actionBarPanel/lib/ReactionsConstants";
import { ReactionName } from "../features/actionBarPanel/lib/ReactionsTypes";
import FlyingEmoji, {
  FLYING_DURATION,
} from "../features/panels/presentationBar/component/FlyingEmoji";
import { ReactionItem } from "../features/panels/presentationBar/component/ReactionsBar";
import { useWindowSize } from "../hooks/ui";
import { useIsWindowFocused } from "../hooks/useWindowFocus";
import { useStore } from "../store/store";
import { sendGameMessage } from "./gameConnection/webrtc/webRtcMessageHandlers";

type ReactionContextState = {
  ReactionButtons: ReactionItem[];
  handleReaction: (reaction: ReactionName, stage: number) => void;
  glowData: GlowData;
  setGlowData: SetGlowData;
};

const ReactionsContext = createContext<ReactionContextState>({
  ReactionButtons: [],
  handleReaction: () => {},
  glowData: {} as GlowData,
  setGlowData: {} as SetGlowData,
});

export const useReactionsContext = () => {
  const context = useContext(ReactionsContext);
  if (!context) {
    throw new Error(
      "useReactionsContext must be used within a ReactionsProvider"
    );
  }
  return context;
};

// After 10 seconds the reaction expires
const REACTION_EXPIRATION_TIME = 10;

type Props = {
  children: React.ReactNode;
};

const ReactionsProvider: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const { glowData, setGlowData } = useReactionsGlow();
  const { firstName } = useStore((s) => s.profile.profileData);
  const playerId = useStore((s) => s.gameConnection.playerId);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const ReactionButtons: ReactionItem[] = useMemo(
    () => [
      {
        value: "surprised",
        tooltip: "Surprised",
        icons: {
          anchor: <BaseIcon type={Reactions.surprised.emojis[0]} size="22px" />,
          stage1: (
            <BaseIcon
              type={Reactions.surprised.emojis[0]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage2: (
            <BaseIcon
              type={Reactions.surprised.emojis[1]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage3: (
            <BaseIcon
              type={Reactions.surprised.emojis[2]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
        },
      },
      {
        value: "sad",
        tooltip: "Sad",
        icons: {
          anchor: <BaseIcon type={Reactions.sad.emojis[0]} size="22px" />,
          stage1: (
            <BaseIcon
              type={Reactions.sad.emojis[0]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage2: (
            <BaseIcon
              type={Reactions.sad.emojis[1]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage3: (
            <BaseIcon
              type={Reactions.sad.emojis[2]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
        },
      },
      {
        value: "laughing",
        tooltip: "Laughing",
        icons: {
          anchor: <BaseIcon type={Reactions.laughing.emojis[0]} size="22px" />,
          stage1: (
            <BaseIcon
              type={Reactions.laughing.emojis[0]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage2: (
            <BaseIcon
              type={Reactions.laughing.emojis[1]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage3: (
            <BaseIcon
              type={Reactions.laughing.emojis[2]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
        },
      },
      {
        value: "celebrate",
        tooltip: "Celebrate",
        icons: {
          anchor: <BaseIcon type={Reactions.celebrate.emojis[0]} size="22px" />,
          stage1: (
            <BaseIcon
              type={Reactions.celebrate.emojis[0]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage2: (
            <BaseIcon
              type={Reactions.celebrate.emojis[1]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage3: (
            <BaseIcon
              type={Reactions.celebrate.emojis[2]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
        },
      },
      {
        tooltip: "Love",
        value: "love",
        icons: {
          anchor: <BaseIcon type={Reactions.love.emojis[0]} size="22px" />,
          stage1: (
            <BaseIcon
              type={Reactions.love.emojis[0]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage2: (
            <BaseIcon
              type={Reactions.love.emojis[1]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
          stage3: (
            <BaseIcon
              type={Reactions.love.emojis[2]}
              color={theme.colorBelow2}
              size="22px"
            />
          ),
        },
      },
      {
        value: "thumbsUp",
        tooltip: "Thumbs Up",
        icons: {
          anchor: <BaseIcon type={Reactions.thumbsUp.emojis[0]} size="24px" />,
          stage1: (
            <BaseIcon
              type={Reactions.thumbsUp.emojis[0]}
              color={theme.colorBelow2}
              size="24px"
            />
          ),
          stage2: (
            <BaseIcon
              type={Reactions.thumbsUp.emojis[1]}
              color={theme.colorBelow2}
              size="24px"
            />
          ),
          stage3: (
            <BaseIcon
              type={Reactions.thumbsUp.emojis[2]}
              color={theme.colorBelow2}
              size="24px"
            />
          ),
        },
      },
    ],
    [theme.colorBelow2]
  );

  const { width, height } = useWindowSize();
  const startPosition = useReactionsStartPosition();
  const getPlayerByPlayerKey = useStore((s) => s.gameConnection.getPlayerByKey);
  const { avatarColor } = useStore((s) => s.profile.profileData);
  const currentReactions = useStore((s) => s.social.currentReactions);
  const shiftCurrentReaction = useStore((s) => s.social.shiftReactions);
  const allPlayers = useStore((s) => s.gameConnection.allPlayers);
  const [isFlushing, setIsFlushing] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isWindowFocused = useIsWindowFocused();
  const id = useRef<number | null>(null);

  useEffect(() => {
    if (id.current) return;
    id.current = window.setInterval(() => {
      setIsOpen(true);
    }, 1500);

    return () => {
      if (id.current) {
        window.clearInterval(id.current);
        id.current = null;
      }
    };
  }, []);

  const showBigEmoji = useCallback(
    (icon: IconName, name: string, index: number) => {
      const emoji = document.createElement("div");
      const root = createRoot(emoji);
      const bigEmojiWidth = width / 6;

      root.render(
        <FlyingEmoji
          emoji={icon}
          name={name}
          startX={bigEmojiWidth * index + width / 40}
        />
      );
      document.getElementById("emojis-container")?.appendChild(emoji);

      setTimeout(() => {
        root.unmount();
        document.getElementById("emojis-container")?.removeChild(emoji);
      }, FLYING_DURATION);
    },
    [width]
  );

  useEffect(() => {
    if (!isWindowFocused) return;
    if (!isOpen) return;
    if (isFlushing) return;
    setIsFlushing(true);

    if (currentReactions.length > 0) {
      currentReactions.forEach((currentReaction, i) => {
        if (Reactions[currentReaction.reaction as ReactionName]) {
          const player = getPlayerByPlayerKey(
            currentReaction.playerId,
            currentReaction.roomId
          );
          const name = player?.name ?? "Unknown";
          const timeout = Math.floor(Math.random() * 100) + 300 * i;
          const now = Date.now();
          if (
            // Milliseconds to seconds conversion
            Math.floor((now - currentReaction.timestamp) / 1000) <
            REACTION_EXPIRATION_TIME
          ) {
            window.requestAnimationFrame(() => {
              setTimeout(() => {
                const { reaction, stage } = currentReaction;
                const reactionData = Reactions[reaction as ReactionName];

                if (reactionData && stage !== undefined) {
                  const { emojis, position } = reactionData;
                  showBigEmoji(emojis[stage], name, position);
                  setGlowData[reaction as ReactionName]({
                    hide: false,
                    opacity: 1,
                    stage,
                  });
                }
              }, timeout);
            });
          }
          shiftCurrentReaction();
        }
      });
    }
    setIsFlushing(false);
    setIsOpen(false);
  }, [
    isWindowFocused,
    isOpen,
    setIsOpen,
    currentReactions,
    height,
    width,
    theme.radiusSmall,
    allPlayers,
    avatarColor,
    startPosition,
    isFlushing,
    shiftCurrentReaction,
    showBigEmoji,
    setGlowData,
    getPlayerByPlayerKey,
  ]);

  const handleReaction = useCallback(
    (reaction: ReactionName, stage: number) => {
      sendGameMessage({
        type: "Reaction",
        reaction,
        stage,
        playerId: playerId ?? 0,
        roomId: roomId ?? "",
        broadcast: true,
      });
      window.analytics?.track("reaction", {
        type: "reaction",
        name: reaction,
      });

      const reactionData = Reactions[reaction];
      if (reactionData) {
        const { emojis, position } = reactionData;
        showBigEmoji(emojis[stage], firstName, position);
      }
      setGlowData[reaction]({
        hide: false,
        opacity: 1,
        stage,
      });
    },
    [firstName, playerId, roomId, setGlowData, showBigEmoji]
  );

  return (
    <ReactionsContext.Provider
      value={useMemo(
        () => ({
          ReactionButtons: ReactionButtons,
          handleReaction,
          glowData,
          setGlowData,
        }),
        [ReactionButtons, handleReaction, glowData, setGlowData]
      )}
    >
      {children}
    </ReactionsContext.Provider>
  );
};

export default ReactionsProvider;
