import styled, { useTheme } from "styled-components";
import Button from "../../../../../../components-ui/atoms/Button";
import HeaderBadge from "../../../../../../components-ui/atoms/HeaderBadge";
import Icon from "../../../../../../components-ui/atoms/Icon";
import Row from "../../../../../../components-ui/atoms/Row";
import Typo from "../../../../../../components-ui/atoms/Typo";
import { GameColor } from "../../../../../../core/gameConnection/messages/sharedDataTypes";
import { useText } from "../../../../../../core/i18n/i18nHooks";
import { VideoConferenceParticipant } from "../../../../../../core/videoConference/VideoConferenceAdapter";
import {
  cssToUnrealColor,
  isColorBright,
  rgbaToHex,
} from "../../../../../../lib/color";
import { SpeakingIndicatorWrapper } from "../../../../videoAvatars/avatarCircle/LocalVideoAvatarCircle";
import SpeakingWidget from "../../../../videoAvatars/avatarCircle/SpeakingWidget";
import VideoAvatarCircle from "../../../../videoAvatars/avatarCircle/VideoAvatarCircle";
import { INDICATOR_SIZE } from "../../../../videoAvatars/lib/videoAvatarsConstants";
import { BackButtonPositioner } from "./FullPlayerProfile.ui.normal";
import TeleportDrawer from "./TeleportDrawer";

const NoNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 72px;
  height: 100%;
`;

const CircleAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: span 2;
  padding: 0 24px;
`;

type Props = {
  playerName: string;
  isStillConnected: boolean;
  avatarColor: GameColor;
  playerKey: string;
  participant: VideoConferenceParticipant | null;
  onTeleport: () => void;
  onPrevious: () => void;
};
const EmptyPlayerProfileUiNormal: React.FC<Props> = ({
  playerName,
  avatarColor,
  playerKey,
  participant,
  isStillConnected,
  onPrevious,
  onTeleport,
}) => {
  const t = useText();
  const theme = useTheme();
  const textColor = isColorBright(rgbaToHex(avatarColor))
    ? theme.colorAbove4
    : theme.colorBelow2;
  const speakingIndicator = (
    <SpeakingIndicatorWrapper
      $size={INDICATOR_SIZE.LARGE}
      style={{
        bottom: "10px",
      }}
    >
      <SpeakingWidget
        micHidden
        size={INDICATOR_SIZE.LARGE}
        isSpeaking={participant?.isSpeaking || false}
        isMuted={!participant?.isAudioOn || false}
        toggleMute={() => {}}
      />
    </SpeakingIndicatorWrapper>
  );
  const CloseButton = isColorBright(rgbaToHex(avatarColor))
    ? Button.Tertiary
    : Button.Glass;

  const rippleWidth = isColorBright(rgbaToHex(avatarColor)) ? 1 : 3;
  const rippleColor = isColorBright(rgbaToHex(avatarColor))
    ? theme.colorAbove5
    : theme.colorBelow2;
  return (
    <ProfileContainer>
      <BackButtonPositioner>
        <CloseButton onClick={onPrevious} circular>
          <Icon.Chevron size="1rem" rotate="-90deg" shiftY="-0.1rem" />
        </CloseButton>
      </BackButtonPositioner>
      <CircleAndTitleWrapper>
        <Row height="200px" justify="center">
          <HeaderBadge
            noBackground
            noShadow
            rippleColor={rippleColor}
            rippleLineWidth={rippleWidth}
          >
            <VideoAvatarCircle
              speakingIndicatorPosition="front"
              isSharingScreen={false}
              hideTitle={false}
              playerKey={playerKey}
              playerName={playerName}
              avatarColor={cssToUnrealColor(theme.colorBelow2)}
              size={156}
              participant={participant}
              noBorder
              speakingIndicator={speakingIndicator}
            />
          </HeaderBadge>
        </Row>
        {!playerName ? (
          <NoNameWrapper>
            <Typo.Note title={t("player_entering")}>
              {t("player_entering")}
            </Typo.Note>
          </NoNameWrapper>
        ) : (
          <Typo.Title
            align="center"
            style={{
              wordBreak: "break-word",
            }}
            color={textColor}
          >
            {playerName}
          </Typo.Title>
        )}
      </CircleAndTitleWrapper>
      <TeleportDrawer
        disabled={!isStillConnected}
        onTeleport={onTeleport}
        color={textColor}
        style={{
          paddingLeft: "24px",
          marginBottom: "24px",
        }}
      />
    </ProfileContainer>
  );
};

export default EmptyPlayerProfileUiNormal;
