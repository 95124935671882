import styled from "styled-components";
import FireAnimated from "../../../../assets/svg/reactions/FireAnimated.svg";
import HeartAnimated from "../../../../assets/svg/reactions/HeartAnimated.svg";
import HeartStarsAnimated from "../../../../assets/svg/reactions/HeartStarsAnimated.svg";
import HeartsAnimated from "../../../../assets/svg/reactions/HeartsAnimated.svg";
import HundredPercentAnimated from "../../../../assets/svg/reactions/HundredPercentAnimated.svg";
import LaughingAnimated from "../../../../assets/svg/reactions/LaughingAnimated.svg";
import LaughingRollingAnimated from "../../../../assets/svg/reactions/LaughingRollingAnimated.svg";
import LaughingTearsAnimated from "../../../../assets/svg/reactions/LaughingTearsAnimated.svg";
import MindBlownAnimated from "../../../../assets/svg/reactions/MindBlownAnimated.svg";
import PartyPopperAnimated from "../../../../assets/svg/reactions/PartyPopperAnimated.svg";
import RocketAnimated from "../../../../assets/svg/reactions/RocketAnimated.svg";
import SadAnimated from "../../../../assets/svg/reactions/SadAnimated.svg";
import SadCryAnimated from "../../../../assets/svg/reactions/SadCryAnimated.svg";
import SadTearAnimated from "../../../../assets/svg/reactions/SadTearAnimated.svg";
import ShootingStarAnimated from "../../../../assets/svg/reactions/ShootingStarAnimated.svg";
import SurprisedAnimated from "../../../../assets/svg/reactions/SurprisedAnimated.svg";
import SurprisedHandsAnimated from "../../../../assets/svg/reactions/SurprisedHandsAnimated.svg";
import ThumbsUpAnimated from "../../../../assets/svg/reactions/ThumbsUpAnimated.svg";
import { IconName } from "../../../../components-ui/atoms/Icon";
import { randomInRange } from "../../../../lib/math";
import { zIndex } from "../../../../style/theme";
import FlyingEmojiTag from "./FlyingEmojiTag";

export const FLYING_DURATION = 3000;

const EMOJI_MAP = {
  ThumbsUpFilled: ThumbsUpAnimated,
  OneHundredFilled: HundredPercentAnimated,
  FireFilled: FireAnimated,
  HeartFilled: HeartAnimated,
  HeartStarsFilled: HeartStarsAnimated,
  HeartsFilled: HeartsAnimated,
  PartyPopperFilled: PartyPopperAnimated,
  RocketFilled: RocketAnimated,
  ShootingStarFilled: ShootingStarAnimated,
  LaughingFilled: LaughingAnimated,
  LaughingTearsFilled: LaughingTearsAnimated,
  LaughingRollingFilled: LaughingRollingAnimated,
  SadFilled: SadAnimated,
  SadTearFilled: SadCryAnimated,
  SadCryFilled: SadTearAnimated,
  SurprisedFilled: SurprisedAnimated,
  SurprisedHandsFilled: SurprisedHandsAnimated,
  MindBlownFilled: MindBlownAnimated,
};

const Container = styled.div<{
  $startX: number;
  $endX: number;
  $endY: number;
  $animation: string;
}>`
  position: absolute;
  width: 150px;
  left: ${(p) => p.$startX}px;
  top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  /** TODO: Check why opacity is disabling the background filter blur of the name tag */
  opacity: 0;

  animation: ${(p) => p.$animation} ${FLYING_DURATION}ms ease-out;

  @keyframes ${(p) => p.$animation} {
    5% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    20% {
      transform: translate(
        ${(p) => (p.$endX / 3) * 2}px,
        ${(p) => (p.$endY / 3) * 2}px
      );
    }
    60% {
      opacity: 1;
    }
    85% {
      opacity: 0;
    }
    100% {
      transform: translate(${(p) => p.$endX}px, ${(p) => p.$endY}px);
    }
  }
`;

const Shadow = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: -40px;
  filter: blur(64px);
`;

const Emoji = styled.object`
  width: 85px;
  height: 85px;
  z-index: ${zIndex.overlays};
`;

type Props = {
  startX: number;
  emoji: IconName;
  name: string;
};

/**
 * Flying Emoji component in ReactionsBar.
 * This component is manually injected into the DOM and should not use the theme.
 */
export const FlyingEmoji: React.FC<Props> = ({ startX, emoji, name }) => {
  return (
    <Container
      $startX={startX}
      $endX={randomInRange(-100, 100)}
      $endY={randomInRange(-350, -450)}
      $animation={"fly" + Date.now().toString()}
    >
      <Shadow />
      <Emoji
        type="image/svg+xml"
        data={EMOJI_MAP[emoji as keyof typeof EMOJI_MAP]}
      />
      <FlyingEmojiTag text={name} />
    </Container>
  );
};

export default FlyingEmoji;
