import { useCallback, useEffect, useMemo, useState } from "react";
import { css, styled } from "styled-components";
import { getArrayFrame, reverseArray, shiftArray } from "../../lib/array";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  white-space: nowrap;
  height: 100%;
  width: 100%;
`;

const Slide = styled.div<{
  $count: number;
  $visibleCount: number;
  $width?: string;
  $height?: string;
  $showPointer?: boolean;
}>`
  display: flex;
  align-content: stretch;
  flex: 0 0 auto;
  position: absolute;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  width: ${({ $width }) => $width || "100%"};
  height: ${({ $height }) => $height || "100%"};
  cursor: ${({ $showPointer }) => ($showPointer ? "pointer" : "default")};

  ${({ $count, $visibleCount }) => {
    const styles = [];
    const offset = (100 * $count) / 2;
    for (let i = 0; i < $count; i++) {
      const isVisible =
        i >= $count / 2 - $visibleCount / 2 &&
        i < $count / 2 + $visibleCount / 2;

      styles.push(css`
        &:nth-child(${i + 1}) {
          transform: translateX(${i * 100 - offset + 50}%)
            scale(${isVisible ? 1 : 0.5});
          opacity: ${isVisible ? 1 : 0};
        }
      `);
    }
    return styles;
  }}
`;

export type Slide = {
  id: string | number;
  width?: string;
  height?: string;
  getElement: (active: boolean) => React.ReactNode;
};

type KeyedSlide = Slide & {
  key: number;
};

const Carousel: React.FC<{
  slides: Slide[];
  activeSlideId: string | number;
  activeClass?: string;
  rotationDirection?: "left" | "right";
  onChange?: (slide: Slide) => void;
  onSlideClick?: (slideId: Slide["id"], position: "left" | "right") => void;
}> = ({
  slides,
  activeSlideId,
  activeClass,
  onChange,
  onSlideClick,
  rotationDirection,
}) => {
  const visibleCount = 3;
  const frameSize = visibleCount + 2;

  /**
   * If the provided slides array is smaller than the frame size, we need to
   * duplicate the slides to fill the frame. We then need to add a key to each
   * slide so that React can differentiate between the slides and keep the
   * correct ones in the DOM during the animation.
   */
  const keyedSlides = useMemo<KeyedSlide[]>(
    () =>
      getArrayFrame(slides, 0, slides.length * 2, true).map((slide, index) => ({
        ...slide,
        key: index,
      })),
    [slides]
  );

  const getSlideIndex = useCallback(
    (slidesState: Slide[], reverse?: boolean) =>
      (reverse ? reverseArray(slidesState) : slidesState).findIndex(
        ({ id }) => id === activeSlideId
      ) + (reverse ? 1 : 0),
    [activeSlideId]
  );

  const options = useMemo(() => {
    return slides
      .map((val) => val.id)
      .filter((val, idx, array) => array.indexOf(val) === idx);
  }, [slides]);

  const getClosestSlideIndex = useCallback(
    (slideIndex: number, reverseSlideIndex: number): number => {
      if (rotationDirection === "left" && options.length === 2) {
        return keyedSlides.length - reverseSlideIndex;
      } else if (rotationDirection === "right" && options.length === 2) {
        return slideIndex;
      }
      return reverseSlideIndex < slideIndex
        ? keyedSlides.length - reverseSlideIndex
        : slideIndex;
    },
    [rotationDirection, options.length, keyedSlides.length]
  );

  const computeSlidesState = useCallback(
    (slidesState: KeyedSlide[]) => {
      const slideIndex = getSlideIndex(slidesState);
      const reverseSlideIndex = getSlideIndex(slidesState, true);

      /**
       * Find the closest target slide to the active slide. This will make the carousel
       * animate in the correct direction.
       */
      const closestSlideIndex = getClosestSlideIndex(
        slideIndex,
        reverseSlideIndex
      );

      return getArrayFrame(
        slidesState,
        closestSlideIndex,
        slides.length * 2,
        true
      );
    },
    [getSlideIndex, slides.length, getClosestSlideIndex]
  );

  const [slidesState, setSlidesState] = useState(() =>
    computeSlidesState(keyedSlides)
  );

  useEffect(() => {
    setSlidesState((state) => computeSlidesState(state));
  }, [activeSlideId, computeSlidesState, onChange]);

  const visibleFrame = useMemo(() => {
    // Shift the slides so that the active slide is in the middle of the frame.
    const shiftedSlides = shiftArray(slidesState, Math.floor(frameSize / 2));
    return getArrayFrame(shiftedSlides, 0, frameSize, true);
  }, [frameSize, slidesState]);

  const activeSlide = useMemo(() => {
    const index = getSlideIndex(slidesState);
    return slidesState[index];
  }, [getSlideIndex, slidesState]);

  return (
    <Wrapper>
      {visibleFrame.map((slide, idx) => {
        return (
          <Slide
            className={
              activeClass && slide.key === activeSlide.key
                ? activeClass
                : undefined
            }
            onClick={(e) => {
              e.preventDefault();
              onSlideClick?.(slide.id, idx === 1 ? "left" : "right");
            }}
            $count={visibleFrame.length}
            $visibleCount={visibleCount}
            key={slide.key}
            $width={slide.width}
            $height={slide.height}
            $showPointer={Boolean(onSlideClick)}
          >
            {slide.getElement(slide.key === activeSlide.key)}
          </Slide>
        );
      })}
    </Wrapper>
  );
};

export default Carousel;
