import { SvgProps } from "../../types/svg";

const SurprisedIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_264_64)">
      <path
        d="M8 13C8 11.8969 8.89688 11 10 11C11.1031 11 12 11.8969 12 13V14C12 15.1031 11.1031 16 10 16C8.89688 16 8 15.1031 8 14V13ZM10 12C9.44687 12 9 12.4469 9 13V14C9 14.5531 9.44687 15 10 15C10.5531 15 11 14.5531 11 14V13C11 12.4469 10.5531 12 10 12ZM8.2625 9C8.2625 9.41563 7.925 9.75 7.5125 9.75C7.09687 9.75 6.7625 9.41563 6.7625 9C6.7625 8.58437 7.09687 8.25 7.5125 8.25C7.925 8.25 8.2625 8.58437 8.2625 9ZM11.7625 9C11.7625 8.58437 12.0969 8.25 12.5125 8.25C12.925 8.25 13.2625 8.58437 13.2625 9C13.2625 9.41563 12.925 9.75 12.5125 9.75C12.0969 9.75 11.7625 9.41563 11.7625 9ZM8 6C7.8625 6 7.725 6.00937 7.56563 6.02812C6.93125 6.11562 6.325 6.4125 5.84063 6.86562C5.64063 7.05312 5.32188 7.04375 5.13438 6.84062C4.94625 6.64062 4.95687 6.32188 5.15937 6.13438C5.78437 5.55 6.58125 5.15312 7.4625 5.03531C7.6375 5.01187 7.81875 5 8 5C8.275 5 8.5 5.225 8.5 5.5C8.5 5.775 8.275 6 8 6ZM12.4062 6.02812C12.275 6.00937 12.1375 6 12 6C11.725 6 11.5 5.775 11.5 5.5C11.5 5.225 11.725 5 12 5C12.1813 5 12.3625 5.01187 12.5375 5.03531C13.4188 5.15312 14.2156 5.55 14.8406 6.13438C15.0437 6.32188 15.0531 6.64062 14.8656 6.84062C14.6781 7.04375 14.3594 7.05312 14.1594 6.86562C13.675 6.4125 13.0688 6.11562 12.4062 6.02812ZM18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10ZM10 3C6.13438 3 3 6.13438 3 10C3 13.8656 6.13438 17 10 17C13.8656 17 17 13.8656 17 10C17 6.13438 13.8656 3 10 3Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
    <defs>
      <clipPath id="clip0_264_64">
        <rect
          width="16"
          height="16"
          fill="currentColor"
          className="fill-color"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SurprisedIcon;
