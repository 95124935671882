import React from "react";
import { SvgProps } from "../../types/svg";

const DownloadIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.58802 14.8973C9.70104 15.0062 9.85052 15.0625 10 15.0625C10.1495 15.0625 10.2985 15.0076 10.4123 14.8977L15.6623 9.83519C15.8902 9.61546 15.8902 9.25933 15.6623 9.03995C15.4345 8.82058 15.0652 8.82023 14.8377 9.03995L10.5833 13.143V2.6875C10.5833 2.37672 10.3208 2.125 10 2.125C9.67917 2.125 9.41667 2.37672 9.41667 2.6875V13.143L5.16234 9.04023C4.93448 8.82051 4.56516 8.82051 4.33766 9.04023C4.11016 9.25996 4.10979 9.61609 4.33766 9.83547L9.58802 14.8973ZM16.4167 16.75H3.58333C3.2609 16.75 3 17.0031 3 17.3125C3 17.6219 3.2609 17.875 3.58333 17.875H16.4167C16.7391 17.875 17 17.6234 17 17.3125C17 17.0016 16.7375 16.75 16.4167 16.75Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default DownloadIcon;
