import { useGetQuestBySlug } from "../../../api/gyaradosHooks";
import Icon from "../../../components-ui/atoms/Icon";
import { VideoConferenceState } from "../../../core/videoConference/VideoConferenceAdapter";
import { useStore } from "../../../store/store";
import HintUi from "../hint/Hint.ui";

const QuestHintLogic: React.FC<{ visible?: boolean }> = ({ visible }) => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const currentQuestProgress = useStore((s) => s.quest.currentQuestProgress);
  const quest = useGetQuestBySlug(currentQuestProgress?.slug || "");
  const isInConference = useStore(
    (state) => state.videoConference.state === VideoConferenceState.JOINED
  );
  let content = "";
  let icon;

  if (currentQuestProgress) {
    content = `${quest?.title || currentQuestProgress.slug} ${currentQuestProgress.currencyCollectedAmount}/${currentQuestProgress.currencyNeededAmount}`;
    const hasCompleted =
      currentQuestProgress.currencyCollectedAmount ===
      currentQuestProgress.currencyNeededAmount;
    icon = hasCompleted ? (
      <Icon.Check size="18px" inheritColor />
    ) : (
      <Icon.Star size="20px" inheritColor />
    );
  }

  const handleClick = () => {
    if (!visible) return;
    openPanel("quest");
    closePanel("questHint");
  };

  return (
    <HintUi
      visible={visible}
      content={content}
      onClick={handleClick}
      icon={icon}
      isLow={isInConference}
    />
  );
};

export default QuestHintLogic;
