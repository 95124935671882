import { styled } from "styled-components";
import Transition from "../../../components-ui/atoms/Transition";
import { BaseLayout } from "../../../core/layout/PanelsLayout";
import { useStore } from "../../../store/store";
import InfoCardLogic from "./InfoCard.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
`;

const InfoCardPanel: React.FC = () => {
  const infoCardId = useStore((s) => s.layout.panels.infocard?.slug);
  const visible = useStore((s) => s.layout.panels.infocard.visible);
  const zone = useStore((s) => s.layout.panels.infocard?.zone);

  return (
    <Layout id="panel-infocard">
      <Transition
        height="100%"
        watch={[infoCardId, visible]}
        delay={200}
        slideIn={zone === "right" ? "right" : "up"}
        slideOut={zone === "right" ? "right" : "down"}
        justify={zone === "right" ? "flex-end" : "center"}
        align={zone === "right" ? "flex-end" : "center"}
      >
        {visible && <InfoCardLogic infoCardId={infoCardId} />}
      </Transition>
    </Layout>
  );
};

export default InfoCardPanel;
