import { SvgProps } from "../../types/svg";

const FireFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 11.9718C16 15.3093 13.3094 17.9999 10 17.9999C6.69062 17.9999 4 15.3084 4 11.9999C4 10.1665 4.86937 8.66244 5.70531 7.80931C6.17312 7.29056 7 7.61869 7 8.29681V10.9687C7 12.0677 7.87406 12.9843 8.97375 12.9981C10.0906 13.0156 11 12.1124 11 10.9999C11 8.24994 5.52813 7.99619 9.37031 2.33744C9.79219 1.72119 10.75 2.00087 10.75 2.74462C10.7219 5.96869 16 6.67806 16 11.9718Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default FireFilledIcon;
