export const iconMenuTestIds = {
  root: "dynamicBtnMenu-root",
  menuItem: (name?: string) =>
    name ? `iconMenu-menuItem-${name}` : `iconMenu-menuItem`,
  anchor: "dynamicBtnMenu-anchor",
};

export const DynamicButtonTestIds = {
  wrapper: "DynamicButton-wraper",
};

export const DevLandingPageTestIds = {
  root: "devLandingPage-root",
};

export const CardTestIds = {
  root: "card-root",
  closeBtn: "card-closeBtn",
  cardImage: {
    root: "cardImage-root",
    preview: "panel-cardImage-preview",
    landscape: "panel-cardImage-landscape",
    portrait: "panel-cardImage-portrait",
    default: "panel-cardImage-default",
  },
};

export const LoginPageTestIds = {
  root: "loginPage-root",
  logo: "loginPage-logo",
  vignette: "loginPage-vignette",
  mainBtn: "loginPage-mainBtn",
  secondaryBtn: "loginPage-secondaryBtn",
  title: "loginPage-title",
  description: "loginPage-description",
  warningIcon: "loginPage-warningIcon",
};

export const PanelTestIds = {
  videoAvatars: {
    videoAvatarCircle: {
      muteButton: "panel-videoAvatars-videoAvatarCircle-muteButton",
      videoButton: "panel-videoAvatars-videoAvatarCircle-videoButton",
      speakingIndication:
        "panel-videoAvatars-videoAvatarCircle-speakingIndication",
      titleMuteIcon: "panel-videoAvatars-videoAvatarCircle-titleMuteIcon",
      videoFeed: "panel-videoAvatars-videoAvatarCircle-videoFeed",
      avatarInitials: "panel-videoAvatars-videoAvatarCircle-avatarInitials",
    },
    peerVideoAvatarCircle: "panel-videoAvatars-peerVideoAvatarCircle",
    localVideoAvatarCircle: "panel-videoAvatars-localVideoAvatarCircle",
  },
  profile: {
    normal: {
      root: "panel-normal-profile-root",
    },
    portrait: {
      root: "panel-portrait-profile-root",
    },
    landscape: {
      root: "panel-landscape-profile-root",
    },
    common: {
      formField: (name: string) => `panel-profile-formField-${name}`,
      formFields: "panel-profile-formFields",
      confirm: "panel-profile-confirm",
      rpmAvatarUrlInput: "panel-profile-rpmAvatarUrlInput",
      carouselAvatarPicker: "panel-profile-carouselAvatarPicker",
      gridAvatarPicker: "panel-profile-gridAvatarPicker",
      carouselAvatarChoice: "panel-profile-carouselAvatarChoice",
      gridAvatarChoice: "panel-profile-gridAvatarChoice",
      customAvatarBtn: (avatarId: string) =>
        `panel-profile-customAvatarBtn-${avatarId}`,
      leftCarouselButton: "panel-profile-leftCarouselButton",
      rightCarouselButton: "panel-profile-rightCarouselButton",
      avatarHiddenInput: "panel-profile-avatarHiddenInput",
      avatarErrorMessage: "panel-profile-avatarErrorMessage",
    },
  },
  settings: {
    normal: {
      videoConferenceLandingPanel:
        "panel-normal-settings-videoConferenceLandingPanel",
      videoConferencePreviewPanel:
        "panel-normal-settings-videoConferencePreviewPanel",
      root: "panel-normal-settings-root",
    },
    portrait: {
      root: "panel-portrait-settings-root",
    },
    landscape: {
      root: "panel-landscape-settings-root",
    },
    common: {
      screensharingButton: "panel-settings-common-screensharingButton",
      leaveVideoConferenceButton: "panel-settings-leaveVideoConferenceButton",
      joinVideoConferenceButton: "panel-settings-joinVideoConferenceButton",
      prepareToJoinButton: "panel-settings-prepareToJoinButton",
      videoConference: "panel-settings-videoConference",
      bottomBar: "panel-settings-bottomBar",
      previousBtn: "panel-settings-previousBtn",
      selectLanguageBtn: "panel-settings-selectLanguageBtn",
    },
  },
  controls: {
    default: {
      normal: {
        root: "panel-default--normal-controls-root",
      },
      portrait: {
        root: "panel-default-portrait-controls-root",
      },
      landscape: {
        root: "panel-default-landscape-controls-root",
      },
      common: {
        buttonsSchema: "panel-default-controls-buttons-schema",
        keysSchema: "panel-default-controls-keys-schema",
      },
    },
    touch: {
      normal: {
        root: "panel-touch-normal-controls-root",
      },
      portrait: {
        root: "panel-touch-portrait-controls-root",
      },
      landscape: {
        root: "panel-touch-landscape-controls-root",
      },
      common: {
        buttonsSchema: "panel-touch-controls-buttons-schema",
        gesturesSchema: "panel-touch-controls-gestures-schema",
      },
    },
    common: {},
  },
  actionBar: {
    settings: "panel-actionBar-settings",
  },
  popup: {
    buttons: {
      spawnPoint: "panel-popup-buttons-spawnPoint",
      externalUrl: "panel-popup-buttons-externalUrl",
      dismissBtn: "panel-popup-buttons-dismissBtnText",
    },
    root: "panel-popup-root",
    title: "panel-popup-title",
    actions: "panel-popup-actions",
    content: "panel-popup-content",
    image: "panel-popup-image",
  },
  infoCard: {
    root: "panel-info-card-root",
    image: "panel-info-card-image",
    iconPortrait: "panel-info-card-icon-portrait",
    iconLandscape: "panel-info-card-icon-landscape",
    title: "panel-info-card-title",
  },
  poll: {
    root: "panel-poll-root",
    multipleChoices: "panel-poll-multipleChoices",
    multipleChoicesInput: "panel-poll-multipleChoicesInput",
    singleChoice: "panel-poll-singleChoice",
    stars: "panel-poll-stars",
    submit: "panel-poll-submit",
    submitLoading: "panel-poll-submitLoading",
    submitDone: "panel-poll-submitDone",
    errorMessage: "panel-poll-errorMessage",
    subtitleError: "panel-poll-subtitleError",
    titleError: "panel-poll-titleError",
  },
};
