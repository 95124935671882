import { SvgProps } from "../../types/svg";

const HeartIcon: React.FC<SvgProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.97188 3L8.34688 2.62563C9.39375 1.60531 10.8438 1.14094 12.2688 1.37813C14.4219 1.73688 16 3.6 16 5.78438V5.96563C16 7.2625 15.4625 8.50313 14.5125 9.3875L8.86563 14.6594C8.63125 14.8781 8.32187 15 8 15C7.67812 15 7.36875 14.8781 7.13438 14.6594L1.48719 9.3875C0.538437 8.50313 0 7.2625 0 5.96563V5.78438C0 3.6 1.57875 1.73688 3.73125 1.37813C5.12813 1.14094 6.60625 1.60531 7.625 2.62563L7.97188 3ZM7.97188 4.41563L6.91875 3.33125C6.12812 2.54094 5 2.18031 3.89687 2.36438C2.22531 2.64313 0.971875 4.09063 0.971875 5.78438V5.96563C0.971875 6.9875 1.42344 7.95938 2.16937 8.65625L7.81563 13.9281C7.86563 13.975 7.93125 14 7.97188 14C8.06875 14 8.13437 13.975 8.18437 13.9281L13.8313 8.65625C14.575 7.95938 15 6.9875 15 5.96563V5.78438C15 4.09063 13.775 2.64313 12.1031 2.36438C10.9719 2.18031 9.87187 2.54094 9.08125 3.33125L7.97188 4.41563Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default HeartIcon;
