import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ServicesApi } from "@journee-live/mew";
import { SWRConfig } from "swr";
import { swrConfig } from "./constants/configs";
import { initializeFlags } from "./constants/flags";
import DevOptionsProvider from "./core/dev/DevOptionsProvider";
import "./core/gameConnection/webrtc/helpers/screencaster";
import "./core/i18n/i18n";
import Routing from "./core/routing/Routing";
import { initializeSentry } from "./core/tracking/sentry";
import { useSentryCollector } from "./core/tracking/sentryColletor";
import DetectTouchForCss from "./core/utility/DetectTouchForCss";
import SafeMarginVariables from "./core/utility/SafeMarginVariables";
import { useApplyClientPersistedId } from "./features/login/loginHooks";
import "./style/_normalize.css";
import "./style/global.css";

initializeSentry();
initializeFlags();

const App: React.FC = () => {
  ServicesApi.baseUrl = import.meta.env.VITE_SERVICES_URL;
  useSentryCollector();
  useApplyClientPersistedId();

  return (
    <StrictMode>
      <DevOptionsProvider>
        <SafeMarginVariables>
          <DetectTouchForCss />
          <HelmetProvider>
            <BrowserRouter>
              <SWRConfig value={swrConfig}>
                <Routing />
              </SWRConfig>
            </BrowserRouter>
          </HelmetProvider>
        </SafeMarginVariables>
      </DevOptionsProvider>
    </StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
