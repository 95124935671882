import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useGetLocales } from "../../../api/gyaradosHooks";
import {
  getQueryStrings,
  updateQueryString,
} from "../../../core/routing/routingUtils";
import { useStore } from "../../../store/store";
import LanguageUi from "./Language.ui";

const LanguageLogic: React.FC = () => {
  const closePanel = useStore((s) => s.layout.closePanel);
  const locales = useGetLocales();
  const { selectedLocaleCode, setSelectedLocaleCode } = useStore((s) => s.i18n);
  const { lang: queryRequestedLocale } = getQueryStrings();
  const navigate = useNavigate();

  const handleDismiss = useCallback(() => {
    closePanel("language");
  }, [closePanel]);

  const onLanguageSelected = useCallback(
    (locale: string) => {
      if (queryRequestedLocale) {
        updateQueryString(navigate, "lang", locale);
      } else {
        setSelectedLocaleCode(locale);
      }
    },
    [setSelectedLocaleCode, queryRequestedLocale, navigate]
  );

  return (
    <LanguageUi
      onLanguageSelected={onLanguageSelected}
      handleDismiss={handleDismiss}
      locales={locales}
      currentLocale={selectedLocaleCode}
    />
  );
};

export default LanguageLogic;
