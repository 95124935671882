import React from "react";
import { SvgProps } from "../../types/svg";

const InstagramIcon: React.FC<SvgProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.00312 4.40623C6.01562 4.40623 4.41249 6.00935 4.41249 7.99685C4.41249 9.98435 6.01562 11.5875 8.00312 11.5875C9.99062 11.5875 11.5937 9.98435 11.5937 7.99685C11.5937 6.00935 9.99062 4.40623 8.00312 4.40623ZM8.00312 10.3312C6.71874 10.3312 5.66874 9.28435 5.66874 7.99685C5.66874 6.70935 6.71562 5.66248 8.00312 5.66248C9.29062 5.66248 10.3375 6.70935 10.3375 7.99685C10.3375 9.28435 9.28749 10.3312 8.00312 10.3312ZM12.5781 4.25935C12.5781 4.72498 12.2031 5.09685 11.7406 5.09685C11.275 5.09685 10.9031 4.72185 10.9031 4.25935C10.9031 3.79685 11.2781 3.42185 11.7406 3.42185C12.2031 3.42185 12.5781 3.79685 12.5781 4.25935ZM14.9562 5.10935C14.9031 3.98748 14.6469 2.99373 13.825 2.17498C13.0062 1.35623 12.0125 1.09998 10.8906 1.04373C9.73437 0.978101 6.26874 0.978101 5.11249 1.04373C3.99374 1.09685 2.99999 1.3531 2.17812 2.17185C1.35624 2.9906 1.10312 3.98435 1.04687 5.10623C0.981244 6.26248 0.981244 9.7281 1.04687 10.8843C1.09999 12.0062 1.35624 13 2.17812 13.8187C2.99999 14.6375 3.99062 14.8937 5.11249 14.95C6.26874 15.0156 9.73437 15.0156 10.8906 14.95C12.0125 14.8968 13.0062 14.6406 13.825 13.8187C14.6437 13 14.9 12.0062 14.9562 10.8843C15.0219 9.7281 15.0219 6.2656 14.9562 5.10935ZM13.4625 12.125C13.2187 12.7375 12.7469 13.2094 12.1312 13.4562C11.2094 13.8219 9.02187 13.7375 8.00312 13.7375C6.98437 13.7375 4.79374 13.8187 3.87499 13.4562C3.26249 13.2125 2.79062 12.7406 2.54374 12.125C2.17812 11.2031 2.26249 9.0156 2.26249 7.99685C2.26249 6.9781 2.18124 4.78748 2.54374 3.86873C2.78749 3.25623 3.25937 2.78435 3.87499 2.53748C4.79687 2.17185 6.98437 2.25623 8.00312 2.25623C9.02187 2.25623 11.2125 2.17498 12.1312 2.53748C12.7437 2.78123 13.2156 3.2531 13.4625 3.86873C13.8281 4.7906 13.7437 6.9781 13.7437 7.99685C13.7437 9.0156 13.8281 11.2062 13.4625 12.125Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default InstagramIcon;
