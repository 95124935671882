import { SvgProps } from "../../types/svg";

const HeartsFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9284 2.35932C18.4034 1.88242 19.1722 1.88242 19.644 2.35932C20.119 2.83622 20.119 3.60907 19.644 4.08597L17.9128 5.82825C17.6846 6.05639 17.3159 6.05639 17.0909 5.82825L15.3565 4.0841C14.8815 3.6072 14.8815 2.83434 15.3565 2.35744C15.8284 1.88085 16.5971 1.88085 17.0721 2.35744L17.5003 2.78965L17.9284 2.35932ZM19.644 16.0851L17.9128 17.8289C17.6846 18.057 17.3159 18.057 17.0909 17.8289L15.3565 16.0851C14.8815 15.6069 14.8815 14.835 15.3565 14.3568C15.8284 13.8818 16.5971 13.8818 17.0721 14.3568L17.5003 14.7912L17.9284 14.36C18.4034 13.8818 19.1722 13.8818 19.644 14.36C20.119 14.8381 20.119 15.61 19.644 16.0851ZM2.92848 13.3599C3.40317 12.8818 4.17194 12.8818 4.64382 13.3599C5.11882 13.8381 5.11882 14.61 4.64382 15.085L2.91254 16.8288C2.68535 17.057 2.3166 17.057 2.08941 16.8288L0.355318 15.085C-0.118439 14.6068 -0.118439 13.8349 0.355318 13.3568C0.829387 12.8818 1.59752 12.8818 2.07159 13.3568L2.50097 13.7912L2.92848 13.3599Z"
      fill="currentColor"
      className="fill-color"
    />
    <path
      d="M18.0006 7.96588V7.78462C18.0006 7.49742 17.9731 7.21615 17.9209 6.9427C17.7856 6.97957 17.6449 6.99926 17.5009 6.99926C17.0765 6.99926 16.6787 6.83332 16.3806 6.53174L14.648 4.78946C14.248 4.38756 14.034 3.8691 14.0052 3.34188C12.6667 3.2 11.3292 3.66783 10.347 4.62539L10.0001 4.99978L9.62514 4.62539C8.60638 3.60502 7.12823 3.14062 5.73134 3.37782C3.5788 3.73659 2.00003 5.59981 2.00003 7.78431V7.96557C2.00003 9.26251 2.53848 10.5032 3.48724 11.3876L4.18256 12.0367C4.62444 12.1164 5.0307 12.3286 5.35383 12.6552C5.64165 12.9452 5.8329 13.2959 5.9279 13.6659L9.13482 16.6598C9.3692 16.8785 9.67858 17.0004 10.0005 17.0004C10.3223 17.0004 10.6317 16.8785 10.8661 16.6598L16.5131 11.3876C17.4631 10.5032 18.0006 9.26251 18.0006 7.96557V7.96588Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default HeartsFilledIcon;
