import { useCallback, useRef, useState } from "react";
import {
  LOCALSTORAGE_RECENT_ENV,
  LOCALSTORAGE_VISITOR_PROMPT,
} from "../constants/configs";
import { logError } from "../lib/logger";

// ! IMPORTANT
/* The main correct way to interract with the localStorage is through
the zustand integration (/src/store).

THis hook should ONLY be used for quick throw away:
- Not valuable if lost.
- Not reactively shared across components.

/**
 * Keys used by this hook. Both for overview and auto-complete purposes.
 */
type LocalStorageKeys =
  | typeof LOCALSTORAGE_RECENT_ENV
  | typeof LOCALSTORAGE_VISITOR_PROMPT;

/**
 * From: https://usehooks.com/useLocalStorage/
 * Improved by Nathan Vogel to not cause useless re-renders.
 * It should however only be used for small values that quickly
 * need to be saved with an interface similar to useState() and
 * aren't used in multiple component instances. The state will NOT
 * be synced across multiple instances.
 *
 * The Redux store persistence mechanism is better suited to more
 * complex purposes such as persisting the business card.
 *
 * @param key The Local Storage key
 * @param initialValue
 */
const useLocalStorage = <T>(
  key: LocalStorageKeys,
  initialValue: T
): [T, (value: T | ((val: T) => T)) => void, React.MutableRefObject<T>] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  // This is a bit UNTESTED and is bad practice
  // (see: https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback)
  // but use a ref to avoid recreating the callback function everytime the
  // value changes (and thus re-rendering components.)
  // + makes more sense for rapid calls without re-renders inbetween.
  const valueRef = useRef(storedValue);
  valueRef.current = storedValue;

  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(valueRef.current) : value;
        // Save state
        setStoredValue(valueToStore);
        valueRef.current = valueToStore;
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case
        logError("GENERIC", error);
      }
    },
    [key]
  );

  // Also returns the ref so that it can be used in other useCallback stuff
  return [storedValue, setValue, valueRef];
};

export default useLocalStorage;
