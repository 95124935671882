import { useMemo, useRef } from "react";
import styled from "styled-components";
import Column from "../../../../../../components-ui/atoms/Column";
import HeaderBadge from "../../../../../../components-ui/atoms/HeaderBadge";
import Icon from "../../../../../../components-ui/atoms/Icon";
import Row from "../../../../../../components-ui/atoms/Row";
import Space from "../../../../../../components-ui/atoms/Space";
import Typo from "../../../../../../components-ui/atoms/Typo";
import { Ring } from "../../../../../../components-ui/molecules/StartButton";
import { PanelTestIds } from "../../../../../../constants/testIds";
import { useText } from "../../../../../../core/i18n/i18nHooks";
import { VideoConferenceParticipant } from "../../../../../../core/videoConference/VideoConferenceAdapter";
import LocalVideoAvatarCircle from "../../../../videoAvatars/avatarCircle/LocalVideoAvatarCircle";
import { VIDEO_AVATAR_CIRCLE_SIZE } from "../../../../videoAvatars/lib/videoAvatarsConstants";
import ConferenceControlButton from "../ConferenceControlButton";
import DeviceSelector from "../DeviceSelector";
import { Props } from "../SettingsVideoPreview.ui";

const Container = styled.div<{ height?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const VideoPreview: React.FC<Props & { buttonComponent: React.ReactNode }> = ({
  localCameraStream,
  localAudioStream,
  micMuted,
  webcamMuted,
  conferenceInitialized,
  conferenceErrors,
  toggleLocalAudio,
  toggleVideo,
  roomId,
  playerName,
  avatarColor,
  toggleScreenShare,
  activeScreenShare,
  amIScreenSharer,
  screenSharer,
  selectedAudioInputDevice,
  selectedAudioOutputDevice,
  selectedVideoInputDevice,
  audioInputDevices,
  audioOutputDevices,
  videoInputDevices,
  buttonComponent,
}) => {
  const t = useText();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const screenSharingDisabled =
    (!amIScreenSharer && Boolean(screenSharer)) || !conferenceInitialized;

  const cameraErrorText = useMemo(() => {
    switch (true) {
      case conferenceErrors.includes("cameraAccessDenied"):
        return t("settings_no_camera_permission");
      case conferenceErrors.includes("noCameraAvailable"):
        return t("settings_no_valid_cam");
      case conferenceErrors.includes("cameraFeedError"):
        return t("settings_camera_feed_error");
      default:
        return undefined;
    }
  }, [conferenceErrors, t]);

  const cameraDisabled =
    conferenceErrors.includes("cameraAccessDenied") ||
    conferenceErrors.includes("noCameraAvailable");

  const microphoneErrorText = useMemo(() => {
    switch (true) {
      case conferenceErrors.includes("microphoneAccessDenied"):
        return t("settings_no_microphone_permission");
      case conferenceErrors.includes("noMicrophoneAvailable"):
        return t("settings_no_valid_mic");
      default:
        return undefined;
    }
  }, [conferenceErrors, t]);

  const participant: VideoConferenceParticipant = useMemo(() => {
    const combinedStream = new MediaStream();
    if (localCameraStream) {
      localCameraStream.getTracks().forEach((track) => {
        combinedStream.addTrack(track);
      });
    }
    if (localAudioStream) {
      localAudioStream.getTracks().forEach((track) => {
        combinedStream.addTrack(track);
      });
    }
    return {
      userId: "local",
      streams: [combinedStream],
      isSpeaking: false,
      lastActive: 0,
      videoEnabled: !webcamMuted,
      audioEnabled: !micMuted,
      isVideoOn: !webcamMuted && !!localCameraStream,
      isAudioOn: !micMuted,
      isLocal: true,
      id: "local",
      name: "local",
      isPublishing: true,
      isVideoHidden: false,
    };
  }, [localAudioStream, localCameraStream, micMuted, webcamMuted]);

  if (!roomId) {
    return (
      <Container ref={wrapperRef}>
        <Column align="center">
          <Ring isSpinning strokeWidth="4" reverse size={50} />
          <Space h={4} />
          <Typo.Subtitle>{t("player_joining_room")}</Typo.Subtitle>
          <Typo.Note>{t("player_please_wait")}</Typo.Note>
        </Column>
      </Container>
    );
  }

  return (
    <Container ref={wrapperRef}>
      <Row height="140px" justify="center">
        <HeaderBadge noBackground>
          <LocalVideoAvatarCircle
            circleStyle={{
              padding: "3px",
            }}
            micMuted={micMuted}
            playerName={playerName}
            size={VIDEO_AVATAR_CIRCLE_SIZE.LARGE}
            micHidden
            participant={participant}
            toggleLocalAudio={toggleLocalAudio}
            avatarColor={avatarColor}
            playerKey={participant?.userId || null}
          />
        </HeaderBadge>
      </Row>
      <Space h={5} />

      <Row gap={3} justify="center" align="flex-start">
        <ConferenceControlButton
          disabled={Boolean(microphoneErrorText)}
          active={!micMuted}
          error={Boolean(microphoneErrorText)}
          errorText={microphoneErrorText}
          onClick={toggleLocalAudio}
          onText={t("settings_on")}
          offText={t("settings_off")}
          label={t("settings_voice")}
          onIcon={<Icon.MicrophoneOnThin inheritColor size="20px" />}
          offIcon={<Icon.MicrophoneOffThin inheritColor size="20px" />}
        />
        <ConferenceControlButton
          disabled={cameraDisabled}
          active={!webcamMuted}
          error={Boolean(cameraErrorText)}
          errorText={cameraErrorText}
          onClick={toggleVideo}
          onText={t("settings_on")}
          offText={t("settings_off")}
          label={t("settings_video")}
          onIcon={<Icon.VideoOn inheritColor size="20px" />}
          offIcon={<Icon.VideoOff inheritColor size="20px" />}
        />
        {conferenceInitialized && (
          <ConferenceControlButton
            testId={PanelTestIds.settings.common.screensharingButton}
            disabled={screenSharingDisabled}
            active={activeScreenShare}
            onClick={toggleScreenShare}
            onText={t("settings_on")}
            offText={t("settings_off")}
            label={t("settings_share")}
            onIcon={<Icon.ScreenShare inheritColor size="20px" />}
            offIcon={<Icon.ScreenShare inheritColor size="20px" />}
          />
        )}
      </Row>
      <Space h={5} />
      <DeviceSelector
        selectedAudioInputDevice={selectedAudioInputDevice}
        selectedAudioOutputDevice={selectedAudioOutputDevice}
        selectedVideoInputDevice={selectedVideoInputDevice}
        audioInputDevices={audioInputDevices}
        audioOutputDevices={audioOutputDevices}
        videoInputDevices={videoInputDevices}
        micMuted={micMuted}
        audioStream={localAudioStream}
        conferenceErrors={conferenceErrors}
      />
      {buttonComponent}
    </Container>
  );
};

export default VideoPreview;
