import { SvgProps } from "../../types/svg";

const RocketFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.68125 5.97185H8.46875C10.9656 1.883 14.7969 1.73303 17.1219 2.16419C17.4875 2.23153 17.7688 2.51403 17.8344 2.87716C18.2656 5.2031 18.1156 9.03435 14 11.5031V14.3187C14 15.1156 13.5813 15.85 12.8969 16.2562L10.1313 17.8937C9.9 18.0312 9.6125 18.0343 9.37813 17.9031C9.14375 17.7687 9 17.5187 9 17.25V13.6656C9 12.9562 8.71875 12.2531 8.21875 11.7531C7.71875 11.2531 7.04375 11 6.33438 11H2.75C2.48063 11 2.23169 10.8562 2.09831 10.6218C1.96491 10.3875 1.96744 10.1 2.10491 9.86872L3.74469 7.1031C4.14969 6.41872 4.88531 5.97185 5.68125 5.97185ZM14 4.74997C13.3094 4.74997 12.75 5.30935 12.75 5.99997C12.75 6.6906 13.3094 7.24997 14 7.24997C14.6906 7.24997 15.25 6.6906 15.25 5.99997C15.25 5.30935 14.6906 4.74997 14 4.74997ZM7.20313 16.6875C5.65625 18.2343 2.01488 17.9843 2.01488 17.9843C2.01488 17.9843 1.76513 14.3156 3.31188 12.7968C4.38656 11.7218 6.12813 11.7218 7.20313 12.7968C8.27813 13.8718 8.27813 15.6125 7.20313 16.6875ZM5.74375 14.2562C5.38438 13.9 4.80656 13.9 4.44938 14.2562C3.935 14.7718 4.01813 15.9812 4.01813 15.9812C4.01813 15.9812 5.22813 16.0656 5.74375 15.55C6.1 15.1937 6.1 14.6156 5.74375 14.2562Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default RocketFilledIcon;
