export const ReactionNames = [
  "surprised",
  "sad",
  "celebrate",
  "laughing",
  "love",
  "thumbsUp",
] as const;

export type ReactionName = (typeof ReactionNames)[number];
