/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_LOG_SETTINGS } from "../constants/configs";

export type LogType = keyof typeof DEFAULT_LOG_SETTINGS;

// We explicitely omit "log" as that would be reserved for current debugging/development and should not be committed.
const logModes = ["error", "warn", "info", "debug", "table"] as const;
type LogMode = (typeof logModes)[number];

const consoleWrapper = (type: LogType, mode: LogMode, ...args: any[]) => {
  if (DEFAULT_LOG_SETTINGS[type]) console[mode](...args);
};

type LogFunction = (type: LogType, ...args: any[]) => void;

export const log: LogFunction = (type, ...args) =>
  consoleWrapper(type, "debug", ...args);
export const logError: LogFunction = (type, ...args) =>
  consoleWrapper(type, "error", ...args);
export const logWarn: LogFunction = (type, ...args) =>
  consoleWrapper(type, "warn", ...args);
export const logInfo: LogFunction = (type, ...args) =>
  consoleWrapper(type, "info", ...args);
export const logTable: LogFunction = (type, ...args) =>
  consoleWrapper(type, "table", ...args);
