import merge from "lodash/merge";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StateCreator } from "zustand/vanilla";
import {
  STORE_LOCAL_STORAGE_KEY,
  STORE_LOCAL_STORAGE_VERSION,
} from "../constants/configs";
import {
  LoadLevelControllerState,
  createLoadLevelControllerSlice,
} from "../core/LegacySliceControllers/loadLevelControllerSlice";
import {
  GameConnectionState,
  createGameConnectionSlice,
} from "../core/gameConnection/gameConnectionSlice";
import { I18nState, createI18nSlice } from "../core/i18n/i18nSlice";
import { LayoutState, createLayoutSlice } from "../core/layout/layoutSlice";
import { UiState, createUiSlice } from "../core/uiSlice";
import { UserFlowState, createUserFlowSlice } from "../core/userFlowSlice";
import {
  UserMediaState,
  createUserMediaSlice,
} from "../core/userMedia/userMediaSlice";
import {
  VideoConferenceState,
  createVideoConferenceSlice,
} from "../core/videoConference/videoConferenceSlice";
import {
  SessionState,
  createSessionSlice,
} from "../features/login/sessionSlice";
import {
  MediaCaptureState,
  createMediaCaptureSlice,
} from "../features/mediaCapture/MediaCapture.slice";
import {
  MintingState,
  createMintingSlice,
} from "../features/minting/mintingSlice";
import { MapState, createMapSlice } from "../features/panels/map/mapSlice";
import {
  VisitorProfileState,
  createVisitorProfileSlice,
} from "../features/panels/profile/profileSlice";
import {
  SocialState,
  createSocialSlice,
} from "../features/panels/social/socialSlice";
import {
  TextChatState,
  createTextChatSlice,
} from "../features/panels/social/subpages/chat/textChatSlice";
import { QuestsState, createQuestsSlice } from "../features/quests/questsSlice";

export type CombinedState = {
  gameConnection: GameConnectionState;
  session: SessionState;
  userFlow: UserFlowState;
  profile: VisitorProfileState;
  i18n: I18nState;
  layout: LayoutState;
  textChat: TextChatState;
  social: SocialState;
  loadLevelController: LoadLevelControllerState;
  ui: UiState;
  map: MapState;
  userMedia: UserMediaState;
  minting: MintingState;
  videoConference: VideoConferenceState;
  mediaCapture: MediaCaptureState;
  quest: QuestsState;
};

// This is currently the only way of properly type the state
export type SliceCreator<Slice> = StateCreator<
  CombinedState,
  [
    ["zustand/devtools", never],
    ["zustand/persist", unknown],
    ["zustand/immer", never],
  ],
  [],
  Slice
>;

// Due to typescript quirks, this has to all be in one giant object for inference
export const useStore = create<CombinedState>()(
  // Allows us to use Redux devtools in the browser
  devtools(
    // Persists the state to the localStorage in the browser
    persist(
      //// Logs each action to the console
      ////logger(
      // Wraps Immer around the set function
      immer((...args) => ({
        // Add the slices here
        ...createSessionSlice(...args),
        ...createUserFlowSlice(...args),
        ...createI18nSlice(...args),
        ...createLayoutSlice(...args),
        ...createGameConnectionSlice(...args),
        ...createTextChatSlice(...args),
        ...createVisitorProfileSlice(...args),
        ...createSocialSlice(...args),
        ...createLoadLevelControllerSlice(...args),
        ...createUiSlice(...args),
        ...createMapSlice(...args),
        ...createUserMediaSlice(...args),
        ...createMintingSlice(...args),
        ...createVideoConferenceSlice(...args),
        ...createMediaCaptureSlice(...args),
        ...createQuestsSlice(...args),
      })),
      ////   Logger options
      ////   {
      ////     enable: import.meta.env.MODE === "development",
      ////   }
      //// ),
      // Persist options
      {
        name: STORE_LOCAL_STORAGE_KEY,
        version: STORE_LOCAL_STORAGE_VERSION,
        // Since we have nested states, we need to deep-merge the objects
        merge: (persistedState, currentState) =>
          merge(currentState, persistedState),
        // Filter which slices to be persisted.
        partialize: (state) => ({
          session: {
            clientPersistedId: state.session.clientPersistedId,
            visitorToken: state.session.visitorToken,
            visitorTokenData: state.session.visitorTokenData,
          },
          profile: state.profile,
          userMedia: {
            micMuted: state.userMedia.micMuted,
            webcamMuted: state.userMedia.webcamMuted,
            selectedCamera: state.userMedia.selectedCamera,
            selectedMicrophone: state.userMedia.selectedMicrophone,
            selectedSpeakers: state.userMedia.selectedSpeakers,
          },
        }),
      }
    ),
    // Devtools options
    {
      name: STORE_LOCAL_STORAGE_KEY,
    }
  )
);
