import { GyaradosSDK } from "@journee-live/gyarados";
import { styled } from "styled-components";
import { useIsSmallScreen, useReflow } from "../../../hooks/ui";
import InfoCardUiLandscape from "./InfoCard.ui.landscape";
import InfoCardUiPortrait from "./InfoCard.ui.portrait";
import InfoCardUiPreview from "./InfoCard.ui.preview";
import InfoCardPanelUiNormal from "./InfoCardPanel.ui.normal";

/** Because the infocard has so many shapes, we need to break our pattern
 * and add some layout-related CSS in here. This allows us to deal with the
 * edge-case positioning of InfoCardPanelMobilePreviewUi without excessive logic.
 */
const ExtraLayoutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export type Props = {
  handleDismiss?: () => void;
  onButtonClick?: () => void;
  onSecondaryButtonClick: () => void;
  data: GyaradosSDK.InfoCardResponseDto;
  testId?: string;
};

type ExtraProps = {
  previewMode?: boolean;
  setPreviewMode?: (value: boolean) => void;
};

const InfoCardUi: React.FC<Props & ExtraProps> = ({
  previewMode,
  setPreviewMode,
  ...props
}) => {
  const isSmallScreen = useIsSmallScreen();

  return useReflow({
    normal: <InfoCardPanelUiNormal {...props} />,
    landscape: <InfoCardUiLandscape {...props} />,
    portrait: <InfoCardUiPortrait {...props} />,
    extra: [
      {
        condition: Boolean(isSmallScreen && previewMode),
        component: (
          <ExtraLayoutWrapper>
            {/* TODO: add safe margin form other PR when done. */}
            <InfoCardUiPreview
              {...props}
              onExpandClick={() => setPreviewMode?.(false)}
            />
          </ExtraLayoutWrapper>
        ),
      },
    ],
  });
};

export default InfoCardUi;
