import { useGetHintBySlug } from "../../../api/gyaradosHooks";
import { useStore } from "../../../store/store";
import HintUi from "./Hint.ui";

type Props = {
  id?: string;
  visible?: boolean;
  // A custom content of the hint when triggered by Lugia
  content?: string;
};

const HintLogic: React.FC<Props> = ({ id, visible, content }) => {
  const data = useGetHintBySlug(id);
  const text = data?.content ?? content;
  const isInConference = useStore((state) =>
    state.videoConference.isConferenceInitialized()
  );

  if (!text) return null;
  return <HintUi visible={visible} content={text} isLow={isInConference} />;
};

export default HintLogic;
