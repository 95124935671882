/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Publisher,
  Session,
  Stream,
  Subscriber,
  initPublisher,
} from "@opentok/client";
import {
  ActorRef,
  assign,
  createActor,
  createMachine,
  fromPromise,
} from "xstate";
import { logInfo } from "../../../lib/logger";
import { useStore } from "../../../store/store";
import { VonageStateMachineEvents } from "./types";

type ScreenShareMachineContext = {
  session: Session | null;
  screensharingPublisher: Publisher | null;
  screensharingSubscriber: Subscriber | null;
  stream: Stream | null;
};

const screenShareMachine = createMachine(
  {
    types: {} as {
      context: ScreenShareMachineContext;
      events: VonageStateMachineEvents;
    },
    context: {
      stream: null,
      session: null,
      screensharingPublisher: null,
      screensharingSubscriber: null,
    },
    id: "vonageScreenShareMachine",
    initial: "Disabled",
    states: {
      Disabled: {
        on: {
          "lugia.enableVonageScreenSharing": {
            target: "Inactive",
            actions: assign({
              session: ({ event }) => event.data,
            }),
          },
        },
      },
      Inactive: {
        on: {
          "lugia.screenSharingStarted": {
            target: "StartingScreenShare",
          },
          "conference.remoteScreensharingStarted": {
            target: "ReceiveScreenSharing",
            actions: assign({
              stream: ({ event }) => event.data.stream,
            }),
          },
        },
      },
      StartingScreenShare: {
        invoke: {
          id: "publishScreenShare",
          src: "publishScreenShare",
          input: ({ context, self }) => {
            return { session: context.session, parent: self };
          },
          onDone: {
            target: "Active",
            actions: assign({
              screensharingPublisher: ({ event }) => event.output,
            }),
          },
        },
        on: {
          "lugia.screenSharingStopped": {
            target: "Inactive",
            actions: "destroyScreenShare",
          },
          "conference.localScreensharingStreamReady": {
            actions: ["STORE_setScreenSharing"],
          },
        },
      },
      Active: {
        on: {
          "lugia.screenSharingStopped": {
            target: "Inactive",
            actions: ["destroyScreenShare", "STORE_resetScreenSharing"],
          },
          "conference.localScreensharingStreamDestroyed": {
            target: "Inactive",
            actions: [
              "destroyScreenShare",
              "STORE_resetScreenSharing",
              "resetPublisher",
            ],
          },
          "conference.remoteScreensharingStreamDestroyed": {
            target: "Inactive",
            actions: ["STORE_resetScreenSharing", "resetSubscriber"],
          },
        },
      },
      ReceiveScreenSharing: {
        invoke: {
          id: "subscribeToScreenShare",
          src: "subscribeToScreenShare",
          input: ({ context, self }) => {
            return {
              session: context.session,
              parent: self,
              stream: context.stream,
            };
          },
          onDone: {
            target: "Active",
            actions: assign({
              screensharingSubscriber: ({ event }) => event.output,
            }),
          },
        },
        on: {
          "conference.remoteScreensharingStreamReady": {
            actions: ["STORE_setScreenSharing"],
          },
          "conference.remoteScreensharingStreamDestroyed": {
            target: "Inactive",
            actions: ["STORE_resetScreenSharing", "resetSubscriber"],
          },
        },
      },
    },
  },
  {
    actions: {
      destroyScreenShare: ({ context }) => {
        context.screensharingPublisher?.destroy();
      },
      resetPublisher: assign({
        screensharingPublisher: null,
      }),
      resetSubscriber: assign({
        screensharingSubscriber: null,
      }),
      STORE_resetScreenSharing: () => {
        const videoConferenceStore = useStore.getState().videoConference;
        videoConferenceStore.setActiveScreenShare(null);
        videoConferenceStore.setScreenSharer(null);
      },
      STORE_setScreenSharing: ({ event }) => {
        if (event.type === "conference.localScreensharingStreamReady") {
          const videoConferenceStore = useStore.getState().videoConference;
          const self = videoConferenceStore.self();
          const videoElement = event.data.element as HTMLVideoElement;
          videoConferenceStore.setActiveScreenShare(
            videoElement.srcObject as MediaStream
          );
          logInfo("VOICE/VIDEO", `Screenshare started for ${self?.userId}`);
          videoConferenceStore.setScreenSharer(self);
        } else if (event.type === "conference.remoteScreensharingStreamReady") {
          const videoConferenceStore = useStore.getState().videoConference;
          videoConferenceStore.setActiveScreenShare(event.data);
        }
      },
    },
    actors: {
      subscribeToScreenShare: fromPromise<
        Subscriber,
        { session: Session; parent: ActorRef<any, any>; stream: Stream }
      >(async ({ input }) => {
        const { session, parent, stream } = input;
        const subscriber = session?.subscribe(stream, undefined, {
          insertDefaultUI: false,
        });
        await new Promise((resolve) => {
          subscriber?.on("videoElementCreated", (event) => {
            const videoElement = event.element as HTMLVideoElement;
            const videoStream = videoElement.srcObject as MediaStream;
            parent.send({
              type: "conference.remoteScreensharingStreamReady",
              data: videoStream,
            });
            videoElement.onplay = () => {
              if (videoStream !== videoElement.srcObject) {
                parent.send({
                  type: "conference.remoteScreensharingStreamReady",
                  data: videoElement.srcObject,
                });
              }
            };
            resolve(videoStream);
          });
        });

        return subscriber;
      }),
      publishScreenShare: fromPromise<
        Publisher,
        { session: Session; parent: any }
      >(async ({ input }) => {
        const { session, parent } = input;
        const screenSharePublisher = initPublisher(undefined, {
          videoSource: "screen",
          publishAudio: true,
          insertDefaultUI: false,
        });
        screenSharePublisher.on("destroyed", () => {
          parent.send({ type: "conference.localScreensharingStreamDestroyed" });
        });
        logInfo("VOICE/VIDEO", "Starting screen share");
        session?.publish(screenSharePublisher);
        await new Promise((resolve) => {
          screenSharePublisher.on("videoElementCreated", (event) => {
            parent.send({
              type: "conference.localScreensharingStreamReady",
              data: event,
            });
            const videoElement = event.element as HTMLVideoElement;
            const mediaStream = videoElement.srcObject as MediaStream;
            mediaStream.getVideoTracks().forEach((track) => {
              track.onended = () => {
                parent.send({
                  type: "conference.localScreensharingStreamDestroyed",
                  data: event,
                });
              };
            });
            resolve(screenSharePublisher);
          });
        });
        return screenSharePublisher;
      }),
    },
  }
);

export const screenShareMachineActor = createActor(screenShareMachine, {
  systemId: "vonageScreenShareMachine",
});

export default screenShareMachine;
