import { SvgProps } from "../../types/svg";

const CircleInfoIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 2C5.58125 2 2 5.58125 2 10C2 14.4187 5.58125 18 10 18C14.4187 18 18 14.4187 18 10C18 5.58125 14.4187 2 10 2ZM10 17C6.14062 17 3 13.8594 3 10C3 6.14062 6.14062 3 10 3C13.8594 3 17 6.14062 17 10C17 13.8594 13.8594 17 10 17ZM10 7.75C10.4141 7.75 10.75 7.41437 10.75 7C10.75 6.58594 10.4141 6.25 10 6.25C9.58594 6.25 9.25 6.58437 9.25 7C9.25 7.41563 9.58437 7.75 10 7.75ZM11.5 13H10.5V9.5C10.5 9.225 10.275 9 10 9H9C8.725 9 8.5 9.225 8.5 9.5C8.5 9.775 8.725 10 9 10H9.5V13H8.5C8.225 13 8 13.225 8 13.5C8 13.775 8.225 14 8.5 14H11.5C11.7761 14 12 13.7761 12 13.5C12 13.225 11.775 13 11.5 13Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CircleInfoIcon;
