import { SvgProps } from "../../types/svg";

const MapIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.725 3.90615C6.81667 3.87033 6.89167 3.86514 7.01111 3.89194L13.0861 5.60065L17.0861 4.02619C17.525 3.85393 18 4.17139 18 4.63569V13.7898C18 14.0578 17.8333 14.2984 17.5806 14.3996L13.275 16.0949C13.1833 16.1305 13.0833 16.1359 12.9889 16.1086L6.91389 14.3996L2.91417 15.9746C2.47639 16.1469 2 15.8297 2 15.3648V6.21014C2 5.94217 2.16597 5.701 2.41917 5.60119L6.725 3.90615ZM2.88889 15.0422L6.44444 13.6422V4.95862L2.88889 6.3578V15.0422ZM12.6667 6.39334L7.33333 4.89244V13.6066L12.6667 15.1078V6.39334ZM13.5556 15.0422L17.1111 13.6422V4.95862L13.5556 6.3578V15.0422Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MapIcon;
