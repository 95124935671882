import { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { useCurrentExperienceLogo } from "../../../api/gyaradosHooks";
import { getImageUrl } from "../../../api/gyaradosUtils";
import IconMenu from "../../../components-ui/molecules/dynamicButtonMenu/DynamicButtonMenuDrawer";
import { LOGO_MAX_HEIGHT, LOGO_MAX_WIDTH } from "../../../constants/configs";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { sendGameMessage } from "../../../core/gameConnection/webrtc/webRtcMessageHandlers";
import useDynamicButtonMenuEmojis from "../../../hooks/useDynamicButtonMenuEmojis";
import { logError } from "../../../lib/logger";
import { useStore } from "../../../store/store";
import { MediaCaptureAction } from "../MediaCapture.slice";
import { MediaCaptureService } from "../lib/MediaCapture.service";
import PhotoCaptureUi from "./PhotoCapture.ui";

type Props = {
  autocapture: MediaCaptureAction | undefined;
  setAutocapture: (autocapture: MediaCaptureAction | undefined) => void;
};

const PhotoCaptureLogic: React.FC<Props> = ({
  autocapture,
  setAutocapture,
}) => {
  const { environment } = useEnvironmentContext();
  const visible = useStore((s) => s.layout.panels.photo.visible);
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const switchTab = useStore((s) => s.layout.panels.photo.options?.switchTab);
  const setPanelExtraOptions = useStore(
    (state) => state.layout.setPanelExtraOptions
  );
  const setMediaShareState = useStore(
    (state) => state.mediaCapture.setMediaShareState
  );
  const theme = useTheme();
  const photoBlob = useRef<Blob | undefined>(undefined);
  const [error, setError] = useState(null as null | Error);
  const logo = useCurrentExperienceLogo();
  const logoUrl = getImageUrl(logo, LOGO_MAX_WIDTH, LOGO_MAX_HEIGHT);
  const availableEmojis = useDynamicButtonMenuEmojis();
  const [captureIsProcessing, setCaptureIsProcessing] = useState(false);
  const [hideUi, setHideUi] = useState(switchTab);
  const settingsPanelVisibility = useStore(
    (s) => s.layout.panels.settings.visible
  );
  const settingsPanelSubpanel = useStore(
    (s) => s.layout.panels.settings.subpage
  );

  const wasSettingsPanelOpen = useMemo(
    () => Boolean(settingsPanelVisibility),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const settingsSubpanel = useMemo(() => settingsPanelSubpanel, []);

  // LEGACY: Have to keep for old builds to go into selfie mode
  useEffect(() => {
    if (visible && !autocapture)
      sendGameMessage({
        type: "PhotoCaptureEvent",
        event: "open",
      });
  }, [visible, autocapture]);

  const closeHandler = () => {
    sendGameMessage({
      type: "PhotoCaptureEvent",
      event: photoBlob.current ? "close" : "cancel",
    });
    setPanelExtraOptions("videoCapture", {
      switchTab: false,
    });
    closePanel("photo", { options: { switchTab: false } });
  };

  const changeTabHandler = () => {
    sendGameMessage({
      type: "PhotoCaptureEvent",
      event: photoBlob.current ? "close" : "cancel",
    });
    closePanel("photo", { options: { switchTab: true } });
    openPanel("videoCapture", { options: { switchTab: true } });
  };

  const captureHandler = async () => {
    if (captureIsProcessing) return;

    window.analytics?.track("click", {
      type: "click",
      name: "photo_capture",
    });

    setCaptureIsProcessing(true);

    const videoElement = document.getElementById(
      "streamingVideo"
    ) as HTMLVideoElement;
    if (videoElement?.tagName !== "VIDEO") {
      const error = new Error("We couldn't find the video to screenshot.");
      logError("GENERIC", error);
      setError(error);
      return;
    }

    photoBlob.current = undefined;
    try {
      photoBlob.current = await MediaCaptureService.getPhoto(
        videoElement,
        logoUrl,
        false,
        parseInt(theme.topLogoHeight, 10)
      );
      setMediaShareState({
        media: photoBlob.current,
        previousPanel: autocapture ? undefined : "photo",
      });
      closeHandler();
      openPanel("mediaShare");
    } catch (error: unknown) {
      logError("GENERIC", error);
      setError(error as Error);
    } finally {
      setAutocapture(undefined);
      setCaptureIsProcessing(false);
    }
  };

  useEffect(() => {
    if (autocapture) {
      captureHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocapture]);

  // We fake the Hint and Grid fade when switching panels
  useEffect(() => {
    switchTab && setHideUi(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Remove after refactoring the panels rendering system.
  // this fix was introduced to remove the settings panel, since
  // its configuration is ghost.
  useEffect(() => {
    if (wasSettingsPanelOpen && visible) {
      closePanel("settings");
    }

    return () => {
      if (wasSettingsPanelOpen && !visible) {
        if (settingsSubpanel) {
          openPanel(settingsSubpanel);
        } else {
          openPanel("settings");
        }
      }
    };
  }, [closePanel, openPanel, visible, settingsSubpanel, wasSettingsPanelOpen]);

  return (
    !autocapture && (
      <PhotoCaptureUi
        error={error?.message}
        logoUrl={logoUrl}
        onCapture={captureHandler}
        onClose={closeHandler}
        onChangeTab={changeTabHandler}
        nrOfReactions={availableEmojis.length}
        reactions={
          <IconMenu
            isOpen
            focused
            standAlone
            items={availableEmojis}
            direction={"column"}
          />
        }
        hideUi={hideUi}
        hideTabs={!environment.allowVideoCapture}
      />
    )
  );
};

export default PhotoCaptureLogic;
