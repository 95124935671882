import { SvgProps } from "../../types/svg";

const PlusIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3571 10C17.3571 10.275 17.1 10.5 16.7857 10.5H3.07143C2.75557 10.5 2.5 10.2766 2.5 10.0003C2.5 9.725 2.75571 9.5 3.07143 9.5H16.7857C17.1 9.5 17.3571 9.725 17.3571 10Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PlusIcon;
