import { SvgProps } from "../../types/svg";

const ChampagneGlassIcon: React.FC<SvgProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_15_2662)">
      <path
        d="M6.2219 14.5H4.97221V10.8656C6.87034 10.3891 8.19721 8.61687 7.94034 6.63125L7.0969 0.853125C7.05315 0.367187 6.63127 0 6.13127 0H1.86784C1.39596 0 0.946274 0.367187 0.875961 0.855625L0.0323052 6.63438C-0.225445 8.61875 1.10199 10.3937 3.00012 10.8687V14.5H1.75034C1.05909 14.5 0.500649 15.0588 0.500649 15.75C0.500649 15.8867 0.613899 16 0.750618 16H7.22249C7.35918 16 7.47243 15.8867 7.47243 15.75C7.4719 15.0594 6.94065 14.5 6.2219 14.5ZM1.93159 4L2.29752 1.5H5.67565L6.04159 4H1.93159Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
  </svg>
);
export default ChampagneGlassIcon;
