import styled from "styled-components";
import Transition from "../../../components-ui/atoms/Transition";
import { BaseLayout } from "../../../core/layout/PanelsLayout";
import { useStore } from "../../../store/store";
import QuestLogic from "./Quest.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0px;
  top: 0px;
`;

const QuestPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.quest.visible);
  const slug = useStore((s) => s.layout.panels.quest.slug);

  return (
    <Layout id="panel-quest">
      <Transition
        height="100%"
        watch={[visible]}
        delay={200}
        // slideIn={isSmallScreen && !isLandscape ? "down" : undefined}
        // slideOut={isSmallScreen && !isLandscape ? "down" : undefined}
        justify="flex-end"
        align="center"
      >
        {visible && <QuestLogic slug={slug} />}
      </Transition>
    </Layout>
  );
};

export default QuestPanel;
