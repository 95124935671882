import { useEffect, useState } from "react";
import { isTouch } from "../../constants/flags";
import { useEnvironmentContext } from "../../core/EnvironmentFetcher.core";
import { useStore } from "../../store/store";
import { useCurrentTextChatChannel } from "../panels/social/subpages/chat/textChatHooks";
import ActionBarPanelUi from "./ActionBarPanelUi";

const ActionBarPanelLogic: React.FC = () => {
  const [focused, setFocused] = useState(false);

  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const subElements = useStore((s) => s.layout.panels.actionBar.subElements);
  const socialPanelVisible = useStore((s) => s.layout.panels.social.visible);

  const photoPanelVisible = useStore((s) => s.layout.panels.photo.visible);
  const settingsPanelVisible = useStore(
    (s) => s.layout.panels.settings.visible
  );
  const mapPanelVisible = useStore((s) => s.layout.panels.map.visible);
  const {
    environment: {
      disableChat,
      allowPhotoCapture,
      allowVideoCapture,
      hasMapNavigation,
      movements,
      quests,
    },
  } = useEnvironmentContext();
  const { type, unseenMessagesCount } = useCurrentTextChatChannel();
  const isSmartChat = type === "smartChat";
  const hasMovements =
    movements && Object.values(movements).find((enabled) => enabled);
  const hasMediaCapture = allowPhotoCapture || allowVideoCapture;

  useEffect(() => {
    if (isTouch) return;
    const handler = (e: MouseEvent) => {
      if (!focused && window.innerHeight - e.clientY < 200) setFocused(true);
      else if (window.innerHeight - e.clientY > 200) setFocused(false);
    };
    window.addEventListener("mousemove", handler);
    return () => window.removeEventListener("mousemove", handler);
  }, [focused]);

  useEffect(() => {
    if (isTouch) return;
    const handler = () => {
      setFocused(false);
    };
    document.addEventListener("mouseleave", handler);
    return () => document.removeEventListener("mouseleave", handler);
  }, [focused]);

  return (
    <ActionBarPanelUi
      focused={focused}
      showSocialButton={
        (!disableChat || isSmartChat) &&
        subElements?.includes("actionBar/social")
      }
      onSocialClick={() => {
        socialPanelVisible ? closePanel("social") : openPanel("social");
      }}
      showEmojisButton={
        subElements?.includes("actionBar/emojis") &&
        !subElements?.includes("actionBar/reactionsBar")
      }
      showMovementsButton={
        hasMovements && subElements?.includes("actionBar/movements")
      }
      showMapButton={hasMapNavigation && subElements?.includes("actionBar/map")}
      onMapClick={() => {
        mapPanelVisible ? closePanel("map") : openPanel("map");
      }}
      showPhotoButton={
        hasMediaCapture && subElements?.includes("actionBar/photo")
      }
      onPhotoClick={() => {
        photoPanelVisible ? closePanel("photo") : openPanel("photo");
      }}
      showSettingsButton={subElements?.includes("actionBar/settings")}
      onSettingsClick={() => {
        settingsPanelVisible ? closePanel("settings") : openPanel("settings");
      }}
      //TODO: Add a Button in the CMS
      showQuestButton={quests.length > 0}
      onQuestClick={() => {
        openPanel("quest");
      }}
      unseenMessagesCount={unseenMessagesCount || 0}
    />
  );
};

export default ActionBarPanelLogic;
