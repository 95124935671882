import { logWarn } from "../../lib/logger";
import { SliceCreator } from "../../store/store";
import { FromGameMessage } from "../gameConnection/messages/fromGameMessages";
import { sendGameMessage } from "../gameConnection/webrtc/webRtcMessageHandlers";
import {
  VideoConferenceParticipant,
  VideoConferenceState,
  VideoConferenceStateType,
} from "./VideoConferenceAdapter";

const sliceName = "videoConferenceSlice";

export const VideoConferenceStatusText = {
  [VideoConferenceState.DISCONNECTED]: "Disconnected",
  [VideoConferenceState.DISCONNECTING]: "Disconnecting",
  [VideoConferenceState.PERMISSION_SETUP]: "Permission Setup",
  [VideoConferenceState.NO_DEVICES]: "No Devices",
  [VideoConferenceState.DEVICE_SETUP]: "Device Setup",
  [VideoConferenceState.CONFERENCE_PREVIEW]: "Conference Preview",
  [VideoConferenceState.BLOCKED_PERMISSIONS]: "Blocked Permissions",
  [VideoConferenceState.SESSION_INITIALIZING]: "Initializing Session",
  [VideoConferenceState.SESSION_INITIALIZED]: "Session Initialized",
  [VideoConferenceState.JOINING]: "Joining",
  [VideoConferenceState.JOINED]: "Joined",
  [VideoConferenceState.ERROR]: "Error",
};

export interface VideoConferenceState {
  state: VideoConferenceStateType;
  activeParticipants: VideoConferenceParticipant[];
  activeScreenShare: MediaStream | null;
  self: () => VideoConferenceParticipant | null;
  nrOfAllParticipants: () => number;
  nrOfPublishingParticipants: () => number;
  screenSharer: VideoConferenceParticipant | null;
  presenterPlayerKey: string | null;
  isConferenceInitialized: () => boolean;
  canShareScreen: () => boolean;
  amIScreenSharer: () => boolean;
  amIPresenter: () => boolean;
  participants: { [key: string]: VideoConferenceParticipant };
  setScreenSharer: (screenSharer: VideoConferenceParticipant | null) => void;
  setPresenterPlayerKey: (presenter: string | null) => void;
  setActiveParticipants: (participants: VideoConferenceParticipant[]) => void;
  setUpdateParticipantStatus: (
    participantExternalId: string,
    status: Partial<VideoConferenceParticipant>
  ) => void;
  cleanConferenceState: () => void;
  isParticipantPresenting: (participant: VideoConferenceParticipant) => boolean;
  setActiveScreenShare: (stream: MediaStream | null) => void;
  setState: (state: VideoConferenceStateType) => void;
  setConferenceLeave: () => void;
  setParticipantJoin: (participant: VideoConferenceParticipant) => void;
  setParticipantLeave: (participantExternalId: string) => void;
  setUpdateActiveParticipantVideo: (activeParticipants: string[]) => void;
  dispatchVideoConferenceMessage: (message: FromGameMessage) => void;
  dispatchVoiceChatUserStateChangedEvent: (params: {
    participant: VideoConferenceParticipant;
    currentScreenSharer?: VideoConferenceParticipant | null;
  }) => void;
  setParticipantSpeaking: (
    participantExternalId: string,
    isSpeaking: boolean
  ) => void;
  setScreenSharing: (presenterId: string | null) => void;
}

type State = {
  videoConference: VideoConferenceState;
};

export const createVideoConferenceSlice: SliceCreator<State> = (set, get) => ({
  videoConference: {
    activeScreenShare: null,
    screenSharer: null,
    presenterPlayerKey: null,
    activeParticipants: [],
    state: VideoConferenceState.DISCONNECTED,
    participants: {},
    nrOfPublishingParticipants: () =>
      Object.values(get().videoConference.participants).filter(
        (participant) => participant.isPublishing
      ).length,
    nrOfAllParticipants: () =>
      Object.values(get().videoConference.participants).length,
    isConferenceInitialized: () =>
      get().videoConference.state === VideoConferenceState.JOINED,
    self: () => {
      const state = get();
      return (
        Object.values(state.videoConference.participants).find(
          (participant) => participant.isLocal
        ) || null
      );
    },
    setUpdateActiveParticipantVideo: (userId: string[]) =>
      set((state) => {
        Object.values(state.videoConference.participants).forEach(
          (participant) => {
            participant.isVideoHidden = !userId.includes(participant.userId);
          }
        );
      }),
    amIPresenter: () => {
      const state = get();

      return (
        state.gameConnection.getMyPlayerKey() ===
        state.videoConference.presenterPlayerKey
      );
    },
    setPresenterPlayerKey: (presenterKey: string | null) =>
      set((state) => {
        state.videoConference.presenterPlayerKey = presenterKey;
        if (!presenterKey) {
          return state;
        }
        const presenterUserId =
          get().gameConnection.getUserIdFromPlayerKey(presenterKey);
        if (!presenterUserId) {
          logWarn(
            "VOICE/VIDEO",
            "Could not find full profile for participant, cannot send message to game",
            presenterKey
          );
          return state;
        }
        const participant = state.videoConference.participants[presenterUserId];
        sendGameMessage({
          type: "VoiceChatUserStateChanged",
          userId: presenterKey,
          isSpeaking: participant.isSpeaking,
          isMuted: !participant.isAudioOn,
          isPresenter: true,
          isVideoSharing:
            state.videoConference.screenSharer?.userId === presenterUserId,
        });
      }),
    amIScreenSharer: () => {
      const state = get();
      return (
        state.videoConference.screenSharer?.id ===
        state.videoConference.self()?.id
      );
    },
    canShareScreen: () => {
      const state = get();
      return !state.videoConference.screenSharer?.id;
    },
    cleanConferenceState: () =>
      set((state) => {
        state.videoConference.participants = {};
        state.videoConference.screenSharer = null;
        state.videoConference.activeScreenShare = null;
      }),
    setActiveParticipants: (participants: VideoConferenceParticipant[]) =>
      set((state) => {
        state.videoConference.activeParticipants = participants;
      }),
    setScreenSharer: (screenSharer: VideoConferenceParticipant | null) =>
      set((state) => {
        state.videoConference.screenSharer = screenSharer;
        if (screenSharer) {
          state.videoConference.dispatchVoiceChatUserStateChangedEvent({
            participant: screenSharer,
            currentScreenSharer: state.videoConference.screenSharer,
          });
        }
      }),
    setUpdateParticipantStatus: (
      participantExternalId: string,
      status: Partial<VideoConferenceParticipant>
    ) =>
      set(
        (state) => {
          if (!state.videoConference.participants[participantExternalId]) {
            return state;
          }
          state.videoConference.participants[participantExternalId] = {
            ...state.videoConference.participants[participantExternalId],
            ...status,
          };

          const updatedParticipant =
            state.videoConference.participants[participantExternalId];
          state.videoConference.dispatchVoiceChatUserStateChangedEvent({
            participant: updatedParticipant,
            currentScreenSharer: state.videoConference.screenSharer,
          });
        },
        false,
        sliceName + "/setUpdateParticipantStatus"
      ),

    setActiveScreenShare: (stream: MediaStream | null) =>
      set(
        (state) => {
          state.videoConference.activeScreenShare = stream;
        },
        false,
        sliceName + "/setActiveScreenShare"
      ),
    setState: (conferenceStatus: VideoConferenceStateType) =>
      set(
        (state) => {
          state.videoConference.state = conferenceStatus;
        },
        false,
        sliceName + "/setState"
      ),
    setConferenceLeave: () =>
      set((state) => {
        state.videoConference.participants = {};
      }),
    setParticipantJoin: (participant: VideoConferenceParticipant) =>
      set((state) => {
        const userId = participant.userId;
        if (userId) {
          state.videoConference.participants[userId] = participant;
        }
      }),
    setParticipantLeave: (participantExternalId: string) =>
      set((state) => {
        delete state.videoConference.participants[participantExternalId];
      }),

    setParticipantSpeaking: (
      participantExternalId: string,
      isSpeaking: boolean
    ) =>
      set((state) => {
        const participantsStatus =
          state.videoConference.participants[participantExternalId];
        if (!participantsStatus) {
          return state;
        }
        participantsStatus.isSpeaking = isSpeaking;
        if (isSpeaking) participantsStatus.lastActive = Date.now();
        const updatedParticipant =
          state.videoConference.participants[participantExternalId];
        state.videoConference.dispatchVoiceChatUserStateChangedEvent({
          participant: updatedParticipant,
          currentScreenSharer: state.videoConference.screenSharer,
        });
      }),
    isParticipantPresenting: (participant: VideoConferenceParticipant) => {
      const participantPlayerKey = get().gameConnection.getPlayerKeyFromUserId(
        participant.userId
      );
      if (!participantPlayerKey) {
        logWarn(
          "VOICE/VIDEO",
          "Could not find full profile for participant, cannot send message to game",
          participant
        );
        return false;
      }
      return get().videoConference.presenterPlayerKey === participantPlayerKey;
    },

    dispatchVoiceChatUserStateChangedEvent: ({
      participant,
      currentScreenSharer,
    }) => {
      const isVideoSharing = currentScreenSharer?.userId === participant.userId;
      const isPresenting =
        get().videoConference.isParticipantPresenting(participant);
      const playerKey = get().gameConnection.getPlayerKeyFromUserId(
        participant.userId
      );
      if (!playerKey) {
        logWarn(
          "VOICE/VIDEO",
          "Could not find full profile for participant, cannot send message to game",
          participant
        );
        return;
      }
      sendGameMessage({
        type: "VoiceChatUserStateChanged",
        userId: playerKey,
        isSpeaking: participant.isSpeaking,
        isMuted: !participant.isAudioOn,
        isPresenter: isPresenting,
        isVideoSharing,
      });
    },

    setScreenSharing: (presenterId: string | null) =>
      set((state) => {
        let screenSharer;
        if (presenterId) {
          screenSharer = state.videoConference.participants[presenterId];
          if (!screenSharer) return state;
          if (state.videoConference.screenSharer?.userId == presenterId) return;
          state.videoConference.screenSharer = screenSharer;
        } else {
          screenSharer = state.videoConference.screenSharer;
          state.videoConference.screenSharer = null;
          state.videoConference.activeScreenShare = null;
        }

        const playerKey =
          presenterId &&
          get().gameConnection.getPlayerKeyFromUserId(presenterId);
        if (!playerKey) {
          logWarn(
            "VOICE/VIDEO",
            "Could not find full profile for participant, cannot send message to game",
            screenSharer
          );
          return;
        }
        sendGameMessage({
          type: "VoiceChatUserStateChanged",
          userId: playerKey,
          isSpeaking: Boolean(screenSharer?.isSpeaking),
          isMuted: !screenSharer?.isAudioOn,
          isVideoSharing: Boolean(presenterId),
          isPresenter: state.videoConference.presenterPlayerKey === playerKey,
        });
      }),

    dispatchVideoConferenceMessage: (message) => {
      set((state) => {
        if (message.type === "ScreenSharing") {
          let presenter;
          const screenSharerId = message.isSharing
            ? message.participantId
            : undefined;
          if (screenSharerId) {
            presenter = state.videoConference.participants[screenSharerId];
            if (!presenter) return state;
            if (state.videoConference.screenSharer?.userId == screenSharerId)
              return;
            state.videoConference.screenSharer = presenter;
          } else {
            state.videoConference.screenSharer = null;
            state.videoConference.activeScreenShare = null;
          }
          const playerKey =
            screenSharerId &&
            get().gameConnection.getPlayerKeyFromUserId(screenSharerId);
          if (!playerKey) {
            logWarn(
              "VOICE/VIDEO",
              "Could not find full profile for participant, cannot send message to game",
              presenter
            );
            return;
          }
          sendGameMessage({
            type: "VoiceChatUserStateChanged",
            userId: playerKey,
            isSpeaking: Boolean(presenter?.isSpeaking),
            isMuted: !presenter?.isAudioOn,
            isPresenter: state.videoConference.presenterPlayerKey === playerKey,
            isVideoSharing: Boolean(screenSharerId),
          });
        } else if (message.type === "SetIsPresenter") {
          if (!message.userId) {
            state.videoConference.presenterPlayerKey = null;
            const myPlayerKey = get().gameConnection.getMyPlayerKey();
            const self = state.videoConference.self();
            if (!myPlayerKey || !self) return state;
            sendGameMessage({
              type: "VoiceChatUserStateChanged",
              userId: myPlayerKey,
              isSpeaking: Boolean(self?.isSpeaking),
              isMuted: !self?.isAudioOn,
              isPresenter: false,
              isVideoSharing: get().videoConference.amIScreenSharer(),
            });
            return state;
          }
          const presenterUserId = get().gameConnection.getUserIdFromPlayerKey(
            message.userId
          );
          state.videoConference.presenterPlayerKey = message.userId;
          if (!presenterUserId) return state;

          const videoConferenceParticipant =
            state.videoConference.participants[presenterUserId];
          if (!videoConferenceParticipant) return state;
          const isScreenSharer =
            state.videoConference.screenSharer?.userId ===
            videoConferenceParticipant.userId;
          sendGameMessage({
            type: "VoiceChatUserStateChanged",
            userId: message.userId,
            isSpeaking: Boolean(videoConferenceParticipant?.isSpeaking),
            isMuted: !videoConferenceParticipant?.isAudioOn,
            isPresenter: true,
            isVideoSharing: isScreenSharer,
          });
        }
      });
    },
  },
});
