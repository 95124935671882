import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { steps } from "../../style/theme";
import { CheckboxWrapper } from "./Checkbox";
import { GenericInputStyles } from "./RadioButton";
import Typo from "./Typo";

const StyledWrapper = styled(CheckboxWrapper)`
  justify-content: space-between;
  margin-bottom: ${steps.spacing[1]};
  ${(p) =>
    !p.$checked &&
    css`
      background: transparent;
    `};
`;

const ListItemInput = styled.input`
  ${GenericInputStyles};
  border: none;
  &::before {
    content: "";
    inline-size: 14px;
    block-size: 16px;
    border: none;
    transform: scale(0);
    transition: 150ms transform ease-in-out;
  }
  &:checked {
    &::before {
      background-color: ${(p) => p.theme.colorBelow2};
      mask-mode: alpha;
      mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIDEiIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yNjc1XzEwMzYyKSI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xMy44NTMxIDMuMTQ2NzJDMTQuMDUgMy4zNDA0NyAxNC4wNSAzLjY1OTIyIDEzLjg1MzEgMy44NTI5N0w1LjM1MzEzIDEyLjM1M0M1LjE1OTM4IDEyLjU0OTggNC44NDA2MiAxMi41NDk4IDQuNjQ2ODcgMTIuMzUzTDAuMTQ2NDM3IDcuODUyOTdDLTAuMDQ4ODEyNSA3LjY1OTIyIC0wLjA0ODgxMjUgNy4zNDA0NyAwLjE0NjQzNyA3LjE0NjcyQzAuMzQxNTYzIDYuOTQ5ODQgMC42NTgxMjUgNi45NDk4NCAwLjg1MzQzNyA3LjE0NjcyTDUgMTEuMjkzNkwxMy4xNDY5IDMuMTQ2NzJDMTMuMzQwNiAyLjk1MTA5IDEzLjY1OTQgMi45NTEwOSAxMy44NTMxIDMuMTQ2NzJaIiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjk1Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjY3NV8xMDM2MiI+CjxyZWN0IHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K);
      transform: scale(1);
    }
  }
`;

export type ListItemProps = {
  name: string;
  label: string;
  checked: boolean;
  onChange?: (params: { name: string; checked: boolean }) => void;
  width?: React.CSSProperties["width"];
  style?: React.CSSProperties;
  value?: string;
};

const ListItem: React.FC<ListItemProps> = ({
  label,
  checked,
  name,
  onChange,
  style,
  width,
  value,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({ name, checked: e.target.checked });
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const _onClick = () => {
    setIsChecked((prev) => !prev);
    onChange?.({ name, checked: true });
  };

  return (
    <StyledWrapper
      $checked={isChecked}
      onClick={_onClick}
      $width={width}
      style={style}
    >
      <Typo.Label>{label}</Typo.Label>
      <ListItemInput
        value={value ?? label}
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={_onChange}
      />
    </StyledWrapper>
  );
};

export default ListItem;
