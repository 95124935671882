import { styled } from "styled-components";
import Transition from "../../../components-ui/atoms/Transition";
import { isInstagramAgent, isMobile } from "../../../constants/flags";
import { useEnvironmentContext } from "../../../core/EnvironmentFetcher.core";
import { BaseLayout } from "../../../core/layout/PanelsLayout";
import { useIsSmallScreen } from "../../../hooks/ui";
import { useStore } from "../../../store/store";
import ForceLandscapeLogic from "./ForceLandscape.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ForceLandscapePanel: React.FC = () => {
  const isSmallScreen = useIsSmallScreen();
  const {
    environment: { disablePortrait },
  } = useEnvironmentContext();
  const currentStep = useStore((s) => s.userFlow.currentStep);

  const visible =
    isMobile &&
    isSmallScreen &&
    disablePortrait &&
    (currentStep == "login:start-action" ||
      currentStep == "experience:ready") &&
    !isInstagramAgent;

  return (
    <Layout id="panel-forceLandscape">
      <Transition watch={[visible]} height="100%">
        {visible && <ForceLandscapeLogic />}
      </Transition>
    </Layout>
  );
};

export default ForceLandscapePanel;
