import styled, { css } from "styled-components";
import tinycolor from "tinycolor2";
import Button from "../../../../../../components-ui/atoms/Button";
import Icon from "../../../../../../components-ui/atoms/Icon";
import Space from "../../../../../../components-ui/atoms/Space";
import Typo from "../../../../../../components-ui/atoms/Typo";
import { useText } from "../../../../../../core/i18n/i18nHooks";
import { steps } from "../../../../../../style/theme";

const DisconnectedWrapper = styled.div`
  display: flex;
  border-radius: ${(p) => p.theme.radiusTiny};
  padding: 4px 8px;
  background-color: ${(p) => p.theme.colorAbove0};
`;

const Wrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column: span 3;
  ${(p) =>
    p.$disabled &&
    css`
      pointer-events: none;
      user-select: none;
      opacity: 0.7;
      * {
        user-select: none;
        pointer-events: none;
      }
    `}
`;

type Props = {
  onTeleport: () => void;
  disabled: boolean;
  style?: React.CSSProperties;
  color?: string;
};

const TeleportDrawer: React.FC<Props> = ({
  onTeleport,
  style,
  color,
  disabled = false,
}) => {
  const t = useText();
  let content;
  if (disabled) {
    content = (
      <DisconnectedWrapper>
        <Typo.Label
          color={tinycolor(color).setAlpha(steps.alpha.a60).toRgbString()}
        >
          {t("player_no_longer_in_game")}
        </Typo.Label>
      </DisconnectedWrapper>
    );
  } else {
    content = (
      <>
        <Button.Primary circular onClick={onTeleport}>
          <Icon.TeleportTo inheritColor size="20px" />
        </Button.Primary>
        <Space w={3} />
        <Typo.Label color={color}>{t("player_teleport")}</Typo.Label>
      </>
    );
  }
  return (
    <Wrapper style={style} $disabled={disabled}>
      {content}
    </Wrapper>
  );
};

export default TeleportDrawer;
