import Space from "../../../../../../components-ui/atoms/Space";
import ButtonsSchema from "./ButtonsSchema";
import KeysSchema from "./KeysSchema";

const ControlsDefaultUiNormal: React.FC = () => {
  return (
    <>
      <ButtonsSchema />
      <Space h={6} />
      <KeysSchema />
      <Space h={5} />
    </>
  );
};

export default ControlsDefaultUiNormal;
