import styled from "styled-components";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import { useText } from "../../../core/i18n/i18nHooks";
import { steps } from "../../../style/theme";
import { Dot, ThreeDots } from "./avatarCircle/TitleBreakoutPlayerList";
import { TITLE_HEIGHT } from "./lib/videoAvatarsConstants";

const NoNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoNameAvatarText = () => {
  const t = useText();

  return (
    <NoNameWrapper>
      <Typo.Note
        fontFamily="Manrope"
        title={t("player_entering")}
        size={steps.font.f10.size}
        noWrap
        lineHeight={steps.font.f20.lineHight}
        style={{
          width: "45px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontStyle: "italic",
          textOverflow: "ellipsis",
          overflow: "hidden",
          pointerEvents: "none",
          height: TITLE_HEIGHT,
        }}
      >
        {t("player_entering")}
      </Typo.Note>
      <Space w={1} />
      <ThreeDots>
        <Dot />
      </ThreeDots>
    </NoNameWrapper>
  );
};

export default NoNameAvatarText;
