import { css, styled } from "styled-components";
import { getImageUrl } from "../../../../api/gyaradosUtils";
import Column from "../../../../components-ui/atoms/Column";
import HeaderBadge from "../../../../components-ui/atoms/HeaderBadge";
import Icon from "../../../../components-ui/atoms/Icon";
import Image from "../../../../components-ui/atoms/Image";
import Space from "../../../../components-ui/atoms/Space";
import Tag from "../../../../components-ui/atoms/Tag";
import { useText } from "../../../../core/i18n/i18nHooks";
import { useIsLandscape } from "../../../../hooks/ui";

const TagWrapper = styled.div<{ $isLandscape: boolean }>`
  position: absolute;
  top: 24px;
  ${(p) =>
    p.$isLandscape
      ? css`
          left: max(var(--safe-margin-left), 0px);
        `
      : css`
          left: 24px;
        `}
  z-index: 1;
`;

type Props = {
  image?: string;
  isCurrentLocation?: boolean;
};

const MapCardImage: React.FC<Props> = ({ image, isCurrentLocation }) => {
  const t = useText();
  const isLandscape = useIsLandscape();

  return (
    <>
      {image ? (
        <Image
          src={getImageUrl(image, 700, 700) ?? ""}
          width="100%"
          height="100%"
          cover
          styleOverrides={{ borderRadius: "0px" }}
        />
      ) : (
        <Column height="100%" width="100%" align="center" justify="center">
          {!isLandscape && <Space h={8} w={8} />}
          <HeaderBadge>
            <Icon.SquareInfo inheritColor size="40px" />
          </HeaderBadge>
        </Column>
      )}
      {isCurrentLocation && (
        <TagWrapper $isLandscape={isLandscape}>
          <Tag
            text={t("map_user_location")}
            iconLeft={<Icon.Arrow size="15px" />}
          />
        </TagWrapper>
      )}
    </>
  );
};

export default MapCardImage;
