import { isMobile } from "../../constants/flags";
import {
  FromGameMessage,
  FromGameMessagesSchema,
} from "../../core/gameConnection/messages/fromGameMessages";
import { PanelName } from "../../core/layout/panelsTypes";
import { SliceCreator } from "../../store/store";

/**
 * Middleware to handle legacy implementation for loading levels Messages.
 */
const sliceName = "mediaCapture";

export type MediaCaptureAction =
  FromGameMessagesSchema["MediaCaptureAction"]["action"];

export type MediaShareState = {
  url?: string;
  media?: Blob;
  noClose?: boolean;
  previousPanel?: PanelName;
};

export type MediaCaptureState = {
  mediaCaptureAction: FromGameMessagesSchema["MediaCaptureAction"] | undefined;
  setMediaCaptureAction: (
    mediaCaptureAction: FromGameMessagesSchema["MediaCaptureAction"] | undefined
  ) => void;

  mediaShareState: MediaShareState | undefined;
  setMediaShareState: (mediaShareState: MediaShareState | undefined) => void;

  dispatchMediaCaptureMessage: (message: FromGameMessage) => void;
};

type State = {
  mediaCapture: MediaCaptureState;
};

export const createMediaCaptureSlice: SliceCreator<State> = (set) => ({
  mediaCapture: {
    mediaCaptureAction: undefined,
    setMediaCaptureAction: (mediaCaptureAction) =>
      set(
        (state) => {
          state.mediaCapture.mediaCaptureAction = mediaCaptureAction;
        },
        false,
        sliceName + "/setMediaCaptureAction"
      ),

    mediaShareState: undefined,
    setMediaShareState: (sharedMediaState: MediaShareState | undefined) =>
      set(
        (state) => {
          state.mediaCapture.mediaShareState = sharedMediaState;
        },
        false,
        sliceName + "/setMediaShareState"
      ),
    dispatchMediaCaptureMessage: (message: FromGameMessage) =>
      set(
        (state) => {
          switch (message.type) {
            case "MediaCaptureAction":
              state.mediaCapture.mediaCaptureAction = { ...message };
              break;
            case "ShareMedia":
              if (isMobile !== message.onMobile) break;
              state.mediaCapture.mediaShareState = {
                url: message.url,
                noClose: message.endSession,
              };
              state.layout.openPanel("mediaShare");
              break;
            default:
              break;
          }
        },
        false,
        sliceName + "/dispatchMediaCaptureMessage"
      ),
  },
});
