import { SvgProps } from "../../types/svg";

const ChatIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 2H4C2.89844 2 2 2.89844 2 3.97187V12.9438C2 14.0453 2.89844 14.9156 4 14.9156H7V17.54C7 17.8474 7.34437 18.0259 7.5975 17.8431L11.5006 14.9156H16.0006C17.1022 14.9156 18.0006 14.0172 18.0006 12.9438V3.97187C18 2.89844 17.1281 2 16 2ZM17.0281 13C17.0281 13.55 16.5781 14 16.0281 14H11.525C11.3085 14 11.0978 14.0703 10.9247 14.2002L8.02812 16.375V14.5C8.02812 14.2238 7.80425 14 7.52812 14H4.02813C3.47813 14 3.02813 13.55 3.02813 13V4C3.02813 3.45 3.47813 3 4.02813 3H16.0281C16.5781 3 17.0281 3.45 17.0281 4V13Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default ChatIcon;
