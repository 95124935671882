import { Form } from "@radix-ui/react-form";
import { styled } from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Checkbox from "../../../components-ui/atoms/Checkbox";
import Column from "../../../components-ui/atoms/Column";
import Glass from "../../../components-ui/atoms/Glass";
import InputText from "../../../components-ui/atoms/InputText";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import CloseButton from "../../../components-ui/molecules/CloseButton";
import { useText } from "../../../core/i18n/i18nHooks";

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 1rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  box-sizing: border-box;
  padding: 0 1.5rem;
  font-size: ${(p) => p.theme.t};
`;

type Props = {
  formRef: React.RefObject<HTMLFormElement>;
  isLoading: boolean;
  error: string | null;
  onClose?: () => void;
  onSubmit?: () => void;
};

const ReportUi: React.FC<Props> = ({
  formRef,
  isLoading,
  error,
  onSubmit,
  onClose,
}) => {
  const t = useText();

  return (
    <Spatial dragMotion rim glare mouseZoom>
      <Glass>
        <CloseButtonWrapper>
          <CloseButton onClick={onClose} />
        </CloseButtonWrapper>
        <Space h={4} />
        <Typo.Title size="1rem">{t("report_title")}</Typo.Title>
        <Space h={4} />
        {error && <Typo.Note color="red">{error || ""}</Typo.Note>}
        <Space h={2} />
        <Form ref={formRef}>
          <Column gap={2} align="stretch">
            <Checkbox
              name="issue"
              value="issue-video"
              label={t("report_issue_video")}
              checked={false}
            />
            <Checkbox
              name="issue"
              value="issue-input-lag"
              label={t("report_issue_input_lag")}
              checked={false}
            />
            <Checkbox
              value="issue-video-conference"
              name="issue"
              checked={false}
              label={t("report_issue_video_chat")}
            />
            <Checkbox
              name="issue"
              value="issue-video-freezes"
              label={t("report_issue_video_freezes")}
              checked={false}
            />
            <Checkbox
              name="issue"
              value="issue-low-framerate"
              label={t("report_issue_low_framerate")}
              checked={false}
            />
            <Checkbox
              name="issue"
              value="issue-bug"
              label={t("report_issue_bug")}
              checked={false}
            />
            <InputText name="comment" label={t("report_comment")} />
            <InputText name="rating" label={t("report_rate")} />
          </Column>
        </Form>
        <Footer>
          <Button.Primary
            buttonType="submit"
            onClick={onSubmit}
            disabled={isLoading}
          >
            {t("report_submit")}
          </Button.Primary>
        </Footer>
      </Glass>
    </Spatial>
  );
};

export default ReportUi;
