import styled, { css } from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Icon from "../../../components-ui/atoms/Icon";
import Typo from "../../../components-ui/atoms/Typo";
import { DivFlex, Props } from "./Hint.ui";
import { ContentContainer, IconContainer } from "./component/CommonHintUi";
import { HINT_POSITION_LOW, HINT_POSITION_NORMAL } from "./lib/hintConstants";

const Container = styled.div<{
  $visible?: boolean;
  $hasButton: boolean;
  $noAnimation?: boolean;
  $isLow?: boolean;
}>`
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: auto;
  background-color: ${(p) => p.theme.colorBelow0};
  padding: 2px ${(p) => (p.$hasButton ? "2px" : "16px")} 2px 2px;
  border: none;
  border-radius: ${(p) => p.theme.radiusBig};
  backdrop-filter: blur(${(p) => p.theme.blurLight});
  position: absolute;
  top: ${(p) => (p.$isLow ? HINT_POSITION_LOW : HINT_POSITION_NORMAL)}px;

  ${(p) =>
    !p.$noAnimation &&
    css`
      animation: hint-container-${p.$visible ? "enter" : "exit"} 600ms ease-out;
      opacity: ${p.$visible ? 1 : 0};
    `}

  @keyframes hint-container-enter {
    0% {
      transform: translate(0px, 20px);
    }

    50% {
      transform: translate(0px, -1px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }

  @keyframes hint-container-exit {
    0% {
      transform: translate(0px, 0px);
    }

    100% {
      transform: translate(0px, -20px);
    }
  }

  transition:
    opacity 0.3s,
    top 0.3s;
`;

const HintUiNormal: React.FC<Props> = ({
  visible = true,
  content,
  onClick,
  icon,
  noAnimation,
  isLow,
}) => {
  return (
    <Container
      $visible={visible}
      $hasButton={Boolean(onClick)}
      $noAnimation={noAnimation}
      $isLow={isLow}
    >
      <DivFlex>
        <IconContainer $visible={visible} $noAnimation={noAnimation}>
          {icon ?? <Icon.CircleInfo size="20px" inheritColor />}
        </IconContainer>
        <ContentContainer $visible={visible} $noAnimation={noAnimation}>
          <Typo.Body>{content}</Typo.Body>
        </ContentContainer>
        {onClick && (
          <Button.Secondary circular onClick={onClick}>
            <Icon.Chevron size="18px" rotate="90deg" />
          </Button.Secondary>
        )}
      </DivFlex>
    </Container>
  );
};

export default HintUiNormal;
