import { SvgProps } from "../../types/svg";

const PersonWalkingIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 3.75C9.5 2.78344 10.2844 2 11.25 2C12.2156 2 13 2.78344 13 3.75C13 4.71656 12.2156 5.5 11.25 5.5C10.2844 5.5 9.5 4.71656 9.5 3.75ZM11.25 4.5C11.6656 4.5 12 4.16406 12 3.75C12 3.33594 11.6656 3 11.25 3C10.8344 3 10.5 3.33594 10.5 3.75C10.5 4.16406 10.8344 4.5 11.25 4.5ZM8.79688 7.31875C8.75312 7.34062 8.70937 7.36562 8.6625 7.39062L7.89438 7.80937C7.28094 8.14375 6.83563 8.71875 6.66594 9.39687L6.485 10.1219C6.41813 10.3906 6.14656 10.5531 5.87875 10.4844C5.61094 10.4187 5.44812 10.1469 5.515 9.87813L5.69594 9.12813C5.93313 8.20625 6.55656 7.4 7.41531 6.93125L8.18438 6.5125C8.8 6.175 9.49063 5.97187 10.1906 5.97187C11.3375 5.97187 12.3594 6.72188 12.7469 7.8L13.4156 9.675L14.7781 10.5844C15.0062 10.7375 15.0687 11.0469 14.9156 11.2781C14.7625 11.5062 14.4531 11.5687 14.2219 11.4156L12.8594 10.5094C12.6813 10.3875 12.5469 10.2156 12.475 10.0125L12.0594 8.85625L10.8531 12.3438L12.2156 13.525C12.3625 13.6531 12.4406 13.8188 12.5219 14.0063L13.4812 17.3625C13.5562 17.6281 13.4031 17.8781 13.1375 17.9812C12.8719 18.0562 12.5938 17.9031 12.5188 17.6375L11.5594 14.2531L8.41875 11.5562C7.96656 11.1656 7.79031 10.5437 7.96813 9.975L8.79688 7.31875ZM9.94375 7.00938L8.92188 10.275C8.8625 10.4625 8.92188 10.6719 9.07188 10.8L10.0375 11.6375L11.4531 7.55312C11.1344 7.20937 10.6813 7 10.1906 7C10.1063 7 10.025 7.00313 9.94375 7.00938ZM7.98344 15.65C7.93531 15.7469 7.87219 15.8344 7.79625 15.9094L5.85344 17.8531C5.65844 18.05 5.34188 18.05 5.14644 17.8531C4.95119 17.6594 4.95119 17.3406 5.14644 17.1469L7.08906 15.2031L8.05281 13.275C8.175 13.0281 8.47812 12.9281 8.725 13.0531C8.97187 13.175 9.07188 13.4781 8.94688 13.725L7.98344 15.65Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PersonWalkingIcon;
