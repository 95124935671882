import Hide from "../../../components-ui/atoms/Hide";
import { VideoConferenceParticipant } from "../../../core/videoConference/VideoConferenceAdapter";
import { ProfileData, SearchableProfile } from "../profile/lib/profileTypes";
import ScreenSharingUiNormal from "./ScreenSharing.ui.normal";
import ScreenSharingUiPreview from "./ScreenSharing.ui.preview";

export type Props = {
  activeScreenShare: MediaStream | null;
  screenSharer: VideoConferenceParticipant | null;
  userId?: string;
  screenSharerName: string | null;
  activeParticipants: VideoConferenceParticipant[];
  allParticipants: VideoConferenceParticipant[];
  localParticipant: VideoConferenceParticipant;
  nrOfParticipants: number;
  amIScreenSharer: boolean;
  playersRowHidden: boolean;
  stopScreenShare: () => void;
  startScreenShare: () => void;
  onMinimizeScreenShare: () => void;
  onMaximizeScreenShare: () => void;
  roomId: string | null;
  minimized: boolean;
  micMuted: boolean;
  allPlayers: Record<string, SearchableProfile>;
  toggleLocalAudio: () => void;
  profileData: ProfileData;
  toggleVideo?: () => void;
  openPlayerList: () => void;
};

const ScreenSharingUi: React.FC<Props> = (props) => {
  /** Why don't we use transition here?
   * Because the props are getting updated constants, and the transition
   * will update its children only when its [watched] props are updated.
   * Meaning, we would retransition the component if we want updated props
   */
  return (
    <>
      <Hide
        unMount
        hide={!props.minimized}
        width="100%"
        height="100%"
        style={{ position: "absolute" }}
      >
        <ScreenSharingUiPreview {...props} />
      </Hide>
      <Hide
        unMount
        hide={props.minimized}
        width="100%"
        height="100%"
        style={{ position: "absolute" }}
      >
        <ScreenSharingUiNormal {...props} />
      </Hide>
    </>
  );
};

export default ScreenSharingUi;
