import { useEffect } from "react";
import { useIsSmallScreen } from "../../hooks/ui";
import { useStore } from "../../store/store";
import { LayoutZone, PanelName, PanelSettings } from "./panelsTypes";

/** Divides and panels and assign them to their correct zone
 *  depending on the isSmallScreen condition. */
export const useUpdateLayout = (panelsSettings: PanelSettings[]) => {
  const isSmallScreen = useIsSmallScreen();
  const updateLayout = useStore((s) => s.layout.updateLayout);
  const currentPanelSettings = panelsSettings.map((p) => {
    const options = isSmallScreen ? p.small ?? p.normal : p.normal;
    return { name: p.name, options: { ...options } };
  });
  const currentStep = useStore((s) => s.userFlow.currentStep);
  useEffect(() => {
    updateLayout(currentPanelSettings);
  }, [currentPanelSettings, updateLayout, currentStep]);
  const components: Record<LayoutZone, React.ReactNode[]> = {
    video: [],
    top: [],
    left: [],
    right: [],
    bottom: [],
    overlay: [],
    extra: [],
  };

  currentPanelSettings.forEach((it) => {
    if (!it.options.zone) return;
    const component = panelsSettings.find((p) => p.name === it.name)?.component;
    if (!component) return;

    components[it.options.zone].push(
      <WithMountable key={it.name} panelName={it.name}>
        {component}
      </WithMountable>
    );
  });

  return components;
};

type WithMountableProps = {
  panelName: PanelName;
  children: React.ReactNode | React.ReactNode[];
};

const WithMountable: React.FC<WithMountableProps> = ({
  panelName,
  children,
}) => {
  const panelState = useStore((s) => s.layout.panels[panelName]);
  const isReadyForMount = panelState.readyForMount;
  if (!isReadyForMount) return null;
  return children;
};
