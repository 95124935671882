export function toDurationString(seconds: number) {
  return new Date(1000 * seconds).toISOString().substring(11, 19);
}

export function isFiniteNumber(value?: number | null) {
  if (typeof value !== "number") return false;
  return isFinite(value);
}

export function toLocalDateTimeString(date?: Date) {
  if (!date) return "N/A";
  const formatter = new Intl.DateTimeFormat(window.navigator.language, {
    dateStyle: "short",
    timeStyle: "medium",
  });
  return formatter.format(date);
}

export const squeezeString = (string: string, size?: number) => {
  const defaultSideLength = 4;
  const sideLength = Math.floor((size || defaultSideLength) / 2 - 1.5);
  if (string.length <= sideLength * 2 + 3) return string;
  return `${string.slice(0, sideLength)}...${string.slice(
    -Math.abs(sideLength)
  )}`;
};
