import { VideoConferenceParticipant } from "./VideoConferenceAdapter";

function findOldActiveParticipants(
  participants: VideoConferenceParticipant[],
  nrOfActiveParticipants: number = 1
) {
  return [...participants]
    .sort((a, b) => a.lastActive - b.lastActive)
    .slice(0, nrOfActiveParticipants);
}

function findDifferentParticipants(
  participants: VideoConferenceParticipant[],
  oldParticipants: VideoConferenceParticipant[]
) {
  return participants.filter(
    (p) => !oldParticipants.some((op) => op.userId === p.userId)
  );
}

function removeDisconnectedParticipants(
  allParticipants: VideoConferenceParticipant[],
  oldParticipants: VideoConferenceParticipant[]
) {
  return oldParticipants.filter((p) =>
    allParticipants.some((ap) => ap.userId === p.userId)
  );
}

const NR_OF_ACTIVE_PARTICIPANTS = 4;

export function computeActiveParticipants(
  allParticipants: VideoConferenceParticipant[],
  oldActiveParticipants: VideoConferenceParticipant[]
) {
  const nextActiveParticipants = removeDisconnectedParticipants(
    allParticipants,
    [...oldActiveParticipants]
  );

  // we are only looking at other participants (peers) that are not local and are publishing
  const allNonLocalParticipants = allParticipants.filter(
    (p) => !p.isLocal && p.isPublishing
  );

  const nrOfOldParticipants = oldActiveParticipants.length;

  // We are interested only in last 4 active participants
  const last4ActiveParticipants = [...allNonLocalParticipants]
    .sort((a, b) => b.lastActive - a.lastActive)
    .slice(0, NR_OF_ACTIVE_PARTICIPANTS);

  const nrOfAllParticipants = last4ActiveParticipants.length;

  // if we have only one total participant, we should return it
  if (last4ActiveParticipants.length <= 1) {
    return last4ActiveParticipants;
  }

  // this might never get called, but its here just in case
  // Theoretically, we are already removing disconnected participants at line 36
  // but, this is just a double check
  if (nrOfOldParticipants > nrOfAllParticipants) {
    return nextActiveParticipants.slice(0, NR_OF_ACTIVE_PARTICIPANTS);
  }

  // if we have more participants than we had before, we should add the new ones
  // but we have to keep the order of the old participants
  if (nrOfOldParticipants < nrOfAllParticipants) {
    const differentParticipants = findDifferentParticipants(
      last4ActiveParticipants,
      oldActiveParticipants
    );
    return [...oldActiveParticipants, ...differentParticipants].slice(
      0,
      NR_OF_ACTIVE_PARTICIPANTS
    );
  }

  // if we have the same number of participants, we should check if they are the same
  const arraysEqual = last4ActiveParticipants.every((newP) => {
    return oldActiveParticipants.some((oldP) => oldP.userId === newP.userId);
  });
  // if they are the same, we should return the old participants
  if (arraysEqual) {
    return oldActiveParticipants;
  }

  /** here begins the actual logic of swapping the participants
   *  Everything before this was just edge cases
   */
  const lastSpeakingParticipant = last4ActiveParticipants[0];

  const isSpeakingParticipantInOldActiveParticipants =
    nextActiveParticipants.some(
      (p) => p.userId === lastSpeakingParticipant.userId
    );
  if (isSpeakingParticipantInOldActiveParticipants) {
    return nextActiveParticipants;
  }

  const participantsToReplace = findOldActiveParticipants(
    nextActiveParticipants,
    1
  );
  if (participantsToReplace.length === 0) {
    return last4ActiveParticipants;
  }
  const participantToReplaceIndex = nextActiveParticipants.indexOf(
    participantsToReplace[0]
  );
  nextActiveParticipants.splice(
    participantToReplaceIndex,
    1,
    lastSpeakingParticipant
  );
  return nextActiveParticipants.slice(0, NR_OF_ACTIVE_PARTICIPANTS);
}

export async function isScreenShareBusy(
  environmentId: string,
  visitorToken: string
) {
  const response = await getEnvironmentState(environmentId, visitorToken);
  return response?.presenterId != undefined;
}

// TODO: Remove when we get Gyaraless SDK
export type EnvironmentState = {
  presenterId: string | null;
};

export async function resetEnvironmentState(
  environmentId: string,
  visitorToken: string
): Promise<void> {
  await fetch(
    `${import.meta.env.VITE_GYARALESS_URL}/environment-states/${environmentId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${visitorToken}`,
      },
      body: JSON.stringify({
        presenterId: null,
      }),
    }
  );
}

export async function getEnvironmentState(
  environmentId: string,
  visitorToken: string
): Promise<EnvironmentState> {
  const response = await fetch(
    `${import.meta.env.VITE_GYARALESS_URL}/environment-states/${environmentId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${visitorToken}`,
      },
    }
  );

  if (response.status === 404) {
    return {
      presenterId: null,
    };
  }
  const body = await response.json();
  return {
    presenterId: body.presenterId,
  };
}

export async function updateEnvironmentState(
  environmentId: string,
  visitorToken: string,
  isSharing: boolean,
  presenterId?: string
): Promise<EnvironmentState | null> {
  const response = await fetch(
    `${import.meta.env.VITE_GYARALESS_URL}/environment-states/${environmentId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${visitorToken}`,
      },
      body: JSON.stringify({
        presenterId: isSharing ? presenterId : null,
      }),
    }
  );

  if (response.status === 409) {
    return null;
  }

  return await response.json();
}
