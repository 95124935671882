import { SvgProps } from "../../types/svg";

const MagnifyingGlassIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_172_43)">
      <path
        d="M17.8531 17.1469L13.4313 12.725C14.4063 11.5875 14.9719 10.1156 14.9719 8.5C14.9719 4.90937 12.0616 2 8.47188 2C4.88219 2 2 4.91031 2 8.5C2 12.0897 4.91001 15 8.47188 15C10.0869 15 11.5609 14.4075 12.6969 13.4328L17.1188 17.8547C17.2438 17.95 17.3719 18 17.5 18C17.6281 18 17.7559 17.9512 17.8534 17.8535C18.05 17.6594 18.05 17.3406 17.8531 17.1469ZM8.50001 14C5.44063 14 3.00001 11.5312 3.00001 8.5C3.00001 5.46875 5.44063 3 8.50001 3C11.5594 3 14 5.44062 14 8.5C14 11.5594 11.5313 14 8.50001 14Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_43">
        <rect
          width="16"
          height="16"
          fill="currentColor"
          className="fill-color"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default MagnifyingGlassIcon;
