import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LOCALSTORAGE_RECENT_ENV } from "../../constants/configs";
import { isForceDev } from "../../constants/flags";
import useLocalStorage from "../../hooks/localStorage";
import { useEnvironmentPath } from "../routing/routingHooks";
import DevtoolsReceiver from "./DevtoolsReceiver";

/** Enables the ALT+B debug command and components depending on the situation. */
const Devtools: React.FC = () => {
  const location = useLocation();
  const { envSlug, orgSlug } = useEnvironmentPath();
  const [recentUrls, setRecentUrls] = useLocalStorage<string[]>(
    LOCALSTORAGE_RECENT_ENV,
    []
  );

  // Save recently visited environment url in local storage on route change.
  useEffect(() => {
    if (envSlug) {
      const url = orgSlug ? `${orgSlug}/${envSlug}` : `${envSlug}`;
      if (!recentUrls.includes(url)) {
        setRecentUrls([url, ...recentUrls.slice(0, 9)]);
      } else {
        setRecentUrls([url, ...recentUrls.filter((it) => it !== url)]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const canOpenDevtools =
    import.meta.env.MODE === "test" ||
    import.meta.env.MODE === "development" ||
    isForceDev ||
    location.pathname.startsWith("/_local");

  return canOpenDevtools && <DevtoolsReceiver />;
};

export default Devtools;
