import { SvgProps } from "../../types/svg";

const NftIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99375 9.87192V7.46879C6.99375 7.19067 7.21875 6.96879 7.49375 6.96879C7.77187 6.96879 7.99375 7.19067 7.99375 7.46879V12.4688C7.99375 12.7063 7.825 12.9125 7.59062 12.9594C7.35312 13.0032 7.11875 12.875 7.00313 12.6532L5.99375 10.0657V12.4688C5.99375 12.7438 5.77188 12.9688 5.49375 12.9688C5.21875 12.9688 4.99531 12.7438 4.99531 12.4688V7.46879C4.99531 7.22817 5.16563 7.02192 5.4 6.97817C5.6375 6.93129 5.87187 7.05942 5.95937 7.28129L6.99375 9.87192ZM8.99375 7.46879C8.99375 7.19067 9.21875 6.96879 9.49375 6.96879H10.9937C11.2719 6.96879 11.4937 7.19067 11.4937 7.46879C11.4937 7.74379 11.2719 7.96879 10.9937 7.96879H9.99375V9.46879H10.9937C11.2719 9.46879 11.4937 9.69067 11.4937 9.96879C11.4937 10.2438 11.2719 10.4688 10.9937 10.4688H9.99375V12.4688C9.99375 12.7438 9.77187 12.9688 9.49375 12.9688C9.21875 12.9688 8.99375 12.7438 8.99375 12.4688V7.46879ZM14.4937 6.96879C14.7719 6.96879 14.9937 7.19067 14.9937 7.46879C14.9937 7.74379 14.7719 7.96879 14.4937 7.96879H13.9937V12.4688C13.9937 12.7438 13.7719 12.9688 13.4937 12.9688C13.2188 12.9688 12.9937 12.7438 12.9937 12.4688V7.96879H12.4937C12.2188 7.96879 11.9937 7.74379 11.9937 7.46879C11.9937 7.19067 12.2188 6.96879 12.4937 6.96879H14.4937ZM8.99375 2.46786C9.6125 2.11073 10.375 2.11073 10.9937 2.46786L15.9906 5.35317C16.6094 5.70942 16.9906 6.36879 16.9906 7.08442V12.8532C16.9906 13.5657 16.6094 14.2282 15.9906 14.5844L10.9937 17.4688C10.375 17.825 9.6125 17.825 8.99375 17.4688L4 14.5844C3.38125 14.2282 3 13.5657 3 12.8532V7.08442C3 6.36879 3.38125 5.70942 4 5.35317L8.99375 2.46786ZM10.4937 3.33411C10.1844 3.15536 9.80312 3.15536 9.49375 3.33411L4.5 6.21879C4.19062 6.39692 4 6.72817 4 7.08442V12.8532C4 13.2094 4.19062 13.5407 4.5 13.7188L9.49375 16.6032C9.80312 16.7813 10.1844 16.7813 10.4937 16.6032L15.4906 13.7188C15.8 13.5407 15.9906 13.2094 15.9906 12.8532V7.08442C15.9906 6.72817 15.8 6.39692 15.4906 6.21879L10.4937 3.33411Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default NftIcon;
