/** A loading page to display (normally in a new tab) while the
 *  computation of the photo sharing is in progress.
 */
const sharingLoadingPageHtml = `
<style>
body {
  background-color: #1f1f1f;
}
.spinner {
  position: fixed;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  width: 2rem;
  height: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(0,0,0,0);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 9999;
}

.spinner--hidden {
  display: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
<body><div class="spinner"></div></body>
`;

export default sharingLoadingPageHtml;
