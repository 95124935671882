import { useState } from "react";
import Hide from "../../../components-ui/atoms/Hide";
import { FullAbsoluteLayout } from "../../../core/layout/PanelsLayout";
import { useStore } from "../../../store/store";
import { MediaCaptureAction } from "../MediaCapture.slice";
import VideoCaptureLogic from "./VideoCapture.logic";
import VideoCaptureLogicPersistent from "./VideoCapture.logic.persistent";

const VideoCapturePanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.videoCapture.visible);
  const switchTab = useStore(
    (s) => s.layout.panels.videoCapture.options?.switchTab
  );

  const [autocapture, setAutocapture] = useState<
    MediaCaptureAction | undefined
  >(undefined);

  return (
    <FullAbsoluteLayout id="panel-videoCapture">
      <VideoCaptureLogicPersistent setAutocapture={setAutocapture} />
      {/* The panel does not fade when switching between photo and video tabs */}
      {switchTab && visible ? (
        <VideoCaptureLogic
          autocapture={autocapture}
          setAutocapture={setAutocapture}
        />
      ) : (
        <Hide
          hide={!visible}
          height="100%"
          width="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          unMount
        >
          <VideoCaptureLogic
            autocapture={autocapture}
            setAutocapture={setAutocapture}
          />
        </Hide>
      )}
    </FullAbsoluteLayout>
  );
};

export default VideoCapturePanel;
