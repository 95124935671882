import Button from "../atoms/Button";
import { PropsClickable } from "../atoms/Clickable";
import Icon from "../atoms/Icon";

export type Props = {
  isGlass?: boolean;
  disabled?: boolean;
  override?: PropsClickable;
  onClick?: () => void;
  size?: string;
  testId?: string;
};

const CloseButton: React.FC<Props> = ({ isGlass, size, ...props }) => {
  const icon = (
    <Icon.Cross inheritColor size={size ? `calc(${size} * 0.4)` : "13px"} />
  );

  return isGlass ? (
    <Button.Glass
      circular
      override={{
        ...(size && { width: size, height: size }),
      }}
      {...props}
    >
      {icon}
    </Button.Glass>
  ) : (
    <Button.Tertiary
      circular
      override={{
        ...(size && { width: size, height: size }),
      }}
      {...props}
    >
      {icon}
    </Button.Tertiary>
  );
};

export default CloseButton;
