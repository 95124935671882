import { SvgProps } from "../../types/svg";

const QuestionMarkIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99998 16.3293V17.5477M5.61035 6.55633C5.61051 5.80382 5.83187 5.06584 6.25024 4.42302C6.66862 3.78021 7.26788 3.25732 7.98266 2.91148C8.6973 2.56565 9.50002 2.41018 10.3027 2.4621C11.1056 2.51399 11.8777 2.77127 12.5347 3.20579C13.1917 3.64031 13.7084 4.23535 14.0281 4.92589C14.3479 5.61643 14.4586 6.37588 14.3479 7.12124C14.2373 7.86661 13.9099 8.56927 13.4011 9.15228C12.8923 9.7353 12.2219 10.1763 11.4632 10.4271C11.0351 10.5687 10.6644 10.8309 10.4024 11.1776C10.1403 11.5244 9.99971 11.9386 10 12.3632V12.9261"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-color"
    />
  </svg>
);
export default QuestionMarkIcon;
