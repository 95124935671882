/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AdvancedNoiseSuppressionFilter,
  Publisher,
  Session,
  initPublisher,
} from "@opentok/client";
import { assign, createMachine, fromPromise } from "xstate";
import { ConferenceError } from "../../../hooks/useConferenceErrors";
import { getDefaultLocalCamera } from "../../../lib/mediaDevices";
import { useStore } from "../../../store/store";
import MediaDevicesService from "../mediaDevices";
import { getExternalId, onAudioLevelUpdated } from "./util";

type PublishMediaDevicesMachineContext = {
  rootActor: any;
  session: Session;
  conferenceErrors: ConferenceError[];
  publisherErrors?: OT.OTError;
  publisher?: Publisher;
  videoInputSource: MediaDeviceInfo | null;
  audioInputSource: MediaDeviceInfo | null;
  videoInputDevices: MediaDeviceInfo[];
  audioInputDevices: MediaDeviceInfo[];
  mediaPermissions: { camera: boolean; microphone: boolean };
  publishVideo: boolean;
  publishAudio: boolean;
};

type PublishCameraAndMicrophoneInput = {
  videoInputSource: MediaDeviceInfo | null;
  audioInputSource: MediaDeviceInfo | null;
  videoInputDevices: MediaDeviceInfo[];
  audioInputDevices: MediaDeviceInfo[];
  mediaPermissions: { camera: boolean; microphone: boolean };
  rootActor: any;
  session: Session;
};

const mediaDevicesPublisherMachine = createMachine(
  {
    types: {} as {
      context: PublishMediaDevicesMachineContext;
      input: PublishCameraAndMicrophoneInput;
    },
    output: ({ context }) => ({
      publisher: context.publisher,
      conferenceErrors: context.conferenceErrors,
      publisherErrors: context.publisherErrors,
    }),
    id: "mediaDevicesPublisherMachine",
    initial: "Idle",
    context: ({ input }) => ({
      videoInputSource: input.videoInputSource,
      audioInputSource: input.audioInputSource,
      videoInputDevices: input.videoInputDevices,
      audioInputDevices: input.audioInputDevices,
      mediaPermissions: input.mediaPermissions,
      session: input.session,
      publishVideo: false,
      publishAudio: false,
      rootActor: input.rootActor,
      conferenceErrors: [],
    }),
    states: {
      Idle: {
        always: "CheckingCamera",
      },
      CheckingCamera: {
        invoke: {
          id: "cameraFeedChecker",
          src: "cameraFeedChecker",
          input: ({ context }) => {
            return context;
          },
          onDone: [
            {
              actions: [
                assign({
                  conferenceErrors: ({ event }) => event.output,
                  publishVideo: ({ event }) => event.output.length === 0,
                }),
              ],
              target: "PublishingMediaDevices",
            },
          ],
        },
      },
      PublishingMediaDevices: {
        invoke: {
          id: "mediaDevicesPublisher",
          src: "mediaDevicesPublisher",
          input: ({ context }) => context,
          onDone: {
            actions: assign({
              publisher: ({ event }) => event.output,
            }),
            target: "Success",
          },
          onError: {
            actions: assign({
              publisherErrors: ({ event }) => {
                return event.error as OT.OTError;
              },
            }),
            target: "Error",
          },
        },
      },
      Error: {
        type: "final",
      },
      Success: {
        type: "final",
      },
    },
  },
  {
    actors: {
      cameraFeedChecker: fromPromise<
        ConferenceError[],
        PublishMediaDevicesMachineContext
      >(async ({ input }) => {
        const hasCameras = input.videoInputDevices.length > 0;
        const hasCameraPermissions = input.mediaPermissions.camera;
        return MediaDevicesService.checkForCameraAvailability(
          hasCameras,
          hasCameraPermissions,
          input.videoInputSource?.deviceId
        );
      }),
      mediaDevicesPublisher: fromPromise<
        Publisher | null,
        PublishMediaDevicesMachineContext
      >(async ({ input }) => {
        const { rootActor, session } = input;
        const selectedVideoInputDevice = input.videoInputSource;
        const selectedAudioInputDevice = input.audioInputSource;
        const shouldShowVideo =
          input.publishVideo && !useStore.getState().userMedia.webcamMuted;
        const publishAudio =
          Boolean(input.audioInputSource) &&
          !useStore.getState().userMedia.micMuted;
        let publisherVideoSource;
        const defaultVideoSource = getDefaultLocalCamera({
          cameraPermission: true,
          videoInputDevices: input.videoInputDevices,
        });

        if (
          input.videoInputDevices.length === 0 &&
          input.audioInputDevices.length === 0
        )
          return null;

        switch (true) {
          case Boolean(
            input.publishVideo && selectedVideoInputDevice?.deviceId
          ):
            publisherVideoSource = selectedVideoInputDevice?.deviceId;
            break;
          case selectedVideoInputDevice?.deviceId === undefined:
            publisherVideoSource = defaultVideoSource?.deviceId ?? null;
            break;
          default:
            publisherVideoSource = null;
            break;
        }
        const hasMediaProcessorSupport = OT.hasMediaProcessorSupport();
        const audioFilter = hasMediaProcessorSupport
          ? ({
              type: "advancedNoiseSuppression",
            } as AdvancedNoiseSuppressionFilter)
          : undefined;

        const publisher = initPublisher(undefined, {
          videoSource: publisherVideoSource,
          audioSource: selectedAudioInputDevice?.deviceId,
          publishVideo: shouldShowVideo && Boolean(publisherVideoSource),
          publishAudio,
          disableAudioInputDeviceManagement: true,
          audioFallback: {
            publisher: true,
          },
          resolution: "320x240",
          maxResolution: {
            width: 640,
            height: 480,
          },
          audioFilter,
          insertDefaultUI: false,
          noiseSuppression: true,
          echoCancellation: true,
        });
        publisher.on("videoElementCreated", (event) => {
          rootActor.send({
            type: "conference.localVideoStreamReady",
            data: event,
          });
        });
        publisher.on("audioLevelUpdated", (event) => {
          if (!session?.connection?.data) return;
          const userId = getExternalId(session?.connection?.data);
          onAudioLevelUpdated(
            userId,
            event,
            useStore.getState().videoConference.setParticipantSpeaking
          );
        });
        await new Promise((resolve, reject) => {
          session.publish(publisher, (error) => {
            if (error) {
              reject(error);
            } else {
              resolve(publisher);
            }
          });
        });
        return publisher;
      }),
    },
  }
);

export default mediaDevicesPublisherMachine;
