import React from "react";
import { SvgProps } from "../../types/svg";

const StarIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99888 15.2728L5.82213 17.4178C5.6039 17.5438 5.30686 17.5233 5.07953 17.368C4.85523 17.2127 4.74309 16.9431 4.76127 16.6794L5.58268 12.1229L2.21552 8.89375C2.01971 8.70915 1.9503 8.43077 2.03638 8.17584C2.12216 7.92385 2.34858 7.73924 2.62107 7.70115L7.2649 7.03599L9.34418 2.89617C9.46845 2.65381 9.72002 2.5 9.99888 2.5C10.2777 2.5 10.5323 2.65384 10.6536 2.89617L12.7329 7.03599L17.3794 7.70115C17.6522 7.73924 17.8765 7.92385 17.9644 8.17584C18.0493 8.43077 17.9796 8.70915 17.7856 8.89375L14.4151 12.1229L15.2122 16.6794C15.2577 16.9431 15.1456 17.2127 14.9182 17.368C14.6939 17.5233 14.3696 17.5438 14.1514 17.4178L9.99888 15.2728ZM8.13783 7.44622C7.9984 7.72459 7.72561 7.91799 7.40735 7.96487L3.24243 8.5597L6.26769 11.4548C6.48895 11.6687 6.59201 11.9763 6.54048 12.2782L5.82516 16.3658L9.54119 14.4465C9.82914 14.2971 10.1716 14.2971 10.4566 14.4465L14.1726 16.3658L13.4603 12.2782C13.4057 11.9763 13.5088 11.6687 13.7331 11.4548L16.7581 8.5597L12.5904 7.96487C12.2479 7.91799 11.9751 7.72459 11.8599 7.44622L9.99888 3.74007L8.13783 7.44622Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default StarIcon;
