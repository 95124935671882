import { GameColor } from "../../../../../core/gameConnection/messages/sharedDataTypes";
import { VideoConferenceParticipant } from "../../../../../core/videoConference/VideoConferenceAdapter";
import { useReflow } from "../../../../../hooks/ui";
import { ConferenceError } from "../../../../../hooks/useConferenceErrors";
import SettingsVideoPreviewNormal from "./SettingsVideoPreview.normal";
import SettingsVideoPreviewSmall from "./SettingsVideoPreview.small";

export type Props = {
  micMuted: boolean;
  webcamMuted: boolean;
  conferenceInitialized: boolean;
  amIScreenSharer: boolean;
  activeScreenShare: boolean;
  screenSharer: VideoConferenceParticipant | null;
  playerName: string | null;
  avatarColor: GameColor;
  toggleLocalAudio: () => void;
  toggleScreenShare: () => Promise<void>;
  toggleVideo: () => void;
  roomId: string | null;
  conferenceErrors: ConferenceError[];
  onLeaveConference: () => void;
  onJoinConference: () => void;
  localAudioStream: MediaStream | null;
  localCameraStream: MediaStream | null;
  selectedAudioInputDevice: MediaDeviceInfo | null;
  selectedAudioOutputDevice: MediaDeviceInfo | null;
  selectedVideoInputDevice: MediaDeviceInfo | null;
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
};

const SettingsVideoPreviewUi: React.FC<Props> = (props) => {
  return useReflow({
    small: <SettingsVideoPreviewSmall {...props} />,
    normal: <SettingsVideoPreviewNormal {...props} />,
  });
};

export default SettingsVideoPreviewUi;
