import { DebugAction } from "./DevtoolsSender";

export const parseDebugMessage = (mes: unknown) => {
  // debugger;
  if (typeof mes === "string" && mes.startsWith("debug;")) {
    const [prefix, _type, extra1, extra2] = mes.split(";");
    const type = _type as DebugAction;

    return {
      prefix,
      type,
      extra1,
      extra2,
    };
  } else return null;
};

export const parseRecentEnvString = (env: string) => {
  const slug1 = env.split("/")[0];
  const slug2 = env.split("/")[1];

  const envSlug = slug2 || slug1;
  const orgSlug = slug2 ? slug1 : undefined;

  return {
    route: orgSlug ? `/${orgSlug}/${envSlug}` : `/${envSlug}`,
    envSlug,
    orgSlug,
  };
};
