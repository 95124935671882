import { useEffect, useState } from "react";
import LoginPage from "../../../features/login/LoginPage";
import LoginVideoBackground from "../../../features/login/LoginVideoBackground";
import FullscreenHelper from "../../../features/login/SwipeUpHelper/FullscreenHelper";
import EndingPanel from "../../../features/panels/ending/Ending.logic";
import SocketDisconnectPageUi from "../../../features/utilityPages/SocketDisconnectPageUi";
import { useStore } from "../../../store/store";
import EnvironmentDisconnectHandler from "../../EnvironmentDisconnectHandler.core";
import { useEnvironmentContext } from "../../EnvironmentFetcher.core";
import ExperiencePage from "../../ExperiencePage";
import GamePlayer from "../../GamePlayer";
import MapImageContextProvider from "../../MapImageContext";
import ReactionsProvider from "../../ReactionsContext";
import VideoPlayerContextProvider from "../../VideoPlayerContext";
import { useCopywriting } from "../../i18n/i18nHooks";
import { useIdentifyLogRocketUser } from "../../tracking/logrocket";
import VideoConferenceProvider from "../../videoConference/VideoConferenceProvider";
import Web3ContextProvider from "../../web3WalletConnect/web3Context";

const ExperienceRoute: React.FC = () => {
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const registerCleanupHook = useStore((s) => s.userFlow.registerCleanupHook);
  const { environment } = useEnvironmentContext();

  const [mountLogin, setMountLogin] = useState(true);

  useEffect(() => {
    registerCleanupHook("removeLoginPage", () => setMountLogin(false));
  }, [registerCleanupHook]);
  useIdentifyLogRocketUser();
  useCopywriting();

  useEffect(() => {
    if (environment) {
      completeStep("load-environment");
    }
  }, [environment, completeStep]);

  return (
    <EnvironmentDisconnectHandler displayPage={SocketDisconnectPageUi}>
      <VideoPlayerContextProvider>
        <MapImageContextProvider>
          <Web3ContextProvider>
            <LoginVideoBackground />
            <EndingPanel />
            <VideoConferenceProvider>
              <ReactionsProvider>
                <ExperiencePage />
              </ReactionsProvider>
            </VideoConferenceProvider>
            <FullscreenHelper>
              <GamePlayer />
            </FullscreenHelper>
            {mountLogin && <LoginPage onDone={() => setMountLogin(false)} />}
          </Web3ContextProvider>
        </MapImageContextProvider>
      </VideoPlayerContextProvider>
    </EnvironmentDisconnectHandler>
  );
};

export default ExperienceRoute;
