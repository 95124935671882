import { styled } from "styled-components";
import { zIndex } from "../../style/theme";
import Icon from "../atoms/Icon";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: white;
  stroke: white;
  background: black;
  z-index: ${zIndex.extras};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: reveal 1s 1s ease-in-out forwards;
  @keyframes reveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const FullScreenLoader: React.FC = () => {
  return (
    <Wrapper>
      <Icon.RipplesAnimated color="white" />
    </Wrapper>
  );
};

export default FullScreenLoader;
