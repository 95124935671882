import { SvgProps } from "../../types/svg";

const FullscreenIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.42857 2H2.28571C2.12836 2 2 2.12821 2 2.28571V7.42857C2 7.58571 2.12836 7.71429 2.28571 7.71429C2.44307 7.71429 2.57143 7.58571 2.57143 7.42857V2.57143H7.42857C7.58571 2.57143 7.71429 2.44321 7.71429 2.28571C7.71429 2.12821 7.58571 2 7.42857 2ZM17.7143 2H12.5714C12.4143 2 12.2857 2.12821 12.2857 2.28571C12.2857 2.44321 12.4141 2.57143 12.5714 2.57143H17.4286V7.42857C17.4286 7.58571 17.5571 7.71429 17.7143 7.71429C17.8714 7.71429 18 7.58571 18 7.42857V2.28571C18 2.12821 17.8714 2 17.7143 2ZM17.7143 12.2857C17.5569 12.2857 17.4286 12.4141 17.4286 12.5714V17.4286H12.5714C12.4141 17.4286 12.2857 17.5569 12.2857 17.7143C12.2857 17.8716 12.4143 18 12.5714 18H17.7143C17.8716 18 18 17.8716 18 17.7143V12.5714C18 12.4143 17.8714 12.2857 17.7143 12.2857ZM7.42857 17.4286H2.57143V12.5714C2.57143 12.4143 2.44321 12.2857 2.28571 12.2857C2.12821 12.2857 2 12.4143 2 12.5714V17.7143C2 17.8714 2.12836 18 2.28571 18H7.42857C7.58571 18 7.71429 17.8714 7.71429 17.7143C7.71429 17.5571 7.58571 17.4286 7.42857 17.4286Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default FullscreenIcon;
