import { useEffect } from "react";
import { isTouch } from "../../constants/flags";

/** Add the class noTouch to the body element when appropriate.
 * This is the only easy way to then make all "&:hover" conditional. */
const DetectTouchForCss: React.FC = () => {
  useEffect(() => {
    if (!isTouch) {
      document.body.classList.add("noTouch");
    }
  }, []);
  return null;
};

export default DetectTouchForCss;
