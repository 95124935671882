import { useRef } from "react";

/**
 * React state hook that returns the latest state as described in
 * the React hooks FAQ. This is mostly useful to get access to the
 * latest value of some props or state inside an asynchronous callback,
 * instead of that value at the time the callback was created from.
 * From https://github.com/streamich/react-use/blob/master/src/useLatest.ts
 *
 * Hopefully this will become unnecessary when useEvent becomes available.
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 *
 * Note: it's not a silver bullet, it should be used with care.
 *
 * @param value A value to stabilize through a ref.
 * @returns A ref that will always point to the latest value
 */
const useLatest = <T>(value: T): React.RefObject<T> => {
  const ref = useRef(value);
  ref.current = value;
  return ref;
};

export default useLatest;
