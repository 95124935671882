import { Shared } from "@journee-live/mew";
import { FromGameMessage } from "../../core/gameConnection/messages/fromGameMessages";
import { SliceCreator } from "../../store/store";
import { VisitorTokenData, getVisitorTokenData } from "./loginUtils";

const sliceName = "session";

export interface SessionState {
  /** An uuid presisted in the client's browser's local storage. */
  clientPersistedId: string | null;
  setClientPersistedId: (id: string) => void;

  /** The token of the visitor. */
  visitorToken: string | null;
  visitorTokenData: VisitorTokenData | null;
  setVisitorToken: (token: string) => void;
  isModerator: () => boolean;
  isAdmin: () => boolean;

  // TODO: Replace with Universal Session Id when available
  setLogRocketSessionId: (sessionId: string) => void;
  logRocketSessionId: string | null;

  inactivityExceptions: Record<string, boolean>;
  addInactivityException: (exception: string) => void;
  removeInactivityException: (exception: string) => void;

  overrideInactivityTimeout?: number | null;
  setOverrideRedirectTimeout: (timeout: number) => void;

  hasGameSessionEnded: boolean;
  dispatchSessionMessage: (message: FromGameMessage) => void;
}

type State = {
  session: SessionState;
};

export const createSessionSlice: SliceCreator<State> = (set, get) => ({
  session: {
    clientPersistedId: null,

    setClientPersistedId: (val) =>
      set(
        (state) => {
          state.session.clientPersistedId = val;
        },
        false,
        `${sliceName}/setClientPersistedId`
      ),

    visitorToken: null,
    visitorTokenData: null,

    setVisitorToken: (token) =>
      set(
        (state) => {
          state.session.visitorToken = token;
          state.session.visitorTokenData = getVisitorTokenData(token);
        },
        false,
        `${sliceName}/setVisitorToken`
      ),

    logRocketSessionId: null,
    setLogRocketSessionId: (session) =>
      set(
        (state) => {
          state.session.logRocketSessionId = session;
        },
        false,
        `${sliceName}/setLogRocketSessionId`
      ),

    isModerator: () =>
      get().session.visitorTokenData?.rol === Shared.VisitorRoles.MODERATOR,
    isAdmin: () =>
      get().session.visitorTokenData?.rol === Shared.VisitorRoles.ADMIN,

    inactivityExceptions: {},
    addInactivityException: (exception) =>
      set((state) => {
        state.session.inactivityExceptions[exception] = true;
      }),
    removeInactivityException: (exception) =>
      set((state) => {
        delete state.session.inactivityExceptions[exception];
      }),
    overrideInactivityTimeout: null,
    setOverrideRedirectTimeout: (timeout) => {
      set((state) => {
        state.session.overrideInactivityTimeout = timeout;
      });
    },
    hasGameSessionEnded: false,
    dispatchSessionMessage: (message) => {
      set((state) => {
        if (message.type === "EndSession") {
          state.session.hasGameSessionEnded = true;
        }
      });
    },
  },
});
