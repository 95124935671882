import { ReactNode } from "react";
import DefaultTheme from "../DefaultTheme";
import Debug from "../dev/Devtools";

type Props = {
  children: ReactNode;
};

/** A wrapper shared among all routes. */
const WithDebug: React.FC<Props> = ({ children }) => {
  return (
    <DefaultTheme>
      <Debug />
      {children}
    </DefaultTheme>
  );
};

export default WithDebug;
